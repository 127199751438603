import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";
import { BricksNewFormRoutingModule } from "./bricks-new-form-routing.module";
//import { TwoDigitDecimaNumberDirective } from '../../two-digit-decima-number.directive';

@NgModule({
  declarations: [BricksNewFormRoutingModule.components, ],
  imports: [SharedModule, BricksNewFormRoutingModule/*, TwoDigitDecimaNumberDirective*/],
  exports: [BricksNewFormRoutingModule.components],
  providers: [SharedModule/*TwoDigitDecimaNumberDirective*/]
})
export class BricksNewFormModule {}
