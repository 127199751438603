import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { Optional } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
//app-services/data.service.js
import { DataService } from "../../../../app-services/data.service";
import { MatDialog } from "@angular/material/dialog";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";
import { FormGroup, FormArray, FormControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Validators } from "@angular/forms";
import { LocationService } from "src/app/app-services/location.service";

export interface UsersData {
  id: number;
  name: string;
  address: string;
  distance: number;
  distance_bbdd: number;
}

@Component({
  selector: "app-add-dialog-mcd-box-locator",
  templateUrl: "./add-dialog-mcd-box-locator.component.html",
  styleUrls: ["./add-dialog-mcd-box-locator.component.scss"]
})
export class AddDialogMcdBoxLocatorComponent implements OnInit, OnDestroy {
  action: string;
  local_dataL: any;
  unsubscribe: any;

  addressLocation: string = "";
  cityCtrl: string = "";
  provinceCtrl: string = "";
  lonCtrl: any = "";
  latCtrl: any = "";
  addressCtrl: any = "";

  disabledButton: boolean = true;

  coordsSelected: any;


  geoStudyFormGroup = new FormGroup({
    addressCtrl: new FormControl("", [Validators.required]),
    cityCtrl: new FormControl("", [Validators.required]),
    provinceCtrl: new FormControl("", [Validators.required]),
    lonCtrl: new FormControl("", [Validators.required]),
    latCtrl: new FormControl("", [Validators.required])

  });

  dataSource: any[] = [];
  @Output() competitorsLocationAdded: EventEmitter<any> = new EventEmitter();


  constructor(
    public dialogRefL: MatDialogRef<AddDialogMcdBoxLocatorComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData,
    private dataService: DataService,
    public dialogL: MatDialog,
    private geoStudiesDataService: GeoStudiesDataService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
  ) {
    this.local_dataL = { ...data };
    this.action = this.local_dataL.action;
    /*
        this.addressLocation = this.local_dataL.local_data.addressCtrl ? this.local_dataL.local_data.addressCtrl : "";
        this.cityCtrl = this.local_dataL.local_data.city ? this.local_dataL.local_data.city : "";
        this.provinceCtrl = this.local_dataL.local_data.province ? this.local_dataL.local_data.province : "";
        this.lonCtrl = this.local_dataL.local_data.long ? this.local_dataL.local_data.long : "";
        this.latCtrl = this.local_dataL.local_data.lat ? this.local_dataL.local_data.lat : "";
    */

    if (!this.local_dataL.local_data.addressCtrl) {
      this.coordsSelected = this.geoStudiesDataService.getCoordsPredictive();

      this.locationService
        .geocodificarCoordenadas({
          lat: this.coordsSelected.lat,
          lon: this.coordsSelected.lon
        })
        .subscribe((respuesta: any) => {
          let resultApi = this.locationService.buscarDireccion(respuesta);


          this.addressLocation = resultApi.address;
          this.cityCtrl = resultApi.locality;
          this.provinceCtrl = resultApi.province;
          this.lonCtrl = resultApi.x;
          this.latCtrl = resultApi.y;

        });

    } else {
      this.addressLocation = this.local_dataL.local_data.addressCtrl ? this.local_dataL.local_data.addressCtrl : "";
      this.cityCtrl = this.local_dataL.local_data.city ? this.local_dataL.local_data.city : "";
      this.provinceCtrl = this.local_dataL.local_data.province ? this.local_dataL.local_data.province : "";
      this.lonCtrl = this.local_dataL.local_data.long ? this.local_dataL.local_data.long : "";
      this.latCtrl = this.local_dataL.local_data.lat ? this.local_dataL.local_data.lat : "";
    }

  }


  locateAddress: any = () => {
    this.addressLocation = this.geoStudyFormGroup.value.addressCtrl;
    this.cityCtrl = this.geoStudyFormGroup.value.cityCtrl;
    this.provinceCtrl = this.geoStudyFormGroup.value.provinceCtrl;

    if (this.addressLocation != undefined) {
      let adress = this.addressLocation;
      let city = this.cityCtrl;
      let province = this.provinceCtrl;
      let params = adress + " , " + city + " , " + province;
      this.geoStudiesDataService.addressPredictiveAddMcd(params);
    }
  };


  openDialog(action, obj) {
    obj.action = action;
    const dialogRefL = this.dialogL.open(AddDialogMcdBoxLocatorComponent, {
      width: "320px",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRefL.afterClosed().subscribe(result => {
      if (result.event == "Añadir dirección") {
        this.addRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    this.local_dataL = row_obj
    this.dataSource.push({
      name: row_obj.name.brand,
      id_brand: row_obj.name.id_brand,
      address: row_obj.address,
      distance: row_obj.distance_bbdd,
      distance_bbdd: row_obj.distance
    });

    if (this.local_dataL.local_data.addressCtrl) {
      this.disabledButton = false;
    }
    this.competitorsLocationAdded.emit(this.dataSource);
  }

  doAction() {
    this.local_dataL
    this.dialogRefL.close({ event: this.action, data: this.geoStudyFormGroup.value });
  }

  closeDialog() {
    this.dialogRefL.close({ event: "Cancelar" });
  }

  get formArray(): any | null {
    return this.geoStudyFormGroup.get("formArray");
  }

  submit() {
    this.dialogRefL.close(this.geoStudyFormGroup.value);
  }

  ngOnInit() {
    /*this.addressLocation = this.local_dataL.local_data.addressCtrl ? this.local_dataL.local_data.addressCtrl : "";
    this.cityCtrl = this.local_dataL.local_data.city ? this.local_dataL.local_data.city : "";
    this.provinceCtrl = this.local_dataL.local_data.province ? this.local_dataL.local_data.province : "";
    this.lonCtrl = this.local_dataL.local_data.long ? this.local_dataL.local_data.long : "";
    this.latCtrl = this.local_dataL.local_data.lat ? this.local_dataL.local_data.lat : "";
*/

    if (!this.local_dataL.local_data.addressCtrl) {
      this.coordsSelected = this.geoStudiesDataService.getCoordsPredictive();

      this.locationService
        .geocodificarCoordenadas({
          lat: this.coordsSelected.lat,
          lon: this.coordsSelected.lon
        })
        .subscribe((respuesta: any) => {
          let resultApi = this.locationService.buscarDireccion(respuesta);


          this.addressLocation = resultApi.address;
          this.cityCtrl = resultApi.locality;
          this.provinceCtrl = resultApi.province;
          this.lonCtrl = resultApi.x;
          this.latCtrl = resultApi.y;


          this.geoStudyFormGroup.patchValue({
            addressCtrl: this.addressLocation,
            cityCtrl: this.cityCtrl,
            provinceCtrl: this.provinceCtrl,
            lonCtrl: this.lonCtrl,
            latCtrl: this.latCtrl
          });
          if (this.addressLocation != undefined) {
            if (this.addressLocation != "") {
              let adress = this.addressLocation;
              let city = this.cityCtrl;
              let province = this.provinceCtrl;
              let params = adress + " , " + city + " , " + province;
              this.geoStudiesDataService.addressPredictiveAddMcd(params);
            }
          }

        });

    } else {
      this.addressLocation = this.local_dataL.local_data.addressCtrl ? this.local_dataL.local_data.addressCtrl : "";
      this.cityCtrl = this.local_dataL.local_data.city ? this.local_dataL.local_data.city : "";
      this.provinceCtrl = this.local_dataL.local_data.province ? this.local_dataL.local_data.province : "";
      this.lonCtrl = this.local_dataL.local_data.long ? this.local_dataL.local_data.long : "";
      this.latCtrl = this.local_dataL.local_data.lat ? this.local_dataL.local_data.lat : "";

      this.geoStudyFormGroup.patchValue({
        addressCtrl: this.addressLocation,
        cityCtrl: this.cityCtrl,
        provinceCtrl: this.provinceCtrl,
        lonCtrl: this.lonCtrl,
        latCtrl: this.latCtrl
      });
      if (this.addressLocation != undefined) {
        if (this.addressLocation != "") {
          let adress = this.addressLocation;
          let city = this.cityCtrl;
          let province = this.provinceCtrl;
          let params = adress + " , " + city + " , " + province;
          this.geoStudiesDataService.addressPredictiveAddMcd(params);
        }
      }
    }





    this.geoStudiesDataService.studyParamsPredictiveAddMcdChanged$.subscribe(
      (params) => {
        this.lonCtrl = params.x;
        this.latCtrl = params.y;
        this.addressLocation = params.address;
        this.cityCtrl = params.locality;
        this.provinceCtrl = params.province;

        this.geoStudyFormGroup.value.addressCtrl = this.addressLocation;
        this.geoStudyFormGroup.value.cityCtrl = this.cityCtrl;
        this.geoStudyFormGroup.value.provinceCtrl = this.provinceCtrl;
        this.geoStudyFormGroup.value.lonCtrl = this.lonCtrl;
        this.geoStudyFormGroup.value.latCtrl = this.latCtrl;

        this.geoStudyFormGroup.patchValue({
          addressCtrl: params.address,
          cityCtrl: params.locality,
          provinceCtrl: params.province,
          lonCtrl: params.x,
          latCtrl: params.y,
        });

      }
    );


  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
