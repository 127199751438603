import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { OnChanges } from "@angular/core";
import { SimpleChange } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";

import { D3MainService } from "../../../app-services/d3/d3-main.service";
import { ReshapeDataService } from "../../../app-services/d3/reshape-data.service";

import * as d3 from "d3";
@Component({
  selector: "app-grouped-bar",
  templateUrl: "./grouped-bar.component.html",
  styleUrls: ["./grouped-bar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedBarComponent implements OnInit {
  // params
  @Input()
  params: any;
  @Input()
  chartId: string;
  @Input()
  chartSize: string;

  @Input()
  categorySelectValue?: any; //string // "0", "1", "2", "3"

  @Input() firstFilterValue?: any;
  @Input() secondFilterValue?: any;

  @Input() thirdFilterValue?: any;
  @Input() fourFilterValue?: any;
  @Input() fifthFilterValue?: any;
  @Input() sixthFilterValue?: any;

  //data: any;
  dataChart: any;
  xColumns: any;
  xKeys: Array<string>;
  design: any;
  chartLegend: any;
  units: any;
  style: any;

  D3READY: boolean = false;
  win: any = window; // in use
  timeout: any = false;
  delay: number;

  // services
  COLORS: any;
  getDimensions: any;
  formatRound: any;
  formatZero: any;
  getRealName: any;

  tDefault: number;
  opacity_lowest: number;

  width: number;
  height: number;
  margin: any;
  // svg containers
  container: any;
  svg: any;
  chartWrapper: any;
  chartInner: any;
  chartGroups: any;
  tooltip: any;

  // scales
  xScale: any;
  xScaleBars: any;
  yScale: any;
  colorScale: any;

  // axis names

  xVal: string;
  yVal: string;
  xAxis: any;
  yAxis: any;

  // averages and extends
  yAvg: number;
  yExtent: any;
  xDomain: Array<number>;
  yDomain: Array<number>;

  // ticks

  //
  // Variables

  init = (): void => {
    this.destroyChart();
    this.renderAll();
  };

  destroyChart() {
    d3.select(this.params.container || "#" + this.chartId).html("");
    d3.select(this.win).on("resize", null);
  }
  renderAll = (): void => {
    // SCALES
    this.colorScale = d3.scaleOrdinal().range(this.style.colors);
    this.xScale = d3.scaleBand().paddingInner(this.style.paddingInner);
    this.xScaleBars = d3.scaleBand().padding(this.style.padding);
    this.yScale = d3.scaleLinear();

    ////////// Initialize axis //////////
    this.xAxis = d3.axisBottom(this.xScale);
    this.yAxis = d3.axisLeft(this.yScale).tickFormat(this.formatZero);

    // ELEMENTS
    this.container = d3.select(this.params.container || "#" + this.chartId); // placeholder div for svg

    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    this.chartLegend = this.svg
      .selectAll("g.chartLegend")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartLegend")
      .attr("transform", "translate(" + this.margin.left + "," + 0 + ")");

    this.chartWrapper = this.svg
      .selectAll("g.chartWrapper")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartWrapper")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.chartInner = this.chartWrapper
      .selectAll("g.chartInner") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartInner");

    // Axis groups
    this.chartWrapper
      .selectAll(".x.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis");
    this.chartWrapper
      .selectAll(".y.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis");

    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.render, this.delay);
    });
    this.reshapedata();
  };

  reshapedata = (): void => {
    if (this.params.xVal.columns.length > 1) {
      this.xColumns = this.params.xVal.columns.filter(
        d => d["id"] === this.categorySelectValue
      )[0]["data"];
    } else {
      this.xColumns = this.params.xVal.columns[0]["data"];
    }
    this.xKeys = Object.keys(this.xColumns);

    let xKeys = this.xKeys;

    this.dataChart = this.reshapeDataService.reshapeDataToRender(this.params, [
      this.categorySelectValue,
      this.firstFilterValue,
      this.secondFilterValue,
      this.thirdFilterValue,
      this.fourFilterValue,
      this.fifthFilterValue,
      this.sixthFilterValue
    ]);

    this.yDomain = [
      0,
      d3.max(this.dataChart, d => {
        return d3.max(xKeys, key => {
          return d[key];
        });
      })
    ];
    this.xDomain = this.dataChart.map(function(d) {
      return d["category"];
    });

    this.render();
  };

  render = (): void => {
    let dimensions = this.d3MainService.getDimensions(
      this.svg,
      this.params.container || "#" + this.chartId,
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;

    this.yScale
      .rangeRound([this.height, 0])
      .domain(this.yDomain)
      .nice();

    this.xScale.domain(this.xDomain).range([0, this.width]);

    this.xScaleBars.domain(this.xKeys).rangeRound([0, this.xScale.bandwidth()]);

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .attr(
        "viewBox",
        "0 0 " +
          (this.width + this.margin.left + this.margin.right) +
          " " +
          (this.height + this.margin.top + this.margin.bottom)
      )
      .attr("preserveAspectRatio", "xMaxYMax meet");

    this.drawAxis();
    this.drawBarGroups();
    this.drawBars();

    this.drawChartLegend();
  };

  public drawBarGroups = () => {
    let xScale = this.xScale;
    const tDefault: number = this.tDefault;

    // Create a group to store the 'nodes'

    let chartGroups = this.chartInner
      .selectAll("g.chartGroup")
      .data(this.dataChart);

    // Exit the nodes
    chartGroups
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(tDefault / 2)
      .remove();

    chartGroups
      .enter()
      .append("svg:g")
      .attr("class", "chartGroup")
      .attr("transform", function(d) {
        return "translate(" + xScale(d.category) + ",0)";
      });

    chartGroups = this.chartInner.selectAll("g.chartGroup");
    chartGroups
      .transition()
      .duration(tDefault)
      .attr("transform", function(d) {
        return "translate(" + xScale(d.category) + ",0)";
      });
  };

  public drawBars = (): void => {
    let xKeys = this.xKeys;
    let xScaleBars = this.xScaleBars;
    let yScale = this.yScale;
    let colorScale = this.colorScale;
    let height = this.height;
    const tDefault: number = this.tDefault;

    let bars = this.chartInner
      .selectAll("g.chartGroup")
      .selectAll("rect.bar")
      .data((d, i) => {
        return xKeys.map(function(key) {
          return {
            key: key,
            value: !!d[key] ? d[key] : 0,
            category: d["category"],
            index: key + "_" + i + "_" + d["category"]
          };
        });
      });

    bars
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(tDefault / 2)
      .remove();

    bars
      .enter()
      .append("svg:rect")
      .attr("class", "bar")
      .attr("x", function(d) {
        return xScaleBars(d.key);
      })
      .attr("y", function(d) {
        return yScale(d.value);
      })
      .attr("width", xScaleBars.bandwidth())
      .attr("data-index", function(d) {
        return d.index;
      })
      .attr("height", function(d) {
        return height - yScale(d.value);
      })
      .attr("fill", function(d) {
        return colorScale(d.key);
      });

    bars = this.chartInner.selectAll("g.chartGroup").selectAll("rect.bar");

    bars
      .transition()
      .duration(tDefault)
      .attr("x", function(d) {
        return xScaleBars(d.key);
      })
      .attr("y", function(d) {
        return yScale(d.value);
      })
      .attr("width", xScaleBars.bandwidth())
      .attr("data-index", function(d) {
        return d.index;
      })
      .attr("height", function(d) {
        return height - yScale(d.value);
      })
      .attr("fill", function(d) {
        return colorScale(d.key);
      });
  };

  public drawAxis = (): void => {
    this.chartWrapper
      .selectAll(".x.axis")
      .attr("transform", `translate(0, ${this.height})`)
      .transition()
      .duration(this.tDefault)
      .call(this.xAxis);

    this.chartWrapper
      .selectAll(".y.axis")
      .attr("transform", "translate(0, 0)")
      .transition()
      .duration(this.tDefault)
      .call(this.yAxis);

    // Append axis titles
    this.chartWrapper
      .selectAll(".x.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.COLORS.gray_dark)
      .merge(this.chartWrapper.selectAll(".x.axis").selectAll(".axis_title"))
      .text(this.d3MainService.capitalize(this.xVal["name"]))
      .attr(
        "transform",
        `translate(${this.width / 2}, ${this.margin.bottom / 1.4})`
      );

    this.chartWrapper
      .selectAll(".y.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.COLORS.gray_dark)

      .merge(this.chartWrapper.selectAll(".y.axis").selectAll(".axis_title"))
      .text(this.yVal["name"])
      .attr(
        "transform",
        `translate(${-this.margin.left / 1.3}, ${this.height / 2}) rotate(-90)`
      );
  };

  public convertArrLengend = arr => {
    let arrL = arr.length;
    let i = 0;
    let arrLengend = [];

    for (i; i < arrL; i++) {
      arrLengend.push({
        value: arr[i],
        index: i,
        name: this.d3MainService.capitalize(arr[i])
      });
    }

    return arrLengend;
  };
  public drawChartLegend = (): void => {
    let tDefault = this.tDefault;
    let colorScale = this.colorScale;
    let getRealName = this.getRealName;

    let keys = this.convertArrLengend(this.xKeys);

    let key = this.chartLegend.selectAll("g.key").data(keys, (d, i) => {
      return d.index;
    });

    key
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(tDefault / 2)
      .style("opacity", 0)
      .remove();

    key
      .enter()
      .append("svg:g")
      .attr("class", "legend key")
      .attr("transform", (d, i) => {
        return "translate(" + (0 + (i * this.width) / keys.length) + ",0)";
      });

    this.chartLegend
      .selectAll(".key")
      .transition()
      .duration(tDefault)
      .style("opacity", 1);

    const keyAll = this.chartLegend.selectAll(".key");

    ///// SWATCHES
    keyAll
      .selectAll(".color")
      .data(function(d, i) {
        return [d];
      })
      .enter()
      .append("svg:rect")
      .attr("class", "color rect")
      .attr("x", 0)
      .attr("y", 2)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", function(d, i) {
        // console.log('colorsRect:::::', d);
        return colorScale(d.value);
      });

    ///// AND TEXTS
    keyAll
      .selectAll(".txt")
      .data(function(d, i) {
        return [d];
      })
      .enter()
      .append("svg:text")
      .attr("class", "txt")
      .attr("x", 12)
      .attr("y", 12)
      .attr("dy", "0em")
      .attr("dx", "0.1em")
      .text((d, i) => {
        return getRealName("wording", "short_title", d.value, "nombre"); //return the array data
      });

    keyAll.selectAll(".txt").text((d, i) => {
      return getRealName("wording", "short_title", d.value, "nombre"); //return the array data
    });
  };

  constructor(
    private d3MainService: D3MainService,
    private reshapeDataService: ReshapeDataService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let changeObj = Object.keys(changes);

    // service here
    this.COLORS = this.d3MainService.COLORS;
    this.getDimensions = this.d3MainService.getDimensions;
    this.formatRound = this.d3MainService.FORMATROUND;
    this.formatZero = this.d3MainService.FORMATZERO;
    this.tDefault = this.d3MainService.TIMESPAN;
    this.opacity_lowest = this.d3MainService.OPACITY_LOWEST;
    this.getRealName = this.d3MainService.getRealName;
    this.delay = this.d3MainService.RESIZE_DELAY;

    // params

    this.design = this.params.design;
    this.margin = this.params.design.margin;
    this.tooltip = this.params.tooltip;
    this.xVal = this.params.xVal;
    this.yVal = this.params.yVal;

    (this.units = this.params.units), (this.style = this.params.design.style);

    if (changeObj.length > 3) {
      // params and selector changes first load
      this.params["change"] = "first load";
      //this.init();
      return;
    }

    if (
      changeObj.length === 1 ||
      changeObj.length === 2 ||
      changeObj.length === 3
    ) {
      // selector changes
      this.params["change"] = "selector";
      this.reshapedata();
      return;
    }
  }

  ngOnInit() {
    console.log(this.params);
  }

  ngAfterViewInit() {
    this.init();
  }
}
