import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";

import { ParamsLayoutService } from "../../../../app-services/d3/params-layout.service";

@Component({
  selector: "app-trafico-segmentos",
  templateUrl: "./trafico-segmentos.component.html",
  styleUrls: ["./trafico-segmentos.component.scss"]
})
export class TraficoSegmentosComponent implements OnInit {
  @Input()
  traffic_segmentation_data: any;
  traffic_segmentation: Array<any>;
  traffic_segmentation_ready: boolean;

  selectedDistance: any;
  originOptions: any;
  destinationOptions: any;
  selectedOrigin: any;
  selectedDestination: any;
  selectedSegmentation: any;
  selectedPeriod: any;
  selectedMonth: any;
  selectedDay: any = null;
  dayOptions: any;
  displayMonths: boolean = false;
  displayDays: boolean = false;
  displayOrigin: boolean = false;
  displayDestination: boolean = false;

  constructor(private paramsLayoutService: ParamsLayoutService) { }

  changeDistance(event) {
    this.selectedDistance = event.key;
    this.originOptions = event.values;

    if (this.originOptions.filter(e => e.key === "Todas").length == 0) {
      this.originOptions.unshift({ key: "Todas", values: [] });
    }

    if (this.selectedDistance != "Todas") {
      this.displayOrigin = true;
      this.displayDestination = false;
    } else {
      this.displayOrigin = false;
      this.displayDestination = false;
    }
  }

  changeOrigin(event) {
    this.selectedOrigin = event;

    this.destinationOptions = this.originOptions.find(
      c => c.key == this.selectedOrigin
    ).values;

    if (this.selectedDistance != "Todas" && this.selectedOrigin == "Todas") {
      this.displayDestination = false;
    } else {
      this.displayDestination = true;
    }
  }
  changeDestination(event) {
    this.selectedDestination = event;
  }

  changeSegmentation(event) {
    this.selectedSegmentation = event;
  }

  changePeriod(event) {
    this.selectedPeriod = event;

    if (event === 'annual' || event === 'weeks' || event === 'months') {
      this.displayMonths = false;
      this.displayDays = false;
    }

    if (event === "days") {
      this.displayMonths = true;
      this.displayDays = false;
    }
  }
  changeMonth(event) {
    this.selectedMonth = event.key;
    this.dayOptions = event.values;
  }

  changeDay(event) {
    this.selectedDay = event;
  }

  ngOnInit() {
    this.traffic_segmentation = this.paramsLayoutService.getLayoutParams(
      this.traffic_segmentation_data,
      // for reuse data, you need to parse and stringify data
      // Another way to handle data: BehaviorSubject and observables
      // https://coryrylan.com/blog/angular-observable-data-services
      // JSON.parse(JSON.stringify(this.presence_segmentation_data)),
      "traffic_segmentation"
    );

    this.traffic_segmentation_ready = true;
  }
}
