import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { SidenavStudyComponent } from "./sidenav-study.component";
import { StudyFichaModule } from "./study-ficha/study-ficha.module";

@NgModule({
  declarations: [SidenavStudyComponent],
  imports: [SharedModule, StudyFichaModule],
  exports: [SidenavStudyComponent],
  providers: []
})
export class SidenavStudyModule {}
