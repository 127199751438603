import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-data-competencia",
  templateUrl: "./data-competencia.component.html",
  styleUrls: ["./data-competencia.component.scss"]
})
export class DataCompetenciaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
