import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { PresenciaSegmentosModule } from "./presencia-segmentos/presencia-segmentos.module";
import { PresenciaResidentesModule } from "./presencia-residentes/presencia-residentes.module";
import { ProgressBarModule } from "../../../app-widgets/indicators/progress-bar/progress-bar.module";

import { DataPresenciaComponent } from "./data-presencia.component";

@NgModule({
  declarations: [DataPresenciaComponent],
  imports: [
    SharedModule,
    PresenciaSegmentosModule,
    PresenciaResidentesModule,
    ProgressBarModule
  ],
  exports: [DataPresenciaComponent],
  providers: []
})
export class DataPresenciaModule {}
