import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";

import { AddDialogCommercialCenterBoxComponent } from "./add-dialog-commercial-center-box/add-dialog-commercial-center-box.component";

@Component({
  selector: "app-geo-add-commercial-center-table",
  templateUrl: "./geo-add-commercial-center-table.component.html",
  styleUrls: ["./geo-add-commercial-center-table.component.scss"]
})
export class GeoAddCommercialCenterTableComponent implements OnInit {
  displayedColumns: string[] = ["id_brand", "name", "address",/*"distance",*/ "action"];
  dataSource: any[] = [];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Output() commercialCenterAdded: EventEmitter<any> = new EventEmitter();
  id_brand: number = 0;

  constructor(public dialog: MatDialog) {}

  regExModelValue(val) {
    let result = val
      .replace(" ", "_")
      .replace(/\(|\)/g, "")
      .toLowerCase();
    return result;
  }

  

  upperFirstLetter( val ) {
    val = val.replaceAll("_", " ");
    return val.toLowerCase()
              .trim()
              .split(' ')
              .map( v => v[0].toUpperCase() + v.substr(1) )
              .join(' ');  
  }
  

  distanceModelValue(val){
    let result = val;
    switch (val) {
      case "minutes_foot_00_03":
        result = "0-3";
        break;
      case "minutes_foot_03_05":
        result = "3-5";
        break;
      case "minutes_foot_05_10":
        result = "5-10";
        break;
      case "minutes_foot_10_15":
        result = "10-15";
        break;
    }
    return result;
  }

  areaModelValue(val){
    let result = val;
    switch (val) {
      case "0-3":
        result = "minutes_foot_00_03";
        break;
      case "3-5":
        result = "minutes_foot_03_05";
        break;
      case "5-10":
        result = "minutes_foot_05_10";
        break;
      case "10-15":
        result = "minutes_foot_10_15";
        break;
    }
    return result;
  }

  createPropertiesInfoObj(ac, o) {
    o["action"] = ac;
    o["properties"] = o["properties"] ? o["properties"] : {};
    o["properties"]["info"] = o["properties"]["info"]
      ? o["properties"]["info"]
      : {};

    o["properties"]["info"].id_brand = o["properties"]["info"].id_brand
      ? o["properties"]["info"].id_brand
      : this.id_brand;
    o["properties"]["info"].name = o["properties"]["info"].name
      ? o["properties"]["info"].name
      : "Nuevo centro comercial";
    o["properties"]["info"].area = "minutes_foot_00_03";
    /*o["properties"]["info"].area
      ? this.distanceModelValue(o["properties"]["info"].area)
      : "minutes_foot_00_03"; //"0-3";
      */
      
    o["properties"]["info"].addressCtrl = o["properties"]["info"].addressCtrl
      ? o["properties"]["info"].addressCtrl
      : "";



      o["properties"]["info"].address = o["properties"]["info"].address
      ? o["properties"]["info"].address
      : "";
      o["properties"]["info"].address_view = o["properties"]["info"].address_view
      ? o["properties"]["info"].address_view
      : "";
      o["properties"]["info"].address_all = o["properties"]["info"].address_all
      ? o["properties"]["info"].address_all
      : "";
      o["properties"]["info"].city = o["properties"]["info"].city
      ? o["properties"]["info"].city
      : "";
      o["properties"]["info"].province = o["properties"]["info"].province
      ? o["properties"]["info"].province
      : "";
      o["properties"]["info"].y = o["properties"]["info"].y
      ? o["properties"]["info"].y
      : 0;
      o["properties"]["info"].x = o["properties"]["info"].x
      ? o["properties"]["info"].x
      : 0;
      

    o["properties"]["info"].type = o["properties"]["info"].type
      ? this.upperFirstLetter(o["properties"]["info"].type)
      : "Tipo";
    o["properties"]["info"].sba_total = o["properties"]["info"].sba_total
      ? o["properties"]["info"].sba_total
      : 0;
      o["properties"]["info"].sba_commerce = o["properties"]["info"].sba_commerce
      ? o["properties"]["info"].sba_commerce
      : 0;  
    o["properties"]["info"].cinemas = o["properties"]["info"].cinemas
      ? this.upperFirstLetter(o["properties"]["info"].cinemas)
      : "No";
    o["properties"]["info"].engine_type = o["properties"]["info"].engine_type
      ? this.upperFirstLetter(o["properties"]["info"].engine_type)
      : "No";
    o["properties"]["info"].engine_surface = o["properties"]["info"]
      .engine_surface
      ? o["properties"]["info"].engine_surface
      : 0;
    o["properties"]["info"].total_commerce = o["properties"]["info"]
      .total_commerce
      ? o["properties"]["info"].total_commerce
      : 0;
    o["properties"]["info"].sba_power_supply = o["properties"]["info"]
      .sba_power_supply
      ? o["properties"]["info"].sba_power_supply
      : 0;
    o["properties"]["info"].sba_fashion_complement = o["properties"]["info"]
      .sba_fashion_complement
      ? o["properties"]["info"].sba_fashion_complement
      : 0;
    o["properties"]["info"].sba_home_brico_electronic = o["properties"]["info"]
      .sba_home_brico_electronic
      ? o["properties"]["info"].sba_home_brico_electronic
      : 0;
    o["properties"]["info"].sba_services = o["properties"]["info"].sba_services
      ? o["properties"]["info"].sba_services
      : 0;
    o["properties"]["info"].sba_various = o["properties"]["info"].sba_various
      ? o["properties"]["info"].sba_various
      : 0;
    o["properties"]["info"].sba_rest_leisure = o["properties"]["info"]
      .sba_rest_leisure
      ? o["properties"]["info"].sba_rest_leisure
      : 0;
    //new params:
      o["properties"]["info"].exterior_shops = o["properties"]["info"].exterior_shops
      ? o["properties"]["info"].exterior_shops
      : 0;
      o["properties"]["info"].interior_shops = o["properties"]["info"].interior_shops
      ? o["properties"]["info"].interior_shops
      : 0;
      o["properties"]["info"].main_commerce = o["properties"]["info"].main_commerce
      ? o["properties"]["info"].main_commerce
      : "";
      o["properties"]["info"].main_commerce_surface = o["properties"]["info"].main_commerce_surface
      ? o["properties"]["info"].main_commerce_surface
      : 0;
      o["properties"]["info"].total_parking_spaces = o["properties"]["info"].total_parking_spaces
      ? o["properties"]["info"].total_parking_spaces
      : 0;
      o["properties"]["info"].engine = o["properties"]["info"].engine
      ? o["properties"]["info"].engine
      : "";
      o["properties"]["info"].transport_car = o["properties"]["info"].transport_car
      ? o["properties"]["info"].transport_car
      : 0;
      o["properties"]["info"].transport_foot = o["properties"]["info"].transport_foot
      ? o["properties"]["info"].transport_foot
      : 0;
      o["properties"]["info"].transport_public = o["properties"]["info"].transport_public
      ? o["properties"]["info"].transport_public
      : 0;

      
    // first pass oect is empty
    let model: any;
    if (o.properties.info.model) {
      model = {
        id_brand: o.properties.info.model.id_brand,
        name: o.properties.info.model.name,
       // addressCtrl: o.properties.info.model.addressCtrl,
/*
        address: o.properties.info.model.address,
        address_view: o.properties.info.model.address_view,
        address_all: o.properties.info.model.address_all,
        city: o.properties.info.model.city,
        province: o.properties.info.model.province,
        y: o.properties.info.model.y,
        x: o.properties.info.model.x,
*/
        addressCtrl: o.addressCtrl,
        address: o.addressCtrl,
        address_view: o.addressTotal,
        address_all: o.addressTotal,
        city: o.city,
        province: o.province,
        y: o.lat,
        x: o.long,


        area: "minutes_foot_00_03", //this.areaModelValue(o.properties.info.model.area),
        type: this.regExModelValue(o.properties.info.model.type),
        sba_total: o.properties.info.model.sba_total,
        sba_commerce: o.properties.info.model.sba_commerce,
        cinemas: this.regExModelValue(o.properties.info.model.cinemas),
        engine_type: this.regExModelValue(o.properties.info.model.engine_type),
        engine_surface: o.properties.info.model.engine_surface,
        total_commerce: o.properties.info.model.total_commerce,
        sba_power_supply: o.properties.info.model.sba_power_supply,
        sba_fashion_complement: o.properties.info.model.sba_fashion_complement,
        sba_home_brico_electronic:
          o.properties.info.model.sba_home_brico_electronic,
        sba_services: o.properties.info.model.sba_services,
        sba_various: o.properties.info.model.sba_various,
        sba_rest_leisure: o.properties.info.model.sba_rest_leisure,
        //new params:
        exterior_shops: o.properties.info.model.exterior_shops,
        interior_shops: o.properties.info.model.interior_shops,
        main_commerce: o.properties.info.model.main_commerce,
        main_commerce_surface: o.properties.info.model.main_commerce_surface,
        total_parking_spaces: o.properties.info.model.total_parking_spaces,
        engine: o.properties.info.model.engine,
        transport_car: o.properties.info.model.transport_car,
        transport_foot: o.properties.info.model.transport_foot,
        transport_public: o.properties.info.model.transport_public,
        
      };
    } else {
      model = {
        id_brand: o.properties.info.id_brand,
        name: o.properties.info.name,
        area: "minutes_foot_00_03", //this.areaModelValue(o.properties.info.area),
        addressCtrl: o.addressCtrl,

        address: o.addressCtrl,
        address_view: o.addressTotal,
        address_all: o.addressTotal,
        city: o.city,
        province: o.province,
        y: o.lat,
        x: o.long,


        type: this.regExModelValue(o.properties.info.type),
        sba_total: o.properties.info.sba_total,
        sba_commerce: o.properties.info.sba_commerce,
        cinemas: this.regExModelValue(o.properties.info.cinemas),
        engine_type: this.regExModelValue(o.properties.info.engine_type),
        engine_surface: o.properties.info.engine_surface,
        total_commerce: o.properties.info.total_commerce,
        sba_power_supply: o.properties.info.sba_power_supply,
        sba_fashion_complement: o.properties.info.sba_fashion_complement,
        sba_home_brico_electronic: o.properties.info.sba_home_brico_electronic,
        sba_services: o.properties.info.sba_services,
        sba_various: o.properties.info.sba_various,
        sba_rest_leisure: o.properties.info.sba_rest_leisure,
        //new params:
        exterior_shops: o.properties.info.exterior_shops,
        interior_shops: o.properties.info.interior_shops,
        main_commerce: o.properties.info.main_commerce,
        main_commerce_surface: o.properties.info.main_commerce_surface,
        total_parking_spaces: o.properties.info.total_parking_spaces,
        engine: o.properties.info.engine,
        transport_car: o.properties.info.transport_car,
        transport_foot: o.properties.info.transport_foot,
        transport_public: o.properties.info.transport_public,
      };
    }

    o["properties"]["info"]["model"] = o["model"] ? o["model"] : model;

    return o;
  }
  openDialog(action, center) {
    let obj = this.createPropertiesInfoObj(action, center);

    const dialogRef = this.dialog.open(AddDialogCommercialCenterBoxComponent, {
      width: "60%",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Añadir") {
        this.addRowData("Añadir", result.data);
        this.updateRowData(result.data);
      } else if (result.event == "Editar") {
        this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        this.deleteRowData(result.data);
      }
      this.id_brand += 1;
    });
  }

  addRowData(action, row_obj) {
    var d = new Date();
    let obj = this.createPropertiesInfoObj(action, row_obj); 
    this.dataSource.push(obj);
    this.commercialCenterAdded.emit(this.dataSource);
    this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.properties.info.id_brand == row_obj.properties.info.id_brand) {
        if (row_obj.model) {
          value.properties.info.id_brand = row_obj.properties.info.id_brand;
          value.properties.info.name = row_obj.model.name;
          value.properties.info.area = "minutes_foot_00_03"; //row_obj.model.area;
          value.properties.info.addressCtrl = row_obj.model.addressCtrl; 

          value.properties.info.address = row_obj.model.addressCtrl;
          value.properties.info.address_view = row_obj.model.addressCtrl;
          value.properties.info.address_all = row_obj.model.addressTotal;
          value.properties.info.city = row_obj.model.city;
          value.properties.info.province = row_obj.model.province;
          value.properties.info.y = row_obj.model.lat;
          value.properties.info.x = row_obj.model.long;

          value.addressCtrl = row_obj.addressCtrl;
          value.addressTotal = row_obj.addressTotal;
          value.city = row_obj.city;
          value.lat = row_obj.lat;
          value.long = row_obj.long;
          value.province = row_obj.province;

          value.properties.info.type = row_obj.model.type;
          value.properties.info.sba_total = row_obj.model.sba_total;
          value.properties.info.sba_commerce = row_obj.model.sba_commerce;
          value.properties.info.cinemas = row_obj.model.cinemas;
          value.properties.info.engine_type = row_obj.model.engine_type;
          value.properties.info.engine_surface = row_obj.model.engine_surface;
          value.properties.info.total_commerce = row_obj.model.total_commerce;
          value.properties.info.sba_power_supply =
            row_obj.model.sba_power_supply;
          value.properties.info.sba_fashion_complement =
            row_obj.model.sba_fashion_complement;
          value.properties.info.sba_home_brico_electronic =
            row_obj.model.sba_home_brico_electronic;
          value.properties.info.sba_services = row_obj.model.sba_services;
          value.properties.info.sba_various = row_obj.model.sba_various;
          value.properties.info.sba_rest_leisure =
            row_obj.model.sba_rest_leisure;          
          //new params:
          value.properties.info.exterior_shops = row_obj.model.exterior_shops;
          value.properties.info.interior_shops = row_obj.model.interior_shops;
          value.properties.info.main_commerce = row_obj.model.main_commerce;
          value.properties.info.main_commerce_surface = row_obj.model.main_commerce_surface;
          value.properties.info.total_parking_spaces = row_obj.model.total_parking_spaces;
          value.properties.info.engine = row_obj.model.engine;
          value.properties.info.transport_car = row_obj.model.transport_car;
          value.properties.info.transport_foot = row_obj.model.transport_foot;
          value.properties.info.transport_public = row_obj.model.transport_public;
        } else {
          value.properties.info.id_brand = row_obj.properties.info.id_brand;
          value.properties.info.name = row_obj.properties.info.model.name;
          value.properties.info.area = "minutes_foot_00_03"; //row_obj.properties.info.model.area;
          value.properties.info.addressCtrl = row_obj.addressCtrl;

          value.properties.info.address = row_obj.addressCtrl;
          value.properties.info.address_view = row_obj.addressCtrl;
          value.properties.info.address_all = row_obj.addressTotal;
          value.properties.info.city = row_obj.city;
          value.properties.info.province = row_obj.province;
          value.properties.info.y = row_obj.lat;
          value.properties.info.x = row_obj.long;

          value.addressCtrl = row_obj.addressCtrl;
          value.addressTotal = row_obj.addressTotal;
          value.city = row_obj.city;
          value.lat = row_obj.lat;
          value.long = row_obj.long;
          value.province = row_obj.province;

          value.properties.info.type = row_obj.properties.info.model.type;
          value.properties.info.sba_total =
            row_obj.properties.info.model.sba_total;
          value.properties.info.sba_commerce =
            row_obj.properties.info.model.sba_commerce;
          value.properties.info.cinemas = row_obj.properties.info.model.cinemas;
          value.properties.info.engine_type =
            row_obj.properties.info.model.engine_type;
          value.properties.info.engine_surface =
            row_obj.properties.info.model.engine_surface;
          value.properties.info.total_commerce =
            row_obj.properties.info.model.total_commerce;
          value.properties.info.sba_power_supply =
            row_obj.properties.info.model.sba_power_supply;
          value.properties.info.sba_fashion_complement =
            row_obj.properties.info.model.sba_fashion_complement;
          value.properties.info.sba_home_brico_electronic =
            row_obj.properties.info.model.sba_home_brico_electronic;
          value.properties.info.sba_services =
            row_obj.properties.info.model.sba_services;
          value.properties.info.sba_various =
            row_obj.properties.info.model.sba_various;
          value.properties.info.sba_rest_leisure =
            row_obj.properties.info.model.sba_rest_leisure;
          //new params:
          value.properties.info.exterior_shops = row_obj.properties.info.exterior_shops;
          value.properties.info.interior_shops = row_obj.properties.info.interior_shops;
          value.properties.info.main_commerce = row_obj.properties.info.main_commerce;
          value.properties.info.main_commerce_surface = row_obj.properties.info.main_commerce_surface;
          value.properties.info.total_parking_spaces = row_obj.properties.info.total_parking_spaces;
          value.properties.info.engine = row_obj.properties.info.engine;
          value.properties.info.transport_car = row_obj.properties.info.transport_car;
          value.properties.info.transport_foot = row_obj.properties.info.transport_foot;
          value.properties.info.transport_public = row_obj.properties.info.transport_public;
        }
      }
      return true;
    });
    this.commercialCenterAdded.emit(this.dataSource);
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.properties.info.id_brand != row_obj.properties.info.id_brand;
    });
    this.commercialCenterAdded.emit(this.dataSource);
  }

  ngOnInit() {}
}
