import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";
import { Observable, of } from "rxjs";
import { DataService } from "src/app/app-services/data.service";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";

@Injectable({
  providedIn: "root"
})
export class GeoNewFormResolverService implements Resolve<any> {
  constructor(
    private dataService: DataService,
    private geoStudiesDataService: GeoStudiesDataService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let brickID = route.paramMap.get("id");
    if (brickID) {
      // this.dataService.getBrickById(brickID).subscribe(d => {
      //   console.log(d);
      //   return d;
      // });
      return this.dataService.getBrickById(brickID);
    } else {
      let idStudy = route.paramMap.get("idStudy");
      return this.geoStudiesDataService.getBasicInfoStudy(idStudy);
    }
  }
}
