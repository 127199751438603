import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { Optional } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

// dummy data
import sampleBrands from "../../../../../assets/data/brands_data.json";
import { DataService } from "../../../../../app/app-services/data.service";
import { MatDialog } from "@angular/material/dialog";
import { AddDialogBoxCommercesLocatorComponent } from "./add-dialog-box-commerces-locator/add-dialog-box-commerces-locator.component";

export interface UsersData {
  id: number;
  category: string;
  commerce: string;
  num: number;
  address: string;
}

@Component({
  selector: "app-add-dialog-box-commerce",
  templateUrl: "./add-dialog-box-commerce.component.html",
  styleUrls: ["./add-dialog-box-commerce.component.scss"]
})
export class AddDialogBoxCommerceComponent implements OnInit, OnDestroy {
  action: string;
  local_data: any;
  sampleBrands: Array<any>; // = sampleBrands;
  sampleCategories: Array<any>;
  unsubscribe: any;
  unsubscribe2: any;

  disabledButton: boolean = true;
  //
  constructor(
    public dialogRef: MatDialogRef<AddDialogBoxCommerceComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData,
    private dataService: DataService,
    public dialog: MatDialog
  ) {
    this.local_data = { ...data };
    this.local_data.addressCtrl = data.address;
    if (this.local_data.addressCtrl) {
      this.disabledButton = false;
    }

    if (this.local_data.commerce) {
      this.filterBrand(this.local_data.category)

    }

    this.action = this.local_data.action;
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancelar" });
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogBoxCommercesLocatorComponent, {
      width: "750px",
      height: "",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      position: { top: "40px" },
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.local_data = result;
      if (result.event == "Añadir dirección") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        // this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        //  this.deleteRowData(result.data);
      }


    });
  }

  addRowData(row_obj) {
    // this.local_data = row_obj;
    this.local_data.addressCtrl = row_obj.addressCtrl;
    this.local_data.addressTotal = row_obj.addressCtrl + ', ' + row_obj.cityCtrl + ', ' + row_obj.provinceCtrl;
    this.local_data.city = row_obj.cityCtrl;
    this.local_data.province = row_obj.provinceCtrl;
    this.local_data.long = row_obj.lonCtrl;
    this.local_data.lat = row_obj.latCtrl;

    if (this.local_data.addressCtrl) {
      this.disabledButton = false;
    }

  }

  filterBrand(filterValue: any) {  
      this.unsubscribe = this.dataService
      .getBrandsByCategoriesCommerce(filterValue)
      .subscribe((result: any) => {
        this.sampleCategories = result;
        if(filterValue=="Alimentación"){
          this.sampleCategories.push("Otro Supermercado");
          this.sampleCategories.push("Otro Hipermercado");
        }else{
          this.sampleCategories.push("Otro "+filterValue);
        }        
        if (this.local_data.commerce) {
          var ee = result.map((currentValue, index) => {
            let data_b = {};
            data_b["id"] = index;
            data_b["value"] = currentValue;
            return data_b;
          });
        }
      });
  }

  ngOnInit() {
    //function brand just important (property mongodb show_brand = true)
    this.unsubscribe2 = this.dataService
      .getBrandsCategoriesCommerce()
      .subscribe((result: any) => {
        result = result.map(function (e) {
          return e;
        });
        this.sampleBrands = result;
      });
  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
