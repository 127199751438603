import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { UlSitesComponent } from "./ul-sites.component";

import { TabCompetenciaModule } from "../tab-competencia/tab-competencia.module";
import { TabRestaurantsModule } from "../tab-restaurants/tab-restaurants.module";
import { TabComerciosModule } from "../tab-comercios/tab-comercios.module";
import { TabStudiesModule } from "../tab-studies/tab-studies.module";
import { TabBricksModule } from "../tab-bricks/tab-bricks.module";
import { TabCentrosComercialesModule } from "../tab-centros-comerciales/tab-centros-comerciales.module";
import { TabStudiesPrescModule } from "../tab-studies-presc/tab-studies-presc.module";
@NgModule({
  imports: [
    SharedModule,
    TabBricksModule,
    TabRestaurantsModule,
    TabComerciosModule,
    TabCompetenciaModule,
    TabStudiesModule,
    TabCentrosComercialesModule,
    TabStudiesPrescModule
  ],
  exports: [UlSitesComponent],
  declarations: [UlSitesComponent],
  providers: []
})
export class UlSitesModule {}
