<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">TRÁFICO MEDIO DIARIO POR SEGMENTACIÓN</h2>

      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="traffic_segmentation" widget_num="0" [emitFirst]="true"
        (eventEmitter)="changeDistance($event)" *ngIf="traffic_segmentation_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="traffic_segmentation" [childrenOptions]="originOptions"
        widget_num="1" [emitFirst]="true" (eventEmitter)="changeOrigin($event)"
        *ngIf="traffic_segmentation_ready && displayOrigin">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="traffic_segmentation"
        [childrenOptions]="destinationOptions" widget_num="2" [emitFirst]="true"
        (eventEmitter)="changeDestination($event)" *ngIf="traffic_segmentation_ready && displayDestination">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="traffic_segmentation" widget_num="3" [emitFirst]="true"
        (eventEmitter)="changeSegmentation($event)" *ngIf="traffic_segmentation_ready">
      </app-options-from-json>

      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="traffic_segmentation" widget_num="4" [emitFirst]="true"
        (eventEmitter)="changePeriod($event)" *ngIf="traffic_segmentation_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-1 col-lg-1">
      <app-options-from-json [ngClass]="{'hidden': this.displayMonths === false}" style="width: 100%;"
        [params]="traffic_segmentation" widget_num="5" [emitFirst]="true" (eventEmitter)="changeMonth($event)"
        *ngIf="traffic_segmentation_ready">
      </app-options-from-json>

      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-1 col-lg-1">
      <app-options-from-parent [ngClass]="{'hidden': this.displayDays === false}" style="width: 100%;"
        [params]="traffic_segmentation" [childrenOptions]="dayOptions" widget_num="6" [emitFirst]="true"
        (eventEmitter)="changeDay($event)" *ngIf="traffic_segmentation_ready">
      </app-options-from-parent>

      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <app-grouped-bar style="width: 100%;" [params]="traffic_segmentation" [categorySelectValue]="selectedSegmentation"
        [firstFilterValue]="selectedDistance" [secondFilterValue]="selectedOrigin"
        [thirdFilterValue]="selectedDestination" [fourFilterValue]="selectedPeriod" [fifthFilterValue]="selectedMonth"
        [sixthFilterValue]="selectedDay" chartId="traffic_segmentation" chartSize="regular"
        *ngIf="traffic_segmentation_ready"></app-grouped-bar>

      <div class="margin-top--s"></div>
    </div>
  </div>
</section>