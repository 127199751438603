<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

        <h1 class="h1-page--title">
          Estudios de predicción de ventas
        </h1>
        <p>Proyectos para nuevas aperturas McDonald's.</p>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
<!--        <div class="form-button">-->
<!--          <button mat-raised-button color="primary" class="button&#45;&#45;m" routerLink="/predictive/studies">-->
<!--            Refrescar listado-->
<!--          </button>-->
<!--        </div>-->
      </div>


      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
        <div class="form-button">
          <button mat-raised-button color="primary" class="button--m" routerLink="/predictive/new-form">
            Nuevo estudio
          </button>

        </div>
      </div>
    </div>

    <div class="section-container margin-top--l">
      <div class="row">
        <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">


          <app-geo-studies-table></app-geo-studies-table>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="margin-top--xl"></div>