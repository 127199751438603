import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";

import { MatTableDataSource, MatTable } from "@angular/material/table";
@Component({
  selector: "app-geo-remove-competitors-others-table",
  templateUrl: "./app-geo-remove-competitors-others-table.component.html",
  styleUrls: ["./app-geo-remove-competitors-others-table.component.scss"]
})
export class AppGeoRemoveCompetitorsOthersTableComponent implements OnInit {
  constructor() {}

  @Input()
  inputCompetitorOth: string;
  @Output() competitorOthEdited: EventEmitter<any> = new EventEmitter();

  data: any;
  dataSource: any;
  selection: any;
  image_route = "../../../../assets/img/marker_icons/markers/";
  displayedColumns: string[] = ["name", "number"/*, "edited"*/];
  checkedCompetitors: Array<any>;
  Competitors: any;
  onDeleteCompetitorOth(ev: any, element: any) {
    element.num = element.num - ev.target.value;
    this.competitorOthEdited.emit(element);
  }

  onAddCompetitorOth(ev: any, element: any) {
    element.num = element.num + ev.target.value;
    this.competitorOthEdited.emit(element);
  }

  onEditedCompetitorOth(ev: any, element: any) {
    element.num_mod = ev.target.value;
    this.dataSource;
    this.competitorOthEdited.emit(element);
  }

  ngOnInit() {
    this.data = this.inputCompetitorOth;
    this.dataSource = new MatTableDataSource<any>(this.data);
  }

  ngOnChanges() {
    this.data = this.inputCompetitorOth;
    this.dataSource = new MatTableDataSource<any>(this.data);
  }
}
