import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, of, forkJoin, from, Subject } from "rxjs";
import { map, concatMap } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class ImageService {
  private brandsSubject$ = new Subject<any>();
  brandsChanged$ = this.brandsSubject$.asObservable();
  host: string = environment.imgUrl;

  brandIconExists(brand: any): any {
    let u: string = this.host + "/markers/" + brand.id_brand + ".svg";

    return fetch(u, {})
      .then(res => {
        brand.imageUrl = this.host + "/helpers/joker.svg";
        if (res.status == 200) {
          brand.imageUrl = u;
        }
        return brand;
      })
      .catch(err => {
        return false;
      });
  }

  getSomethingFromAnAPI(brands: any[]): Observable<any> {
    return from(brands).pipe(
      concatMap(brand => {
        const observable = from(this.brandIconExists(brand));
        return observable;
      })
    );
  }

  dictionaryImageExists(brands: any[]) {
    let data = [];
    return this.getSomethingFromAnAPI(brands).subscribe(
      response => {
        data.push(response);
        if (data.length == brands.length) {
          this.brandsSubject$.next(true);
        }
      },
      error => {
        console.error(error);
      }
    );
  }
}
