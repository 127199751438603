import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { EditDialogPartialPredictiveStudyComponent } from "./edit-dialog-partial-predictive-study.component";

@NgModule({
    declarations: [EditDialogPartialPredictiveStudyComponent],
    imports: [SharedModule],
    exports: [EditDialogPartialPredictiveStudyComponent],
    providers: []
})
export class EditDialogPartialPredictiveStudyModule {}
