import { Component, OnInit, Input, OnDestroy } from "@angular/core";

import { SharedModule } from "../../../app-modules/app-shared.module";
import { DataService } from "src/app/app-services/data.service";

@Component({
  selector: "app-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.scss"]
})
export class ExpansionPanelComponent implements OnInit, OnDestroy {
  @Input()
  panel_num: string;

  burger_kings: any;
  burger_king_destacado: any;
  bk1_dist: any;
  bk2_dist: any;
  bk3_dist: any;
  bk_dest_dist: any;

  panelOpenState: boolean = false;

  dataSource: Array<any>;
  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;
  unsubscribe4: any;
  unsubscribe5: any;

  private getExpansionPanelData = () => {
    let d: Array<any>;
    switch (this.panel_num) {
      case "1":
        d = this.burger_kings;
        break;
      case "2":
        d = this.burger_king_destacado;
        break;
      default:
        d = [{}];
    }
    return d;
  };

  constructor(private dataService: DataService) {}

  ngOnInit() {
    const _this = this;
    //search BKs:
    this.burger_kings = [];
    this.burger_king_destacado = [];
    let idPlace = this.dataService.getSelectedRestaurant();
    this.unsubscribe = this.dataService.getPlacesIdMcd(idPlace).subscribe(d => {
      let mcd_bk_distance = d.properties.info.mcd_bk_distance;
      //con el idBK buscamos los datos del BK:
      this.bk1_dist = mcd_bk_distance.distance_bk1;
      this.bk2_dist = mcd_bk_distance.distance_bk2;
      this.bk3_dist = mcd_bk_distance.distance_bk3;
      _this.unsubscribe2 = _this.dataService
        .getPlacesId(mcd_bk_distance.id_bk1)
        .subscribe(dbk1 => {
          this.burger_kings.push(dbk1.properties.info);
          this.burger_kings[0].dist_meters_mcd_nearby = this.bk1_dist;

          _this.unsubscribe3 = _this.dataService
        .getPlacesId(mcd_bk_distance.id_bk2)
        .subscribe(dbk2 => {
          this.burger_kings.push(dbk2.properties.info);
          this.burger_kings[1].dist_meters_mcd_nearby = this.bk2_dist;

          _this.unsubscribe4 = _this.dataService
        .getPlacesId(mcd_bk_distance.id_bk3)
        .subscribe(dbk3 => {
          this.burger_kings.push(dbk3.properties.info);
          this.burger_kings[2].dist_meters_mcd_nearby = this.bk3_dist;
        });
        

        });


        });
      
      

      this.bk_dest_dist = mcd_bk_distance.distance_bk_nearby;

      _this.unsubscribe5 = _this.dataService
        .getPlacesId(mcd_bk_distance.id_bk_nearby)
        .subscribe(dbk_nearby => {
          this.burger_king_destacado.push(dbk_nearby.properties.info);
          this.burger_king_destacado[0].dist_meters_mcd_nearby = this.bk_dest_dist;
        });
    });

    this.dataSource = this.getExpansionPanelData();
  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
    if (this.unsubscribe4) {
      this.unsubscribe4.unsubscribe();
    }
    if (this.unsubscribe5) {
      this.unsubscribe5.unsubscribe();
    }
  }
}
