<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-8  col-lg-8">
        <h1 class="h1-page--title">
          {{ title }}
        </h1>
        <p>{{ paragraph }}</p>
      </div>
      <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2">
        <div class="form-button">
          <button mat-raised-button color="primary" class="button--m" routerLink="/prescriptive/studies">
            Refrescar listado
          </button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2">
        <div class="form-button">
          <button *ngIf="userAuthorized" mat-raised-button color="primary" class="button--m"
            routerLink="/prescriptive/new-form">
            Nuevo estudio
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">

        <div class="margin-top--xl"></div>

        <app-brick-studies-table></app-brick-studies-table>
      </div>
    </div>
  </div>
</section>
<div class="margin-top--xl"></div>