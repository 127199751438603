import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";
import { OptionsFromJsonModule } from "../../../../app-widgets/form-controls/options-from-json/options-from-json.module";
import { OptionsFromParentModule } from "../../../../app-widgets/form-controls/options-from-parent/options-from-parent.module";
import { StackedBarModule } from "../../../../app-widgets/charts/stacked-bar/stacked-bar.module";
import { TreemapModule } from "../../../../app-widgets/charts/treemap/treemap.module";
import { PresenciaResidentesComponent } from "./presencia-residentes.component";

@NgModule({
  declarations: [PresenciaResidentesComponent],
  imports: [
    SharedModule,
    OptionsFromJsonModule,
    OptionsFromParentModule,
    StackedBarModule,
    TreemapModule
  ],
  exports: [PresenciaResidentesComponent],
  providers: []
})
export class PresenciaResidentesModule {}
