import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { ServicesIconsComponent } from "./services-icons.component";

@NgModule({
  declarations: [ServicesIconsComponent],
  imports: [SharedModule],
  exports: [ServicesIconsComponent],
  providers: []
})
export class ServicesIconsModule {}
