import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { GeoStudiesDataService } from "../../../app-services/geo-studies-data.service";
import { Todo } from "../../../app-services/geo-studies-data.service";
import { map } from "rxjs/operators";
import { DataService } from "src/app/app-services/data.service";

@Component({
  selector: "app-sidenav-study",
  templateUrl: "./sidenav-study.component.html",
  styleUrls: ["./sidenav-study.component.scss"]
})
export class SidenavStudyComponent implements OnInit, OnDestroy {
  ready: boolean = false;

  todos: Observable<Todo[]>;
  studiesSub: any;
  studiesData: any;
  studyData: any;
  studyDataVoid: any;
  unsubscribe: any;
  unsubscribe2: any;

  constructor(
    private geoStudiesDataService: GeoStudiesDataService,
    private dataService: DataService
  ) {
    this.studyDataVoid = this.studyDataVoid = {};
    this.studyDataVoid.accessibility = "";
    this.studyDataVoid.address = "";
    this.studyDataVoid.auto_king = "";
    this.studyDataVoid.autonomous_region = "";
    this.studyDataVoid.brand = "";
    this.studyDataVoid.breakfast = "";
    this.studyDataVoid.bunit = "";
    this.studyDataVoid.comments = "";
    this.studyDataVoid.country = "";
    this.studyDataVoid.data_parking = "";
    this.studyDataVoid.email = "";
    this.studyDataVoid.express = "";
    this.studyDataVoid.food_court = "";
    this.studyDataVoid.franchised = "";
    this.studyDataVoid.home_delivery = "";
    this.studyDataVoid.id_brand = null;
    this.studyDataVoid.kind_building = "";
    this.studyDataVoid.local_name = "";
    this.studyDataVoid.mall = "";
    this.studyDataVoid.mcauto = "";
    this.studyDataVoid.mccafe = "";
    this.studyDataVoid.mcexpress = "";
    this.studyDataVoid.mckiosko = "";
    this.studyDataVoid.name = "";
    this.studyDataVoid.name_mcd_nearby = "";
    this.studyDataVoid.openning_date = "";
    this.studyDataVoid.own_parking = "";
    this.studyDataVoid.province = "";
    this.studyDataVoid.seasonal = "";
    this.studyDataVoid.seat_room = 0;
    this.studyDataVoid.seat_terrace_indoor = 0;
    this.studyDataVoid.seat_terrace_outward = 0;
    this.studyDataVoid.separate_entrance_mall = "";
    this.studyDataVoid.status = "";
    this.studyDataVoid.tel_abr = null;
    this.studyDataVoid.telephone = "";
    this.studyDataVoid.town = "";
    this.studyDataVoid.type_licence = "";
    this.studyDataVoid.type_local = "";
    this.studyDataVoid.type_play = "";
    this.studyDataVoid.visibility = "";
    this.studyDataVoid.zip_code = "";

    this.studyDataVoid.properties = {};
    this.studyDataVoid.properties.data_model = {};
    this.studyDataVoid.properties.info = {};
    this.studyDataVoid.properties.info.mccafe = false;
    this.studyDataVoid.properties.info.mcauto = false;
    this.studyDataVoid.properties.info.mcexpress = false;
    this.studyDataVoid.properties.info.mckiosko = false;
    this.studyDataVoid.properties.info.breakfast = false;
    this.studyDataVoid.properties.info.type_play = false;
    this.studyDataVoid.properties.info.data_parking = false;

    this.studyDataVoid.properties.study = {};

    this.studiesSub = this.geoStudiesDataService.todos.subscribe(d => {
      if (d.length > 0) {
        this.studiesData = d;
        this.studyData = this.studyDataVoid;
        this.ready = false;
      }
    });

    this.unsubscribe = this.dataService.studySelectedPredictiveChanged$.subscribe(
      id => {
        this.studyData = this.studyDataVoid;
        if (id !== undefined) {
          this.unsubscribe2 = this.geoStudiesDataService
            .getBasicInfoStudy(id)
            .subscribe(d => {
              this.studyData = d;
              this.ready = true;
            });
        }
      }
    );
  }

  ngOnInit() {
    this.todos = this.geoStudiesDataService.todos;

    this.geoStudiesDataService.loadAll();
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.studiesSub) {
      this.studiesSub.unsubscribe();
    }
  }
}
