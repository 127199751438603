import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-brick-section-zero',
  templateUrl: './brick-section-zero.component.html',
  styleUrls: ['./brick-section-zero.component.scss']
})
export class BrickSectionZeroComponent implements OnInit {
  @Input() setup: any;
  sectionID: number;

  selectedOrder: string;
  constructor() { }

  ngOnInit(): void {
    this.sectionID = this.setup.id;
    

  }

}
