import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabRestaurantsComponent } from "./tab-restaurants.component";

@NgModule({
  declarations: [TabRestaurantsComponent],
  imports: [SharedModule],
  exports: [TabRestaurantsComponent],
  providers: []
})
export class TabRestaurantsModule {}
