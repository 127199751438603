import { Component, OnChanges } from "@angular/core";
import { OnInit } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";

// table competencia
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";

import { environment } from "src/environments/environment";

@Component({
  selector: "app-geo-remove-mcd-table",
  templateUrl: "./geo-remove-mcd-table.component.html",
  styleUrls: ["./geo-remove-mcd-table.component.scss"]
})
export class GeoRemoveMcdTableComponent
  implements OnChanges /*OnInit*/ {
  // table competencia case B

  @Input()
  inputData: string;

  @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  data: any;
  dataSource: any;
  selection: any;

  image_route = "../../../../assets/img/marker_icons/markers/";

  displayedColumns: string[] = ["select", "name","status", "address", "distance_tostudy"]; //, "area"

  checkedCompetitors: Array<any>;
  Competitors: any;

  // selectedEle = this.selection.selected;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  isSomeSelected() {
    this.eventEmitter.emit(this.selection.selected);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));

    this.eventEmitter.emit(this.selection.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
  }


  checkUrl = (url: any) => {
    try {
      let reader = new XMLHttpRequest();
      reader.open("GET", url, false);
      reader.send();
      if (reader.status == 404) {
        return false;
      } else {
        return true;
      }
    } catch (error) {}
  };

  constructor() {}

  ngOnInit() {
    this.data = this.inputData;
    
    if(this.data){
      this.data = this.data.filter(dt => dt.status != "Plan 5 años");
      this.data.forEach(element => {
        
        let urlIcon = this.image_route + element.id_brand ;
        
        var checkedurl = this.checkUrl(urlIcon + ".svg");
              if (!checkedurl) {
                urlIcon = environment.imgUrl + "/helpers/joker";
              }
        element.url_icono = urlIcon + ".svg";
        if(element.status == "Plan 5 años"){
          urlIcon = environment.imgUrl + "/helpers/4772-circle-darkBlue.svg"; 
          element.url_icono = urlIcon;
        }
        if(element.status == "Próxima apertura"){
          urlIcon = environment.imgUrl + "/helpers/4772-circle-purple.svg"; 
          element.url_icono = urlIcon;
        } 
         
      });
    }
    
    
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }
  
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.data = this.inputData;
    
    if(this.data){
      this.data = this.data.filter(dt => dt.status != "Plan 5 años");
      this.data.forEach(element => {
        let urlIcon = this.image_route + element.id_brand ;
        var checkedurl = this.checkUrl(urlIcon + ".svg");
              if (!checkedurl) {
                urlIcon = environment.imgUrl + "/helpers/joker";
              }
        element.url_icono = urlIcon + ".svg"; 
        if(element.status == "Plan 5 años"){
          urlIcon = environment.imgUrl + "/helpers/4772-circle-darkBlue.svg"; 
          element.url_icono = urlIcon;
        }
        if(element.status == "Próxima apertura"){
          urlIcon = environment.imgUrl + "/helpers/4772-circle-purple.svg"; 
          element.url_icono = urlIcon;
        }
        
      });
    }
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource.sort = this.sort;
  }
}
