import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { TraficoSegmentosModule } from "./trafico-segmentos/trafico-segmentos.module";
import { TraficoResidentesModule } from "./trafico-residentes/trafico-residentes.module";
import { ProgressBarModule } from "../../../app-widgets/indicators/progress-bar/progress-bar.module";

import { DataTraficoComponent } from "./data-trafico.component";

@NgModule({
  declarations: [DataTraficoComponent],
  imports: [
    SharedModule,
    TraficoSegmentosModule,
    TraficoResidentesModule,
    ProgressBarModule
  ],
  exports: [DataTraficoComponent],
  providers: []
})
export class DataTraficoModule {}
