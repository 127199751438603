// this module IS NOT in USE

import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { GeoRemoveCommercesTableComponent } from "./geo-remove-commerces-table.component";

@NgModule({
  declarations: [GeoRemoveCommercesTableComponent],
  imports: [SharedModule],
  exports: [GeoRemoveCommercesTableComponent],
  providers: []
})
export class GeoRemoveCommercesTableModule {}
