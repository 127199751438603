// this module IS NOT in USE

import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { GeoRemoveMcdTableComponent } from "./geo-remove-mcd-table.component";

@NgModule({
  declarations: [GeoRemoveMcdTableComponent],
  imports: [SharedModule],
  exports: [GeoRemoveMcdTableComponent],
  providers: []
})
export class GeoRemoveMcdTableModule {}
