import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";

import { NewUserRoutingModule } from "./new-user-routing.module";

@NgModule({
  declarations: [NewUserRoutingModule.components],
  imports: [SharedModule, NewUserRoutingModule],
  exports: [],
  providers: []
})
export class NewUserModule {}
