import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabCentrosComercialesComponent } from "./tab-centros-comerciales.component";

@NgModule({
  declarations: [TabCentrosComercialesComponent],
  imports: [SharedModule],
  exports: [TabCentrosComercialesComponent],
  providers: []
})
export class TabCentrosComercialesModule {}
