<h1 mat-dialog-title>
  <strong>{{ action }} comercio:</strong>
</h1>
<div mat-dialog-content>
  <!-- c.brand -->
  <div *ngIf="action != 'Eliminar'; else elseTemplate">
    <mat-form-field>
      <mat-label>Seleccione una categoría</mat-label>
      <mat-select [(ngModel)]="local_data.category" (selectionChange)="filterBrand($event.value)">
        <mat-option *ngFor="let c of sampleBrands" [value]="c.category">{{
          c.category
          }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Añadir un comercio</mat-label>
      <mat-select [(ngModel)]="local_data.commerce">
        <mat-option *ngFor="let b of sampleCategories" [value]="b">{{
          b
          }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div style="margin-bottom: 0.3cm;">
      <button mat-button (click)="
        $event.stopPropagation();
        $event.preventDefault();
        openDialog('Añadir dirección', {local_data})
      " mat-flat-button color="primary">
        {{ action }} dirección
      </button>
    </div>
    <!-- <mat-form-field>
      <input
        placeholder="{{ action }} dirección"
        matInput
        [(ngModel)]="local_data.address"
      />
    </mat-form-field>
    -->

    <mat-form-field *ngIf="!disabledButton">
      <mat-label>Dirección añadida</mat-label>
      <input placeholder="Dirección añadida" matInput [(ngModel)]="local_data.addressCtrl" readonly />
    </mat-form-field>
    <!--
    <mat-form-field>
      <mat-label>Seleccione un área andando</mat-label>
      <mat-select [(ngModel)]="local_data.distance">
        <mat-option value="minutes_foot_00_03">0-3</mat-option>
        <mat-option value="minutes_foot_03_05">3-5</mat-option>
        <mat-option value="minutes_foot_05_10">5-10</mat-option>
        <mat-option value="minutes_foot_10_15">10-15</mat-option>
      </mat-select>
    </mat-form-field>
    -->

    <!--
    <mat-form-field>
      <mat-label>Cantidad</mat-label>
      <input
        matInput
        type="number"
        min="0"
        pattern="^[0-9]+"
        [(ngModel)]="local_data.num"
        (keydown.enter)="$event.preventDefault()"
        value="1"
      />
    </mat-form-field>
  -->
  </div>

  <ng-template #elseTemplate>
    <p>
      ¿Seguro que quiere realizar esta acción?<br />Para este estudio no podrá
      recuperar el comercio que está eliminando
    </p>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()" [disabled]="disabledButton">{{ action }}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancelar
  </button>
</div>