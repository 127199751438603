export const environment = {
  production: true,
  imgUrl:
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "") +
    "/assets/img/marker_icons",
  imgServicesBK: location.protocol +
  "//" +
  location.hostname +
  (location.port ? ":" + location.port : "") + "/assets/img/services_icons_bk",
  postmanUrl:
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "") +
    "/abacusgis-pro",
  postmanPort: "",
  postmanDelimitator: ":",
  postmanHost:
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : ""),
  postmanContext: "/abacusgis-pro",
  postmanPortDev: "",
  urlExpansionReport:
    location.protocol +
    "//" +
    location.hostname +
    "/expansion-report/dist/index.html",
  urlPrescriptiveReport:
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "") +
    "/brick-report"
};
