<p class="margin-top--l">
  <strong> {{ title }}</strong>
</p>

<form [formGroup]="sliderForm">
  <p>
    {{ sliderForm.value.sliderControl[0] }} |
    {{ sliderForm.value.sliderControl[1] }}
  </p>

  <ng5-slider
    [attr.id]="id"
    [options]="options"
    formControlName="sliderControl"
    (userChange)="onChange($event)"
  ></ng5-slider>
  <p>
    <button mat-flat-button class="small" (click)="resetForm()">
      Reiniciar
    </button>
  </p>
</form>
