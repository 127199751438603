import { Component ,OnDestroy} from "@angular/core";
import { OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DeleteDialogComponent } from "../../../app-widgets/popup-modals/delete-dialog/delete-dialog.component";
import { EditDialogComponent } from "../../../app-widgets/popup-modals/edit-dialog/edit-dialog.component";

// https://stackblitz.com/edit/angular-fh1kyp?file=src%2Fapp%2Ftodo.service.ts
import { Observable,Subject } from "rxjs";

import { ViewChild } from "@angular/core";
import { animate } from "@angular/animations";
import { state } from "@angular/animations";
import { style } from "@angular/animations";
import { transition } from "@angular/animations";
import { trigger } from "@angular/animations";

// https://stackblitz.com/edit/angular-kavsfy-xrwmuv?file=app/table-expandable-rows-example.ts

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

// dummy data
import { brick_study } from "../../../../assets/models/brick_study.model";
import sampleBrickStudies from "../../../../assets/data/brick_studies_data.json";
import sampleProvincias from "../../../../assets/data/provincias.json";


import { TableStudyActionsService } from "../../../app-services/table-study-actions.service";

import { GeoStudiesPrescriptiveDataService } from "../../../app-services/geo-studies-prescriptive-data.service";
import { Todo } from "../../../app-services/geo-studies-prescriptive-data.service";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { BrickReportService } from "src/app/brick-report/brick-report.service";
import { EditDialogGroupViewersComponent } from "src/app/app-widgets/popup-modals/edit-dialog-group-viewers/edit-dialog-group-viewers.component";

@Component({
  selector: "app-brick-studies-table",
  templateUrl: "./brick-studies-table.component.html",
  styleUrls: ["./brick-studies-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})
export class BrickStudiesTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  todos: Observable<Todo[]>;

  dataSource: any;
  bricksTable: any;
  studiesSub: any;

  userAuthorized: boolean = false;
  unsubscribe: any;
  public destroyed = new Subject<any>();

  columnsToDisplay: string[] = [
    "id",
    "nombre",
    "zona de estudio",
   // "tipo restaurante",
    "fecha creacion",
    "estado"
  ];

  top_bricks_headers = ["Brick con mayor potencial", "Venta €"];
  
  expandedStudy: brick_study | null; // IMPORTANT!!
  index: any;

  downloadStudy: any;
  seeOnline: any;
  seeMapP: any;
  editStudy: any;

  provincias = sampleProvincias;

  constructor(    
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService,
    public dialog: MatDialog,
    private tableStudyActionsService: TableStudyActionsService,
    private router: Router,
    private brickReportService: BrickReportService
  ) {
    this.studiesSub = this.geoStudiesPrescriptiveDataService.todos.subscribe(d => {
    this.bricksTable = d.map(el => {
            el["id"] = el["properties"]["study"].study_id;
            el["nombre"] = el["properties"]["study_name"];
            el["province_id"] = el["properties"]["study_zone_id"];
           // el["tipo restaurante"] = el["properties"]["building_type"];        
            el["fecha creacion"] = el["properties"]["study"]["created"].split("T")
            .join(" ")
            .split("Z")
            .join("")
            .split(".")[0];
            el["estado"] = el["properties"]["study"]["state_description"];
            el["provincia"] =  this.provincias.filter(function (elemnt1) {
              if(elemnt1.key ==el["properties"]["study_zone_id"]){
                return elemnt1.value;
              }
                })[0].value; 
                el["zona de estudio"] =  this.provincias.filter(function (elemnt1) {
                  if(elemnt1.key ==el["properties"]["study_zone_id"]){
                    return elemnt1.value;
                  }
                    })[0].value; 
                
            el["top_bricks"] = [];

            var brick = el.properties["bricks"];
            let contador = 0;
            if(brick){
              this.brickReportService.sortBricksS(el);
              brick.forEach(e => {
                contador++;
                let data_brick = {};
                data_brick["id_brick"] = e.brick_id;
                data_brick["building_type"] = e.building_type;
                data_brick["id_brick_vta"] = Math.round(e.sales_info.first_year_sales);
                if(contador<6){
                  el["top_bricks"].push(data_brick);
                }              
                return el["top_bricks"]; 
              });
            }
            
      return el; 
    });
    if (d.length > 0) {
      this.dataSource = new MatTableDataSource(this.bricksTable);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return (
            String(data.study_id)
              .toLowerCase()
              .includes(filter) ||
            String(data.study_name)
              .toLowerCase()
              .includes(filter) ||
            String(data.province)
              .toLowerCase()
              .includes(filter) ||
            String(data.building_type)
              .toLowerCase()
              .includes(filter)
          );
        };
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

    }

    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "320px",
      data:
        "Esta acción no podrá deshacerse. ¿Está seguro que quiere eliminar el estudio?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // delete study:
        this.geoStudiesPrescriptiveDataService
          .deleteStudy(this.expandedStudy)
          .subscribe(
            response => {
              console.log("deleteStudy Response is: ", response);
              this.geoStudiesPrescriptiveDataService.loadAll();
            },
            error => {
              console.error("deleteStudy An error occurred, ", error);
            }
          );
      }
    });
  }

  openDialogGroupSeeStudy(expandedStudy): void {
    if (expandedStudy.properties.study.state_code == "processed") {
      const dialogRefEdit = this.dialog.open(EditDialogGroupViewersComponent, {
        width: "320px",
        data:  expandedStudy
      });

      dialogRefEdit.afterClosed().subscribe(result => {
        //ToDo: edit-dialog-group-viewer aqui guardar en lugar de en el componente del dialogo
    /*
            this.geoStudiesPrescriptiveDataService
        .saveStudyMassivePrescriptive(expandedStudy)
        .subscribe(
          (response) => {
            console.log("saveStudyMassivePrescriptive Response is: ", response);
          },
          (error) => {
            console.error("saveStudyMassivePrescriptive An error occurred, ", error);
          }
            );  
    */

        if (result) {
          console.log("Yes clicked edited", expandedStudy);
        }
      });

    } else {
      const dialogRefEdit = this.dialog.open(EditDialogComponent, {
        width: "320px",
        data: "No están disponibles los datos. Espere a estado Disponible."
      });

      dialogRefEdit.afterClosed().subscribe(result => {
        if (result) {
          console.log("Yes clicked", expandedStudy);
        }
      });
    }
  }

  openDialogEdit(expandedStudy): void {
     if (expandedStudy.properties.study.state_code == "processed") {
    this.tableStudyActionsService.seeOnlinePrescriptive(expandedStudy);
     } else {
       const dialogRefEdit = this.dialog.open(EditDialogComponent, {
         width: "320px",
         data: "No está disponible el informe. Espere a estado Disponible."
       });

       dialogRefEdit.afterClosed().subscribe(result => {
         if (result) {
           console.log("Yes clicked", this.expandedStudy);
         }
       });
     }
  }

  openDialogSee(expandedStudy): void {
    if (expandedStudy.properties.study.state_code == "processed") {
   this.tableStudyActionsService.seeMapP(expandedStudy);
    } else {
      const dialogRefEdit = this.dialog.open(EditDialogComponent, {
        width: "320px",
        data: "No están los datos disponibles. Espere a estado Disponible."
      });

      dialogRefEdit.afterClosed().subscribe(result => {
        if (result) {
          console.log("Yes clicked", this.expandedStudy);
        }
      });
    }
 }

  openDialogDownload(expandedStudy): void {
    if (expandedStudy.properties.study.state_code == "processed") {
   this.geoStudiesPrescriptiveDataService.getDownloadFileFromFolder(expandedStudy.id);

    } else {
      const dialogRefEdit = this.dialog.open(EditDialogComponent, {
        width: "320px",
        data: "No están los datos disponibles. Espere a estado Disponible."
      });

      dialogRefEdit.afterClosed().subscribe(result => {
        if (result) {
          console.log("Yes clicked", this.expandedStudy);
        }
      });
    }
 }
  

  ngOnInit() {
    //get user
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      if (user.group != "advance") {
        this.userAuthorized = false;
      } else {
        this.userAuthorized = true;
      }
    }
    this.downloadStudy = this.tableStudyActionsService.downloadStudy;
    this.seeOnline = this.tableStudyActionsService.seeOnline;
    this.seeMapP = this.tableStudyActionsService.seeMapP;
    this.todos = this.geoStudiesPrescriptiveDataService.todos;
    this.unsubscribe = this.geoStudiesPrescriptiveDataService.loadAll();

    this.unsubscribe = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.geoStudiesPrescriptiveDataService.loadAll();
      }
    });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    
    if (this.studiesSub) {
      this.studiesSub.unsubscribe();
    }
  }
}
