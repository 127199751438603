<section class="full-width clearfix">
  <!-- <mat-checkbox
    [(ngModel)]="allCommercesChecked"
    [value]="0"
    (change)="selectAll(eve)"
    #eve
    >Ver todos los comercios</mat-checkbox
  >
-->
</section>
<section class="full-width clearfix">
  <mat-form-field>
    <mat-label>Categoría</mat-label>
    <mat-select matNativeControl [formControl]="ensenyas" (selectionChange)="filterBrand($event.value)">
      <mat-option *ngFor="let ensenya of ensenyasList" [value]="ensenya.category">{{ ensenya.category }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Brand</mat-label>
    <mat-select multiple (selectionChange)="filterCommerce($event.value)">
      <mat-option *ngFor="let b of sampleCategories" [value]="b">{{
        b
        }}</mat-option>
    </mat-select>
  </mat-form-field>
</section>
<section class="full-width clearfix margin-top--xs">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="places">
    <!-- ONE Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="commerceSelected(element.id, element.geometry)">
        <p>
          <strong>{{ element.name }}</strong>
        </p>
        <p><strong>{{ element.brand }}</strong></p>
        <p>{{ element.address }}</p>
        <p>{{ element.province }}</p>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</section>