import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";
import { MassiveBricksNewFormRoutingModule } from "./massive-bricks-new-form-routing.module";
//import { TwoDigitDecimaNumberDirective } from '../../two-digit-decima-number.directive';


import {NgxMatFileInputModule} from '@angular-material-components/file-input';

@NgModule({
  declarations: [MassiveBricksNewFormRoutingModule.components/*, TwoDigitDecimaNumberDirective*/],
  imports: [SharedModule, MassiveBricksNewFormRoutingModule,
    NgxMatFileInputModule,
  ],
  exports: [MassiveBricksNewFormRoutingModule.components],
  providers: [SharedModule]
})
export class MassiveBricksNewFormModule {}
