import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { Optional } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
//app-services/data.service.js
import { DataService } from "../../../../app-services/data.service";
import { AddDialogMcdBoxLocatorComponent } from "../add-dialog-mcd-box-locator/add-dialog-mcd-box-locator.component";
import { MatDialog } from "@angular/material/dialog";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
export interface UsersData {
  id: number;
  name: any;
  address: string;
  distance: number;
  distance_bbdd: number;
  id_brand: number;
  brand: string;
}

@Component({
  selector: "app-add-dialog-mcd-box",
  templateUrl: "./add-dialog-mcd-box.component.html",
  styleUrls: ["./add-dialog-mcd-box.component.scss"]
})
export class AddDialogMcdBoxComponent implements OnInit, OnDestroy {
  action: string;
  local_data: any;
  sampleBrands: Array<any>;
  unsubscribe: any;

  disabledButton: boolean = true;
  disabledAddress: boolean = true;

  show_div_kb_checkbox_ndc: boolean = false;
  show_div_kb_checkbox_zce: boolean = false;
  checked_div_kb_checkbox_ndc: boolean = false;

  nuevodesarrollocomercialCtrl: boolean = false;
  mccafeCtrl: boolean = false;
  mckioskoCtrl: boolean = false;
  mcexpressCtrl: boolean = false;
  mcdesayunoCtrl: boolean = false;
  mcdeliveryCtrl: boolean = false;

  playCtrl = "No";
  loungeSeatsCtrl = 0;
  outdoorSeatsCtrl = 0;
  indoorSeatsCtrl = 0;
  independentEntanceCtrl = "No";
  totalParkingCtrl = 0;
  totalOwnParkingCtrl = 0;
  accessibilityCtrl = "A";
  visibilityCtrl = "A";
  sales_estimation = 2000000;




  dataSource: any[] = [];
  @Output() competitorsLocationAdded: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<AddDialogMcdBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData,
    private dataService: DataService,
    public dialog: MatDialog
  ) {

    this.local_data = { ...data };
    if (this.local_data.action == "Añadir") {
      this.local_data.kind_building = "instore";
      this.playCtrl = "No";
      this.local_data.playCtrl = "No";
      this.local_data.loungeSeatsCtrl = 0;
      this.local_data.outdoorSeatsCtrl = 0;
      this.local_data.indoorSeatsCtrl = 0;
      this.local_data.independentEntanceCtrl = "No";
      this.local_data.totalParkingCtrl = 0;
      this.local_data.totalOwnParkingCtrl = 0;
      this.local_data.accessibilityCtrl = "A";
      this.local_data.visibilityCtrl = "A";
      this.local_data.sales_estimation = 2000000;
    }
    this.local_data.addressCtrl = data.address;

    if (this.local_data.addressCtrl || this.local_data.name) {
      if (this.local_data.addressCtrl) {
        this.disabledAddress = false;
      }
      if (!this.local_data.name) {
        this.disabledButton = true;
      } else {
        this.disabledButton = false;
      }
    } else {
      this.disabledButton = true;
    }

    if (this.local_data.kind_building == "mall") {
      this.show_div_kb_checkbox_ndc = true;
    }





    this.action = this.local_data.action;
  }



  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogMcdBoxLocatorComponent, {
      width: "750px",
      height: "",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      position: { top: "40px" },
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.local_data = result;
      if (result.event == "Añadir dirección") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        // this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        //  this.deleteRowData(result.data);
      }


    });
  }

  addRowData(row_obj) {
    this.local_data.addressCtrl = row_obj.addressCtrl;
    this.local_data.addressTotal = row_obj.addressCtrl + ', ' + row_obj.cityCtrl + ', ' + row_obj.provinceCtrl;
    this.local_data.city = row_obj.cityCtrl;
    this.local_data.province = row_obj.provinceCtrl;
    this.local_data.long = row_obj.lonCtrl;
    this.local_data.lat = row_obj.latCtrl;

    if (this.local_data.addressCtrl || this.local_data.name) {
      if (this.local_data.addressCtrl) {
        this.disabledAddress = false;
      }
      if (!this.local_data.name) {
        this.disabledButton = true;
      } else {
        this.disabledButton = false;
      }
    } else {
      this.disabledButton = true;
    }
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancelar" });
  }

  onChangeKindBuilding(event, item) {
    this.checked_div_kb_checkbox_ndc = false;
    if (event.value == "instore") {
      this.show_div_kb_checkbox_zce = false;
      this.show_div_kb_checkbox_ndc = false;
    } else if (event.value == "mall") {
      this.show_div_kb_checkbox_zce = false;
      this.show_div_kb_checkbox_ndc = true;
    } else if (event.value == "freestanding") {
      this.show_div_kb_checkbox_zce = true;
      this.show_div_kb_checkbox_ndc = false;
    }

  }

  onChangeKBCheckbox(event, item) {
    if (item == "nuevodesarrollocomercial") {
      this.checked_div_kb_checkbox_ndc = event.checked;
    }
  }

  updateInput(event) {
    if (event.target.value) {
      this.disabledButton = false;
      if (!this.local_data.addressCtrl) {
        this.disabledButton = true;
      }
    }
  }

  ngOnInit() {

  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
