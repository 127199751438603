<!-- Change TO showSidenavEndRight / true -->
<div class="sidenav-end-left-container" [style.width]="(isPrescriptive || isMassivePrescriptive) ? '25%' : '55%'"
  [ngClass]="{ display: showSidenavEndRight }" id="main_menu_right" aria-label="Menú selector">
  <nav role="navigation">
    <div role="header" class="selector-header">
      <div class="row">
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11"></div>
        <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
          <div class="selector-header--button padding-xs">
            <div (click)="triggerToggleSideNavs()">
              <app-rotate-arrow></app-rotate-arrow>
            </div>
          </div>

        </div>


      </div>
    </div>

    <div class="sidebar">
      <div class="sidebar--inner">
        <div class="sidebar--direction">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </nav>
</div>
<div class="overlay-right-label">
  <div class="overlay fade-in" (click)="triggerToggleSideNavs()"></div>
</div>