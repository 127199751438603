<aside class="clearfix">
  <div class="icon mcCafe" *ngIf="mcCafe"></div>
  <div class="icon mcAuto" *ngIf="mcAuto"></div>
  <div class="icon mcExpress" *ngIf="mcExpress"></div>
  <div class="icon mcKiosko" *ngIf="mcKiosko"></div>
  <div class="icon mcDesayunos" *ngIf="mcDesayunos"></div>
  <div class="icon mcPlayPlace" *ngIf="mcPlayPlace"></div>
  <div class="icon mcParking" *ngIf="mcParking"></div>
  <div class="icon mcDelivery" *ngIf="mcDelivery"></div>
</aside>
