import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";
import { Observable, of } from "rxjs";
import { GeoStudiesPrescriptiveDataService } from "src/app/app-services/geo-studies-prescriptive-data.service";
import { AuthenticationService } from "../app-services/authentication.service";

import { BrickReportService } from '../brick-report/brick-report.service';

@Injectable({
  providedIn: "root"
})
export class BrickReportResolverService implements Resolve<any> {
  constructor(private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService, private brickReportService: BrickReportService, private authenticationService: AuthenticationService) {}

  resolve(
    routep: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let idStudy = routep.paramMap.get("idStudy");

    let tryingUserR = JSON.parse(sessionStorage.tryingUser);
    this.authenticationService.setCurrentUserValue(tryingUserR);
    this.geoStudiesPrescriptiveDataService
        .getBasicInfoStudy(idStudy)
        .subscribe(d => {
            this.brickReportService.getReportPrescriptive(d);
      });
    return this.brickReportService.setSelectedStudyReportPresc(idStudy);
  }
}