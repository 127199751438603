<main class="login-page">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="login-card">
        <div class="card-container">
          <app-login-card></app-login-card>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="video-container">
        <div class="video-overlays"></div>
        <video #mcdonalds_video loop title="McDonald´s login" webkit-playsinline="true" playsinline="true"
          preload="auto" class="full-video" autoplay="true" muted="muted" poster="./assets/img/login/video-poster.jpg">
          <source src="./assets/video/videoplayback.mp4" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"' />
          <source src="./assets/videoplayback.ogg" type="video/ogg; codecs=dirac, speex" />
          <source src="./assets/videoplayback.webm" type='video/webm;codecs="vp8, vorbis"' />

        </video>
      </div>
    </div>
  </div>
</main>