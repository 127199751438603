<div class="sidebar--inner">
  <ul class="vertical-ul sites">
    <li><span class="icon icon-bricks"></span>Bricks</li>
  </ul>

  <div class="tabs-container">
    <div class="tab" data-tab="bricks">
      <app-li-massive-bricks></app-li-massive-bricks>
    </div>
  </div>
</div>
