import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MassiveBrickStudiesComponent } from "./massive-brick-studies.component";

const routes: Routes = [
  {
    path: "massive-prescriptive/studies",
    component: MassiveBrickStudiesComponent,
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MassiveBrickStudiesRoutingModule {
  static components = [MassiveBrickStudiesComponent];
}
