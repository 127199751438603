<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar" />
</mat-form-field>

<div class="mat-elevation-z8">
  <table #table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="brick-studies">
    <ng-container *ngFor="let column of columnsToDisplay" matColumnDef="{{ column }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
      <td mat-cell *matCellDef="let study">
        {{ study[column] }}
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let study" [attr.colspan]="columnsToDisplay.length">
        <div class="study-detail" [@detailExpand]="study == expandedStudy ? 'expanded' : 'collapsed'">
          <section class="full-width clearfix padding-xs margin-top--s">
            <div class="row">
              <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">

                <h2 class="tab-headline">
                  <i class="material-icons sub-icons primary-color--black">
                    location_city
                  </i>
                  McDonalds
                </h2>
              </div>
            </div>





            <div class="row">
              <!--
                <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
                <div class="study-diagram">
                  <div class="study-position">
                    {{ study.id }}
                  </div>
                  <div class="study-symbol">
                    {{ study.study_name }}
                  </div>
                  <div class="study-name">
                    {{ study.building_type }}
                  </div>
                  <div class="study-name">
                    {{ study.provincia }}
                  </div>
                </div>
                <div class="study-sales">
                  <app-study-sales [studyData]="study"></app-study-sales>
                </div>
                <div class="margin-top--s"></div>
              </div>
            -->
              <!--
                <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
                <table class="mat-elevation-z1 mcd_responsive_table flush-left">
                  <caption></caption>
                  <tbody>
                    <tr>
                      <td colspan="1">Provincia</td>
                      <td colspan="1">
                        {{ study.provincia }}
                      </td>
                    </tr>                    
                    <tr>
                      <td colspan="1">Tipología</td>
                      <td colspan="1">
                        {{ study.building_type }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Ventas mínimas</td>
                      <td colspan="1">
                        {{ study.properties.min_sales }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">% Máximo de otros restaurantes</td>
                      <td colspan="1">
                        {{ study.properties.max_sales_from_restaurants_percent }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1">% Máximo de impacto</td>
                      <td colspan="1">
                        {{ study.properties.max_restaurant_impact_percent }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Delivery</td>
                      <td colspan="1">
                        {{ study.properties.delivery === true ? "Sí" : "No" }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">McCafe</td>
                      <td colspan="1">
                        {{ study.properties.mccafe === true ? "Sí" : "No" }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1">McAuto</td>
                      <td colspan="1">
                        {{ study.properties.mcauto === true ? "Sí" : "No" }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">McKiosko</td>
                      <td colspan="1">
                        {{ study.properties.mckiosko === true ? "Sí" : "No" }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1">McExpress</td>
                      <td colspan="1">
                        {{ study.properties.mcexpress === true ? "Sí" : "No" }}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            

                <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
                <table class="mat-elevation-z1 mcd_responsive_table flush-left">
                  <caption></caption>
                  <tbody>
                    

                    
                    <tr>
                      <td colspan="1">Tipo Play</td>
                      <td colspan="1">{{ study.properties.type_play }}</td>
                    </tr>

                    <tr>
                      <td colspan="1">Asientos salón</td>
                      <td colspan="1">{{ study.properties.seats_room }}</td>
                    </tr>
                    <tr>
                      <td colspan="1">Asientos terraza exterior</td>
                      <td colspan="1">
                        {{ study.properties.seats_terrace_outdoor }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Asientos terraza interior</td>
                      <td colspan="1">
                        {{ study.properties.seats_terrace_indoor }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Entrada independiente CC</td>
                      <td colspan="1">
                        {{ study.properties.separate_entrance_mall }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Plazas de parking propio</td>
                      <td colspan="1">
                        {{ study.properties.own_parking_space }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Plazas totales de parking</td>
                      <td colspan="1">
                        {{ study.properties.total_parking_spaces }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1">Accesibilidad</td>
                      <td colspan="1">
                        {{ study.properties.accesibility }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="1">Visibilidad</td>
                      <td colspan="1">
                        {{ study.properties.visibility }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            -->

              <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">


                <table class="mat-elevation-z1 mcd_responsive_table flush-left">
                  <caption></caption>
                  <tbody>
                    <tr>
                      <td class="text-align-center">
                        Orden
                      </td>
                      <td class="text-align-center">
                        Tipo inmueble
                      </td>
                      <td class="text-align-center">
                        Ventas mínimas
                      </td>
                      <td class="text-align-center">
                        Porcentaje impacto máximo
                      </td>
                      <td class="text-align-center">
                        Porcentaje ventas ajenas máximo
                      </td>
                    </tr>
                    <tr *ngFor="let row of study.properties.studies">
                      <td class="text-align-center">
                        {{row.order}}
                      </td>
                      <td class="text-align-center">
                        {{row.building_type}}
                      </td>
                      <td class="text-align-center">
                        {{row.min_sales | number: "1.0-0":"es" }} €
                      </td>
                      <td class="text-align-center">
                        {{row.max_restaurant_impact_percent | number: "1.0-0":"es" }} %
                      </td>
                      <td class="text-align-center">
                        {{row.max_sales_from_restaurants_percent | number: "1.0-0":"es" }} %
                      </td>

                    </tr>
                  </tbody>
                </table>

              </div>

              <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">


                <table class="mat-elevation-z1 mcd_responsive_table flush-left">
                  <caption></caption>
                  <tbody>
                    <tr>
                      <td>
                        Bricks con mayor potencial
                      </td>
                      <td class="text-align-center">
                        Tipo inmueble
                      </td>
                      <td class="text-align-center">
                        Ventas
                      </td>
                    </tr>
                    <tr *ngFor="let row of study.top_bricks">
                      <td class="text-align-center">
                        {{row.id_brick}}
                      </td>
                      <td class="text-align-center">
                        {{row.building_type}}
                      </td>
                      <td class="text-align-center">
                        {{row.id_brick_vta | number: "1.0-0":"es" }} €
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row margin-top--l">


              <!--  <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
                <button
                  mat-button
                  color="primary"
                  class="action-link"
                  (click)="downloadStudy(expandedStudy)"
                >
                  <i class="material-icons sub-icons small">
                    cloud_download
                  </i>
                  Descargar PDF >
                </button>
              </div> -->

              <!-- (click)="openDialogEdit(expandedStudy)" -->
              <!-- (click)="seeOnline(expandedStudy)" -->
              <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2">
                <button mat-button color="primary" class="action-link" (click)="openDialogEdit(expandedStudy)">
                  <i class="material-icons sub-icons small">
                    web
                  </i>
                  Informe >
                </button>
              </div>

              <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2">
                <button mat-button color="primary" class="action-link" (click)="openDialogDownload(expandedStudy)">
                  <i class="material-icons sub-icons small">
                    file_download
                  </i>
                  Descarga de resultados >
                </button>
              </div>

              <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2">
                <button mat-button color="primary" class="action-link" *ngIf="expandedStudy"
                  (click)="openDialogSee(expandedStudy)">
                  <i class="material-icons sub-icons small">
                    map
                  </i>
                  Ver mapa >
                </button>
              </div>
              <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2" *ngIf="userAuthorized">

                <button mat-button color="primary" class="action-link" *ngIf="expandedStudy"
                  (click)="openDialogGroupSeeStudy(expandedStudy)">
                  <i class="material-icons sub-icons small">
                    face
                  </i>
                  Grupo de visualización >
                </button>
              </div>
              <!--
               <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2">
                <button
                  mat-button
                  color="primary"
                  class="action-link"
                  [routerLink]="
                    '/predictive/new-form/study/' + expandedStudy._id
                  "
                  *ngIf="expandedStudy"
                >
                  <i class="material-icons sub-icons small">
                    edit
                  </i>
                  Editar >
                </button>
              </div>
-->
              <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2" *ngIf="userAuthorized">
                <button mat-button color="primary" class="action-link" (click)="openDialog()">
                  <i class="material-icons sub-icons small">
                    delete
                  </i>
                  Eliminar >
                </button>
              </div>
              <div class="col-xs-12 col-sm-12  col-md-2  col-lg-2"> </div>

            </div>
          </section>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let study; columns: columnsToDisplay" class="study-row"
      [class.expanded-row]="expandedStudy === study" (click)="expandedStudy = expandedStudy === study ? null : study">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

  <div class="margin-top--xl"></div>
</div>