import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { UlBricksComponent } from "./ul-bricks.component";
import { LiBricksModule } from "../li-bricks/li-bricks.module";

@NgModule({
  declarations: [UlBricksComponent],
  imports: [SharedModule, LiBricksModule],
  exports: [UlBricksComponent],
  providers: []
})
export class UlBricksModule {}
