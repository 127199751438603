import { Injectable } from "@angular/core";

import { SelectWidgetService } from "./select-widget.service";
import { DynamicSelectorsService } from "./dynamic-selectors.service";
import { D3MainService } from "./d3-main.service";

import * as d3 from "d3";

@Injectable({
  providedIn: "root"
})
export class ParamsDataService {
  //dataParams: any;
  sales: any;
  environment: any;
  presence_segmentation: any;
  percentages_sales: any;
  half_hour_sales;
  week_sales;
  month_sales;
  trimester_sales;
  pyramid_data;
  housing_typology;
  housing_vs_population;
  income_per_household;
  unemployment_rate;
  //presence_data: any;

  // TO BE DONE
  public getDataParams = (d: any, chartId: string): any => {
    let dataParams = {};

    // routes from restaurant json
    if (d && d.properties) {
      this.sales = d.properties.sales;
      this.environment = d.properties.environment;
      this.presence_segmentation = d.properties.presence;
      this.percentages_sales = this.sales.percentages_sales;
      this.half_hour_sales = this.sales.half_hour_sales;
      this.week_sales = this.sales.week_sales;
      this.month_sales = this.sales.month_sales;
      this.trimester_sales = this.sales.trimester_sales;
      this.pyramid_data = this.environment.population_pyramid_2017;
      this.housing_typology = this.environment.housing_typology;
      this.housing_vs_population = this.environment.housing_vs_population;
      this.income_per_household = this.environment.income_per_household;
      this.unemployment_rate = this.environment.unemployment_rate;
    }

    // presence data from JSON
    if (d && d.presence) {
      dataParams["presence"] = d.presence;
    }

    // traffic data from JSON
    if (d && d.traffic) {
      dataParams["traffic"] = d.traffic;
    }

    let timeParse = this.d3MainService.timeParse;
    let timeFormat = this.d3MainService.timeFormat;
    let parseYear = timeParse("%Y%m%d");
    let formatMonth = timeFormat("%B");

    switch (chartId) {
      case "sales":
        dataParams["grossData"] = [
          {
            facturacion: this.sales.sales_amount,
            variacion: this.sales.comparable_2017,
            hora_max_facturacion: this.sales.billing_sales.hour_max_billing,
            max_euros: this.sales.billing_sales
              .billing_average_hour_max_billing,
            hora_max_tickets: this.sales.tickets_sales.hour_max_tickets,
            max_tickets: this.sales.tickets_sales
              .average_number_tickets_in_hour_max_tickets
          }
        ];

        break;

      case "percentages_sales":
        dataParams["grossData"] = [
          {
            category: "2019",
            perc_mostrador: this.percentages_sales.percentage_desk,
            perc_mcauto: this.percentages_sales.percentage_mcauto,
            perc_mccafe: this.percentages_sales.percentage_mccafe,
            perc_kiosko: this.percentages_sales.percentage_kiosk
          }
        ];
        break;

      case "time_sales":
        let hour_sales_obj = this.convertToKeyName(this.half_hour_sales);
        let week_sales_obj = this.convertToKeyName(this.week_sales);
        let month_sales_obj = this.convertToKeyName(this.month_sales);
        let trimester_sales_obj = this.convertToKeyName(this.trimester_sales);

        dataParams["grossData"] = [
          { key: "half_hour_sales", data: hour_sales_obj },
          { key: "week_sales", data: week_sales_obj },
          { key: "trimester_sales", data: trimester_sales_obj },
          { key: "month_sales", data: month_sales_obj }
        ];
        break;

      case "population_pyramid_2017":
        dataParams["grossData"] = this.filterDataForPyramidChart(
          this.pyramid_data
        );
        break;

      case "housing_typology":
        dataParams["grossData"] = this.housing_typology;
        break;

      case "housing_vs_population":
        dataParams["grossData"] = this.housing_vs_population;
        break;

      case "income_per_household":
        dataParams["grossData"] = this.income_per_household;
        break;

      case "unemployment_rate":
        dataParams["grossData"] = this.unemployment_rate;
        break;

      case "presence_segmentation":
        dataParams["presence"] = dataParams["presence"].filter(
          d => d.nationality == "Spain"
        );
        dataParams["presence"] = dataParams["presence"].map(el => {
          let o = Object.assign({}, el);
          o.month = parseYear(el["date"]);
          o.month = formatMonth(o.month);
          return o;
        });

        break;
      case "presence_residence":
        dataParams["presence"] = dataParams["presence"].map(el => {
          let o = Object.assign({}, el);
          o.month = parseYear(el["date"]);
          o.month = formatMonth(o.month);
          return o;
        });

        break;

      case "presence_residence_spain":
        dataParams["presence"] = dataParams["presence"].filter(d => {
          return d.nationality == "Spain";
        });

        break;

      case "presence_residence_abroad":
        dataParams["presence"] = dataParams["presence"].filter(d => {
          return d.nationality == "abroad";
        });

        break;

      case "presence_residence_total":
        dataParams["presence"] = dataParams["presence"];

        break;

      case "traffic_segmentation":
        dataParams["traffic"] = dataParams["traffic"].filter(
          d => d.nationality == "Spain"
        );
        dataParams["traffic"] = dataParams["traffic"].map(el => {
          let o = Object.assign({}, el);
          o.parseDate = parseYear(el["date"]);
          o.month = formatMonth(o.parseDate);
          return o;
        });

        break;
      case "traffic_residence":
        dataParams["traffic"] = dataParams["traffic"].map(el => {
          let o = Object.assign({}, el);
          o.parseDate = parseYear(el["date"]);
          o.month = formatMonth(o.parseDate);
          return o;
        });

        break;

      case "traffic_residence_spain":
        dataParams["traffic"] = dataParams["traffic"].filter(d => {
          return d.nationality == "Spain";
        });

        break;

      case "traffic_residence_abroad":
        dataParams["traffic"] = dataParams["traffic"].filter(d => {
          return d.nationality == "abroad";
        });

        break;

      default:
        return "";
    }

    //console.log("the params", dataParams);
    return dataParams;
  };

  public getDataParams_data = (d: any, chartId: string): any => {
    let dataParams = {};

    // routes from restaurant json
    // if (d && d.environment) {
    // this.sales = d.properties.sales;
    this.environment = d.environment;
    // this.presence_segmentation = d.properties.presence;
    // this.percentages_sales = this.sales.percentages_sales;
    // this.half_hour_sales = this.sales.half_hour_sales;
    // this.week_sales = this.sales.week_sales;
    // this.month_sales = this.sales.month_sales;
    // this.trimester_sales = this.sales.trimester_sales;
    this.pyramid_data = this.environment.population_pyramid_2017;
    this.housing_typology = this.environment.housing_typology;
    this.housing_vs_population = this.environment.housing_vs_population;
    this.income_per_household = this.environment.income_per_household;
    this.unemployment_rate = this.environment.unemployment_rate;
    // }
    // presence data from JSON
    if (d && d.presence) {
      dataParams["presence"] = d.presence;
    }

    let timeParse = this.d3MainService.timeParse;
    let timeFormat = this.d3MainService.timeFormat;
    let parseYear = timeParse("%Y%m%d");
    let formatMonth = timeFormat("%B");

    switch (chartId) {
      case "population_pyramid_2017":
        dataParams["grossData"] = this.filterDataForPyramidChart(
          this.pyramid_data
        );
        break;

      case "housing_typology":
        dataParams["grossData"] = this.housing_typology;
        break;

      case "housing_vs_population":
        dataParams["grossData"] = this.housing_vs_population;
        break;

      case "income_per_household":
        dataParams["grossData"] = this.income_per_household;
        break;

      case "unemployment_rate":
        dataParams["grossData"] = this.unemployment_rate;
        break;

      default:
        return "";
    }

    //console.log("the params", this.params);
    return dataParams;
  };

  public getDataParamsHousing_typology = (d: any, chartId: string): any => {
    let dataParams = {};

    // routes from restaurant json
    // if (d && d.properties) {
    this.housing_typology = d;
    // }

    let timeParse = this.d3MainService.timeParse;
    let timeFormat = this.d3MainService.timeFormat;
    let parseYear = timeParse("%Y%m%d");
    let formatMonth = timeFormat("%B");

    //let environment: any = d.properties.environment;
    let housing_typology = d;

    switch (chartId) {
      case "housing_typology":
        dataParams["grossData"] = this.housing_typology;
        break;
      default:
        return "";
    }

    //console.log("the params", this.params);
    return dataParams;
  };

  public getDataParamsSales = (d: any, chartId: string): any => {
    let dataParams = {};

    // routes from restaurant json
    // if (d && d.properties) {
    this.sales = d.properties.sales;
    // this.environment = d.properties.environment;
    // this.presence_segmentation = d.properties.presence;
    this.percentages_sales = this.sales.percentages_sales;
    this.half_hour_sales = this.sales.half_hour_sales;
    this.week_sales = this.sales.week_sales;
    this.month_sales = this.sales.month_sales;
    this.trimester_sales = this.sales.trimester_sales;
    //this.pyramid_data = this.environment.population_pyramid_2017;
    //this.housing_typology = this.environment.housing_typology;
    // this.housing_vs_population = this.environment.housing_vs_population;
    // this.income_per_household = this.environment.income_per_household;
    // this.unemployment_rate = this.environment.unemployment_rate;
    // }

    // presence data from JSON
    if (d && d.presence) {
      dataParams["presence"] = d.presence;
    }

    let timeParse = this.d3MainService.timeParse;
    let timeFormat = this.d3MainService.timeFormat;
    let parseYear = timeParse("%Y%m%d");
    let formatMonth = timeFormat("%B");

    switch (chartId) {
      case "sales":
        dataParams["grossData"] = [
          {
            facturacion: this.sales.sales_amount,
            variacion: this.sales.comparable_2017,
            hora_max_facturacion: this.sales.billing_sales.hour_max_billing,
            max_euros: this.sales.billing_sales
              .billing_average_hour_max_billing,
            hora_max_tickets: this.sales.tickets_sales.hour_max_tickets,
            max_tickets: this.sales.tickets_sales
              .average_number_tickets_in_hour_max_tickets
          }
        ];

        break;

      case "percentages_sales":
        dataParams["grossData"] = [
          {
            category: "2019",
            perc_mostrador: this.percentages_sales.percentage_desk,
            perc_mcauto: this.percentages_sales.percentage_mcauto,
            perc_mccafe: this.percentages_sales.percentage_mccafe,
            perc_kiosko: this.percentages_sales.percentage_kiosk
          }
        ];
        break;

      case "time_sales":
        let hour_sales_obj = this.convertToKeyName(this.half_hour_sales);
        let week_sales_obj = this.convertToKeyName(this.week_sales);
        let month_sales_obj = this.convertToKeyName(this.month_sales);
        let trimester_sales_obj = this.convertToKeyName(this.trimester_sales);

        dataParams["grossData"] = [
          { key: "half_hour_sales", data: hour_sales_obj },
          { key: "week_sales", data: week_sales_obj },
          { key: "trimester_sales", data: trimester_sales_obj },
          { key: "month_sales", data: month_sales_obj }
        ];
        break;

      case "population_pyramid_2017":
        // this.params["dummy"] = this.filterDataForPyramidChart(
        //   this.pyramid_data
        // );
        break;

      case "housing_typology":
        // this.params["dummy"] = this.housing_typology;
        break;

      case "housing_vs_population":
        // this.params["dummy"] = this.housing_vs_population;
        break;

      case "income_per_household":
        // this.params["dummy"] = this.income_per_household;
        break;

      case "unemployment_rate":
        // this.params["dummy"] = this.unemployment_rate;
        break;

      case "presence_segmentation":
        // this.params["presence"] = this.presence_data.filter(
        //   d => d.nationality == "Spain"
        // );
        // this.params["presence"] = this.params.presence.map(el => {
        //   let o = Object.assign({}, el);
        //   o.month = parseYear(el["date"]);
        //   o.month = formatMonth(o.month);
        //   return o;
        // });

        break;
      case "presence_residence":
        // this.params["presence"] = this.presence_data.map(el => {
        //   let o = Object.assign({}, el);
        //   o.month = parseYear(el["date"]);
        //   o.month = formatMonth(o.month);
        //   return o;
        // });

        break;

      case "presence_residence_spain":
        // this.params["presence"] = this.presence_data.filter(d => {
        //   return d.nationality == "Spain";
        // });

        break;

      default:
        return "";
    }

    //console.log("the params", dataParams);
    return dataParams;
  };

  // helpers

  public convertToKeyName = obj => {
    let result = Object.keys(obj).map(function(key) {
      return { name: key, value: obj[key] };
    });

    return result;
  };

  public filterDataForPyramidChart = d => {
    let hombresArr = [],
      mujeresArr = [],
      spainHombresArr = [],
      spainMujeresArr = [],
      arrL: number,
      i: number = 0,
      totalArr = [];

    Object.keys(d).forEach((key, v) => {
      if (key.indexOf("v_men") >= 0) {
        hombresArr.push({
          index: key,
          value: d[key],
          position: v,
          name: this.getTheRangeName(key)
        });
        // console.log('v_hombre', key, d[key], v);
      }
      if (key.indexOf("v_women") >= 0) {
        mujeresArr.push({
          index: key,
          value: d[key],
          position: v,
          name: this.getTheRangeName(key)
        });
      }
      if (key.indexOf("average_es_men") >= 0) {
        spainHombresArr.push({
          index: key,
          value: d[key],
          position: v,
          name: this.getTheRangeName(key)
        });
      }
      if (key.indexOf("average_es_women") >= 0) {
        spainMujeresArr.push({
          index: key,
          value: Math.abs(d[key]),
          position: v,
          name: this.getTheRangeName(key)
        });
        // console.log('v_hombre', key, d[key], v);
      }
    });
    arrL = mujeresArr.length;
    i = 0;

    for (i; i < arrL; i++) {
      // console.log('foorr:::', hombresArr[i]);
      totalArr.push({
        edad: hombresArr[i].name,
        hombre: hombresArr[i].value,
        mujer: mujeresArr[i].value,
        spain_hombre: spainHombresArr[i].value,
        spain_mujer: spainMujeresArr[i].value,
        position: i
      });
    }
    // console.log('hombresArr', hombresArr);
    // console.log('mujeresArr', mujeresArr, arrL);
    // console.log('spainHombresArr', spainHombresArr);
    // console.log('spainMujeresArr', spainMujeresArr);

    //console.log('totalArr', totalArr);
    return totalArr;
  };

  // **********************************************************************************
  // nombres de los rangos de edad
  public getTheRangeName = l => {
    let k, name, tv, tempOne, tw, ty, tyy, tyz;
    k = l.trim();

    if (k.indexOf("pob") >= 0) {
      name = k.replace("pob", "");
    } else if (k.indexOf("v_men") >= 0) {
      name = k.replace("v_men", "");
      name = name.replace("_", "");
    } else if (k.indexOf("v_women") >= 0) {
      name = k.replace("v_women", "");
      name = name.replace("_", "");
    } else if (k.indexOf("average_es_men") >= 0) {
      name = k.replace("average_es_men", "");
      name = name.replace("_", "");
      // console.log('media_esp_varones', name);
    } else if (k.indexOf("average_es_women") >= 0) {
      name = k.replace("average_es_women", "");
      name = name.replace("_", "");
      // console.log('media_esp_mujeres', name);
    } else {
      name = "0000";
    }

    // name = k.replace('pob', '');
    tv = name.substring(0, 2) + "-" + name.substring(2);
    tempOne = tv.substring(0, 1);
    //console.log(tempOne);
    if (tempOne == "0") {
      tw = tv.replace("00", "0");
      ty = tw.replace("04", "4");
      tyy = ty.replace("05", "5");
      tyz = tyy.replace("09", "9");
    } else {
      if (tv === "85-mas" || tv === "85-m") {
        tv = "+85";
        return tv;
      } else {
        return tv;
      }
    }
    return tyz;
  };

  constructor(
    private selectWidgetService: SelectWidgetService,
    private dynamicSelectorsService: DynamicSelectorsService,
    private d3MainService: D3MainService
  ) {}

  ngOnChanges(changes: any) {}
}
/*
import { Injectable } from "@angular/core";

import { D3LayoutService } from "./d3-layout.service";
import * as d3 from "d3";

@Injectable({
  providedIn: "root"
})
export class ParamsMainService {
  params: any;
  d3Layout: Array<any>;

  public findTheIndex: any = (s: string): number => {
    let i: number = this.params.selectorsData.findIndex(c => c.id == s);
    return i;
  };

  public convertToKeyName = obj => {
    let result = Object.keys(obj).map(function(key) {
      return { name: key, value: obj[key] };
    });

    return result;
  };

  // TO BE DONE
  public getDataParams = (d: any, chartId: string): any => {
    //let chartObj = d.properties.charts.find(c => c.id == chartId);
    let layoutObj = this.d3LayoutService.d3Layout.find(c => c.id == chartId);
    //console.log("FIRST d --->", d);

    // routes
    let sales: any = d.properties.sales;
    let environment: any = d.properties.environment;
    let presence_segmentation: any = d.properties.presence;
    let percentages_sales: any = sales.percentages_sales;
    let half_hour_sales = sales.half_hour_sales;
    let week_sales = sales.week_sales;
    let month_sales = sales.month_sales;
    let trimester_sales = sales.trimester_sales;
    // let pyramid_data = environment.population_pyramid_2017;
    // let housing_typology = environment.housing_typology;
    let housing_vs_population = environment.housing_vs_population;
    let income_per_household = environment.income_per_household;
    let unemployment_rate = environment.unemployment_rate;

    this.params = {};

    //this.params.data = chartObj.data;
    this.params.id = layoutObj.id;

    this.params.container = layoutObj.container || {};
    this.params.legend = layoutObj.legend || {};
    this.params.units = layoutObj.units[0] || {};

    this.params.selectors = layoutObj.selectors;
    this.params.xVal = layoutObj.xVal || null;
    this.params.yVal = layoutObj.yVal || null;
    this.params.design = layoutObj.design || {};
    this.params.tooltip = layoutObj.tooltip || {};

    switch (chartId) {
      case "sales":
        this.params.dummy = [
          {
            facturacion: sales.sales_amount,
            variacion: sales.comparable_2017,
            hora_max_facturacion: sales.billing_sales.hour_max_billing,
            max_euros: sales.billing_sales.billing_average_hour_max_billing,
            hora_max_tickets: sales.tickets_sales.hour_max_tickets,
            max_tickets:
              sales.tickets_sales.average_number_tickets_in_hour_max_tickets
          }
        ];

        break;

      case "percentages_sales":
        this.params.dummy = [
          {
            col: "2019",
            perc_mostrador: percentages_sales.percentage_desk,
            perc_mcauto: percentages_sales.percentage_mcauto,
            perc_mccafe: percentages_sales.percentage_mccafe,
            perc_kiosko: percentages_sales.percentage_kiosk
          }
        ];
        break;

      case "time_sales":
        let hour_sales_obj = this.convertToKeyName(half_hour_sales);
        let week_sales_obj = this.convertToKeyName(week_sales);
        let month_sales_obj = this.convertToKeyName(month_sales);
        let trimester_sales_obj = this.convertToKeyName(trimester_sales);

        this.params.dummy = [
          { key: "half_hour_sales", data: hour_sales_obj },
          { key: "week_sales", data: week_sales_obj },
          { key: "trimester_sales", data: trimester_sales_obj },
          { key: "month_sales", data: month_sales_obj }
        ];
        break;

      case "population_pyramid_2017":
        // this.params.dummy = this.filterDataForPyramidChart(pyramid_data);
        break;

      case "housing_typology":
        // this.params.dummy = housing_typology;
        break;

      case "housing_vs_population":
        this.params.dummy = housing_vs_population;
        break;

      case "income_per_household":
        this.params.dummy = income_per_household;
        break;

      case "unemployment_rate":
        this.params.dummy = unemployment_rate;
        break;

      case "presence_segmentation":
        this.params.dummy = presence_segmentation;
        break;

      default:
        return "";
    }

    //console.log("the params", this.params);
    return this.params;
  };

  public getDataParams_data = (d: any, chartId: string): any => {
    let layoutObj = this.d3LayoutService.d3Layout.find(c => c.id == chartId);

    //let environment: any = d.properties.environment;
    let environment: any = d.environment;
    // let presence_segmentation: any = d.properties.presence;
    // let percentages_sales: any = sales.percentages_sales;
    // let half_hour_sales = sales.half_hour_sales;
    // let week_sales = sales.week_sales;
    // let month_sales = sales.month_sales;
    // let trimester_sales = sales.trimester_sales;
    let pyramid_data = environment.population_pyramid_2017;
    let housing_typology = environment.housing_typology;
    let housing_vs_population = environment.housing_vs_population;
    let income_per_household = environment.income_per_household;
    let unemployment_rate = environment.unemployment_rate;

    this.params = {};

    //this.params.data = chartObj.data;
    this.params.id = layoutObj.id;

    this.params.container = layoutObj.container || {};
    this.params.legend = layoutObj.legend || {};
    this.params.units = layoutObj.units[0] || {};

    this.params.selectors = layoutObj.selectors;
    this.params.xVal = layoutObj.xVal || null;
    this.params.yVal = layoutObj.yVal || null;
    this.params.design = layoutObj.design || {};
    this.params.tooltip = layoutObj.tooltip || {};

    switch (chartId) {
      // case "sales":
      //   this.params.dummy = [
      //     {
      //       facturacion: sales.sales_amount,
      //       variacion: sales.comparable_2017,
      //       hora_max_facturacion: sales.billing_sales.hour_max_billing,
      //       max_euros: sales.billing_sales.billing_average_hour_max_billing,
      //       hora_max_tickets: sales.tickets_sales.hour_max_tickets,
      //       max_tickets:
      //         sales.tickets_sales.average_number_tickets_in_hour_max_tickets
      //     }
      //   ];

      //   break;

      // case "percentages_sales":
      //   this.params.dummy = [
      //     {
      //       col: "2019",
      //       perc_mostrador: percentages_sales.percentage_desk,
      //       perc_mcauto: percentages_sales.percentage_mcauto,
      //       perc_mccafe: percentages_sales.percentage_mccafe,
      //       perc_kiosko: percentages_sales.percentage_kiosk
      //     }
      //   ];
      //   break;

      // case "time_sales":
      //   let hour_sales_obj = this.convertToKeyName(half_hour_sales);
      //   let week_sales_obj = this.convertToKeyName(week_sales);
      //   let month_sales_obj = this.convertToKeyName(month_sales);
      //   let trimester_sales_obj = this.convertToKeyName(trimester_sales);

      //   this.params.dummy = [
      //     { key: "half_hour_sales", data: hour_sales_obj },
      //     { key: "week_sales", data: week_sales_obj },
      //     { key: "trimester_sales", data: trimester_sales_obj },
      //     { key: "month_sales", data: month_sales_obj }
      //   ];
      //   break;

      case "population_pyramid_2017":
        this.params.dummy = this.filterDataForPyramidChart(pyramid_data);
        break;

      case "housing_typology":
        this.params.dummy = housing_typology;
        break;

      case "housing_vs_population":
        this.params.dummy = housing_vs_population;
        break;

      case "income_per_household":
        this.params.dummy = income_per_household;
        break;

      case "unemployment_rate":
        this.params.dummy = unemployment_rate;
        break;

      // case "presence_segmentation":
      //   this.params.dummy = presence_segmentation;
      //   break;

      default:
        return "";
    }

    return this.params;
  };

  public getDataParamsHousing_typology = (d: any, chartId: string): any => {
    let layoutObj = this.d3LayoutService.d3Layout.find(c => c.id == chartId);

    //let environment: any = d.properties.environment;
    let housing_typology = d;

    this.params = {};

    //this.params.data = chartObj.data;
    this.params.id = layoutObj.id;

    this.params.container = layoutObj.container || {};
    this.params.legend = layoutObj.legend || {};
    this.params.units = layoutObj.units[0] || {};

    this.params.selectors = layoutObj.selectors;
    this.params.xVal = layoutObj.xVal || null;
    this.params.yVal = layoutObj.yVal || null;
    this.params.design = layoutObj.design || {};
    this.params.tooltip = layoutObj.tooltip || {};

    switch (chartId) {
      case "housing_typology":
        this.params.dummy = housing_typology;
        break;
      default:
        return "";
    }

    return this.params;
  };

  public getDataParamsFake = (d: any, chartId: string): any => {
    //let chartObj = d.properties.charts.find(c => c.id == chartId);
    let layoutObj = this.d3LayoutService.d3Layout.find(c => c.id == chartId);
    //console.log("FIRST d --->", d);

    // routes
    let sales: any = d.properties.sales;
    let environment: any = d.properties.environment;
    let presence_segmentation: any = d.properties.presence;
    let percentages_sales: any = sales.percentages_sales;
    let half_hour_sales = sales.half_hour_sales;
    let week_sales = sales.week_sales;
    let month_sales = sales.month_sales;
    let trimester_sales = sales.trimester_sales;
    // let pyramid_data = environment.population_pyramid_2017;
    //let housing_typology = environment.housing_typology;
    //let housing_vs_population = environment.housing_vs_population;
    //let income_per_household = environment.income_per_household;
    //let unemployment_rate = environment.unemployment_rate;

    this.params = {};

    //this.params.data = chartObj.data;
    this.params.id = layoutObj.id;

    this.params.container = layoutObj.container || {};
    this.params.legend = layoutObj.legend || {};
    this.params.units = layoutObj.units[0] || {};

    this.params.selectors = layoutObj.selectors;
    this.params.xVal = layoutObj.xVal || null;
    this.params.yVal = layoutObj.yVal || null;
    this.params.design = layoutObj.design || {};
    this.params.tooltip = layoutObj.tooltip || {};

    switch (chartId) {
      case "sales":
        this.params.dummy = [
          {
            facturacion: sales.sales_amount,
            variacion: sales.comparable_2017,
            hora_max_facturacion: sales.billing_sales.hour_max_billing,
            max_euros: sales.billing_sales.billing_average_hour_max_billing,
            hora_max_tickets: sales.tickets_sales.hour_max_tickets,
            max_tickets:
              sales.tickets_sales.average_number_tickets_in_hour_max_tickets
          }
        ];

        break;

      case "percentages_sales":
        this.params.dummy = [
          {
            col: "2019",
            perc_mostrador: percentages_sales.percentage_desk,
            perc_mcauto: percentages_sales.percentage_mcauto,
            perc_mccafe: percentages_sales.percentage_mccafe,
            perc_kiosko: percentages_sales.percentage_kiosk
          }
        ];
        break;

      case "time_sales":
        let hour_sales_obj = this.convertToKeyName(half_hour_sales);
        let week_sales_obj = this.convertToKeyName(week_sales);
        let month_sales_obj = this.convertToKeyName(month_sales);
        let trimester_sales_obj = this.convertToKeyName(trimester_sales);

        this.params.dummy = [
          { key: "half_hour_sales", data: hour_sales_obj },
          { key: "week_sales", data: week_sales_obj },
          { key: "trimester_sales", data: trimester_sales_obj },
          { key: "month_sales", data: month_sales_obj }
        ];
        break;

      case "population_pyramid_2017":
        // this.params.dummy = this.filterDataForPyramidChart(pyramid_data);
        break;

      case "housing_typology":
        //  this.params.dummy = housing_typology;
        break;

      case "housing_vs_population":
        //  this.params.dummy = housing_vs_population;
        break;

      case "income_per_household":
        //  this.params.dummy = income_per_household;
        break;

      case "unemployment_rate":
        //  this.params.dummy = unemployment_rate;
        break;

      case "presence_segmentation":
        this.params.dummy = presence_segmentation;
        break;

      default:
        return "";
    }

    //console.log("the params", this.params);
    return this.params;
  };

  public reshapeDataToRender = (d: any, changes?: Array<any>) => {
    let data: any = {};
    let change;
    let legend;
    let key;

    // filter from selectors
    switch (d.id) {
      case "time_sales":
        change = changes[0];
        legend = d.legend.find(c => c.value == change);
        key = legend.key;

        data = d.dummy.filter(c => c.key == key)[0]["data"];

        for (let x = 0; x < data.length; x++) {
          // console.log("looping", x);
          for (let y = 0; y < legend.children.length; y++) {
            //console.log("looping",data[y]);

            if (legend.children[y]["key"] === data[x]["name"]) {
              //console.log("loop", legend.children[y], data[x]);
              data[x]["name"] = legend.children[y]["value"];
              data[x]["tooltipKey"] = legend.children[y]["name"];
            }
          }
        }

        // we are here
        break;

      case "housing_typology":
        change = changes[0];
        legend = d.legend.bars.find(c => c.value == change);
        key = legend.key;
        data = d.dummy
          .filter(c => c.key == key)[0]
          ["data"].sort((x, y) => {
            return d3.descending(x.zone, y.zone);
          });

        break;

      case "income_per_household":
        data = d.dummy;
        break;

      case "unemployment_rate":
        data = d.dummy;
        break;

      case "presence_segmentation":
        let mode = changes[1]; // driving, foot
        let area = changes[2]; // area minutes
        let segmentation = changes[0]; // activity, age, gender
        let period = changes[3]; // date, period
        let day = changes[4]; // null or year date (20180205)

        let filtering: any;
        let nestData: any;

        if (period === "date") {
          filtering = d.dummy.filter(
            d => d.mode == mode && d.area == area && d.nationality == "Spain"
          );
          nestData = d3
            .nest()
            .key(d => d[period])
            .key(d => d[segmentation])
            .rollup(v => {
              return d3.sum(v, d => d["stays"]);
            })
            .entries(filtering)
            .sort((a, b) => {
              return d3.ascending(a.key, b.key);
            });
        }
        if (period === "period") {
          filtering = d.dummy.filter(
            d =>
              d.mode == mode &&
              d.area == area &&
              d.date == day &&
              d.nationality == "Spain"
          );
          nestData = d3
            .nest()
            .key(d => d[period])
            .key(d => d[segmentation])
            .rollup(v => {
              return d3.sum(v, d => d["stays"]);
            })
            .entries(filtering);
        }

        data = nestData.map(x => {
          let rObj = {};
          rObj["category"] = x.key;
          x.values.map(v => {
            rObj[v.key] = v.value;
          });
          return rObj;
        });

        break;

      default:
        data = { key: "default", value: null };
    }
    //console.log(data);

    return data;
  };

  public filterDataForPyramidChart = d => {
    let hombresArr = [],
      mujeresArr = [],
      spainHombresArr = [],
      spainMujeresArr = [],
      arrL: number,
      i: number = 0,
      totalArr = [];

    Object.keys(d).forEach((key, v) => {
      if (key.indexOf("v_men") >= 0) {
        hombresArr.push({
          index: key,
          value: d[key],
          position: v,
          name: this.getTheRangeName(key)
        });
        // console.log('v_hombre', key, d[key], v);
      }
      if (key.indexOf("v_women") >= 0) {
        mujeresArr.push({
          index: key,
          value: d[key],
          position: v,
          name: this.getTheRangeName(key)
        });
      }
      if (key.indexOf("average_es_men") >= 0) {
        spainHombresArr.push({
          index: key,
          value: d[key],
          position: v,
          name: this.getTheRangeName(key)
        });
      }
      if (key.indexOf("average_es_women") >= 0) {
        spainMujeresArr.push({
          index: key,
          value: Math.abs(d[key]),
          position: v,
          name: this.getTheRangeName(key)
        });
        // console.log('v_hombre', key, d[key], v);
      }
    });
    arrL = mujeresArr.length;
    i = 0;

    for (i; i < arrL; i++) {
      // console.log('foorr:::', hombresArr[i]);
      totalArr.push({
        edad: hombresArr[i].name,
        hombre: hombresArr[i].value,
        mujer: mujeresArr[i].value,
        spain_hombre: spainHombresArr[i].value,
        spain_mujer: spainMujeresArr[i].value,
        position: i
      });
    }
    // console.log('hombresArr', hombresArr);
    // console.log('mujeresArr', mujeresArr, arrL);
    // console.log('spainHombresArr', spainHombresArr);
    // console.log('spainMujeresArr', spainMujeresArr);

    //console.log('totalArr', totalArr);
    return totalArr;
  };

  // **********************************************************************************
  // nombres de los rangos de edad
  public getTheRangeName = l => {
    let k, name, tv, tempOne, tw, ty, tyy, tyz;
    k = l.trim();

    if (k.indexOf("pob") >= 0) {
      name = k.replace("pob", "");
    } else if (k.indexOf("v_men") >= 0) {
      name = k.replace("v_men", "");
      name = name.replace("_", "");
    } else if (k.indexOf("v_women") >= 0) {
      name = k.replace("v_women", "");
      name = name.replace("_", "");
    } else if (k.indexOf("average_es_men") >= 0) {
      name = k.replace("average_es_men", "");
      name = name.replace("_", "");
      // console.log('media_esp_varones', name);
    } else if (k.indexOf("average_es_women") >= 0) {
      name = k.replace("average_es_women", "");
      name = name.replace("_", "");
      // console.log('media_esp_mujeres', name);
    } else {
      name = "0000";
    }

    // name = k.replace('pob', '');
    tv = name.substring(0, 2) + "-" + name.substring(2);
    tempOne = tv.substring(0, 1);
    //console.log(tempOne);
    if (tempOne == "0") {
      tw = tv.replace("00", "0");
      ty = tw.replace("04", "4");
      tyy = ty.replace("05", "5");
      tyz = tyy.replace("09", "9");
    } else {
      if (tv === "85-mas" || tv === "85-m") {
        tv = "+85";
        return tv;
      } else {
        return tv;
      }
    }
    return tyz;
  };

  constructor(private d3LayoutService: D3LayoutService) {}

  ngOnChanges(changes: any) {
    this.d3Layout = this.d3LayoutService.d3Layout;
  }
}
*/
