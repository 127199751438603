<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

      <h2 class="tab-headline">PRESENCIA MEDIA DIARIA POR SEGMENTACIÓN</h2>

      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="presence_segmentation" widget_num="0" [emitFirst]="true"
        (eventEmitter)="changeMode($event)" *ngIf="presence_segmentation_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="presence_segmentation" [childrenOptions]="areaOptions"
        widget_num="0" [emitFirst]="true" (eventEmitter)="changeArea($event)" *ngIf="presence_segmentation_ready">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="presence_segmentation" widget_num="2" [emitFirst]="true"
        (eventEmitter)="changeSegmentation($event)" *ngIf="presence_segmentation_ready">
      </app-options-from-json>

      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="presence_segmentation" widget_num="3" [emitFirst]="true"
        (eventEmitter)="changePeriod($event)" *ngIf="presence_segmentation_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json [ngClass]="{'hidden': this.displayMonths === false}" style="width: 100%;"
        [params]="presence_segmentation" widget_num="4" [emitFirst]="true" (eventEmitter)="changeMonth($event)"
        *ngIf="presence_segmentation_ready">
      </app-options-from-json>

      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent [ngClass]="{'hidden': this.displayDays === false}" style="width: 100%;"
        [params]="presence_segmentation" [childrenOptions]="dayOptions" widget_num="1" [emitFirst]="true"
        (eventEmitter)="changeDay($event)" *ngIf="presence_segmentation_ready">
      </app-options-from-parent>

      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <app-grouped-bar style="width: 100%;" [params]="presence_segmentation" [firstFilterValue]="selectedMode"
        [secondFilterValue]="selectedArea" [categorySelectValue]="selectedSegmentation"
        [thirdFilterValue]="selectedPeriod" [fourFilterValue]="selectedMonth" [fifthFilterValue]="selectedDay"
        chartId="presence_segmentation" chartSize="regular" *ngIf="presence_segmentation_ready"></app-grouped-bar>

      <div class="margin-top--s"></div>
    </div>
  </div>
</section>