import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";
import { AddDialogBoxCommerceComponent } from "./add-dialog-box-commerces/add-dialog-box-commerce.component";

export interface UsersData {
  id: number;
  category: string;
  commerce: string;
  num: number;
}

@Component({
  selector: "app-geo-add-commerces-table",
  templateUrl: "./geo-add-commerces-table.component.html",
  styleUrls: ["./geo-add-commerces-table.component.scss"]
})
export class GeoAddCommercesTableComponent implements OnInit {
  displayedColumns: string[] = ["category", "commerce", "address",/* "distance",*/  "action"];
  dataSource: any[] = [];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Output() commercesAdded: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogBoxCommerceComponent, {
      width: "320px",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Añadir") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    var d = new Date();
    switch (row_obj.distance) {
      case "minutes_foot_00_03":
        row_obj.distance_bbdd = "0-3";
        break;
      case "minutes_foot_03_05":
        row_obj.distance_bbdd = "3-5";
        break;
      case "minutes_foot_05_10":
        row_obj.distance_bbdd = "5-10";
        break;
      case "minutes_foot_10_15":
        row_obj.distance_bbdd = "10-15";
        break;
      default:
        row_obj.distance = "minutes_foot_00_03";
        row_obj.distance_bbdd = "0-3";
        break;
    }
    this.dataSource.push({
      id: d.getTime(),
      category: row_obj.category,
      commerce: row_obj.commerce,
      //num: row_obj.num,
      //address: row_obj.address,
      distance: row_obj.distance_bbdd,
      distance_bbdd: row_obj.distance,


      address: row_obj.addressCtrl,
      address_view: row_obj.addressCtrl,
      address_all: row_obj.addressTotal,
      city: row_obj.city,
      province: row_obj.province,
      lat: row_obj.lat,
      long: row_obj.long

    });

    this.commercesAdded.emit(this.dataSource);

    this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value.category = row_obj.category;
        value.commerce = row_obj.commerce;
        //value.num = row_obj.num;
        //value.address = row_obj.address;
        value.address = row_obj.addressCtrl;
        value.distance = row_obj.distance;

        value.address_view = row_obj.addressCtrl;
        value.address_all = row_obj.addressTotal;
        value.city = row_obj.city;
        value.province = row_obj.province;
        value.lat = row_obj.lat;
        value.long = row_obj.long;

      }
      return true;
    });
    this.commercesAdded.emit(this.dataSource);
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
    this.commercesAdded.emit(this.dataSource);
  }

  ngOnInit() { }
}
