import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { DataCompetenciaComponent } from "./data-competencia.component";

import { ExpansionPanelComponent } from "../../../app-widgets/layout/expansion-panel/expansion-panel.component";
import { CompetenciaTableComponent } from "../../../app-widgets/tables/competencia-table/competencia-table.component";

@NgModule({
  declarations: [
    DataCompetenciaComponent,
    ExpansionPanelComponent,
    CompetenciaTableComponent
  ],
  imports: [SharedModule],
  exports: [DataCompetenciaComponent, ExpansionPanelComponent],
  providers: []
})
export class DataCompetenciaModule {}
