import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { DataService } from "../../app-services/data.service";
import { GeoStudiesDataService } from "../../app-services/geo-studies-data.service";

@Component({
  selector: "app-geo-map",
  templateUrl: "./geo-map.component.html",
  styleUrls: ["./geo-map.component.scss"]
})
export class GeoMapComponent implements OnInit, OnDestroy {
  showSidenavStartLeft: boolean = false;
  showSidenavEndRight: boolean = false;
  unsubscribe: any;
  isPredictive: boolean = true;

  sideNavsPredictiveSub: any;
  constructor(
    private dataService: DataService,
    private geoStudiesDataService: GeoStudiesDataService
  ) {
    this.sideNavsPredictiveSub = this.dataService.sideNavsStateChanged$.subscribe(
      target => {
        this.showSidenavStartLeft = target.SidenavStartLeftState;
        this.showSidenavEndRight = target.SidenavEndRightState;
      }
    );
  }

  ngOnInit() {
    this.isPredictive = true;
    this.geoStudiesDataService.studyMapPredictive(this.isPredictive);
  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
