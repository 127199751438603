import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { OptionsFromParentComponent } from "./options-from-parent.component";

@NgModule({
  declarations: [OptionsFromParentComponent],
  imports: [SharedModule],
  exports: [OptionsFromParentComponent],
  providers: []
})
export class OptionsFromParentModule {}
