import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "../../../app-services/data.service";

@Component({
  selector: "app-data-ficha",
  templateUrl: "./data-ficha.component.html",
  styleUrls: ["./data-ficha.component.scss"]
})
export class FichaComponent implements OnInit, OnDestroy {
  checkIfServiceExists = this.dataService.checkIfRestaurantServiceExists;
  data: any;
  restaurant: any;
  ready: boolean = false;
  mcCafe: boolean = false;
  mcAuto: boolean = false;
  mcExpress: boolean = false;
  mcKiosko: boolean = false;
  mcDesayunos: boolean = false;
  mcPlayPlace: boolean = false;
  mcParking: boolean = false;
  mcDelivery: boolean = false;
  sub: any;
  unsubscribe: any;
  unsubscribe2: any;
  checkedInflu = false;

  isMcdsPA_P5= false;

  
  showInfluenceRestaurant: any = () => {
    this.dataService.showInfluenceRestaurant(this.checkedInflu, this.restaurant);
  };


  constructor(private dataService: DataService) {}

  ngOnInit() {
    const _this = this;
    let idPlace = this.dataService.getSelectedRestaurant();
    _this.unsubscribe = _this.dataService
      .getPlacesIdMcd(idPlace)
      .subscribe(d => {
        this.data = d;
        if(d.properties.areas){
          if(d.properties.areas.influence){
            this.dataService.setSelectedRestaurantInfluence(d.properties.areas.influence);
            this.checkedInflu = true;
            this.showInfluenceRestaurant();
          }
        }  
        
        if(this.data.properties.info.status == "Próxima apertura" || this.data.properties.info.status == "Plan 5 años"){
          this.isMcdsPA_P5 = true;
        }else{
          this.isMcdsPA_P5 = false;
        }        
        this.ready = true;
        this.restaurant = this.data.properties.info;
        this.mcCafe = this.checkIfServiceExists(this.restaurant.mccafe);
        this.mcAuto = this.checkIfServiceExists(this.restaurant.mcauto);
        this.mcExpress = this.checkIfServiceExists(this.restaurant.mcexpress);
        this.mcKiosko = this.checkIfServiceExists(this.restaurant.mckiosko);
        this.mcDesayunos = this.checkIfServiceExists(this.restaurant.breakfast);
        this.mcDelivery = this.checkIfServiceExists(this.restaurant.delivery);
        this.mcPlayPlace = this.checkIfServiceExists(this.restaurant.type_play);
        this.mcParking = this.checkIfServiceExists(
          this.restaurant.data_parking
        );


      });
    this.sub = this.dataService.restaurantSelectedDescriptiveChanged$.subscribe(
      r => {
        let idPlace = r;
        _this.unsubscribe2 = _this.dataService
          .getPlacesIdMcd(idPlace)
          .subscribe(d => {
            this.data = d;
            if(d.properties.areas){
              if(d.properties.areas.influence){
                this.dataService.setSelectedRestaurantInfluence(d.properties.areas.influence);
                this.checkedInflu = true;
                this.showInfluenceRestaurant();
              }
            }            
            
            if(this.data.properties.info.status == "Próxima apertura" || this.data.properties.info.status == "Plan 5 años"){
              this.isMcdsPA_P5 = true;
            }else{
              this.isMcdsPA_P5 = false;
            }
            this.ready = true;
            this.restaurant = this.data.properties.info;
            this.mcCafe = this.checkIfServiceExists(this.restaurant.mccafe);
            this.mcAuto = this.checkIfServiceExists(this.restaurant.mcauto);
            this.mcExpress = this.checkIfServiceExists(
              this.restaurant.mcexpress
            );
            this.mcKiosko = this.checkIfServiceExists(this.restaurant.mckiosko);
            this.mcDesayunos = this.checkIfServiceExists(
              this.restaurant.breakfast
            );
            this.mcDelivery = this.checkIfServiceExists(
              this.restaurant.delivery
            );
            this.mcPlayPlace = this.checkIfServiceExists(
              this.restaurant.type_play
            );
            this.mcParking = this.checkIfServiceExists(
              this.restaurant.data_parking
            );
          });
      }
    );

    
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
