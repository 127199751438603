import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatTable } from "@angular/material/table";

import { EditDialogCommercialCenterBoxComponent } from "./edit-dialog-commercial-center-box/edit-dialog-commercial-center-box.component";

// dummy data
import place_mall_original from "../../../../assets/data/place_mall_original.json";

@Component({
  selector: "app-geo-edit-commercial-center-table",
  templateUrl: "./geo-edit-commercial-center-table.component.html",
  styleUrls: ["./geo-edit-commercial-center-table.component.scss"]
})
export class GeoEditCommercialCenterTableComponent implements OnInit {
  displayedColumns: string[] = ["id_brand", "name",  "distance_tostudy","action"];

  @Input()
  inputCommercialCenter: any[];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Output() commercialCenterEdited: EventEmitter<any> = new EventEmitter();
  dataSource: any;
  data: any;

  // we are here

  constructor(public dialog: MatDialog) {}

  regExModelValue(val) {
    let result = val
    if(val!==undefined){
      result = val
      .replace(" ", "_")
      .replace(/\(|\)/g, "")
      .toLowerCase();
    }
    
    return result;
  }
  openDialog(action, obj) {
    obj.action = action;
    let model = {
      id_brand: obj.properties.info.id_brand,
      name: obj.properties.info.name,
      type: this.regExModelValue(obj.properties.info.type),
      sba_total: obj.properties.info.sba_total,
      cinemas: this.regExModelValue(obj.properties.info.cinemas),
      engine_type: this.regExModelValue(obj.properties.info.engine_type),
      engine_surface: obj.properties.info.engine_surface,
      total_commerce: obj.properties.info.total_commerce,
      sba_power_supply: obj.properties.info.sba_power_supply,
      sba_fashion_complement: obj.properties.info.sba_fashion_complement,
      sba_home_brico_electronic: obj.properties.info.sba_home_brico_electronic,
      sba_services: obj.properties.info.sba_services,
      sba_various: obj.properties.info.sba_various,
      sba_rest_leisure: obj.properties.info.sba_rest_leisure,

      address: obj.properties.info.address,
      distance_tostudy: obj.properties.distance_tostudy,
      exterior_shops: obj.properties.info.exterior_shops,
      interior_shops:obj.properties.info.interior_shops,
      main_commerce: obj.properties.info.main_commerce,
      main_commerce_surface: obj.properties.info.main_commerce_surface,
      total_parking_spaces: obj.properties.info.total_parking_spaces,
      engine: obj.properties.info.engine,
      transport_car: obj.properties.info.transport_car,
      transport_foot: obj.properties.info.transport_foot,
      transport_public: obj.properties.info.transport_public,
      sba_commerce: obj.properties.info.sba_commerce

    };

    obj["model"] = obj["model"] ? obj["model"] : model;

    const dialogRef = this.dialog.open(EditDialogCommercialCenterBoxComponent, {
      width: "60%",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Editar") {
        this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        this.deleteRowData(result.data);
      }
    });
  }

  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.properties.info.id_brand == row_obj.properties.info.id_brand) {
        if (row_obj.model) {
          value.properties.info.id_brand = row_obj.properties.info.id_brand;
          value.properties.info.name = row_obj.model.name;
          value.properties.info.type = row_obj.model.type;
          value.properties.info.sba_total = row_obj.model.sba_total;
          value.properties.info.cinemas = row_obj.model.cinemas;
          value.properties.info.engine_type = row_obj.model.engine_type;
          value.properties.info.engine_surface = row_obj.model.engine_surface;
          value.properties.info.total_commerce = row_obj.model.total_commerce;
          value.properties.info.sba_power_supply =
            row_obj.model.sba_power_supply;
          value.properties.info.sba_fashion_complement =
            row_obj.model.sba_fashion_complement;
          value.properties.info.sba_home_brico_electronic =
            row_obj.model.sba_home_brico_electronic;
          value.properties.info.sba_services = row_obj.model.sba_services;
          value.properties.info.sba_various = row_obj.model.sba_various;
          value.properties.info.sba_rest_leisure =
            row_obj.model.sba_rest_leisure;

          value.properties.info.address = row_obj.model.address;
          value.properties.info.distance_tostudy = row_obj.model.distance_tostudy;
          value.properties.info.exterior_shops = row_obj.model.exterior_shops;
          value.properties.info.interior_shops = row_obj.model.interior_shops;
          value.properties.info.main_commerce = row_obj.model.main_commerce;
          value.properties.info.main_commerce_surface = row_obj.model.main_commerce_surface;
          value.properties.info.total_parking_spaces = row_obj.model.total_parking_spaces;
          value.properties.info.engine = row_obj.model.engine;
          value.properties.info.transport_car = row_obj.model.transport_car;
          value.properties.info.transport_foot = row_obj.model.transport_foot;
          value.properties.info.transport_public = row_obj.model.transport_public;
          value.properties.info.sba_commerce = row_obj.model.sba_commerce;

        } else {
          value.properties.info.id_brand = row_obj.properties.info.id_brand;
          value.properties.info.name = row_obj.properties.info.name;
          value.properties.info.type = row_obj.properties.info.type;
          value.properties.info.sba_total = row_obj.properties.info.sba_total;
          value.properties.info.cinemas = row_obj.properties.info.cinemas;
          value.properties.info.engine_type =
            row_obj.properties.info.engine_type;
          value.properties.info.engine_surface =
            row_obj.properties.info.engine_surface;
          value.properties.info.total_commerce =
            row_obj.properties.info.total_commerce;
          value.properties.info.sba_power_supply =
            row_obj.properties.info.sba_power_supply;
          value.properties.info.sba_fashion_complement =
            row_obj.properties.info.sba_fashion_complement;
          value.properties.info.sba_home_brico_electronic =
            row_obj.properties.info.sba_home_brico_electronic;
          value.properties.info.sba_services =
            row_obj.properties.info.sba_services;
          value.properties.info.sba_various =
            row_obj.properties.info.sba_various;
          value.properties.info.sba_rest_leisure =
            row_obj.properties.info.sba_rest_leisure;

          value.properties.info.address = row_obj.properties.info.address;
          value.properties.info.distance_tostudy = row_obj.properties.info.distance_tostudy;
          value.properties.info.exterior_shops = row_obj.properties.info.exterior_shops;
          value.properties.info.interior_shops = row_obj.properties.info.interior_shops;
          value.properties.info.main_commerce = row_obj.properties.info.main_commerce;
          value.properties.info.main_commerce_surface = row_obj.properties.info.main_commerce_surface;
          value.properties.info.total_parking_spaces = row_obj.properties.info.total_parking_spaces;
          value.properties.info.engine = row_obj.properties.info.engine;
          value.properties.info.transport_car = row_obj.properties.info.transport_car;
          value.properties.info.transport_foot = row_obj.properties.info.transport_foot;
          value.properties.info.transport_public = row_obj.properties.info.transport_public;
          value.properties.info.sba_commerce = row_obj.properties.info.sba_commerce;
        }
      }
      return true;
    });
    this.commercialCenterEdited.emit(this.dataSource);
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.properties.info.id_brand != row_obj.properties.info.id_brand;
    });
    this.commercialCenterEdited.emit(this.dataSource);
  }

  ngOnInit() {
    this.dataSource = this.inputCommercialCenter;
  }

  ngOnChanges() {
    this.dataSource = this.inputCommercialCenter;
  }
}
