<mat-form-field>
  <input
    matInput
    (keyup)="applyFilter($event.target.value)"
    placeholder="Buscar"
  />
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort class="competencia">
    <!-- Position Column -->
    <!-- src="{{ image_route + element.id_brand }}.svg" -->
    <!-- src="{{ element.imageUrl }}" -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Enseña</th>
      <td mat-cell *matCellDef="let element">
        <img
          src="{{ element.imageUrl }}"
          alt=""
          height="32"
          width="32"
          style="margin-right: 8px"
        />{{ element.brand }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dirección</th>
      <td mat-cell *matCellDef="let element">{{ element.address }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="dist_meters_mcd_nearby">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Distancia</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dist_meters_mcd_nearby | number: "1.0-0":"es-ES" }} m
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
