import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { Optional } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

// JSON for FORM ELEMENTS
import centroComercialForm from "../../../../../assets/data/centro_comercial_form.json";
import { MatDialog } from "@angular/material/dialog";

import { AddDialogCommercialCenterBoxLocatorComponent } from "./add-dialog-commercial-center-box-locator/add-dialog-commercial-center-box-locator.component";

@Component({
  selector: "app-add-dialog-commercial-center-box",
  templateUrl: "./add-dialog-commercial-center-box.component.html",
  styleUrls: ["./add-dialog-commercial-center-box.component.scss"]
})
export class AddDialogCommercialCenterBoxComponent implements OnInit {
  action: string;
  local_data: any;
  formElements: Array<any> = centroComercialForm["values"];

  disabledButton: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AddDialogCommercialCenterBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.local_data = { ...data };
    // this.local_data.properties.info.addressCtrl = "";
    this.action = this.local_data.action;
  }


  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogCommercialCenterBoxLocatorComponent, {
      width: "750px",
      height: "",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      position: { top: "40px" },
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.local_data = result;
      if (result.event == "Añadir dirección") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        // this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        //  this.deleteRowData(result.data);
      }


    });
  }


  addRowData(row_obj) {
    // this.local_data = row_obj;
    this.local_data.addressCtrl = row_obj.addressCtrl;
    this.local_data.addressTotal = row_obj.addressCtrl + ', ' + row_obj.cityCtrl + ', ' + row_obj.provinceCtrl;
    this.local_data.city = row_obj.cityCtrl;
    this.local_data.province = row_obj.provinceCtrl;
    this.local_data.long = row_obj.lonCtrl;
    this.local_data.lat = row_obj.latCtrl;
    //this.local_data.properties.info.addressCtrl = this.local_data.addressTotal;
    this.local_data.properties.info.model.addressCtrl = this.local_data.addressTotal;


    this.local_data.properties.info.model.address = row_obj.addressCtrl;
    this.local_data.properties.info.model.address_view = row_obj.addressCtrl;
    this.local_data.properties.info.model.address_all = this.local_data.addressTotal;
    this.local_data.properties.info.model.city = row_obj.cityCtrl;
    this.local_data.properties.info.model.province = row_obj.provinceCtrl;
    this.local_data.properties.info.model.y = row_obj.latCtrl;
    this.local_data.properties.info.model.x = row_obj.lonCtrl;
    if (this.local_data.addressCtrl) {
      this.disabledButton = false;
    }

  }



  doAction() {
    if (this.local_data.properties.info.model.sba_total < 0) {
    } else if (this.local_data.properties.info.model.engine_surface < 0) {
    } else if (this.local_data.properties.info.model.total_commerce < 0) {
    } else if (this.local_data.properties.info.model.sba_power_supply < 0) {
    } else if (
      this.local_data.properties.info.model.sba_fashion_complement < 0
    ) {
    } else if (
      this.local_data.properties.info.model.sba_home_brico_electronic < 0
    ) {
    } else if (this.local_data.properties.info.model.sba_services < 0) {
    } else if (this.local_data.properties.info.model.sba_services < 0) {
    } else if (this.local_data.properties.info.model.sba_various < 0) {
    } else if (this.local_data.properties.info.model.sba_rest_leisure < 0) {
    } else {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancelar" });
  }

  ngOnInit() { }
}
