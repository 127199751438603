import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";

import { AddDialogBoxComponent } from "./add-dialog-box/add-dialog-box.component";

export interface UsersData {
  id: number;
  name: string;
  address: string;
  distance: number;
  distance_bbdd: number;
}

@Component({
  selector: "app-geo-add-competitors-table",
  templateUrl: "./geo-add-competitors-table.component.html",
  styleUrls: ["./geo-add-competitors-table.component.scss"]
})
export class GeoAddCompetitorsTableComponent implements OnInit {
  displayedColumns: string[] = ["name", "address",/* "distance", */"action"];
  dataSource: any[] = [];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Output() competitorsAdded: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogBoxComponent, {
      width: "320px",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Añadir") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    var d = new Date();
    switch (row_obj.distance_bbdd) {
      case "minutes_foot_00_03":
        row_obj.distance_bbdd = "minutes_foot_00_03";
        row_obj.distance = "0-3";
        break;
      case "minutes_foot_03_05":
        row_obj.distance_bbdd = "minutes_foot_03_05";
        row_obj.distance = "3-5";
        break;
      case "minutes_foot_05_10":
        row_obj.distance_bbdd = "minutes_foot_05_10";
        row_obj.distance = "5-10";
        break;
      case "minutes_foot_10_15":
        row_obj.distance_bbdd = "minutes_foot_10_15";
        row_obj.distance = "10-15";
        break;
      default:
        row_obj.distance_bbdd = "minutes_foot_00_03";
        row_obj.distance = "0-3";
        break;
    }
    this.dataSource.push({
      id: d.getTime(),
      name: row_obj.name.brand,
      id_brand: row_obj.name.id_brand,
      address: row_obj.addressCtrl,
      distance: row_obj.distance,
      distance_bbdd: row_obj.distance_bbdd,
      address_view: row_obj.addressCtrl,
      address_all: row_obj.addressTotal,
      city: row_obj.city,
      province: row_obj.province,
      lat: row_obj.lat,
      long: row_obj.long
    });

    this.competitorsAdded.emit(this.dataSource);

    this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value.name = row_obj.name.brand;
        value.id_brand = row_obj.name.id_brand;
        value.address = row_obj.addressCtrl;
        value.distance = row_obj.distance;

        value.address_view = row_obj.addressCtrl;
        value.address_all = row_obj.addressTotal;
        value.city = row_obj.city;
        value.province = row_obj.province;
        value.lat = row_obj.lat;
        value.long = row_obj.long;

        switch (row_obj.distance_bbdd) {
          case "minutes_foot_00_03":
            value.distance_bbdd = "minutes_foot_00_03";
            value.distance = "0-3";
            break;
          case "minutes_foot_03_05":
            value.distance_bbdd = "minutes_foot_03_05";
            value.distance = "3-5";
            break;
          case "minutes_foot_05_10":
            value.distance_bbdd = "minutes_foot_05_10";
            value.distance = "5-10";
            break;
          case "minutes_foot_10_15":
            value.distance_bbdd = "minutes_foot_10_15";
            value.distance = "10-15";
            break;
          default:
            value.distance_bbdd = "minutes_foot_00_03";
            value.distance = "0-3";
            break;
        }

      }
      return true;
    });
    this.competitorsAdded.emit(this.dataSource);
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
    this.competitorsAdded.emit(this.dataSource);
  }

  ngOnInit() { }
}
