export class User {
  active: any;
  authentication: any;
  company: any;
  country: any;
  createDate: any;
  data_access: any[];
  email: string;
  groups: any[];
  id: string;
  internal: any;
  name: string;
  password: string;
  surname: string;
  username: string;
  token: string;
  group: string;
}
