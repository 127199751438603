import { NgModule } from "@angular/core";
import { RotateArrowComponent } from "./rotate-arrow.component";

@NgModule({
  declarations: [RotateArrowComponent],
  imports: [],
  exports: [RotateArrowComponent],
  providers: []
})
export class RotateArrowModule {}
