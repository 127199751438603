<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">
        <h1 class="h1-page--title">{{ title }}</h1>
        <p>{{ paragraph }}</p>
      </div>
    </div>
  </div>
</section>

<section class="full-width clearfix">
  <form type='post' enctype="multipart/form-data" [formGroup]="bricksStudyFormGroup" (ngSubmit)="onSubmit()">
    <div class="section-container margin-top--l">
      <div class="row">
        <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">


          <mat-form-field class="full-width clearfix">
            <mat-label>Nombre estudio</mat-label>
            <input matInput type="textbox" (keydown.enter)="$event.preventDefault()" [(value)]="study_name"
              formControlName="study_name" required />
          </mat-form-field>

          <mat-form-field class="full-width clearfix">
            <mat-label>Tipo de inmueble</mat-label>
            <mat-select [(value)]="kind_building" formControlName="study_kind_building" required>
              <mat-option value="instore_u">Instore Urban</mat-option>
              <mat-option value="instore_m">Instore Medio</mat-option>
              <mat-option value="freestanding1">Free Standing 1</mat-option>
              <mat-option value="freestanding2">Free Standing 2</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix">
            <div>
              <input matInput hidden />
              <div>
                <input type="file" class="file-input" accept=".csv" (change)="onFileSelected($event)" #fileUpload
                  hidden>

                <div class="file-upload">
                  <label style="padding-right: 20px;"> {{fileName || "No se ha subido un csv aún."}} </label>
                  <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                    <mat-icon>attach_file</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-form-field>

          <div class="invalid-feedback" style="color: orange;" *ngIf="fileName=='' ">
            <sup>*</sup>Debe seleccionar un csv.
          </div>

          <div class="full-width clearfix margin-top--xl buttons" style="margin-bottom: 30px; ">
            <button mat-flat-button color="primary" type="submit" [disabled]="fileName==''">
              Calcular
            </button>
            <button mat-flat-button type="button" routerLink="/massive-prescriptive/studies">
              Cancelar
            </button>
          </div>



        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1"></div>
        <div class="col-xs-12 col-sm-12  col-md-5  col-lg-5">
          <h2 style="font-weight: bold;">Especificación del csv. </h2>
          <div style="font-size: 14px; line-height: 21px; font-weight: 400;">
            <ul>
              <li style="padding-bottom: 13px;">Para poder calcular correctamente estudios masivos, el csv con las
                localizaciones debe tener el siguiente formato:
              </li>
              <li> * Campos separados por símbolo <b style="font-weight: bold;">;</b> </li>
              <li> * Cabecera con el siguiente formato: <i style="font-style: italic;">ID;longitud;latitud</i> </li>
              <li> * Una fila con los datos anteriores por punto a calcular</li>
              <li> * Punto decimal para las coordenadas</li>
            </ul>
          </div>
          <div>
            <p>Ejemplo:</p>
            <img src="../../../assets/img/massive_study/csv_specification.jpg" alt="csv" width="350" height="50">
          </div>
        </div>
      </div>
    </div>

  </form>



</section>

<div class="margin-top--xl"></div>