import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/app-services/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser == null) {
      //logout
      this.authenticationService.logout();
    } else {
      if (!request.url.includes("google")) {
        if (!!currentUser.username) {
          if (!currentUser.token) {
            currentUser.token = btoa(
              currentUser.username + ":" + currentUser.password
            );
          }
          request = request.clone({
            setHeaders: {
              Authorization: `Basic ${currentUser.token}`
            }
          });
        }
      }
    }
    return next.handle(request);
  }
}
