import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getAllUsers(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/users/",
      {
        responseType: "json"
      }
    );
  }

  createUser(user: any): Observable<any> {
    return this.http
      .post(
        environment.postmanHost +
          environment.postmanDelimitator +
          environment.postmanPort +
          environment.postmanContext +
          "/user/",
        user,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .pipe(catchError(this.handleError));
  }

  editUser(user: any): Observable<any> {
    return this.http
      .put(
        environment.postmanHost +
          environment.postmanDelimitator +
          environment.postmanPort +
          environment.postmanContext +
          "/user/",
        user,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .pipe(catchError(this.handleError));
  }

  deleteUser(userId: any): Observable<any> {
    console.log(userId);
    // const options = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json"
    //   }),
    //   body: userId
    // };

    // //return this.http.delete(
    // return this.http.get(
    //   environment.postmanHost +
    //     // ":" +
    // environment.postmanDelimitator +
    //     environment.postmanPort +
    //     environment.postmanContext +
    //     // "/user/" +
    //     "/remove_user/" +
    //     userId +
    //     "/",
    //   options
    // );

    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/remove_user/" +
        userId +
        "/",
      {
        responseType: "json"
      }
    );
  }

  getUserById(userId: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/user/" +
        userId +
        "/",
      {
        responseType: "json"
      }
    );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred user:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `UserService Backend returned code ${error.status}, ` +
          `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
