import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { LiMassiveBricksComponent } from "./li-massive-bricks.component";
import { TabMassiveBuscadorModule } from "../tab-massive-buscador/tab-massive-buscador.module";
import { TabMassiveExpansionModule } from "../tab-massive-expansion/tab-massive-expansion.module";
import { TabTematicosModule } from "../tab-tematicos/tab-tematicos.module";

@NgModule({
  declarations: [LiMassiveBricksComponent],
  imports: [
    SharedModule,
    TabMassiveBuscadorModule,
    TabMassiveExpansionModule,
    TabTematicosModule
  ],
  exports: [LiMassiveBricksComponent],
  providers: []
})
export class LiMassiveBricksModule {}
