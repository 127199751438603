<!--
<mat-table
  [dataSource]="dataSource"
  matSort
  class=" mat-elevation-z0 competitorsOth"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
    <td mat-cell *matCellDef="let element">
      {{ element.info.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
    <td mat-cell *matCellDef="let element">
      {{ element.num }}
    </td>
  </ng-container>

  <ng-container matColumnDef="edited">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Editar</th>
    <td mat-cell *matCellDef="let element">
      <input
        matInput
        type="number"
        min="0"
        pattern="^[0-9]+"
        (change)="onEditedCompetitorOth($event, element)"
        (keydown.enter)="$event.preventDefault()"
        [value]="element.num_mod"
      />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</mat-table>
-->

<mat-table
  [dataSource]="dataSource"
  matSort
  class=" mat-elevation-z0 competitorsOth"
>
<ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Otros </th>
  <td mat-cell *matCellDef="let element">
    
  </td>
</ng-container>
  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
    <td mat-cell *matCellDef="let element">
      {{ element.num }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</mat-table>
