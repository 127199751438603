import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";
import { Observable, of } from "rxjs";
import { GeoStudiesPrescriptiveDataService } from "src/app/app-services/geo-studies-prescriptive-data.service";

@Injectable({
  providedIn: "root"
})
export class MassiveBrickMapResolverService implements Resolve<any> {
  constructor(private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService) {}

  resolve(
    routepmassive: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let idStudy = routepmassive.paramMap.get("idStudy");
    return this.geoStudiesPrescriptiveDataService.getBasicInfoStudyMassive(idStudy);
  }
}