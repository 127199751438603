import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { AuthenticationService } from "src/app/app-services/authentication.service";
import { AlertService } from "src/app/app-services/alert.service";
import { DataService } from "src/app/app-services/data.service";

@Component({
  selector: "app-login-card",
  templateUrl: "./login-card.component.html",
  styleUrls: ["./login-card.component.scss"]
})
export class LoginCardComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  login_user: any;
  login_password: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private dataService: DataService
  ) { }

  init: any = () => {
    this.init_login();
    this.init_inputs();
  }; // ends INIT

  init_login: any = () => {
    let open_login_button = document.getElementById("open_login_button");
    let close_login_button = document.getElementById("close_login_button");
    let login_modal = document.getElementById("login_modal");

    open_login_button.onclick = () => {
      login_modal.style.display = "block";
      document.getElementById("login_user").focus();
    };

    close_login_button.onclick = () => {
      login_modal.style.display = "none";
    };
    /*
        window.onclick = event => {
          if (event.target == login_modal) {
            login_modal.style.display = "none";
          }
        };
        */
  };
  init_inputs: any = () => {
    this.login_user = document.getElementById("login_user");
    this.login_password = document.getElementById("login_password");
    this.focusBlur(this.login_user);
    this.focusBlur(this.login_password);
  };

  focusBlur = input => {
    input.onfocus = () => {
      if (input.value == input.defaultValue) input.value = "";
    };

    input.onblur = () => {
      if (input.value == "") input.value = input.defaultValue;
    };
  };
  ngOnInit() {
    this.init();
  }

  // convenience getter for easy access to form fields
  // get f() {
  //   return this.loginForm.controls;
  // }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    // if (this.loginForm.invalid) {
    //   return;
    // }

    this.loading = true;
    this.authenticationService
      .login(this.login_user.value, this.login_password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(["/descriptive"]);
        },
        error => {
          this.alertService.error("Usuario o contraseña erróneos");
          this.loading = false;
        }
      );
  }
}
