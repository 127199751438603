<div mat-dialog-content>
  <p>Editar Grupo de visualización para el estudio: {{ message.properties.study.study_id }}</p>
</div>
<div class="margin-top--s">
  <form [formGroup]="userForm" (ngSubmit)="onSubmit(message)">
  <mat-form-field class="full-width">
    <mat-label>Rol del usuario</mat-label>
    <mat-select formControlName="userProfile" required>
      <mat-option *ngFor="let u of userProfiles" [value]="u.value">
        {{ u.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial type="submit">
      Guardar cambios
    </button>
    <button mat-flat-button mat-dialog-close color="warn" (click)="onNoClick()">Cancelar</button>
  </div>
  </form>
</div>
