import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { McdGeoLocatorMapComponent } from "./mcdGeoLocatorMap.component";

@NgModule({
  declarations: [McdGeoLocatorMapComponent],
  imports: [SharedModule],
  exports: [McdGeoLocatorMapComponent],
  providers: []
})
export class McdGeoLocatorMapModule {}
