import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { OptionsFromJsonModule } from "../../../app-widgets/form-controls/options-from-json/options-from-json.module";

import { DataVentasComponent } from "./data-ventas.component";
import { StackedBarModule } from "../../../app-widgets/charts/stacked-bar/stacked-bar.module";
import { VerticalBarsModule } from "../../../app-widgets/charts/vertical-bars/vertical-bars.module";

@NgModule({
  imports: [
    SharedModule,
    OptionsFromJsonModule,
    VerticalBarsModule,
    StackedBarModule
  ],
  exports: [DataVentasComponent],
  declarations: [DataVentasComponent],
  providers: []
})
export class VentasModule {}
