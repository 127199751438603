import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-brick-section-two',
  templateUrl: './brick-section-two.component.html',
  styleUrls: ['./brick-section-two.component.scss']
})
export class BrickSectionTwoComponent implements OnInit {
  @Input() setup: any;
  sectionID: number;
  constructor() { }

  convertASCII(originalText: any) {
    let indexes = [];
    let finalText = '';
    let codHTMLNumber = '';
    let codASCII = null;
    let numStringSpacerStart = 0;
    let numStringSpacerEnd = 0;

    if(originalText){

      // Contamos el número de códgos HTML Numero que se encuentran en la cadena de texto.
      for (let i = 0; i < originalText.length; i++) {
        if(originalText[i] === '&') indexes.push(i);
      }

      //ITERACIÓN: Asignando texto formateado a 'finalText'.
      for (let x = 0; x <= indexes.length; x++) {

        // Buscamos por cada iteración el primer HTML Numero (&#num;).
        numStringSpacerStart = originalText.indexOf('&');
        numStringSpacerEnd = originalText.indexOf(';');

        // CONDIOCIONAL: se accede mientras exista algún HTML Número.
        if(numStringSpacerEnd > 0){
          finalText += originalText.substring(0, numStringSpacerStart);
          codHTMLNumber = originalText.substring(numStringSpacerStart, numStringSpacerEnd + 1);

          codASCII = codHTMLNumber.substring(2, codHTMLNumber.length - 1);

          originalText = originalText.substring(numStringSpacerEnd + 1, originalText.length);

          finalText += String.fromCharCode(codASCII);
        }else{ // se accede si no existe ningún HTML Número.
          finalText += originalText;
        }

        // RESETEO: reseteando variables.
        numStringSpacerStart = 0;
        numStringSpacerEnd = 0;
      }
      return finalText;
    }else{
      return 'hola';
    }
  } 
  ngOnInit(): void {
    this.sectionID = this.setup.id;
  }

}
