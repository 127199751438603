import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { StackedBarComponent } from "./stacked-bar.component";

@NgModule({
  declarations: [StackedBarComponent],
  imports: [SharedModule],
  exports: [StackedBarComponent],
  providers: []
})
export class StackedBarModule {}
