import { Injectable } from "@angular/core";
import * as d3 from "d3";
import { D3MainService } from "./d3-main.service";

@Injectable({
  providedIn: "root"
})
export class D3LayoutService {
  d3Service = this.d3MainService;
  COLORS = this.d3Service.COLORS;
  formatZero: any = this.d3Service.FORMATZERO;
  formatMillions: any = this.d3Service.formatMillions;
  tooltipValues: any = {
    font_size: "12px",
    background_color: this.COLORS.white,
    padding: "8px",
    position: "absolute",
    z_index: 99999,
    font_weight: "bold",
    text_anchor: "middle",
    fill_text: this.COLORS.black,
    opacity_lowest: 0.5,
    high_opacity: 1
  };
  constructor(private d3MainService: D3MainService) { }

  public d3Layout = [
    {
      name: "ventas",
      id: "sales",
      container: null,
      type: "table",
      selectors: [{}],
      tooltip: {},

      legend: [],
      units: [{}],
      xVal: null,
      yVal: null
    },
    {
      name: "ventas por canal",
      id: "percentages_sales",
      type: "stacked-chart",
      container: "#ventas_canal_stacked",
      selectors: [],
      design: {
        height: null,
        width: null,
        margin: { top: 16, right: 160, bottom: 42, left: 56 },

        stroke: {
          width: null,
          stroke: this.COLORS.gray_extraLight,
          linecap: null
        },
        bar: {
          fill: null,
          r: null
        },
        text: {
          fill: this.COLORS.gray_dark
        },
        style: {
          colors: [
            this.COLORS.red_dark,
            this.COLORS.red_orange,
            this.COLORS.yellow_orange,
            this.COLORS.yellow_green
          ],

          paddingInner: 0.05,
          padding: null,
          align: 0.1,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "percentages_sales",
        size: {
          width: null,
          height: null,
          x: 24,
          y: -24,
          rx: null,
          ry: null
        },
        style: this.tooltipValues,

        text: {}
      },
      legend: [
        { key: "perc_mostrador", value: "Mostrador" },
        { key: "perc_mcauto", value: "McAuto" },
        { key: "perc_mccafe", value: "McCafe" },
        { key: "perc_kiosko", value: "Kiosko" }
      ],
      units: [{ name: "porcentaje", symbol: "%" }],
      xVal: [{ key: "Canales", values: null }],
      yVal: [{ key: "Porcentaje", values: null, format: this.formatZero }]
    },
    {
      name: "importe de ventas",
      id: "time_sales",
      type: "vertical-bars",
      container: "#time_sales",

      selectors: [
        {
          id: "temporal_select",
          indicator: "time_sales",
          name: "temporal_select",
          options: [
            { key: "half_hour_sales", selected: true },
            { key: "week_sales", selected: false },
            { key: "month_sales", selected: false },
            { key: "trimester_sales", selected: false }
          ],
          widget: "options-from-json",
          widget_num: 1,
          selected: ["0"],
          isMultiple: false,
          display: true,
          enabled: true,
          placeholder: "División temporal"
        },
        {}
      ],

      legend: [
        {
          key: "half_hour_sales",
          name: "Ventas por hora",
          value: "0",
          children: [
            { key: "average_sales_hour0", name: "0h", value: "0" },
            { key: "average_sales_hour1", name: "1h", value: "1" },
            { key: "average_sales_hour2", name: "2h", value: "2" },
            { key: "average_sales_hour3", name: "3h", value: "3" },
            { key: "average_sales_hour4", name: "4h", value: "4" },
            { key: "average_sales_hour5", name: "5h", value: "5" },
            { key: "average_sales_hour6", name: "6h", value: "6" },
            { key: "average_sales_hour7", name: "7h", value: "7" },
            { key: "average_sales_hour8", name: "8h", value: "8" },
            { key: "average_sales_hour9", name: "9h", value: "9" },
            { key: "average_sales_hour10", name: "10h", value: "10" },
            { key: "average_sales_hour11", name: "11h", value: "11" },
            { key: "average_sales_hour12", name: "12h", value: "12" },
            { key: "average_sales_hour13", name: "13h", value: "13" },
            { key: "average_sales_hour14", name: "14h", value: "14" },
            { key: "average_sales_hour15", name: "15h", value: "15" },
            { key: "average_sales_hour16", name: "16h", value: "16" },
            { key: "average_sales_hour17", name: "17h", value: "17" },
            { key: "average_sales_hour18", name: "18h", value: "18" },
            { key: "average_sales_hour19", name: "19h", value: "19" },
            { key: "average_sales_hour20", name: "20h", value: "20" },
            { key: "average_sales_hour21", name: "21h", value: "21" },
            { key: "average_sales_hour22", name: "22h", value: "22" },
            { key: "average_sales_hour23", name: "23h", value: "23" }
          ]
        },
        {
          key: "week_sales",
          name: "Ventas por día",
          value: "1",
          children: [
            { key: "monday_sales", name: "Lunes", value: "Lu" },
            { key: "tuesday_sales", name: "Martes", value: "Ma" },
            { key: "wednesday_sales", name: "Miércoles", value: "Mi" },
            { key: "thursday_sales", name: "Jueves", value: "Ju" },
            { key: "friday_sales", name: "Viernes", value: "Vi" },
            { key: "saturday_sales", name: "Sábado", value: "Sa" },
            { key: "sunday_sales", name: "Domingo", value: "Do" }
          ]
        },
        {
          key: "month_sales",
          name: "Ventas por mes",
          value: "2",
          children: [
            { key: "january_sales", name: "Enero", value: "Ene" },
            { key: "february_sales", name: "Febrero", value: "Feb" },
            { key: "march_sales", name: "Marzo", value: "Mar" },
            { key: "april_sales", name: "Abril", value: "Abr" },
            { key: "may_sales", name: "Mayo", value: "May" },
            { key: "june_sales", name: "Junio", value: "Jun" },
            { key: "july_sales", name: "Julio", value: "Jul" },
            { key: "august_sales", name: "Agosto", value: "Ago" },
            { key: "september_sales", name: "Septiembre", value: "Sep" },
            { key: "october_sales", name: "Octubre", value: "Oct" },
            { key: "november_sales", name: "Noviembre", value: "Nov" },
            { key: "december_sales", name: "Diciembre", value: "Dic" }
          ]
        },

        {
          key: "trimester_sales",
          name: "Ventas por trimestre",
          value: "3",
          children: [
            { key: "sales_trimester1", name: "I-T", value: "IT" },
            { key: "sales_trimester2", name: "II-T", value: "IIT" },
            { key: "sales_trimester3", name: "III-T", value: "IIIT" },
            { key: "sales_trimester4", name: "IV-T", value: "IVT" }
          ]
        }
      ],
      units: [{ name: "Eur", value: "€" }],
      dictionary: "controls-select",
      xVal: {
        name: "name",
        value: [
          "half_hour_sales",
          "week_sales",
          "month_sales",
          "trimester_sales"
        ]
      },
      yVal: { name: "value", value: ["Euros"] },

      design: {
        height: null,
        width: null,
        margin: { top: 8, right: 8, bottom: 42, left: 66 },

        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        bar: {
          fill: this.COLORS.yellow_green,
          r: null
        },
        style: {
          colors: {
            yellow_green: this.COLORS.yellow_green,
            gray_dark: this.COLORS.gray_dark,
            gray_extraLight: this.COLORS.gray_extraLight
          },

          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1,
          legend: {
            fontSize: "10px"
          },
          averages: {}
        },
        keys: []
      },
      tooltip: {
        tooltip: "time_sales",
        size: {
          width: 80,
          height: 40,
          x: -48,
          y: -12,
          rx: 4,
          ry: 4
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    {
      name: "Pirámide de población",
      id: "population_pyramid_2017",
      container: "#population_pyramid",
      type: "age pyramid",
      selectors: [{}],
      legend: {
        bars: [
          { key: "rightBar", value: "Mujeres" },
          { key: "leftBar", value: "Hombres" }
        ],
        lines: [{ key: "line", value: "Media en España" }]
      },
      units: [{}],
      xVal: null,
      yVal: null,
      design: {
        height: 236,
        width: 685,
        margin: {
          top: 16,
          right: 10,
          bottom: 20,
          left: 10,
          middle: 20
        },
        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        circle: {
          fill: this.COLORS.gray_medium,
          r: 3
        },
        style: {
          leftBarColor: this.COLORS.blue2,
          rightBarColor: this.COLORS.purple1,
          leftLineColor: this.COLORS.blue4,
          rightLineColor: this.COLORS.purple4,
          leftDotColor: this.COLORS.blue4,
          rightDotColor: this.COLORS.purple4,
          tooltipBG: this.COLORS.white,
          tooltipColor: this.COLORS.black
        },
        keys: {
          rightBar: "mujer",
          leftBar: "hombre",
          rigthLine: "spain_mujer",
          leftLine: "spain_hombre",
          rightDot: "spain_mujer",
          leftDot: "spain_hombre",
          index: "edad"
        }
      },

      tooltip: {
        tooltip: "pyramid_tooltip",
        style: this.tooltipValues,
        text: {
          rightBar: {
            area: "Estudio",
            index: "Mujer",
            key: "años",
            total: "del total"
          },
          leftBar: {
            area: "Estudio",
            index: "Hombre",
            key: " años",
            total: "del total"
          },
          rightDot: {
            area: "España",
            index: "Mujer",
            key: " años",
            total: "del total"
          },
          leftDot: {
            area: "España",
            index: "Hombre",
            key: "años",
            total: "del total"
          }
        }
      }
    },
    {
      name: "Tipología de viviendas",
      id: "housing_typology",
      container: "#housing_typology",
      type: "grouped bar chart",
      selectors: [
        {
          id: "area_select",
          indicator: "housing_typology",
          name: "area_select",
          options: [
            { key: "3min", selected: true },
            { key: "5min", selected: false },
            { key: "10min", selected: false },
            { key: "15min", selected: false }
          ],
          widget: "options-from-json",
          widget_num: 1,
          selected: ["0"],
          isMultiple: false,
          display: true,
          enabled: true,
          placeholder: "Tiempos"
        },
        {}
      ],

      legend: {
        bars: [
          { key: "3min", name: "3 min. coche", value: "0", children: [] },
          { key: "5min", name: "5 min. coche", value: "1", children: [] },
          { key: "10min", name: "10 min. coche", value: "2", children: [] },
          { key: "15min", name: "15 min. coche", value: "3", children: [] }
        ],
        lines: [{}]
      },
      units: [{ name: "percentage", value: "%" }],
      xVal: {
        name: "tipologías",
        value: "Tipologías",
        columns: [
          {
            id: "0",
            data: {
              zone: "Zona",
              municipio: "Municipio",
              provincia: "Provincia",
              pais: "España"
            }
          }
        ]
      },

      yVal: { name: "En %", value: "En %" },
      design: {
        height: null,
        width: null,
        margin: {
          top: 40,
          right: 8,
          bottom: 40,
          left: 40
        },
        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        circle: {
          fill: null,
          r: null
        },
        style: {
          colors: [
            this.COLORS.orange,
            this.COLORS.yellow_green,
            this.COLORS.blue2,
            this.COLORS.red_dark
          ],
          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1
        },
        keys: ["Colectiva", "Otro", "Vacía", "Secundaria", "Principal"],
        sort: [{ key: "zone", value: "Zona del estudio" }]
      },

      tooltip: {
        tooltip: "housing_typology",
        style: this.tooltipValues,
        text: {}
      }
    },

    {
      name: "Hogares vs población",
      id: "housing_vs_population",
      container: "#housing_vs_population",
      type: "scatterplot",
      selectors: [],

      legend: {
        bars: [
          { key: "3min", name: "3 min. coche", value: "0", children: [] },
          { key: "5min", name: "5 min. coche", value: "1", children: [] },
          { key: "10min", name: "10 min. coche", value: "2", children: [] },
          { key: "15min", name: "15 min. coche", value: "3", children: [] }
        ],
        lines: [
          {
            key: "average",
            name: "Media",
            value: "habitantes/hogar",
            children: []
          }
        ]
      },
      units: [{ name: "número", value: "Número" }],
      xVal: {
        name: "population",
        value: "Población"
      },
      yVal: { name: "housing", value: "Hogares" },
      design: {
        height: null,
        width: null,
        margin: {
          top: 16,
          right: 8,
          bottom: 36,
          left: 70
        },
        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        circle: {
          fill: this.COLORS.gray_medium,
          r: 6
        },
        style: {
          colors: [
            this.COLORS.orange,
            this.COLORS.yellow_green,
            this.COLORS.blue2,
            this.COLORS.red_dark
          ],
          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1,
          legend: {
            fontSize: "10px"
          },
          averages: {
            axisColor: this.COLORS.gray_medium,
            circleColor: this.COLORS.gray_dark
          }
        },
        keys: ["Media", "Tiempos"]
      },

      tooltip: {
        tooltip: "housing_vs_population",
        style: this.tooltipValues,
        text: {}
      }
    },

    {
      name: "Ingresos por hogar",
      id: "income_per_household",
      container: "#income_per_household",
      type: "vertical-bars",
      selectors: [],

      legend: [
        {
          key: "income_per_household",
          name: "Ingresos por hogar",
          value: "0",
          children: [
            { key: "3min", name: "3 min. coche", value: "0", children: [] },
            { key: "5min", name: "5 min. coche", value: "1", children: [] },
            { key: "10min", name: "10 min. coche", value: "2", children: [] },
            { key: "15min", name: "15 min. coche", value: "3", children: [] }
          ]
        }
      ],

      units: [{ name: "Eur", value: "€" }],
      dictionary: "wording",

      xVal: {
        name: "name",
        value: ["income_per_household"],
        ticks: null
      },
      yVal: { name: "value", value: ["Euros"], ticks: 3 },

      design: {
        height: null,
        width: null,
        margin: { top: 8, right: 8, bottom: 36, left: 66 },

        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        bar: {
          fill: this.COLORS.yellow_green,
          r: null
        },
        style: {
          colors: {
            yellow_green: this.COLORS.yellow_green,
            gray_dark: this.COLORS.gray_dark,
            gray_extraLight: this.COLORS.gray_extraLight
          },

          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1,
          legend: {
            fontSize: "10px"
          },
          averages: {}
        },
        keys: []
      },

      tooltip: {
        tooltip: "income_per_household",
        size: {
          width: 80,
          height: 40,
          x: -48,
          y: -12,
          rx: 4,
          ry: 4
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    {
      name: "Tasa de paro",
      id: "unemployment_rate",
      container: "#unemployment_rate",
      type: "vertical-bars",
      selectors: [],

      legend: [
        {
          key: "unemployment_rate",
          name: "Tasa de paro",
          value: "0",
          children: [
            { key: "3min", name: "3 min. coche", value: "0", children: [] },
            { key: "5min", name: "5 min. coche", value: "1", children: [] },
            { key: "10min", name: "10 min. coche", value: "2", children: [] },
            { key: "15min", name: "15 min. coche", value: "3", children: [] }
          ]
        }
      ],

      units: [{ name: "Porcentaje", value: "%" }],
      dictionary: "wording",
      xVal: {
        name: "name",
        value: ["unemployment_rate"],
        ticks: null
      },
      yVal: { name: "value", value: ["Euros"], ticks: 3 },

      design: {
        height: null,
        width: null,
        margin: { top: 8, right: 8, bottom: 36, left: 66 },

        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        bar: {
          fill: this.COLORS.yellow_green,
          r: null
        },
        style: {
          colors: {
            yellow_green: this.COLORS.yellow_green,
            gray_dark: this.COLORS.gray_dark,
            gray_extraLight: this.COLORS.gray_extraLight
          },

          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1,
          legend: {
            fontSize: "10px"
          },
          averages: {}
        },
        keys: []
      },

      tooltip: {
        tooltip: "unemployment_rate",
        size: {
          width: 80,
          height: 40,
          x: -48,
          y: -12,
          rx: 4,
          ry: 4
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    // PRESENCE LAYOUT
    {
      name: "Presencia: segmentación",
      id: "presence_segmentation",
      container: "#presence_segmentation",
      type: "grouped bar chart",
      selectors: null,
      legend: [],
      units: [{ name: "Number", value: "Nª" }],
      xVal: {
        name: "segmentación",
        value: "Segmentación",
        columns: [
          {
            id: "activity",
            data: {
              H: "Casa",
              NF: "No frecuente",
              O: "Frecuente",
              W: "Trabajo"
            }
          },
          {
            id: "gender",
            data: {
              Female: "Mujer",
              Male: "Hombre"
            }
          },
          {
            id: "age",
            data: {
              E0: "16-29",
              E1: "30-49",
              E2: "50-64",
              E3: "+65"
            }
          }
        ]
      },
      yVal: { name: "Personas / día", value: "Personas al día" },
      design: {
        height: null,
        width: null,
        margin: { top: 24, right: 8, bottom: 36, left: 72 },
        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        bar: {
          fill: this.COLORS.yellow_green,
          r: null
        },
        style: {
          colors: [
            this.COLORS.orange,
            this.COLORS.yellow_green,
            this.COLORS.blue2,
            this.COLORS.red_dark
          ],
          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1,
          legend: {
            fontSize: "10px"
          },
          averages: {}
        }
      },
      tooltip: {
        tooltip: "null",
        size: {
          width: 80,
          height: 40,
          x: -48,
          y: -12,
          rx: 4,
          ry: 4
        },
        style: this.tooltipValues,
        text: {}
      }
    },

    {
      name: "Presencia: residencia selectors",
      id: "presence_residence_selectors",
      type: "selectors",
      selectors: null
    },

    {
      name: "Tráfico: residencia selectors",
      id: "traffic_residence_selectors",
      type: "selectors",
      selectors: null
    },

    {
      name: "Presencia: residencia Spain",
      id: "presence_residence_spain",
      container: "#presence_residence_spain",
      type: "treemap",
      selectors: null,
      legend: [],
      units: [{ name: "", value: "" }],
      dictionary: "provinces",
      xVal: null,
      yVal: null,
      design: {
        height: null,
        width: null,
        margin: { top: 0, right: 0, bottom: 0, left: 0 },

        stroke: {
          width: null,
          stroke: null
        },
        bar: {
          fill: null,
          r: null
        },
        style: {
          colors: null,

          paddingInner: null,
          padding: null,
          align: null,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "null",
        size: {
          width: 80,
          height: 60,
          x: 0,
          y: 0,
          rx: 0,
          ry: 0
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    {
      name: "Presencia: residencia extranjeros",
      id: "presence_residence_abroad",
      container: "#presence_residence_abroad",
      type: "treemap",
      selectors: null,
      legend: [],
      units: [{ name: "", value: "" }],
      dictionary: "countries",

      xVal: null,
      yVal: null,
      design: {
        height: null,
        width: null,
        margin: { top: 0, right: 0, bottom: 0, left: 0 },

        stroke: {
          width: null,
          stroke: null
        },
        bar: {
          fill: null,
          r: null
        },
        style: {
          colors: null,

          paddingInner: null,
          padding: null,
          align: null,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "null",
        size: {
          width: 80,
          height: 60,
          x: 0,
          y: 0,
          rx: 0,
          ry: 0
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    {
      name: "Presencia: residentes",
      id: "presence_residence_total",
      type: "stacked-chart",
      container: "#presence_residence_total",
      selectors: null,
      design: {
        height: null,
        width: null,
        margin: { top: 32, right: 56, bottom: 46, left: 56 },

        stroke: {
          width: null,
          stroke: this.COLORS.gray_extraLight,
          linecap: null
        },
        bar: {
          fill: null,
          r: null
        },
        text: {
          fill: this.COLORS.gray_dark
        },
        style: {
          colors: [
            this.COLORS.red_dark,
            this.COLORS.red_orange,
            this.COLORS.yellow_orange,
            this.COLORS.yellow_green
          ],

          paddingInner: 0.05,
          padding: null,
          align: 0.1,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "presence_residence_total",
        size: {
          width: null,
          height: null,
          x: 80,
          y: -4,
          rx: null,
          ry: null
        },
        style: this.tooltipValues,

        text: {}
      },
      legend: null,
      units: [{ name: "", symbol: "" }],
      xVal: [{ key: "Personas / día", values: null }],
      yVal: [{ key: "", values: null, format: this.formatZero }]
    },

    // TRAFFIC LAYOUT
    {
      name: "Tráfico: segmentación",
      id: "traffic_segmentation",
      container: "#traffic_segmentation",
      type: "grouped bar chart",
      selectors: null,

      legend: [],

      units: [{ name: "Number", value: "Nª" }],
      xVal: {
        name: "segmentación",
        value: "Segmentación",
        columns: [
          {
            id: "activity_origin",
            data: {
              H: "Casa",
              NF: "No frecuente",
              O: "Frecuente",
              W: "Trabajo"
            }
          },
          {
            id: "activity_destination",
            data: {
              H: "Casa",
              NF: "No frecuente",
              O: "Frecuente",
              W: "Trabajo"
            }
          },
          {
            id: "gender",
            data: {
              Female: "Mujer",
              Male: "Hombre"
            }
          },
          {
            id: "age",
            data: {
              E0: "16-29",
              E1: "30-49",
              E2: "50-64",
              E3: "+65"
            }
          }
        ]
      },
      yVal: { name: "Personas / día", value: "Personas al día" },
      design: {
        height: null,
        width: null,
        margin: { top: 24, right: 8, bottom: 36, left: 72 },

        stroke: {
          width: 0.2,
          stroke: this.COLORS.gray_light
        },
        bar: {
          fill: this.COLORS.yellow_green,
          r: null
        },
        style: {
          colors: [
            this.COLORS.orange,
            this.COLORS.yellow_green,
            this.COLORS.blue2,
            this.COLORS.red_dark
          ],

          paddingInner: 0.2,
          padding: 0.05,
          align: 0.1,
          legend: {
            fontSize: "10px"
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "null",
        size: {
          width: 80,
          height: 40,
          x: -48,
          y: -12,
          rx: 4,
          ry: 4
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    {
      name: "Tráfico: residentes",
      id: "traffic_residence_total",
      type: "stacked-chart",
      container: "#traffic_residence_total",
      selectors: null,
      design: {
        height: null,
        width: null,
        margin: { top: 32, right: 56, bottom: 46, left: 56 },

        stroke: {
          width: null,
          stroke: this.COLORS.gray_extraLight,
          linecap: null
        },
        bar: {
          fill: null,
          r: null
        },
        text: {
          fill: this.COLORS.gray_dark
        },
        style: {
          colors: [
            this.COLORS.red_dark,
            this.COLORS.red_orange,
            this.COLORS.yellow_orange,
            this.COLORS.yellow_green
          ],

          paddingInner: 0.05,
          padding: null,
          align: 0.1,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "traffic_residence_total",
        size: {
          width: null,
          height: null,
          x: 80,
          y: -4,
          rx: null,
          ry: null
        },
        style: this.tooltipValues,

        text: {}
      },
      legend: null,
      units: [{ name: "", symbol: "" }],
      xVal: [{ key: "Personas / día", values: null }],
      yVal: [{ key: "", values: null, format: this.formatZero }]
    },

    {
      name: "Tráfico: residencia Spain",
      id: "traffic_residence_spain",
      container: "#traffic_residence_spain",
      type: "treemap",
      selectors: null,
      legend: [],
      units: [{ name: "", value: "" }],
      dictionary: "provinces",

      xVal: null,
      yVal: null,
      design: {
        height: null,
        width: null,
        margin: { top: 0, right: 0, bottom: 0, left: 0 },

        stroke: {
          width: null,
          stroke: null
        },
        bar: {
          fill: null,
          r: null
        },
        style: {
          colors: null,

          paddingInner: null,
          padding: null,
          align: null,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "null",
        size: {
          width: 80,
          height: 60,
          x: 0,
          y: 0,
          rx: 0,
          ry: 0
        },
        style: this.tooltipValues,

        text: {}
      }
    },

    {
      name: "Traffic: residencia extranjeros",
      id: "traffic_residence_abroad",
      container: "#traffic_residence_abroad",
      type: "treemap",
      selectors: null,
      legend: [],
      units: [{ name: "", value: "" }],
      dictionary: "countries",
      xVal: null,
      yVal: null,
      design: {
        height: null,
        width: null,
        margin: { top: 0, right: 0, bottom: 0, left: 0 },

        stroke: {
          width: null,
          stroke: null
        },
        bar: {
          fill: null,
          r: null
        },
        style: {
          colors: null,

          paddingInner: null,
          padding: null,
          align: null,
          legend: {
            fontSize: null
          },
          averages: {}
        }
      },

      tooltip: {
        tooltip: "null",
        size: {
          width: 80,
          height: 60,
          x: 0,
          y: 0,
          rx: 0,
          ry: 0
        },
        style: this.tooltipValues,

        text: {}
      }
    }
  ];

  ngOnChanges(changes: any) { }
}
