import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";
import { GeoMapRoutingModule } from "./geo-map-routing.module";
import { McdGeoMakerModule } from "../../app-widgets/cartography/mcdGeoMaker/mcdGeoMaker.module";
import { SearchboxModule } from "../../app-widgets/form-controls/searchbox/searchbox.module";
import { DummyMarkerModule } from "../../app-widgets/buttons/dummy-marker/dummy-marker.module";

import { SidenavStartLeftModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-start-left.module";
import { UlSitesModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-restaurants/ul-sites/ul-sites.module";

import { SidenavEndRightModule } from "../../app-widgets/navigation/sidenav-end-right/sidenav-end-right.module";
import { SidenavStudyModule } from "../geo-map/sidenav-study/sidenav-study.module";

@NgModule({
  declarations: [GeoMapRoutingModule.components],
  imports: [
    SharedModule,
    GeoMapRoutingModule,
    McdGeoMakerModule,
    SearchboxModule,
    DummyMarkerModule,
    SidenavStartLeftModule,
    UlSitesModule,
    SidenavEndRightModule,
    SidenavStudyModule
  ],
  exports: [GeoMapRoutingModule.components],
  providers: []
})
export class GeoMapModule {}
