import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-brick-studies",
  templateUrl: "./brick-studies.component.html",
  styleUrls: ["./brick-studies.component.scss"]
})
export class BrickStudiesComponent implements OnInit {
  title: string = "Estudios de potencial de ventas";
  paragraph: string = "Proyectos para nuevas aperturas McDonald's.";

  userAuthorized: boolean = false;
  
  constructor() {}

  ngOnInit() {

    //get user
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      if (user.group != "advance") {
        this.userAuthorized = false;
      } else {
        this.userAuthorized = true;
      }
    }

  }
}
