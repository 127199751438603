import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-buscador',
  templateUrl: './tab-buscador.component.html',
  styleUrls: ['./tab-buscador.component.scss']
})
export class TabBuscadorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
