import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EditUserComponent } from "./edit-user.component";
import { EditUserResolverService } from "./edit-user-resolver.service";

const routes: Routes = [
  //{ path: "users/edit", component: EditUserComponent, canActivate: [] },
  {
    path: "users/edit/:idUser",
    component: EditUserComponent,
    // resolve: {
    //   mappedParams: EditUserResolverService
    // }
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditUserRoutingModule {
  static components = [EditUserComponent];
}
