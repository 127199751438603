<app-mcdGeoMaker  mapId="prescriptive_map"></app-mcdGeoMaker>

<app-dummy-marker></app-dummy-marker>
<app-searchbox></app-searchbox>
<app-sidenav-start-left [showSidenavStartLeft]="showSidenavStartLeft">
  <app-ul-sites></app-ul-sites>
</app-sidenav-start-left> 


<app-sidenav-end-right [showSidenavEndRight]="showSidenavEndRight" [isPrescriptive]="isPrescriptive"> 
  <app-sidenav-bricks></app-sidenav-bricks>
</app-sidenav-end-right>


<!--<app-dummy-marker></app-dummy-marker>
-->


