import { Component } from "@angular/core";
import { OnInit } from "@angular/core";

@Component({
  selector: "app-rotate-arrow",
  templateUrl: "./rotate-arrow.component.html",
  styleUrls: ["./rotate-arrow.component.scss"]
})
export class RotateArrowComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
