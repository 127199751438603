import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { McdGeoMakerModule } from "../../app-widgets/cartography/mcdGeoMaker/mcdGeoMaker.module";

import { MassiveBrickMapComponent } from "./massive-brick-map.component";
import { MassiveBrickMapResolverService } from "./massive-brick-map-resolver.service";

const routes: Routes = [
  {
    path: "massive-prescriptive/map",
    component: MassiveBrickMapComponent,
    canActivate: []
  },
  {
    path: "massive-prescriptive/map/study/:idStudy",
    component: MassiveBrickMapComponent,
    resolve: {
      mappedParams: MassiveBrickMapResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), McdGeoMakerModule],
  exports: [RouterModule, McdGeoMakerModule]
})
export class MassiveBrickMapRoutingModule {
  static components = [MassiveBrickMapComponent];
}
