import { Component, OnInit } from "@angular/core";

import { DataService } from "src/app/app-services/data.service";

@Component({
  selector: "app-searchbox",
  templateUrl: "./searchbox.component.html",
  styleUrls: ["./searchbox.component.scss"]
})
export class SearchboxComponent implements OnInit {
  searchQuery: string;

  constructor(private dataService: DataService) {}

  ngOnInit() {}

  searchboxDescriptive: any = () => {
    let params = this.searchQuery;
    this.dataService.searchboxDescriptive(params);
  };

  onKeydown(event: any) {
    console.log(event);
  }
}
