import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { GeoStudiesDataService } from "../../../../../app-services/geo-studies-data.service";
import { Todo } from "../../../../../app-services/geo-studies-data.service";
import { GeoStudiesPrescriptiveDataService } from "src/app/app-services/geo-studies-prescriptive-data.service";
import sampleProvincias from "../../../../../../assets/data/provincias.json"


@Component({
  selector: "app-tab-studies-presc",
  templateUrl: "./tab-studies-presc.component.html",
  styleUrls: ["./tab-studies-presc.component.scss"]
})
export class TabStudiesPrescComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  checked = true;
  displayedColumns: string[] = ["nombre"];

  todos: Observable<Todo[]>;
  studiesSub: any;
  studiesTable: any;
  dataSource: any;
  studiesFiltered: any;

  provincias = sampleProvincias;

  applyFilter(filterValue: string) {
    if (filterValue == "") {
      this.checked = true;
    } else {
      this.checked = false;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.studiesFiltered = this.dataSource.filteredData;    
    this.geoStudiesPrescriptiveDataService.getFilteredPrescriptiveStudies(this.studiesFiltered);
  }


  showAllPrescStudies: any = () => {
    this.geoStudiesPrescriptiveDataService.showAllPrescriptiveStudies(this.checked);    
    this.dataSource = new MatTableDataSource(this.studiesTable);    
    this.dataSource.paginator = this.paginator;

  };

  studySelected(elementId: string, lat: string, long: string) {
    let coords = { lat: lat, long: long };
    this.geoStudiesPrescriptiveDataService.getZoomOnStudy(coords);
  }

  constructor(private geoStudiesDataService: GeoStudiesDataService,
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService) {    

      this.studiesSub = this.geoStudiesPrescriptiveDataService.todos.subscribe(d => {
      this.studiesTable = d.map(el => {
        el["provincia"] =  this.provincias.filter(function (elemnt1) {
            if(elemnt1.key ==el["properties"]["study_zone_id"]){ 
              return elemnt1.value;
            }
          })[0].value;
        return el;
      });

      if (d.length > 0) {
        this.dataSource = new MatTableDataSource(this.studiesTable);
        this.dataSource.paginator = this.paginator;
      }
    });

  }

  ngOnInit() {
    this.todos = this.geoStudiesPrescriptiveDataService.todos;
    this.geoStudiesPrescriptiveDataService.loadAll();
  }

  ngOnDestroy() {
    if (this.studiesSub) {
      this.studiesSub.unsubscribe();
    }
  }
}
