// this module IS NOT in USE

import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { GeoRemoveCompetitorsTableComponent } from "./geo-remove-competitors-table.component";

@NgModule({
  declarations: [GeoRemoveCompetitorsTableComponent],
  imports: [SharedModule],
  exports: [GeoRemoveCompetitorsTableComponent],
  providers: []
})
export class GeoRemoveCompetitorsTableModule {}
