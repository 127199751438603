import { Injectable } from '@angular/core';
import * as L from 'leaflet';
@Injectable({ providedIn: 'root' })
export class BrickReportMapService {

    constructor() { }

    getMapBoxToken = () => {
        let mapBoxUrl =
            //"https://api.mapbox.com/styles/v1/abacus-consulting/cjxem7nyt09cj1cmkiz7v371c/tiles/256/{z}/{x}/{y}?access_token=";
            "https://api.mapbox.com/styles/v1/adegracia/clzi9zxzt00hm01r3bvlzbiwc/tiles/256/{z}/{x}/{y}?access_token=";
        return [
            {
                key: "main",
                value:
                    mapBoxUrl +
                    //"pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw"
                    "pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg"
            }
        ];
    };

    getMapBoxAttribution = () => {
        return (
            'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
            '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="http://mapbox.com">Mapbox</a>'
        );
    }


    map = {
        id: 0,
        bricks: [],
        token: this.getMapBoxToken()[0].value,
        style: "mapbox.streets",
        chartElement: "bricks_map",
        attribution: this.getMapBoxAttribution(),
        zoomControl: false,
        zoomPosition: "topright",
        zoom: 13,
        maxZoom: 18,
        type: "Estudio",
        brand: "McDonalds",
        markerUrl: "./assets/img/brick-report/4772-marker-orange.svg",
        iconSize: [38, 95],
        geometry: [],
        geojsonLayer: null,
        minMaxScales: [{}]
    };

    getMin(data) {
        return data.reduce((min, p) => p.properties.sales_info.first_year_sales < min ? p.properties.sales_info.first_year_sales : min, data[0].properties.sales_info.first_year_sales);
    }
    getMax(data) {
        return data.reduce((max, p) => p.properties.sales_info.first_year_sales > max ? p.properties.sales_info.first_year_sales : max, data[0].properties.sales_info.first_year_sales);
    }


    getMinMax(data) {
        let min = this.getMin(data);
        let max = this.getMax(data);
        return [min, max];

    }

    getMap(setup) {
        this.map.geometry = setup.geometry;
        this.map.bricks = setup.properties.bricks;
        if(setup.properties.bricks.length>0){
            if(this.map.bricks.length>1){
                if(this.map.bricks.length<8){
                    this.map.minMaxScales[0]['minMax'] = this.getMinMax(setup.properties.bricks);
                    this.map.minMaxScales[0]['steps'] = this.setPercentilScale(this.map.bricks);
                    //this.map.minMaxScales[0]['colors'] = this.colors.scales.sequentialYlRd;
                    this.map.minMaxScales[0]['colors'] = this.setColor(this.map.bricks.length);
                }else{
                    this.map.minMaxScales[0]['minMax'] = this.getMinMax(setup.properties.bricks);
                    this.map.minMaxScales[0]['steps'] = this.setScale(this.map.minMaxScales[0]['minMax'], 8);
                    this.map.minMaxScales[0]['colors'] = this.colors.scales.sequentialYlRd;
                }
               
            }else{
                this.map.minMaxScales[0]['minMax'] = this.getMinMax(setup.properties.bricks);
                this.map.minMaxScales[0]['steps'] = this.setScale(this.map.minMaxScales[0]['minMax'], 1);
                this.map.minMaxScales[0]['colors'] = this.colors.scales.sequentialYlRd;
            }
            
        }
        
        return this.map;
    };

    setScale(data, steps) {
        let scale = [];
        let n = 0;
        let step = (data[1] - data[0]) / steps;
        let initialVal = Math.floor(data[0]);
        while (n < steps) {
            scale.push(Math.ceil(initialVal + (step * n)));
            n++;
        }
        return scale;
    }

    setPercentilScale(bricks){
        let scale = [];
        let bricks_len = bricks.length;
        for (let index = 0; index < bricks_len; index++) {
            scale.push(Math.round(bricks[index].properties.sales_info.first_year_sales));
        }
        scale = scale.sort();
        return scale;
    }

    setColor( steps){
        let colorsS = {
            "scales": {
                "sequentialYlRd": []
            }
        };
        var sections =  Math.trunc(this.colors.scales.sequentialYlRd.length/steps);
        colorsS.scales.sequentialYlRd.push(this.colors.scales.sequentialYlRd[0]);
        for (let index = 1; index < this.colors.scales.sequentialYlRd.length; index+=sections) {     
            if(this.colors.scales.sequentialYlRd[index+sections]){
                const element = this.colors.scales.sequentialYlRd[index+sections];
                colorsS.scales.sequentialYlRd.push(element);
            }            
        }        
        return colorsS.scales.sequentialYlRd;
    }



    colors = {
        "scales": {
            "sequentialYlRd": [
                "#FFEDA0",
                "#FED976",
                "#FEB24C",
                "#FD8D3C",
                "#FC4E2A",
                "#E31A1C",
                "#BD0026",
                "#800026"
            ]
        }
    };




    public getColor(d, minMaxScales) {
        let colors = minMaxScales.colors;
        let steps = minMaxScales.steps;
        if(steps.length>1){
            return d > steps[7] ? colors[7] :
            d > steps[6] ? colors[6] :
                d > steps[5] ? colors[5] :
                    d > steps[4] ? colors[4] :
                        d > steps[3] ? colors[3] :
                            d > steps[2] ? colors[2] :
                                d > steps[1] ? colors[1] :
                                    colors[0];
        }else{
            return colors[7];
        }
        
    }



}
