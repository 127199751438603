import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { McdGeoMakerModule } from "../../app-widgets/cartography/mcdGeoMaker/mcdGeoMaker.module";

import { BrickMapComponent } from "./brick-map.component";
import { BrickMapResolverService } from "./brick-map-resolver.service";

const routes: Routes = [
  {
    path: "prescriptive/map",
    component: BrickMapComponent,
    canActivate: []
  },
  {
    path: "prescriptive/map/study/:idStudy",
    component: BrickMapComponent,
    resolve: {
      mappedParams: BrickMapResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), McdGeoMakerModule],
  exports: [RouterModule, McdGeoMakerModule]
})
export class BrickMapRoutingModule {
  static components = [BrickMapComponent];
}
