<section class="full-width clearfix">
  <h2 class="tab-headline margin-top--m">VENTAS TOTALES</h2>
  <p>
    En el primer año.
  </p>

  <div class="full-width clearfix">
    <p class="big-number">
      {{ ventas_first_year_sales_medium | number: "1.0-0":"es" }}
      euros.
    </p>
  </div>
</section>
<!-- [style.width.%]="channel.value" -->
<section class="full-width clearfix study-channel_decomposition">
  <div class="full-width clearfix">
    <mat-list>
      <h3 class="small" mat-subheader>Reparto por canal</h3>
      <mat-list-item *ngFor="let channel of channel_decomposition">
        <mat-icon mat-list-icon>{{ channel.icon }}</mat-icon>
        <h4 class="small" mat-line>{{ channel.name }}</h4>
        <p class="small" mat-line>
          <span class="barchart" [style.width.%]="channel.value * 0.8"></span
          >{{ channel.value.toPrecision(3) }}%
        </p>
      </mat-list-item>
    </mat-list>
  </div>
</section>

<section class="full-width clearfix">
  <h2 class="tab-headline margin-top--m">VENTAS ASOCIADAS</h2>
  <p>
    Ventas que previamente eran de restaurantes ya existentes.
  </p>

  <div class="full-width clearfix">
    <p class="big-number">
      {{ ventas.perc_of_sales_associated_to_existing_rest.toPrecision(2) }}%.
    </p>
  </div>
</section>
