import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";

@Component({
  selector: "app-presencia-segmentos",
  templateUrl: "./presencia-segmentos.component.html",
  styleUrls: ["./presencia-segmentos.component.scss"]
})
export class PresenciaSegmentosComponent implements OnInit {
  @Input()
  presence_segmentation: any;
  @Input()
  presence_segmentation_ready: boolean;

  selectedMode: any;
  areaOptions: any;
  dayOptions: any;
  selectedArea: any;
  selectedSegmentation: any;
  selectedPeriod: any;
  selectedMonth: any;
  selectedDay: any = null;
  displayMonths: boolean = false;
  displayDays: boolean = false;

  constructor() { }

  changeMode(event) {
    this.selectedMode = event.key;
    this.areaOptions = event.values;
  }

  changeArea(event) {
    this.selectedArea = event;
  }

  changeSegmentation(event) {
    this.selectedSegmentation = event;
  }

  changePeriod(event) {
    this.selectedPeriod = event;
    if (event === 'annual' || event === 'weeks' || event === 'months') {
      this.displayMonths = false;
      this.displayDays = false;
    }

    if (event === "days") {
      this.displayMonths = true;
      this.displayDays = false;
    }
  }

  changeMonth(event) {
    this.selectedMonth = event.key;
    this.dayOptions = event.values;
  }

  changeDay(event) {
    this.selectedDay = event;
  }

  ngOnInit() { }
}
