import { NgModule } from "@angular/core";
import { LOCALE_ID } from "@angular/core";
import { SharedModule } from "./app-modules/app-shared.module";

import { AppRoutingModule } from "./app-modules/app-routing.module";
import { AppComponent } from "./app.component";

import { LoginModule } from "./login-page/login.module";
import { RestaurantMapModule } from "./descriptive-model/restaurant-map/restaurant-map.module";

import { GeoStudiesModule } from "./predictive-model/geo-studies/geo-studies.module";
import { GeoNewFormModule } from "./predictive-model/geo-new-form/geo-new-form.module";
import { GeoMapModule } from "./predictive-model/geo-map/geo-map.module";

import { BrickStudiesModule } from "./prescriptive-model/brick-studies/brick-studies.module";
import { BrickMapModule } from "./prescriptive-model/brick-map/brick-map.module";
import { BricksNewFormModule } from "./prescriptive-model/bricks-new-form/bricks-new-form.module";

import { MassiveBrickStudiesModule } from "./prescriptive-model/massive-brick-studies/massive-brick-studies.module";
import { MassiveBrickMapModule } from "./prescriptive-model/massive-brick-map/massive-brick-map.module";
import { MassiveBricksNewFormModule } from "./prescriptive-model/massive-bricks-new-form/massive-bricks-new-form.module";


import { DownloadsPageModule } from "./downloads-page/downloads-page.module";
import { UsersPageModule } from "./users-page/users-page.module";
import { NewUserModule } from "./users-page/new-user/new-user.module";

import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";

import { CoreModule } from "./core/core.module";
import { EditUserModule } from "./users-page/edit-user/edit-user.module";
import { BrickReportModule } from './brick-report/brick-report.module';

import { AddDialogBoxlocatorModule} from './predictive-model/geo-new-form/geo-add-competitors-table/add-dialog-box-locator/add-dialog-box-locator.module'
import { AddDialogMcdBoxlocatorModule } from './predictive-model/geo-new-form/geo-add-mcd-table/add-dialog-mcd-box-locator/add-dialog-mcd-box-locator.module'

import { HashLocationStrategy, LocationStrategy } from '@angular/common';


import { AddDialogBoxCommercesLocatorModule } from './predictive-model/geo-new-form/geo-add-commerces-table/add-dialog-box-commerces/add-dialog-box-commerces-locator/add-dialog-box-commerces-locator.module'
import { AddDialogCommercialCenterBoxLocatorModule } from './predictive-model/geo-new-form/geo-add-commercial-center-table/add-dialog-commercial-center-box/add-dialog-commercial-center-box-locator/add-dialog-commercial-center-box-locator.module'


// the second parameter 'fr' is optional
registerLocaleData(localeEs, "es");

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    LoginModule,
    RestaurantMapModule,
    GeoStudiesModule,
    GeoNewFormModule,
    GeoMapModule,
    BrickStudiesModule,
    BrickMapModule,
    BricksNewFormModule,
    DownloadsPageModule,
    UsersPageModule,
    NewUserModule,
    EditUserModule,
    BrickReportModule,
    AddDialogBoxlocatorModule,
    AddDialogMcdBoxlocatorModule,
    AddDialogBoxCommercesLocatorModule,
    AddDialogCommercialCenterBoxLocatorModule,
    MassiveBrickStudiesModule,
    MassiveBrickMapModule,
    MassiveBricksNewFormModule
    
    

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
