import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { Optional } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
//app-services/data.service.js
import { DataService } from "../../../../../app/app-services/data.service";
import { AddDialogBoxLocatorComponent } from "../add-dialog-box-locator/add-dialog-box-locator.component";
import { MatDialog } from "@angular/material/dialog";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
export interface UsersData {
  id: number;
  name: any;
  address: string;
  distance: number;
  distance_bbdd: number;
  id_brand: number;
  brand: string;
}

@Component({
  selector: "app-add-dialog-box",
  templateUrl: "./add-dialog-box.component.html",
  styleUrls: ["./add-dialog-box.component.scss"]
})
export class AddDialogBoxComponent implements OnInit, OnDestroy {
  action: string;
  local_data: any;
  sampleBrands: Array<any>;
  unsubscribe: any;

  disabledButton: boolean = true;

  dataSource: any[] = [];
  @Output() competitorsLocationAdded: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<AddDialogBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData,
    private dataService: DataService,
    public dialog: MatDialog
  ) {

    this.local_data = { ...data };
    this.local_data.addressCtrl = data.address;
    this.local_data.brand = data.name;
    this.local_data.id_brand = data.id_brand;
    this.local_data.name = {
      brand: data.name,
      id_brand: data.id_brand
    };
    if (this.local_data.addressCtrl) {
      this.disabledButton = false;
    }

    this.action = this.local_data.action;
  }



  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogBoxLocatorComponent, {
      width: "750px",
      height: "",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      position: { top: "40px" },
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.local_data = result;
      if (result.event == "Añadir dirección") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        // this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        //  this.deleteRowData(result.data);
      }


    });
  }

  addRowData(row_obj) {
    // this.local_data = row_obj;
    this.local_data.addressCtrl = row_obj.addressCtrl;
    this.local_data.addressTotal = row_obj.addressCtrl + ', ' + row_obj.cityCtrl + ', ' + row_obj.provinceCtrl;
    this.local_data.city = row_obj.cityCtrl;
    this.local_data.province = row_obj.provinceCtrl;
    this.local_data.long = row_obj.lonCtrl;
    this.local_data.lat = row_obj.latCtrl;

    if (this.local_data.addressCtrl) {
      this.disabledButton = false;
    }

  }

  doAction() {

    var resultBrand = this.sampleBrands.filter(obj => {
      return obj.id_brand === this.local_data.name.id_brand;
    });
    if (resultBrand) {
      this.local_data.name.brand = resultBrand[0].brand
    }


    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancelar" });
  }

  ngOnInit() {
    //function brand just important (property mongodb show_brand = true)
    this.unsubscribe = this.dataService.getBrands().subscribe((result: any) => {
      result = result.map(function (e) {
        e.brand = e._id.brand;
        e.id_brand = e._id.id_brand;
        delete e._id;
        return e;
      });
      this.sampleBrands = result;
      this.sampleBrands.push({ "brand": "OTROS QSR", "id_brand": "OTROS QSR" });
      this.sampleBrands.push({ "brand": "OTROS IEO", "id_brand": "OTROS IEO" });


    });
  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
