import { Component, OnInit, Input } from "@angular/core";
import { D3MainService } from "../../../app-services/d3/d3-main.service";
import { ReshapeDataService } from "../../../app-services/d3/reshape-data.service";

import * as d3 from "d3";

@Component({
  selector: "app-vertical-bars",
  templateUrl: "./vertical-bars.component.html",
  styleUrls: ["./vertical-bars.component.scss"]
})
export class VerticalBarsComponent implements OnInit {
  @Input()
  params: any;
  @Input()
  temporalSerie: any; //string // "0", "1", "2", "3"
  @Input()
  chartId: string;
  @Input()
  chartSize: string;

  dataChart: any;

  D3READY: boolean = false;
  win: any = window; // in use
  timeout: any = false;
  delay: number;

  duration: number = 1000;
  // services
  COLORS: any;
  getDimensions: any;
  formatRound: any;
  formatZero: any;
  dictionary: any;

  tDefault: number;
  opacity_lowest: number;

  width: number;
  height: number;
  margin: any;
  // svg containers
  container: any;
  svg: any;
  chartWrapper: any;
  chartInner: any;
  tooltip: any;
  tooltipStyle: any;
  tooltipSize: any;

  // scales
  xScale: any;
  yScale: any;
  xAxis: any;
  yAxis: any;

  // averages

  yAvg: number;
  yExtent: Array<number>;
  yDefaultDomain: Array<number>;
  xDefaultDomain: Array<number>;

  // Variables
  xVar: string = "";
  yVar: string = "";
  xVal: string;
  yVal: string;
  units: string;

  yDescription: string = "Cantidad";
  xDescription: string = "Cantidad";

  init = (): void => {
    this.destroyChart();
    this.renderAll();
  };

  destroyChart() {
    d3.select(this.params.container || "#" + this.chartId).html("");
    d3.select(this.win).on("resize", null);
  }
  renderAll = (): void => {
    /*
    console.log("date", this.d3MainService.formatSortYear(new Date("2020")));
    console.log(
      "formatMonthLong",
      this.d3MainService.formatMonthLong(new Date())
    );
    console.log("formatMonthSort", this.d3MainService.formatMonthSort("7.5"));
    console.log("formatRoundPercentage", this.d3MainService.FORMATPER(7.0));
      */
    this.xScale = d3
      .scaleBand()
      .padding(0.02)
      .round(true);
    this.yScale = d3.scaleLinear();

    ////////// Initialize axis //////////
    this.xAxis = d3.axisBottom(this.xScale);
    this.yAxis = d3
      .axisLeft(this.yScale)
      .tickFormat(this.formatZero)
      .ticks(this.yVal["ticks"]);

    this.container = d3.select(this.params.container || "#" + this.chartId); // placeholder div for svg
    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    this.chartWrapper = this.svg
      .selectAll("g.chartWrapper")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartWrapper")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    // Axis groups
    this.chartWrapper
      .selectAll(".x.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis");
    this.chartWrapper
      .selectAll(".y.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis");

    this.chartInner = this.chartWrapper
      .selectAll("g.chartInner") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartInner");

    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.render, this.delay);
    });
    this.reshapedata();
  };

  reshapedata = (): void => {
    this.D3READY = false;

    this.dataChart = this.reshapeDataService.reshapeDataToRender(this.params, [
      this.temporalSerie
    ]);

    this.xVar = this.xVal["value"].filter(d => d == this.temporalSerie)[0];
    this.yVar = this.yVal["value"][0];

    this.yAvg = d3.mean(this.dataChart, d => d[this.yVal["name"]]);
    this.yExtent = d3.extent(this.dataChart, d => d[this.yVal["name"]]);

    this.yDefaultDomain = [0, this.yExtent[1]];
    this.xDefaultDomain = this.dataChart.map(d => d[this.xVal["name"]]);

    //console.log(this.dataChart);
    this.render();
  };

  render = (): void => {
    let dimensions = this.d3MainService.getDimensions(
      this.svg,
      this.params.container || "#" + this.chartId,
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.xScale.domain(this.xDefaultDomain).range([0, this.width]);
    this.yScale
      .domain(this.yDefaultDomain)
      .range([this.height, 0])
      .nice();

    this.drawAxis();
    this.drawGrid();
    this.drawAverages();
    this.drawBars();
    this.drawTooltip();

    //console.log("rendered!!");
  };

  public drawAverages = (): void => {
    //console.log("drawAverages");
    let yScale: any = this.yScale;
    let width: number = this.width;
    let height: number = this.height;

    // Create a group to store
    this.chartInner
      .selectAll("g.avg")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "avg");

    let gAvg: any = this.chartInner.selectAll("g.avg");

    gAvg
      .selectAll(".avgline")
      .data([{ coord: "y", value: this.yAvg }])
      .enter()
      .append("line")
      .attr("class", "avgline")
      .attr("x1", d => {
        return d.coord == "x" ? 0 : 0;
      })
      .attr("y1", d => {
        return d.coord == "x" ? 0 : yScale(d.value);
      })
      .attr("x2", d => {
        return d.coord == "x" ? width : width;
      })
      .attr("y2", d => {
        // console.log("value", d.value);
        return d.coord == "x" ? height : yScale(d.value);
      })
      .style("stroke", this.COLORS.gray_dark)
      .style("opacity", status == "active" ? 1 : 1);

    // Update position
    gAvg
      .selectAll(".avgline")
      .transition()
      .duration(this.tDefault / 2)
      .attr("x1", d => {
        return d.coord == "x" ? 0 : 0;
      })
      .attr("y1", d => {
        return d.coord == "x" ? 0 : yScale(d.value);
      })
      .attr("x2", d => {
        return d.coord == "x" ? width : width;
      })
      .attr("y2", d => {
        //console.log("value", d.value);

        return d.coord == "x" ? height : yScale(d.value);
      })
      .style("opacity", status == "active" ? 1 : 1);

    //gAvg.exit().remove();
  };
  //this.COLORS.gray_dark
  public drawAxis = (): void => {
    // Call axis
    this.chartWrapper
      .selectAll(".x.axis")
      .attr("transform", `translate(0, ${this.height})`)
      .transition()
      .duration(this.tDefault)
      .call(this.xAxis);

    this.chartWrapper
      .selectAll(".y.axis")
      .attr("transform", "translate(0, 0)")
      .transition()
      .duration(this.tDefault)
      .call(this.yAxis);

    // Append axis titles
    this.chartWrapper
      .selectAll(".x.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.COLORS.gray_dark)

      .merge(this.chartWrapper.selectAll(".x.axis").selectAll(".axis_title"))
      .text(
        this.d3MainService.getRealName(
          this.dictionary,
          "short_title",
          this.xVar
        )
      )
      .attr(
        "transform",
        `translate(${this.width / 2}, ${this.margin.bottom / 1.4})`
      );

    this.chartWrapper
      .selectAll(".y.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.COLORS.gray_dark)

      .merge(this.chartWrapper.selectAll(".y.axis").selectAll(".axis_title"))
      .text(this.d3MainService.capitalize(this.yVar))
      .attr(
        "transform",
        `translate(${-this.margin.left / 1.3}, ${this.height / 2}) rotate(-90)`
      );
  };

  public drawGrid = (): void => {};

  public drawTooltip = (): void => {
    this.svg.select(".tooltip").remove();

    this.tooltip = this.svg
      .append("svg:g")
      .attr("class", "tooltip")
      .style("display", "none");

    this.tooltip
      .append("svg:rect")
      .attr("width", this.tooltipSize.width)
      .attr("height", this.tooltipSize.height)
      .attr("x", this.tooltipSize.x)
      .attr("y", this.tooltipSize.y)
      .attr("rx", this.tooltipSize.rx)
      .attr("ry", this.tooltipSize.ry)
      .attr("fill", this.tooltipStyle.background_color)
      .style("opacity", this.tooltipStyle.low_opacity);
  };

  public drawBars = (): void => {
    let xScale: any = this.xScale;
    let yScale: any = this.yScale;
    let height: any = this.height;
    const COLORS: any = this.COLORS;
    const tDefault: number = this.tDefault;
    let mouseoverNode: any = this.mouseoverNode;
    let mouseoutNode: any = this.mouseoutNode;
    let mousemoveNode: any = this.mousemoveNode;

    let _xVal: string = this.xVal["name"];
    let _yVal: string = this.yVal["name"];

    // Create a group to store the 'nodes'
    this.chartInner
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");

    // Join the data
    let nodes: any = this.chartInner
      .selectAll("g.nodes")
      .selectAll(".node")
      .data(this.dataChart, d => {
        return d[_xVal];
      });

    // Exit the nodes
    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(tDefault / 2)
      .attr("height", 0)
      .attr("y", height)

      .remove();

    // Enter the new nodes
    nodes
      .enter()
      .append("svg:rect")
      .attr("class", d => {
        return "node " + d[_xVal];
      })
      .attr("data-name", d => {
        return d[_xVal];
      })
      .attr("data-value", d => {
        return d[_yVal];
      })

      .attr("x", (d, i) => {
        return xScale(d[_xVal]);
      })
      .attr("y", d => {
        return height;
      })
      .attr("height", (d, i) => {
        return 0;
      })
      .attr("width", (d, i) => {
        return 0;
      })
      .style("opacity", d => {
        return 1;
      })
      .style("fill", d => {
        return COLORS.yellow_green;
      })
      .style("stroke", COLORS.gray_extraLight);

    nodes = this.chartInner.selectAll(".node");

    // Apply transition
    nodes
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", (d, i) => {
        return xScale(d[_xVal]);
      })
      .attr("y", d => {
        return yScale(d[_yVal]);
      })
      .attr("height", (d, i) => {
        return height - yScale(d[_yVal]);
      })
      .attr("width", (d, i) => {
        return xScale.bandwidth();
      })
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", mouseoverNode)
          .on("mouseout", mouseoutNode)
          .on("mousemove", mousemoveNode)

          .on("click", null);
      });
  };

  public mouseoverNode = (): void => {
    const selected: any = d3.select(d3.event.target);
    //console.log(this, d3.event.target, d3.mouse(d3.event.target));

    selected.style("cursor", "pointer");

    d3.selectAll(this.params.container + " .chartInner rect.node").style(
      "opacity",
      this.opacity_lowest
    );
    selected.style("opacity", 1);
    this.tooltip.style("display", null);
  };
  public mousemoveNode = (d, i, r): void => {
    let _legend: Array<any> = this.params.legend;

    const selected: any = d3.select(d3.event.target);
    const data: any = selected.data();

    const selectedData: any = selected.data()[0];

    const selectedParent: any = selected.select(d => {
      return d3.event.target.parentNode;
    });

    let selectedParentClass: any = selectedParent.attr("class").split(" ")[1];

    let xPosition = d3.mouse(d3.event.target)[0] + 24;
    let yPosition = d3.mouse(d3.event.target)[1] - 12;

    this.tooltip.attr(
      "transform",
      "translate(" + xPosition + "," + yPosition + ")"
    );

    let tooltipText = this.tooltip
      .selectAll("text")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("transform", "translate(" + 0 + "," + 0 + ")")

      .style("text-anchor", this.tooltipStyle.text_anchor)
      .attr("font-size", this.tooltipStyle.font_size)
      .attr("font-weight", this.tooltipStyle.font_weight);

    tooltipText
      .append("tspan")
      .text(d.name + ": ")
      .attr("x", 0)
      .attr("dx", 0)
      .attr("dy", 0)
      .style("fill", this.tooltipStyle.fill_text)
      .style("text-anchor", this.tooltipStyle.text_anchor)
      .attr("class", "title");

    tooltipText
      .append("tspan")
      .text(this.formatZero(d.value) + " " + this.units["value"])
      .attr("class", "number")
      .attr("x", 0)
      .attr("dx", 0)
      .attr("dy", 16)
      .style("text-anchor", this.tooltipStyle.text_anchor);
  };

  public mouseoutNode = (): void => {
    d3.selectAll("rect.node").style("opacity", 1);
    this.tooltip.select("text").remove();
    this.tooltip.style("display", "none");
  };

  constructor(
    private d3MainService: D3MainService,
    private reshapeDataService: ReshapeDataService
  ) {}
  ngOnChanges(changes: any) {
    let changeObj = Object.keys(changes);
    this.COLORS = this.params.design.style.colors;
    this.xVal = this.params.xVal;
    this.yVal = this.params.yVal;
    this.units = this.params.units;
    this.margin = this.params.design.margin;
    this.getDimensions = this.d3MainService.getDimensions;
    this.formatRound = this.d3MainService.FORMATROUND;
    this.formatZero = this.d3MainService.FORMATZERO;
    this.tDefault = this.d3MainService.TIMESPAN;
    this.opacity_lowest = this.d3MainService.OPACITY_LOWEST;
    this.tooltipStyle = this.params.tooltip.style;
    this.tooltipSize = this.params.tooltip.size;
    this.delay = this.d3MainService.RESIZE_DELAY;
    this.dictionary = this.params.dictionary;

    if (changeObj.length > 1) {
      // params and selector changes first load
      this.params["change"] = "first load";
      //this.init();
      return;
    }

    if (changeObj.length === 1) {
      // selector changes
      this.params["change"] = "selector";
      this.reshapedata();
      return;
    }
  }

  ngOnInit() {
    // console.log("init", this.params, this.temporalSerie);
  }

  ngAfterViewInit() {
    this.params["change"] = "AfterViewInit";
    this.init();
  }
}
