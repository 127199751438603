import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-tematicos',
  templateUrl: './tab-tematicos.component.html',
  styleUrls: ['./tab-tematicos.component.scss']
})
export class TabTematicosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
