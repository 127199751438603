import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { StudySalesComponent } from "./study-sales.component";

@NgModule({
  declarations: [StudySalesComponent],
  imports: [SharedModule],
  exports: [StudySalesComponent],
  providers: []
})
export class StudySalesModule {}
