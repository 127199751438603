import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { FichaComponent } from "./data-ficha.component";
import { ServicesIconsModule } from "../../../app-widgets/icons/services-icons/services-icons.module";

@NgModule({
  imports: [SharedModule, ServicesIconsModule],
  exports: [FichaComponent],
  declarations: [FichaComponent],
  providers: []
})
export class DataFichaModule {}
