<app-layout-wrapper>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <app-menu-mobile *ngIf="!navigationHidden" (sidenavClose)="sidenav.close()"></app-menu-mobile>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-menu-screen (sidenavToggle)="sidenav.toggle()" *ngIf="!navigationHidden"></app-menu-screen>
      <!--in here all the content must reside. We will add a navigation header as well-->
      <main>
        <router-outlet></router-outlet>
      </main>
      <app-footer *ngIf="!footerHidden"></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout-wrapper>