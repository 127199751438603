import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { DeleteDialogComponent } from "./delete-dialog.component";

@NgModule({
    declarations: [DeleteDialogComponent],
    imports: [SharedModule],
    exports: [DeleteDialogComponent],
    providers: []
})
export class DeleteDialogModule {}
