import { NgModule } from "@angular/core";
import { SharedModule } from "../app-modules/app-shared.module";
import { MatTableModule } from "@angular/material/table";
import { UsersPageRoutingModule } from "./users-page-routing.module";

@NgModule({
  imports: [UsersPageRoutingModule, SharedModule, MatTableModule],
  exports: [MatTableModule],
  declarations: [UsersPageRoutingModule.components],
  providers: []
})
export class UsersPageModule {}
