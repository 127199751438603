import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppMaterialModule } from "src/app/app-modules/app-material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { Ng5SliderModule } from "ng5-slider";
import { LayoutWrapperComponent } from "../app-widgets/layout/layout-wrapper/layout-wrapper.component";
import { FooterComponent } from "../app-widgets/layout/footer/footer.component";
import { MenuScreenComponent } from "../app-widgets/navigation/menu-screen/menu-screen.component";
import { MenuMobileComponent } from "../app-widgets/navigation/menu-mobile/menu-mobile.component";

import { TwoDigitDecimaNumberDirective } from '../two-digit-decima-number.directive';

@NgModule({
  imports: [
    BrowserModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    RouterModule,
    Ng5SliderModule,
    // TwoDigitDecimaNumberDirective
  ],
  exports: [
    BrowserModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    RouterModule,
    Ng5SliderModule,
    LayoutWrapperComponent,
    FooterComponent,
    MenuScreenComponent,
    MenuMobileComponent,
    TwoDigitDecimaNumberDirective
  ],
  declarations: [
    LayoutWrapperComponent,
    FooterComponent,
    MenuScreenComponent,
    MenuMobileComponent,
    TwoDigitDecimaNumberDirective
  ]
})
export class SharedModule { }
