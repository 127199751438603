<div class="login-modal" id="login_modal">
  <!-- Modal content -->
  <div class="login-modal--container">
    <div class="clearfix">
      <span class="login-modal--close" id="close_login_button">&times;</span>
    </div>
    <div class="login-modal--header">
      <div class="login-modal--logo"></div>
      <div class="full-width clearfix margin-bottom--48"></div>
      <h1>Inicia una sesión en la aplicación</h1>
      <p>Introduce tu usuario y contraseña para identificarte.</p>
    </div>
    <div class="full-width clearfix margin-bottom--24"></div>

    <div class="login-modal--form">
      <alert></alert> 
      <form
        id="login_form"
        class="login-form"
        method="post"
        (ngSubmit)="onSubmit()"
      >
        <div class="login-modal-user">
          <label for="login_user" class="hidden">Usuario</label>
          <input
            placeholder="Usuario"
            type="text"
            name="login_user"
            id="login_user"
            value="Usuario"
            required
          />
        </div>
        <div class="full-width clearfix margin-bottom--24"></div>
        <div class="login-modal-password">
          <label for="login_password" class="hidden">Contraseña</label>
          <input
            placeholder="Contraseña"
            type="password"
            name="login_password"
            id="login_password"
            value="Contraseña"
            required
          />
        </div>
        <div class="full-width clearfix margin-bottom--48"></div>
        <div class="form-button">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            class="button--xl"
          >
            Entrar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="login-init form-button">
  <button
    id="open_login_button"
    mat-raised-button
    color="primary"
    class="button--xl"
  >
    Inicie sesión
  </button>
</div>
