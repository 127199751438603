import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { AfterViewInit } from "@angular/core";
import { OnChanges } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { ReshapeDataService } from "../../../app-services/d3/reshape-data.service";
import { D3MainService } from "../../../app-services/d3/d3-main.service";

import * as d3 from "d3";

@Component({
  selector: "app-stacked-bar",
  templateUrl: "./stacked-bar.component.html",
  styleUrls: ["./stacked-bar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedBarComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input()
  params: any;
  @Input()
  chartId: string;
  @Input()
  chartSize: string;
  @Input() firstFilterValue?: any;
  @Input() secondFilterValue?: any;
  @Input() thirdFilterValue?: any;
  @Input() fourFilterValue?: any;
  @Input() fifthFilterValue?: any;
  @Input() sixthFilterValue?: any;

  numberOfSelectors: number;
  dataChart: any;
  D3READY: boolean = false;
  win: any = window; // in use
  timeout: any = false;
  delay: number;

  // services
  colors: any;
  stroke: any;
  text: any;
  getDimensions: any;
  getRealName: any;
  numberFormatter: any;
  tDefault: number;
  opacity_lowest: number;
  paddingInner: number;
  align: number;
  units: any;

  width: number;
  height: number;
  margin: any;
  // svg containers
  container: any;
  svg: any;
  chartWrapper: any;
  chartInner: any;
  tooltip: any;
  tooltipLayout: any;

  // scales
  xScale: any;
  yScale: any;
  zScale: any;
  keys: any;

  // axis
  xAxis: any;
  yAxis: any;
  xTitle: any;
  yTitle: any;

  destroyChart: any = () => {
    d3.select(this.params.container || "#" + this.chartId).html("");
    d3.select(this.win).on("resize", null);
  };
  init = (): void => {
    this.destroyChart();
    this.renderAll();
  };

  renderAll = (): void => {
    this.xScale = d3.scaleBand();
    this.yScale = d3.scaleLinear();
    this.zScale = d3.scaleOrdinal().range(this.colors);

    ////////// initialize axis //////////
    this.xAxis = d3.axisBottom(this.xScale);
    this.yAxis = d3.axisLeft(this.yScale).tickFormat(this.numberFormatter);

    this.container = d3.select(this.params.container || "#" + this.chartId); // placeholder div for svg

    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    this.chartWrapper = this.svg
      .selectAll("g.chartWrapper")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartWrapper")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    // Axis groups
    this.chartWrapper
      .selectAll(".x.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis");
    this.chartWrapper
      .selectAll(".y.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis");
    this.chartWrapper
      .selectAll("g.legend") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "legend");

    this.chartInner = this.chartWrapper
      .selectAll("g.chartInner") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartInner");

    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.init, this.delay);
    });
    this.reshapedata();
  };

  reshapedata = (): void => {
    this.dataChart = this.reshapeDataService.reshapeDataToRender(this.params, [
      this.firstFilterValue,
      this.secondFilterValue,
      this.thirdFilterValue,
      this.fourFilterValue,
      this.fifthFilterValue,
      this.sixthFilterValue
    ]);

    let keys = [];
    for (let key in this.dataChart[0]) {
      if (key != "category" && key != "total") {
        keys.push(key);
      }
    }

    this.dataChart.forEach(d => {
      d.total = 0;
      keys.forEach(k => {
        if (d[k] === undefined || d[k] === null) {
          d[k] = 0;
        }
        d.total += d[k];
      });
    });

    this.dataChart.sort((a, b) => {
      return b.total - a.total;
    });
    this.keys = keys;

    this.render();
  };

  render = (): void => {
    let dimensions: any = this.getDimensions(
      this.svg,
      this.params.container ? this.params.container : "#" + this.chartId,
      this.margin
    );

    this.width = dimensions.width;
    this.height = dimensions.height;

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.xScale
      .rangeRound([0, this.width])
      .domain(
        this.dataChart.map(d => {
          return d.category;
        })
      )
      .paddingInner(this.paddingInner)
      .align(this.align);

    this.yScale
      .rangeRound([this.height, 0])
      .domain([
        0,
        d3.max(this.dataChart, d => {
          return d.total;
        })
      ])
      .nice();

    this.zScale.domain(this.keys);

    this.drawTooltip();
    this.drawAxis();
    this.drawStack();
    this.drawLegend();
    //console.log("rendered!!");
  };

  public drawTooltip = () => {
    this.svg.select(".tooltip").remove();

    this.tooltip = this.svg
      .append("svg:g")
      .attr("class", "tooltip")
      .style("display", "none");

    this.tooltip
      .append("svg:rect")
      .attr("width", 0)
      .attr("height", 0)
      .attr("fill", "white")
      .style("opacity", 0);
  };
  public drawLegend = () => {
    let _zScale = this.zScale;
    let getRealName = this.getRealName;

    let nodes: any = this.chartWrapper
      .selectAll("g.legend")
      .selectAll(".node")
      .data(d3.stack().keys(this.keys)(this.dataChart));

    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .remove();

    let node = nodes
      .enter()
      .append("svg:g")
      .attr("class", "node")
      .attr("transform", (d, i) => {
        return "translate(30," + i * 19 + ")";
      })
      .attr("fill", (d, i) => {
        return _zScale(d.key);
      });

    let rect = node
      .append("rect")
      .attr("x", this.width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("stroke", this.stroke.stroke)
      .attr("class", d => {
        return "node " + d.key;
      })
      .on("mouseover", this.mouseoverNode)
      .on("mouseout", this.mouseoutNode);

    let text = node
      .append("text")
      .attr("class", "text")
      .style("fill", "#000000")
      .attr("x", this.width + 5)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .attr("font-size", "10px")
      .text((d, i) => {
        return getRealName("wording", "word_title", d.key); //return the array data
      });
  };

  public drawStack = () => {
    let _zScale = this.zScale;
    let _yScale = this.yScale;
    let _xScale = this.xScale;
    this.chartInner
      .selectAll("g.stack")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "stack");

    let stack: any = this.chartInner
      .selectAll("g.stack")
      .selectAll(".node")
      .data(d3.stack().keys(this.keys)(this.dataChart))
      .enter()
      .append("svg:g")
      .attr("fill", (d, i) => {
        return _zScale(d.key);
      })
      .attr("class", d => {
        return "node " + d.key;
      })
      .selectAll("rect")
      .data(d => {
        return d;
      })

      .enter()
      .append("svg:rect")
      .style("stroke", this.stroke.stroke)

      .attr("x", d => {
        return _xScale(d.data.category);
      })
      .attr("y", d => {
        return _yScale(d[1]);
      })
      .attr("height", d => {
        return _yScale(d[0]) - _yScale(d[1]);
      })
      .attr("width", _xScale.bandwidth())
      .on("mouseover", this.mouseoverStack)
      .on("mouseout", this.mouseoutStack)
      .on("mousemove", this.mousemoveStack);
  };
  public drawAxis = (): void => {
    // axis
    this.chartWrapper
      .selectAll(".x.axis")
      .attr("transform", "translate(" + 0 + "," + this.height + ")")
      .transition()
      .duration(this.tDefault)
      .call(this.xAxis);

    this.chartWrapper
      .selectAll(".y.axis")
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .transition()
      .duration(this.tDefault)
      .call(this.yAxis);

    // text
    this.chartWrapper
      .selectAll(".x.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.text.fill);

    this.chartWrapper
      .selectAll(".x.axis")
      .selectAll(".axis_title")
      .text(this.xTitle)
      .attr(
        "transform",
        "translate(" + this.width / 2 + "," + this.margin.bottom / 1.4 + ")"
      );

    this.chartWrapper
      .selectAll(".y.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle");

    this.chartWrapper
      .selectAll(".y.axis")
      .selectAll(".axis_title")
      .attr(
        "transform",
        "translate(" +
        -this.margin.left / 1.3 +
        "," +
        this.height / 2 +
        ")rotate(-90)"
      )
      .style("fill", this.text.fill)
      .text(this.yTitle);
  };

  mouseoverNode: any = d => {
    const selected: any = d3.select(d3.event.target);
    let selectedFeature: string = selected.attr("class").split(" ")[1];

    selected.style("cursor", "pointer");
    d3.selectAll(".legend .node rect").style("opacity", this.opacity_lowest);
    selected.style("opacity", 1);

    d3.selectAll(".stack .node").style("opacity", this.opacity_lowest);
    d3.select(".stack ." + selectedFeature).style("opacity", 1);
  };

  mouseoutNode: any = d => {
    d3.selectAll(".stack .node").style("opacity", 1);
    d3.selectAll(".legend .node").style("opacity", 1);
  };

  mouseoverStack: any = d => {
    const selected: any = d3.select(d3.event.target);
    //console.log(this, d3.event.target, d3.mouse(d3.event.target));

    selected.style("cursor", "pointer");

    d3.selectAll(".stack .node rect").style("opacity", this.opacity_lowest);
    selected.style("opacity", 1);
    this.tooltip.style("display", null);
  };

  mouseoutStack: any = d => {
    d3.selectAll(".stack .node rect").style("opacity", 1);
    this.tooltip.select("text").remove();
    this.tooltip.style("display", "none");
  };

  mousemoveStack: any = (d): void => {
    const selected: any = d3.select(d3.event.target);
    const selectedData: any = selected.data()[0];

    const selectedParent: any = selected.select(d => {
      return d3.event.target.parentNode;
    });

    let selectedParentClass: any = selectedParent.attr("class").split(" ")[1];
    let propName: string = this.getRealName(
      "wording",
      "short_title",
      selectedParentClass
    );

    let xPosition = d3.mouse(d3.event.target)[0] + this.tooltipLayout.size.x;
    let yPosition = d3.mouse(d3.event.target)[1] + this.tooltipLayout.size.y;

    this.tooltip.attr(
      "transform",
      "translate(" + xPosition + "," + yPosition + ")"
    );
    let tooltipText = this.tooltip
      .selectAll("text")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("transform", "translate(" + 10 + "," + 10 + ")")
      .style("text-anchor", this.tooltipLayout.style.text_anchor)
      .attr("font-size", this.tooltipLayout.style.font_size)
      .attr("font-weight", this.tooltipLayout.style.font_weight);

    tooltipText
      .append("tspan")
      .text(propName)
      .attr("x", 0)
      .attr("dx", 0)
      .attr("dy", 0)
      .style("text-anchor", this.tooltipLayout.style.text_anchor)
      .attr("class", "title"),
      tooltipText
        .append("tspan")
        .text(
          this.numberFormatter(selectedData[1] - selectedData[0]) +
          this.units.symbol
        )
        .attr("class", "number")
        .attr("x", 0)
        .attr("dx", 0)
        .attr("dy", 16)
        .style("text-anchor", this.tooltipLayout.style.text_anchor);
  };

  constructor(
    private d3MainService: D3MainService,
    private reshapeDataService: ReshapeDataService
  ) { }

  ngOnChanges(changes: any) {
    let changeObj = Object.keys(changes);
    this.margin = this.params.design.margin;
    this.text = this.params.design.text;
    this.colors = this.params.design.style.colors;
    this.stroke = this.params.design.stroke;

    this.xTitle = this.params.xVal[0].key;
    this.yTitle = this.params.yVal[0].key;

    this.units = this.params.units;
    this.tooltipLayout = this.params.tooltip;

    this.paddingInner = this.params.design.style.paddingInner;
    this.align = this.params.design.style.paddingInner;

    this.getDimensions = this.d3MainService.getDimensions;
    this.getRealName = this.d3MainService.getRealName;
    this.numberOfSelectors = this.params.selectors.length;
    this.numberFormatter = this.params.yVal[0].format;

    this.tDefault = this.d3MainService.TIMESPAN;
    this.opacity_lowest = this.d3MainService.OPACITY_LOWEST;
    this.delay = this.d3MainService.RESIZE_DELAY;

    // if there are selectors in layout service
    // if not, ngAfterViewInit fires (init)
    if (this.numberOfSelectors > 0) {
      // params, chartId, and selects
      if (changeObj.length > 3) {
        // params and selector changes first load
        this.params["change"] = "first load";
        return;
      }
      // selects change
      if (
        changeObj.length === 1 ||
        changeObj.length === 2 ||
        changeObj.length === 3
      ) {
        // selector changes
        this.params["change"] = "selector";
        this.init();
        //this.reshapedata();
        return;
      }
    }
  }

  ngOnInit() {
    //console.log(this.params);
  }

  ngAfterViewInit() {
    console.log("init component");
    this.init();
  }

  ngOnDestroy() {
    //console.log(this.params);
  }
}
