import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-presencia-residentes",
  templateUrl: "./presencia-residentes.component.html",
  styleUrls: ["./presencia-residentes.component.scss"]
})
export class PresenciaResidentesComponent implements OnInit {
  @Input()
  presence_residence_selectors: any;
  @Input()
  presence_residence_total: any;
  @Input()
  presence_residence_spain: any;
  @Input()
  presence_residence_abroad: any;
  @Input()
  presence_residence_ready: boolean;
  @Input()
  presence_selectors_ready: boolean;

  selectedMode: any;
  areaOptions: any;
  dayOptions: any;
  selectedArea: any;
  selectedPeriod: any;
  selectedMonth: any;
  selectedDay: any = null;
  displayMonths: boolean = false;
  displayDays: boolean = false;

  changeMode(event) {
    this.selectedMode = event.key;
    this.areaOptions = event.values;
  }

  changeArea(event) {
    this.selectedArea = event;
  }

  changePeriod(event) {
    this.selectedPeriod = event;
    if (event === 'annual') {
      this.displayMonths = false;
      this.displayDays = false;
    }
    // change logic here in case weeks and days are availables again
    // changed on may 2023 because days were merged on backEnd
    if (event === "months" || event === "weeks" || event === "days") {
      this.displayMonths = true;
      this.displayDays = false;
    }
  }

  changeMonth(event) {
    this.selectedMonth = event.key;
    this.dayOptions = event.values;
  }

  changeDay(event) {
    this.selectedDay = event;
  }

  constructor() { }
  ngOnInit() { }
}
