import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-brick-report-footer',
  templateUrl: './brick-report-footer.component.html',
  styleUrls: ['./brick-report-footer.component.scss']
})
export class BrickReportFooterComponent implements OnInit {
  @Input() page_number: number;


  constructor() { }

  ngOnInit(): void {

  }

}
