import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BricksNewFormComponent } from "./bricks-new-form.component";

const routes: Routes = [
  {
    path: "prescriptive/new-form",
    component: BricksNewFormComponent,
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BricksNewFormRoutingModule {
  static components = [BricksNewFormComponent];
}
