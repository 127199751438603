import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { SidenavEndRightComponent } from "./sidenav-end-right.component";
import { RotateArrowModule } from "../../../app-widgets/buttons/rotate-arrow/rotate-arrow.module";

@NgModule({
  declarations: [SidenavEndRightComponent],
  imports: [SharedModule, RotateArrowModule],
  exports: [SidenavEndRightComponent],
  providers: []
})
export class SidenavEndRightModule {}
