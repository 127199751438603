<!--
<table
  mat-table
  matSort
  [dataSource]="dataSource"
  class="mat-elevation-z0 competitors"
>
-->

<!--
<mat-table
  [dataSource]="dataSource"
  matSort
  class=" mat-elevation-z0 "
>
<ng-container matColumnDef="select">
  <th mat-header-cell *matHeaderCellDef>
    <mat-checkbox
      (change)="$event ? masterToggle() : null"
      (keydown.enter)="$event.preventDefault()"
      [checked]="selection.hasValue() && isAllSelected()"
      [indeterminate]="selection.hasValue() && !isAllSelected()"
      [aria-label]="checkboxLabel()"
    >
    </mat-checkbox>
  </th>
  <td mat-cell *matCellDef="let row">
    <mat-checkbox
      (click)="$event.stopPropagation()"
      (keydown.enter)="$event.preventDefault()"
      (change)="$event ? selection.toggle(row) : null; isSomeSelected()"
      [checked]="selection.isSelected(row)"
      [aria-label]="checkboxLabel(row)"
    >
    </mat-checkbox>
  </td>
</ng-container>
  
  
<ng-container matColumnDef="icon">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>
    Eliminar comercio
  </th>
  <td mat-cell *matCellDef="let element">
    <img
      src="{{ element.url_icono }}"
      alt=""
      height="32"
      width="32"
      style="margin-right: 8px"
    />{{ element.id_brand }}
  </td>
</ng-container>
  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dirección</th>
    <td mat-cell *matCellDef="let element">
      {{ element.address }}
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
    <td mat-cell *matCellDef="let element">
      {{ element.category }}
    </td>
  </ng-container>

  <ng-container matColumnDef="mall">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Centro Comercial</th>
    <td mat-cell *matCellDef="let element">
      {{ element.mall }}
    </td>
  </ng-container>

  

  <ng-container matColumnDef="m2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Superficie m2</th>
    <td mat-cell *matCellDef="let element">
      {{ element.distance_tostudy | number: "1.0-0":"es-ES" }}
    </td>
  </ng-container>
  
  <ng-container matColumnDef="distance_tostudy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Distancia (metros)</th>
    <td mat-cell *matCellDef="let element">
      {{ element.sup_m2 | number: "1.0-0":"es-ES" }}
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="selection.toggle(row)"
  ></tr>
</mat-table>
-->

<mat-table [dataSource]="dataSource" matSort class=" mat-elevation-z0 competitors">
  <!-- <mat-table [dataSource]="dataSource" matSort> -->
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null" (keydown.enter)="$event.preventDefault()"
        [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (keydown.enter)="$event.preventDefault()"
        (change)="$event ? selection.toggle(row) : null; isSomeSelected()" [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Eliminar Comercio
    </th>
    <td mat-cell *matCellDef="let element">
      <!--element.properties.info.name    
      {{ element.nombre }}
      -->
      <img src="{{ element.url_icono }}" alt="" height="32" width="32" style="margin-right: 8px" />{{ element.name }}
    </td>
  </ng-container>



  <!-- Name Column -->
  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dirección</th>
    <td mat-cell *matCellDef="let element">
      {{ element.address
      }}<!--element.properties.info.address-->
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
    <td mat-cell *matCellDef="let element">
      {{ element.category }}
    </td>
  </ng-container>

  <ng-container matColumnDef="mall">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Centro Comercial</th>
    <td mat-cell *matCellDef="let element">
      {{ element.mall }}
    </td>
  </ng-container>

  <ng-container matColumnDef="sup_m2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Superficie m2</th>
    <td mat-cell *matCellDef="let element">
      {{ element.sup_m2 | number: "1.0-0":"es-ES" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="distance_tostudy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Distancia (metros)</th>
    <td mat-cell *matCellDef="let element1">
      {{ element1.distance_tostudy | number: "1.0-0":"es-ES"}}
    </td>
  </ng-container>



  <!-- Name Column 
  <ng-container matColumnDef="area">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Área</th>
    <td mat-cell *matCellDef="let element">
      {{ element.id_brand }}
    </td>
  </ng-container>
-->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
  <!-- </table> -->
  <!-- </mat-table>
</div> -->
  <!-- </table> -->
</mat-table>


<section class="full-width clearfix padding-xs margin-top--xl">
  <div class="row">
    <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">
      <h2 class="tab-headline">
        OTROS COMERCIOS NO PRIORITARIOS
      </h2>


      <mat-table [dataSource]="dataSourceOtros" matSort class=" mat-elevation-z0 competitorsOth">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros</th>
          <td mat-cell *matCellDef="let element">

          </td>
        </ng-container>
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
          <td mat-cell *matCellDef="let element">
            {{ element.num }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsOtros"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsOtros"></tr>
      </mat-table>


    </div>
  </div>
</section>