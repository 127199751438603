import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { GeoNewFormComponent } from "./geo-new-form.component";
import { GeoNewFormResolverService } from "./geo-new-form-resolver.service";

const routes: Routes = [
  {
    path: "predictive/new-form",
    component: GeoNewFormComponent,
    canActivate: []
  },
  {
    path: "predictive/new-form/:id",
    component: GeoNewFormComponent,
    resolve: {
      mappedParams: GeoNewFormResolverService
    }
  },
  {
    path: "predictive/new-form/study/:idStudy",
    component: GeoNewFormComponent,
    resolve: {
      mappedParams: GeoNewFormResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeoNewFormRoutingModule {
  static components = [GeoNewFormComponent];
}
