import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { EditDialogGroupViewersComponent } from "./edit-dialog-group-viewers.component";

@NgModule({
    declarations: [EditDialogGroupViewersComponent],
    imports: [SharedModule],
    exports: [EditDialogGroupViewersComponent],
    providers: []
})
export class EditDialogGroupViewersModule {}
