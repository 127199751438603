<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <h1 class="h1-page--title">
          Descargas
        </h1>
        <p>Seleccione el documento que desee descargar a su dispositivo.</p>
        <div class="margin-top--xl">
          <form [formGroup]="downloadsForm" (ngSubmit)="onSubmit()">
            <div class="margin-top--l">
              <mat-form-field class="full-width">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="folderName" required (selectionChange)="filterFolder($event.value)">
                  <mat-option *ngFor="let dir of directories" [value]="dir.value">
                    {{ dir.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width">
                <mat-label>Año</mat-label>
                <mat-select formControlName="yearName" required (selectionChange)="filterFolderYear($event.value)">
                  <mat-option *ngFor="let dirYear of dirYears" [value]="dirYear.name">
                    {{ dirYear.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field class="full-width">
                <mat-label>Ficheros</mat-label>
                <mat-select formControlName="fileName" required>
                  <mat-option *ngFor="let file of files" [value]="file.fullName">
                    {{ file.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="margin-top--l">
              <button mat-flat-button color="primary" type="submit" [disabled]="!downloadsForm.valid">
                Descargar
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="downloads-img huerotate"></div>
      </div>
    </div>
  </div>

  <div class="margin-top--xl"></div>
</section>