import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabMassiveBuscadorComponent } from "./tab-massive-buscador.component";

@NgModule({
  declarations: [TabMassiveBuscadorComponent],
  imports: [SharedModule],
  exports: [TabMassiveBuscadorComponent],
  providers: []
})
export class TabMassiveBuscadorModule {}
