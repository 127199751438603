import { Injectable } from "@angular/core";
import * as d3 from "d3";

@Injectable({
  providedIn: "root"
})
export class D3MainService {
  // vars
  public COLORS = {
    primary_color: "#3d904d",
    primary_color_black: "#1b440a",
    secondary_color: "#eb7900",
    secondary_color_black: "#bd6203",
    tertiary_color: "#ffdd00",
    tertiary_color_black: "#ccb100",
    white: "#ffffff",
    gray_extraLight: "#E6E6E6",
    gray_light: "#C6C6C6",
    gray_medium: "#878787",
    gray_dark: "#6E6E6E",
    gray_heavy: "#3b3b3b",
    black: "#000000",
    red_dark: "#b33040",
    red_orange: "#d25c4d",
    coral: "#FF7F50",
    orange: "#FFA500",
    yellow: "#FFD700",
    yellow_orange: "#f2b447",
    yellow_green: "#d9d574",
    green1: "#1b9740",
    green2: "#1FAD4A",
    green3: "#9BCC3B",
    green4: "#D6E029",
    purple1: "#D73888",
    purple2: "#cc66cc",
    purple4: "#7f1b55",
    brown: "#c1baa9",
    blue1: "#3182bd",
    blue2: "#9c9ede",
    blue4: "#1a316b"
  };

  public TIMESPAN: number = 750;

  public RESIZE_DELAY: number = 750;

  public OPACITY_LOWEST: number = 0.2;

  public es_ES: any = {
    decimal: ",",
    thousands: ".",
    grouping: [3],
    currency: ["", "\u00a0€"],
    dateTime: "%a %b %e %X %Y",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    shortDays: ["Dom", "Lun", "Mar", "Mi", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    shortMonths: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  public time_ES = d3.timeFormatLocale(this.es_ES);
  public timeFormat = this.time_ES.format;
  public timeParse = this.time_ES.parse;
  public utcFormat = this.time_ES.utcFormat;
  public utcParse = this.time_ES.utcParse;

  public format_ES = d3.formatLocale(this.es_ES);
  public format = this.format_ES.format;
  public formatPrefix = this.format_ES.formatPrefix;

  public formatLongYear = this.timeFormat("%Y"); // 2019
  public formatSortYear = this.timeFormat("%y"); // 2019

  public formatMonthLong = this.timeFormat("%B");
  public formatMonthSort = this.timeFormat("%b");

  public FORMATPER: any = this.format(".0%"); // 7 --> 700%
  public FORMATROUND: any = this.format(".2f"); // 2,00
  public FORMATZERO: any = this.format(",.0f"); // 7.5 --> 7 // formatThousands

  public formatMillions: any = this.formatPrefix(".2", 1e6); // SI-prefix with two significant digits, "42,0M"

  /// DICTIONARY /////
  public dictionary = [
    {
      key: "provinces",
      values: [
        {
          name: "10",
          long_title: "Cáceres",
          short_title: "Cáceres",
          word_title: "CC"
        },
        {
          name: "11",
          long_title: "Cádiz",
          short_title: "Cádiz",
          word_title: "CA"
        },
        {
          name: "12",
          long_title: "Castellón",
          short_title: "Castellón",
          word_title: "CS"
        },
        {
          name: "13",
          long_title: "Ciudad Real",
          short_title: "C. Real",
          word_title: "CR"
        },
        {
          name: "14",
          long_title: "Córdoba",
          short_title: "Córdoba",
          word_title: "CO"
        },
        {
          name: "15",
          long_title: "A Coruña",
          short_title: "Coruña",
          word_title: "C"
        },
        {
          name: "16",
          long_title: "Cuenca",
          short_title: "Cuenca",
          word_title: "CU"
        },
        {
          name: "17",
          long_title: "Girona",
          short_title: "Girona",
          word_title: "GI"
        },
        {
          name: "18",
          long_title: "Granada",
          short_title: "Granada",
          word_title: "GR"
        },
        {
          name: "19",
          long_title: "Guadalajara",
          short_title: "Guadalajara",
          word_title: "GU"
        },
        {
          name: "20",
          long_title: "Guipúzcoa",
          short_title: "Guipúzcoa",
          word_title: "SS"
        },
        {
          name: "21",
          long_title: "Huelva",
          short_title: "Huelva",
          word_title: "H"
        },
        {
          name: "22",
          long_title: "Huesca",
          short_title: "Huesca",
          word_title: "HU"
        },
        {
          name: "23",
          long_title: "Jaén",
          short_title: "Jaén",
          word_title: "J"
        },
        {
          name: "24",
          long_title: "León",
          short_title: "León",
          word_title: "LE"
        },
        {
          name: "25",
          long_title: "Lleida",
          short_title: "Lleida",
          word_title: "L"
        },
        {
          name: "26",
          long_title: "La Rioja",
          short_title: "Rioja",
          word_title: "LO"
        },
        {
          name: "27",
          long_title: "Lugo",
          short_title: "Lugo",
          word_title: "LU"
        },
        {
          name: "28",
          long_title: "Madrid",
          short_title: "Madrid",
          word_title: "M"
        },
        {
          name: "29",
          long_title: "Málaga",
          short_title: "Málaga",
          word_title: "MA"
        },
        {
          name: "30",
          long_title: "Murcia",
          short_title: "Murcia",
          word_title: "MU"
        },
        {
          name: "31",
          long_title: "Navarra",
          short_title: "Navarra",
          word_title: "NA"
        },
        {
          name: "32",
          long_title: "Ourense",
          short_title: "Ourense",
          word_title: "OR"
        },
        {
          name: "33",
          long_title: "Asturias",
          short_title: "Asturias",
          word_title: "O"
        },
        {
          name: "34",
          long_title: "Palencia",
          short_title: "Palencia",
          word_title: "P"
        },
        {
          name: "35",
          long_title: "Las Palmas",
          short_title: "Palmas",
          word_title: "GC"
        },
        {
          name: "36",
          long_title: "Pontevedra",
          short_title: "Pontevedra",
          word_title: "PO"
        },
        {
          name: "37",
          long_title: "Salamanca",
          short_title: "Salamanca",
          word_title: "SA"
        },
        {
          name: "38",
          long_title: "Santa Cruz de Tenerife",
          short_title: "SC Tenerife",
          word_title: "TF"
        },
        {
          name: "39",
          long_title: "Cantabria",
          short_title: "Cantabria",
          word_title: "S"
        },
        {
          name: "40",
          long_title: "Segovia",
          short_title: "Segovia",
          word_title: "SG"
        },
        {
          name: "41",
          long_title: "Sevilla",
          short_title: "Sevilla",
          word_title: "SE"
        },
        {
          name: "42",
          long_title: "Soria",
          short_title: "Soria",
          word_title: "SO"
        },
        {
          name: "43",
          long_title: "Tarragona",
          short_title: "Tarragona",
          word_title: "TF"
        },
        {
          name: "44",
          long_title: "Teruel",
          short_title: "Teruel",
          word_title: "TE"
        },
        {
          name: "45",
          long_title: "Toledo",
          short_title: "Toledo",
          word_title: "TO"
        },
        {
          name: "46",
          long_title: "Valencia",
          short_title: "Valencia",
          word_title: "V"
        },
        {
          name: "47",
          long_title: "Valladolid",
          short_title: "Valladolid",
          word_title: "VA"
        },
        {
          name: "48",
          long_title: "Vizcaya",
          short_title: "Vizcaya",
          word_title: "BI"
        },
        {
          name: "49",
          long_title: "Zamora",
          short_title: "Zamora",
          word_title: "ZA"
        },
        {
          name: "50",
          long_title: "Zaragoza",
          short_title: "Zaragoza",
          word_title: "Z"
        },
        {
          name: "51",
          long_title: "Ceuta",
          short_title: "Ceuta",
          word_title: "CE"
        },
        {
          name: "52",
          long_title: "Melilla",
          short_title: "Melilla",
          word_title: "ML"
        },
        {
          name: "01",
          long_title: "Alava",
          short_title: "Alava",
          word_title: "VI"
        },
        {
          name: "02",
          long_title: "Albacete",
          short_title: "Albacete",
          word_title: "AB"
        },
        {
          name: "03",
          long_title: "Alicante",
          short_title: "Alicante",
          word_title: "A"
        },
        {
          name: "04",
          long_title: "Almería",
          short_title: "Almería",
          word_title: "AL"
        },
        {
          name: "05",
          long_title: "Ávila",
          short_title: "Ávila",
          word_title: "AV"
        },
        {
          name: "06",
          long_title: "Badajoz",
          short_title: "Badajoz",
          word_title: "BA"
        },
        {
          name: "07",
          long_title: "Islas Baleares",
          short_title: "Baleares",
          word_title: "PM"
        },
        {
          name: "08",
          long_title: "Barcelona",
          short_title: "Barcelona",
          word_title: "B"
        },
        {
          name: "09",
          long_title: "Burgos",
          short_title: "Burgos",
          word_title: "BU"
        },
        {
          name: "Others",
          long_title: "Otros",
          short_title: "Otros",
          word_title: "Otros"
        }
      ]
    },
    {
      key: "countries",
      values: [
        {
          name: "Be",
          long_title: "Bélgica",
          short_title: "Bélgica",
          word_title: "BEL"
        },
        {
          name: "Fr",
          long_title: "Francia",
          short_title: "Francia",
          word_title: "FRA"
        },
        {
          name: "Ge",
          long_title: "Alemania",
          short_title: "Alemania",
          word_title: "DEU"
        },
        {
          name: "It",
          long_title: "Italia",
          short_title: "Italia",
          word_title: "ITA"
        },
        {
          name: "Ne",
          long_title: "Holanda",
          short_title: "Holanda",
          word_title: "NDL"
        },
        {
          name: "No",
          long_title: "Noruega",
          short_title: "Noruega",
          word_title: "NOR"
        },
        {
          name: "Re",
          long_title: "Reunión",
          short_title: "Reunión",
          word_title: "REU"
        },
        {
          name: "Ir",
          long_title: "Irlanda",
          short_title: "Irlanda",
          word_title: "IRL"
        },
        {
          name: "UK",
          long_title: "Reino Unido",
          short_title: "R. Unido",
          word_title: "GBR"
        },
        {
          name: "Po",
          long_title: "Portugal",
          short_title: "Portugal",
          word_title: "PRT"
        },
        {
          name: "Ru",
          long_title: "Rumanía",
          short_title: "Rumanía",
          word_title: "ROU"
        },
        {
          name: "Belgium",
          long_title: "Bélgica",
          short_title: "Bélgica",
          word_title: "BEL"
        },
        {
          name: "France",
          long_title: "Francia",
          short_title: "Francia",
          word_title: "FRA"
        },
        {
          name: "Germany",
          long_title: "Alemania",
          short_title: "Alemania",
          word_title: "DEU"
        },
        {
          name: "Ireland",
          long_title: "Irlanda",
          short_title: "Irlanda",
          word_title: "IRL"
        },
        {
          name: "Italy",
          long_title: "Italia",
          short_title: "Italia",
          word_title: "ITA"
        },
        {
          name: "Netherlands",
          long_title: "Holanda",
          short_title: "Holanda",
          word_title: "NLD"
        },
        {
          name: "Northern Countries",
          long_title: "Países Nórdicos",
          short_title: "P. Nórdicos",
          word_title: "Nórdicos"
        },
        {
          name: "Portugal",
          long_title: "Portugal",
          short_title: "Portugal",
          word_title: "PRT"
        },
        {
          name: "Rest of Europe",
          long_title: "Resto de Europa",
          short_title: "Resto Europa",
          word_title: "Resto Europa"
        },
        {
          name: "Rest of the world",
          long_title: "Resto del mundo",
          short_title: "Resto mundo",
          word_title: "Resto mundo"
        },
        {
          name: "Russia",
          long_title: "Rusia",
          short_title: "Rusia",
          word_title: "RUS"
        },
        {
          name: "UK",
          long_title: "United Kingdom",
          short_title: "UK",
          word_title: "GBR"
        },
        {
          name: "Others",
          long_title: "Otros",
          short_title: "Otros",
          word_title: "Otros"
        }
      ]
    },

    {
      key: "wording",
      values: [
        {
          name: "zone",
          long_title: "Zona",
          short_title: "Zona",
          word_title: "Zona"
        },
        {
          name: "2min en coche",
          long_title: "2 minutos en coche",
          short_title: "2min coche",
          word_title: "2min"
        },
        {
          name: "5min en coche",
          long_title: "5 minutos en coche",
          short_title: "5min coche",
          word_title: "5min"
        },
        {
          name: "3min andando",
          long_title: "3 minutos andando",
          short_title: "3min andando",
          word_title: "3min"
        },
        {
          name: "5min andando",
          long_title: "5 minutos andando",
          short_title: "5min andando",
          word_title: "5min"
        },
        {
          name: "8min andando",
          long_title: "5 minutos andando",
          short_title: "8min andando",
          word_title: "8min"
        },
        {
          name: "municipio",
          long_title: "Municipio",
          short_title: "Municipio",
          word_title: "Municipio"
        },
        {
          name: "provincia",
          long_title: "Provincia",
          short_title: "Provincia",
          word_title: "Provincia"
        },
        {
          name: "pais",
          long_title: "País",
          short_title: "País",
          word_title: "País"
        },
        {
          name: "foot",
          long_title: "Andando",
          short_title: "Andando",
          word_title: "Andando"
        },
        {
          name: "driving",
          long_title: "En coche",
          short_title: "En coche",
          word_title: "En coche"
        },
        {
          name: "00-03",
          long_title: "3 minutos",
          short_title: "3 minutos",
          word_title: "3min."
        },
        {
          name: "03-05",
          long_title: "5 minutos",
          short_title: "5 minutos",
          word_title: "5min."
        },
        {
          name: "05-10",
          long_title: "10 minutos",
          short_title: "10 minutos",
          word_title: "10min."
        },
        {
          name: "10-15",
          long_title: "15 minutos",
          short_title: "15 minutos",
          word_title: "15min."
        },
        {
          name: "E0",
          long_title: "16 a 29 años",
          short_title: "16-29 años",
          word_title: "16-29"
        },
        {
          name: "E1",
          long_title: "30 a 49 años",
          short_title: "30-49 años",
          word_title: "30-49"
        },
        {
          name: "E2",
          long_title: "50 a 64 años",
          short_title: "50-64 años",
          word_title: "50-64"
        },
        {
          name: "E3",
          long_title: "Más de 65 añs",
          short_title: "+65 años",
          word_title: "+65"
        },
        {
          name: "H",
          long_title: "En casa",
          short_title: "Casa",
          word_title: "Casa"
        },
        {
          name: "W",
          long_title: "En el trabajo",
          short_title: "Trabajo",
          word_title: "Trabajo"
        },
        {
          name: "O",
          long_title: "Frecuente",
          short_title: "Frecuente",
          word_title: "Frecuente"
        },
        {
          name: "NF",
          long_title: "No frecuente",
          short_title: "No frecuente",
          word_title: "No frecuente"
        },
        {
          name: "Spain",
          long_title: "España",
          short_title: "España",
          word_title: "Esp."
        },
        {
          name: "abroad",
          long_title: "Extranjero",
          short_title: "Extranjero",
          word_title: "Ext."
        },
        {
          name: "Male",
          long_title: "Hombre",
          short_title: "Hombre",
          word_title: "Hombre"
        },
        {
          name: "Female",
          long_title: "Mujer",
          short_title: "Mujer",
          word_title: "Mujer"
        },
        {
          name: "perc_mostrador",
          long_title: "Mostrador",
          short_title: "Mostrador",
          word_title: "Mostrador"
        },
        {
          name: "perc_mcauto",
          long_title: "McAuto",
          short_title: "McAuto",
          word_title: "McAuto"
        },
        {
          name: "perc_mccafe",
          long_title: "McCafe",
          short_title: "McCafe",
          word_title: "McCafe"
        },
        {
          name: "perc_kiosko",
          long_title: "Kiosko",
          short_title: "Kiosko",
          word_title: "Kiosko"
        },
        {
          name: "income_per_household",
          long_title: "Ingresos por hogar",
          short_title: "Ingresos/hogar",
          word_title: "Ingresos/hogar"
        },
        {
          name: "unemployment_rate",
          long_title: "Tasa de paro",
          short_title: "Desempleados",
          word_title: "Desempleados"
        }
      ]
    },
    {
      key: "controls-select-residence",
      values: [
        {
          name: "annual",
          long_title: "Anual",
          short_title: "Anual",
          word_title: "Anual"
        },
        {
          name: "months",
          long_title: "Mensual",
          short_title: "Mensual",
          word_title: "Mensual"
        },
        {
          name: "weeks",
          long_title: "Semanal",
          short_title: "Semanal",
          word_title: "Semanal"
        },
        {
          name: "days",
          long_title: "Diario",
          short_title: "Diario",
          word_title: "Diario"
        }
      ]
    },
    {
      key: "controls-select-segmentation",
      values: [
        {
          name: "annual",
          long_title: "Anual",
          short_title: "Anual",
          word_title: "Anual"
        },
        {
          name: "months",
          long_title: "Por meses",
          short_title: "Por meses",
          word_title: "Por meses"
        },
        {
          name: "weeks",
          long_title: "Semanal",
          short_title: "Semanal",
          word_title: "Semanal"
        },
        {
          name: "days",
          long_title: "Por horas",
          short_title: "Por horas",
          word_title: "Por horas"
        }
      ]
    },
    {
      key: "controls-select",
      values: [
        {
          name: "driving",
          long_title: "En coche",
          short_title: "Coche",
          word_title: "Coche"
        },
        {
          name: "foot",
          long_title: "Andando",
          short_title: "Andando",
          word_title: "Andando"
        },
        {
          name: "<03",
          long_title: "Menos de 3 minutos",
          short_title: "0-3 min.",
          word_title: "<3min."
        },
        {
          name: "<05",
          long_title: "Menos de 5 minutos",
          short_title: "0-5 min.",
          word_title: "<5min."
        },
        {
          name: "<10",
          long_title: "Menos de 10 minutos",
          short_title: "0-10 min.",
          word_title: "<10min."
        },
        {
          name: "<15",
          long_title: "Menos de 15 minutos",
          short_title: "0-15 min.",
          word_title: "<15min."
        },
        {
          name: "annual",
          long_title: "Anual",
          short_title: "Anual",
          word_title: "Anual"
        },
        {
          name: "months",
          long_title: "Mensual",
          short_title: "Mensual",
          word_title: "Mensual"
        },
        {
          name: "weeks",
          long_title: "Semanal",
          short_title: "Semanal",
          word_title: "Semanal"
        },
        {
          name: "days",
          long_title: "Diario",
          short_title: "Diario",
          word_title: "Diario"
        },
        {
          name: "3min",
          long_title: "A 3 minutos",
          short_title: "A 3 min.",
          word_title: "3 min."
        },
        {
          name: "5min",
          long_title: "A 5 minutos",
          short_title: "A 5 min.",
          word_title: "5 min."
        },
        {
          name: "10min",
          long_title: "A 10 minutos",
          short_title: "A 10 min.",
          word_title: "10 min."
        },
        {
          name: "15min",
          long_title: "A 15 minutos",
          short_title: "A 15 min.",
          word_title: "15 min."
        },
        {
          name: "half_hour_sales",
          long_title: "Ventas por hora",
          short_title: "Por hora/día",
          word_title: "Horaria"
        },
        {
          name: "week_sales",
          long_title: "Venta semanal",
          short_title: "Por día/semana",
          word_title: "Semanal"
        },
        {
          name: "month_sales",
          long_title: "Venta mensual",
          short_title: "Por mes/año",
          word_title: "Mensual"
        },
        {
          name: "trimester_sales",
          long_title: "Ventas por trimestre",
          short_title: "Por trimestre/año",
          word_title: "Trimestral"
        },
        {
          name: "activity",
          long_title: "Actividad",
          short_title: "Actividad",
          word_title: "Actividad"
        },
        {
          name: "activity_origin",
          long_title: "Actividad en origen",
          short_title: "Actividad en origen",
          word_title: "Actividad origen"
        },
        {
          name: "activity_destination",
          long_title: "Actividad en destino",
          short_title: "Actividad en destino",
          word_title: "Actividad destino"
        },
        {
          name: "age",
          long_title: "Edad",
          short_title: "Edad",
          word_title: "Edad"
        },
        {
          name: "gender",
          long_title: "Género",
          short_title: "Género",
          word_title: "Género"
        },
        {
          name: "Todas",
          long_title: "Todas",
          short_title: "Todas",
          word_title: "Todas"
        },
        {
          name: "000-005",
          long_title: "0-5 km",
          short_title: "0-5 km",
          word_title: "0-5"
        },
        {
          name: "005-010",
          long_title: "5-10 km",
          short_title: "5-10 km",
          word_title: "5-10"
        },
        {
          name: "010-025",
          long_title: "10-25 km",
          short_title: "10-25 km",
          word_title: "10-25"
        },
        {
          name: "025-050",
          long_title: "25-50 km",
          short_title: "25-50 km",
          word_title: "25-50"
        },
        {
          name: "050-100",
          long_title: "50-100 km",
          short_title: "50-100 km",
          word_title: "50-100"
        },
        {
          name: "100-200",
          long_title: "100-200 km",
          short_title: "100-200 km",
          word_title: "100-200"
        },
        {
          name: "200",
          long_title: "+200 km",
          short_title: "+200 km",
          word_title: "+200"
        }
      ]
    }
  ];

  // dictionary getter
  // nommbre de la zona, corto, medio, largo
  // se puede pasar un objeto o un string
  // diccionary key: el diccionario
  // n: la columna del diccionario que devuelve
  // z: valor que se pasa (objeto o string)
  // k: en caso de objeto, la key del objeto que se quiere comprobar

  public getRealName = (key, n, z, k?) => {
    // get the dictionary
    let dic: Array<any> = this.dictionary.filter(t => {
      return t.key == key;
    })[0]["values"];

    let i: number = 0,
      name,
      dicLength: number = dic.length;

    for (i; i < dicLength; i++) {
      if (z && typeof z === "object" && z.constructor === Object) {
        if (dic[i]["name"] === z[k]) {
          name = dic[i][n];
        }
      }

      if (typeof z === "string" || z instanceof String) {
        if (dic[i]["name"] === z) {
          name = dic[i][n];
        }
      }
    }
    if (!name) {
      name = z[k] ? z[k] : z;
    }
    return name;
  };

  //////////// GETTERS ////////////
  public getDimensions = (svg: any, selector: string, margin: any): any => {
    // Reset the svg dimensions
    svg.attr("width", 0).attr("height", 0);

    const container = d3.select(selector);

    // Get the new dimensions
    const containerWidth: number = parseInt(container.style("width"), 10);
    const containerHeight: number = parseInt(container.style("height"), 10);
    const width: number = containerWidth - margin.left - margin.right;
    const height: number = containerHeight - margin.top - margin.bottom;

    return { width: width, height: height };
  };

  public getDomain = (
    extent: Array<number>,
    avg: number,
    pad: number
  ): Array<number> => {
    // Find the farest point
    let dif: number = d3.max([avg - extent[0], extent[1] - avg]);
    if (dif == 0) {
      dif = 0.01;
    }

    // dif = (Math.ceil(dif * 1000)/1000) * pad;
    dif = dif * pad;
    // Add it to both sides of the avg
    const domain = [avg - dif, avg + dif];
    return domain;
  };

  public getTicks = (domain: Array<number>, avg: number): Array<number> => {
    let ticks: Array<number>;
    // Find the farest point
    const step: number = (domain[1] - avg) / 2;
    ticks = [domain[0], domain[0] + step, avg, avg + step, domain[1]];

    return ticks;
  };

  ///// HELPERS ////

  public capitalize = str => {
    str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  public toTitleCase = str => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  public isNumber = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  public wrap = (text, width) => {
    text.each((d, i, arr) => {
      let text = d3.select(arr[i]),
        words = text
          .text()
          .split(/\s+/)
          .reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  };
}
