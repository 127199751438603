import { Injectable } from "@angular/core";

import { D3LayoutService } from "./d3-layout.service";
import { DynamicSelectorsService } from "./dynamic-selectors.service";

import * as d3 from "d3";

@Injectable({
  providedIn: "root"
})
export class ParamsLayoutService {
  public getLayoutParams = (d: any, chartId: string): any => {
    let layoutParams = d;
    let layoutObj = this.d3LayoutService.d3Layout.find(c => c.id == chartId);
    layoutParams.id = layoutObj.id ? layoutObj.id : "";

    layoutParams.container = layoutObj.container ? layoutObj.container : {};
    layoutParams.legend = layoutObj.legend ? layoutObj.legend : {};
    layoutParams.units = layoutObj.units ? layoutObj.units[0] : {};

    layoutParams.selectors = !!layoutObj.selectors
      ? layoutObj.selectors
      : this.dynamicSelectorsService.getDynamicSelectors(layoutParams.id);
    layoutParams.xVal = layoutObj.xVal ? layoutObj.xVal : null;
    layoutParams.yVal = layoutObj.yVal ? layoutObj.yVal : null;
    layoutParams.design = layoutObj.design ? layoutObj.design : {};
    layoutParams.tooltip = layoutObj.tooltip ? layoutObj.tooltip : {};
    layoutParams["dictionary"] = layoutObj["dictionary"]
      ? layoutObj["dictionary"]
      : {};

    return layoutParams;
  };

  constructor(
    private d3LayoutService: D3LayoutService,
    private dynamicSelectorsService: DynamicSelectorsService
  ) {}

  ngOnChanges(changes: any) {}
}
