<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">BURGER KING</h2>
      <p>Enseñas Burger King más cercanas.</p>
      <div class="margin-top--s">
        <app-expansion-panel panel_num="1"></app-expansion-panel>
      </div>

    </div>
  </div>

  <div class="margin-top--s"></div>
</section>

<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">BURGER KING DESTACADO</h2>

      <p>Burger King más cercano con apertura en los últimos 12 meses.</p>
      <div class="margin-top--s">
        <app-expansion-panel panel_num="2"></app-expansion-panel>
      </div>
    </div>
  </div>
  <div class="margin-top--s"></div>
</section>

<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">OTRAS ENSEÑAS</h2>

      <p>Enseñas ordenadas por distancia al McDonalds seleccionado.</p>
      <div class="margin-top--s">
        <app-competencia-table table_num="1"></app-competencia-table>
      </div>
    </div>
  </div>
  <div class="margin-top--s"></div>
</section>