import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-massive-brick-studies",
  templateUrl: "./massive-brick-studies.component.html",
  styleUrls: ["./massive-brick-studies.component.scss"]
})
export class MassiveBrickStudiesComponent implements OnInit {
  title: string = "Estudios masivos de predicción de ventas";
  paragraph: string = "Proyectos para nuevas aperturas McDonald's.";

  userAuthorized: boolean = false;
  
  constructor() {}

  ngOnInit() {
    //get user
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      if (user.group != "advance") {
        this.userAuthorized = false;
      } else {
        this.userAuthorized = true;
      }
    }

  }
}
