import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";

import { D3MainService } from "../../../app-services/d3/d3-main.service";
import { ReshapeDataService } from "../../../app-services/d3/reshape-data.service";

import * as d3 from "d3";

@Component({
  selector: "app-treemap",
  templateUrl: "./treemap.component.html",
  styleUrls: ["./treemap.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreemapComponent implements OnInit {
  // params
  @Input()
  params: any;
  @Input()
  chartId: string;
  @Input()
  chartSize: string;
  @Input() firstFilterValue?: any;
  @Input() secondFilterValue?: any;
  @Input() thirdFilterValue?: any;
  @Input() fourFilterValue?: any;
  @Input() fifthFilterValue?: any;
  @Input() sixthFilterValue?: any;

  dataChart: any;
  root: any;
  dataSum: number;

  win: any = window; // in use
  timeout: any = false;
  delay: number;
  tDefault: number;

  width: number;
  height: number;
  margin: any;
  design: any;
  // svg containers
  container: any;
  svg: any;
  chartWrapper: any;
  chartInner: any;

  // scales
  colorScale: any;

  // tooltip
  tooltip: any;
  tooltipStyle: any;
  tooltipSize: any;

  // services
  opacity_lowest: number;
  formatZero: any;
  formatRound: any;
  units: any;
  getRealName: any;
  dictionary: any;

  init = (): void => {
    this.destroyChart();
    this.renderAll();
  };

  destroyChart() {
    d3.select(this.params.container || "#" + this.chartId).html("");
    d3.select(this.win).on("resize", null);
  }
  renderAll = (): void => {
    this.colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // ELEMENTS
    this.container = d3.select(this.params.container || "#" + this.chartId); // placeholder div for svg

    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    this.chartWrapper = this.svg
      .selectAll("g.chartWrapper")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartWrapper")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.chartInner = this.chartWrapper
      .selectAll("g.chartInner") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartInner");

    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.render, this.delay);
    });
    this.reshapedata();
  };

  reshapedata = (): void => {
    this.dataChart = this.reshapeDataService.reshapeDataToRender(this.params, [
      this.firstFilterValue,
      this.secondFilterValue,
      this.thirdFilterValue,
      this.fourFilterValue,
      this.fifthFilterValue,
      this.sixthFilterValue
    ]);

    this.root = d3.hierarchy(this.dataChart, d => d.values).sum(d => d.value);

    this.dataSum = d3.sum(this.dataChart.values, x => x.value);

    this.render();
  };

  render = (): void => {
    let dimensions = this.d3MainService.getDimensions(
      this.svg,
      this.params.container || "#" + this.chartId,
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .attr(
        "viewBox",
        "0 0 " +
        (this.width + this.margin.left + this.margin.right) +
        " " +
        (this.height + this.margin.top + this.margin.bottom)
      )
      .attr("preserveAspectRatio", "xMaxYMax meet");

    this.drawTreeMap();
    this.drawTooltip();
  };

  public drawTreeMap = () => {
    const treemap = d3
      .treemap()
      .size([this.width, this.height])
      .padding(1)
      .round(true);
    const tree = treemap(this.root);
    let colorScale = this.colorScale;
    let getRealName = this.getRealName;
    let dictionary = this.dictionary;

    let mouseoverNode: any = this.mouseoverNode;
    let mouseoutNode: any = this.mouseoutNode;
    let mousemoveNode: any = this.mousemoveNode;

    let node: any = this.chartInner
      .datum(this.root)
      .selectAll(".node")
      .data(tree.leaves(), function (d) {
        return d.key;
      });

    node.selectAll("rect.treemap-rect").data(tree.leaves(), function (d) {
      return d.key;
    });

    node.selectAll("text").data(tree.leaves(), function (d) {
      return d.key;
    });

    node.selectAll("rect.tooltip-clip").data(tree.leaves(), function (d) {
      return d.key;
    });

    node
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault)
      .remove();

    // enter
    let newNode = node
      .enter()
      .append("svg:g")
      .attr("class", "node");

    newNode.append("svg:rect").attr("class", "treemap-rect");
    newNode.append("svg:text");

    newNode
      .append("svg:rect")
      .attr("class", "tooltip-clip")
      .style("visibility", "hidden")
      .style("pointer-events", "all")
      .on("mouseover", mouseoverNode)
      .on("mouseout", mouseoutNode)
      .on("mousemove", mousemoveNode);

    // update
    this.chartInner
      .selectAll(".node rect")
      .transition()
      .duration(this.tDefault)
      .attr("x", function (d) {
        return d.x0;
      })
      .attr("y", function (d) {
        return d.y0;
      })
      .attr("width", function (d) {
        return d.x1 - d.x0;
      })
      .attr("height", function (d) {
        return d.y1 - d.y0;
      })
      .attr("fill", function (d) {
        while (d.depth > 1) d = d.parent;
        return colorScale(d.data.key);
      });

    this.chartInner
      .selectAll(".node text")
      .transition()
      .duration(this.tDefault)
      .text(function (d, i) {
        if (i === 0) {
          return getRealName(dictionary, "short_title", d.data.key); //return the array data;
        }

      })
      .attr("y", "1.5em")
      .attr("x", "0.5em")
      .attr("font-size", "1.0em")
      .attr("fill", "#000")
      .attr("transform", function (d) {
        return "translate(" + [d.x0, d.y0] + ")";
      });
  };

  public drawTooltip = () => {
    this.svg.select(".tooltip").remove();

    this.tooltip = this.svg
      .append("svg:g")
      .attr("class", "tooltip")
      .style("display", "none");

    this.tooltip
      .append("svg:rect")
      .attr("width", 0)
      .attr("height", 0)
      .attr("fill", "white")
      .style("opacity", 0);
  };

  public mouseoverNode = () => {
    const selected: any = d3.select(d3.event.target);
    selected.style("cursor", "pointer");
    this.tooltip.style("display", null);
  };

  public mouseoutNode = () => {
    d3.selectAll(this.params.container + " .chartInner .node rect").style(
      "opacity",
      1
    );
    this.tooltip.select("text").remove();
    this.tooltip.style("display", "none");
  };

  public mousemoveNode = d => {
    let xPosition = d3.mouse(d3.event.target)[0] + 0;
    let yPosition = d3.mouse(d3.event.target)[1] - this.tooltipSize.height;
    let getRealName = this.getRealName;
    let dictionary = this.dictionary;

    if (xPosition < this.tooltipSize.width) {
      xPosition = this.tooltipSize.width / 2;
    }
    if (xPosition > this.width - this.tooltipSize.width) {
      xPosition = this.width - this.tooltipSize.width / 2;
    }

    this.tooltip.attr(
      "transform",
      "translate(" + xPosition + "," + yPosition + ")"
    );

    let tooltipText = this.tooltip
      .selectAll("text")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("text-anchor", this.tooltipStyle.text_anchor)
      .attr("font-size", this.tooltipStyle.font_size)
      .attr("font-weight", this.tooltipStyle.font_weight);

    tooltipText
      .append("tspan")
      .text(getRealName(dictionary, "short_title", d.data.key) + ": ")
      .attr("x", 0)
      .attr("dx", 0)
      .attr("dy", 0)
      .style("fill", this.tooltipStyle.fill_text)
      .style("text-anchor", this.tooltipStyle.text_anchor)
      .attr("class", "title");

    tooltipText
      .append("tspan")
      .text(this.formatZero(d.data.value) + " " + this.units["value"])
      .attr("class", "number")
      .attr("x", 0)
      .attr("dx", 0)
      .attr("dy", 12)
      .style("text-anchor", this.tooltipStyle.text_anchor);

    tooltipText
      .append("tspan")
      .text(this.formatRound((d.data.value * 100) / this.dataSum) + "%")
      .attr("class", "number")
      .attr("x", 0)
      .attr("dx", 0)
      .attr("dy", 12)
      .style("text-anchor", this.tooltipStyle.text_anchor);
  };

  public drawAxis = (): void => { };
  public drawGrid = (): void => { };

  constructor(
    private d3MainService: D3MainService,
    private reshapeDataService: ReshapeDataService
  ) { }

  ngOnChanges(changes: any) {
    let changeObj = Object.keys(changes);

    this.design = this.params.design;
    this.margin = this.params.design.margin;
    this.tDefault = this.d3MainService.TIMESPAN;
    this.opacity_lowest = this.d3MainService.OPACITY_LOWEST;
    this.tooltipStyle = this.params.tooltip.style;
    this.tooltipSize = this.params.tooltip.size;
    this.formatZero = this.d3MainService.FORMATZERO;
    this.formatRound = this.d3MainService.FORMATROUND;
    this.getRealName = this.d3MainService.getRealName;
    this.units = this.params.units;
    this.dictionary = this.params.dictionary;
    this.delay = this.d3MainService.RESIZE_DELAY;

    if (changeObj.length > 3) {
      // params and selector changes first load
      this.params["change"] = "first load";
      //this.init();
      return;
    }

    if (
      changeObj.length === 1 ||
      changeObj.length === 2 ||
      changeObj.length === 3
    ) {
      // selector changes
      this.params["change"] = "selector";
      this.reshapedata();
      return;
    }
  }

  ngOnInit() {
    console.log(this.params);
  }

  ngAfterViewInit() {
    this.init();
  }
}
