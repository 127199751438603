import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {catchError, map} from 'rxjs/operators';
import _ from 'lodash';

export interface Todo {
    id: number | string;
    createdAt: number;
    value: string;
}

@Injectable()
export class GeoStudiesDataService {
    todos: Observable<Todo[]>;
    private _todos: BehaviorSubject<Todo[]>;
    private studiesUrl: string;
    private dataStore: {
        todos: Todo[];
    };

    featuresSelected: any;
    selectedStudy: any;

    coordsPredictiveStudy: any;

    private addressPredictive$ = new Subject<any>();
    addressPredictiveChanged$ = this.addressPredictive$.asObservable();

    private addressPredictiveAddMcd$ = new Subject<any>();
    addressPredictiveAddMcdChanged$ = this.addressPredictiveAddMcd$.asObservable();


    private studyZoomPredictive$ = new Subject<any>();
    studyZoomPredictiveChanged$ = this.studyZoomPredictive$.asObservable();

    private coordsPredictive$ = new Subject<any>();
    coordsPredictiveChanged$ = this.coordsPredictive$.asObservable();

    private studyMapPredictive$ = new Subject<any>();
    studyMapPredictiveChanged$ = this.studyMapPredictive$.asObservable();

    private studyParamsPredictive$ = new Subject<any>();
    studyParamsPredictiveChanged$ = this.studyParamsPredictive$.asObservable();

    private studyParamsPredictiveAddMcd$ = new Subject<any>();
    studyParamsPredictiveAddMcdChanged$ = this.studyParamsPredictiveAddMcd$.asObservable();


    private bricksPredictive$ = new Subject<any>();
    bricksPredictiveChanged$ = this.bricksPredictive$.asObservable();

    private bricksTransitPredictive$ = new Subject<any>();
    bricksTransitPredictiveChanged$ = this.bricksTransitPredictive$.asObservable();

    private competitorsPointPredictive$ = new Subject<any>();
    competitorsPointPredictiveChanged$ = this.competitorsPointPredictive$.asObservable();

    private commercesPredictive$ = new Subject<any>();
    commercesPredictiveChanged$ = this.commercesPredictive$.asObservable();

    private mallPredictive$ = new Subject<any>();
    mallPredictiveChanged$ = this.mallPredictive$.asObservable();

    private studyPredictive$ = new Subject<any>();
    studyPredictiveChanged$ = this.studyPredictive$.asObservable();
    private totalCount: any;
    private loadingAll = false;
    private skip: number;
    private limit: number;

    constructor(private http: HttpClient) {
        this.studiesUrl = "../../assets/data/geo_studies_data.json";
        this.dataStore = {todos: []};
        this._todos = <BehaviorSubject<Todo[]>>new BehaviorSubject([]);
        this.todos = this._todos.asObservable();
    }

    count() {
        this.studiesUrl =
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            '/studies/?count=true';
        return this.http
            .get(this.studiesUrl)
            .pipe(map((res: any) => this.totalCount = res?.count)).toPromise();

    }

    async updateLast(count: number = 50) {
        this.studiesUrl =
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            `/studies/?skip=${await this.count() - count}&limit=${count}`;
        if (!this.loadingAll) {
            return this.http.get<Todo[]>(`${this.studiesUrl}`).subscribe(
                (data) => {
                    /////
                    data = data.map((el) => {
                        if (!el["properties"].hasOwnProperty("info")) {
                            el["properties"]["info"] = {};
                        }
                        el["properties"]["info"]["_id"] = el["_id"];
                        el["properties"]["info"]["properties"] = el["properties"];
                        el["properties"]["info"]["geometry"] = el["geometry"];
                        if (!el["properties"].hasOwnProperty("study")) {
                            el["properties"]["study"] = {};

                            el["properties"]["study"]["study_id"] = 0;

                            el["properties"]["study"]["version"];
                            el["properties"]["study"]["state_description"];
                            el["properties"]["info"]["name"];
                            el["properties"]["info"]["address"];
                            el["properties"]["study"][
                                "created"
                                ] = "2018-11-23T18:25:43.511Z"
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".")[0];
                            el["properties"]["info"]["fecha modificado"] = el["properties"][
                                "study"
                                ]["modified"] = "2018-11-23T18:25:43.511Z"
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".");
                        } else {
                            el["properties"]["info"]["id"] =
                                el["properties"]["study"]["study_id"];
                            el["properties"]["info"]["version"] =
                                el["properties"]["study"]["version"];
                            el["properties"]["info"]["estado"] =
                                el["properties"]["study"]["state_description"];
                            el["properties"]["info"]["nombre"] =
                                el["properties"]["info"]["name"];
                            el["properties"]["info"]["dirección"] =
                                el["properties"]["info"]["address"];
                            el["properties"]["info"]["fecha creacion"] = el["properties"][
                                "study"
                                ]["created"]
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".")[0];
                            el["properties"]["info"]["fecha modificado"] = el["properties"][
                                "study"
                                ]["modified"]
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".")[0];
                        }

                        //ñapa hasta que JL pase los datos ventas:
                        if (!el["properties"].hasOwnProperty("data_model")) {
                            el["properties"]["data_model"] = {};
                            el["properties"]["data_model"]["sales_info"] = {};
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ] = {};
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Delivery"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Kiosco"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Mostrador"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Mostrador_Kiosco"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["McCafe"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["McAuto"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "perc_of_sales_associated_to_existing_rest"
                                ] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "first_year_sales"
                                ] = {};
                            el["properties"]["data_model"]["sales_info"]["first_year_sales"][
                                "medium"
                                ] = 0;
                            el["properties"]["data_model"]["sales_info"]["first_year_sales"][
                                "lower"
                                ] = 0;
                            el["properties"]["data_model"]["sales_info"]["first_year_sales"][
                                "upper"
                                ] = 0;
                            // el["data_model"]["sales_info"]["first_year_sales"] = 0;

                            el["properties"]["data_model"]["impact_info"] = {};
                        }

                        return el["properties"]["info"];
                    });

                    this.dataStore.todos = data.concat(this.dataStore.todos);
                    const ids = [];
                    const filteredData = [];
                    this.dataStore.todos.forEach((element) => {
                        if (!ids.includes(element['_id'])){
                          ids.push(element["_id"]);
                          filteredData.push(element);
                        }
                    });
                    this.dataStore.todos = filteredData;
                    this._todos.next(Object.assign({}, this.dataStore).todos);
                },
                (error) => {
                    this.loadingAll = false;
                    console.log('Could not load todos.');
                }
            );
        }
    }

    async loadAll(clearBefore = true, limit = 200, skip = 200) {
        let step = 200;

        if (!this.loadingAll && clearBefore) {
            this.totalCount = await this.count();
            if (this.totalCount < step ){
                skip = this.totalCount;
            }
            this.dataStore.todos = [];
        }


        this.studiesUrl =
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            `/studies/?skip=${this.totalCount - skip}&limit=${limit}`;

        if (!(this.loadingAll && clearBefore) ) {

            this.loadingAll = true;
            return this.http.get<Todo[]>(`${this.studiesUrl}`).subscribe(
                (data) => {
                    /////
                    data = data.map((el) => {
                        if (!el["properties"].hasOwnProperty("info")) {
                            el["properties"]["info"] = {};
                        }
                        el["properties"]["info"]["_id"] = el["_id"];
                        el["properties"]["info"]["properties"] = el["properties"];
                        el["properties"]["info"]["geometry"] = el["geometry"];
                        if (!el["properties"].hasOwnProperty("study")) {
                            el["properties"]["study"] = {};

                            el["properties"]["study"]["study_id"] = 0;

                            el["properties"]["study"]["version"];
                            el["properties"]["study"]["state_description"];
                            el["properties"]["info"]["name"];
                            el["properties"]["info"]["address"];
                            el["properties"]["study"][
                                "created"
                                ] = "2018-11-23T18:25:43.511Z"
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".")[0];
                            el["properties"]["info"]["fecha modificado"] = el["properties"][
                                "study"
                                ]["modified"] = "2018-11-23T18:25:43.511Z"
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".");
                        } else {
                            el["properties"]["info"]["id"] =
                                el["properties"]["study"]["study_id"];
                            el["properties"]["info"]["version"] =
                                el["properties"]["study"]["version"];
                            el["properties"]["info"]["estado"] =
                                el["properties"]["study"]["state_description"];
                            el["properties"]["info"]["nombre"] =
                                el["properties"]["info"]["name"];
                            el["properties"]["info"]["dirección"] =
                                el["properties"]["info"]["address"];
                            el["properties"]["info"]["fecha creacion"] = el["properties"][
                                "study"
                                ]["created"]
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".")[0];
                            el["properties"]["info"]["fecha modificado"] = el["properties"][
                                "study"
                                ]["modified"]
                                .split("T")
                                .join(" ")
                                .split("Z")
                                .join("")
                                .split(".")[0];
                        }

                        //ñapa hasta que JL pase los datos ventas:
                        if (!el["properties"].hasOwnProperty("data_model")) {
                            el["properties"]["data_model"] = {};
                            el["properties"]["data_model"]["sales_info"] = {};
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ] = {};
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Delivery"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Kiosco"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Mostrador"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["Mostrador_Kiosco"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["McCafe"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "channel_decomposition"
                                ]["McAuto"] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "perc_of_sales_associated_to_existing_rest"
                                ] = 0;
                            el["properties"]["data_model"]["sales_info"][
                                "first_year_sales"
                                ] = {};
                            el["properties"]["data_model"]["sales_info"]["first_year_sales"][
                                "medium"
                                ] = 0;
                            el["properties"]["data_model"]["sales_info"]["first_year_sales"][
                                "lower"
                                ] = 0;
                            el["properties"]["data_model"]["sales_info"]["first_year_sales"][
                                "upper"
                                ] = 0;
                            // el["data_model"]["sales_info"]["first_year_sales"] = 0;

                            el["properties"]["data_model"]["impact_info"] = {};
                        }

                        return el["properties"]["info"];
                    });

                    this.dataStore.todos = _.union(this.dataStore.todos, data);
                    this._todos.next(Object.assign({}, this.dataStore).todos);
                    this.skip = skip;
                    this.limit = limit;

                    if (this.dataStore.todos.length < this.totalCount) {
                        setTimeout(() => {
                            let skipCal = this.dataStore.todos.length + step;

                            if (skipCal > this.totalCount) {
                                step = this.totalCount - (skipCal - 200);
                                skipCal = this.totalCount;
                            }
                            this.loadAll(false, step, skipCal);
                        }, 20);

                    } else {
                        this.updateLast();
                        this.loadingAll = false;
                    }
                },
                (error) => {
                    this.loadingAll = false;
                    console.log("Could not load todos.")
                }
            );
        } else {
            return false;
        }
    }

    getZoomOnStudy(object: any): any {
        this.studyZoomPredictive$.next(object);
    }

    addressPredictive(addressData: any) {
        this.addressPredictive$.next(addressData);
    }

    addressPredictiveAddMcd(addressData: any) {
        this.addressPredictiveAddMcd$.next(addressData);
    }

    coordsPredictive(coordsData: any) {
        this.coordsPredictive$.next(coordsData);
    }

    setCoordsPredictive(coords: any) {
        this.coordsPredictiveStudy = coords;
    }

    getCoordsPredictive() {
        return this.coordsPredictiveStudy;
    }

    studyMapPredictive(isPredictive: any) {
        this.studyMapPredictive$.next(isPredictive);
    }

    reloadDataNewForm(object: any) {
        this.studyParamsPredictive$.next(object);
    }

    reloadDataNewFormAddMcd(object: any) {
        this.studyParamsPredictiveAddMcd$.next(object);
    }

    bricksPredictive(data: any) {
        this.bricksPredictive$.next(data);
    }

    getBricksTransitPredictive() {
        return this.featuresSelected;
    }

    setBricksTransitPredictive(bricks: any): any {
        this.featuresSelected = bricks;
        this.bricksTransitPredictive$.next(this.featuresSelected);
    }

    competitorsPointPredictive(data: any) {
        this.competitorsPointPredictive$.next(data);
    }

    commercesPredictive(data: any) {
        this.commercesPredictive$.next(data);
    }

    mallPredictive(data: any) {
        this.mallPredictive$.next(data);
    }

    getAllStudies(): Observable<any> {
        return this.http.get(
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/studies/",
            {
                responseType: "json",
            }
        );
    }

    getBasicInfoStudy(studyId: any): Observable<any> {
        return this.http.get(
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/study/" +
            studyId +
            "/",
            {
                responseType: "json",
            }
        );
    }

    getCreateBasicInfoStudy(lng: any, lat: any, field: any): Observable<any> {
        return this.http.get(
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/create_study/" +
            lng +
            "," +
            lat +
            "/properties.info.kind_building=" +
            field +
            "/",
            {
                responseType: "json",
            }
        );
    }

    updatePresenceTrafficBrickStudy(study: any): Observable<any> {
        return this.http
            .put(
                environment.postmanHost +
                environment.postmanDelimitator +
                environment.postmanPort +
                environment.postmanContext +
                "/study/",
                study,
                {
                    headers: {"Content-Type": "application/json"},
                }
            )
            .pipe(catchError(this.handleError));
    }

    executeStudy(study: any): Observable<any> {
        return this.http.get(
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/study/execute/" +
            study +
            "/",
            {
                responseType: "json",
            }
        );
    }

    deleteStudy(study: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({"Content-Type": "application/json"}),
        };
        return this.http.get(
            environment.postmanHost +
            // ":" +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/remove_study/" +
            study._id +
            "/",
            {
                responseType: "json",
            }
        );
    }

    updateStudyPredictive(study: any, studyId: any): Observable<any> {
        return this.http.post(
            environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/study/" +
            studyId +
            "/",
            study
            ,
            {
                headers: {"Content-Type": "application/json"}
            }
        ).pipe(catchError(this.handleError));
    }

    getStudyMap(object: any): any {
        this.studyPredictive$.next(object);
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` + `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError("Something bad happened; please try again later.");
    }
}
