import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { GeoAddCommercesTableComponent } from "./geo-add-commerces-table.component";

import { AddDialogBoxCommerceComponent } from "./add-dialog-box-commerces/add-dialog-box-commerce.component";

@NgModule({
    declarations: [GeoAddCommercesTableComponent, AddDialogBoxCommerceComponent],
    imports: [SharedModule],
    exports: [GeoAddCommercesTableComponent],
    providers: []
})
export class GeoAddCommercesTableModule {}
