import { Component, OnInit, OnDestroy } from "@angular/core";

import { DataService } from "../../app-services/data.service";

import { GeoStudiesPrescriptiveDataService } from "../../app-services/geo-studies-prescriptive-data.service";
@Component({
  selector: "app-brick-map",
  templateUrl: "./brick-map.component.html",
  styleUrls: ["./brick-map.component.scss"]
})
export class BrickMapComponent implements OnInit, OnDestroy {
  showSidenavStartLeft: boolean = false;
  showSidenavEndRight: boolean = false;

  sideNavsPrescriptiveSub: any;
  isPrescriptive: boolean = true;
  isMassivePrescriptive: boolean = false;

  constructor(private dataService: DataService,
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService) {
    /*this.sideNavsPrescriptiveSub = this.dataService.sideNavsPrescStateChanged$.subscribe(
      target => {
        this.showSidenavStartLeft = target.SidenavStartLeftState;
        this.showSidenavEndRight = target.SidenavEndRightState;
      }
    );
    */
    this.sideNavsPrescriptiveSub = this.dataService.sideNavsStateChanged$.subscribe(
      target => {
        this.showSidenavStartLeft = target.SidenavStartLeftState;
        this.showSidenavEndRight = target.SidenavEndRightState;
        this.isPrescriptive = true;
        this.isMassivePrescriptive = false;
        this.dataService.getSelectedStudyPresc();
        
      }
    );
  }

  ngOnInit() {
    this.isPrescriptive = true;
    this.isMassivePrescriptive = false;
    this.geoStudiesPrescriptiveDataService.studyMapPrescriptive(this.isPrescriptive);
    this.geoStudiesPrescriptiveDataService.studyMapPrescriptiveMassive(this.isMassivePrescriptive);
  }
  ngOnDestroy() {
    this.isPrescriptive = false;
    this.isMassivePrescriptive = false;
    if (this.sideNavsPrescriptiveSub) {
      this.sideNavsPrescriptiveSub.unsubscribe();
    }
  }
}
