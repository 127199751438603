import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { Optional } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

// JSON for FORM ELEMENTS
import centroComercialForm from "../../../../../assets/data/centro_comercial_edit_form.json";

@Component({
  selector: "app-edit-dialog-commercial-center-box",
  templateUrl: "./edit-dialog-commercial-center-box.component.html",
  styleUrls: ["./edit-dialog-commercial-center-box.component.scss"]
})
export class EditDialogCommercialCenterBoxComponent implements OnInit {
  action: string;
  local_data: any;

  // removes name object from array

  formElements: Array<any> = centroComercialForm["values"];

  constructor(
    public dialogRef: MatDialogRef<EditDialogCommercialCenterBoxComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {
    if (this.local_data.model.sba_total < 0) {
    } else if (this.local_data.model.engine_surface < 0) {
    } else if (this.local_data.model.total_commerce < 0) {
    } else if (this.local_data.model.sba_power_supply < 0) {
    } else if (this.local_data.model.sba_fashion_complement < 0) {
    } else if (this.local_data.model.sba_home_brico_electronic < 0) {
    } else if (this.local_data.model.sba_services < 0) {
    } else if (this.local_data.model.sba_services < 0) {
    } else if (this.local_data.model.sba_various < 0) {
    } else if (this.local_data.model.sba_rest_leisure < 0) {
    } else {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancelar" });
  }

  ngOnInit() {}
}
