import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GeoStudiesPrescriptiveDataService } from "src/app/app-services/geo-studies-prescriptive-data.service";

export interface UserProfile {
  name: string;
  value: string;
}

@Component({
  selector: "app-edit-dialog-group-viewers",
  templateUrl: "./edit-dialog-group-viewers.component.html",
  styleUrls: ["./edit-dialog-group-viewers.component.scss"]
})
export class EditDialogGroupViewersComponent implements OnInit {
  constructor(
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService,
    public dialogRefEdit: MatDialogRef<EditDialogGroupViewersComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any
  ) {}

  userProfiles: UserProfile[] = [
    { name: "Avanzado", value: "advance" },
    { name: "Básico", value: "basic" }
  ];

  onNoClick(): void {
    this.dialogRefEdit.close();
  }


  userForm = new FormGroup({
    userProfile: new FormControl(""),

  });

  onSubmit(jsonStudy: any) {
  
   let data = {};
   data["properties"] = jsonStudy.properties;
   data["_id"] = jsonStudy._id;
   data["properties"].group =  this.userForm.value.userProfile;
   
    if(jsonStudy.properties.study.class =='study_massive'){
      this.geoStudiesPrescriptiveDataService
          .updateStudyGroupMassive(jsonStudy)
          .subscribe(
            response => {
              console.log("updateStudyGroupMassive Response is: ", response);
            },
            error => {
              console.error("updateStudyGroupMassive An error occurred, ", error);
            }
          );

    }else if(jsonStudy.properties.study.class=='prescriptive'){
      this.geoStudiesPrescriptiveDataService
      .updateStudyGroupPrescriptive(jsonStudy)
      .subscribe(
        response => {
          console.log("updateStudyGroupMassive Response is: ", response);
        },
        error => {
          console.error("updateStudyGroupMassive An error occurred, ", error);
        }
      );

    }

    
  }

  ngOnInit() {
    //set properties.group:
    console.log(this.message);
    this.userForm.patchValue({ 
      userProfile: this.message.properties.group,
    });

  }
}
