import {
  Component,
  OnChanges,
  ViewChild,
  OnDestroy,
  Input
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { DataService } from "src/app/app-services/data.service";

export interface Comercio {
  nombre: string;
  calle: string;
  num: number;
  localidad: string;
  province: string;
  cp: number;
  properties: {
    info: {
      name: string;
      category: string;
      id_brand: string;
      brand: string;
    };
  };
}
@Component({
  selector: "app-tab-centros-comerciales",
  templateUrl: "./tab-centros-comerciales.component.html",
  styleUrls: ["./tab-centros-comerciales.component.scss"]
})
export class TabCentrosComercialesComponent implements OnChanges, OnDestroy {
  checked = false;
  displayedColumns: string[] = ["name"];
  comercios: Comercio[] = [];
  selected_brand: any;
  @Input()
  loadMalls: any;
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ensenyas = new FormControl();
  ensenyasList: string[] = ["Todos"];
  sampleCategories: Array<any>;
  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;
  restaurants: any;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mallSelected(elementId: string, geometry: string) {
    this.dataService.getZoomOnRestaurant(geometry);
  }

  selectAll(ev) {
    if (ev.checked) {
      this.dataService.getMalls().subscribe((result: any) => {
        this.restaurants = result;
        this.restaurants = this.restaurants.map(element => {
          element.properties.info.id = element.properties.info.id_brand;
          element.properties.info.geometry = element.geometry;
          return element.properties.info;
        });
        this.dataSource = new MatTableDataSource(this.restaurants);
        this.dataService.getFilteredMalls(this.dataSource.filteredData);
        this.dataSource.paginator = this.paginator;
      });
    }
    if (ev.checked == false) {
      this.dataSource = new MatTableDataSource();
      this.dataService.getFilteredMalls(this.dataSource.filteredData);
    }
  }

  constructor(private dataService: DataService) {}

  ngOnChanges() {
    if (this.checked) {
      this.unsubscribe3 = this.dataService
        .getMalls()
        .subscribe((result: any) => {
          this.restaurants = result;
          this.restaurants = this.restaurants.map(element => {
            element.properties.info.id = element.properties.info.id_brand;
            element.properties.info.geometry = element.geometry;
            return element.properties.info;
          });
          this.dataSource = new MatTableDataSource(this.restaurants);
          this.dataService.getFilteredMalls(this.dataSource.filteredData);
          this.dataSource.paginator = this.paginator;
        });
    } else {
      this.dataSource = new MatTableDataSource();
      this.dataService.getFilteredMalls(this.dataSource.filteredData);
      this.dataSource.paginator = this.paginator;
    }

    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
  }
}
