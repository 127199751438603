<footer class="clearfix primary-color-bkg">
  <div class="section-container">
    <div class="footer">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-xs center-sm end-md start-lg">
          <p>
            Copyright © McDonald's España {{year_date}}.
            <span class="footer-logo mcd" onclick="location='http://www.mcdonalds.es'"></span>
          </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 center-xs center-sm end-md end-lg">
          <p>
            Powered by
            <span class="footer-logo nommon" onclick="location='http://www.nommon.es'"></span>
            &
            <span class="footer-logo abacus" onclick="location='http://abacus-consulting.net'"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>