import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthenticationService } from "../app-services/authentication.service";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: 'root' })
export class BrickReportService {

    private devUrl = "../../assets/data/prescriptive/prescriptive_test.json";
    private proUrl = "here_your_production_call_to_data";
    public brickReport$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    selectedStudyReportPresc: any ="";
    private selectedStudyReportPrescPrescriptive$ = new Subject<any>();
    selectedStudyReportPrescPrescriptiveChanged$ = this.selectedStudyReportPrescPrescriptive$.asObservable();
    private headers: HttpHeaders = new HttpHeaders();

   
    private reportPrescriptive$ = new Subject<any>();
    reportPrescriptiveChanged$ = this.reportPrescriptive$.asObservable();

    

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) { }

    handleError(error: HttpErrorResponse) {
        const kk = null;
        return of(kk);
    }

    filterStudyImpact(impact_info) {
        return impact_info.filter(restaurant => restaurant.total_site_impact && restaurant.total_site_impact > 2);
    }

    sortBricks(study) {
        return study.properties.bricks.sort((a, b) => b.properties.sales_info.first_year_sales - a.properties.sales_info.first_year_sales);
    }

    sortBricksS(study) {
        return study.properties.bricks.sort((a, b) => b.sales_info.first_year_sales - a.sales_info.first_year_sales);
    }

    setProvinceName(study) {
        return study['province_name'] = this.getProvinceName(study.properties.study_zone_id);
    }

    mapImpactInfo(study) {

        return study.properties.bricks.map((brick) => {
            if (!brick.properties.impact_info) { brick.properties["impact_info"] = [] }
            brick.properties.impact_info = this.filterStudyImpact(brick.properties.impact_info);
            // add at least one impact_info dummy for table !important
            if (brick.properties.impact_info.length <= 0) {
                brick.properties["impact_info"] = [
                    { total_site_impact: "", delivery_impact: "", site_name: "", site_id: "" }];
            }
            return brick;
        });
    }

    setStudyProperties(study) {
        this.sortBricks(study);
        this.mapImpactInfo(study);
        this.setProvinceName(study)
        return study;
    }

    // main call
    getAll(studyData: any) {
        this.brickReport$.next(undefined);
        this.setHeaders();
        return this.http
            //.get(`${this.devUrl}`)
            .get(
                environment.postmanHost +
                  environment.postmanDelimitator +
                  environment.postmanPort +
                  environment.postmanContext +
                  "/prescriptive/" +
                  studyData +
                  "/",
                {
                  responseType: "json",
                  headers: this.headers
                }
              )
            .pipe(
                catchError(this.handleError),
                map((data: any) => [data].map((study) => {
                    return this.setStudyProperties(study);
                }))
            )
            .subscribe((mappedBricks: any) => {
                this.brickReport$.next(mappedBricks);
            });
    }

    public getBrickReport() {
        return this.brickReport$.asObservable();
    }
    public setBrickReport(value: any): void {
        this.brickReport$.next(value);
    }

    // helpers
    public provinces = [
    { "id": "ES", "name": "España"},
    { "id": "02", "name": "Albacete" },
    { "id": "03", "name": "Alicante" },
    { "id": "04", "name": "Almería" },
    { "id": "01", "name": "Araba" },
    { "id": "33", "name": "Asturias" },
    { "id": "05", "name": "Ávila" },
    { "id": "06", "name": "Badajoz" },
    { "id": "07", "name": "Illes Balears " },
    { "id": "08", "name": "Barcelona" },
    { "id": "48", "name": "Bizkaia" },
    { "id": "09", "name": "Burgos" },
    { "id": "10", "name": "Cáceres" },
    { "id": "11", "name": "Cádiz" },
    { "id": "39", "name": "Cantabria" },
    { "id": "12", "name": "Castellón" },
    { "id": "13", "name": "Ciudad Real" },
    { "id": "14", "name": "Córdoba" },
    { "id": "15", "name": "A Coruña" },
    { "id": "16", "name": "Cuenca" },
    { "id": "20", "name": "Gipuzkoa" },
    { "id": "17", "name": "Girona" },
    { "id": "18", "name": "Granada" },
    { "id": "19", "name": "Guadalajara" },
    { "id": "21", "name": "Huelva" },
    { "id": "22", "name": "Huesca" },
    { "id": "23", "name": "Jaén" },
    { "id": "24", "name": "León" },
    { "id": "25", "name": "Lleida" },
    { "id": "27", "name": "Lugo" },
    { "id": "28", "name": "Madrid" },
    { "id": "29", "name": "Málaga" },
    { "id": "30", "name": "Murcia" },
    { "id": "31", "name": "Navarra" },
    { "id": "32", "name": "Ourense" },
    { "id": "34", "name": "Palencia" },
    { "id": "35", "name": "Las Palmas" },
    { "id": "36", "name": "Pontevedra" },
    { "id": "26", "name": "La Rioja" },
    { "id": "37", "name": "Salamanca" },
    { "id": "38", "name": "Santa Cruz de Tenerife" },
    { "id": "40", "name": "Segovia" },
    { "id": "41", "name": "Sevilla" },
    { "id": "42", "name": "Soria" },
    { "id": "43", "name": "Tarragona" },
    { "id": "44", "name": "Teruel" },
    { "id": "45", "name": "Toledo" },
    { "id": "46", "name": "Valencia" },
    { "id": "47", "name": "Valladolid" },
    { "id": "49", "name": "Zamora" },
    { "id": "50", "name": "Zaragoza" },
    { "id": "51", "name": "Ceuta" },
    { "id": "52", "name": "Melilla" }];

    public getProvinceName(id) {
        return this.provinces.filter(d => d.id === id)[0].name;
    }

    getSelectedStudyReportPresc(): any {
        return this.selectedStudyReportPresc;
    }

    setSelectedStudyReportPresc(idStudy: any): any {
        this.selectedStudyReportPresc = idStudy;
        this.selectedStudyReportPrescPrescriptive$.next(idStudy);
    }

    setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');
        const currentUser = this.authenticationService.currentUserValue;
        const token = `Basic ${currentUser.token}`;
        if (token !== '') {
            const tokenValue =  `Basic ${currentUser.token}`;
            this.headers = this.headers.set('Authorization', tokenValue);
        }
    }

    getReportPrescriptive(object: any): any {
        this.reportPrescriptive$.next(object);
    }

}
