import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MassiveBricksNewFormComponent } from "./massive-bricks-new-form.component";

const routes: Routes = [
  {
    path: "massive-prescriptive/new-form",
    component: MassiveBricksNewFormComponent,
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MassiveBricksNewFormRoutingModule {
  static components = [MassiveBricksNewFormComponent];
}
