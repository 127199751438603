import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "../../../app-services/data.service";

@Component({
  selector: "app-sidenav-start-left",
  templateUrl: "./sidenav-start-left.component.html",
  styleUrls: ["./sidenav-start-left.component.scss"]
})
export class SidenavStartLeftComponent implements OnInit {
  @Input()
  showSidenavStartLeft;

  year_date =  (new Date()).getFullYear();
  
  constructor(private dataService: DataService) {}

  triggerToggleSideNavs: any = () => {
    this.dataService.serviceToggleSideNavs({
      SidenavStartLeftState: false,
      SidenavEndRightState: false
    });
  };

  ngOnInit() {}
}
