
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";

import { AddDialogBoxCommercesLocatorComponent } from "./add-dialog-box-commerces-locator.component";

import { McdGeoLocatorMapModule } from "../../../../../app-widgets/cartography/mcdGeoLocatorMap/mcdGeoLocatorMap.module";
import { SearchboxModule } from "../../../../../app-widgets/form-controls/searchbox/searchbox.module";


@NgModule({
  declarations: [AddDialogBoxCommercesLocatorComponent ],
  imports: [SharedModule, McdGeoLocatorMapModule,SearchboxModule],
  exports: [AddDialogBoxCommercesLocatorComponent],
  providers: []
})
export class AddDialogBoxCommercesLocatorModule {}

