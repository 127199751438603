import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";

import { BrickMapRoutingModule } from "./brick-map-routing.module";

import { McdGeoMakerModule } from "../../app-widgets/cartography/mcdGeoMaker/mcdGeoMaker.module";
import { SearchboxModule } from "../../app-widgets/form-controls/searchbox/searchbox.module";
import { DummyMarkerModule } from "../../app-widgets/buttons/dummy-marker/dummy-marker.module";

import { SidenavStartLeftModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-start-left.module";
import { SidenavBricksModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-bricks/sidenav-bricks.module";


import { SidenavEndRightModule } from "../../app-widgets/navigation/sidenav-end-right/sidenav-end-right.module";
import { UlSitesModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-restaurants/ul-sites/ul-sites.module";
import { SidenavDataModule } from "../../descriptive-model/sidenav-data/sidenav-data.module";

import { McdGeoLocatorMapModule } from "../../app-widgets/cartography/mcdGeoLocatorMap/mcdGeoLocatorMap.module";
@NgModule({
  declarations: [BrickMapRoutingModule.components],
  imports: [
    SharedModule,
    BrickMapRoutingModule,
    McdGeoMakerModule,
    SearchboxModule,
    DummyMarkerModule,
    SidenavStartLeftModule,
    SidenavBricksModule,
    UlSitesModule,
    SidenavEndRightModule,
    SidenavDataModule,
    McdGeoLocatorMapModule
  ],
  exports: [],
  providers: []
})
export class BrickMapModule {}
