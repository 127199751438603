import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { GeoEditCommercialCenterTableComponent } from "./geo-edit-commercial-center-table.component";

@NgModule({
    declarations: [GeoEditCommercialCenterTableComponent],
    imports: [SharedModule],
    exports: [GeoEditCommercialCenterTableComponent],
    providers: []
})
export class GeoEditCommercialCenterTableModule {}
