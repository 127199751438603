import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { OnChanges } from "@angular/core";

import { DataService } from "../../../app-services/data.service";
import { ParamsDataService } from "../../../app-services/d3/params-data.service";

@Component({
  selector: "app-data-trafico",
  templateUrl: "./data-trafico.component.html",
  styleUrls: ["./data-trafico.component.scss"]
})
export class DataTraficoComponent implements OnInit, OnDestroy {
  traffic_segmentation_data: Array<any>;
  //traffic_segmentation: Array<any>;
  traffic_segmentation_data_ready: boolean = false;

  // traffic RESIDENCE SELECTORS
  traffic_residence_data: Array<any>;
  traffic_residence_data_ready: boolean = false;

  traffic_loading: boolean = true;
  unsubscribe: any;
  unsubscribe2: any;

  constructor(
    private dataService: DataService,
    private paramsDataService: ParamsDataService
  ) { }

  ngOnInit() {
    this.unsubscribe = this.dataService
      .getMCDfromID(this.dataService.selectedRestaurant)
      .subscribe(residMCD => {
        this.unsubscribe2 = this.dataService
          .getTransitIdMcd(residMCD["properties"]["id_mcd"])
          .subscribe(d => {
            this.traffic_segmentation_data = this.paramsDataService.getDataParams(
              d,
              "traffic_segmentation"
            );

            // traffic RESIDENCE
            // Filter data for  Residence overall with Spain data and abroad data
            this.traffic_residence_data = this.paramsDataService.getDataParams(
              d,
              "traffic_residence"
            );
            this.traffic_loading = false;
            this.traffic_segmentation_data_ready = true;
            this.traffic_residence_data_ready = true;

          });
      });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
