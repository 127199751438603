<div class="sidebar--inner">
  <ul class="vertical-ul sites" id="sidenavRestaurantsLinks">
    <li
      (click)="toggleRestaurantsSideNav($event, 'restaurantes')"
      class="sidenavRestaurantLink active"
      data-tab="restaurants"
    >
      <span class="icon icon-mcdonalds"></span>Restaurantes McDONALD’s
    </li>
    <li
      class="sidenavRestaurantLink"
      [ngClass]="{ 'gray-disabled': competenciaLink }"
      (click)="toggleRestaurantsSideNav($event, 'competencia')"
      data-tab="competencia"
    >
      <span class="icon icon-competencia"></span>Competencia
      <span class="tooltiptext" [ngClass]="{ 'gray-disabled': competenciaLink }"
        >Haga zoom en el mapa para activar esta opción</span
      >
    </li>

    <li
      class="sidenavRestaurantLink"
      (click)="toggleRestaurantsSideNav($event, 'comercios')"
      data-tab="comercios"
    >
      <span class="icon icon-comercios"></span>Comercios
    </li>
    <li
      class="sidenavRestaurantLink"
      (click)="toggleRestaurantsSideNav($event, 'malls')"
      data-tab="malls"
    >
      <span class="icon icon-cc"></span>Centros comerciales
    </li>

    <li
      *ngIf="router == '/predictive/map'"
      class="sidenavRestaurantLink"
      (click)="toggleRestaurantsSideNav($event, 'estudios')"
      data-tab="estudios"
    >
      <span class="icon icon-estudios"></span>Estudios
    </li>
    <li
      *ngIf="router == '/prescriptive/map'" 
      class="sidenavRestaurantLink"
      (click)="toggleRestaurantsSideNav($event, 'estudios')"
      data-tab="estudios_presc"
    >
      <span class="icon icon-estudios"></span>Estudios
    </li>

     <li
      *ngIf="router == '/predictive/map'"
      class="sidenavRestaurantLink"
      (click)="toggleRestaurantsSideNav($event, 'bricks')"
      data-tab="bricks"
    >
      <span class="icon icon-bricks"></span>Buscar brick
    </li> 
  </ul>
  <div class="tabs-container" id="sidenavRestaurantsTabs">
    <div class="tab display" data-tab="restaurants">
      <app-tab-restaurants></app-tab-restaurants>
    </div>
    <div class="tab" data-tab="competencia">
      <app-tab-competencia
        [brandListZoom]="brandListZoom"
        [ngClass]="{ 'comp-disabled': competenciaLink }"
      ></app-tab-competencia>
    </div>
    <div class="tab" data-tab="comercios">
      <app-tab-comercios></app-tab-comercios>
    </div>
    <div class="tab" data-tab="malls">
      <app-tab-centros-comerciales
        [loadMalls]="loadMalls"
      ></app-tab-centros-comerciales>
    </div>
    <div class="tab" data-tab="estudios">
      <app-tab-studies></app-tab-studies>
    </div>
    <div class="tab" data-tab="estudios_presc">
      <app-tab-studies-presc></app-tab-studies-presc>
    </div>

    <div class="tab" data-tab="bricks">
      <app-tab-bricks></app-tab-bricks>
    </div>
  </div>
</div>
