import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "../../app-services/data.service";

@Component({
  selector: "app-restaurant-map",
  templateUrl: "./restaurant-map.component.html",
  styleUrls: ["./restaurant-map.component.scss"]
})
export class RestaurantMapComponent implements OnInit, OnDestroy {
  showSidenavStartLeft: boolean = false;
  showSidenavEndRight: boolean = false;
  unsubscribe: any;
  sideNavsDescriptiveSub: any;
  constructor(private dataService: DataService) {
    this.sideNavsDescriptiveSub = this.dataService.sideNavsStateChanged$.subscribe(
      target => {
        this.showSidenavStartLeft = target.SidenavStartLeftState;
        this.showSidenavEndRight = target.SidenavEndRightState;
        this.dataService.getSelectedRestaurant();
        this.dataService.showInfluenceRestaurant(false, this.dataService.getSelectedRestaurant());
      }
    );
  }

  ngOnInit() {}
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
