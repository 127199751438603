import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";

import { RestaurantMapRoutingModule } from "./restaurant-map-routing.module";

import { McdGeoMakerModule } from "../../app-widgets/cartography/mcdGeoMaker/mcdGeoMaker.module";
import { SearchboxModule } from "../../app-widgets/form-controls/searchbox/searchbox.module";

import { SidenavEndRightModule } from "../../app-widgets/navigation/sidenav-end-right/sidenav-end-right.module";
import { SidenavDataModule } from "../sidenav-data/sidenav-data.module";

import { SidenavStartLeftModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-start-left.module";
import { UlSitesModule } from "../../app-widgets/navigation/sidenav-start-left/sidenav-restaurants/ul-sites/ul-sites.module";
import { DummyMarkerModule } from "../../app-widgets/buttons/dummy-marker/dummy-marker.module";

@NgModule({
  imports: [
    SharedModule,
    RestaurantMapRoutingModule,
    SearchboxModule,
    McdGeoMakerModule,
    SidenavStartLeftModule,
    UlSitesModule,
    SidenavEndRightModule,
    SidenavDataModule,
    DummyMarkerModule
  ],
  declarations: [RestaurantMapRoutingModule.components],
  exports: [RestaurantMapRoutingModule.components],

  providers: []
})
export class RestaurantMapModule {}
