import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabMassiveExpansionComponent } from "./tab-massive-expansion.component";
import { ReactiveFormRangeSliderModule } from "../../../../form-controls/reactive-form-range-slider/reactive-form-range-slider.module";

@NgModule({
  declarations: [TabMassiveExpansionComponent],
  imports: [SharedModule, ReactiveFormRangeSliderModule],
  exports: [TabMassiveExpansionComponent],
  providers: []
})
export class TabMassiveExpansionModule {}
