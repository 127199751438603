import { Component, OnInit } from "@angular/core";

import { DataService } from "../../../../app-services/data.service";

@Component({
  selector: "app-hamburger-restaurants",
  templateUrl: "./hamburger-restaurants.component.html",
  styleUrls: ["./hamburger-restaurants.component.scss"]
})
export class HamburgerRestaurantsComponent implements OnInit {
  constructor(private dataService: DataService) {}

  ngOnInit() {}

  triggerToggleSideNavs: any = () => {
    this.dataService.serviceToggleSideNavs({
      SidenavStartLeftState: true,
      SidenavEndRightState: false
    });
  };
}
