<!-- app.component.html -->
<div class="container text-center">
  <button
    mat-button
    (click)="
      $event.stopPropagation();
      $event.preventDefault();
      openDialog('Añadir', {})
    "
    mat-flat-button
    color="primary"
  >
    Añadir comercio
  </button>
  <div class="margin-top--s"></div>

  <!-- <table
    mat-table
    [dataSource]="dataSource"
    #removetable
    class="remove-table mat-elevation-z0"
  > -->

  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    #removetable
    class="mat-elevation-z0 competitors"
  >
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- nombre Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Categoría</th>
      <td mat-cell *matCellDef="let element">{{ element.category }}</td>
    </ng-container>

    <!-- nombre_local Column -->
    <ng-container matColumnDef="commerce">
      <th mat-header-cell *matHeaderCellDef>Comercio</th>
      <td mat-cell *matCellDef="let element">{{ element.commerce }}</td>
    </ng-container>

     <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Dirección</th>
      <td mat-cell *matCellDef="let element">{{ element.address_view }}</td>
    </ng-container>

    <!--
    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef>Área andando</th>
      <td mat-cell *matCellDef="let element">
        {{ element.distance }}
      </td>
    </ng-container>
    -->

    <!-- dist_metros_mcd_cercano Column -->
    <!--
    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef>Cantidad</th>
      <td mat-cell *matCellDef="let element">
        {{ element.num }}
      </td>
    </ng-container>
  -->

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Acción</th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <a (click)="openDialog('Editar', element)">Editar</a> |
        <a (click)="openDialog('Eliminar', element)">Eliminar</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
