import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-brick-report-header',
  templateUrl: './brick-report-header.component.html',
  styleUrls: ['./brick-report-header.component.scss']
})
export class BrickReportHeaderComponent implements OnInit {
  @Input() setup: any;
  constructor() { }

  ngOnInit(): void { }

}
