import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { BrickStudiesComponent } from "./brick-studies.component";

const routes: Routes = [
  {
    path: "prescriptive/studies",
    component: BrickStudiesComponent,
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrickStudiesRoutingModule {
  static components = [BrickStudiesComponent];
}
