import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "src/app/app-services/data.service";
import { GeoStudiesPrescriptiveDataService } from "src/app/app-services/geo-studies-prescriptive-data.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
@Component({
  selector: "app-li-massive-bricks",
  templateUrl: "./li-massive-bricks.component.html",
  styleUrls: ["./li-massive-bricks.component.scss"]
})
export class LiMassiveBricksComponent implements OnInit {
  readyExpansion: boolean = true;
  readyBuscador: boolean = false;
  readyTematicos: boolean = false;

  ready: boolean = false;

  unsubscribe: any;
  unsubscribe2: any;
  studyDataVoid: any;
  studyData:any;

  displayedColumns: string[] = ["properties.ID", "properties.building_type"];
  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private dataService: DataService,
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService) {

      this.dataService.filterBricksPrescriptiveChanged$.subscribe(
        filterValue => {
          this.dataSource.filter = filterValue;
          this.geoStudiesPrescriptiveDataService.getFilteredBricksStudy(this.dataSource.filteredData);
        });
    }

  changeDataTab = e => {
    this.readyExpansion = e.index == 1 ? true : false;
    this.readyBuscador = e.index == 2 ? true : false;
    this.readyTematicos = e.index == 3 ? true : false;
  };


  clickBrick = e =>{
    this.geoStudiesPrescriptiveDataService.setBrickDataPrescriptive(e);
    this.geoStudiesPrescriptiveDataService.getBrickMap(e);

  }

  applyFilter(filterValue: any) {
    if(!Array.isArray(filterValue)){
      if(filterValue!=""){

      

    
    this.dataSource.filterPredicate = (data: any, filter: any) => {
      var rgularExp = {
        contains_alphaNumeric : /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/,
        containsNumber : /\d+/,
        containsAlphabet : /[a-zA-Z]/,

        onlyLetters : /^[A-Za-z]+$/,
        onlyNumbers : /^[0-9]+$/,
        onlyMixOfAlphaNumeric : /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/
    }

    if(rgularExp.contains_alphaNumeric.test(filterValue) ){

      if( rgularExp.containsAlphabet.test(filterValue) ){
        return (
          //data.properties.building_type.toString().toLowerCase().includes( filter.toLowerCase())
          data.properties.sales_info.perc_of_sales_associated_to_existing_rest.toString().toLowerCase().includes( filter.toLowerCase())
          ||
          data.properties.ID.toString().toLowerCase().includes( filter.toLowerCase()) 
      );
      }else{
        return (
         // data.properties.brick_id.toString().includes( filter) 
         //data.properties.ID.toString().includes( filter)
         data.properties.sales_info.perc_of_sales_associated_to_existing_rest.toString().includes(filter)
      );
      }
    }else{
      return (
        //data.properties.building_type.toString().toLowerCase().includes( filter.toLowerCase()) 
        data.properties.sales_info.perc_of_sales_associated_to_existing_rest.toString().toLowerCase().includes( filter.toLowerCase())
        ||
        data.properties.ID.toString().toLowerCase().includes( filter.toLowerCase()) 
    );
    }
      

      
    };  
    //this.geoStudiesPrescriptiveDataService.getFilteredBricksStudy(this.dataSource.filteredData);
  }else{
    
  }
  }
    this.dataSource.filter = filterValue;
    this.geoStudiesPrescriptiveDataService.getFilteredBricksStudy(this.dataSource.filteredData);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource.paginator = this.paginator; 
  }

      
  ngOnInit() {
    this.ready = false;
    this.unsubscribe = this.dataService.studySelectedPrescriptiveMassiveChanged$.subscribe(
      id => {
        this.studyData = this.studyDataVoid;
        if (id !== undefined) {
          this.unsubscribe2 = this.geoStudiesPrescriptiveDataService
            .getBasicInfoStudyMassive(id)
            .subscribe(d => {
              d["top_bricks"] = [];
              // reasignar la nueva localización del os bricks de properties, para que siga funcionando con el mapeo antiguo
              d.bricks = d.properties.bricks;
              let contador = 0;

              if(d.bricks){
                  d.bricks.forEach(e => {
                  contador++;
                  let data_brick = {};
                  if(e.properties){
                    data_brick["id_brick"] = e.properties.brick_id;
                    data_brick["ID"] = e.properties.ID;
                    data_brick["id_brick_vta"] =  Math.round(e.properties.sales_info.first_year_sales);
                    data_brick["impact_info"] = e.properties.impact_info;
                    data_brick["sales_info"] = e.properties.sales_info;
                  }else{
                    e.properties = e;
                    data_brick["id_brick"] = e.brick_id;
                    data_brick["ID"] = e.ID;
                    data_brick["id_brick_vta"] =  Math.round(e.sales_info.first_year_sales);
                    data_brick["impact_info"] = e.impact_info;
                    data_brick["sales_info"] = e.sales_info;
                  }
                  
                  if(contador<6){
                    d["top_bricks"].push(data_brick);
                  }
                  return d["top_bricks"]; 
                });
              }

              this.studyData = d;
              if(this.studyData.bricks){
                this.ready = true;
              }
              this.dataSource = new MatTableDataSource(this.studyData.bricks);

              this.dataSource.filterPredicate = (data: any, filter: any) => {
                return (
                    (data.properties.sales_info.first_year_sales >= filter[0]) && (data.properties.sales_info.first_year_sales <= filter[1])
                );
              };
              if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
              }
              this.dataSource.paginator = this.paginator;             
            });
            if(this.dataSource){
              this.dataSource.paginator = this.paginator;
            }  
        }
          
      }
    );

  }

  ngOnChanges() {
    this.ready = false;
    this.unsubscribe = this.dataService.studySelectedPrescriptiveMassiveChanged$.subscribe(
      id => {
        this.studyData = this.studyDataVoid;
        if (id !== undefined) {
          this.unsubscribe2 = this.geoStudiesPrescriptiveDataService
            .getBasicInfoStudyMassive(id)
            .subscribe(d => {             
              //this.ready = true;
                d["top_bricks"] = [];
                var brick = d.bricks;
                let contador = 0;
                if(brick){
                  brick.forEach(e => {
                    contador++;
                    let data_brick = {};
                    if(e.properties){
                      data_brick["id_brick"] = e.properties.brick_id;
                      data_brick["ID"] = e.properties.ID;
                      data_brick["id_brick_vta"] = Math.round(e.properties.sales_info.first_year_sales);
                      data_brick["impact_info"] = e.properties.impact_info
                      data_brick["sales_info"] = e.properties.sales_info
                    }else{
                      e.properties = e;
                      data_brick["id_brick"] = e.brick_id;
                      data_brick["ID"] = e.ID;
                      data_brick["id_brick_vta"] = Math.round(e.sales_info.first_year_sales);
                      data_brick["impact_info"] = e.impact_info
                      data_brick["sales_info"] = e.sales_info                      
                    }
                    
                    if(contador<6){
                      d["top_bricks"].push(data_brick);
                    }              
                    return d["top_bricks"]; 
                  });
                }
                
              this.studyData = d;
              if(this.studyData.bricks){
                this.ready = true;
              }
              this.dataSource = new MatTableDataSource(this.studyData.bricks);

              this.dataSource.filterPredicate = (data: any, filter: any) => {
                return (
                    (data.properties.sales_info.first_year_sales >= filter[0]) && (data.properties.sales_info.first_year_sales <= filter[1])
                );
              };

              this.dataSource.paginator = this.paginator;
             
            });
            if(this.dataSource){
              this.dataSource.paginator = this.paginator;
            }
        }
       
      }
    );

  }

}
