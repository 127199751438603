// https://code-maze.com/angular-material-navigation/
// https://github.com/CodeMazeBlog/angular-material-series/tree/navigation

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";
import { AuthenticationService } from "src/app/app-services/authentication.service";
@Component({
  selector: "app-menu-screen",
  templateUrl: "./menu-screen.component.html",
  styleUrls: ["./menu-screen.component.scss"]
})
export class MenuScreenComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  isPredictive: boolean = true;
  userAuthorized: boolean = false;
  constructor(
    private geoStudiesDataService: GeoStudiesDataService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    //get user
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      if (user.group != "advance") {
        this.userAuthorized = false;
      } else {
        this.userAuthorized = true;
      }
    }
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  public onStudiesMap = () => {
    this.isPredictive = true;
    this.geoStudiesDataService.studyMapPredictive(this.isPredictive);
  };
}
