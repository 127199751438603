<div class="full-width clearfix custom-slider margin-top--s">
 
 
    <div  *ngIf="studyData">
    <h2 class="tab-headline">INFORMACIÓN BÁSICA</h2>
      <ul role="list">
        <li class="margin-top--xs" role="listitem">
          <div class="mat-icon--icon"><mat-icon>turned_in</mat-icon></div>
          <label class="mat-icon--label">{{
            studyData.properties.study?.study_id
          }}</label>
        </li>

        <li class="margin-top--xs" role="listitem">
          <div class="mat-icon--icon">
            <mat-icon>assignment</mat-icon>
          </div>
          <label class="mat-icon--label">{{
            studyData.properties.study_name
          }}</label>         
        </li>        

        <li class="margin-top--xs" role="listitem">
          <div class="mat-icon--icon">
            <mat-icon>store_mall_directory</mat-icon>
          </div>
          <label class="mat-icon--label">{{
            selectedOrder
          }}</label>
        </li>

        <li class="margin-top--xs" role="listitem">
          <div class="mat-icon--icon">
            <mat-icon>verified_user</mat-icon>
          </div>
          <label class="mat-icon--label">{{
            studyData.properties.study?.state_description
          }}</label>
        </li>
      </ul>
      <div class="margin-top--m"></div>
    </div>
    <form *ngIf="studyData">
    <h2 class="tab-headline">
      POTENCIAL DE VENTAS
    </h2>
    <app-reactive-form-range-slider [studyData]="studyData"
      style="width: 100%;"
      [params]="expansion_range_slider"
      widget_num="1"
      [emitFirst]="true"
      (eventEmitter)="change_expansion_range($event)"
    ></app-reactive-form-range-slider>
</form>
</div>
