<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">PRESENCIA MEDIA DIARIA POR RESIDENCIA</h2>
      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="presence_residence_selectors" widget_num="0"
        [emitFirst]="true" (eventEmitter)="changeMode($event)" *ngIf="presence_selectors_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="presence_residence_selectors"
        [childrenOptions]="areaOptions" widget_num="0" [emitFirst]="true" (eventEmitter)="changeArea($event)"
        *ngIf="presence_selectors_ready">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="presence_residence_selectors" widget_num="3"
        [emitFirst]="true" (eventEmitter)="changePeriod($event)" *ngIf="presence_selectors_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="presence_residence_selectors" widget_num="4"
        [emitFirst]="true" (eventEmitter)="changeMonth($event)" *ngIf="presence_selectors_ready && displayMonths">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="presence_residence_selectors"
        [childrenOptions]="dayOptions" widget_num="1" [emitFirst]="true" (eventEmitter)="changeDay($event)"
        *ngIf="presence_selectors_ready && displayMonths && displayDays">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"></div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
      <h2 class="tab-headline">Residentes</h2>
      <div class="full-width clearfix">
        <app-stacked-bar style="width: 100%;" [params]="presence_residence_total" chartId="presence_residence_total"
          chartSize="xs" [firstFilterValue]="selectedMode" [secondFilterValue]="selectedArea"
          [thirdFilterValue]="selectedPeriod" [fourFilterValue]="selectedMonth" [fifthFilterValue]="selectedDay"
          *ngIf="presence_residence_ready"></app-stacked-bar>
      </div>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
      <h2 class="tab-headline">Nacionales</h2>
      <div class="full-width clearfix">
        <app-treemap style="width: 100%;" [params]="presence_residence_spain" [firstFilterValue]="selectedMode"
          [secondFilterValue]="selectedArea" [thirdFilterValue]="selectedPeriod" [fourFilterValue]="selectedMonth"
          [fifthFilterValue]="selectedDay" chartId="presence_residence_spain" chartSize="regular"
          *ngIf="presence_residence_ready"></app-treemap>
      </div>
      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
      <!--       
      <h2 class="tab-headline">Extranjeros</h2>
      <div class="full-width clearfix">
        <app-treemap style="width: 100%;" [params]="presence_residence_abroad" [firstFilterValue]="selectedMode"
          [secondFilterValue]="selectedArea" [thirdFilterValue]="selectedPeriod" [fourFilterValue]="selectedMonth"
          [fifthFilterValue]="selectedDay" chartId="presence_residence_abroad" chartSize="regular"
          *ngIf="presence_residence_ready"></app-treemap>
      </div>
    -->
    </div>
    <div class="margin-top--s"></div>

  </div>
</section>