import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../app-services/data.service";

@Component({
  selector: "app-dummy-marker",
  templateUrl: "./dummy-marker.component.html",
  styleUrls: ["./dummy-marker.component.scss"]
})
export class DummyMarkerComponent implements OnInit {
  constructor(private dataService: DataService) {}

  ngOnInit() {}

  triggerToggleSideNavs: any = () => {
    this.dataService.getSelectedStudyPresc();
    
    this.dataService.serviceToggleSideNavs({
      SidenavStartLeftState: false,
      SidenavEndRightState: true
    });
  };
}
