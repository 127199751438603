import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { FormControl } from "@angular/forms";

import { SelectWidgetService } from "../../../app-services/d3/select-widget.service";
import { D3MainService } from "../../../app-services/d3/d3-main.service";

@Component({
  selector: "app-options-from-json",
  templateUrl: "./options-from-json.component.html",
  styleUrls: ["./options-from-json.component.scss"]
})
export class OptionsFromJsonComponent implements OnInit, OnChanges {
  @Input()
  params: any;
  @Input()
  widget_num: number;

  @Input()
  emitFirst: boolean;
  @Output()
  eventEmitter = new EventEmitter<number>();

  formControl = new FormControl();
  pickerOptions: any;
  pickerOptionsExists: boolean;
  selectorPlaceholder: string;
  isMultiple: boolean;
  isParent: boolean;

  // service
  getRealName: any;

  // hide or show selector based on the display property of the selector
  isVisible: boolean = true;

  constructor(
    private d3MainService: D3MainService,
    private selectWidgetService: SelectWidgetService
  ) { }

  setSelector(): void {
    const controls = this.params.selectors;
    const thisSelector = controls.find(
      c =>
        c.widget == "options-from-json" &&
        parseInt(c.widget_num, 10) == +this.widget_num
    );

    const indexOfSelect = controls.findIndex(
      c => c.widget == "options-from-json" && c.widget_num == +this.widget_num
    );

    if (!thisSelector.enabled) {
      this.formControl.disable();
    }
    if (!thisSelector.display) {
      this.isVisible = false;
    }


    this.selectorPlaceholder = thisSelector.placeholder;
    this.pickerOptions = thisSelector.options;
    this.pickerOptionsExists = !!this.pickerOptions;
    this.isMultiple = !!thisSelector.isMultiple;
    this.isParent = thisSelector.isParent;

    this.pickerOptions = this.pickerOptionsExists
      ? this.pickerOptions
      : this.selectWidgetService.getOptionsFromData(thisSelector, this.params)
        .options; // to be done

    this.pickerOptions.forEach(pick => {

      if ((this.params.id === "traffic_residence_selectors" || this.params.id === "presence_residence_selectors") &&
        ["annual", "months", "weeks", "days"].indexOf(pick.key) >= 0) {

        pick.dictionaryName = this.getRealName(
          "controls-select-residence",
          "short_title",
          pick.key
        );

      } else if ((this.params.id === "traffic_segmentation" || this.params.id === "presence_segmentation") &&
        ["annual", "months", "weeks", "days"].indexOf(pick.key) >= 0) {
        pick.dictionaryName = this.getRealName(
          "controls-select-segmentation",
          "short_title",
          pick.key
        );
      } else {
        pick.dictionaryName = this.getRealName(
          "controls-select",
          "short_title",
          pick.key
        );
      }
    });

    if (!this.isMultiple) {
      this.formControl.setValue(
        this.isParent
          ? thisSelector.options.find(c => c.selected == true)
          : thisSelector.options.find(c => c.selected == true).key
      );
    }
    if (this.isMultiple) {
      // TO BE COMPLETED
      this.formControl.setValue(thisSelector.selected);
    }

    if (!this.pickerOptionsExists) {
      this.params.selectors[indexOfSelect].options = this.pickerOptions;
    }
  }

  // JS change
  onChange(newVal) {
    this.eventEmitter.emit(this.formControl.value); // "key"
  }

  ngOnChanges() {
    this.getRealName = this.d3MainService.getRealName;
    this.setSelector();
    if (this.emitFirst) {
      this.eventEmitter.emit(this.formControl.value); // "key"
    }
  }

  ngOnInit() { }
}
