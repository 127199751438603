import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { DataService } from "../../app-services/data.service";

@Component({
  selector: "app-sidenav-data",
  templateUrl: "./sidenav-data.component.html",
  styleUrls: ["./sidenav-data.component.scss"]
})
export class SidenavDataComponent implements OnInit, OnDestroy {
  data: any;
  restaurant: any;
  ready: boolean = false;
  readyFicha: boolean = true;
  readyVentas: boolean = false;
  readyCompetencia: boolean = false;
  readyEntorno: boolean = false;
  readyPresencia: boolean = false;
  readyTrafico: boolean = false;
  mcAutoExists: boolean = false;

  isMcdsPA_P5: boolean = false;

  checkIfServiceExists = this.dataService.checkIfRestaurantServiceExists;
  selectedIndexInit: number = 0;

  unsubscribe: any;
  unsubscribe2: any;
  constructor(private dataService: DataService) {}

  changeDataTab = e => {
    this.readyFicha = e.index == 0 ? true : false;
    this.readyVentas = e.index == 1 ? true : false;
    this.readyCompetencia = e.index == 2 ? true : false;
    this.readyEntorno = e.index == 3 ? true : false;
    this.readyPresencia = e.index == 4 ? true : false;
    this.readyTrafico = e.index == 5 ? true : false;
    if(e.tab.textLabel=="Entorno"){
      this.readyEntorno = true;
    }
  };

  ngOnInit() {
    const _this = this;
    this.unsubscribe = this.dataService.restaurantSelectedDescriptiveChanged$.subscribe(
      idPlace => {
        this.selectedIndexInit = 0;
        //_this.readyPresencia = false;
        if (idPlace !== undefined) {
          _this.unsubscribe2 = _this.dataService
            .getPlacesIdMcd(idPlace)
            .subscribe(d => {
              _this.data = d;
              _this.restaurant = this.data.properties;
              this.mcAutoExists = this.checkIfServiceExists(
                this.restaurant.info.mcauto
              );
              if(_this.restaurant.info.status == "Próxima apertura" || _this.restaurant.info.status == "Plan 5 años"){
                _this.isMcdsPA_P5 = true;
              }else{
                _this.isMcdsPA_P5 = false;
              }
               
              _this.ready = true;
              _this.readyFicha = true;
              //_this.readyPresencia = true;
            });
        }
      }
    );
  }

  triggerToggleSideNavs: any = () => {
    this.dataService.serviceToggleSideNavs({
      sidenavRestaurants: false,
      sidenavData: false
    });
  };

  ngOnChanges() {
    const _this = this;
    this.unsubscribe = this.dataService.restaurantSelectedDescriptiveChanged$.subscribe(
      idPlace => {
        this.selectedIndexInit = 0;
        //_this.readyPresencia = false;
        if (idPlace !== undefined) {
          _this.unsubscribe2 = _this.dataService
            .getPlacesIdMcd(idPlace)
            .subscribe(d => {
              _this.data = d;
              _this.restaurant = this.data.properties;
              this.mcAutoExists = this.checkIfServiceExists(
                this.restaurant.info.mcauto
              );
              if(_this.restaurant.info.status == "Próxima apertura" || _this.restaurant.info.status == "Plan 5 años"){
                _this.isMcdsPA_P5 = true;
              }else{
                _this.isMcdsPA_P5 = false;
              }
              _this.ready = true;
              _this.readyFicha = true;
              //_this.readyPresencia = true;
            });
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
