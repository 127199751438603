<article>
    <div data-size="A4">

        <article>
            <app-brick-report-map [setup]="setup"></app-brick-report-map>

            <section class="four-col-middle margin-top-24">
                <p class="text-align-center margin-top-16 margin-bottom-0">
                    INFORME ESTUDIO |
                    <span class="template-state">Prescriptivo</span> |
                    <span class="template-state">{{ setup.province_name }}</span>
                </p>
                <p class="text-align-center">
                    Bricks: Potencial de ventas para nuevas aperturas McDonalds
                </p>
            </section>

            <section class="six-col-full">
                <p class="text-align-center">
                    <span class="bgk--black">#<span class="template-id_study">{{ setup.properties.study.study_id
                            }}</span></span>
                </p>
                <h1 class="text-align-center">
                    <span class="template-name">{{ setup.properties.study_name}}</span>
                </h1>
                <h2 class="text-align-center">
                    <span class="template-kind_building">{{ setup.selectedOrder }}</span>
                </h2>
            </section>

            <section class="four-col-middle text-align-center margin-top-48">
                <p class="margin-bottom-0">
                    <i class="fa fa-briefcase" aria-hidden="true"></i>
                </p>
                <!--
                <p class="margin-bottom-0">
                    Propietario del informe:
                    <span class="html-nombreUsuario">Abacus</span>
                </p>
-->
                <p>
                    <span class="template-day">{{setup.properties.study.created | date:'fullDate'}}</span> |
                    <span class="template-hour">{{setup.properties.study.created | date:'fullTime'}}</span>
                </p>
            </section>
            <section class="four-col-middle text-align-center margin-top-48">
                <p class="margin-bottom-0">
                    <small>Abacus Consulting | Nommon Solutions</small>
                </p>
            </section>
            <app-brick-report-footer [page_number]="setup.id + 1"></app-brick-report-footer>
        </article>
    </div>
</article>