import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabBuscadorComponent } from "./tab-buscador.component";

@NgModule({
  declarations: [TabBuscadorComponent],
  imports: [SharedModule],
  exports: [TabBuscadorComponent],
  providers: []
})
export class TabBuscadorModule {}
