import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { GeoAddCompetitorsTableComponent } from "./geo-add-competitors-table.component";

import { AddDialogBoxComponent } from "./add-dialog-box/add-dialog-box.component";

@NgModule({
    declarations: [GeoAddCompetitorsTableComponent, AddDialogBoxComponent],
    imports: [SharedModule],
    exports: [GeoAddCompetitorsTableComponent],
    providers: []
})
export class GeoAddCompetitorsTableModule {}
