import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";
import { StudyFichaComponent } from "./study-ficha.component";
import { ServicesIconsModule } from "../../../../app-widgets/icons/services-icons/services-icons.module";
import { StudySalesModule } from "../../../../app-widgets/tables/study-sales/study-sales.module";

@NgModule({
  declarations: [StudyFichaComponent],
  imports: [SharedModule, ServicesIconsModule, StudySalesModule],
  exports: [StudyFichaComponent],
  providers: []
})
export class StudyFichaModule {}
