import { Component, OnInit, Input } from "@angular/core";
import { D3MainService } from "../../../app-services/d3/d3-main.service";

import * as d3 from "d3";
@Component({
  selector: "app-scatterplot",
  templateUrl: "./scatterplot.component.html",
  styleUrls: ["./scatterplot.component.scss"]
})
export class ScatterplotComponent implements OnInit {
  @Input()
  params: any;
  data: any;
  dataChart: any;
  xColumns: any;
  xKeys: Array<string>;
  design: any;
  chartLegend: any;
  units: any;
  style: any;
  legend: any;

  D3READY: boolean = false;
  win: any = window; // in use
  timeout: any = false;
  delay: number;

  // services
  COLORS: any;
  getDimensions: any;
  formatRound: any;
  formatZero: any;
  getRealName: any;

  tDefault: number;
  opacity_lowest: number;

  width: number;
  height: number;
  margin: any;

  // svg containers
  container: any;
  svg: any;
  chartWrapper: any;
  chartInner: any;
  chartGroups: any;
  tooltip: any;

  // scales
  xScale: any;
  xScaleBars: any;
  yScale: any;
  colorScale: any;

  // axis names

  xVal: string;
  yVal: string;
  xAxis: any;
  yAxis: any;

  // averages and extends
  yAvg: number;
  xAvg: number;
  xExtent: any;
  yExtent: any;
  xDomain: Array<number>;
  yDomain: Array<number>;

  // ticks
  xTicks: any;
  yTicks: any;

  init = (): void => {
    this.destroyChart();
    this.renderAll();
  };

  destroyChart() {
    d3.select(this.params.container || ".chart-area").html("");
    d3.select(this.win).on("resize", null);
  }
  renderAll = (): void => {
    this.xScale = d3.scaleLinear();
    this.yScale = d3.scaleLinear();

    ////////// Initialize axis //////////
    this.xAxis = d3.axisBottom(this.xScale).tickFormat(this.formatZero);
    this.yAxis = d3.axisLeft(this.yScale).tickFormat(this.formatZero);

    // Container
    this.container = d3.select(this.params.container || ".chart-area"); // placeholder div for svg

    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    // External group
    this.chartWrapper = this.svg
      .selectAll("g.chartWrapper")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartWrapper")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    // Axis groups
    this.chartWrapper
      .selectAll(".x.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis");
    this.chartWrapper
      .selectAll(".y.axis")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis");

    this.chartInner = this.chartWrapper
      .selectAll("g.chartInner") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartInner");

    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.render, this.delay);
    });
    this.reshapedata();
  };

  reshapedata = (): void => {
    this.D3READY = false;

    this.dataChart = this.params.grossData;

    ////////// Domains & extents //////////
    // The averages for the selected brand.
    this.xAvg = d3.mean(this.dataChart, d => d.data[this.xVal["name"]]);
    this.yAvg = d3.mean(this.dataChart, d => d.data[this.yVal["name"]]);

    // The extents & domains
    this.xExtent = d3.extent(this.dataChart, d => d.data[this.xVal["name"]]);
    this.yExtent = d3.extent(this.dataChart, d => d.data[this.yVal["name"]]);

    this.xDomain = [0, this.xExtent[1]];
    this.yDomain = [0, this.yExtent[1]];

    this.render();
  };

  render = (): void => {
    let dimensions = this.getDimensions(
      this.svg,
      this.params.container || ".chart-area",
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .attr(
        "viewBox",
        "0 0 " +
          (this.width + this.margin.left + this.margin.right) +
          " " +
          (this.height + this.margin.top + this.margin.bottom)
      )
      .attr("preserveAspectRatio", "xMaxYMax meet");

    this.xScale
      .domain(this.xDomain)
      .range([0, this.width])
      .nice();
    this.yScale
      .domain(this.yDomain)
      .range([this.height, 0])
      .nice();

    this.drawAxis();
    this.drawGrid();
    this.drawAverages();
    this.drawBubbles();
    this.drawLabels();

    console.log("rendered!!");
  };

  public drawAxis = (): void => {
    this.xTicks = this.d3MainService.getTicks(this.xDomain, this.xAvg);
    this.yTicks = this.d3MainService.getTicks(this.yDomain, this.yAvg);
    this.xAxis.ticks(4);
    this.yAxis.ticks(4);
    // Call axis
    this.chartWrapper
      .selectAll(".x.axis")
      .attr("transform", `translate(0, ${this.height})`)
      .transition()
      .duration(this.tDefault)
      .call(this.xAxis);

    this.chartWrapper
      .selectAll(".y.axis")
      .attr("transform", "translate(0, 0)")
      .transition()
      .duration(this.tDefault)
      .call(this.yAxis);

    // Append axis titles
    this.chartWrapper
      .selectAll(".x.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.COLORS.gray_dark)
      .merge(this.chartWrapper.selectAll(".x.axis").selectAll(".axis_title"))
      .text(this.xVal["value"])
      .attr(
        "transform",
        `translate(${this.width / 2}, ${this.margin.bottom / 1.2})`
      );

    this.chartWrapper
      .selectAll(".y.axis")
      .selectAll(".axis_title")
      .data([{}])
      .enter()
      .append("svg:text")
      .attr("class", "axis_title")
      .attr("text-anchor", "middle")
      .style("fill", this.COLORS.gray_dark)
      .merge(this.chartWrapper.selectAll(".y.axis").selectAll(".axis_title"))
      .text(this.yVal["value"])
      .attr(
        "transform",
        `translate(${-this.margin.left / 1.3}, ${this.height / 2}) rotate(-90)`
      );
  };
  public drawGrid = (): void => {};
  public drawAverages = (): void => {
    const _xScale: any = this.xScale;
    const _yScale: any = this.yScale;
    let _width: number = this.width;
    let _height: number = this.height;

    // Create a group to store
    this.chartInner
      .selectAll("g.avg")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "avg");
    let gAvg: any = this.chartInner.selectAll("g.avg");

    //  Enter the lines
    gAvg
      .selectAll(".avgline")
      .data([
        { coord: "x", value: this.xAvg },
        { coord: "y", value: this.yAvg }
      ])
      .enter()
      .append("line")
      .attr("class", "avgline")
      .attr("x1", function(d) {
        return d.coord == "x" ? _xScale(d.value) : 0;
      })
      .attr("y1", function(d) {
        return d.coord == "x" ? 0 : _yScale(d.value);
      })
      .attr("x2", function(d) {
        return d.coord == "x" ? _xScale(d.value) : _width;
      })
      .attr("y2", function(d) {
        return d.coord == "x" ? _height : _yScale(d.value);
      })
      .style("stroke", this.design.style.averages.axisColor)
      .style("opacity", 1);

    // Update position
    gAvg
      .selectAll(".avgline")
      .transition()
      .duration(this.tDefault / 2)
      .attr("x1", function(d) {
        return d.coord == "x" ? _xScale(d.value) : 0;
      })
      .attr("y1", function(d) {
        return d.coord == "x" ? 0 : _yScale(d.value);
      })
      .attr("x2", function(d) {
        return d.coord == "x" ? _xScale(d.value) : _width;
      })
      .attr("y2", function(d) {
        return d.coord == "x" ? _height : _yScale(d.value);
      })
      .style("opacity", 1);

    let circleAvg: any = this.chartInner.selectAll("g.avg");

    circleAvg
      .selectAll(".avgcircle")
      .data([{ cx: this.xAvg, cy: this.yAvg, r: 3, "data-name": "average" }])
      .enter()
      .append("svg:circle")
      .attr("class", "avgcircle")
      .attr("cx", function(d) {
        return _xScale(d.cx);
      })
      .attr("cy", function(d) {
        return _yScale(d.cy);
      })
      .attr("r", function(d) {
        return d.r;
      })
      .attr("data", function(d) {
        return d.r;
      })
      .style("stroke", this.design.style.averages.circleColor)
      .style("opacity", 1);

    // Update position
    circleAvg
      .selectAll(".avgcircle")
      .transition()
      .duration(this.tDefault / 2)
      .attr("cx", function(d) {
        return _xScale(d.cx);
      })
      .attr("cy", function(d) {
        return _yScale(d.cy);
      })
      .attr("r", function(d) {
        return d.r;
      });
  };
  public drawBubbles = (): void => {
    let _xScale: any = this.xScale;
    let _yScale: any = this.yScale;
    let _colorScale: any = this.colorScale;
    const _xVar: string = this.xVal["name"];
    const _yVar: string = this.yVal["name"];
    let _tDefault: number = this.tDefault;

    let _mouseoverNode: any = this.mouseoverNode;
    let _mouseoutNode: any = this.mouseoutNode;
    let _clickNode: any = this.clickNode;

    // Create a group to store the 'nodes'
    this.chartInner
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");

    let nodes: any = this.chartInner
      .selectAll("g.nodes")
      .selectAll(".node")
      .data(this.dataChart, function(d) {
        return d.key;
      });

    // Exit the nodes
    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("r", 0)
      .remove();

    // Enter a group for each brand node
    nodes
      .enter()
      .append("svg:g")
      .attr("class", function(d) {
        return "node " + d.key;
      })
      .attr("transform", function(d) {
        return (
          "translate(" +
          _xScale(d.data[_xVar]) +
          "," +
          _yScale(d.data[_yVar]) +
          ")"
        );
      })
      .style("opacity", 1);

    nodes = this.chartInner.selectAll("g.nodes").selectAll(".node");

    // Enter the elements
    nodes
      .selectAll(".nodebg")
      .data(function(d) {
        return [d];
      })
      .enter()
      .append("svg:circle")
      .attr("class", function(d) {
        return "nodebg " + d.key;
      })
      .attr("r", this.design.circle.r)
      .style("fill", function(d) {
        return _colorScale(d.key);
      })
      .style("opacity", 1);

    // Apply transition to the group
    nodes
      .transition()
      .duration(this.tDefault)
      .attr("transform", function(d) {
        return (
          "translate(" +
          _xScale(d.data[_xVar]) +
          "," +
          _yScale(d.data[_yVar]) +
          ")"
        );
      })
      .style("opacity", 1)
      .on("start", function(d, i) {
        // Remove node group interaction
        d3.select(this)
          .on("mouseover", null)
          .on("mouseout", null)
          .on("click", null);

        // update circle color
        d3.select(this)
          .select(".nodebg")
          .transition()
          .duration(_tDefault)
          .style("fill", function(d) {
            return _colorScale(d.key);
          })
          .style("opacity", 1);
      })
      .on("end", function(d, i) {
        // Add node group interaction
        d3.select(this)
          .on("mouseover", function(data) {
            // this from d3!!
            _mouseoverNode(data, this);
          })
          .on("mouseout", function(data) {
            // this from d3!!
            _mouseoutNode(data, this);
          })
          .on("click", function(data) {
            // this from d3!!
            _clickNode(data, this);
          });

        // Finish render
      });
  };

  mouseoverNode = () => {};
  mouseoutNode = () => {};
  clickNode = () => {};

  drawLabels = (): void => {
    const _tDefault: number = this.tDefault / 2;
    const _xScale: any = this.xScale;
    const _yScale: any = this.yScale;
    const _xVar: string = this.xVal["name"];

    const _yVar: string = this.yVal["name"];
    const _nodeRadius: number = this.design.circle.r;

    const _mouseoutNode: any = this.mouseoutNode;
    const _mouseoverNode: any = this.mouseoverNode;
    const _clickNode: any = this.clickNode;

    // Labels positions from data

    this.dataChart.forEach(function(d) {
      d["xLabel"] = _xScale(d.data[_xVar]);
      d["yLabel"] = _yScale(d.data[_yVar]);
    });

    // Create a group for the 'labels'
    this.chartInner
      .selectAll("g.labels")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "labels");

    let gLabels: any = this.chartWrapper.selectAll("g.labels");

    // Exit the nodes
    gLabels
      .selectAll(".label_text")
      .data(this.dataChart, function(d) {
        return d.key;
      })
      .exit()
      .remove();

    // Append the texts & links
    gLabels
      .selectAll(".label_text")
      .data(this.dataChart, function(d) {
        return d.key;
      })
      .enter()
      .append("text")
      .attr("class", function(d) {
        return "label_text " + d.key;
      })
      .attr("x", function(d) {
        return _xScale(d.data[_xVar]);
      })
      .attr("y", function(d) {
        return _yScale(d.data[_yVar]) + _nodeRadius * 3;
      })

      .attr("text-anchor", "middle")
      .style("opacity", 0)
      .style("font-weight", "normal")
      .style("font-size", this.style.legend.fontSize)

      .text(function(d) {
        return d.key;
      });

    // Update the labels position
    gLabels
      .selectAll(".label_text")
      .transition()
      .duration(_tDefault)
      .attr("x", function(d) {
        return _xScale(d.data[_xVar]);
      })
      .attr("y", function(d) {
        return _yScale(d.data[_yVar]) + _nodeRadius * 2;
      })
      .style("opacity", 1);
  };

  constructor(private d3MainService: D3MainService) {}

  ngOnChanges(changes: any) {
    let changeObj = Object.keys(changes);

    // service here
    this.COLORS = this.d3MainService.COLORS;
    this.getDimensions = this.d3MainService.getDimensions;
    this.formatRound = this.d3MainService.FORMATROUND;
    this.formatZero = this.d3MainService.FORMATZERO;
    this.tDefault = this.d3MainService.TIMESPAN;
    this.opacity_lowest = this.d3MainService.OPACITY_LOWEST;
    this.getRealName = this.d3MainService.getRealName;
    this.delay = this.d3MainService.RESIZE_DELAY;

    // params

    this.design = this.params.design;
    this.margin = this.params.design.margin;
    this.legend = this.params.legend;
    this.tooltip = this.params.tooltip;
    this.xVal = this.params.xVal;
    this.yVal = this.params.yVal;
    (this.xColumns = this.params.xVal.columns),
      (this.units = this.params.units),
      (this.style = this.params.design.style);

    this.colorScale = d3.scaleOrdinal().range(this.style.colors);

    if (changeObj.length > 1) {
      // params and selector changes first load
      this.params["change"] = "first load";
      //this.init();
      return;
    }

    if (changeObj.length === 1) {
      // selector changes
      this.params["change"] = "selector";
      //this.reshapedata();
      return;
    }
  }

  ngOnInit() {
    console.log(this.params);
  }

  ngAfterViewInit() {
    this.init();
  }
}
