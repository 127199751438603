import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { UlMassiveBricksComponent } from "./ul-massive-bricks.component";
import { LiMassiveBricksModule } from "../li-massive-bricks/li-massive-bricks.module";

@NgModule({
  declarations: [UlMassiveBricksComponent],
  imports: [SharedModule, LiMassiveBricksModule],
  exports: [UlMassiveBricksComponent],
  providers: []
})
export class UlMassiveBricksModule {}
