<mat-nav-list>
  <a mat-list-item routerLink="/descriptive" (click)="onSidenavClose()">
    <mat-icon>wb_sunny</mat-icon> <span class="nav-caption">Descriptivo</span>
  </a>

  <mat-list-item [matMenuTriggerFor]="predictive_menu">
    <mat-icon>layers</mat-icon>
    <a matline>Predictivo</a>
  </mat-list-item>

  <mat-list-item [matMenuTriggerFor]="massive_prescriptive_menu">
    <mat-icon>layers</mat-icon>
    <a matline>Estudios Masivos</a>
  </mat-list-item>

  <mat-list-item [matMenuTriggerFor]="prescriptive_menu">
    <mat-icon>assignment</mat-icon>
    <a matline>Prescriptivo</a>
  </mat-list-item>

  <a mat-list-item routerLink="/downloads" (click)="onSidenavClose()">
    <mat-icon>cloud_download</mat-icon
    ><span class="nav-caption">Descargas</span>
  </a>

  <a mat-list-item routerLink="/users" (click)="onSidenavClose()">
    <mat-icon>supervisor_account</mat-icon>
    <span class="nav-caption">Usuarios</span>
  </a>

  <mat-menu #predictive_menu="matMenu">
    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/predictive/map"
    >
      <mat-icon>map</mat-icon>Mapa
    </button>
    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/predictive/studies"
    >
      <mat-icon>assignment</mat-icon>Estudios
    </button>

    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/predictive/new-form"
    >
      <mat-icon>create_new_folder</mat-icon>Nuevo estudio
    </button>
  </mat-menu>


  <mat-menu #massive_prescriptive_menu="matMenu">
    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/massive-prescriptive/map"
    >
      <mat-icon>map</mat-icon>Mapa
    </button>
    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/massive-prescriptive/studies"
    >
      <mat-icon>assignment</mat-icon>Estudios Masivos
    </button>

    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/massive-prescriptive/new-form"
    >
      <mat-icon>create_new_folder</mat-icon>Nuevo estudio masivo
    </button>
  </mat-menu>

  <mat-menu #prescriptive_menu="matMenu">
    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/massive-prescriptive/map"
    >
      <mat-icon>map</mat-icon>Mapa
    </button>
    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/prescriptive/studies"
    >
      <mat-icon>assignment</mat-icon>Estudios
    </button>

    <button
      mat-menu-item
      (click)="onSidenavClose()"
      routerLink="/prescriptive/new-form"
    >
      <mat-icon>create_new_folder</mat-icon>Nuevo estudio
    </button>
  </mat-menu>
</mat-nav-list>
