import { Component, OnInit, Input } from "@angular/core";
import { D3MainService } from "../../../app-services/d3/d3-main.service";

import * as d3 from "d3";
@Component({
  selector: "app-population-pyramid",
  templateUrl: "./population-pyramid.component.html",
  styleUrls: ["./population-pyramid.component.scss"]
})
export class PopulationPyramidComponent implements OnInit {
  @Input()
  params: any;
  dataChart: any;
  maxValue: number;
  totalPopulation: number;

  D3READY: boolean = false;
  win: any = window; // in use
  timeout: any = false;
  delay: number;
  tDefault: number;
  FORMATROUND: any;

  COLORS: any = {};

  width: number;
  height: number;
  w: number;
  h: number;
  margin: any = {};
  sectorWidth: number;
  leftBegin: number;
  rightBegin: number;
  stroke: any = {};
  circle: any = {};
  style: any = {};

  // keys
  leftBarKey: string;
  rightBarKey: string;
  leftLineKey: string;
  rightLineKey: string;
  indexKey: string;

  // scales
  xScale: any;
  yScale: any;
  xScaleLeft: any;
  xScaleRight: any;

  //Axis
  yAxisLeft: any;
  yAxisRight: any;
  xAxisRight: any;
  xAxisLeft: any;

  // lines
  lineRight: any;
  lineLeft: any;

  // svg containers
  container: any;
  svg: any;
  chartWrapper: any;
  chartLegend: any;
  lineLegend: any;
  barInnerLeft: any;
  barInnerRight: any;
  lineInnerLeft: any;
  lineInnerRight: any;
  dotInnerLeft: any;
  dotInnerRight: any;

  // tooltip
  vBody: any;
  tipDiv: any;
  tooltipStyle: any;
  tooltipText: any;
  tooltipID: string;

  // legend
  legend: any;

  init = (): void => {
    this.destroyTooltip();
    this.destroyChart();
    this.renderAll();
  };

  destroyChart() {
    d3.select(this.params.container || "#population_pyramid").html("");
    d3.select(this.win).on("resize", null);
  }

  destroyTooltip() {
    this.tipDiv = null;
    this.vBody
      .select("#" + this.tooltipID)
      .html("")
      .remove();
  }

  renderAll = (): void => {
    this.dataChart = this.params.grossData;
    this.margin = {
      top: this.params.design.margin.top,
      right: this.params.design.margin.right,
      bottom: this.params.design.margin.bottom,
      left: this.params.design.margin.left,
      middle: this.params.design.margin.middle
    };
    this.stroke = {
      width: this.params.design.stroke.width,
      stroke: this.params.design.stroke.stroke
    };
    this.circle = {
      fill: this.params.design.circle.fill,
      r: this.params.design.circle.r
    };

    if (typeof this.params.design.style === "undefined") {
      this.style = {
        leftBarColor: "#6c9dc6",
        rightBarColor: "#de5454",
        leftLineColor: "#1a316b", // hombre
        rightLineColor: "#7f1b55", // mujer
        leftDotColor: "#1a316b", // hombre
        rightDotColor: "#7f1b55", // mujer
        tooltipBG: "#fefefe",
        tooltipColor: "black"
      };
    } else {
      this.style = {
        leftBarColor:
          typeof this.params.design.style.leftBarColor === "undefined"
            ? "#6c9dc6"
            : this.params.design.style.leftBarColor,
        rightBarColor:
          typeof this.params.design.style.rightBarColor === "undefined"
            ? "#de5454"
            : this.params.design.style.rightBarColor,

        leftLineColor:
          typeof this.params.design.style.leftLineColor === "undefined"
            ? "#1a316b"
            : this.params.design.style.leftLineColor,
        rightLineColor:
          typeof this.params.design.style.rightLineColor === "undefined"
            ? "#7f1b55"
            : this.params.design.style.rightLineColor,

        leftDotColor:
          typeof this.params.design.style.leftDotColor === "undefined"
            ? "#1a316b"
            : this.params.design.style.leftDotColor,
        rightDotColor:
          typeof this.params.design.style.rightDotColor === "undefined"
            ? "#7f1b55"
            : this.params.design.style.rightDotColor,

        tooltipBG:
          typeof this.params.design.style.tooltipBG === "undefined"
            ? "#fefefe"
            : this.params.design.style.tooltipBG,
        tooltipColor:
          typeof this.params.design.style.tooltipColor === "undefined"
            ? "black"
            : this.params.design.style.tooltipColor
      };
    }

    // the xScale goes from 0 to the width of a region
    //  it will be reversed for the left x-axis
    this.xScale = d3.scaleLinear();
    this.xScaleLeft = d3.scaleLinear();
    this.xScaleRight = d3.scaleLinear();
    this.yScale = d3.scaleBand();

    /**********************/
    /** SET UP AXES **/
    /**********************/

    this.yAxisLeft = d3.axisRight();
    this.yAxisRight = d3.axisLeft();
    this.xAxisRight = d3.axisBottom();
    this.xAxisLeft = d3.axisBottom();

    /**********************/
    /** SET UP LINES**/
    /**********************/

    this.lineRight = d3.line();
    this.lineLeft = d3.line();

    this.container = d3.select(this.params.container || "#population_pyramid"); // placeholder div for svg

    this.svg = this.container
      .selectAll("svg")
      .data([{}])
      .enter()
      .append("svg:svg");

    this.chartLegend = this.svg
      .selectAll("g.chartLegend")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartLegend")
      .attr("transform", "translate(" + this.margin.left + "," + 0 + ")");

    this.lineLegend = this.svg
      .selectAll("g.lineLegend")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "lineLegend")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    // External group
    this.chartWrapper = this.svg
      .selectAll("g.chartWrapper")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "chartWrapper")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    // Axis groups
    this.chartWrapper
      .selectAll(".x.axis.left")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis left");

    this.chartWrapper
      .selectAll(".x.axis.right")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "x axis right");
    this.chartWrapper
      .selectAll(".y.axis.left")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis left");
    this.chartWrapper
      .selectAll(".y.axis.right")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "y axis right");

    this.barInnerLeft = this.chartWrapper
      .selectAll("g.barInner.barInnerLeft") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "barInner barInnerLeft");

    this.barInnerRight = this.chartWrapper
      .selectAll("g.barInner.barInnerRight") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "barInner barInnerRight");

    this.lineInnerLeft = this.chartWrapper
      .selectAll("g.lineInner.lineInnerLeft") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "lineInner lineInnerLeft");

    this.lineInnerRight = this.chartWrapper
      .selectAll("g.lineInner.lineInnerRight") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "lineInner lineInnerRight");

    this.dotInnerLeft = this.chartWrapper
      .selectAll("g.dotInner.dotInnerLeft") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "dotInner dotInnerLeft");

    this.dotInnerRight = this.chartWrapper
      .selectAll("g.dotInner.dotInnerRight") // chart without axis to clipPath
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "dotInner dotInnerRight");

    this.win.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.render, this.delay);
    });
    this.reshapedata();
  };

  reshapedata = (): void => {
    this.D3READY = false;
    let percentage = this.percentage;
    let leftBarKey = this.leftBarKey;
    let rightBarKey = this.rightBarKey;
    let leftLineKey = this.leftLineKey;
    let rightLineKey = this.rightLineKey;

    let totalPopulationBars: number = d3.sum(this.dataChart, d => {
      return d[leftBarKey] + d[rightBarKey];
    });

    let totalPopulationLines: number = d3.sum(this.dataChart, d => {
      return d[leftLineKey] + d[rightLineKey];
    });

    this.totalPopulation = (totalPopulationLines + totalPopulationBars) / 2;

    // find the maximum data value for whole dataset
    // and rounds up to nearest 5%
    //  since this will be shared by both of the x-axes
    let maxValueBars: number =
      Math.ceil(
        Math.max(
          d3.max(this.dataChart, d => {
            return percentage(d[leftBarKey]);
          }),
          d3.max(this.dataChart, d => {
            return percentage(d[rightBarKey]);
          })
        ) / 0.05
      ) * 0.05;

    // Math.ceil(0.95) --> 1
    // Math.ceil(-0.95) --> - 0
    // Math.ceil(7.004) --> 8
    // Math.ceil(-7.004) --> -7

    let maxValueLines: number =
      Math.ceil(
        Math.max(
          d3.max(this.dataChart, d => {
            // console.log('d-->', d);
            // console.log('d-->', d[keys.leftLine]);
            return percentage(d[leftLineKey]);
          }),
          d3.max(this.dataChart, d => {
            return percentage(d[rightLineKey]);
          })
        ) / 0.05
      ) * 0.05;

    this.maxValue = d3.max([maxValueBars, maxValueLines]); // 0.1

    this.render();
  };

  render = (): void => {
    let indexKey = this.indexKey;

    let dimensions = this.d3MainService.getDimensions(
      this.svg,
      this.params.container || "#population_pyramid",
      this.margin
    );
    this.width = dimensions.width;
    this.height = dimensions.height;

    this.w = this.width - (this.margin.left + this.margin.right);
    this.h = this.height - (this.margin.top + this.margin.bottom);

    (this.sectorWidth = this.width / 2 - this.margin.middle),
      (this.leftBegin = this.sectorWidth - this.margin.left),
      (this.rightBegin = this.width - this.margin.right - this.sectorWidth);

    // the xScale goes from 0 to the width of a region
    //  it will be reversed for the left x-axis
    this.xScale
      .domain([0, this.maxValue])
      .range([0, this.sectorWidth - this.margin.right]);

    this.xScaleLeft
      .domain([0, this.maxValue])
      .range([this.sectorWidth - this.margin.left, 0]);

    this.xScaleRight
      .domain([0, this.maxValue])
      .range([0, this.sectorWidth - this.margin.right]);

    this.yScale
      .domain(
        this.dataChart.map(function(d) {
          return d[indexKey];
        })
      )
      .range([this.h, 0], 0.1);

    this.yAxisLeft
      .scale(this.yScale)
      .tickSize(4, 0)
      .tickPadding(this.margin.middle - 4);

    this.yAxisRight
      .scale(this.yScale)
      .tickSize(4, 0)
      .tickFormat("");

    this.xAxisRight
      .scale(this.xScale)
      .ticks(5)
      .tickFormat(d3.format(".0%"));

    // REVERSE THE X-AXIS SCALE ON THE LEFT SIDE BY REVERSING THE RANGE
    this.xAxisLeft
      .scale(this.xScale.copy().range([this.leftBegin, 0]))
      .ticks(5)
      .tickFormat(d3.format(".0%"));

    /**********************/
    /** LINES **/
    /**********************/

    this.lineRight
      .x(d => {
        return this.xScaleRight(this.percentage(d[this.rightLineKey]));
      })
      .y(d => {
        return this.yScale(d[this.indexKey]) + this.margin.middle / 4;
      })
      .curve(d3.curveLinear);

    this.lineLeft
      .x(d => {
        return this.xScaleLeft(this.percentage(d[this.leftLineKey]));
      })
      .y(d => {
        return this.yScale(d[this.indexKey]) + this.margin.middle / 4;
      })
      .curve(d3.curveLinear);

    // Apply to svg
    this.svg
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .attr(
        "viewBox",
        "0 0 " +
          (this.width + this.margin.left + this.margin.right) +
          " " +
          (this.height + this.margin.top + this.margin.bottom)
      )
      .attr("preserveAspectRatio", "xMaxYMax meet");
    this.destroyTooltip();

    this.drawTooltip();
    this.drawAxis();
    this.drawLefBarGroup();
    this.drawRightBarGroup();
    this.drawLeftLineGroup();
    this.drawRightLineGroup();
    this.drawLefDotGroup();
    this.drawRightDotGroup();

    this.drawBarLegend();
    this.drawLineLegend();
    console.log("rendered!!");
  };

  public drawLeftLineGroup = (): void => {
    const tDefault: number = this.tDefault;

    // Create a group to store the 'nodes'
    this.lineInnerLeft.attr("transform", this.translation(0, 0));

    this.lineInnerLeft
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");
    // Join the data
    let lines: any = this.lineInnerLeft
      .selectAll("g.nodes")
      .selectAll("path.path")
      .data([this.dataChart]);

    lines
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .remove();

    lines
      .enter()
      .append("path")
      .attr("class", d => {
        return "path left " + this.leftLineKey;
      })
      .attr("data-name", d => {
        return this.leftLineKey;
      })

      .attr("d", this.lineLeft)
      .attr("fill", "none")
      .attr("stroke", this.style.leftLineColor)
      .attr("stroke-width", this.stroke.width + "%");

    lines = this.lineInnerLeft.selectAll(".path");

    lines
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("d", this.lineLeft)
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      });
  };

  // we are here
  public drawRightLineGroup = (): void => {
    const tDefault: number = this.tDefault;

    // Create a group to store the 'nodes'
    this.lineInnerRight.attr("transform", this.translation(this.rightBegin, 0));

    this.lineInnerRight
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");
    // Join the data
    let lines: any = this.lineInnerRight
      .selectAll("g.nodes")
      .selectAll("path.path")
      .data([this.dataChart]);

    lines
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .remove();

    lines
      .enter()
      .append("path")
      .attr("class", d => {
        return "path left " + this.rightLineKey;
      })
      .attr("data-name", d => {
        return this.rightLineKey;
      })

      .attr("d", this.lineRight)
      .attr("fill", "none")
      .attr("stroke", this.style.rightLineColor)
      .attr("stroke-width", this.stroke.width + "%");

    lines = this.lineInnerRight.selectAll(".path");

    lines
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("d", this.lineRight)
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      });
  };
  public drawLefDotGroup = (): void => {
    let yScale = this.yScale;
    let xScale = this.xScale;
    let margin = this.margin;
    let leftLineKey = this.leftLineKey;
    let indexKey = this.indexKey;

    let percentage = this.percentage;
    const tDefault: number = this.tDefault;
    let mouseoverNode: any = this.mouseoverNode;
    let mouseoutNode: any = this.mouseoutNode;
    let mousemoveNode: any = this.mousemoveNode;

    // Create a group to store the 'nodes'
    this.dotInnerLeft.attr(
      "transform",
      this.translation(this.leftBegin, 0) + "scale(-1,1)"
    );

    this.dotInnerLeft
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");

    // Join the data
    let nodes: any = this.dotInnerLeft
      .selectAll("g.nodes")
      .selectAll(".node")
      .data(this.dataChart, d => {
        return d[this.leftLineKey];
      });

    // Exit the nodes
    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("r", 0)
      .remove();

    nodes
      .enter()
      .append("svg:circle")
      .attr("class", d => {
        return "node circle left " + leftLineKey;
      })
      .attr("data-name", d => {
        return leftLineKey;
      })
      .attr("data-value", d => {
        return d[leftLineKey];
      })
      .attr("cx", function(d) {
        return xScale(percentage(d[leftLineKey]));
      })
      .attr("cy", function(d) {
        return yScale(d[indexKey]) + margin.middle / 4;
      })
      .attr("r", this.circle.r)

      .style("opacity", d => {
        return 0;
      })
      .style("fill", d => {
        return this.style.rightBarColor;
      })
      .style("stroke", 0);

    nodes = this.dotInnerLeft.selectAll(".node");

    nodes
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("cx", function(d) {
        return xScale(percentage(d[leftLineKey]));
      })
      .attr("cy", function(d) {
        return yScale(d[indexKey]) + margin.middle / 4;
      })
      .attr("r", this.circle.r)
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", mouseoverNode)
          .on("mousemove", mousemoveNode)
          .on("mouseout", mouseoutNode)
          .on("click", null);
      });
  };
  public drawRightDotGroup = (): void => {
    let yScale = this.yScale;
    let xScale = this.xScale;
    let margin = this.margin;
    let rightLineKey = this.rightLineKey;
    let indexKey = this.indexKey;

    let percentage = this.percentage;
    const tDefault: number = this.tDefault;
    let mouseoverNode: any = this.mouseoverNode;
    let mouseoutNode: any = this.mouseoutNode;
    let mousemoveNode: any = this.mousemoveNode;

    // Create a group to store the 'nodes'
    this.dotInnerRight.attr("transform", this.translation(this.rightBegin, 0));

    this.dotInnerRight
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");

    // Join the data
    let nodes: any = this.dotInnerRight
      .selectAll("g.nodes")
      .selectAll(".node")
      .data(this.dataChart, d => {
        return d[rightLineKey];
      });

    // Exit the nodes
    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("r", 0)
      .remove();

    nodes
      .enter()
      .append("svg:circle")
      .attr("class", d => {
        return "node circle left " + rightLineKey;
      })
      .attr("data-name", d => {
        return rightLineKey;
      })
      .attr("data-value", d => {
        return d[rightLineKey];
      })
      .attr("cx", function(d) {
        return xScale(percentage(d[rightLineKey]));
      })
      .attr("cy", function(d) {
        return yScale(d[indexKey]) + margin.middle / 4;
      })
      .attr("r", this.circle.r)

      .style("opacity", d => {
        return 0;
      })
      .style("fill", d => {
        return this.style.leftBarColor;
      })
      .style("stroke", 0);

    nodes = this.dotInnerRight.selectAll(".node");

    nodes
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("cx", function(d) {
        return xScale(percentage(d[rightLineKey]));
      })
      .attr("cy", function(d) {
        return yScale(d[indexKey]) + margin.middle / 4;
      })
      .attr("r", this.circle.r)
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", mouseoverNode)
          .on("mouseout", mouseoutNode)
          .on("mousemove", mousemoveNode)

          .on("click", null);
      });
  };

  public drawRightBarGroup = (): void => {
    let yScale = this.yScale;
    let xScale = this.xScale;
    let margin = this.margin;
    let indexKey = this.indexKey;
    let rightBarkey = this.rightBarKey;
    let percentage = this.percentage;
    const tDefault: number = this.tDefault;
    let mouseoverNode: any = this.mouseoverNode;
    let mouseoutNode: any = this.mouseoutNode;
    let mousemoveNode: any = this.mousemoveNode;

    // Create a group to store the 'nodes'
    this.barInnerRight.attr("transform", this.translation(this.rightBegin, 0));

    this.barInnerRight
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");

    // Join the data
    let nodes: any = this.barInnerRight
      .selectAll("g.nodes")
      .selectAll(".node")
      .data(this.dataChart, d => {
        return d[this.rightBarKey];
      });

    // Exit the nodes
    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("width", 0)
      .attr("x", 0)
      .remove();

    // Enter the new nodes
    nodes
      .enter()
      .append("svg:rect")
      .attr("class", d => {
        return "node right " + this.rightBarKey;
      })
      .attr("data-name", d => {
        return this.rightBarKey;
      })
      .attr("data-value", d => {
        return d[this.rightBarKey];
      })
      .attr("x", 0)
      .attr("y", function(d) {
        return yScale(d[indexKey]) + margin.middle / 16;
      })
      .attr("width", 0)
      .attr(
        "height",
        yScale.range()[0] / this.dataChart.length - margin.middle / 8
      )
      .style("opacity", d => {
        return 1;
      })
      .style("fill", d => {
        return this.style.rightBarColor;
      })
      .style("stroke", 0);

    nodes = this.barInnerRight.selectAll(".node");

    // Apply transition
    nodes
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", 0)
      .attr("y", function(d) {
        return yScale(d[indexKey]) + margin.middle / 16;
      })
      .attr(
        "height",
        yScale.range()[0] / this.dataChart.length - margin.middle / 8
      )
      .attr("width", function(d) {
        return xScale(percentage(d[rightBarkey]));
      })
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", mouseoverNode)
          .on("mouseout", mouseoutNode)
          .on("mousemove", mousemoveNode)
          .on("click", null);
      });
  };

  public drawLefBarGroup = (): void => {
    let yScale = this.yScale;
    let xScale = this.xScale;
    let margin = this.margin;
    let indexKey = this.indexKey;
    let leftBarKey = this.leftBarKey;
    let percentage = this.percentage;
    const tDefault: number = this.tDefault;
    let mouseoverNode: any = this.mouseoverNode;
    let mouseoutNode: any = this.mouseoutNode;
    let mousemoveNode: any = this.mousemoveNode;

    // Create a group to store the 'nodes'
    this.barInnerLeft.attr(
      "transform",
      this.translation(this.leftBegin, 0) + "scale(-1,1)"
    );

    this.barInnerLeft
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");

    // Join the data
    let nodes: any = this.barInnerLeft
      .selectAll("g.nodes")
      .selectAll(".node")
      .data(this.dataChart, d => {
        return d[this.leftBarKey];
      });

    // Exit the nodes
    nodes
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("width", 0)
      .attr("x", 0)
      .remove();

    // Enter the new nodes
    nodes
      .enter()
      .append("svg:rect")
      .attr("class", d => {
        return "node left " + this.leftBarKey;
      })
      .attr("data-name", d => {
        return this.leftBarKey;
      })
      .attr("data-value", d => {
        return d[this.leftBarKey];
      })
      .attr("x", 0)
      .attr("y", function(d) {
        return yScale(d[indexKey]) + margin.middle / 16;
      })
      .attr("width", 0)
      .attr(
        "height",
        yScale.range()[0] / this.dataChart.length - margin.middle / 8
      )
      .style("opacity", d => {
        return 1;
      })
      .style("fill", d => {
        return this.style.leftBarColor;
      })
      .style("stroke", 0);

    nodes = this.barInnerLeft.selectAll(".node");

    // Apply transition
    nodes
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", 0)
      .attr("y", function(d) {
        return yScale(d[indexKey]) + margin.middle / 16;
      })
      .attr(
        "height",
        yScale.range()[0] / this.dataChart.length - margin.middle / 8
      )
      .attr("width", function(d) {
        return xScale(percentage(d[leftBarKey]));
      })
      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", mouseoverNode)
          .on("mouseout", mouseoutNode)
          .on("mousemove", mousemoveNode)

          .on("click", null);
      });
  };

  public drawAxis = (): void => {
    // Call axis
    this.chartWrapper
      .selectAll(".x.axis.left")
      .attr("transform", this.translation(0, this.h))
      .transition()
      .duration(this.tDefault)
      .call(this.xAxisLeft);

    this.chartWrapper
      .selectAll(".x.axis.right")
      .attr("transform", this.translation(this.rightBegin, this.h))
      .transition()
      .duration(this.tDefault)
      .call(this.xAxisRight);

    this.chartWrapper
      .selectAll(".y.axis.left")
      .attr("transform", this.translation(this.leftBegin, 0))
      .transition()
      .duration(this.tDefault)
      .call(this.yAxisLeft)
      .selectAll("text")
      .style("text-anchor", "middle");

    this.chartWrapper
      .selectAll(".y.axis.right")
      .attr("transform", this.translation(this.rightBegin, 0))
      .transition()
      .duration(this.tDefault)
      .call(this.yAxisRight);
  };
  public drawBarLegend = (): void => {
    const tDefault: number = this.tDefault;
    let margin = this.margin;

    this.chartLegend
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");
    // Join the data
    let legendRightRect: any = this.chartLegend
      .selectAll("g.nodes")
      .selectAll("rect.legend.right")
      .data([this.legend.bars[0]]);

    // Exit the nodes
    legendRightRect
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("width", 0)
      .attr("x", 0)
      .remove();

    // Enter the new nodes
    legendRightRect
      .enter()
      .append("svg:rect")
      .attr("class", d => {
        return "legend right " + this.rightBarKey;
      })
      .attr("x", this.rightBegin + this.margin.middle / 2)
      .attr("y", 0)
      .attr("width", 8)
      .attr("height", 8)
      .style("opacity", d => {
        return 1;
      })
      .style("fill", d => {
        return this.style.rightBarColor;
      })
      .style("stroke", 0);

    legendRightRect = this.chartLegend.selectAll("rect.legend.right");

    // Apply transition
    legendRightRect
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", this.rightBegin + this.margin.middle / 2)
      .attr("y", 0)
      .attr("width", 8)
      .attr("height", 8)

      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);
      });

    // we are HERE
    let legendLeftRect: any = this.chartLegend
      .selectAll("g.nodes")
      .selectAll("rect.legend.left")
      .data([this.legend.bars[1]]);

    // Exit the nodes
    legendLeftRect
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("width", 0)
      .attr("x", 0)
      .remove();

    // Enter the new nodes
    legendLeftRect
      .enter()
      .append("svg:rect")
      .attr("class", d => {
        return "legend left " + this.leftBarKey;
      })
      .attr("x", this.width / 2 - this.margin.middle * 3 + 10)
      .attr("y", 0)
      .attr("width", 8)
      .attr("height", 8)
      .style("opacity", d => {
        return 1;
      })
      .style("fill", d => {
        return this.style.leftBarColor;
      })
      .style("stroke", 0);

    legendLeftRect = this.chartLegend.selectAll("rect.legend.left");

    // Apply transition
    legendLeftRect
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", this.width / 2 - this.margin.middle * 3 + 10)
      .attr("y", 0)
      .attr("width", 8)
      .attr("height", 8)

      .style("opacity", d => {
        return 1;
      })
      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);
      });

    let legendLeftText: any = this.chartLegend
      .selectAll("g.nodes")
      .selectAll("text.legend.left")
      .data([this.legend.bars[1]]);

    legendLeftText
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("y", 0)
      .attr("x", 0)
      .remove();

    // Enter the new nodes
    legendLeftText
      .enter()
      .append("svg:text")
      .attr("class", d => {
        return "legend left " + this.leftBarKey;
      })
      .attr("x", d => {
        let distance = this.width / 2 - margin.middle * 2.8;
        return distance;
      })
      .attr("y", 6)
      .attr("dy", "0.2em")
      .style("fill", "#000")
      .style("text-anchor", "end")
      .text(this.legend.bars[1].value);

    legendLeftText = this.chartLegend.selectAll("text.legend.left");

    // Apply transition
    legendLeftText
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", this.width / 2 - this.margin.middle * 2.8)
      .attr("y", 6)
      .attr("dy", "0.2em")

      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);
      });

    // here

    let legendRightText: any = this.chartLegend
      .selectAll("g.nodes")
      .selectAll("text.legend.right")
      .data([this.legend.bars[0]]);

    legendRightText
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .attr("y", 6)
      .attr("x", this.rightBegin + margin.middle / 2 + 18)
      .remove();

    // Enter the new nodes
    legendRightText
      .enter()
      .append("svg:text")
      .attr("class", d => {
        return "legend right " + this.rightBarKey;
      })
      .attr("x", this.rightBegin + margin.middle / 2 + 18)
      .attr("y", 6)
      .attr("dy", "0.2em")
      .style("fill", "#000")
      .style("text-anchor", "start")
      .text(this.legend.bars[0].value);

    legendRightText = this.chartLegend.selectAll("text.legend.right");

    // Apply transition
    legendRightText
      .transition()
      .duration(tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", this.rightBegin + margin.middle / 2 + 18)
      .attr("y", 6)
      .attr("dy", "0.2em")

      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);
      });
  };

  public drawLineLegend = (): void => {
    const tDefault: number = this.tDefault;

    this.lineLegend
      .selectAll("g.nodes")
      .data([{}])
      .enter()
      .append("svg:g")
      .attr("class", "nodes");
    // Join the data
    let legendRect: any = this.lineLegend
      .selectAll("g.nodes")
      .selectAll("line.legend")
      .data([this.legend.lines[0]]);

    // Exit the nodes
    legendRect
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .remove();

    legendRect
      .enter()
      .append("svg:line")
      .attr("class", d => {
        return "legend line " + this.tooltipText.leftDot.area;
      })
      .attr("x1", 0)
      .attr("y1", 6)
      .attr("x2", 0)
      .attr("y2", 6)
      .attr("stroke-width", this.stroke.width + "%")
      .attr("stroke", this.style.rightLineColor);

    legendRect = this.lineLegend.selectAll("line.legend");

    // Apply transition
    legendRect
      .transition()
      .duration(this.tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x1", 0)
      .attr("y1", 6)
      .attr("x2", 32)
      .attr("y2", 6)

      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);
      });

    //here now
    let legendDot: any = this.lineLegend
      .selectAll("g.nodes")
      .selectAll("circle.legend")
      .data([this.legend.lines[0]]);

    // Exit the nodes
    legendDot
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .remove();

    legendDot
      .enter()
      .append("svg:circle")
      .attr("class", d => {
        return "legend circle " + this.tooltipText.leftDot.area;
      })
      .attr("cx", 18)
      .attr("cy", 6)
      .attr("r", this.circle.r)
      .style("fill", this.style.rightDotColor);

    legendDot = this.lineLegend.selectAll("circle.legend");

    // Apply transition
    legendDot
      .transition()
      .duration(this.tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("cx", 18)
      .attr("cy", 6)
      .attr("r", this.circle.r)

      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);
      });

    let legendText: any = this.lineLegend
      .selectAll("g.nodes")
      .selectAll("text.legend")
      .data([this.legend.lines[0]]);

    // Exit the nodes
    legendText
      .exit()
      .attr("class", "exit")
      .transition()
      .duration(this.tDefault / 2)
      .remove();

    legendText
      .enter()
      .append("svg:text")
      .attr("class", d => {
        return "legend text " + this.tooltipText.leftDot.area;
      })
      .attr("x", 0)
      .attr("y", 22)
      .attr("dy", "0.2em")
      .style("fill", "#000")
      .text("Media " + this.tooltipText.leftDot.area);

    legendText = this.lineLegend.selectAll("text.legend");

    // Apply transition
    legendText
      .transition()
      .duration(this.tDefault)
      .on("start", d => {
        d3.select(this)
          .on("mouseover", null)
          .on("mousemove", null)
          .on("mouseout", null)
          .on("click", null);
      })
      .attr("x", 0)
      .attr("y", 22)
      .attr("dy", "0.2em")

      .on("end", (d, i, arr) => {
        d3.select(arr[i])
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null)
          .on("click", null);

        // Finish render
        if (i == legendText.size() - 1)
          setTimeout(() => {
            this.D3READY = true;
          }, tDefault * 2);
      });
  };

  public drawTooltip = (): void => {
    this.tipDiv = this.vBody
      .append("div")
      .attr("class", "tooltip")
      .attr("id", this.tooltipID)
      .style("z-index", this.tooltipStyle.z_index)
      .style("font-size", this.tooltipStyle.font_size)
      .style("background-color", this.tooltipStyle.background_color)
      .style("padding", this.tooltipStyle.padding)
      .style("position", this.tooltipStyle.position)
      .style("opacity", 0); //  --> tooltip
  };

  public mousemoveNode = (d, i, r): void => {
    let name = d3.event.target.nodeName;
    let text: string = "";
    const selected: any = d3.select(d3.event.target);
    // MEDIA NACIONAL
    if (name === "circle") {
      // Hombre España
      if (selected.classed(this.leftLineKey)) {
        text +=
          "<strong>" +
          this.tooltipText.leftDot.area +
          " " +
          this.tooltipText.leftDot.index +
          " " +
          d[this.indexKey] +
          " " +
          this.tooltipText.leftDot.key +
          "</strong>" +
          "<br />" +
          this.FORMATROUND(
            Math.round(this.percentage(d[this.leftLineKey]) * 1000) / 10
          ) +
          "% " +
          this.tooltipText.leftDot.total;
      }
      // Mujer España

      if (selected.classed(this.rightLineKey)) {
        text +=
          "<strong>" +
          this.tooltipText.rightDot.area +
          " " +
          this.tooltipText.rightDot.index +
          " " +
          d[this.indexKey] +
          " " +
          this.tooltipText.rightDot.key +
          "</strong>" +
          "<br />" +
          this.FORMATROUND(
            Math.round(this.percentage(d[this.rightLineKey]) * 1000) / 10
          ) +
          "% " +
          this.tooltipText.rightDot.total;
      }
    }

    // ESTUDIOS
    if (name === "rect") {
      // Hombre Estudio
      if (selected.classed(this.leftBarKey)) {
        text +=
          "<strong>" +
          this.tooltipText.leftBar.area +
          " " +
          this.tooltipText.leftBar.index +
          " " +
          d[this.indexKey] +
          " " +
          this.tooltipText.leftBar.key +
          "</strong>" +
          "<br />" +
          this.FORMATROUND(
            Math.round(this.percentage(d[this.leftBarKey]) * 1000) / 10
          ) +
          "% " +
          this.tooltipText.leftBar.total;
      }

      // Mujer Estudio
      if (selected.classed(this.rightBarKey)) {
        text +=
          "<strong>" +
          this.tooltipText.rightBar.area +
          " " +
          this.tooltipText.rightBar.index +
          " " +
          d[this.indexKey] +
          " " +
          this.tooltipText.rightBar.key +
          "</strong>" +
          "<br />" +
          this.FORMATROUND(
            Math.round(this.percentage(d[this.rightBarKey]) * 1000) / 10
          ) +
          "% " +
          this.tooltipText.rightBar.total;
      }
    }

    //console.log("mousemoveNode", selected, r[i], name);

    this.tipDiv
      .html(text)
      .style("left", d3.event.pageX - 60 + "px")
      .style("top", d3.event.pageY - 60 + "px");
  };

  public mouseoverNode = (d, i, arr): void => {
    //console.log("mouseover", d, i, arr);
    //const selected: any = d3.select(d3.event.target);
    //console.log(this, d3.event.target, d3.mouse(d3.event.target));
    this.tipDiv
      .style("cursor", "pointer")
      .style("width", "auto")
      .style("height", "auto")
      .style("display", null)
      .style("opacity", 0.9);
  };

  public mouseoutNode = (): void => {
    this.tipDiv.style("opacity", 0).style("display", "none");
  };

  // HELPERS
  public percentage = d => {
    // console.log('percentage--> d', d/100);
    // 4 / 100 = 0.04
    return d / this.totalPopulation;
  };

  // string concat for translate
  public translation = (x, y) => {
    return "translate(" + x + "," + y + ")";
  };

  constructor(private d3MainService: D3MainService) {}

  ngOnChanges(changes: any) {
    console.log(this.params);

    let changeObj = Object.keys(changes);
    this.vBody = d3.select("body");
    this.tooltipID = this.params.tooltip.tooltip;
    this.tooltipText = this.params.tooltip.text;
    this.tooltipStyle = this.params.tooltip.style;
    this.FORMATROUND = this.d3MainService.FORMATROUND;
    this.COLORS = this.d3MainService.COLORS;
    this.tDefault = this.d3MainService.TIMESPAN;
    this.leftBarKey = this.params.design.keys.leftBar;
    this.rightBarKey = this.params.design.keys.rightBar;
    this.leftLineKey = this.params.design.keys.leftLine;
    this.rightLineKey = this.params.design.keys.rigthLine;
    this.indexKey = this.params.design.keys.index;
    this.legend = this.params.legend;
    this.delay = this.d3MainService.RESIZE_DELAY;

    //this.init();
  }

  ngOnInit() {
    console.log(this.params);
  }

  ngAfterViewInit() {
    this.init();
  }
}
