<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">
        <h1 class="h1-page--title">{{ title }}</h1>
        <p>{{ paragraph }}</p>
      </div>
    </div>
  </div>
</section>

<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
        <form [formGroup]="bricksStudyFormGroup" (ngSubmit)="onSubmit()">
          <mat-form-field class="full-width clearfix ">
            <mat-label>Nombre estudio</mat-label>
            <input matInput type="textbox" (keydown.enter)="$event.preventDefault()" [(value)]="study_name"
              formControlName="study_name" required />
          </mat-form-field>
          <mat-form-field class="full-width clearfix">
            <mat-label>Zona de estudio</mat-label>
            <mat-select [(value)]="provinciaSelected" formControlName="study_zone_id">
              <mat-option *ngFor="let p of provincias" [value]="p.key">
                {{ p.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- esta tabla aunque es dinamica para cargar las filas, no vale para eliminar opciones de los select posteriores.
       <table class="mat-elevation-z1 mcd_responsive_table "  formArrayName="studies">
        <caption></caption>
      <tbody> 
        <tr >
          <td class="text-align-center !important">
            Orden
          </td>
          <td class="text-align-center">
            Tipo inmueble
          </td>
          <td class="text-align-center">
            Ventas mínimas
          </td>
          <td class="text-align-center">
            Porcentaje impacto máximo
          </td>
          <td class="text-align-center">
            Porcentaje ventas ajenas máximo
          </td>
        </tr>
        <tr  *ngFor = " let row of bricksStudyFormGroup.get('studies')['controls']; let i = index;" [formGroupName]="i"> 
          <td class="text-align-center">
            <mat-form-field class="margin-top--xl " appearance="none">     
              <input matInput formControlName="order" readonly [value]="i+1">                   
            </mat-form-field>
          </td>
          <td class="text-align-center">
            <mat-form-field class="full-width clearfix margin-top--xl" appearance="none" id="{{ 'inmueble_' + i }}">
              <mat-select formControlName="inmueble" (selectionChange)="onChange($event.value, i)"  >
                <mat-option *ngFor="let ii of inmuebles " [value]="ii.key">
                  {{ ii.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td class="text-align-center">
            <mat-form-field class="full-width clearfix margin-top--xl" appearance="none">
                 
              <input
                matInput
                type="textbox"
                appTwoDigitDecimaNumber         
                (keydown.enter)="$event.preventDefault()"
                [(value)]="number_vta_minSelected"
                formControlName="vta_min"
                required
              />
            </mat-form-field>
          </td>
          <td class="text-align-center">
            <mat-form-field class="full-width clearfix margin-top--xl" appearance="none">
                 
              <input
                matInput
                type="textbox"   
                appTwoDigitDecimaNumber         
                (keydown.enter)="$event.preventDefault()"
                [(value)]="number_prct_vta_maxSelected"
                formControlName="prct_vta_max"
                required
              />
            </mat-form-field>
          </td>
          <td class="text-align-center">
            <mat-form-field class="full-width clearfix margin-top--xl" appearance="none">
               
              <input
                matInput
                type="textbox"
                appTwoDigitDecimaNumber
                (keydown.enter)="$event.preventDefault()"
                [(value)]="number_prct_impact_maxSelected"
                formControlName="prct_impact_max"
                required
              />
            </mat-form-field>
          </td>

        </tr>
      </tbody>
    </table>
  -->

          <table class="mat-elevation-z1 mcd_responsive_table flush-left" formArrayName="studies">
            <caption></caption>
            <tbody>
              <tr>
                <td class="text-align-center">
                  Orden
                </td>
                <td class="text-align-center">
                  Tipo inmueble
                </td>
                <td class="text-align-center">
                  Ventas mínimas
                </td>
                <td class="text-align-center">
                  Porcentaje impacto máximo
                </td>
                <td class="text-align-center">
                  Porcentaje ventas ajenas máximo
                </td>
              </tr>


              <tr>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="0">
                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="orderArray[0]" formControlName="order" required readonly />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="0">

                    <mat-select formControlName="inmueble" (selectionChange)="onChange($event.value,orderArray[0])">
                      <mat-option *ngFor="let i of inmuebles_1" [value]="i.key">
                        {{ i.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="0">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_vta_minSelected" formControlName="min_sales" required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="0">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_vta_maxSelected" formControlName="max_sales_from_restaurants_percent"
                      required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="0">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_impact_maxSelected" formControlName="max_restaurant_impact_percent"
                      required />
                  </mat-form-field>
                </td>

              </tr>
              <tr>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="1">
                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="orderArray[1]" formControlName="order" required readonly />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="1">

                    <mat-select formControlName="inmueble" (selectionChange)="onChange($event.value,orderArray[1])">
                      <mat-option *ngFor="let i of inmuebles_2" [value]="i.key">
                        {{ i.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="1">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_vta_minSelected" formControlName="min_sales" required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="1">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_vta_maxSelected" formControlName="max_sales_from_restaurants_percent"
                      required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="1">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_impact_maxSelected" formControlName="max_restaurant_impact_percent"
                      required />
                  </mat-form-field>
                </td>

              </tr>
              <tr>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="2">
                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="orderArray[2]" formControlName="order" required readonly />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="2">

                    <mat-select formControlName="inmueble" (selectionChange)="onChange($event.value, orderArray[2])">
                      <mat-option *ngFor="let i of inmuebles_3" [value]="i.key">
                        {{ i.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="2">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_vta_minSelected" formControlName="min_sales" required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="2">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_vta_maxSelected" formControlName="max_sales_from_restaurants_percent"
                      required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="2">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_impact_maxSelected" formControlName="max_restaurant_impact_percent"
                      required />
                  </mat-form-field>
                </td>

              </tr>
              <tr>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="3">
                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="orderArray[3]" formControlName="order" required readonly />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="3">

                    <mat-select formControlName="inmueble" (selectionChange)="onChange($event.value, orderArray[3])">
                      <mat-option *ngFor="let i of inmuebles_4" [value]="i.key">
                        {{ i.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="3">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_vta_minSelected" formControlName="min_sales" required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="3">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_vta_maxSelected" formControlName="max_sales_from_restaurants_percent"
                      required />
                  </mat-form-field>
                </td>
                <td class="text-align-center">
                  <mat-form-field class="full-width clearfix margin-top--xl" formGroupName="3">

                    <input matInput type="textbox" appTwoDigitDecimaNumber (keydown.enter)="$event.preventDefault()"
                      [(value)]="number_prct_impact_maxSelected" formControlName="max_restaurant_impact_percent"
                      required />
                  </mat-form-field>
                </td>

              </tr>


            </tbody>
          </table>




          <!-- error block -->
          <div class="invalid-feedback" style="color: orange;"
            *ngIf="bricksStudyFormGroup.get('studies')['controls'][0].errors?.age">
            <sup>*</sup>Debe seleccionar al menos un Tipo de restaurante y que esté unicado en el orden 1.
          </div>
          <div class="invalid-feedback" style="color: orange;" *ngIf="(bricksStudyFormGroup.get('studies')['controls'][1].errors?.nullArr || bricksStudyFormGroup.get('studies')['controls'][2].errors?.nullArr 
          || bricksStudyFormGroup.get('studies')['controls'][3].errors?.nullArr)">
            <sup>*</sup>No se pueden dejar filas intermedias sin seleccionar. Deben ser completadas en orden.
          </div>
          <div class="full-width clearfix margin-top--xl buttons" style="margin-bottom: 30px; ">
            <button mat-flat-button color="primary" type="submit" [disabled]="bricksStudyFormGroup.get('studies')['controls'][0].errors?.age || (bricksStudyFormGroup.get('studies')['controls'][1].errors?.nullArr || bricksStudyFormGroup.get('studies')['controls'][2].errors?.nullArr 
            || bricksStudyFormGroup.get('studies')['controls'][3].errors?.nullArr)">
              Calcular
            </button>
            <button mat-flat-button type="button" routerLink="/prescriptive/studies">
              Cancelar
            </button>
          </div>
        </form>
      </div>
      <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
        <!-- <img
          src="../../../assets/img//bricks/bricks-new-form.png"
          class="full-width clearfix"
        /> -->
      </div>
    </div>
  </div>
</section>

<div class="margin-top--xl"></div>