import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { MassiveBrickStudiesTableComponent } from "./massive-brick-studies-table.component";
import { DeleteDialogModule } from "../../../app-widgets/popup-modals/delete-dialog/delete-dialog.module";

import { GeoStudiesPrescriptiveDataService } from "../../../app-services/geo-studies-prescriptive-data.service";
import {EditDialogGroupViewersModule } from "../../../app-widgets/popup-modals/edit-dialog-group-viewers/edit-dialog-group-viewrs.module"

@NgModule({
  declarations: [MassiveBrickStudiesTableComponent],
  imports: [SharedModule, DeleteDialogModule, EditDialogGroupViewersModule],
  exports: [MassiveBrickStudiesTableComponent],
  providers: [GeoStudiesPrescriptiveDataService]
})
export class MassiveBrickStudiesTableModule {}
