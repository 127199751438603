import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { DataService } from "src/app/app-services/data.service";

export interface Comercio {
  nombre: string;
  calle: string;
  num: number;
  localidad: string;
  province: string;
  cp: number;
  properties: {
    info: {
      name: string;
      category: string;
      id_brand: string;
      brand: string;
    };
  };
}
@Component({
  selector: "app-tab-comercios",
  templateUrl: "./tab-comercios.component.html",
  styleUrls: ["./tab-comercios.component.scss"]
})
export class TabComerciosComponent implements OnInit, OnDestroy {
  checked = true;
  allCommercesChecked = false;
  displayedColumns: string[] = ["name"];
  comercios: any;//: Comercio[] = [];
  selected_brand: any;
  selected_category: any;
  dataSource  = new MatTableDataSource([]);// = new MatTableDataSource(this.comercios);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ensenyas = new FormControl();
  ensenyasList: any; //string[] = ["Todos"];
  sampleCategories: Array<any>;
  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  commerceSelected(elementId: string, geometry: string) {
    this.dataService.getZoomOnRestaurant(geometry);
  }

  selectAll(ev) {
    if (ev.checked) {
      /*this.dataService
        .getAllCommerce()
        .subscribe((result: any) => {
          this.comercios = result;
          this.sampleCategories = null;
          this.ensenyasList = null;
          this.dataService
            .getBrandsCategoriesCommerce()
            .subscribe((result: any) => {
              this.comercios = result;
             

              result = result.map(function(e) {
                if (e.hasOwnProperty("brands")) {
                  delete e["brands"];
                }
                return e;
              });
              this.ensenyasList = result;
              this.ensenyas.setValue([""]);
            });
            
            this.comercios = this.comercios.map(element => {
              //return element;
              element.properties.info.id = element.properties.ID_;
              element.properties.info.geometry = element.geometry;
              return element.properties.info;
            });

          this.dataSource = new MatTableDataSource(this.comercios);
          this.dataService.getFilteredCommerces(this.dataSource.filteredData);
        });*/

        this.comercios = this.dataService.getAllCommmercesShow();
        this.dataSource = new MatTableDataSource(this.comercios);
        this.dataService.getFilteredCommerces(this.dataSource.filteredData);

    }
    if (ev.checked == false) {
      this.dataSource = new MatTableDataSource();
      this.sampleCategories = null;
      this.ensenyasList;
      this.dataService.getFilteredCommerces(this.dataSource.filteredData);
    }
  }

  filterBrand(filterValue: any) {
    this.allCommercesChecked = false;
    this.dataSource = new MatTableDataSource();
    this.dataService.getFilteredCommerces(this.dataSource.filteredData);
    this.selected_category = filterValue;
    this.unsubscribe = this.dataService
      .getBrandsByCategoriesCommerce(filterValue)
      .subscribe((result: any) => {
        this.sampleCategories = result;
      });
  }
  filterCommerce(filterValue: any) {
    this.selected_brand = "";
    this.allCommercesChecked = false;
    filterValue.forEach(element => {
      this.selected_brand = element + ";" + this.selected_brand;
    });
    this.selected_brand = this.selected_brand.substring(
      0,
      this.selected_brand.length - 1
    );

    this.unsubscribe2 = this.dataService
      .getCompetitorsByBrands(this.selected_brand, this.selected_category)
      .subscribe((result: any) => {
        result;
        this.comercios = result;
        this.comercios = this.comercios.map(element => {
          //return element;
          element.properties.info.id = element.properties.ID_;
          element.properties.info.geometry = element.geometry;
          return element.properties.info;
        });
        this.dataSource = new MatTableDataSource(this.comercios);
        this.dataService.getFilteredCommerces(this.dataSource.filteredData);
        this.dataSource.paginator = this.paginator;
      });
  }

  constructor(private dataService: DataService) {
    this.unsubscribe3 = this.dataService
      .getBrandsCategoriesCommerce()
      .subscribe((result: any) => {
        this.comercios = result;
        result = result.map(function(e) {
          if (e.hasOwnProperty("brands")) {
            delete e["brands"];
          }
          return e;
        });
        this.ensenyasList = result;
      });

    this.dataSource = new MatTableDataSource(this.comercios);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
  }
}
