import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
//import "leaflet/dist/images/marker-shadow.png";
import leaflet from 'leaflet';
import * as L from "leaflet";
import 'leaflet.markercluster';
import { LocationService } from "../../../app-services/location.service";
import { DataService } from "../../../app-services/data.service";
import { GeoStudiesDataService } from "../../../app-services/geo-studies-data.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { GeoStudiesPrescriptiveDataService } from "../../../app-services/geo-studies-prescriptive-data.service";
import { Input } from "@angular/core";

@Component({
  selector: "app-mcdGeoMaker",
  templateUrl: "./mcdGeoMaker.component.html",
  styleUrls: ["./mcdGeoMaker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class McdGeoMakerComponent implements OnInit, OnDestroy {
  @Input()
  mapId: string;

  descriptive_map: any;
  geoJsonAnswer: any;
  icon_marker_subtype;
  private static readonly token =
   // "pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw";
   "pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg";
  layer_marker: any;
  layer_marker_others: any;
  layer_mcd: any;
  layer_mcd_closed: any;
  searchBoxSub: any;
  layerSearchBox: any;
  layer_brick: any;
  icon_markerStudy;
  layer_mcdStudy: any;
  layer_mcdStudy_presc: any;
  layer_marker_commerces: any;
  layer_marker_malls: any;
  isPredictive: boolean = false;
  isPrescriptive: boolean = false;

  isMassivePrescriptive: boolean = false;
  
  access_token: string = this.dataService.getMapBoxToken()[0].value;
  layer_mcd_delivery: any;
  layer_mcd_influencia: any;
  layer_mcdRestaurant_influencia: any;

  competenciasOthers: boolean = false;
  clusterGroup: any;
  clusterGroupOth: any;
  clusterGroupCommerces: any;

  data_click_b: any;
  layer_bricks_prescriptive: any;
  layer_ptos: any;
  min_vta: any;
  max_vta: any;
  array_rangos: any;

  layer_mcd_prox_apert: any;
  layer_mcd_plan5: any;

  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;
  unsubscribe4: any;
  unsubscribe5: any;
  unsubscribe6: any;
  unsubscribe7: any;
  unsubscribe8: any;
  unsubscribe9: any;
  unsubscribe10: any;
  unsubscribe11: any;
  unsubscribe12: any;
  unsubscribe13: any;
  unsubscribe14: any;
  unsubscribe15: any;
  unsubscribe16: any;
  unsubscribe17: any;
  unsubscribe18: any;
  unsubscribe19: any;
  unsubscribe20: any;
  unsubscribe21: any;
  unsubscribe22: any;
  popUpContent: any;

  constructor(
    private dataService: DataService,
    private locationService: LocationService,
    private geoStudiesDataService: GeoStudiesDataService,
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService,
    private route: ActivatedRoute,
    private routep: ActivatedRoute,
    private routepmassive: ActivatedRoute,
    private router: Router
  ) {
    this.unsubscribe = this.geoStudiesDataService.studyMapPredictiveChanged$.subscribe(
      data => {
        this.isPredictive = data;
      }
    );
    this.unsubscribe = this.geoStudiesPrescriptiveDataService.studyMapPrescriptiveChanged$.subscribe(
      data => {
        this.isPrescriptive = data;
      }
    );

    this.unsubscribe = this.geoStudiesPrescriptiveDataService.studyMapPrescriptiveMassiveChanged$.subscribe(
      data => {
        this.isMassivePrescriptive = data;
      }
    );
    

    this.unsubscribe2 = this.searchBoxSub = this.dataService.searchboxDescriptiveChanged$.subscribe(
      address => {
        if (address == "") {
          if (this.descriptive_map.hasLayer(this.layerSearchBox)) {
            try {
              this.descriptive_map.removeLayer(this.layerSearchBox);
            } catch (error) { }
          }
        } else {
          this.unsubscribe3 = this.locationService
            .geocodificarDireccion(address)
            .subscribe((respuesta: any) => {
              let dataResult = this.locationService.buscarDireccion(respuesta);
              this.descriptive_map.setView([dataResult.y, dataResult.x], 18);
              try {
                this.descriptive_map.removeLayer(this.layerSearchBox);
              } catch (error) { }
              let markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                icon: L.icon({
                  iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                  shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png"
                })
              });

              this.layerSearchBox = L.layerGroup([markerSearchBox]);
              this.descriptive_map.addLayer(this.layerSearchBox);
            });
        }
      }
    );

    this.unsubscribe4 = this.dataService.restaurantCheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcd)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcd);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcd);
          } catch (error) { }
        }
      }
    );

    this.unsubscribe5 = this.dataService.restaurantClosedCheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcd_closed)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcd_closed);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcd_closed);
          } catch (error) { }
        }
      }
    );

     this.dataService.restaurantPlan5CheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcd_plan5)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcd_plan5);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcd_plan5);
          } catch (error) { }
        }
      }
    );
     this.dataService.restaurantProxApertCheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcd_prox_apert)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcd_prox_apert);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcd_prox_apert);
          } catch (error) { }
        }
      }
    );

    this.dataService.deliveryCheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcd_delivery)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcd_delivery);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcd_delivery);
          } catch (error) { }
        }
      }
    );

    this.dataService.influenceCheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcd_influencia)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcd_influencia);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcd_influencia);
          } catch (error) { }
        }
      }
    );

    this.dataService.influenceRestaurantCheckDescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcdRestaurant_influencia)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcdRestaurant_influencia);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcdRestaurant_influencia);
          } catch (error) { }
        }
      }
    );

    this.dataService.restaurantSelectedInfluenceDescriptiveChanged$.subscribe(
      r => {
        this.layer_mcdRestaurant_influencia = L.geoJSON();
        this.layer_mcdRestaurant_influencia.addData(r);
        this.layer_mcdRestaurant_influencia.setStyle({ color: "#DA4665", fillOpacity: 0.01 });

      }
    );

    this.dataService.bricksStudyPrescriptiveChanged$.subscribe(
      data => {
        const _this = this;
        if (this.descriptive_map.hasLayer(this.layer_bricks_prescriptive)) {
          try {
            this.descriptive_map.removeLayer(this.layer_bricks_prescriptive);
            this.descriptive_map.removeLayer(this.layer_ptos);
          } catch (error) { }
        }
        if (data) {
          let arr_vta = [];
          data.forEach(e => {
            let data_vta = e.properties.sales_info.first_year_sales
            arr_vta.push(data_vta);
          });
          arr_vta.sort();
          var len = arr_vta.length;
          var per40 = Math.floor(len * 6) - 1;
          this.min_vta = arr_vta[0];
          this.max_vta = arr_vta[arr_vta.length - 1]
          //calculo 5 rangos:
          this.array_rangos = [];
          let quin = this.max_vta - this.min_vta;
          let vq = quin / 6;
          let v1 = vq + (vq + this.min_vta);
          let v2 = vq + (v1 + 1);
          let v3 = vq + (v2 + 1);
          let v4 = vq + (v3 + 1);
          let v5 = vq + (v4 + 1);
          let v6 = vq + (v5 + 1);
          this.array_rangos.push(this.min_vta, v1, v2, v3, v4, v5, v6);

          this.layer_bricks_prescriptive = L.geoJSON(data, {
            style: function (feature) {
              return _this.styleBrick(feature);
            },
            onEachFeature: function (feature, layer) {
              _this.onEachFeatureBricks(feature, layer);

            }
          });
          // layer bolas
          //sacamos geojson con centroides para la capa de circulos:
          let json_ptos = data.map(function(feat) {
            return {
              type: 'Feature',
              properties: feat.properties,
              geometry: feat.properties.centroid
              }
          });

          this.layer_ptos = L.geoJSON(json_ptos,{
              style:(feature): any =>{
                return {
                  fillColor: this.getColor(feature.properties.sales_info.first_year_sales),
                  weight: 2,
                  opacity: 0.7,
                  color: 'gray',
                  dashArray: '0',
                  fillOpacity: 0.7
                };
              },
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, );
              },
              onEachFeature: function(feature, layer) {
                  var label_building_type ='';
                  if(feature.properties.building_type.includes('Fs')){
                    label_building_type = 'FS'
                  }else if(feature.properties.building_type.includes('Ins')){
                    label_building_type = 'In'
                  }        
                  layer.bindTooltip(label_building_type, {direction: "center", permanent: true, className: 'labelMarkerOptions'});       
            }
          }); 


          try {
            this.descriptive_map.addLayer(this.layer_bricks_prescriptive);
            this.descriptive_map.addLayer(this.layer_ptos);
          } catch (error) { }
        }

      }
    );

    this.unsubscribe4 = this.dataService.studyPredictiveCheckChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcdStudy)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcdStudy);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcdStudy);
          } catch (error) { }
        }
      }
    );

    
    this.geoStudiesPrescriptiveDataService.bricksStudyFilteredChanged$.subscribe(
      data => {
        const _this = this;
        if (this.descriptive_map.hasLayer(this.layer_bricks_prescriptive)) {
          try {
            this.descriptive_map.removeLayer(this.layer_bricks_prescriptive);
            this.descriptive_map.removeLayer(this.layer_ptos);
          } catch (error) { }
        }
        if (data) {
          let arr_vta = [];
          data.forEach(e => {
            let data_vta = e.properties.sales_info.first_year_sales
            arr_vta.push(data_vta);
          });
          arr_vta.sort();
          var len = arr_vta.length;
          var per40 = Math.floor(len * 6) - 1;
          this.min_vta = arr_vta[0];
          this.max_vta = arr_vta[arr_vta.length - 1]
          //calculo 5 rangos:
          this.array_rangos = [];
          let quin = this.max_vta - this.min_vta;
          let vq = quin / 6;
          let v1 = vq + (vq + this.min_vta);
          let v2 = vq + (v1 + 1);
          let v3 = vq + (v2 + 1);
          let v4 = vq + (v3 + 1);
          let v5 = vq + (v4 + 1);
          let v6 = vq + (v5 + 1);
          this.array_rangos.push(this.min_vta, v1, v2, v3, v4, v5, v6);

          this.layer_bricks_prescriptive = L.geoJSON(data, {
            style: function (feature) {
              return _this.styleBrick(feature);
            },
            onEachFeature: function (feature, layer) {
              _this.onEachFeatureBricks(feature, layer);

            }
          });

          // layer bolas
          //sacamos geojson con centroides para la capa de circulos:
          let json_ptos = data.map(function(feat) {
            return {
              type: 'Feature',
              properties: feat.properties,
              geometry: feat.properties.centroid
          }
          });
          this.layer_ptos = L.geoJSON(json_ptos,{
              style:(feature): any =>{
                return {
                  fillColor: this.getColor(feature.properties.sales_info.first_year_sales),
                  weight: 2,
                  opacity: 0.7,
                  color: 'gray',
                  dashArray: '0',
                  fillOpacity: 0.7
                };
              },
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, );
              },
              onEachFeature: function(feature, layer) {
                  var label_building_type ='';
                  if(feature.properties.building_type.includes('Fs')){
                    label_building_type = 'FS'
                  }else if(feature.properties.building_type.includes('Ins')){
                    label_building_type = 'In'
                  }        
                  layer.bindTooltip(label_building_type, {direction: "center", permanent: true, className: 'labelMarkerOptions'});       
                }     
          });

          try {
            this.descriptive_map.addLayer(this.layer_bricks_prescriptive);
            this.descriptive_map.addLayer(this.layer_ptos);
          } catch (error) { }
        }

      }
    );

    this.geoStudiesPrescriptiveDataService.allStudiesCheckPrescriptiveChanged$.subscribe(
      isChecked => {
        if (isChecked == true) {
          if (!this.descriptive_map.hasLayer(this.layer_mcdStudy_presc)) {
            try {
              this.descriptive_map.addLayer(this.layer_mcdStudy_presc);
            } catch (error) { }
          }
        } else {
          try {
            this.descriptive_map.removeLayer(this.layer_mcdStudy_presc);
          } catch (error) { }
        }
      }
    );


    this.unsubscribe12 = this.dataService.restaurantFilteredDescriptiveChanged$.subscribe(
      result => {
        this.pintarMcd(result, "n");
      }
    );

    this.unsubscribe12 = this.dataService.studyPredictiveFilteredDescriptiveChanged$.subscribe(
      result => {
        this.pintarEstudios(result);
      }
    );

    this.unsubscribe12 = this.geoStudiesPrescriptiveDataService.studyPrescriptiveFilteredChanged$.subscribe(
      result => {
        //this.pintarEstudiosPrescriptivo(result);
      }
    );    

  }

  triggerToggleSideNavs: any = () => {
    this.dataService.serviceToggleSideNavs({
      SidenavStartLeftState: false,
      SidenavEndRightState: true
    });
  };

  triggerToggleSideNavsPresc: any = () => {
    this.dataService.serviceToggleSideNavs({
      SidenavStartLeftState: false,
      SidenavEndRightState: true
    });
  };

  getColor = (d: any) => {
    this.min_vta;
    this.max_vta;
    this.array_rangos;
    if (this.array_rangos) {
      return d >= this.array_rangos[6] ? '#800026' :
        d > this.array_rangos[5] ? '#BD0026' :
          d > this.array_rangos[4] ? '#E31A1C' :
            d > this.array_rangos[3] ? '#FC4E2A' :
              d > this.array_rangos[2] ? '#FD8D3C' :
                d > this.array_rangos[1] ? '#FEB24C' :
                  d > this.array_rangos[0] ? '#FED976' :
                    '#FFEDA0';
    }

  }


  styleBrick = (feature: any) => {
    return {
      fillColor: this.getColor(feature.properties.sales_info.first_year_sales),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    };
  };

  highlightFeature = (e: any) => {
    var layer = e.target;
    layer.setStyle({
      weight: 3,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight = (e: any) => {
    if (e.target) {
      this.layer_bricks_prescriptive.resetStyle(e.target);
    }
  }

  zoomToFeature = (e: any) => {
    this.descriptive_map.fitBounds(e.target.getBounds());
    this.zoomBrickPrescriptive(e.target.feature)

  }

  onEachFeatureBricks = (feature: any, layer: any) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
      click: this.zoomToFeature
    });
  }


  checkUrl = (url: any) => {
    try {
      let reader = new XMLHttpRequest();
      reader.open("GET", url, false);
      reader.send();
      if (reader.status == 404) {
        return false;
      } else {
        return true;
      }
    } catch (error) { }
  };

  myPreventDefault = (e: any) => {
    let id_brick = e.layer.feature.properties.ID_int;
    this.router.navigateByUrl("/predictive/new-form/" + id_brick);
  };

  zoomMap = (objectZoom: any) => {
   
    var currentZoom = this.descriptive_map.getZoom();
    var x= 65; //Update x 
      var y= 65; //Update Y   65, 65   
      if(currentZoom<9){
        var leafIcon = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker.svg",
          iconSize: [x/3, y/3],
          iconAnchor: [25/3, 40/3],
          popupAnchor: [7, -45]
        });
  
        this.layer_mcd.eachLayer(function(layer) {
          layer.setIcon(leafIcon);
        });


        

        var leafIconCerrado = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker-closed.svg",
          iconSize: [x/3, y/3],
          iconAnchor: [25/3, 40/3],
          popupAnchor: [7, -45]
        });
        var leafIconPA = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_purple.svg",
          iconSize: [x/3, y/3],
          iconAnchor: [25/3, 40/3],
          popupAnchor: [7, -45]
        });
        var leafIconP5 = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_blue.svg",
          iconSize: [x/3, y/3],
          iconAnchor: [25/3, 40/3],
          popupAnchor: [7, -45]
        });

        
        this.layer_mcd_closed.eachLayer(function(layer) {
          layer.setIcon(leafIconCerrado);
        });
        this.layer_mcd_prox_apert.eachLayer(function(layer) {
          layer.setIcon(leafIconPA);
        });
        this.layer_mcd_plan5.eachLayer(function(layer) {
          layer.setIcon(leafIconP5);
        });


        
      }else if(currentZoom<16){
        var leafIcon = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker.svg",
          iconSize: [x/2, y/2],
          iconAnchor: [25/2, 40/2],
          popupAnchor: [7, -45]
        });
  
        if(this.layer_mcd){
          this.layer_mcd.eachLayer(function(layer) {
            layer.setIcon(leafIcon);
          });
        }    

        
        var leafIconCerrado = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker-closed.svg",
          iconSize: [x/2, y/2],
          iconAnchor: [25/2, 40/2],
          popupAnchor: [7, -45]
        });
        var leafIconPA = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_purple.svg",
          iconSize: [x/2, y/2],
          iconAnchor: [25/2, 40/2],
          popupAnchor: [7, -45]
        });
        var leafIconP5 = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_blue.svg",
          iconSize: [x/2, y/2],
          iconAnchor: [25/2, 40/2],
          popupAnchor: [7, -45]
        });

        if(this.layer_mcd_closed){
          this.layer_mcd_closed.eachLayer(function(layer) {
            layer.setIcon(leafIconCerrado);
          });
        }        
        if(this.layer_mcd_prox_apert){
          this.layer_mcd_prox_apert.eachLayer(function(layer) {
            layer.setIcon(leafIconPA);
          });
        }        
        if(this.layer_mcd_plan5){
          this.layer_mcd_plan5.eachLayer(function(layer) {
            layer.setIcon(leafIconP5);
          });
        }        


      }else{
        var leafIcon = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker.svg",
          iconSize: [x, y],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
  
        if(this.layer_mcd){
          this.layer_mcd.eachLayer(function(layer) {
            layer.setIcon(leafIcon);
          });
        }        

        var leafIconCerrado = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker-closed.svg",
          iconSize: [x, y],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
        var leafIconPA = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_purple.svg",
          iconSize: [x, y],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
        var leafIconP5 = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_blue.svg",
          iconSize: [x, y],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });

        if(this.layer_mcd_closed){
          this.layer_mcd_closed.eachLayer(function(layer) {
            layer.setIcon(leafIconCerrado);
          });
        }        
        if(this.layer_mcd_prox_apert){
          this.layer_mcd_prox_apert.eachLayer(function(layer) {
            layer.setIcon(leafIconPA);
          });
        }        
        if(this.layer_mcd_plan5){
          this.layer_mcd_plan5.eachLayer(function(layer) {
            layer.setIcon(leafIconP5);
          });
        }        
      }
  }

  zoomCompetitor = (objectZoom: any) => {
    const _this = this;
    let bounds = _this.descriptive_map.getBounds();
    let latmin = bounds._northEast.lat;
    let lngmin = bounds._northEast.lng;
    let latmax = bounds._southWest.lat;
    let lngmax = bounds._southWest.lng;

    if(this.clusterGroup){
     /* this.clusterGroup.eachLayer(function(layer) { 
        this.clusterGroup.removeLayer(layer);
      });*/
      try {
        this.descriptive_map.removeLayer(this.clusterGroup);
      } catch (error) { }
      try {
        this.clusterGroup.removeAllFeatures();
      } catch (e) { }
    }
    
      try {
        this.descriptive_map.removeLayer(this.layer_marker);
      } catch (error) { }
      try {
        this.descriptive_map.removeLayer(this.clusterGroup);
      } catch (error) { }
      try {
        this.layer_marker.removeAllFeatures();
      } catch (e) { }
      try {
        this.clusterGroup.removeAllFeatures();
      } catch (e) { }

    _this.unsubscribe6 = _this.dataService
      .getBrandsByCoords(lngmin, latmin, lngmax, latmax)
      .subscribe((res: any) => {
        _this.dataService.setBrandsByCoords(res);
      });

    _this.dataService.setCoordsMap(lngmin, latmin, lngmax, latmax);

    let brands = _this.dataService.getBrandFilter();
    if (_this.descriptive_map.getZoom() <= 13) {
      if (_this.descriptive_map.hasLayer(_this.layer_marker)) {
        try {
          _this.descriptive_map.removeLayer(_this.layer_marker);
        } catch (error) { }
        try {
          _this.descriptive_map.removeLayer(_this.clusterGroup);
        } catch (error) { }
        try {
          _this.layer_marker.removeAllFeatures();
        } catch (e) { }
      }

      if (_this.descriptive_map.hasLayer(_this.clusterGroup)) {
        try {
          _this.descriptive_map.removeLayer(_this.clusterGroup);
        } catch (error) { }
        try {
          _this.clusterGroup.removeAllFeatures();
        } catch (e) { }
      }


      if (_this.descriptive_map.hasLayer(_this.layer_marker_others)) {
        try {
          _this.descriptive_map.removeLayer(_this.layer_marker_others);
        } catch (error) { }
        try {
          _this.layer_marker_others.removeAllFeatures();
        } catch (e) { }
      }
      if (_this.descriptive_map.hasLayer(_this.clusterGroupOth)) {
        try {
          _this.descriptive_map.removeLayer(_this.clusterGroupOth);
        } catch (error) { }
        try {
          _this.clusterGroupOth.removeAllFeatures();
        } catch (e) { }
      }

      //disable component competencia:
      _this.dataService.setDisabledCompetitor(true);
    } else {
      _this.dataService.setDisabledCompetitor(false);
      if (brands != undefined) {
        if (objectZoom.layer != undefined) {
          _this.descriptive_map.setView(
            [objectZoom.coordinates[1], objectZoom.coordinates[0]],
            18
          );
        } else {
          if (_this.descriptive_map.hasLayer(_this.layer_marker)) {
            try {
              _this.descriptive_map.removeLayer(_this.layer_marker);
            } catch (error) { }
            try {
              _this.layer_marker.removeAllFeatures();
            } catch (e) { }
          }
          if (_this.descriptive_map.hasLayer(_this.clusterGroup)) {
            try {
              _this.descriptive_map.removeLayer(_this.clusterGroup);
            } catch (error) { }
            try {
              _this.clusterGroup.removeAllFeatures();
            } catch (e) { }
          }
          _this.createLayerCompetitors(brands);
        }
      } else {
        if (_this.descriptive_map.hasLayer(_this.layer_marker)) {
          try {
            _this.descriptive_map.removeLayer(_this.layer_marker);
          } catch (error) { }
          try {
            _this.layer_marker.removeAllFeatures();
          } catch (e) { }
        }
        if (_this.descriptive_map.hasLayer(_this.clusterGroup)) {
          try {
            _this.descriptive_map.removeLayer(_this.clusterGroup);
          } catch (error) { }
          try {
            _this.clusterGroup.removeAllFeatures();
          } catch (e) { }
        }
        //load competitors data:
        let bounds = _this.descriptive_map.getBounds();
        let latmin = bounds._northEast.lat;
        let lngmin = bounds._northEast.lng;
        let latmax = bounds._southWest.lat;
        let lngmax = bounds._southWest.lng;
        _this.unsubscribe7 = _this.dataService
          .getBrandsByCoords(lngmin, latmin, lngmax, latmax)
          .subscribe((res: any) => {
            _this.dataService.setBrandsByCoords(res);
          });
        _this.dataService.setCoordsMap(lngmin, latmin, lngmax, latmax);
        _this.createLayerCompetitors(null);
      }



      if (this.competenciasOthers) {
        this.unsubscribe = this.dataService
          .getCompetitorsOthersByBrandsCoords(
            [""], lngmin, latmin, lngmax, latmax
          )
          .subscribe((result: any) => {
            result;
            let competenciasOthers1 = result;
            competenciasOthers1 = competenciasOthers1.map(element => {
              element.properties.info.id = element.properties.ID_;
              element.properties.info.geometry = element.geometry;
              return element.properties.info;
            });
            _this.dataService.getFilteredCompetitorsOthers(
              competenciasOthers1
            );
          });
      }
    }
  };
  zoomBrick = (objectBrick: any) => {
    if (this.descriptive_map.hasLayer(this.layer_brick)) {
      try {
        this.descriptive_map.removeLayer(this.layer_brick);
      } catch (error) { }
      try {
        this.layer_brick.removeAllFeatures();
      } catch (e) { }
    }
    let bounds = this.descriptive_map.getBounds();
    let latmin = bounds._northEast.lat;
    let lngmin = bounds._northEast.lng;
    let latmax = bounds._southWest.lat;
    let lngmax = bounds._southWest.lng;

    this.dataService.setCoordsMap(lngmin, latmin, lngmax, latmax);
    this.layer_brick = L.geoJSON(objectBrick, {
      onEachFeature: function (feature, layer) {
        layer
          .bindTooltip(
            "<p style='font-size:14px;color:black'><strong>Crear estudio en <br/>Brick: " +
            feature.properties.ID_int +
            "</strong></p><center>",
            {
              permanent: true,
              direction: "right",
              opacity: 0.8
            }
          ).openTooltip();
      }
    });

    this.layer_brick.on("click ", this.myPreventDefault);
    this.descriptive_map.addLayer(this.layer_brick);
  };

  zoomBrickPrescriptive = (objectBrick: any) => {
    const _this = this;
    if (this.descriptive_map.hasLayer(this.layer_brick)) {
      try {
        this.descriptive_map.removeLayer(this.layer_brick);
      } catch (error) { }
      try {
        this.layer_brick.removeAllFeatures();
      } catch (e) { }
    }
    let bounds = this.descriptive_map.getBounds();
    let latmin = bounds._northEast.lat;
    let lngmin = bounds._northEast.lng;
    let latmax = bounds._southWest.lat;
    let lngmax = bounds._southWest.lng;

    this.dataService.setCoordsMap(lngmin, latmin, lngmax, latmax);
    this.geoStudiesPrescriptiveDataService.setBrickDataPrescriptive(objectBrick.properties);
    this.layer_brick = L.geoJSON(objectBrick, {
      onEachFeature: function (feature, layer) {
        let brick_data = _this.geoStudiesPrescriptiveDataService.getBrickDataPrescriptive();
        let dataImpacto = "";
        if (brick_data.impact_info.length > 0) {
          brick_data.impact_info.forEach(e => {
            //filtramos los que tengan impacto superior a 2%
            if(e.total_site_impact.toFixed(2) >=2){
              dataImpacto += "&nbsp;&nbsp; * ID: " + e.site_id + " " + e.site_name + " : " + e.total_site_impact.toFixed(2) + "% <br/>";
            }
           
          });
        }
        let textPopUp = "<p style='font-size:16px;color:black'><center><button id='button-submit'  style='color=white !important;border: 2px solid #008CBA;'  class='goNewStudyPred'  type='button'><strong>Crear estudio Predictivo</strong></button><br/><br/><strong>Brick: " +
          brick_data.brick_id +
          "</strong></center></p><p> <strong> "+brick_data.building_type+"</p> </strong> <p> <strong>Venta estimada: </strong>" + Intl.NumberFormat('es-ES').format(brick_data.sales_info.first_year_sales) + " € <br/>" +
          "<strong>% Procedente otros Mcd: </strong>" + brick_data.sales_info.perc_of_sales_associated_to_existing_rest.toFixed(2) + " % <br/>" +
          "<strong>% Impacto:</strong> <br/>"
          + (dataImpacto ? dataImpacto : "No hay impactados" ) +
          "</p><center>";
        var popup: any;
        popup = L.popup({ closeOnClick: true, closeButton: true })
          .setLatLng(feature.properties.centroid.coordinates)
          .setContent(textPopUp);
        layer.bindPopup(popup).openPopup();

        layer.on("popupopen", (a) => {
          _this.data_click_b = a;
          var popUp = a.target.getPopup()
          popUp.getElement()
            .querySelector(".goNewStudyPred")
            .addEventListener("click", _this.clickButtonNewPred.bind(_this, _this.data_click_b), false);
        })

      }
    });
    this.descriptive_map.addLayer(this.layer_brick);
  };

  clickButtonNewPred(feature: any, layer: any) {
    let id_brick = feature.target.feature.properties.brick_id;
    this.router.navigateByUrl("/predictive/new-form/" + id_brick);
  }

  pintarMcd(result: any, from: any): any {
    if (this.descriptive_map.hasLayer(this.layer_mcd)) {
      try {
        this.descriptive_map.removeLayer(this.layer_mcd);
      } catch (error) { }
    }
    const _this = this;
    let all_markersMcd = [];
    let all_markersMcdClosed = [];
    let all_markersMcdProxApert = [];
    let all_markersMcdPlan5 = [];
    let all_delivery = [];
    let all_influencia = [];
    let status: any;
    for (let i = 0; i < result.length; i++) {
      if (from == "create") {
        status = result[i].properties.info.status;
      } else {
        status = result[i].status;
      }
      if (status == "Abierto") {
        this.icon_marker_subtype = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker.svg",
          iconSize: [65, 65],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
      } else if (status == "Cerrado") {
        this.icon_marker_subtype = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker-closed.svg",
          iconSize: [65, 65],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
      }
      else if(status == "Próxima apertura"){
        this.icon_marker_subtype = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_purple.svg",
          iconSize: [65, 65],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
      }else if(status == "Plan 5 años"){
        this.icon_marker_subtype = L.icon({
          iconUrl: environment.imgUrl + "/helpers/4772-marker_blue.svg",
          iconSize: [65, 65],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
      }

      let geoCoordinates = result[i].geometry.coordinates;
      var popup: any;
      if (status == "Abierto") {
        let markerMcd = L.marker([geoCoordinates[1], geoCoordinates[0]], {
          icon: this.icon_marker_subtype
        }).addTo(this.descriptive_map);
        markerMcd.on("click", function (e) {
          if (from == "create") {
            _this.dataService.setSelectedRestaurant(result[i].properties.ID_);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.id_mcd);
              markerMcd.bindPopup(popup);
            }
            if (_this.isPrescriptive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.id_mcd);
              markerMcd.bindPopup(popup);
            }
          } else {
            _this.dataService.setSelectedRestaurant(result[i].id);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
              markerMcd.bindPopup(popup);
            }
            if (_this.isPrescriptive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
              markerMcd.bindPopup(popup);
            }
          }
          _this.dataService.getSelectedRestaurant();
        });

        all_markersMcd.push(markerMcd);
      } else if (status == "Cerrado"){
        let markerMcdClosed = L.marker([geoCoordinates[1], geoCoordinates[0]], {
          icon: this.icon_marker_subtype
        });
        markerMcdClosed.on("click", function (e) {
          if (from == "create") {
            _this.dataService.setSelectedRestaurant(result[i].properties.ID_);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.ID_);
            }
          } else {
            _this.dataService.setSelectedRestaurant(result[i].id);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
            }
          }
          _this.dataService.getSelectedRestaurant();
        });
        all_markersMcdClosed.push(markerMcdClosed);
        markerMcdClosed.bindPopup(popup);
      }
      else if (status == "Próxima apertura"){
        let markerMcdProxApert = L.marker([geoCoordinates[1], geoCoordinates[0]], {
          icon: this.icon_marker_subtype
        });
        /*markerMcdProxApert.on("click", function (e) {
          if (from == "create") {
            _this.dataService.setSelectedRestaurant(result[i].properties.ID_);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.ID_);
            }
          } else {
            _this.dataService.setSelectedRestaurant(result[i].id);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
            }
          }
          _this.dataService.getSelectedRestaurant();
        });*/
        markerMcdProxApert.on("click", function (e) {
          if (from == "create") {
            _this.dataService.setSelectedRestaurant(result[i].properties.ID_);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.id_mcd);
                markerMcdProxApert.bindPopup(popup);
            }
            if (_this.isPrescriptive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.id_mcd);
                markerMcdProxApert.bindPopup(popup);
            }
          } else {
            _this.dataService.setSelectedRestaurant(result[i].id);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
                markerMcdProxApert.bindPopup(popup);
            }
            if (_this.isPrescriptive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
                markerMcdProxApert.bindPopup(popup);
            }
          }
          _this.dataService.getSelectedRestaurant();
        });
        all_markersMcdProxApert.push(markerMcdProxApert);
       // markerMcdProxApert.bindPopup(popup);
      } else if (status == "Plan 5 años"){
        let markerMcdPlan5 = L.marker([geoCoordinates[1], geoCoordinates[0]], {
          icon: this.icon_marker_subtype
        });
        /*markerMcdPlan5.on("click", function (e) {
          if (from == "create") {
            _this.dataService.setSelectedRestaurant(result[i].properties.ID_);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.ID_);
            }
          } else {
            _this.dataService.setSelectedRestaurant(result[i].id);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
            }
          }
          _this.dataService.getSelectedRestaurant();
        });
        */
        markerMcdPlan5.on("click", function (e) {
          if (from == "create") {
            _this.dataService.setSelectedRestaurant(result[i].properties.ID_);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.id_mcd);
                markerMcdPlan5.bindPopup(popup);
            }
            if (_this.isPrescriptive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("ID: " + result[i].properties.id_mcd);
                markerMcdPlan5.bindPopup(popup);
            }
          } else {
            _this.dataService.setSelectedRestaurant(result[i].id);
            if (_this.isPredictive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
                markerMcdPlan5.bindPopup(popup);
            }
            if (_this.isPrescriptive) {
              popup = L.popup()
                .setLatLng(geoCoordinates)
                .setContent("SITE: " + result[i].id);
                markerMcdPlan5.bindPopup(popup);
            }
          }
          _this.dataService.getSelectedRestaurant();
        });
        all_markersMcdPlan5.push(markerMcdPlan5);
       // markerMcdPlan5.bindPopup(popup);
      }
      if (result[i].properties.areas) {
        if (result[i].properties.areas.delivery) {
          let deliveryMcd = result[i].properties.areas.delivery;
          all_delivery.push(deliveryMcd);
        }

        if (result[i].properties.areas.influence) {
          let influenciaMcd = result[i].properties.areas.influence;
          all_influencia.push(influenciaMcd);
        }
      }


    }
    this.layer_mcd = L.featureGroup(all_markersMcd);
    this.layer_mcd_closed = L.featureGroup(all_markersMcdClosed);
    this.layer_mcd_prox_apert = L.featureGroup(all_markersMcdProxApert);
    this.layer_mcd_plan5 = L.featureGroup(all_markersMcdPlan5);
    this.descriptive_map.addLayer(this.layer_mcd);
    if (this.isPredictive || this.isPrescriptive) {
      this.layer_mcd.on("click", function (e) {
        this.openPopup();
      });
      this.layer_mcd_closed.on("click", function (e) {
        this.openPopup();
      });
      this.layer_mcd_prox_apert.on("click", function (e) {
        this.openPopup();
      });
      this.layer_mcd_plan5.on("click", function (e) {
        this.openPopup();
      });


    } else {
      this.layer_mcd.on("click", this.triggerToggleSideNavs);
      this.layer_mcd_closed.on("click", this.triggerToggleSideNavs);
      this.layer_mcd_prox_apert.on("click", this.triggerToggleSideNavs);
      this.layer_mcd_plan5.on("click", this.triggerToggleSideNavs);
    }

    this.layer_mcd_delivery = L.geoJSON();
    this.layer_mcd_delivery.addData(all_delivery);
    this.layer_mcd_delivery.setStyle({ color: "blue", fillOpacity: 0.01 });

    this.layer_mcd_influencia = L.geoJSON();
    this.layer_mcd_influencia.addData(all_influencia);
    this.layer_mcd_influencia.setStyle({ color: "green", fillOpacity: 0.01 });

    this.zoomMap(null);
  }
  createLayerMcd = () => {
    this.unsubscribe8 = this.dataService
      .getPlacesMcd()
      .subscribe((result: any) => {
        this.pintarMcd(result, "create");
      });
  };
  loadCommercesMap = () => {
    this.dataService
      .getAllCommerce()
      .subscribe((result1: any) => {
        let comercios = result1;
        //this.sampleCategories = null;
        let ensenyasList = null;
        this.dataService
          .getBrandsCategoriesCommerce()
          .subscribe((result2: any) => {
            comercios = result2;


            result2 = result2.map(function (e) {
              if (e.hasOwnProperty("brands")) {
                delete e["brands"];
              }
              return e;
            });
            ensenyasList = result2;
            //this.ensenyas.setValue([""]);
          });

        comercios = comercios.map(element => {
          //return element;
          element.properties.info.id = element.properties.ID_;
          element.properties.info.geometry = element.geometry;
          return element.properties.info;
        });
        this.dataService.setAllCommmercesShow(comercios);
        //this.dataSource = new MatTableDataSource(this.comercios);
        //this.dataService.getFilteredCommerces(this.dataSource.filteredData);
      });

  }
  createLayerCompetitors = brands => {
    let bounds = this.descriptive_map.getBounds();
    let latmin = bounds._northEast.lat;
    let lngmin = bounds._northEast.lng;
    let latmax = bounds._southWest.lat;
    let lngmax = bounds._southWest.lng;

    //remove layer this.descriptive_map.addLayer(this.clusterGroup);
    if (this.descriptive_map.hasLayer(this.clusterGroup)) {
      try {
        this.descriptive_map.removeLayer(this.clusterGroup);
        this.clusterGroup.removeAllFeatures();
      } catch (error) { }
    }

    if (this.descriptive_map.hasLayer(this.layer_marker)) {
      try {
        this.descriptive_map.removeLayer(this.layer_marker);
      } catch (error) { }
      try {
        this.layer_marker.removeAllFeatures();
      } catch (e) { }
    }

    if(brands==null){
    //no se pintan los competidores
    }else{
        if (brands || brands == "" ) {
          this.unsubscribe9 = this.dataService
            .getMarkersbyBrandsGeoJson(brands, lngmin, latmin, lngmax, latmax)
            .subscribe((res: any) => {
              this.geoJsonAnswer = res;
              this.clusterGroup =
                L.markerClusterGroup({
                  showCoverageOnHover: false,
                  zoomToBoundsOnClick: true,
                  spiderfyOnMaxZoom: true,
                  spiderLegPolylineOptions: { color: 'blue', weight: 2, opacity: 1 },
                  singleMarkerMode: false,
                  maxClusterRadius: 30,
                  iconCreateFunction: function (cluster) {
                    if (cluster.getChildCount() < 3) {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster',
                        iconSize: null
                      });
                    } else if (cluster.getChildCount() < 8) {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster_3',
                        iconSize: null
                      });
                    } else if (cluster.getChildCount() < 15) {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster_8',
                        iconSize: null
                      });
                    } else {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster_15',
                        iconSize: null
                      });
                    }
                  }
                });
              let all_markers = [];
              for (let i = 0; i < this.geoJsonAnswer.length; i++) {
                let idImgIcon = this.geoJsonAnswer[i].properties.info.id_brand;
                let urlIcon = environment.imgUrl + "/markers/" + idImgIcon + ".svg";

                var checkedurl = this.checkUrl(urlIcon);
                if (!checkedurl) {
                  urlIcon = environment.imgUrl + "/helpers/joker.svg";
                }

                if (idImgIcon != "4772") {
                  this.icon_marker_subtype = L.icon({
                    iconUrl: urlIcon,
                    shadowUrl:
                      environment.imgUrl + "/helpers/circle-white-border-XXL.svg",
                    iconSize: [30, 30],
                    shadowSize: [33, 33]
                  });
                }

                let geoCoordinates = this.geoJsonAnswer[i].geometry.coordinates;
                let idMarker = this.geoJsonAnswer[i].properties.id;
                let nameMarker = this.geoJsonAnswer[i].properties.info.name;
                let directionMarker = this.geoJsonAnswer[i].properties.info.address;
                let marker = L.marker([geoCoordinates[1], geoCoordinates[0]], {
                  icon: this.icon_marker_subtype
                });

                if (this.geoJsonAnswer[i].properties.info.id_brand == 4599) {
                  //BK
                  let type_local = this.geoJsonAnswer[i].properties.info.type_local=='I' ? 
                                          "<img src="+environment.imgServicesBK +"/instore.png  style='height:65px; width:65px; border:5px solid white'/>" 
                                          : (this.geoJsonAnswer[i].properties.info.type_local=='F' ? "<img src="+environment.imgServicesBK +"/freestanding.png  style='height:65px; width:65px; border:5px solid white'/>" :
                                          (this.geoJsonAnswer[i].properties.info.type_local=='M' ? "<img src="+environment.imgServicesBK +"/mall.png  style='height:65px; width:65px; border:5px solid white'/>" : ""));
                  //let home_delivery = this.geoJsonAnswer[i].properties.info.home_delivery;
                  //let auto_king = this.geoJsonAnswer[i].properties.info.auto_king;
                  //let express = this.geoJsonAnswer[i].properties.info.express;
                  let food_court = this.geoJsonAnswer[i].properties.info.food_court;
                  let seasonal = this.geoJsonAnswer[i].properties.info.seasonal;
                  //let _24_hours = this.geoJsonAnswer[i].properties.info["24_hours"];

                  let home_delivery = this.geoJsonAnswer[i].properties.info.home_delivery=='SI' ? "<img src="+environment.imgServicesBK +"/delivery.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let auto_king = this.geoJsonAnswer[i].properties.info.auto_king=='SI' ? "<img src="+ environment.imgServicesBK +"/autoking.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let express = this.geoJsonAnswer[i].properties.info.express=='SI' ? "<img src="+environment.imgServicesBK +"/express.png style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let _24_hours = this.geoJsonAnswer[i].properties.info["24_hours"]=='SI' ? "<img src="+environment.imgServicesBK +"/24h.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let mall = this.geoJsonAnswer[i].properties.info.mall ? "<br/>Centro Comercial: " + this.geoJsonAnswer[i].properties.info.mall : "";

                  var popup = L.popup()
                    .setLatLng(geoCoordinates)
                    .setContent(
                      "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +
                    "<strong>"+ nameMarker + "/<strong><br/>" +
                      directionMarker +
                      mall  +
                      "<br/>Apertura: " + this.geoJsonAnswer[i].properties.info.openning_date +
                      "<br/><br/> " +
                      //tipo local: " +
                      //type_local +
                      "<br/>food court: " +
                      food_court +
                      "<br/>estacional: " +
                      seasonal + 
                      "<br/>-------------------------------------"+
                      "<br/>Site más cercano: " + this.geoJsonAnswer[i].properties.info.name_mcd_nearby +
                      "<br/>Distancia site más cercano: " + Math.trunc(this.geoJsonAnswer[i].properties.info.dist_meters_mcd_nearby) + " metros" +
                      "<br/><center>" +
                      type_local +
                      //"<br/>auto_king: " +
                      auto_king +
                    // "<br/>home delivery: " +
                      home_delivery +
                    //  "<br/>express: " +
                      express +
                    //  "<br/>24 horas: " +
                      _24_hours +
                      "</center>"
                    );
                  marker.bindPopup(popup);
                } else {
                  let mall = this.geoJsonAnswer[i].properties.info.mall ? "<br/>Centro Comercial: " + this.geoJsonAnswer[i].properties.info.mall : "";

                  var popup = L.popup()
                    .setLatLng(geoCoordinates)
                    .setContent(
                      "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +
                      "<strong>"+nameMarker + "</strong>"+ "<br/>" + directionMarker +
                      mall  +
                      "<br/>Apertura: " + this.geoJsonAnswer[i].properties.info.openning_date +  
                      "<br/>-------------------------------------"+
                      "<br/>Site más cercano: " + this.geoJsonAnswer[i].properties.info.name_mcd_nearby +
                      "<br/>Distancia site más cercano: " + Math.trunc(this.geoJsonAnswer[i].properties.info.dist_meters_mcd_nearby) + " metros" );
                  marker.bindPopup(popup);
                }
                marker.on("mouseover", function (e) { });
                marker.on("mouseout", function (e) { });
                if (idImgIcon != "4772") {
                  marker.on("click", function (e) {
                    this.openPopup();
                  });
                }
                all_markers.push(marker);
              }
              this.layer_marker = L.layerGroup(all_markers);
              this.clusterGroup.addLayer(this.layer_marker);
              this.descriptive_map.addLayer(this.clusterGroup);
            });

        } else {
          this.unsubscribe10 = this.dataService
            .getMarkersGeoJson(lngmin, latmin, lngmax, latmax)
            .subscribe((res: any) => {
              this.geoJsonAnswer = res;
              this.clusterGroup =
                L.markerClusterGroup({
                  showCoverageOnHover: false,
                  zoomToBoundsOnClick: true,
                  spiderfyOnMaxZoom: true,
                  spiderLegPolylineOptions: { color: 'blue', weight: 2, opacity: 1 },
                  singleMarkerMode: false,
                  maxClusterRadius: 30,
                  iconCreateFunction: function (cluster) {
                    if (cluster.getChildCount() < 3) {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster',
                        iconSize: null
                      });
                    } else if (cluster.getChildCount() < 8) {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster_3',
                        iconSize: null
                      });
                    } else if (cluster.getChildCount() < 15) {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster_8',
                        iconSize: null
                      });
                    } else {
                      return L.divIcon({
                        html: cluster.getChildCount().toString(),
                        className: 'mkrcluster_15',
                        iconSize: null
                      });
                    }
                  }
                });


              let all_markers = [];
              for (let i = 0; i < this.geoJsonAnswer.length; i++) {
                let idImgIcon = this.geoJsonAnswer[i].properties.info.id_brand;
                let urlIcon = environment.imgUrl + "/markers/" + idImgIcon + ".svg";

                var checkedurl = this.checkUrl(urlIcon);
                if (!checkedurl) {
                  urlIcon = environment.imgUrl + "/helpers/joker.svg";
                }
                if (idImgIcon != "4772") {
                  this.icon_marker_subtype = L.icon({
                    iconUrl: urlIcon,
                    shadowUrl:
                      environment.imgUrl + "/helpers/circle-white-border-XXL.svg",
                    iconSize: [30, 30],
                    shadowSize: [33, 33]
                  });
                }

                let geoCoordinates = this.geoJsonAnswer[i].geometry.coordinates;
                let idMarker = this.geoJsonAnswer[i].properties.id;
                let nameMarker = this.geoJsonAnswer[i].properties.info.name;
                let directionMarker = this.geoJsonAnswer[i].properties.info.address;
                let marker = L.marker([geoCoordinates[1], geoCoordinates[0]], {
                  icon: this.icon_marker_subtype
                });
                if (this.geoJsonAnswer[i].properties.info.id_brand == 4599) {
                  //BK
                /*  let type_local = this.geoJsonAnswer[i].properties.info.type_local;
                  let home_delivery = this.geoJsonAnswer[i].properties.info
                    .home_delivery;
                  let auto_king = this.geoJsonAnswer[i].properties.info.auto_king;
                  let express = this.geoJsonAnswer[i].properties.info.express;
                  let food_court = this.geoJsonAnswer[i].properties.info.food_court;
                  let seasonal = this.geoJsonAnswer[i].properties.info.seasonal;
                  let _24_hours = this.geoJsonAnswer[i].properties.info["24_hours"];
    */
                  let type_local = this.geoJsonAnswer[i].properties.info.type_local=='I' ? 
                                          "<img src="+environment.imgServicesBK +"/instore.png  style='height:65px; width:65px; border:5px solid white'/>" 
                                          : (this.geoJsonAnswer[i].properties.info.type_local=='F' ? "<img src="+environment.imgServicesBK +"/freestanding.png  style='height:65px; width:65px; border:5px solid white'/>" :
                                          (this.geoJsonAnswer[i].properties.info.type_local=='M' ? "<img src="+environment.imgServicesBK +"/mall.png  style='height:65px; width:65px; border:5px solid white'/>" : ""));
                        
                  let home_delivery = this.geoJsonAnswer[i].properties.info.home_delivery=='SI' ? "<img src="+environment.imgServicesBK +"/delivery.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let auto_king = this.geoJsonAnswer[i].properties.info.auto_king=='SI' ? "<img src="+ environment.imgServicesBK +"/autoking.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let express = this.geoJsonAnswer[i].properties.info.express=='SI' ? "<img src="+environment.imgServicesBK +"/express.png style='height:65px; width:65px; border:5px solid white'/>" : "";
                  let _24_hours = this.geoJsonAnswer[i].properties.info["24_hours"]=='SI' ? "<img src="+environment.imgServicesBK +"/24h.png  style='height:65px; width:65px; border:5px solid white'/>" : "";

                  let food_court = this.geoJsonAnswer[i].properties.info.food_court;
                  let seasonal = this.geoJsonAnswer[i].properties.info.seasonal;
                  let mall = this.geoJsonAnswer[i].properties.info.mall ? "<br/>Centro Comercial: " + this.geoJsonAnswer[i].properties.info.mall : "";

                  var popup = L.popup()
                    .setLatLng(geoCoordinates)
                    .setContent(
                      "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +
                      "<strong>"+ nameMarker + "</strong>"+
                      "<br/>" +
                      directionMarker +
                      mall  +
                      "<br/>Apertura: " + this.geoJsonAnswer[i].properties.info.openning_date +
                      "<br/><br/> " +
                      "<br/>food court: " +
                      food_court +
                      "<br/>estacional: " +
                      seasonal + 
                      "<br/>-------------------------------------"+
                      "<br/>Site más cercano: " + this.geoJsonAnswer[i].properties.info.name_mcd_nearby +
                      "<br/>Distancia site más cercano: " + Math.trunc(this.geoJsonAnswer[i].properties.info.dist_meters_mcd_nearby) + " metros" +
                      "<br/><center>" +                  
                      type_local +
                      auto_king +
                      home_delivery +
                      express +
                      _24_hours +
                      "</center>"	
                    );
                  marker.bindPopup(popup,{autoPan:false});
                } else {
                  let mall = this.geoJsonAnswer[i].properties.info.mall ? "<br/>Centro Comercial: " + this.geoJsonAnswer[i].properties.info.mall : "";

                  var popup = L.popup()
                    .setLatLng(geoCoordinates)
                    .setContent( "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +  "<strong>"+nameMarker + "</strong>"+ "<br/>" + directionMarker +
                    mall  +
                    "<br/>Apertura: " + this.geoJsonAnswer[i].properties.info.openning_date +  
                    "<br/>-------------------------------------"+
                    "<br/>Site más cercano: " + this.geoJsonAnswer[i].properties.info.name_mcd_nearby +
                    "<br/>Distancia site más cercano: " + Math.trunc(this.geoJsonAnswer[i].properties.info.dist_meters_mcd_nearby) + " metros" );
                  marker.bindPopup(popup, {autoPan:false});
                }
                marker.on("mouseover", function (e) { });
                marker.on("mouseout", function (e) { });
                if (idImgIcon != "4772") {
                  marker.on("click", function (e) {
                      this.openPopup();
                  });
                }
                all_markers.push(marker);
              }

              this.layer_marker = L.layerGroup(all_markers);
              this.clusterGroup.addLayer(this.layer_marker);
              this.descriptive_map.addLayer(this.clusterGroup);
            });
        }

    }//else brands null
  };

  //Study:
  createLayerEstudios = () => {
    this.unsubscribe11 = this.geoStudiesDataService
      .getAllStudies()
      .subscribe((dataStudies: any) => {
        this.pintarEstudios(dataStudies);
      });
  };
  createLayerEstudiosPrescriptive = () => {
    this.unsubscribe11 = this.geoStudiesPrescriptiveDataService
      .getAllStudies()
      .subscribe((dataStudies: any) => {
       // this.pintarEstudiosPrescriptivo(dataStudies);
      });
  };

  pintarEstudios(dataStudies: any): any {
    if (this.descriptive_map.hasLayer(this.layer_mcdStudy)) {
      try {
        this.descriptive_map.removeLayer(this.layer_mcdStudy);
      } catch (error) { }
    }

    const _this = this;
    let all_markersMcdStudy = [];
    for (let i = 0; i <= dataStudies.length-1; i++) {     
      if (dataStudies[i].properties.data_model) {
        if (dataStudies[i].properties.data_model.sales_info) {
          this.icon_markerStudy = L.icon({
            iconUrl: environment.imgUrl + "/helpers/4772-marker-orange.svg",
            iconSize: [65, 65],
            iconAnchor: [25, 40],
            popupAnchor: [7, -45]
          });
          let geoCoordinates = dataStudies[i].geometry.coordinates;
          var popup: any;
          let markerMcdStudy = L.marker([geoCoordinates[1], geoCoordinates[0]], {
            icon: this.icon_markerStudy
          }).addTo(this.descriptive_map);
          markerMcdStudy.on("click", function (e) {
            _this.dataService.setSelectedStudy(dataStudies[i]._id);
            _this.dataService.getSelectedStudy();
          });
          all_markersMcdStudy.push(markerMcdStudy);
        }
      }
    }

    this.layer_mcdStudy = L.featureGroup(all_markersMcdStudy);
    this.descriptive_map.addLayer(this.layer_mcdStudy);
    this.layer_mcdStudy.on("click", this.triggerToggleSideNavs);
  }

  pintarEstudiosPrescriptivo(dataStudies: any): any {
    if (this.descriptive_map.hasLayer(this.layer_mcdStudy_presc)) {
      try {
        this.descriptive_map.removeLayer(this.layer_mcdStudy_presc);
      } catch (error) { }
    }
    const _this = this;
    let all_markersMcdStudy = [];
    //this.dataService.setSelectedStudyPresc(undefined);
    for (let i = 0; i <= dataStudies.length-1; i++) {
      if (dataStudies[i]) {

        this.icon_markerStudy = L.icon({
          iconUrl: environment.imgUrl + "/helpers/marker-prescrip-study.png",
          iconSize: [65, 65],
          iconAnchor: [25, 40],
          popupAnchor: [7, -45]
        });
        let geoCoordinates = dataStudies[i].geometry.coordinates;
        var popup: any;
        let markerMcdStudy = L.marker([geoCoordinates[1], geoCoordinates[0]], {
          icon: this.icon_markerStudy
        }).addTo(this.descriptive_map);

        this.layer_bricks_prescriptive = L.geoJSON(dataStudies[i].bricks, {
          style: function (feature) {
            return _this.styleBrick(feature);
          },
          onEachFeature: function (feature, layer) {
            _this.onEachFeatureBricks(feature, layer);

          }
        });
        markerMcdStudy.on("click", function (e) {
          if (_this.dataService.getSelectedStudyPresc() != dataStudies[i]._id) {
            _this.dataService.showBricksStudyPrescriptive(false, dataStudies[i].bricks);
            _this.dataService.setSelectedStudyPresc(dataStudies[i]._id);
          }
          _this.dataService.getSelectedStudyPresc();
        });
        all_markersMcdStudy.push(markerMcdStudy);

      }
    }

    this.layer_mcdStudy_presc = L.featureGroup(all_markersMcdStudy);
    this.descriptive_map.addLayer(this.layer_mcdStudy_presc);
    this.layer_mcdStudy_presc.on("click", this.triggerToggleSideNavsPresc);
  }

  ngOnInit() {
  }

  getMallPopUpTitle(property, value) {
    if (value) {
      return "<strong>" + property + "</strong>"
    }
    return "";
  }

  getMallPopUpTxt(property, value) {
    let txt = "";
    if (value) {
      if (isNaN(value)) {
        txt += "<tr><td>" + property + "</td><td>" + value + "</td></tr>"
      }
      if (!isNaN(value)) {
        txt += "<tr><td>" + property + "</td><td>" + (+value.toLocaleString('es-ES')) + "</td></tr>"
      }
    }
    return txt;
  }

  ngAfterViewInit() {

    this.descriptive_map = L.map(this.mapId, {
      zoomControl: false
    }).setView([40.42352, -3.70459], 15);
    var bounds = [
      [25.088089, -24.700402], // Southwest coordinates
      [44.820016, 6.733603] // Northeast coordinates
    ];
    this.descriptive_map.setMaxBounds(bounds);
    L.control
      .zoom({
        position: "topright"
      })
      .addTo(this.descriptive_map);

    L.tileLayer(this.access_token, {
      attribution:
        'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
        '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="http://mapbox.com">Mapbox</a>',

      //  ,
      //  id: 'mapbox.streets',
      //  accessToken: 'pk.eyJ1Ijoia3VyYW1hIiwiYSI6ImNqdmd1cnZnZjA2bHI0YXF0d3lsZDY5M2EifQ.Kz7LTA1Fj_Vds50sNzKBPg'

      // Para modificar el estilo del mapa de MapBox seguir las siguientes instrucciones:
      // 1 - Nos dirigimos a la página de incio de mapbox.
      // 2 - En la parte superior derecha pulsamos en el icono de nuestra cuenta y accedemos a Account.
      // 3 - En el menú vertical derecha pulsamos en la opción 'Design in Mapbox Studio'.
      // 4 - En esta ventana veremos los diferentes estilos de mapas que tengamos creados. Para obtener la ruta al estilo y ser cargado en el proyecto, pulsaremos
      // en uno de los estilos del mapa para entrar en la opción de edición de este.
      // 5 - En el menú superior pulsamos en donde pone 'Share'.
      // 6 - En esta ventana modal, copiaremos la URL de Share.
      // 7 - https://api.mapbox.com/styles/v1/kurama/cjwhqiqfv01is1clevwlypsf9.html?fresh=true&title=true&access_token=pk.eyJ1Ijoia3VyYW1hIiwiYSI6ImNqdmd1cnZnZjA2bHI0YXF0d3lsZDY5M2EifQ.Kz7LTA1Fj_Vds50sNzKBPg#10.0/42.362400/-71.020000/0
      // 8 - La URL anterior es la original, se tiene que modificar para que funcione. La sección '.html?fresh=true&title=true&' se reemplaza por '/tiles/256/{z}/{x}/{y}?'.
      noWrap: true,
      maxZoom: 20,
      minZoom: 4
    }).addTo(this.descriptive_map); // carga del mapa

    // this.descriptive_map.on("zoomend ", this.zoomCompetitor); //'moveend': just in case we need to update competitors when move the map
    this.descriptive_map.on("moveend ", this.zoomCompetitor);

    this.descriptive_map.on("submit ", ".form", this.myPreventDefault);
    this.createLayerMcd();
    this.loadCommercesMap();

    this.descriptive_map.on('zoomend', this.zoomMap);
    

    //competitors:
    this.unsubscribe13 = this.dataService.competitorFilteredDescriptiveChanged$.subscribe(
      result => {
        const _this = this;
        let tiene = _this.descriptive_map.hasLayer(_this.layer_marker);
        let tieneCG = _this.descriptive_map.hasLayer(_this.clusterGroup);
        try {
          _this.descriptive_map.removeLayer(_this.layer_marker);
          _this.layer_marker.removeAllFeatures();
        } catch (error) { }

        if(tieneCG){
          _this.clusterGroup.eachLayer(function(layer) { 
            _this.clusterGroup.removeLayer(layer);
          });
        }

        _this.clusterGroup =
          L.markerClusterGroup({
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            spiderfyOnMaxZoom: true,
            spiderLegPolylineOptions: { color: 'blue', weight: 2, opacity: 1 },
            singleMarkerMode: false,
            maxClusterRadius: 30,
            iconCreateFunction: function (cluster) {
              if (cluster.getChildCount() < 3) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster',
                  iconSize: null
                });
              } else if (cluster.getChildCount() < 8) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster_3',
                  iconSize: null
                });
              } else if (cluster.getChildCount() < 15) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster_8',
                  iconSize: null
                });
              } else {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster_15',
                  iconSize: null
                });
              }
            }
          });

          _this.geoJsonAnswer = result;
        let all_markers = [];
        for (let i = 0; i < _this.geoJsonAnswer.length; i++) {
          let idImgIcon = _this.geoJsonAnswer[i].id_brand;
          let urlIcon = environment.imgUrl + "/markers/" + idImgIcon + ".svg";

          var checkedurl = _this.checkUrl(urlIcon);
          if (!checkedurl) {
            urlIcon = environment.imgUrl + "/helpers/joker.svg";
          }

          if (idImgIcon != "4772") {
            _this.icon_marker_subtype = L.icon({
              iconUrl: urlIcon,
              shadowUrl:
                environment.imgUrl + "/helpers/circle-white-border-XXL.svg",
              iconSize: [25, 25],
              shadowSize: [28, 28]
            });
          }

          let geoCoordinates = _this.geoJsonAnswer[i].geometry.coordinates;
          let nameMarker = _this.geoJsonAnswer[i].name;
          let directionMarker = _this.geoJsonAnswer[i].address;
          let marker = L.marker([geoCoordinates[1], geoCoordinates[0]], {
            icon: _this.icon_marker_subtype
          });
          if (_this.geoJsonAnswer[i].id_brand == 4599) {
            //BK
            let type_local = _this.geoJsonAnswer[i].type_local=='I' ? 
            "<img src="+environment.imgServicesBK +"/instore.png  style='height:65px; width:65px; border:5px solid white'/>" 
            : (_this.geoJsonAnswer[i].type_local=='F' ? "<img src="+environment.imgServicesBK +"/freestanding.png  style='height:65px; width:65px; border:5px solid white'/>" :
             (_this.geoJsonAnswer[i].type_local=='M' ? "<img src="+environment.imgServicesBK +"/mall.png  style='height:65px; width:65px; border:5px solid white'/>" : ""));

            let home_delivery = _this.geoJsonAnswer[i].home_delivery=='SI' ? "<img src="+environment.imgServicesBK +"/delivery.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
            let auto_king = _this.geoJsonAnswer[i].auto_king=='SI' ? "<img src="+ environment.imgServicesBK +"/autoking.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
            let express = _this.geoJsonAnswer[i].express=='SI' ? "<img src="+environment.imgServicesBK +"/express.png style='height:65px; width:65px; border:5px solid white'/>" : "";
            let _24_hours = _this.geoJsonAnswer[i]["24_hours"]=='SI' ? "<img src="+environment.imgServicesBK +"/24h.png  style='height:65px; width:65px; border:5px solid white'/>" : "";

            let food_court = _this.geoJsonAnswer[i].food_court;
            let seasonal = _this.geoJsonAnswer[i].seasonal;
            let mall = _this.geoJsonAnswer[i].mall ? "<br/>Centro Comercial: " + _this.geoJsonAnswer[i].mall : "";
            var popup = L.popup()
              .setLatLng(geoCoordinates)
              .setContent(
                "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +
                "<strong>"+ nameMarker + "</strong>"+
                  "<br/>" +
                  directionMarker +
                  mall  +
                  "<br/>Apertura: " + _this.geoJsonAnswer[i].openning_date +
                  "<br/><br/> " +
                  "<br/>food court: " +
                  food_court +
                  "<br/>estacional: " +
                  seasonal + 
                  "<br/>-------------------------------------"+
                  "<br/>Site más cercano: " + _this.geoJsonAnswer[i].name_mcd_nearby +
                  "<br/>Distancia site más cercano: " + Math.trunc(_this.geoJsonAnswer[i].dist_meters_mcd_nearby) + " metros" +
                  "<br/><center>" +                                    
                  type_local +
                  auto_king +
                  home_delivery +
                  express +
                  _24_hours +
                  "</center>"
              );
            marker.bindPopup(popup);
          } else {
            let mall = _this.geoJsonAnswer[i].mall ? "<br/>Centro Comercial: " + _this.geoJsonAnswer[i].mall : "";

            var popup = L.popup()
              .setLatLng(geoCoordinates)
              .setContent( "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +  "<strong>"+nameMarker + "</strong>"+ "<br/>" + directionMarker +
              mall  +
              "<br/>Apertura: " + _this.geoJsonAnswer[i].openning_date +  
              "<br/>-------------------------------------"+
              "<br/>Site más cercano: " + _this.geoJsonAnswer[i].name_mcd_nearby +
              "<br/>Distancia site más cercano: " + Math.trunc(_this.geoJsonAnswer[i].dist_meters_mcd_nearby) + " metros" );
            marker.bindPopup(popup);
          }
          marker.on("mouseover", function (e) { });
          marker.on("mouseout", function (e) { });
          if (idImgIcon != "4772") {
            marker.on("click", function (e) {
              this.openPopup();
            });
          }

          all_markers.push(marker);
        }
        _this.layer_marker = L.layerGroup(all_markers);
        _this.clusterGroup.addLayer(_this.layer_marker);
        _this.descriptive_map.addLayer(_this.clusterGroup);

      }
    );
    //competitors others:
    this.unsubscribe14 = this.dataService.competitorOthersFilteredDescriptiveChanged$.subscribe(
      result => {
        const _this = this;
        if (result.length <= 0) {
          this.competenciasOthers = false
        } else {
          this.competenciasOthers = true;
        }

        let tiene = this.descriptive_map.hasLayer(this.layer_marker_others);
        try {
          this.descriptive_map.removeLayer(this.layer_marker_others);
        } catch (error) { }
        try {
          this.descriptive_map.removeLayer(this.clusterGroupOth);
        } catch (error) { }

        this.clusterGroupOth =
          L.markerClusterGroup({
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            spiderfyOnMaxZoom: true,
            spiderLegPolylineOptions: { color: 'blue', weight: 2, opacity: 1 },
            singleMarkerMode: false,
            maxClusterRadius: 30,
            iconCreateFunction: function (cluster) {
              if (cluster.getChildCount() < 5) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrclusterOth_5',
                  iconSize: null
                });
              } else if (cluster.getChildCount() < 10) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrclusterOth_10',
                  iconSize: null
                });
              } else if (cluster.getChildCount() < 20) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrclusterOth_20',
                  iconSize: null
                });
              } else {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrclusterOth',
                  iconSize: null
                });
              }
            }
          });
        this.geoJsonAnswer = result;
        let all_markers = [];
        for (let i = 0; i < this.geoJsonAnswer.length; i++) {
          let idImgIcon = this.geoJsonAnswer[i].id_brand;
          let urlIcon = environment.imgUrl + "/markers/" + idImgIcon + ".svg";

          var checkedurl = this.checkUrl(urlIcon);
          if (!checkedurl) {
            urlIcon = environment.imgUrl + "/helpers/joker.svg";
          }

          if (idImgIcon != "4772") {
            this.icon_marker_subtype = L.icon({
              iconUrl: urlIcon,
              shadowUrl:
                environment.imgUrl + "/helpers/circle-white-border-XXL.svg",
              iconSize: [25, 25],
              shadowSize: [28, 28]
            });
          }

          let geoCoordinates = this.geoJsonAnswer[i].geometry.coordinates;
          let nameMarker = this.geoJsonAnswer[i].name;
          let directionMarker = this.geoJsonAnswer[i].address;
          let marker = L.marker([geoCoordinates[1], geoCoordinates[0]], {
            icon: this.icon_marker_subtype
          });
          if (this.geoJsonAnswer[i].id_brand == 4599) {
            /*let type_local = this.geoJsonAnswer[i].type_local;
            let home_delivery = this.geoJsonAnswer[i].home_delivery;
            let auto_king = this.geoJsonAnswer[i].auto_king;
            let express = this.geoJsonAnswer[i].express;
            let food_court = this.geoJsonAnswer[i].food_court;
            let seasonal = this.geoJsonAnswer[i].seasonal;
            */
            let type_local = this.geoJsonAnswer[i].type_local=='I' ? 
            "<img src="+environment.imgServicesBK +"/instore.png  style='height:65px; width:65px; border:5px solid white'/>" 
            : (this.geoJsonAnswer[i].type_local=='F' ? "<img src="+environment.imgServicesBK +"/freestanding.png  style='height:65px; width:65px; border:5px solid white'/>" :
             (this.geoJsonAnswer[i].type_local=='M' ? "<img src="+environment.imgServicesBK +"/mall.png  style='height:65px; width:65px; border:5px solid white'/>" : ""));

            let home_delivery = this.geoJsonAnswer[i].home_delivery=='SI' ? "<img src="+environment.imgServicesBK +"/delivery.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
            let auto_king = this.geoJsonAnswer[i].auto_king=='SI' ? "<img src="+ environment.imgServicesBK +"/autoking.png  style='height:65px; width:65px; border:5px solid white'/>" : "";
            let express = this.geoJsonAnswer[i].express=='SI' ? "<img src="+environment.imgServicesBK +"/express.png style='height:65px; width:65px; border:5px solid white'/>" : "";
            let _24_hours = this.geoJsonAnswer[i]["24_hours"]=='SI' ? "<img src="+environment.imgServicesBK +"/24h.png  style='height:65px; width:65px; border:5px solid white'/>" : "";

            let food_court = this.geoJsonAnswer[i].food_court;
            let seasonal = this.geoJsonAnswer[i].seasonal;
            let mall = this.geoJsonAnswer[i].mall ? "<br/>Centro Comercial: " + this.geoJsonAnswer[i].mall : "";
            var popup = L.popup()
              .setLatLng(geoCoordinates)
              .setContent(
                "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +
                "<strong>"+ nameMarker + "</strong>"+
                  "<br/>" +
                  directionMarker +
                  mall  +
                  "<br/>Apertura: " + this.geoJsonAnswer[i].openning_date +
                  "<br/><br/> " +
                  "<br/>food court: " +
                  food_court +
                  "<br/>estacional: " +
                  seasonal + 
                  "<br/>-------------------------------------"+
                  "<br/>Site más cercano: " + this.geoJsonAnswer[i].name_mcd_nearby +
                  "<br/>Distancia site más cercano: " + Math.trunc(this.geoJsonAnswer[i].dist_meters_mcd_nearby) + " metros" +
                  "<br/><center>" +
                  type_local +
                  auto_king +
                  home_delivery +
                  express +
                  _24_hours +
                  "</center>"
              );
            marker.bindPopup(popup);
          } else {
            let mall = this.geoJsonAnswer[i].mall ? "<br/>Centro Comercial: " + this.geoJsonAnswer[i].mall : "";

            var popup = L.popup()
              .setLatLng(geoCoordinates)
              .setContent( "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +  "<strong>"+nameMarker + "</strong>"+ "<br/>" + directionMarker +
              mall  +
              "<br/>Apertura: " + this.geoJsonAnswer[i].openning_date +  
              "<br/>-------------------------------------"+
              "<br/>Site más cercano: " + this.geoJsonAnswer[i].name_mcd_nearby +
              "<br/>Distancia site más cercano: " + Math.trunc(this.geoJsonAnswer[i].dist_meters_mcd_nearby) + " metros" 
              );
            marker.bindPopup(popup);
          }
          marker.on("mouseover", function (e) { });
          marker.on("mouseout", function (e) { });
          if (idImgIcon != "4772") {
            marker.on("click", function (e) {
              this.openPopup();
            });
          }
          all_markers.push(marker);
        }
        this.layer_marker_others = L.layerGroup(all_markers);
        this.clusterGroupOth.addLayer(this.layer_marker_others);
        this.descriptive_map.addLayer(this.clusterGroupOth);

      }
    );
    //commerces:
    this.unsubscribe15 = this.dataService.commercesFilteredDescriptiveChanged$.subscribe(
      result => {
        let tiene = this.descriptive_map.hasLayer(this.layer_marker_commerces);
        try {
          this.descriptive_map.removeLayer(this.layer_marker_commerces);
        } catch (error) { }
        try {
          this.descriptive_map.removeLayer(this.clusterGroupCommerces);
        } catch (error) { }

        this.geoJsonAnswer = result;
        this.clusterGroupCommerces =
          L.markerClusterGroup({
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            spiderfyOnMaxZoom: true,
            spiderLegPolylineOptions: { color: 'red', weight: 2, opacity: 1 },
            singleMarkerMode: false,
            maxClusterRadius: 30,
            iconCreateFunction: function (cluster) {
              if (cluster.getChildCount() < 3) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster',
                  iconSize: null
                });
              } else if (cluster.getChildCount() < 8) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster_3',
                  iconSize: null
                });
              } else if (cluster.getChildCount() < 15) {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster_8',
                  iconSize: null
                });
              } else {
                return L.divIcon({
                  html: cluster.getChildCount().toString(),
                  className: 'mkrcluster_15',
                  iconSize: null
                });
              }
            }
          });
        let all_markers = [];
        for (let i = 0; i < this.geoJsonAnswer.length; i++) {
          let idImgIcon = this.geoJsonAnswer[i].icono; //this.geoJsonAnswer[i].properties.info.id_brand;
          //let urlIcon = environment.imgUrl + "/helpers/commerces.png";
          let urlIcon = environment.imgUrl + "/commerces_markers/" + idImgIcon + ".png";
          var checkedurl = this.checkUrl(urlIcon);
          if (!checkedurl) {
            urlIcon = environment.imgUrl + "/helpers/commerces.png";
          }


          this.icon_marker_subtype = L.icon({
            iconUrl: urlIcon,
            iconSize: [25, 25]
          });
          let geoCoordinates = this.geoJsonAnswer[i].geometry.coordinates;
          let nameMarker = this.geoJsonAnswer[i].brand;
          let marker = L.marker([geoCoordinates[1], geoCoordinates[0]], {
            icon: this.icon_marker_subtype
          });
          let category = this.geoJsonAnswer[i].category;
          let address = this.geoJsonAnswer[i].address;
          let mall = this.geoJsonAnswer[i].mall;
          let m2 = this.geoJsonAnswer[i].sup_m2;
          let content =           
          "<center><img src=" + urlIcon + " height='35px' width='35px' />" +"</center><br/>" +
          "<strong>"+ nameMarker + "</strong>";
          if (address) {
            content = content.concat("<br/>" + address);
          }
          if (mall) {
            content = content.concat("<br/>Centro comercial: " + mall);
          }
          if (m2) {
            content = content.concat("<br/>Superficie: " + m2 + " m<sup>2</sup>");
          }
          content = content.concat("<br/>Categoría: " + category);
          
         
          var popup = L.popup()
            .setLatLng(geoCoordinates)
            .setContent(content);
          marker.bindPopup(popup);
          marker.on("mouseover", function (e) { });
          marker.on("mouseout", function (e) { });
          marker.on("click", function (e) {
            this.openPopup();
          });
          all_markers.push(marker);
        }
       
        this.layer_marker_commerces = L.layerGroup(all_markers);
        this.clusterGroupCommerces.addLayer(this.layer_marker_commerces);
        this.descriptive_map.addLayer(this.clusterGroupCommerces);


      }
    );


    //malls:
    this.unsubscribe22 = this.dataService.mallsFilteredDescriptiveChanged$.subscribe(
      result => {
        let tiene = this.descriptive_map.hasLayer(this.layer_marker_malls);
        try {
          this.descriptive_map.removeLayer(this.layer_marker_malls);
        } catch (error) { }

        this.geoJsonAnswer = result;
        let all_markers = [];
        for (let i = 0; i < this.geoJsonAnswer.length; i++) {
          let urlIcon = environment.imgUrl + "/helpers/commercialcenter.png";

          this.icon_marker_subtype = L.icon({
            iconUrl: urlIcon,
            shadowUrl:
              environment.imgUrl + "/helpers/circle-white-border-XXXL.png",
            iconSize: [24, 24],
            shadowSize: [32, 32]
          });
          let geoCoordinates = this.geoJsonAnswer[i].geometry.coordinates;
          let nameMarker = this.geoJsonAnswer[i].name;
          let marker = L.marker([geoCoordinates[1], geoCoordinates[0]], {
            icon: this.icon_marker_subtype
          });
          var popup = L.popup()
            .setLatLng(geoCoordinates)
            .setContent(
              "<strong>" + nameMarker + "</strong><br/>" +
              this.geoJsonAnswer[i].address + "<br/>" +
              "<table>" +
              this.getMallPopUpTxt('Apertura: ', this.geoJsonAnswer[i].date_open) +
              this.getMallPopUpTxt('Tipo: ', this.geoJsonAnswer[i].type) +
              this.getMallPopUpTxt('Nº comercios: ', this.geoJsonAnswer[i].total_commerce) +
              this.getMallPopUpTxt('Comercio exterior: ', this.geoJsonAnswer[i].exterior_shops) +
              this.getMallPopUpTxt('Comercio interior: ', this.geoJsonAnswer[i].interior_shops) +
              this.getMallPopUpTxt('Comercio principal: ', this.geoJsonAnswer[i].main_commerce) +
              this.getMallPopUpTxt('Comercio principal (m2): ', this.geoJsonAnswer[i].main_commerce_surface) +
              this.getMallPopUpTxt('Plazas parking: ', this.geoJsonAnswer[i].total_parking_spaces) +
              this.getMallPopUpTxt('Locomotora: ', Number(this.geoJsonAnswer[i].engine)) +
              this.getMallPopUpTxt('Tipo de locomotora: ', this.geoJsonAnswer[i].engine_type) +
              this.getMallPopUpTxt('Locomotora (m2): ', this.geoJsonAnswer[i].engine_surface) +
              this.getMallPopUpTxt('Transporte en coche: ', this.geoJsonAnswer[i].transport_car) +
              this.getMallPopUpTxt('Transporte a pie: ', this.geoJsonAnswer[i].transport_foot) +
              this.getMallPopUpTxt('Transporte público: ', this.geoJsonAnswer[i].transport_public) +
              this.getMallPopUpTxt('Cines: ', this.geoJsonAnswer[i].cinemas) +
              "</table>" +
              this.getMallPopUpTitle('Superficie bruta alquilable: ', this.geoJsonAnswer[i].sba_total) +
              "<table>" +
              this.getMallPopUpTxt('SBA Total (m2): ', this.geoJsonAnswer[i].sba_total) +
              this.getMallPopUpTxt('Comercios (%): ', this.geoJsonAnswer[i].sba_commerce) +
              this.getMallPopUpTxt('Alimentación (%): ', this.geoJsonAnswer[i].sba_power_supply) +
              this.getMallPopUpTxt('Moda y complementos (%): ', this.geoJsonAnswer[i].sba_fashion_complement) +
              this.getMallPopUpTxt('Hogar, Bricolaje y Electrónica (%): ', this.geoJsonAnswer[i].sba_home_brico_electronic) +
              this.getMallPopUpTxt('Servicios (%): ', this.geoJsonAnswer[i].sba_services) +
              this.getMallPopUpTxt('Restauración y Ocio (%): ', this.geoJsonAnswer[i].sba_rest_leisure) +
              this.getMallPopUpTxt('Varios (%): ', this.geoJsonAnswer[i].sba_various) +

              "</table>"



            );
          marker.bindPopup(popup);
          marker.on("mouseover", function (e) { });
          marker.on("mouseout", function (e) { });
          marker.on("click", function (e) {
            this.openPopup();
          });
          all_markers.push(marker);
        }
        this.layer_marker_malls = L.layerGroup(all_markers);
        this.descriptive_map.addLayer(this.layer_marker_malls);
      }
    );

    this.unsubscribe16 = this.dataService.restaurantZoomDescriptiveChanged$.subscribe(
      geometryResult => {
        if (geometryResult.layer == "layer_marker") {
          this.zoomCompetitor(geometryResult);
        } else {
          this.descriptive_map.setView(
            [geometryResult.coordinates[1], geometryResult.coordinates[0]],
            18
          );
        }
      }
    );

    this.unsubscribe17 = this.geoStudiesDataService.studyZoomPredictiveChanged$.subscribe(
      geometryResult => {
        this.descriptive_map.setView(
          [geometryResult.lat, geometryResult.long],
          11
        );
      }
    );

    this.unsubscribe18 = this.dataService.competitorZoomDescriptiveChanged$.subscribe(
      geometryResult => {
        this.descriptive_map.setView(
          [geometryResult.coordinates[1], geometryResult.coordinates[0]],
          18
        );
      }
    );

    this.geoStudiesPrescriptiveDataService.studyZoomPrescriptiveChanged$.subscribe(
      geometryResult => {
        this.descriptive_map.setView(
          [geometryResult.lat, geometryResult.long],
          11
        );
      }
    );

    this.unsubscribe19 = this.dataService.brickDescriptiveChanged$.subscribe(
      dataBrick => {
        this.zoomBrick(dataBrick);
        this.descriptive_map.setView(
          [
            dataBrick.properties.centroid.coordinates[1],
            dataBrick.properties.centroid.coordinates[0]
          ],
          16
        );
      }
    );

    this.geoStudiesPrescriptiveDataService.brickPrescriptiveChanged$.subscribe(
      dataBrick => {
        this.zoomBrickPrescriptive(dataBrick);
        this.descriptive_map.setView(
          [
            dataBrick.properties.centroid.coordinates[1],
            dataBrick.properties.centroid.coordinates[0]
          ],
          16
        );
      }
    );


    if (this.isPredictive) {
      this.createLayerEstudios();
    }
    if (this.isPrescriptive) {
      this.createLayerEstudiosPrescriptive();
    }

    this.unsubscribe20 = this.geoStudiesDataService.studyPredictiveChanged$.subscribe(
      objectZoom => {
      }
    );

    this.unsubscribe20 = this.geoStudiesPrescriptiveDataService.studyPrescriptiveChanged$.subscribe(
      objectZoom => {
      }
    );

    this.routep.data.subscribe(d => {
      const _this = this;
      if (this.isPrescriptive) {
        if (d.mappedParams) {
          _this.dataService.setSelectedStudyPresc(d.mappedParams._id);
          if(d.mappedParams.properties.study_zone_id=='ES'){
            _this.descriptive_map.setView(
              [
                d.mappedParams.geometry.coordinates[1],
                d.mappedParams.geometry.coordinates[0]
              ],
              5
            );
          }else{
            _this.descriptive_map.setView(
              [
                d.mappedParams.geometry.coordinates[1],
                d.mappedParams.geometry.coordinates[0]
              ],
              11
            );
          }
          
          _this.dataService.setSelectedStudyPresc(d.mappedParams._id);
        }

        //_this.dataService.getSelectedStudy();
      }

    });


    this.routepmassive.data.subscribe(d => {
      const _this = this;
      if (this.isMassivePrescriptive) {
        if (d.mappedParams) {
          _this.dataService.setSelectedStudyPrescMassive(d.mappedParams._id);
          if(d.mappedParams.properties.study_zone_id=='ES'){
            _this.descriptive_map.setView(
              [
                d.mappedParams.geometry.coordinates[1],
                d.mappedParams.geometry.coordinates[0]
              ],
              5
            );
          }else{
            _this.descriptive_map.setView(
              [
                d.mappedParams.geometry.coordinates[1],
                d.mappedParams.geometry.coordinates[0]
              ],
              11
            );
          }
          
          _this.dataService.setSelectedStudyPrescMassive(d.mappedParams._id);
        }

      //  _this.dataService.getSelectedStudy();
      }

    });

    this.unsubscribe21 = this.route.data.subscribe(d => {
      const _this = this;
      if (this.isPredictive) {
        if (d.mappedParams) {
          _this.descriptive_map.setView(
            [
              d.mappedParams.geometry.coordinates[1],
              d.mappedParams.geometry.coordinates[0]
            ],
            18
          );
          _this.dataService.setSelectedStudy(d.mappedParams._id);
        }

        _this.dataService.getSelectedStudy();
      }

    });
  }

  onSubmit() {
    this.myPreventDefault;
  }

  ngOnChanges() { }

  ngOnDestroy() {
    if (this.descriptive_map) {
      this.descriptive_map._container.id = null
    }

    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
    if (this.unsubscribe4) {
      this.unsubscribe4.unsubscribe();
    }
    if (this.unsubscribe5) {
      this.unsubscribe5.unsubscribe();
    }
    if (this.unsubscribe6) {
      this.unsubscribe6.unsubscribe();
    }
    if (this.unsubscribe7) {
      this.unsubscribe7.unsubscribe();
    }
    if (this.unsubscribe8) {
      this.unsubscribe8.unsubscribe();
    }
    if (this.unsubscribe9) {
      this.unsubscribe9.unsubscribe();
    }
    if (this.unsubscribe10) {
      this.unsubscribe10.unsubscribe();
    }
    if (this.unsubscribe11) {
      this.unsubscribe11.unsubscribe();
    }
    if (this.unsubscribe12) {
      this.unsubscribe12.unsubscribe();
    }
    if (this.unsubscribe13) {
      this.unsubscribe13.unsubscribe();
    }
    if (this.unsubscribe14) {
      this.unsubscribe14.unsubscribe();
    }
    if (this.unsubscribe15) {
      this.unsubscribe15.unsubscribe();
    }
    if (this.unsubscribe16) {
      this.unsubscribe16.unsubscribe();
    }
    if (this.unsubscribe17) {
      this.unsubscribe17.unsubscribe();
    }
    if (this.unsubscribe18) {
      this.unsubscribe18.unsubscribe();
    }
    if (this.unsubscribe19) {
      this.unsubscribe19.unsubscribe();
    }
    if (this.unsubscribe20) {
      this.unsubscribe20.unsubscribe();
    }
    if (this.unsubscribe21) {
      this.unsubscribe21.unsubscribe();
    }
  }
}