<nav>
  <!-- Change TO showSidenavStartLeft -->
  <div
    class="sidenav-start-left"
    [ngClass]="{ display: showSidenavStartLeft }"
    id="sidenav_restaurants"
    aria-label="Menú principal"
  >
    <nav role="navigation">
      <!-- Company or app name -->
      <div role="header" class="navigation-header">
        <div class="navigation-header--logo">
          <p class="hidden">
            McDonalds Studio App, Powered by Abacus Consulting, {{year_date}}
          </p>
          <img
            src="../../../../assets/img/brand/mcd.svg"
            alt="McDonalds Studio App, Powered by Abacus Consulting, 2023"
            routerLink="/login"
          />
        </div>
        <div
          class="navigation-header--button"
          (click)="triggerToggleSideNavs()"
        >
          <app-rotate-arrow></app-rotate-arrow>
        </div>
      </div>
      <div class="sidebar">
        <ng-content></ng-content>
      </div>
    </nav>
  </div>
  <div class="overlay-left-label">
    <div class="overlay fade-in" (click)="triggerToggleSideNavs()"></div>
  </div>
</nav>
