import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "src/app/app-services/data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-ul-sites",
  templateUrl: "./ul-sites.component.html",
  styleUrls: ["./ul-sites.component.scss"]
})
export class UlSitesComponent implements OnInit, OnDestroy {
  router: string;
  sidenavRestaurantsLinks: any;
  sidenavRestaurantsTabs: any;
  brandListZoom: any;
  disabledComp: any;
  loadMalls: boolean = false;
  competenciaLink: boolean = true;
  unsubscribe: any;

  constructor(private _router: Router, private dataService: DataService) {
    this.router = _router.url;
  }

  toggleRestaurantsSideNav: any = (event: any, type: string) => {
    if (type == "competencia") {
      this.disabledComp = this.dataService.getDisabledCompetitor();
      if (!this.disabledComp) {
        this.brandListZoom = this.dataService.getBrandsByCoordsF().sort();
      }
    }

    this.sidenavRestaurantsLinks.forEach(element => {
      let target: string = event.target.dataset.tab;
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
      if (!event.target.classList.contains("active")) {
        event.target.classList.add("active");

        this.sidenavRestaurantsTabs.forEach(tab => {
          if (tab.classList.contains("display")) {
            tab.classList.remove("display");
          }
          if (tab.dataset.tab === target) {
            tab.classList.add("display");
            if (this.disabledComp) {
              if (target == "competencia") {
                tab.classList.remove("display");
              }
            }
            if (target == "malls") {
              tab.classList.add("display");
              this.loadMalls = true;
            } else {
              this.loadMalls = false;
            }
          }
        });
      }
    });
  };

  ngOnInit() {}

  ngAfterViewInit() {
    this.sidenavRestaurantsLinks = document.querySelectorAll(
      "ul#sidenavRestaurantsLinks li"
    );
    this.sidenavRestaurantsTabs = document.querySelectorAll(
      "div#sidenavRestaurantsTabs .tab"
    );

    this.disabledComp = this.dataService.getDisabledCompetitor();
    this.competenciaLink = this.disabledComp;

    this.unsubscribe = this.dataService.competitorZoomDisabledDescriptiveChanged$.subscribe(
      competitorDisabled => {
        this.competenciaLink = competitorDisabled;
      }
    );
  }

  ngOnChanges(){
    this.disabledComp = this.dataService.getDisabledCompetitor();
    this.competenciaLink = this.disabledComp;

    this.unsubscribe = this.dataService.competitorZoomDisabledDescriptiveChanged$.subscribe(
      competitorDisabled => {
        this.competenciaLink = competitorDisabled;
      }
    );
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
