<app-progress-bar [loading]="entorno_loading"></app-progress-bar>
<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">ESTRUCTURA DEMOGRÁFICA</h2>
      <p>En porcentaje (tiempo 10 min. en coche).</p>

      <app-population-pyramid style="width: 100%;" [params]="population_pyramid" *ngIf="entornoReady">
      </app-population-pyramid>

      <div class="margin-top--s"></div>
    </div>
  </div>
</section>

<section class="full-width clearfix padding-xs">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">TIPOLOGÍA DE VIVIENDAS</h2>
      <p>
        En porcentaje, ordenada de mayor a menor según la zona seleccionada
        (tiempos en coche).
      </p>

      <app-options-from-json style="width: 100%;" [params]="housing_typology" widget_num="1" [emitFirst]="true"
        (eventEmitter)="changeCategorySelectValue($event)" *ngIf="entornoReady">
      </app-options-from-json>

      <app-grouped-bar style="width: 100%;" [params]="housing_typology" [categorySelectValue]="categorySelectValue"
        chartId="housing_typology" chartSize="regular" *ngIf="entornoReady"></app-grouped-bar>

      <div class="margin-top--s"></div>
    </div>
  </div>
</section>

<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">HOGARES VS POBLACIÓN</h2>
      <p>Tiempos en coche.</p>

      <app-scatterplot style="width: 100%;" [params]="housing_vs_population" *ngIf="entornoReady"></app-scatterplot>

      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">INGRESOS POR HOGAR</h2>
      <p>En euros, tiempos en coche.</p>

      <app-vertical-bars style="width: 100%;" [params]="income_per_household" *ngIf="entornoReady"
        chartId="income_per_household" chartSize="small" temporalSerie="income_per_household"></app-vertical-bars>

      <h2 class="tab-headline">TASA DE PARO</h2>
      <p>En porcentaje, tiempos en coche.</p>

      <app-vertical-bars style="width: 100%;" [params]="unemployment_rate" *ngIf="entornoReady"
        chartId="unemployment_rate" chartSize="small" temporalSerie="unemployment_rate"></app-vertical-bars>

      <div class="margin-top--s"></div>
    </div>
  </div>
  <div class="margin-top--xl"></div>
</section>