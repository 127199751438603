import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

export interface Todo {
  properties: any;
  id: number | string;
  createdAt: number;
  value: string;
  study_name: string;
  kind_building: string;
}

@Injectable()
export class GeoStudiesPrescriptiveDataService {
  todos: Observable<Todo[]>;
  private _todos: BehaviorSubject<Todo[]>;
  private studiesUrl: string;
  private dataStore: {
    todos: Todo[];
  };


  todosMassive: Observable<Todo[]>;
  private _todosMassive: BehaviorSubject<Todo[]>;
  private studiesMassiveUrl: string;
  private dataStoreMassive: {
    todosMassive: Todo[];
  };

  featuresSelected: any;
  selectedStudy: any;
  dataBrickSelected: any;

  idStudySelected: any;
  idMassiveStudySelected: any;

  private headers: HttpHeaders = new HttpHeaders();

  private addressPrescriptive$ = new Subject<any>();
  addressPrescriptiveChanged$ = this.addressPrescriptive$.asObservable();

  private studyZoomPrescriptive$ = new Subject<any>();
  studyZoomPrescriptiveChanged$ = this.studyZoomPrescriptive$.asObservable();

  private coordsPrescriptive$ = new Subject<any>();
  coordsPrescriptiveChanged$ = this.coordsPrescriptive$.asObservable();

  private studyMapPrescriptive$ = new Subject<any>();
  studyMapPrescriptiveChanged$ = this.studyMapPrescriptive$.asObservable();

  private studyParamsPrescriptive$ = new Subject<any>();
  studyParamsPrescriptiveChanged$ = this.studyParamsPrescriptive$.asObservable();

  private competitorsPointPrescriptive$ = new Subject<any>();
  competitorsPointPrescriptiveChanged$ = this.competitorsPointPrescriptive$.asObservable();

  private commercesPrescriptive$ = new Subject<any>();
  commercesPrescriptiveChanged$ = this.commercesPrescriptive$.asObservable();

  private mallPrescriptive$ = new Subject<any>();
  mallPrescriptiveChanged$ = this.mallPrescriptive$.asObservable();

  private studyPrescriptive$ = new Subject<any>();
  studyPrescriptiveChanged$ = this.studyPrescriptive$.asObservable();

  private brickPrescriptive$ = new Subject<any>();
  brickPrescriptiveChanged$ = this.brickPrescriptive$.asObservable();

  private allStudiesCheckPrescriptive$ = new Subject<any>();
  allStudiesCheckPrescriptiveChanged$ = this.allStudiesCheckPrescriptive$.asObservable();

  private bricksStudyFiltered$ = new Subject<any>();
  bricksStudyFilteredChanged$ = this.bricksStudyFiltered$.asObservable();

  private studyPrescriptiveFiltered$ = new Subject<any>();
  studyPrescriptiveFilteredChanged$ = this.studyPrescriptiveFiltered$.asObservable();



  private studyMassivePrescriptive$ = new Subject<any>();
  studyMassivePrescriptiveChanged$ = this.studyMassivePrescriptive$.asObservable();


  private studyMapPrescriptiveMassive$ = new Subject<any>();
  studyMapPrescriptiveMassiveChanged$ = this.studyMapPrescriptiveMassive$.asObservable();

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.studiesUrl = "../../assets/data/brick_studies_data.json";
    this.dataStore = { todos: [] };
    this._todos = <BehaviorSubject<Todo[]>>new BehaviorSubject([]);
    this.todos = this._todos.asObservable();

    this.studiesMassiveUrl = "../../assets/data/brick_studies_data.json";
    this.dataStoreMassive = { todosMassive: [] };
    this._todosMassive = <BehaviorSubject<Todo[]>>new BehaviorSubject([]);
    this.todosMassive = this._todosMassive.asObservable();
  }

  loadAll() {
    this.studiesUrl =
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/prescriptives/";

    return this.http.get<Todo[]>(`${this.studiesUrl}`).subscribe(
      (data) => {
        data = data.map((el) => {
        el["id"] = el["properties"]["study"].study_id;
        el["nombre"] = el["properties"]["study_name"];
        el["province_id"] = el["properties"]["province_id"];       
        el["fecha creacion"] = el["properties"]["study"]["created"].split("T")
        .join(" ")
        .split("Z")
        .join("")
        .split(".")[0];
        el["estado"] = el["properties"]["study"]["state_description"];
        el["provincia"] = el["province_id"];
        el["top_bricks"] = [];

        var brick = el["bricks"];
        if(brick){
          if(brick.length>0){
          brick.forEach((e,index) => {
            let data_brick = {};
            data_brick["id_brick"] = e.properties.brick_id;
            data_brick["id_brick_vta"] = e.properties.sales_info.first_year_sales;
            if(index<5){
              el["top_bricks"].push(data_brick);
            }
           });
        }
      }
        return el;
      });
        this.dataStore.todos = data;
        this._todos.next(Object.assign({}, this.dataStore).todos);
      },
      (error) => console.log("Could not load todos.")
    );
  }


  loadAllMassive() {
    this.studiesMassiveUrl =
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/study_massive/";

    return this.http.get<Todo[]>(`${this.studiesMassiveUrl}`).subscribe(
      (data) => {
        data = data.map((el) => {
        el["id"] = el["properties"]["study"].study_id;
        el["nombre"] = el["properties"]["study_name"];
        el["building_type"] = el["properties"]["building_type"];        
        el["fecha creacion"] = el["properties"]["study"]["created"].split("T")
        .join(" ")
        .split("Z")
        .join("")
        .split(".")[0];
        el["estado"] = el["properties"]["study"]["state_description"];
        el["top_bricks"] = [];

        var brick = el["bricks"];
        if(brick){
          if(brick.length>0){
          brick.forEach((e,index) => {
            let data_brick = {};
            data_brick["id_brick"] = e.properties.brick_id;
            data_brick["id_brick_vta"] = e.properties.sales_info.first_year_sales;
            if(index<5){
              el["top_bricks"].push(data_brick);
            }
           });
        }
      }
        return el;
      });
        this.dataStoreMassive.todosMassive = data;
        this._todosMassive.next(Object.assign({}, this.dataStoreMassive).todosMassive);
      },
      (error) => console.log("Could not load todosMassive.")
    );
  }


  showAllPrescriptiveStudies(isChecked: boolean) {
    this.allStudiesCheckPrescriptive$.next(isChecked);
  }

  getFilteredPrescriptiveStudies(object: any): any {
    this.studyPrescriptiveFiltered$.next(object);
  }

  getZoomOnStudy(object: any): any {
    this.studyZoomPrescriptive$.next(object);
  }

  studyMapPrescriptive(isPredictive: any) {
    this.studyMapPrescriptive$.next(isPredictive);
  }

  studyMapPrescriptiveMassive(isPredictiveMassive: any) {
    this.studyMapPrescriptiveMassive$.next(isPredictiveMassive);
  }

  getBrickMap(object: any): any {
    this.brickPrescriptive$.next(object);
  }

  setBrickDataPrescriptive(brick: any): any {
    this.dataBrickSelected = brick;
  }

  getBrickDataPrescriptive() {
    return this.dataBrickSelected;
  }

  setIdStudyPrescriptive(id: any): any {
    this.idStudySelected = id;
  }
  getIdStudyPrescriptive() {
    return this.idStudySelected;
  }


  getFilteredBricksStudy(object: any): any {
    this.bricksStudyFiltered$.next(object);
  }

  getAllStudies(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/prescriptives/",
      {
        responseType: "json",
      }
    );
  }

  getBasicInfoStudy(studyId: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/prescriptive/" + //"605b0fa5f9b93cfd8ca6a00b" +
        studyId +
        "/",
      {
        responseType: "json",
      }
    );
  }

  getBasicInfoStudyMassive(studyId: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/study_massive/" +
        studyId +
        "/",
      {
        responseType: "json",
      }
    );
  }


  executeStudyPrescriptive(study: any): Observable<any> {
    return this.http.post(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/prescriptives/" ,
        study
        ,
        {
          headers: { "Content-Type": "application/json" }
        }
    ).pipe(catchError(this.handleError));
  }

  executeStudyMassivePrescriptive(study: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/study_massive/execute/"  +
        study._id +
        "/",
        {}
        
                
    ).pipe(catchError(this.handleError));
  }

  saveStudyMassivePrescriptive(study: any): Observable<any> {
    return this.http.post(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/study_massive/" ,
        study
        ,
        {
          headers: { "Content-Type": "application/json" }
        }
    ).pipe(catchError(this.handleError));
  }



  loadFileStudyMassivePrescriptive(study: any): Observable<any> {
    return this.http.post(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/mcd/file/send/" ,
        study
        ,
        {
          responseType: 'json'
    }
    );//.pipe(catchError(this.handleError));
  }


  deleteStudy(study: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.delete(
      environment.postmanHost +
        // ":" +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/prescriptive/" +
        study._id +
        "/",
      {
        responseType: "json",
      }
    );
  }

  deleteStudyMassive(study: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.delete(
      environment.postmanHost +
        // ":" +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/study_massive/" +
        study._id +
        "/",
      {
        responseType: "json",
      }
    );
  }

  updateStudyGroupPrescriptive(study: any): Observable<any> {
    return this.http.put(
      environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/prescriptive/"+study._id+"/" ,
        '{ "$set": {"properties.group": "'+study.properties.group+'"}  }'
        ,
        {
          headers: { "Content-Type": "application/json" }
        }
    ).pipe(catchError(this.handleError));
  }
  
  updateStudyGroupMassive(study: any): Observable<any> {
    return this.http.put(
        environment.postmanHost +
        environment.postmanDelimitator +
        environment.postmanPort +
        environment.postmanContext +
        "/study_massive/"+study._id+"/"  ,
        '{ "$set": {"properties.group": "'+study.properties.group+'"}  }'
        ,
        {
          headers: { "Content-Type": "application/json" }
        }
    ).pipe(catchError(this.handleError));
  }
  


  getStudyMap(object: any): any {
    this.studyPrescriptive$.next(object);
  }


  setHeaders() {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');

    const currentUser = this.authenticationService.currentUserValue;

    const token = `Basic ${currentUser.token}`;
    if (token !== '') {
        const tokenValue =  `Basic ${currentUser.token}`;
        this.headers = this.headers.set('Authorization', tokenValue);
    }
}
  getDownloadFileFromFolder( file: any) {
    const currentUser = this.authenticationService.currentUserValue;
   
    let url = environment.postmanHost +
    environment.postmanDelimitator +
    environment.postmanPort +
    environment.postmanContext +
    "/file_prescriptive/" +
    file +"/";
  
    this.setHeaders();
          this.http.get( url , {
              headers: this.headers
          }).subscribe(
            (data: any) => {        
                console.log(`open DownloadFile for file /${file}`);
                window.open(`${url}${currentUser.token}`);
              },
            error => {
                if(error=="OK"){
                  window.open(url)
                }else{
                  console.error("An error occurred:", error);
                }
              },
            () => {
                console.log(`complete DownloadFile for file /${file}`);
                window.open(`${url}${currentUser.token}`);
             } 
            );
  
    }

    getDownloadMassiveFileFromFolder( file: any) {
      const currentUser = this.authenticationService.currentUserValue;
     
      let url = environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/file_massive/" +
      file +"/";
    
      this.setHeaders();
            this.http.get( url , {
                headers: this.headers
            }).subscribe(
              (data: any) => {        
                  console.log(`open DownloadFile for file /${file}`);
                  window.open(`${url}${currentUser.token}`);
                },
              error => {
                  if(error=="OK"){
                    window.open(url)
                  }else{
                    console.error("An error occurred:", error);
                  }
                },
              () => {
                  console.log(`complete DownloadFile for file /${file}`);
                  window.open(`${url}${currentUser.token}`);
               } 
              );
    
      }


   

    setIdStudyMassivePrescriptive(id: any): any {
      this.idMassiveStudySelected = id;
    }

    getMassiveStudyMap(object: any): any {
      this.studyMassivePrescriptive$.next(object);
    }



  handleError(error: HttpErrorResponse) {
    let textError = "";
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
      textError = "Something bad happened; please try again later.";
    } else if (error.toString()=="OK"){
      textError = "OK";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      textError = "Something bad happened; please try again later.";
    }
    // return an observable with a user-facing error message
    return throwError(textError);
  }
}
