import { Component, OnInit, Input } from '@angular/core';
import { BrickReportMapService } from "../brick-report-map/brick-report-map.service";
import { BrickReportTableService } from './brick-report-table.service';


@Component({
  selector: 'app-brick-report-loader',
  templateUrl: './brick-report-loader.component.html',
  styleUrls: ['./brick-report-loader.component.scss']
})
export class BrickReportLoaderComponent implements OnInit {
  @Input() setup: any;
  section_commons: any;

  section_0: any;
  section_1: any;
  section_2: any;
  section_3: any
  map: any;

  selectedOrder: any="";

  constructor(

    private brickReportMapService: BrickReportMapService,
    private brickReportTableService: BrickReportTableService) { }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  parseFloatNumber(num) {
    return parseFloat(parseFloat(num).toFixed(0)).toLocaleString('es-ES', { useGrouping: true })
  }

  ngOnInit(): void {

    this.section_commons = {
      properties: {
        building_type: this.setup[0].properties.building_type,
        study: this.setup[0].properties.study,
        study_name: this.setup[0].properties.study_name
      }
    }
    //cover, front page with map
    for(var i=0;i<this.setup[0].properties.studies.length; i++){
      if(i>0){
        this.selectedOrder += " - ";
      }
      this.selectedOrder +=  this.setup[0].properties.studies[i].building_type ;
    }

    this.section_0 = Object.assign({
      id: 0,
      title: null,
      image: null,
      pages: null,
      province_name: this.setup[0].province_name,
      selectedOrder: this.selectedOrder,
      map: this.brickReportMapService.getMap(this.setup[0]),
    }, this.section_commons);

    // study table
    var orderTot = this.setup[0].properties.studies.length;
    var array =[];

    for(var i=0;i<this.setup[0].properties.studies.length; i++){
      var data = this.setup[0].properties.studies[i];
      var obj = {
        id: i+1,
        title: "Datos del estudio",
        image: '../../../assets/img/random/' + this.getRandomNumber(1, 35) + '.jpg',
        map: null,
        pages: i+1,
        province_name: null,
        tableSource: [
          { name: "province_name", translation: "Provincia", value: this.setup[0].province_name, type: 'string' },
          { name: "kind_building", translation: "Edificación", value: data.kind_building, type: 'string' },
          { name: "building_type", translation: "Tipología", value: data.building_type, type: 'string' },
          { name: "order", translation: "Orden de selección", value: data.order+"/"+orderTot, type: 'string' },
          { name: "min_sales", translation: "Ventas mínimas (€)", value: data.min_sales, type: 'euros' },
          { name: "max_sales_from_restaurants_percent", translation: "Máximo venta en otros restaurantes (%)", value: data.max_sales_from_restaurants_percent, type: 'percent' },
          { name: "max_restaurant_impact_percent", translation: "Impacto máximo (%)", value: data.max_restaurant_impact_percent, type: 'percent' },
          { name: "delivery", translation: "Delivery", value: data.delivery ? "Sí" : "No", type: 'string' },
          { name: "Mcafe", translation: "McCafe", value: data.mccafe ? "Sí" : "No", type: 'string' },
          { name: "mcauto", translation: "McAuto", value: data.mcauto ? "Sí" : "No", type: 'string' },
          { name: "mckiosko", translation: "McKiosko", value: data.mckiosko ? "Sí" : "No", type: 'string' },
          { name: "Mcexpress", translation: "McExpress", value: data.mcexpress ? "Sí" : "No", type: 'string' },
          { name: "type_play", translation: "Tipo Play", value: data.type_play, type: 'string' },
          { name: "seats_room", translation: "Asientos salón (nº)", value: data.seats_room, type: 'number' },
          { name: "seats_terrace_outdoor", translation: "Asientos terraza exterior (nº)", value: data.seats_terrace_outdoor, type: 'number' },
          { name: "seats_terrace_indoor", translation: "Asientos terraza interior (nº)", value: data.seats_terrace_indoor, type: 'number' },
          { name: "separate_entrance_mall", translation: "Entrada independiente CC", value: data.separate_entrance_mall, type: 'string' },
          { name: "total_parking_spaces", translation: "Plazas de parking (nº)", value: data.total_parking_spaces, type: 'number' },
          { name: "own_parking", translation: "Parking propio", value: data.own_parking, type: 'string' },
          { name: "own_parking_space", translation: "Plazas de parking propio (nº)", value: data.own_parking_space, type: 'number' },
          { name: "accesibility", translation: "Accesibilidad", value: data.accesibility, type: 'string' },
          { name: "visibility", translation: "Visibilidad", value: data.visibility, type: 'string' }
        ],
      };
      array.push(obj);
    }

    this.section_1 = Object.assign( array
    , this.section_commons);

    /*
    this.section_1 = Object.assign({
      id: 1,
      title: "Datos del estudio",
      image: '../../../assets/img/random/' + this.getRandomNumber(1, 35) + '.jpg',
      map: null,
      pages: null,
      province_name: null,
      tableSource: [
        { name: "province_name", translation: "Provincia", value: this.setup[0].province_name, type: 'string' },
        { name: "kind_building", translation: "Edificación", value: this.setup[0].properties.kind_building, type: 'string' },
        { name: "building_type", translation: "Tipología", value: this.setup[0].properties.building_type, type: 'string' },
        { name: "min_sales", translation: "Ventas mínimas (€)", value: this.setup[0].properties.min_sales, type: 'euros' },
        { name: "max_sales_from_restaurants_percent", translation: "Máximo venta en otros restaurantes (%)", value: this.setup[0].properties.max_sales_from_restaurants_percent, type: 'percent' },
        { name: "max_restaurant_impact_percent", translation: "Impacto máximo (%)", value: this.setup[0].properties.max_restaurant_impact_percent, type: 'percent' },
        { name: "delivery", translation: "Delivery", value: this.setup[0].properties.delivery ? "Sí" : "No", type: 'string' },
        { name: "Mcafe", translation: "McCafe", value: this.setup[0].properties.mccafe ? "Sí" : "No", type: 'string' },
        { name: "mcauto", translation: "McAuto", value: this.setup[0].properties.mcauto ? "Sí" : "No", type: 'string' },
        { name: "mckiosko", translation: "McKiosko", value: this.setup[0].properties.mckiosko ? "Sí" : "No", type: 'string' },
        { name: "Mcexpress", translation: "McExpress", value: this.setup[0].properties.mcexpress ? "Sí" : "No", type: 'string' },
        { name: "type_play", translation: "Tipo Play", value: this.setup[0].properties.type_play, type: 'string' },
        { name: "seats_room", translation: "Asientos salón (nº)", value: this.setup[0].properties.seats_room, type: 'number' },
        { name: "seats_terrace_outdoor", translation: "Asientos terraza exterior (nº)", value: this.setup[0].properties.seats_terrace_outdoor, type: 'number' },
        { name: "seats_terrace_indoor", translation: "Asientos terraza interior (nº)", value: this.setup[0].properties.seats_terrace_indoor, type: 'number' },
        { name: "separate_entrance_mall", translation: "Entrada independiente CC", value: this.setup[0].properties.separate_entrance_mall, type: 'string' },
        { name: "total_parking_spaces", translation: "Plazas de parking (nº)", value: this.setup[0].properties.total_parking_spaces, type: 'number' },
        { name: "own_parking", translation: "Parking propio", value: this.setup[0].properties.own_parking, type: 'string' },
        { name: "own_parking_space", translation: "Plazas de parking propio (nº)", value: this.setup[0].properties.own_parking_space, type: 'number' },
        { name: "accesibility", translation: "Accesibilidad", value: this.setup[0].properties.accesibility, type: 'string' },
        { name: "visibility", translation: "Visibilidad", value: this.setup[0].properties.visibility, type: 'string' }
      ],
    }, this.section_commons);
*/
    // mega brick tables
    //order bricks by building_type :
    this.setup[0].properties.bricks
    var bricksSortKB = [];
    for(var iOrd=0; iOrd < this.setup[0].properties.studies.length; iOrd++){      
      //recorremos array bricks y sacamos solo los de este filtro:
      var keyValue ;
      if(this.setup[0].properties.studies[iOrd].building_type == "Fst 2"){
        keyValue = "Fst2";
      }else if(this.setup[0].properties.studies[iOrd].building_type == "Fst 1"){
        keyValue = "Fst1";
      }else if(this.setup[0].properties.studies[iOrd].building_type == "Instore Medio"){
        keyValue = "Instore_medio";
      }else if(this.setup[0].properties.studies[iOrd].building_type == "Instore Urban"){
        keyValue = "Instore_urban";
      }

      var ordB;
      ordB = this.setup[0].properties.bricks.filter( obj => obj.properties.building_type == keyValue);
      bricksSortKB.push(...ordB);
    }
    this.setup[0].properties.bricks = bricksSortKB;    


    this.section_2 = Object.assign({
      id:this.setup[0].properties.studies.length+2,
      title: null,
      image: null,
      map: null,
      province_name: null,
      pages: this.brickReportTableService.getBrickForPages(this.setup[0].properties.bricks),
    }, this.section_commons);


  }

}
