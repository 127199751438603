import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { GeoAddMcdTableComponent } from "./geo-add-mcd-table.component";

import { AddDialogMcdBoxComponent } from "./add-dialog-mcd-box/add-dialog-mcd-box.component";

@NgModule({
    declarations: [GeoAddMcdTableComponent, AddDialogMcdBoxComponent],
    imports: [SharedModule],
    exports: [GeoAddMcdTableComponent],
    providers: []
})
export class GeoAddMcdTableModule {}
