<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checked" (change)="showAll()">Ver todos los estudios</mat-checkbox>
</section>

<section class="full-width clearfix margin-top--xs">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="places">
    <!-- ONE Column -->
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="studySelected(element.id, element.lat, element.long)">
        <p>
          <strong>{{ element.properties.info.name }}</strong>
        </p>
        <p>{{ element.properties.info.address }}</p>
        <p>{{ element.properties.study.state_description }}</p>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</section>