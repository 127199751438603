import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrickReportService } from './brick-report.service';
@Component({
  selector: 'app-brick-report',
  templateUrl: './brick-report.component.html',
  styleUrls: ['./brick-report.component.scss']
})
export class BrickReportComponent implements OnInit, OnDestroy  {

  brickReport$ = this.brickReportService.brickReport$;
  studyData: any;
  study_id: any;

  constructor(private brickReportService: BrickReportService) {
    this.brickReportService.reportPrescriptiveChanged$.subscribe(
      objectZoom => {
        this.brickReportService.getAll(objectZoom._id);
        sessionStorage.tryingUser = null;
      }
    );
  }

  ngOnInit(): void {}
  ngOnDestroy() {}

}
