<h1 mat-dialog-title>
  <strong>{{ action }}:</strong>
</h1>

<div mat-dialog-content>
  <section class="full-width clearfix padding-xs margin-top--s">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">

        <form [formGroup]="geoStudyFormGroup" (ngSubmit)="submit()">
          <mat-form-field>
            <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Dirección"
              formControlName="addressCtrl" required />
          </mat-form-field>

          <mat-form-field>
            <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Ciudad" formControlName="cityCtrl"
              required />
          </mat-form-field>

          <mat-form-field>
            <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Provincia"
              formControlName="provinceCtrl" required />
          </mat-form-field>

          <mat-form-field>
            <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Longitud" formControlName="lonCtrl"
              readonly required />
          </mat-form-field>

          <mat-form-field>
            <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Latitud" formControlName="latCtrl"
              readonly required />
          </mat-form-field>
        </form>
      </div>
      <div id="div_map_rest" class="col-xs-12 col-sm-12  col-md-6  col-lg-6">


        <app-mcdGeoLocatorMap style="width: 100%;" mapId="geoStudyLocatorMapRest"
          mapSize="regular"></app-mcdGeoLocatorMap>

        <div class="mat-dialog-actions margin-top--s">
          <button type="button" mat-button (click)="locateAddress();">
            Localizar
          </button>
        </div>
      </div>
    </div>
  </section>

</div>
<div mat-dialog-actions>
  <button mat-button type="submit" (click)="doAction()" [disabled]="!geoStudyFormGroup.valid">{{ action }}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancelar
  </button>
</div>