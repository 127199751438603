import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabComerciosComponent } from "./tab-comercios.component";

@NgModule({
  declarations: [TabComerciosComponent],
  imports: [SharedModule],
  exports: [TabComerciosComponent],
  providers: []
})
export class TabComerciosModule {}
