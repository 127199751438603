import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";

import { BrickStudiesRoutingModule } from "./brick-studies-routing.module";
import { BrickStudiesTableModule } from "./brick-studies-table/brick-studies-table.module";

@NgModule({
  declarations: [BrickStudiesRoutingModule.components],
  imports: [SharedModule, BrickStudiesRoutingModule, BrickStudiesTableModule],
  exports: [],
  providers: []
})
export class BrickStudiesModule {}
