<section class="full-width clearfix">
  <div class="section-container margin-top--l margin-bottom--xxl">
    <div class="row">
      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <h1 class="h1-page--title">
          Usuarios
        </h1>

      </div>
      <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
        <div class="form-button">
          <button mat-raised-button color="primary" class="button--m" routerLink="/users/new">
            Añadir nuevo
          </button>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">


        <!--  -->
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
        </mat-form-field>

        <!-- <table
                      mat-table
                      matSort
                      [dataSource]="dataSource"
                      class="mat-elevation-z8"
                    > -->
        <!-- <div class="mat-elevation-z8">
                      <table
                        #table
                        mat-table
                        [dataSource]="dataSource"
                        multiTemplateDataRows
                        matSort
                        class="users"
                      > -->

        <mat-table [dataSource]="dataSource" matSort class="users mat-elevation-z0 ">
          <!-- <mat-table [dataSource]="dataSource" class=" mat-elevation-z0 "> -->
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">{{ element.position }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let element">
              <p>
                <strong>{{ element.name }}</strong>
              </p>
              <p>
                <a [routerLink]="'/users/edit/' + element._id">Editar</a>
              </p>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Usuario</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>

          <!-- perfil Column -->
          <ng-container matColumnDef="perfil">
            <th mat-header-cell *matHeaderCellDef>Perfil</th>
            <td mat-cell *matCellDef="let element">{{ element.perfil }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
          <!-- </table>
                    </div> -->
        </mat-table>
        <div class="margin-top--xl"></div>
        <button mat-flat-button color="primary" (click)="deleteUser()">
          Delete
        </button>
        <div class="margin-top--xl"></div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        <div class="margin-top--xl"></div>
      </div>
    </div>
  </div>
  <div class="margin-top--xl"></div>
  <div class="margin-top--xl"></div>
  <div class="margin-top--xl"></div>
</section>