import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { GroupedBarComponent } from "./grouped-bar.component";

@NgModule({
  declarations: [GroupedBarComponent],
  imports: [SharedModule],
  exports: [GroupedBarComponent],
  providers: []
})
export class GroupedBarModule {}
