import {
  Component,
  ViewChild,
  Input,
  ViewEncapsulation,
  OnDestroy,
} from "@angular/core";
import { OnInit } from "@angular/core";

import { environment } from "src/environments/environment";

// forms
import { FormGroup, FormArray } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";
import { Router } from "@angular/router";

import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/app-services/data.service";
import { MatStepper } from "@angular/material/stepper";

// dummy data
import sampleMovilidad from "../../../assets/data/movilidad_new_form.json";

@Component({
  selector: "app-geo-new-form",
  templateUrl: "./geo-new-form.component.html",
  styleUrls: ["./geo-new-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GeoNewFormComponent implements OnInit, OnDestroy {
  title = "Nuevo formulario predictivo";
  sampleCompetencia: any;
  competitorsOth: any;
  commerces: any;
  mall_center: any;
  sampleMcDonalds: any;
  sampleMovilidad = sampleMovilidad;
  commercialCenterEdited: any;
  commercialCenterAdded: any;
  competitorsRemoved: any;
  competitorsAdded: any;
  mcDonaldsAdded: any
  commercesAdded: any;
  mcDonaldsRemoved: any;
  studyArray: any;
  geoStudyFormReady: boolean = false;
  // forms
  geoStudyFormGroup: FormGroup;
  buildingTypeSelected = "instore";
  show_div_kb_checkbox_zce: boolean = false;
  show_div_kb_checkbox_ndc: boolean = false;
 // checked_div_kb_checkbox_zce: boolean = false;
  checked_div_kb_checkbox_ndc: boolean = false;
  kind_building_toSend = "instore";
  playSelected = "No";
  loungeSelected = 0;
  independentEntanceSelected = "No";
  totalParkingSelected = 0;
  totalOwnParkingSelected = 0;
  ownParkingSelected = "Sí";
  accessibilitySelected = "A";
  visibilitySelected = "A";
  addressLocation: string;
  cityCtrl: string;
  provinceCtrl: string;
  lonCtrl: any;
  latCtrl: any;
  addressCtrl: any;
  postalCodeCtrl: any;
  idBricksSelected: any;
  mcautoCtrl: boolean = false;
  isEditable = true;
  dataPoint: any;
  projectNameCtrl: string;
  zonacomercialexistenteCtrl: boolean = false;
  nuevodesarrollocomercialCtrl: boolean = false;
  mccafeCtrl: boolean = true;
  mckioskoCtrl: boolean = false;
  mcexpressCtrl: boolean = false;
  mcdesayunoCtrl: boolean = false;
  mcdeliveryCtrl: boolean = false;
  commercesRemoved: any;//number = 0;
  commercesEdited: any;
  competitorOthEdited: any;
  outdoorSeatsCtrl = 0;
  indoorSeatsCtrl = 0;
  independentEntanceCtrl;
  totalParkingCtrl;
  totalOwnParkingCtrl;
  ownParkingCtrl;
  accessibilityCtrl;
  visibilityCtrl;
  data_point: any;
  data_study: any;
  study_created: boolean = false;
  hasPresenciaTrafico: boolean = false;
  isExecuted: boolean = false;
  isProcessed: boolean = false;
  urlImg = environment.imgUrl + "/helpers/lava.gif";
  partDisabled: boolean = false;
  disabledmap: boolean = false;

  disabledButton: boolean = true;

  @Input()
  inputData: string;
  @Input()
  inputCommerce: string;
  @Input()
  inputCommercialCenter: string;
  @Input()
  inputCompetitorOth: string;
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;
  unsubscribe4: any;
  unsubscribe5: any;
  unsubscribe6: any;
  unsubscribe7: any;
  unsubscribe8: any;
  unsubscribe9: any;
  unsubscribe10: any;
  unsubscribe11: any;
  unsubscribe12: any;
  unsubscribe13: any;
  unsubscribe14: any;
  unsubscribe15: any;
  unsubscribe16: any;
  unsubscribe17: any;
  unsubscribe18: any;

  constructor(
    private formBuilder: FormBuilder,
    private geoStudiesDataService: GeoStudiesDataService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {}

  commercialCenterEditedHandler(event) {
    this.commercialCenterEdited = event;
  }
  commercialCenterAddedHandler(event) {
    this.commercialCenterAdded = event;
  }

  competitorsRemovedHandler(event) {
    this.competitorsRemoved = event;
  }

  // competitors  handlers
  commercesEditedHandler(event) {
    //removed:

   /* this.commercesEdited = event;
    if (this.data_study.properties.study) {
      if (this.data_study.properties.study.version < 1) {
        const found = this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce.findIndex(
          (element) => element.ID_ === event.ID_
        );
        this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce[
          found
        ].num = event.num_mod;
      }
    }
    */

    /* this.unsubscribe = this.geoStudiesDataService
      .updatePresenceTrafficBrickStudy(this.data_study)
      .subscribe(
        response => {
          console.log("commercesEditedHandler Response is: ", response);
        },
        error => {
          console.error("commercesEditedHandler An error occurred, ", error);
        }
      ); */

      this.commercesRemoved = event;
    /*  if (this.commercesRemoved) {
        this.commercesRemoved.forEach((elementRemoved) => {
          let comp_o = this.data_study.properties.point_information.properties
            .areas.minutes_foot_00_10.properties.places_commerce; //places_commerce_original
          let comp = [];
          comp.push(comp_o);
          comp = comp.map((d) => {
              const found = d.findIndex(
                (element) => element._id === elementRemoved._id
              );
              d.splice(found, 1);
            //}

            return d;
          });
          this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce =comp[0];
          console.log("commerces: "+comp[0])  ;
        });
      }
*/
  }

  competitorsOthersEditedHandler(event) {
    this.competitorOthEdited = event;
    const found = this.data_study.properties.point_information.properties.areas.minutes_car_00_10.properties.places_benchmark.findIndex(
      (element) => element.ID_ === event.ID_
    );
    this.data_study.properties.point_information.properties.areas.minutes_car_00_10.properties.places_benchmark[
      found
    ].num = event.num_mod;

    /* this.unsubscribe2 = this.geoStudiesDataService
      .updatePresenceTrafficBrickStudy(this.data_study)
      .subscribe(
        response => {
          console.log("competitorsOthersEditedHandler Response is: ", response);
        },
        error => {
          console.error(
            "competitorsOthersEditedHandler An error occurred, ",
            error
          );
        }
      ); */
  }

  competitorsNext() {
    this.competitorsAdded;
    this.competitorsRemoved;
    this.mcDonaldsAdded;
    this.mcDonaldsRemoved;
    if (this.data_study) {
      if (this.competitorsRemoved) {
        this.competitorsRemoved.forEach((elementRemoved) => {
          let comp_o = this.data_study.properties.point_information.properties
            .areas.minutes_car_00_10.properties.places_benchmark; //places_benchmark_original
          let comp = [];
          comp.push(comp_o);
          comp = comp.map((d) => {
            if (!elementRemoved.ID_) {
              elementRemoved.id;
              const found = d.findIndex(
                (element) => element.id === elementRemoved.id
              );
              d.splice(found, 1);
            } else {
              const found = d.findIndex(
                (element) => element.properties.ID_ === elementRemoved.ID_
              );
              d.splice(found, 1);
            }

            return d;
          });
          this.data_study.properties.point_information.properties.areas.minutes_car_00_10.properties.places_benchmark =
            comp[0];

            //buscamos los eliminados en este area para quitarlos en el de 00_15:
          let comp_o_15 = this.data_study.properties.point_information.properties
            .areas.minutes_car_00_15.properties.places_benchmark; //places_benchmark_original
          let comp_15 = [];
          comp_15.push(comp_o_15);
          comp_15 = comp_15.map((d) => {
            if (!elementRemoved.ID_) {
              elementRemoved.id;
              const found = d.findIndex(
                (element) => element.id === elementRemoved.id
              );
              d.splice(found, 1);
            } else {
              const found = d.findIndex(
                (element) => element.properties.ID_ === elementRemoved.ID_
              );
              d.splice(found, 1);
            }

            return d;
          });
          this.data_study.properties.point_information.properties.areas.minutes_car_00_15.properties.places_benchmark =
            comp_15[0];
        });
      }

      if (this.competitorsAdded) {
        this.competitorsAdded.forEach((elementAdded, i) => {
          let mov = {
            properties: {
              ID_: -i,
              class: "benchmark",
              info: {
                name: elementAdded.name,
                address: elementAdded.address,
                brand: elementAdded.name,
                id_brand: elementAdded.id_brand,
                //area: elementAdded.distance_bbdd,
                //area: elementAdded.distance,

                area: "minutes_foot_00_03",

                ID_: -i,

                address_view: elementAdded.address_view,
                address_all: elementAdded.address_all,
                city: elementAdded.city,
                province: elementAdded.province,
                y: elementAdded.lat,
                x: elementAdded.long,
              },
            },
            geometry: 
            {
              type : "Point",
              coordinates : [ 
                elementAdded.long,
                elementAdded.lat
              ]
            }
          };

          this.data_study.properties.point_information.properties.areas.minutes_car_00_10.properties.places_benchmark.push(
            mov
          );
        });
      }
      if (this.mcDonaldsAdded) {
        this.mcDonaldsAdded.forEach((elementAdded, i) => {
          let mov = {
            _id: ""+ (-i) +"" ,
            properties: {
              ID_: -i,
              info: {
                ID_: -i,
                name: elementAdded.name,
                status: "Añadido",
                address: elementAdded.address,
                kind_building: elementAdded.kind_building,
                view_kind_building: elementAdded.view_kind_building,
                address_view: elementAdded.address_view,
                address_all: elementAdded.address_all,
                city: elementAdded.city,
                town: elementAdded.city,
                province: elementAdded.province,
                y: elementAdded.lat,
                x: elementAdded.long,

                mccafe: true, //elementAdded.mccafeCtrl?"SI":"NO",
                mckiosko: elementAdded.mckioskoCtrl?"SI":"NO",
                mcexpress: elementAdded.mcexpressCtrl?"SI":"NO",
                mcauto: elementAdded.mcautoCtrl?"SI":"NO",
                delivery: elementAdded.mcdeliveryCtrl?"SI":"NO",
                nuevodesarrollocomercial: elementAdded.nuevodesarrollocomercial,

                type_play: elementAdded.playCtrl,
                seat_room: elementAdded.loungeSeatsCtrl,
                seat_terrace_outward: elementAdded.outdoorSeatsCtrl,
                seat_terrace_indoor: elementAdded.indoorSeatsCtrl,
                separate_entrance_mall: elementAdded.independentEntanceCtrl,
                total_parking_spaces: elementAdded.totalParkingCtrl,
                own_parking_space: elementAdded.totalOwnParkingCtrl,
                accessibility: elementAdded.accessibilityCtrl,
                visibility: elementAdded.visibilityCtrl,
                sales_estimation: elementAdded.sales_estimation,
                area: "minutes_foot_00_03"
                //ToDo remove area, its just to send to nommon by now


              },
            },
            geometry: 
            {
              type : "Point",
              coordinates : [ 
                elementAdded.long,
                elementAdded.lat
              ]
            }
          };

          this.data_study.properties.point_information.properties.areas.minutes_car_00_10.properties.places_main.push(
            mov
          );
        });
      }
      

      if (this.mcDonaldsRemoved) {
        this.mcDonaldsRemoved.forEach((elementMcdRemoved) => {
          let compmcd_o = this.data_study.properties.point_information
            .properties.areas.minutes_car_00_10.properties.places_main;
          let comp_mcd = [];
          comp_mcd.push(compmcd_o);
          comp_mcd = comp_mcd.map((d) => {
            const found_mcd = d.findIndex(
              (element) => element.properties.ID_ === elementMcdRemoved.ID_
            );
            d.splice(found_mcd, 1);
            return d;
          });
          this.data_study.properties.point_information.properties.areas.minutes_car_00_10.properties.places_main =
            comp_mcd[0];
        });
      }

      /* this.unsubscribe3 = this.geoStudiesDataService
        .updatePresenceTrafficBrickStudy(this.data_study)
        .subscribe(
          response => {
            console.log("Response is: ", response);
          },
          error => {
            console.error("An error occurred, ", error);
          }
        ); */
    }
  }

  commercesNext() {
    this.commercesRemoved;
    if (this.commercesAdded) {
      this.commercesAdded.forEach((elementAdded, i) => {
        let mov = {
          _id: ""+ (-i) +"" ,
          properties: {
          num: elementAdded.num,
          ID_: elementAdded.commerce,
          info: {
            name: elementAdded.commerce,
            id_brand: elementAdded.commerce,
            brand: elementAdded.commerce,
            category: elementAdded.category,
            address: elementAdded.address,
            //area: elementAdded.distance_bbdd,
           // area: elementAdded.distance,
           area: "minutes_foot_00_03",
            ID_: null,
            address_view: elementAdded.address_view,
            address_all: elementAdded.address_all,
            city: elementAdded.city,
            province: elementAdded.province,
            y: elementAdded.lat,
            x: elementAdded.long,
          },
        },
        geometry: 
            {
              type : "Point",
              coordinates : [ 
                elementAdded.long,
                elementAdded.lat
              ]
            }
        };
        this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce.push(
          mov
        );
      });
    }

    if (this.commercesRemoved) {
      this.commercesRemoved.forEach((elementRemoved) => {
        let comp_o = this.data_study.properties.point_information.properties
          .areas.minutes_foot_00_10.properties.places_commerce; //places_commerce_original
        let comp = [];
        comp.push(comp_o);
        comp = comp.map((d) => {
          /*if (!elementRemoved._id) {
            elementRemoved._id;
            const found = d.findIndex(
              (element) => element._id === elementRemoved._id
            );
            d.splice(found, 1);
          } else {
            */
            const found = d.findIndex(
              (element) => element._id === elementRemoved._id
            );
            d.splice(found, 1);
          //}

          return d;
        });
        this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce =comp[0];
        //buscamos los eliminados en este area para quitarlos en el de 00_15:
        let comp_o_15 = this.data_study.properties.point_information.properties
          .areas.minutes_foot_00_15.properties.places_commerce; //places_commerce_original
        let comp_15 = [];
        comp_15.push(comp_o_15);
        comp_15 = comp_15.map((d) => {         
            const found = d.findIndex(
              (element) => element._id === elementRemoved._id
            );
            d.splice(found, 1);
          return d;
        });
        this.data_study.properties.point_information.properties.areas.minutes_foot_00_15.properties.places_commerce =comp_15[0];
      });
    }


    //this.data_study.properties.study.state_code = "edited";
    this.unsubscribe4 = this.geoStudiesDataService
      .updatePresenceTrafficBrickStudy(this.data_study)
      .subscribe(
        (response) => {
          console.log("commercesNext Response is: ", response);
        },
        (error) => {
          console.error("commercesNext An error occurred, ", error);
        }
      );
  }

  saveStudy() {
    this.data_study.properties.study.state_code = "edited";

    this.data_study;
    this.commercialCenterEdited;
    this.commercialCenterAdded;

    if (this.commercialCenterEdited) {
      let mall_rm = [];
      mall_rm.push(this.commercialCenterEdited);
      mall_rm = mall_rm.map((d) => {
        d.forEach((elementEdited) => {
          if (elementEdited.action) {
            if (elementEdited.action == "Editar") {
              const found_mall = d.findIndex(
                (element) =>
                  element.properties.ID_ === elementEdited.properties.ID_
              );
              //delete
              if (found_mall != -1) {
                //not found
                d.splice(found_mall, 1);
                //add
                let infoCC = elementEdited.model;
                let ccEdited = {
                  _id: elementEdited._id,
                  geometry: elementEdited.geometry,
                  properties: {
                    ID_: elementEdited.properties.ID_,
                    class: elementEdited.properties.class,
                    info: {
                      id_brand: elementEdited.properties.info.id_brand,
                      name: infoCC.name,
                      type: infoCC.type,
                      cp: elementEdited.properties.info.cp,
                      province: elementEdited.properties.info.province,
                      ambit: elementEdited.properties.info.ambit,
                      date_open: elementEdited.properties.info.date_open,
                      size: elementEdited.properties.info.size,
                      sba_total: infoCC.sba_total,
                      total_commerce: infoCC.total_commerce,
                      engine: elementEdited.properties.info.engine,
                      engine_type: infoCC.engine_type,
                      engine_surface: infoCC.engine_surface,
                      main_commerce:
                        elementEdited.properties.info.main_commerce,
                      main_commerce_surface:
                        elementEdited.properties.info.main_commerce_surface,
                      cinemas: infoCC.cinemas,
                      sba_power_supply: infoCC.sba_power_supply,
                      sba_fashion_complement: infoCC.sba_fashion_complement,
                      sba_home_brico_electronic:
                        infoCC.sba_home_brico_electronic,
                      sba_services: infoCC.sba_services,
                      sba_various: infoCC.sba_various,
                      sba_rest_leisure: infoCC.sba_rest_leisure,
                      brand: elementEdited.properties.info.brand,
                      //new params:
                      exterior_shops: elementEdited.properties.info.exterior_shops,
                      interior_shops: elementEdited.properties.info.interior_shops,
                      total_parking_spaces: elementEdited.properties.info.total_parking_spaces,
                      transport_car: elementEdited.properties.info.transport_car,
                      transport_foot: elementEdited.properties.info.transport_foot,
                      transport_public: elementEdited.properties.info.transport_public,
                    },
                  },
                };
                d.push(ccEdited);
              }
            } else {
              //removed
            }
          }
        });
        return d;
      });
      this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_mall =
        mall_rm[0];
    }

    if (this.commercialCenterAdded) {
      this.commercialCenterAdded.forEach((elementAdded) => {
        let infoCC = elementAdded.properties;
        let ccAdded = {
          _id: null,
          geometry: 
            {
              type : "Point",
              coordinates : [ 
                elementAdded.properties.info.x,
                elementAdded.properties.info.y
              ]
            },
          properties: {
            ID_: infoCC.info.id_brand,
            class: "mall",
            info: infoCC.info
          },
          
        };
        this.data_study.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_mall.push(
          ccAdded
        );
      });
    }

    this.unsubscribe5 = this.geoStudiesDataService
      .updatePresenceTrafficBrickStudy(this.data_study)
      .subscribe(
        (response) => {
          console.log("saveStudy Response is: ", response);
          //check if presence traffic exist:
          this.unsubscribe6 = this.geoStudiesDataService
            .getBasicInfoStudy(this.data_study._id)
            .subscribe((study_pt) => {
              if (study_pt.properties.data_model) {
                if (
                  study_pt.properties.data_model.presence &&
                  study_pt.properties.data_model.traffic
                ) {
                  this.hasPresenciaTrafico = true;
                  this.data_study.properties.data_model =
                    study_pt.properties.data_model;
                  this.patchedValues(study_pt);
                }
              }
            });
        },
        (error) => {
          console.error("saveStudy An error occurred, ", error);
        }
      );
  }
  movilidadBefore() {
    //not come back
    if (this.data_study.properties.study.state_code == "processing_2") {
      this.stepper.selectedIndex = 4;
      this.isExecuted = true;
    }
  }
  movilidadNext() {
    if (this.data_study.properties.data_model) {
      if (this.data_study.properties.data_model.presence) {
        this.hasPresenciaTrafico = true;
        this.data_study.properties.data_model.presence.average_values.year.floating = this.geoStudyFormGroup.value.formArray[6].flotante_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.foreign_tourists = this.geoStudyFormGroup.value.formArray[6].turista_extranjero_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.national_tourists = this.geoStudyFormGroup.value.formArray[6].turista_nacional_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.residence = this.geoStudyFormGroup.value.formArray[6].residentes_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.work = this.geoStudyFormGroup.value.formArray[6].trabajadores_iCtrl;
        this.data_study.properties.data_model.traffic.average_values.transit = this.geoStudyFormGroup.value.formArray[6].volumen_total_iCtrl;

        this.unsubscribe7 = this.geoStudiesDataService
          .updatePresenceTrafficBrickStudy(this.data_study)
          .subscribe(
            (response) => {
              console.log("movilidadNext Response is: ", response);
            },
            (error) => {
              console.error("movilidadNext An error occurred, ", error);
            }
          );
      }
    }
  }
  competitorsAddedHandler(event) {
    this.competitorsAdded = event;
  }

  mcDonaldsAddedHandler(event) {
    this.mcDonaldsAdded = event;
  }

  commercesAddedHandler(event) {
    this.commercesAdded = event;
  }

  mcDonadsRemovedHandler(event) {
    this.mcDonaldsRemoved = event;
  }

  getMobilityValue = (parent: string, key: string) => {
    let p = this.sampleMovilidad
      .filter((d) => {
        return d["key"] === parent;
      })[0]
      .values.filter((e) => {
        return e["key"] === key;
      })[0];
    return p["input"] && p["input"] != "" ? p["input"] : p["value"];
  };

  ngOnInit() {
    this.stepper.linear = true;
    this.unsubscribe8 = this.geoStudiesDataService.studyParamsPredictiveChanged$.subscribe(
      (params) => {
        this.lonCtrl = params.x;
        this.latCtrl = params.y;
        this.addressLocation = params.address;
        this.cityCtrl = params.locality;
        this.provinceCtrl = params.province;
        this.studyArray = this.geoStudyFormGroup.controls
          .formArray as FormArray;
        this.studyArray.controls[0].patchValue({
          addressCtrl: params.address,
          cityCtrl: params.locality,
          provinceCtrl: params.province,
          lonCtrl: params.x,
          latCtrl: params.y,
        });
      }
    );

    this.geoStudyFormGroup =
      // Localización [0]
      this.formBuilder.group({
        formArray: this.formBuilder.array([
          this.formBuilder.group({
            zonacomercialexistenteCtrl: [this.zonacomercialexistenteCtrl],
            nuevodesarrollocomercialCtrl: [this.nuevodesarrollocomercialCtrl],
            mccafeCtrl: [true],//[this.mccafeCtrl],
            mckioskoCtrl: [this.mckioskoCtrl],
            mcexpressCtrl: [this.mcexpressCtrl],
            mcautoCtrl: [this.mcautoCtrl],
            mcdesayunoCtrl: [this.mcdesayunoCtrl],
            mcdeliveryCtrl: [this.mcdeliveryCtrl],
            projectNameCtrl: [this.projectNameCtrl, Validators.required],
            buildingTypeCtrl: [this.buildingTypeSelected, Validators.required],
            addressCtrl: ["", Validators.required],
            cityCtrl: [this.cityCtrl, Validators.required],
            provinceCtrl: [this.provinceCtrl, Validators.required],
            lonCtrl: [this.lonCtrl, Validators.required],
            latCtrl: [this.latCtrl, Validators.required],
          }),
          // Trafico [1]
          this.formBuilder.group({}),
          // Características [2]
          this.formBuilder.group({
            playCtrl: [this.playSelected, Validators.required],
            loungeSeatsCtrl: [this.loungeSelected, Validators.required],
            outdoorSeatsCtrl: [this.outdoorSeatsCtrl, Validators.required],
            indoorSeatsCtrl: [this.indoorSeatsCtrl, Validators.required],
            independentEntanceCtrl: [
              this.independentEntanceSelected,
              Validators.required,
            ],
            totalParkingCtrl: [this.totalParkingSelected, Validators.required],
            totalOwnParkingCtrl: [
              this.totalOwnParkingSelected,
              Validators.required,
            ],
            ownParkingCtrl: [this.ownParkingSelected, Validators.required],
            accessibilityCtrl: [
              this.accessibilitySelected,
              Validators.required,
            ],
            visibilityCtrl: [this.visibilitySelected, Validators.required],
          }),
          // Restauracion [3]
          this.formBuilder.group({}),
          // Comercio [4]
          this.formBuilder.group({}),
          // centros comerciales [5]
          this.formBuilder.group({}),
          // Movilidad [6]

          this.formBuilder.group({
            residentes_vCtrl: [
              {
                value: this.getMobilityValue("presencia", "residentes"),
                disabled: true,
              },
              Validators.required,
            ],
            residentes_iCtrl: [
              {
                value: this.getMobilityValue("presencia", "residentes"),
                disabled: false,
              },
              Validators.required,
            ],
            trabajadores_vCtrl: [
              {
                value: this.getMobilityValue("presencia", "trabajadores"),
                disabled: true,
              },
              Validators.required,
            ],
            trabajadores_iCtrl: [
              {
                value: this.getMobilityValue("presencia", "trabajadores"),
                disabled: false,
              },
              Validators.required,
            ],

            flotante_vCtrl: [
              {
                value: this.getMobilityValue("presencia", "flotante"),
                disabled: true,
              },
              Validators.required,
            ],
            flotante_iCtrl: [
              {
                value: this.getMobilityValue("presencia", "flotante"),
                disabled: false,
              },
              Validators.required,
            ],

            turista_nacional_vCtrl: [
              {
                value: this.getMobilityValue("presencia", "turista_nacional"),
                disabled: true,
              },
              Validators.required,
            ],
            turista_nacional_iCtrl: [
              {
                value: this.getMobilityValue("presencia", "turista_nacional"),
                disabled: false,
              },
              Validators.required,
            ],

            turista_extranjero_vCtrl: [
              {
                value: this.getMobilityValue("presencia", "turista_extranjero"),
                disabled: true,
              },
              Validators.required,
            ],
            turista_extranjero_iCtrl: [
              {
                value: this.getMobilityValue("presencia", "turista_extranjero"),
                disabled: false,
              },
              Validators.required,
            ],

            volumen_total_vCtrl: [
              {
                value: this.getMobilityValue("trafico", "volumen_total"),
                disabled: true,
              },
              Validators.required,
            ],
            volumen_total_iCtrl: [
              {
                value: this.getMobilityValue("trafico", "volumen_total"),
                disabled: false,
              },
              Validators.required,
            ],
          }),
          // ends [7]
          //this.formBuilder.group({})
        ]),
      });

    this.geoStudyFormReady = true;

    this.unsubscribe9 = this.route.data.subscribe((d) => {
      if (d.mappedParams) {
        this.study_created = true;
        //if it is a brick, not patch
        //if (d.mappedParams._id) {
        if (d.mappedParams.properties.class_ == "brick") {
          this.lonCtrl = d.mappedParams.properties.centroid.coordinates[0];
          this.latCtrl = d.mappedParams.properties.centroid.coordinates[1];
          this.locateCoordinates();

          // this.partDisabled = true;
          // this.disabledmap = true;
        } else {
          this.data_study = d.mappedParams;
          if (d.mappedParams.properties.data_model) {
            if (d.mappedParams.properties.data_model.traffic_original) {
              this.hasPresenciaTrafico = true;
              let tmp_tr =
                d.mappedParams.properties.data_model.traffic_original
                  .average_values;
              let tmp_pr =
                d.mappedParams.properties.data_model.presence_original
                  .average_values.year;
              let mov = [
                {
                  key: "presencia",
                  values: [
                    {
                      key: "residentes",
                      value: tmp_pr.residence,
                      input: tmp_pr.residence,
                      name: "Residentes",
                    },
                    {
                      key: "trabajadores",
                      value: tmp_pr.work,
                      input: tmp_pr.work,
                      name: "Trabajadores",
                    },
                    {
                      key: "flotante",
                      value: tmp_pr.floating,
                      input: tmp_pr.floating,
                      name: "Flotante",
                    },
                    {
                      key: "turista_nacional",
                      value: tmp_pr.national_tourists,
                      input: tmp_pr.national_tourists,
                      name: "Turista nacional",
                    },
                    {
                      key: "turista_extranjero",
                      value: tmp_pr.foreign_tourists,
                      input: tmp_pr.foreign_tourists,
                      name: "Turista extranjero",
                    },
                  ],
                },
                {
                  key: "trafico",
                  values: [
                    {
                      key: "volumen_total",
                      value: tmp_tr.transit,
                      input: tmp_tr.transit,
                      name: "Volumen total",
                    },
                  ],
                },
              ];
              this.sampleMovilidad = mov;
            }
          }
          if (d.mappedParams.properties.study.state_code == "edited") {
            this.patchedValues(d.mappedParams);
            this.data_study = d.mappedParams;
            this.stepper.selectedIndex = 5;
            if (d.mappedParams.properties.data_model.presence) {
              this.hasPresenciaTrafico = true;
            } else {
              this.hasPresenciaTrafico = false;
            }
          } else if (
            // d.mappedParams.properties.study.state_code == "processing_2" ||
            // d.mappedParams.properties.study.state_code == "processing_1"
            d.mappedParams.properties.study.state_code == "processing_1" ||
            d.mappedParams.properties.study.state_code == "edited_pt" ||
            d.mappedParams.properties.study.state_code == "pt"
          ) {
            this.patchedValues(d.mappedParams);
            this.data_study = d.mappedParams;
            //this.stepper.selectedIndex = 1;
            //modified
            this.stepper.selectedIndex = 5;
            // this.stepper.selectedIndex = 4;
            this.hasPresenciaTrafico = true;
            this.stepper.linear = false;
          } else if (
            d.mappedParams.properties.study.state_code == "processing_2"
            // || d.mappedParams.properties.study.state_code == "processed"
          ) {
            this.patchedValues(d.mappedParams);
            this.data_study = d.mappedParams;
            //this.stepper.selectedIndex = 6;
            this.stepper.selectedIndex = 5;
            this.isExecuted = true;
            //new version:
          } else if (
            this.data_study.properties.study.state_code == "processed"
          ) {
            this.geoStudiesDataService
              .updatePresenceTrafficBrickStudy(this.data_study)
              .subscribe(
                (response) => {
                  //do something with the response
                  console.log("new version study Response is: ", response);

                  this.patchedValues(response);
                  this.locateCoordinates();
                  this.data_study = response;

                  //this.geoStudyFormGroup.value["formArray"][0].buildingTypeCtrl.disable();
                  let array = this.geoStudyFormGroup.controls
                    .formArray as FormArray;
                  array.controls[0].get("buildingTypeCtrl").disable();

                  this.partDisabled = true;
                  this.disabledmap = true;

                  this.isExecuted = false;
                  this.isProcessed = false;
                  this.stepper.selectedIndex = 0;
                },
                (error) => {
                  //catch the error
                  console.error("new version study An error occurred, ", error);
                }
              );
            // this.stepper.selectedIndex = 6;
            // this.isExecuted = false;
            // this.isProcessed = true;
          }
          //  else if (d.mappedParams.properties.study.state_code == "edited") {
          //   this.patchedValues(d.mappedParams);
          //   this.data_study = d.mappedParams;
          //   // this.stepper.selectedIndex = 5;
          //   this.stepper.selectedIndex = 4;
          //   if (d.mappedParams.properties.data_model.presence) {
          //     this.hasPresenciaTrafico = true;
          //   } else {
          //     this.hasPresenciaTrafico = false;
          //   }
          // }
          else {
            this.lonCtrl = d.mappedParams.geometry.coordinates[0];
            this.latCtrl = d.mappedParams.geometry.coordinates[1];
            this.locateCoordinates();
            this.patchedValues(d.mappedParams);
            this.unsubscribe10 = this.geoStudiesDataService
              .getBasicInfoStudy(d.mappedParams._id)
              .subscribe((study_basic_data) => {
                this.data_study = study_basic_data;
                let comp = [];
                comp.push(study_basic_data);
                comp = comp.map((d) => {
                  delete d.type;
                  return d;
                });

                this.addData(comp[0], 0);
                this.geoStudiesDataService.competitorsPointPredictive(
                  study_basic_data
                );
                this.geoStudiesDataService.commercesPredictive(
                  study_basic_data
                );
                this.geoStudiesDataService.mallPredictive(study_basic_data);
              });
          }
        }
        // else {
        //   this.lonCtrl = d.mappedParams.properties.centroid.coordinates[0];
        //   this.latCtrl = d.mappedParams.properties.centroid.coordinates[1];
        //   this.locateCoordinates();
        // }
      }
    });

    //load competitors:
    this.unsubscribe11 = this.geoStudiesDataService.competitorsPointPredictiveChanged$.subscribe(
      (object) => {
        //load original competitors:
        let comp =
          object.properties.point_information.properties.areas.minutes_car_00_10
            .properties.places_benchmark_original;
        this.sampleCompetencia = comp.filter((dt) => dt.ID_ != "Others");
        this.sampleCompetencia = comp.filter((dtt) => dtt.properties.class == "benchmark");
        

        this.sampleCompetencia = this.sampleCompetencia.map(function (e) {
          if (e.properties) {
            if (e.properties.info) {
              e.properties.info = e.properties.info;
              e.properties.info.ID_ = e.properties.ID_;
              e.properties.info.distance_tostudy = Math.round(
                e.properties.distance_tostudy
              );
              e = e.properties.info;
            } else {
              console.log(e);
            }
          } else {
            console.log(e);
            //no importantes Others
            //this.competitorsOth = comm;
          }

          return e;
        });

        // this.competitorsOth = comp.filter.filter(dt => dt.ID_ == "Others");
        this.competitorsOth = comp.filter((dt) => dt.ID_ == "Others");
        this.competitorsOth = comp.filter((dt) => dt.properties.class == "benchmark2");
        this.competitorsOth= /*this.competitorsOth.map(function (e) {
          e.num_mod = e.num;
          return e;
        });*/
        [{name : 'Otros',
        num: this.competitorsOth.length
      }];
        //load mcd original:
        let mcd =
          object.properties.point_information.properties.areas.minutes_car_00_10
            .properties.places_main_original;
        this.sampleMcDonalds = mcd.map(function (e) {
          e.properties.info = e.properties.info;
          e.properties.info.ID_ = e.properties.ID_;
          e.properties.info.distance_tostudy = Math.round(
            e.properties.distance_tostudy
          );
          e = e.properties.info;
          return e;
        });
      }
    );

    //load commerces:
    this.unsubscribe12 = this.geoStudiesDataService.commercesPredictiveChanged$.subscribe(
      (object) => {
        //load original commerces:
        let comm =
          object.properties.point_information.properties.areas
            .minutes_foot_00_10.properties.places_commerce_original;
        comm.map(function (e) {
          //find in modificated:
          const found_mod = object.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce.findIndex(
            (element) => element.ID_ === e.ID_
          );
          e.num_mod =
            object.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce[
              found_mod
            ].num;
          return e;
        });
        this.commerces = comm;
      }
    );

    //load commercial centers:
    this.unsubscribe13 = this.geoStudiesDataService.mallPredictiveChanged$.subscribe(
      (object) => {
        let mall_o =
          object.properties.point_information.properties.areas
            .minutes_foot_00_10.properties.places_mall;
        this.mall_center = mall_o;
        this.mall_center = mall_o.map(function (e) {
          e.properties.info.distance_tostudy = Math.round(
            e.properties.distance_tostudy
          );        
          return e;
        });


      }
    );
  }

  ngAfterViewInit() {
    if (this.stepper.selectedIndex) {
      this.stepper.linear = false;
    } else {
      this.stepper.linear = true;
    }
  }

  get formArray(): any | null {
    return this.geoStudyFormGroup.get("formArray");
  }

  stepClick(ev, stepperr) {
    if (this.stepper.linear == true) {
      if (ev.selectedIndex == 1) {
        this.stepper.linear = true;
        this.loadTransit();
      }
    } else {
      this.stepper.linear = true;
      this.stepper.selectedIndex = 0;
      ev.selectedIndex = 0;
    }
  }

  goBack(stepper: MatStepper) {
    this.stepper = stepper;
    this.stepper.previous();
  }
  goForward(stepper: MatStepper) {
    this.stepper.next();
  }

  locateAddress: any = () => {
    this.addressLocation = this.geoStudyFormGroup.value[
      "formArray"
    ][0].addressCtrl;
    this.cityCtrl = this.geoStudyFormGroup.value["formArray"][0].cityCtrl;
    this.provinceCtrl = this.geoStudyFormGroup.value[
      "formArray"
    ][0].provinceCtrl;
    if (this.addressLocation != undefined) {
      let adress = this.addressLocation;
      let city = this.cityCtrl;
      let province = this.provinceCtrl;
      let params = adress + " , " + city + " , " + province;
      this.geoStudiesDataService.addressPredictive(params);
    }
  };

  patchedValues(data: any): any {
    let array = this.geoStudyFormGroup.controls.formArray as FormArray;
    let valuesArray = this.geoStudyFormGroup.getRawValue();
    if (data.properties) {
      if (data.properties.info.kind_building == "instore") {
        this.buildingTypeSelected = "instore";
        this.show_div_kb_checkbox_zce = false;
        this.show_div_kb_checkbox_ndc = false;
        //this.checked_div_kb_checkbox_zce = false;
        this.checked_div_kb_checkbox_ndc = false;
        this.kind_building_toSend = data.properties.info.kind_building;

        this.zonacomercialexistenteCtrl = false;
        this.nuevodesarrollocomercialCtrl = false;
      } else if (data.properties.info.kind_building.includes("mall")) {
        this.buildingTypeSelected = "mall";
        this.kind_building_toSend = data.properties.info.kind_building;
        if (
          data.properties.info.kind_building.includes(
            "nuevodesarrollocomercial"
          )
        ) {
          this.show_div_kb_checkbox_zce = false;
          this.show_div_kb_checkbox_ndc = true;
         // this.checked_div_kb_checkbox_zce = false;
          this.checked_div_kb_checkbox_ndc = true;
          this.zonacomercialexistenteCtrl = false;
          this.nuevodesarrollocomercialCtrl = true;
        } else {
          this.show_div_kb_checkbox_zce = false;
          this.show_div_kb_checkbox_ndc = false;
         // this.checked_div_kb_checkbox_zce = false;
          this.checked_div_kb_checkbox_ndc = false;
          this.zonacomercialexistenteCtrl = false;
          this.nuevodesarrollocomercialCtrl = false;
        }
      } else if (data.properties.info.kind_building.includes("freestanding")) {
        this.buildingTypeSelected = "freestanding";
        this.kind_building_toSend = data.properties.info.kind_building;
        if (
          data.properties.info.kind_building.includes(
            "nuevodesarrollocomercial"
          )
        ) {
          this.buildingTypeSelected = "freestanding";
         // this.checked_div_kb_checkbox_zce = true;
          this.checked_div_kb_checkbox_ndc = true;

          this.show_div_kb_checkbox_zce = true;
          this.show_div_kb_checkbox_ndc = true;
          this.zonacomercialexistenteCtrl = true;
          this.nuevodesarrollocomercialCtrl = true;
        } else if (
          data.properties.info.kind_building.includes("zonacomercialexistente")
        ) {
         // this.checked_div_kb_checkbox_zce = true;

          this.checked_div_kb_checkbox_ndc = false;
          this.show_div_kb_checkbox_zce = true;
          this.show_div_kb_checkbox_ndc = false;
          this.zonacomercialexistenteCtrl = true;
          this.nuevodesarrollocomercialCtrl = false;
          this.buildingTypeSelected = "freestanding";
        } else {
          this.buildingTypeSelected = "freestanding";
          this.show_div_kb_checkbox_zce = false;
          this.show_div_kb_checkbox_ndc = false;
         // this.checked_div_kb_checkbox_zce = false;
          this.checked_div_kb_checkbox_ndc = false;
          this.zonacomercialexistenteCtrl = false;
          this.nuevodesarrollocomercialCtrl = false;
        }
      }

      this.mccafeCtrl = true;//data.properties.info.mccafe;
      this.mckioskoCtrl = data.properties.info.mckiosko;
      this.mcexpressCtrl = data.properties.info.mcexpress;
      this.mcautoCtrl = data.properties.info.mcauto;
      this.mcdesayunoCtrl = data.properties.info.breakfast;
      this.mcdeliveryCtrl = data.properties.info.mcdelivery;
      this.projectNameCtrl = data.properties.info.name;
      this.buildingTypeSelected;
      this.addressCtrl = data.properties.info.address;
      this.cityCtrl = data.properties.info.town;
      this.provinceCtrl = data.properties.info.province;
      this.lonCtrl = data.properties.info.long;
      this.latCtrl = data.properties.info.lat;

      this.playSelected = data.properties.info.type_play;
      this.loungeSelected = data.properties.info.seat_room;
      this.outdoorSeatsCtrl = data.properties.info.seat_terrace_outward;
      this.indoorSeatsCtrl = data.properties.info.seat_terrace_indoor;
      this.independentEntanceCtrl = data.properties.info.separate_entrance_mall;
      this.totalParkingCtrl = data.properties.info.total_parking_spaces;
      this.totalOwnParkingCtrl = data.properties.info.own_parking_space;
      this.ownParkingCtrl = data.properties.info.own_parking;
      this.accessibilityCtrl = data.properties.info.accessibility;
      this.visibilityCtrl = data.properties.info.visibility;
    } else {
      this.addressCtrl = data.address;
      this.provinceCtrl = data.province;
      this.cityCtrl = data.locality;
      this.lonCtrl = data.x;
      this.latCtrl = data.y;
    }

    array.controls[0].patchValue({
      zonacomercialexistenteCtrl: this.zonacomercialexistenteCtrl,
      nuevodesarrollocomercialCtrl: this.nuevodesarrollocomercialCtrl,
      mccafeCtrl: true, //data.properties.info.mccafe,
      mckioskoCtrl: data.properties.info.mckiosko,
      mcexpressCtrl: data.properties.info.mcexpress,
      mcautoCtrl: data.properties.info.mcauto,
      mcdesayunoCtrl: data.properties.info.breakfast,
      mcdeliveryCtrl: data.properties.info.delivery,
      projectNameCtrl: data.properties.info.name,
      buildingTypeCtrl: this.buildingTypeSelected,
      addressCtrl: data.properties.info.address,
      cityCtrl: data.properties.info.town,
      provinceCtrl: data.properties.info.province,
      lonCtrl: data.properties.info.long,
      latCtrl: data.properties.info.lat,
    });
    array.controls[2].patchValue({
      playCtrl: data.properties.info.type_play,
      loungeSeatsCtrl: data.properties.info.seat_room,
      outdoorSeatsCtrl: data.properties.info.seat_terrace_outward,
      indoorSeatsCtrl: data.properties.info.seat_terrace_indoor,
      independentEntanceCtrl: data.properties.info.separate_entrance_mall,
      totalParkingCtrl: data.properties.info.total_parking_spaces,
      totalOwnParkingCtrl: data.properties.info.own_parking_space,
      ownParkingCtrl: data.properties.info.own_parking,
      accessibilityCtrl: data.properties.info.accessibility,
      visibilityCtrl: data.properties.info.visibility,
    });

    //if new version then competitors, commerces and cc from modified:
    if (data.properties.study) {
      if (data.properties.study.version > 0) {
        //load original competitors:
        let comp =
          data.properties.point_information.properties.areas.minutes_car_00_10
            .properties.places_benchmark;
           // let competitorsPref = comp.filter((dt) => dt.class == "benchmark");
           
           //let competitorsPref = comp.filter((dt) => dt.ID_ != "Others");
           let competitorsPref = comp.filter((dtt) => dtt.properties.class == "benchmark");

        this.sampleCompetencia = competitorsPref.map(function (e) {
          if (e.properties) {
            if (e.properties.info) {
              e.properties.info = e.properties.info;
              if (e.properties.ID_ != null) {
                e.properties.info.ID_ = e.properties.ID_;
                e.properties.info.distance_tostudy = Math.round(
                  e.properties.distance_tostudy
                );
              } else {
                //its an added competitor
                e.properties.info.ID_ = null;
                switch (e.properties.info.area) {
                  case "minutes_foot_00_03":
                    e.properties.info.distance_tostudy = "0-3 min. andando";
                    break;
                  case "minutes_foot_03_05":
                    e.properties.info.distance_tostudy = "3-5 min. andando";
                    break;
                  case "minutes_foot_05_10":
                    e.properties.info.distance_tostudy = "5-10 min. andando";
                    break;
                  case "minutes_foot_10_15":
                    e.properties.info.distance_tostudy = "10-15 min. andando";
                    break;
                }
              }

              e = e.properties.info;
            } else {
              console.log(e);
            }
          } else {
            console.log(e);
            //no importantes Others
            //this.competitorsOth = comm;
          }

          return e;
        });
       /* this.competitorsOth = comp.filter((dt) => dt.ID_ == "Others");
        this.competitorsOth = this.competitorsOth.map(function (e) {
          e.num_mod = e.num;
          return e;
        });
*/
        this.competitorsOth = comp.filter((dt) => dt.ID_ == "Others");
        this.competitorsOth = comp.filter((dt) => dt.properties.class == "benchmark2");
        this.competitorsOth= /*this.competitorsOth.map(function (e) {
          e.num_mod = e.num;
          return e;
        });*/
        [{name : 'Otros',
        num: this.competitorsOth.length
      }];

        //load mcd original:
        let mcd =
          data.properties.point_information.properties.areas.minutes_car_00_10
            .properties.places_main;
        this.sampleMcDonalds = mcd.map(function (e) {
          e.properties.info = e.properties.info;
          e.properties.info.ID_ = e.properties.ID_;
          e.properties.info.distance_tostudy = Math.round(
            e.properties.distance_tostudy
          );
          e = e.properties.info;
          return e;
        });

        //load commerces:
        let comm =
          data.properties.point_information.properties.areas.minutes_foot_00_10
            .properties.places_commerce;
        comm.map(function (e) {
          //find in modificated:
          const found_mod = data.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce.findIndex(
            (element) => element.ID_ === e.ID_
          );
          e.num_mod =
            data.properties.point_information.properties.areas.minutes_foot_00_10.properties.places_commerce[
              found_mod
            ].num;
          return e;
        });
        this.commerces = comm;
        //load commercial centers:
        let mall_o =
          data.properties.point_information.properties.areas.minutes_foot_00_10
            .properties.places_mall;
        this.mall_center = mall_o;
      }
    }
    if (data.properties.data_model) {
      if (data.properties.data_model.presence) {
        if (data.properties.data_model.traffic_original) {
          this.hasPresenciaTrafico = true;
          let tmp_tr =
            data.properties.data_model.traffic_original.average_values;
          let tmp_pr =
            data.properties.data_model.presence_original.average_values.year;
          let mov = [
            {
              key: "presencia",
              values: [
                {
                  key: "residentes",
                  value: tmp_pr.residence,
                  input: tmp_pr.residence,
                  name: "Residentes",
                },
                {
                  key: "trabajadores",
                  value: tmp_pr.work,
                  input: tmp_pr.work,
                  name: "Trabajadores",
                },
                {
                  key: "flotante",
                  value: tmp_pr.floating,
                  input: tmp_pr.floating,
                  name: "Flotante",
                },
                {
                  key: "turista_nacional",
                  value: tmp_pr.national_tourists,
                  input: tmp_pr.national_tourists,
                  name: "Turista nacional",
                },
                {
                  key: "turista_extranjero",
                  value: tmp_pr.foreign_tourists,
                  input: tmp_pr.foreign_tourists,
                  name: "Turista extranjero",
                },
              ],
            },
            {
              key: "trafico",
              values: [
                {
                  key: "volumen_total",
                  value: tmp_tr.transit,
                  input: tmp_tr.transit,
                  name: "Volumen total",
                },
              ],
            },
          ];
          this.sampleMovilidad = mov;
        }
        this.hasPresenciaTrafico = true;
        array.controls[6].patchValue({
          residentes_vCtrl: this.getMobilityValue("presencia", "residentes"),
          trabajadores_vCtrl: this.getMobilityValue(
            "presencia",
            "trabajadores"
          ),
          flotante_vCtrl: this.getMobilityValue("presencia", "flotante"),
          turista_nacional_vCtrl: this.getMobilityValue(
            "presencia",
            "turista_nacional"
          ),
          turista_extranjero_vCtrl: this.getMobilityValue(
            "presencia",
            "turista_extranjero"
          ),
          volumen_total_vCtrl: this.getMobilityValue(
            "trafico",
            "volumen_total"
          ),
          residentes_iCtrl:
            data.properties.data_model.presence.average_values.year.residence,
          flotante_iCtrl:
            data.properties.data_model.presence.average_values.year.floating,
          trabajadores_iCtrl:
            data.properties.data_model.presence.average_values.year.work,
          turista_extranjero_iCtrl:
            data.properties.data_model.presence.average_values.year
              .foreign_tourists,
          volumen_total_iCtrl:
            data.properties.data_model.traffic.average_values.transit,
          turista_nacional_iCtrl:
            data.properties.data_model.presence.average_values.year
              .national_tourists,
        });
      }
    }
  }

  loadTransit: any = () => {
    //NO TRANSIT TAB:
    //this.stepper.selectedIndex = 2;
    let array = this.geoStudyFormGroup.controls.formArray as FormArray;
    array.controls[0].get("buildingTypeCtrl").enable();
    if (this.geoStudyFormGroup.value["formArray"][0].lonCtrl) {
      if (!this.study_created) {
        if (
          this.geoStudyFormGroup.value["formArray"][0].buildingTypeCtrl ==
          "instore"
        ) {
          this.kind_building_toSend = "instore";
        } else if (
          this.geoStudyFormGroup.value["formArray"][0].buildingTypeCtrl ==
          "mall"
        ) {
          if (this.checked_div_kb_checkbox_ndc) {
            this.kind_building_toSend = "mallnuevodesarrollocomercial";
          } else {
            this.kind_building_toSend = "mall";
          }
        } else if (
          this.geoStudyFormGroup.value[
            "formArray"
          ][0].buildingTypeCtrl.includes("freestanding")
        ) {
          //if (
            //this.checked_div_kb_checkbox_zce &&
         //   this.checked_div_kb_checkbox_ndc
         // ) {
         //   this.kind_building_toSend = "freestandingnuevodesarrollocomercial";
         // } //else if (this.checked_div_kb_checkbox_zce) {
           // this.kind_building_toSend = "freestandingzonacomercialexistente";
         // } 
        //  else {
         //   this.kind_building_toSend = "freestandingzonaresidencial";
        //  }
        this.kind_building_toSend = "freestanding";

        }
        this.geoStudyFormGroup.value[
          "formArray"
        ][0].buildingTypeCtrl = this.kind_building_toSend;
        //llamada para crear el estudio:
        this.unsubscribe14 = this.geoStudiesDataService
          .getCreateBasicInfoStudy(
            this.geoStudyFormGroup.value["formArray"][0].lonCtrl,
            this.geoStudyFormGroup.value["formArray"][0].latCtrl,
            this.kind_building_toSend
          )
          .subscribe((result_data_point) => {
            console.log(result_data_point);
            this.study_created = true;

            this.disabledButton = false;
            this.data_study = result_data_point;
            let comp = [];
            comp.push(result_data_point);
            comp = comp.map((d) => {
              delete d.type;
              return d;
            });
            this.addData(comp[0], 0);
            this.geoStudiesDataService.competitorsPointPredictive(
              result_data_point
            );
            this.geoStudiesDataService.commercesPredictive(result_data_point);
            this.geoStudiesDataService.mallPredictive(result_data_point);
            this.addData(null, 3);
          });
      } else {
        //study new version processed
        this.disabledButton = false;
        if (this.data_study.properties.study.state_code == "processed") {
          this.partDisabled = true;
          this.disabledmap = true;
          this.geoStudiesDataService
            .updatePresenceTrafficBrickStudy(this.data_study)
            .subscribe(
              (response) => {
                console.log("new version study Response is: ", response);
               
                this.patchedValues(response);
                this.locateCoordinates();
                this.data_study = response;
                let array = this.geoStudyFormGroup.controls
                  .formArray as FormArray;
                array.controls[0].get("buildingTypeCtrl").disable();
                this.partDisabled = true;
                this.disabledmap = true;

                this.isExecuted = false;
                this.isProcessed = false;
                this.stepper.selectedIndex = 0;
              },
              (error) => {
                console.error("new version study An error occurred, ", error);
              }
            );
          // this.stepper.selectedIndex = 6;
          // this.isExecuted = false;
          // this.isProcessed = true;
        }
        if (this.data_study.properties.study.version > 0) {
          this.data_study.properties.info.name = this.geoStudyFormGroup.value[
            "formArray"
          ][0].projectNameCtrl;
          this.data_study.properties.info.mccafe = true;
          //this.geoStudyFormGroup.value["formArray"][0].mccafeCtrl;
          this.data_study.properties.info.mcauto = this.geoStudyFormGroup.value[
            "formArray"
          ][0].mcautoCtrl;
          this.data_study.properties.info.mcexpress = this.geoStudyFormGroup.value[
            "formArray"
          ][0].mcexpressCtrl;
          this.data_study.properties.info.mckiosko = this.geoStudyFormGroup.value[
            "formArray"
          ][0].mckioskoCtrl;
          this.data_study.properties.info.breakfast = this.geoStudyFormGroup.value[
            "formArray"
          ][0].mcdesayunoCtrl;
          this.data_study.properties.info.delivery = this.geoStudyFormGroup.value[
            "formArray"
          ][0].mcdeliveryCtrl;
        }
      }
    }
  };

  onChange(event, item) {
    this.mcautoCtrl = event.checked;
    this.isEditable = event.checked;
  }

  onChangeKindBuilding(event, item) {
    this.checked_div_kb_checkbox_ndc = false;
   // this.checked_div_kb_checkbox_zce = false;
    if (event.value == "instore") {
      this.show_div_kb_checkbox_zce = false;
      this.show_div_kb_checkbox_ndc = false;
    } else if (event.value == "mall") {
      this.show_div_kb_checkbox_zce = false;
      this.show_div_kb_checkbox_ndc = true;
    } else if (event.value == "freestanding") {
      this.show_div_kb_checkbox_zce = true;

      this.show_div_kb_checkbox_ndc = false;
    }
    //show checkbox
    //later on next page, check if checkboxes are checked
  }
  onChangeKBCheckbox(event, item) {
    //if (item == "zonacomercialexistente") {
    //  this.checked_div_kb_checkbox_zce = event.checked;
    //  if (this.checked_div_kb_checkbox_zce) {
    //    this.show_div_kb_checkbox_ndc = true;
    //  } else {
    //    this.show_div_kb_checkbox_ndc = false;
    //  }
    //} else
     if (item == "nuevodesarrollocomercial") {
      this.checked_div_kb_checkbox_ndc = event.checked;
    }
  }

  locateCoordinates: any = () => {
    this.lonCtrl = (this.lonCtrl + "").replace(",", ".");
    this.latCtrl = (this.latCtrl + "").replace(",", ".");

    if (this.lonCtrl != "undefined") {
      if (this.lonCtrl != "") {
        let lon = this.lonCtrl;
        let lat = this.latCtrl;
        let coords = { lat: lat, lon: lon };
        this.geoStudiesDataService.coordsPredictive(coords);
      }
    }
  };

  bricksSelected: any = () => {
    this.bricksSelected = this.geoStudiesDataService.getBricksTransitPredictive();
  };

  featuresBack: any = () => {
    this.stepper.selectedIndex = 0;
  };

  addData(data: any, step: any): any {
    this.geoStudyFormGroup.value;
    switch (step) {
      case 0:
        if (!data) {
          this.data_study = {};
          this.data_study.properties = {};
          this.data_study.properties.info = {};
          this.data_study.geometry = [];
        } else {
          this.data_study = data;
          this.data_study.properties.info = {};
        }
        this.data_study.properties.info.name = this.geoStudyFormGroup.value[
          "formArray"
        ][0].projectNameCtrl;
        this.data_study.properties.info.telephone = "";
        this.data_study.properties.info.zip_code = "";
        this.data_study.properties.info.kind_building = this.geoStudyFormGroup.value[
          "formArray"
        ][0].buildingTypeCtrl;
        this.data_study.properties.info.mccafe = true; //this.geoStudyFormGroup.value["formArray"][0].mccafeCtrl;
        this.data_study.properties.info.mcauto = this.geoStudyFormGroup.value[
          "formArray"
        ][0].mcautoCtrl;
        this.data_study.properties.info.mcexpress = this.geoStudyFormGroup.value[
          "formArray"
        ][0].mcexpressCtrl;
        this.data_study.properties.info.mckiosko = this.geoStudyFormGroup.value[
          "formArray"
        ][0].mckioskoCtrl;
        this.data_study.properties.info.breakfast = this.geoStudyFormGroup.value[
          "formArray"
        ][0].mcdesayunoCtrl;
        this.data_study.properties.info.delivery = this.geoStudyFormGroup.value[
          "formArray"
        ][0].mcdeliveryCtrl;
        //2
        this.data_study.properties.info.address = this.geoStudyFormGroup.value[
          "formArray"
        ][0].addressCtrl;
        this.data_study.properties.info.town = this.geoStudyFormGroup.value[
          "formArray"
        ][0].cityCtrl;
        this.data_study.properties.info.province = this.geoStudyFormGroup.value[
          "formArray"
        ][0].provinceCtrl;
        this.data_study.properties.info.autonomous_region = "";
        this.data_study.properties.info.lat = this.geoStudyFormGroup.value[
          "formArray"
        ][0].latCtrl;
        this.data_study.properties.info.long = this.geoStudyFormGroup.value[
          "formArray"
        ][0].lonCtrl;
        //overwrite geom :
        this.data_study.geometry.coordinates[0] = this.geoStudyFormGroup.value[
          "formArray"
        ][0].lonCtrl;
        this.data_study.geometry.coordinates[1] = this.geoStudyFormGroup.value[
          "formArray"
        ][0].latCtrl;

        //guardamos las coordenadas para visualizarlas en las localizaciones:
        
        this.geoStudiesDataService.setCoordsPredictive({lat:this.data_study.properties.info.lat,
           lon:this.data_study.properties.info.long} );
        this.unsubscribe15 = this.geoStudiesDataService
          .updatePresenceTrafficBrickStudy(this.data_study)
          .subscribe(
            (response) => {
              console.log("Response is: ", response);
            },
            (error) => {
              console.error("An error occurred, ", error);
            }
          );
        break;
      case 2:
        this.bricksSelected();
        this.data_study.properties.presence_traffic = this.bricksSelected;
        // put to insert this object with body this.data_study
        /* this.unsubscribe16 = this.geoStudiesDataService
          .updatePresenceTrafficBrickStudy(this.data_study)
          .subscribe(
            response => {
              console.log("Response is: ", response);
            },
            error => {
              console.error("An error occurred, ", error);
            }
          ); */

        break;
      case 3:
        //3
        if (this.study_created) {
          //guardamos las coordenadas para visualizarlas en las localizaciones:        
        this.geoStudiesDataService.setCoordsPredictive({lat:this.data_study.properties.info.lat,
          lon:this.data_study.properties.info.long} );

          this.data_study.properties.info.type_play = this.geoStudyFormGroup.value[
            "formArray"
          ][2].playCtrl;
          this.data_study.properties.info.seat_room = this.geoStudyFormGroup.value[
            "formArray"
          ][2].loungeSeatsCtrl;
          this.data_study.properties.info.seat_terrace_outward = Number(
            this.geoStudyFormGroup.value["formArray"][2].outdoorSeatsCtrl
          );
          this.data_study.properties.info.seat_terrace_indoor = this.geoStudyFormGroup.value[
            "formArray"
          ][2].indoorSeatsCtrl;
          this.data_study.properties.info.separate_entrance_mall = this.geoStudyFormGroup.value[
            "formArray"
          ][2].independentEntanceCtrl;
          this.data_study.properties.info.data_parking = "";
          this.data_study.properties.info.total_parking_spaces = this.geoStudyFormGroup.value[
            "formArray"
          ][2].totalParkingCtrl;
          this.data_study.properties.info.own_parking = this.geoStudyFormGroup.value[
            "formArray"
          ][2].ownParkingCtrl;
          this.data_study.properties.info.own_parking_space = this.geoStudyFormGroup.value[
            "formArray"
          ][2].totalOwnParkingCtrl;
          this.data_study.properties.info.accessibility = this.geoStudyFormGroup.value[
            "formArray"
          ][2].accessibilityCtrl;
          this.data_study.properties.info.visibility = this.geoStudyFormGroup.value[
            "formArray"
          ][2].visibilityCtrl;
          this.data_study.properties.info.mall = "";
          this.data_study.properties.info.brand = "MCDONALDS";
          this.data_study.properties.info.id_brand = 4772;

          //ñapa others portals:
          if (this.data_study.properties.point_information.properties.areas) {
            let evilResponseProps = Object.keys(
              this.data_study.properties.point_information.properties.areas
            );

            for (const key of evilResponseProps) {
              if (
                this.data_study.properties.point_information.properties.areas.hasOwnProperty(
                  key
                )
              ) {
                let element = this.data_study.properties.point_information
                  .properties.areas[key];
                if (!element.properties.portals2017.hasOwnProperty("others")) {
                  this.data_study.properties.point_information.properties.areas[
                    key
                  ].properties.portals2017.others = {};
                  this.data_study.properties.point_information.properties.areas[
                    key
                  ].properties.portals2017.others = {
                    cpro: "",
                    asv_men75_79: null,
                    em: null,
                    v0103e: 92837.1680883681,
                    v0405e: 13676.795386728,
                    v0610e: 14177.766446957,
                    v1115e: 5791.9247937975,
                    v1620e: 3172.96149638352,
                    v2130e: 3270.97477422749,
                    v3140e: 1622.98853615527,
                    v4150e: 1066.9999999999,
                    v5160e: 580.988536155156,
                    v6170e: 444.9885361552,
                    v7180e: 335.999999999947,
                    v8190e: 256.000000000017,
                    v91100e: 245.999999999987,
                    v101125e: 373.999999999976,
                    v126150e: 290.999999999964,
                    v151175e: 174.999999999993,
                    v176200e: 128.999999999994,
                    v201300e: 302.988536155209,
                    v301400e: 118.000000000006,
                    v401500e: 89.9999999999983,
                    v501750e: 98.0000000000013,
                    v7511ke: 50.0000000000022,
                    vm1000e: 115.999999999998,
                    vnulemp: 113675.870272054,
                    empleados_empresa: 19.1314008875201,
                    empleados_autonomos: 4.24983770686592,
                    tasa_paro: 0.121254391081318,
                    v_unifamiliares: 0,
                    facturacion_empresa: 3236675.11813828,
                    v_ppctp: 1305250.9003549,
                    v_ppcpp: 881669.095017965,
                    v_alq: 497690.8924014,
                    v_oform: 79490.2424234174,
                    v_pphod: 125518.418849681,
                    v_cedgra: 43270.9466907365,
                    pob_0_14: 15.4490430293867,
                    pob_15_64: 65.9212314587113,
                    pob_mas64: 18.6297255118107,
                  };
                }
              }
            }
          }

          this.unsubscribe17 = this.geoStudiesDataService
            .updatePresenceTrafficBrickStudy(this.data_study)
            .subscribe(
              (response) => {
                console.log("Response is: ", response);
              },
              (error) => {
                console.error("An error occurred, ", error);
              }
            );
        } else {
          this.stepper.linear = false;
        }
        break;
    }
    //1
  }

  cancelForm() {
    setTimeout(() => {
      this.router.navigateByUrl("/predictive/studies");
    }, 5000);
  }

  loadDataStudy(study) {
    //load study data:
    this.data_study = study;
    let dataStudyInfo = study.properties.info;
    this.geoStudyFormGroup.value;
    //0
    this.geoStudyFormGroup.value.formArray[0].addressCtrl =
      dataStudyInfo.address;
    this.geoStudyFormGroup.value.formArray[0].buildingTypeCtrl =
      dataStudyInfo.kind_building;
    this.geoStudyFormGroup.value.formArray[0].cityCtrl = dataStudyInfo.town;
    this.geoStudyFormGroup.value.formArray[0].latCtrl = dataStudyInfo.lat;
    this.geoStudyFormGroup.value.formArray[0].lonCtrl = dataStudyInfo.long;
    this.geoStudyFormGroup.value.formArray[0].mcautoCtrl = dataStudyInfo.mcauto;
    this.geoStudyFormGroup.value.formArray[0].mccafeCtrl = true; //dataStudyInfo.mccafe;
    this.geoStudyFormGroup.value.formArray[0].mcdesayunoCtrl =
      dataStudyInfo.breakfast;
    this.geoStudyFormGroup.value.formArray[0].mcdeliveryCtrl =
      dataStudyInfo.mcdelivery;
    this.geoStudyFormGroup.value.formArray[0].mcexpressCtrl =
      dataStudyInfo.mcexpress;
    this.geoStudyFormGroup.value.formArray[0].mckioskoCtrl =
      dataStudyInfo.mckiosko;
    this.geoStudyFormGroup.value.formArray[0].projectNameCtrl =
      dataStudyInfo.name;
    this.geoStudyFormGroup.value.formArray[0].provinceCtrl =
      dataStudyInfo.province;
    //2
    this.geoStudyFormGroup.value.formArray[2].accessibilityCtrl =
      dataStudyInfo.accessibility;
    this.geoStudyFormGroup.value.formArray[2].independentEntanceCtrl =
      dataStudyInfo.separate_entrance_mall;
    this.geoStudyFormGroup.value.formArray[2].indoorSeatsCtrl =
      dataStudyInfo.seat_terrace_indoor;
    this.geoStudyFormGroup.value.formArray[2].loungeSeatsCtrl = Number(
      dataStudyInfo.seat_room
    );
    this.geoStudyFormGroup.value.formArray[2].outdoorSeatsCtrl =
      dataStudyInfo.seat_terrace_outward;
    this.geoStudyFormGroup.value.formArray[2].ownParkingCtrl =
      dataStudyInfo.own_parking;
    this.geoStudyFormGroup.value.formArray[2].playCtrl =
      dataStudyInfo.type_play;
    this.geoStudyFormGroup.value.formArray[2].totalParkingCtrl =
      dataStudyInfo.total_parking_spaces;
    this.geoStudyFormGroup.value.formArray[2].totalOwnParkingCtrl =
      dataStudyInfo.own_parking_space;
    this.geoStudyFormGroup.value.formArray[2].visibilityCtrl =
      dataStudyInfo.visibility;
    this.mccafeCtrl = true; //dataStudyInfo.mccafe;
    this.mckioskoCtrl = dataStudyInfo.mckiosko;
    this.mcexpressCtrl = dataStudyInfo.mcexpress;
    this.mcautoCtrl = dataStudyInfo.mcauto;
    this.mcdesayunoCtrl = dataStudyInfo.breakfast;
    this.mcdeliveryCtrl = dataStudyInfo.mcdelivery;
    this.projectNameCtrl = dataStudyInfo.name;
    this.buildingTypeSelected = dataStudyInfo.kind_building;
    this.addressCtrl = dataStudyInfo.address;
    this.cityCtrl = dataStudyInfo.town;
    this.provinceCtrl = dataStudyInfo.province;
    this.lonCtrl = dataStudyInfo.long;
    this.latCtrl = dataStudyInfo.lat;
  }

  onSubmit() {
    console.log(
      "click",
      this.geoStudyFormGroup.value,
      this.formArray.get([0]).value.projectNameCtrl,

      this.mcDonaldsAdded,
      this.mcDonaldsRemoved,
      this.competitorsRemoved,
      this.competitorsAdded,
      this.data_point,
      this.data_study,
      this.commercesEdited,
      this.commercesAdded,
      this.commercialCenterEdited,
      this.commercialCenterAdded
    );

    if (this.data_study.properties.data_model) {
      if (this.data_study.properties.data_model.presence) {
        this.hasPresenciaTrafico = true;
        this.data_study.properties.data_model.presence.average_values.year.floating = this.geoStudyFormGroup.value.formArray[6].flotante_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.foreign_tourists = this.geoStudyFormGroup.value.formArray[6].turista_extranjero_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.national_tourists = this.geoStudyFormGroup.value.formArray[6].turista_nacional_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.residence = this.geoStudyFormGroup.value.formArray[6].residentes_iCtrl;
        this.data_study.properties.data_model.presence.average_values.year.work = this.geoStudyFormGroup.value.formArray[6].trabajadores_iCtrl;
        this.data_study.properties.data_model.traffic.average_values.transit = this.geoStudyFormGroup.value.formArray[6].volumen_total_iCtrl;
        this.isExecuted = true;
         this.geoStudiesDataService
          .updatePresenceTrafficBrickStudy(this.data_study)
          .subscribe(
            (response) => {
              console.log("movilidadNext Response is: ", response);
              //execute study:
              this.isExecuted = true;
              this.cancelForm();
               this.geoStudiesDataService
                .executeStudy(this.data_study._id)
                .subscribe(
                  (response) => {
                    console.log("executeStudy Response is: ", response);
                  },
                  (error) => {
                    console.error("executeStudy An error occurred, ", error);
                  }
                );
            },
            (error) => {
              console.error("movilidadNext An error occurred, ", error);
            }
          );
      }
    }

  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
    if (this.unsubscribe4) {
      this.unsubscribe4.unsubscribe();
    }
    if (this.unsubscribe5) {
      this.unsubscribe5.unsubscribe();
    }
    if (this.unsubscribe6) {
      this.unsubscribe6.unsubscribe();
    }
    if (this.unsubscribe7) {
      this.unsubscribe7.unsubscribe();
    }
    if (this.unsubscribe8) {
      this.unsubscribe8.unsubscribe();
    }
    if (this.unsubscribe9) {
      this.unsubscribe9.unsubscribe();
    }
    if (this.unsubscribe10) {
      this.unsubscribe10.unsubscribe();
    }
    if (this.unsubscribe11) {
      this.unsubscribe11.unsubscribe();
    }
    if (this.unsubscribe12) {
      this.unsubscribe12.unsubscribe();
    }
    if (this.unsubscribe13) {
      this.unsubscribe13.unsubscribe();
    }
    if (this.unsubscribe14) {
      this.unsubscribe14.unsubscribe();
    }
    if (this.unsubscribe15) {
      this.unsubscribe15.unsubscribe();
    }
    if (this.unsubscribe16) {
      this.unsubscribe16.unsubscribe();
    }
    if (this.unsubscribe17) {
      this.unsubscribe17.unsubscribe();
    }
    if (this.unsubscribe18) {
      this.unsubscribe18.unsubscribe();
    }
  }
}
