import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";

import { FormBuilder } from "@angular/forms";

export interface UserProfile {
  name: string;
  value: string;
}

@Component({
  selector: "app-edit-dialog-partial-predictive-study",
  templateUrl: "./edit-dialog-partial-predictive-study.component.html",
  styleUrls: ["./edit-dialog-partial-predictive-study.component.scss"]
})
export class EditDialogPartialPredictiveStudyComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private geoStudiesDataService: GeoStudiesDataService,
    public dialogRefEdit: MatDialogRef<EditDialogPartialPredictiveStudyComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any
  ) {}

  

  onNoClick(): void {
    this.dialogRefEdit.close();
  }

  delivery_impact_sel = 0;
      site_id_sel="";
      site_impact_sel = 0;
      site_name_sel="";
      site_sales_sel = 0;


  studyForm = new FormGroup({
    perc_of_sales_associated_to_existing_rest : new FormControl(""),
    first_year_sales_upper: new FormControl(""),
    first_year_sales_lower: new FormControl(""),
    first_year_sales_medium: new FormControl(""),
    channel_decomposition_Delivery: new FormControl(""),
    channel_decomposition_Mostrador_Kiosco: new FormControl(""),
    channel_decomposition_McCafe: new FormControl(""),
    channel_decomposition_McAuto: new FormControl(""),
    impact_info_delivery_impact: new FormControl(""),
    impact_info_site_impact: new FormControl(""),
    impact_info_site_sales: new FormControl(""),    
    impact_info: new FormControl([{
      "delivery_impact":'',
      "site_id":'',
      "site_impact":'',
      "site_name":'',
      "site_sales":''
  }], [])
  });

  impact_infoValue: Array<any>;

  onSubmit(jsonStudy: any) {
  
   let data = {};
   let data_edited= {};
   data["properties"] = jsonStudy.properties;
   data["_id"] = jsonStudy._id;
   data["properties"].data_model.sales_info.perc_of_sales_associated_to_existing_rest = this.studyForm.value.perc_of_sales_associated_to_existing_rest;
   data["properties"].data_model.sales_info.first_year_sales.upper = this.studyForm.value.first_year_sales_upper;
   data["properties"].data_model.sales_info.first_year_sales.lower = this.studyForm.value.first_year_sales_lower;
   data["properties"].data_model.sales_info.first_year_sales.medium = this.studyForm.value.first_year_sales_medium;
   data["properties"].data_model.sales_info.channel_decomposition.Delivery = this.studyForm.value.channel_decomposition_Delivery;
   data["properties"].data_model.sales_info.channel_decomposition.Mostrador_Kiosco = this.studyForm.value.channel_decomposition_Mostrador_Kiosco;
   data["properties"].data_model.sales_info.channel_decomposition.McCafe = this.studyForm.value.channel_decomposition_McCafe;
   data["properties"].data_model.sales_info.channel_decomposition.McAuto = this.studyForm.value.channel_decomposition_McAuto;
   
   data["properties"].data_model.impact_info = this.studyForm.value.impact_info;

data_edited["properties.data_model.sales_info"]= data["properties"].data_model.sales_info;
data_edited["properties.data_model.impact_info"] = {};
for(let index_imp = 0; index_imp<this.studyForm.value.impact_info.length; index_imp++){
  
  let objI =  {
    "site_impact" : this.studyForm.value.impact_info[index_imp].site_impact,
    "delivery_impact" : this.studyForm.value.impact_info[index_imp].delivery_impact ,
    "site_name" : this.studyForm.value.impact_info[index_imp].site_name,
    "site_sales" : this.studyForm.value.impact_info[index_imp].site_sales
  }

  data_edited["properties.data_model.impact_info"][this.studyForm.value.impact_info[index_imp].site_id] = objI
  
}

this.message.properties.data_model.impact_info = data_edited["properties.data_model.impact_info"]


   jsonStudy.properties.data_model.sales_info.perc_of_sales_associated_to_existing_rest = data["properties"].data_model.sales_info.perc_of_sales_associated_to_existing_rest ;

   jsonStudy.properties.data_model.sales_info.first_year_sales.upper = data["properties"].data_model.sales_info.first_year_sales.upper;
   jsonStudy.properties.data_model.sales_info.first_year_sales.lower = data["properties"].data_model.sales_info.first_year_sales.lower ;
   jsonStudy.properties.data_model.sales_info.first_year_sales.medium = data["properties"].data_model.sales_info.first_year_sales.medium;

   jsonStudy.properties.data_model.sales_info.channel_decomposition.Delivery = data["properties"].data_model.sales_info.channel_decomposition.Delivery;
   jsonStudy.properties.data_model.sales_info.channel_decomposition.Mostrador_Kiosco = data["properties"].data_model.sales_info.channel_decomposition.Mostrador_Kiosco ;
   jsonStudy.properties.data_model.sales_info.channel_decomposition.McCafe = data["properties"].data_model.sales_info.channel_decomposition.McCafe;
   jsonStudy.properties.data_model.sales_info.channel_decomposition.McAuto = data["properties"].data_model.sales_info.channel_decomposition.McAuto;
   
  

   this.geoStudiesDataService
            .updateStudyPredictive(data_edited, data["_id"])
            .subscribe(
              (response) => {
                this.geoStudiesDataService.loadAll();
              },
              (error) => {
                console.error("An error occurred, ", error);
              }
            );
  }

  ngOnInit() {
    let inf = this.message.properties.data_model.impact_info

    var myDataImpc = Object.keys(inf).map(key => {
      let dt = inf[key];
      dt["site_id"]=key;
      return dt;
  })
     var arrayT =[];
     for(var t = 0; t< myDataImpc.length ; t++){  
      arrayT.push(
        this.formBuilder.group({              
          delivery_impact: [myDataImpc[t].delivery_impact],
          site_id: myDataImpc[t].site_id,
          site_impact: myDataImpc[t].site_impact,
          site_name: myDataImpc[t].site_name,
          site_sales: myDataImpc[t].site_sales
        } 
      ));
    }

    this.studyForm = this.formBuilder.group({
      perc_of_sales_associated_to_existing_rest : this.message.properties.data_model.sales_info.perc_of_sales_associated_to_existing_rest,
      first_year_sales_upper: this.message.properties.data_model.sales_info.first_year_sales.upper,
      first_year_sales_lower: this.message.properties.data_model.sales_info.first_year_sales.lower,
      first_year_sales_medium: this.message.properties.data_model.sales_info.first_year_sales.medium,
      channel_decomposition_Delivery: this.message.properties.data_model.sales_info.channel_decomposition.Delivery  ,
      channel_decomposition_Mostrador_Kiosco: this.message.properties.data_model.sales_info.channel_decomposition.Mostrador_Kiosco,
      channel_decomposition_McCafe: this.message.properties.data_model.sales_info.channel_decomposition.McCafe,
      channel_decomposition_McAuto: this.message.properties.data_model.sales_info.channel_decomposition.McAuto,
     
      impact_info: this.formBuilder.array(arrayT),
    });

    this.impact_infoValue = myDataImpc; 
  }
}
