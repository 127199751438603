<div mat-dialog-content>
  <p>Editar datos para el estudio: {{ message.properties.study.study_id }}</p>
</div>
<div class="margin-top--s">
  <form [formGroup]="studyForm" (ngSubmit)="onSubmit(message)">
  <mat-form-field class="mff-width200">
    <mat-label>% venta que proviene de impacto</mat-label>
    <input matInput type="number" formControlName="perc_of_sales_associated_to_existing_rest">
  </mat-form-field>

  <mat-form-field class="mff-width200">
    <mat-label>Estimación de ventas</mat-label>
    <input matInput type="number" formControlName="first_year_sales_medium">
  </mat-form-field>
  <mat-form-field class="mff-width200">
    <mat-label>Límite superior</mat-label>
    <input matInput type="number" formControlName="first_year_sales_upper">
  </mat-form-field>
  <mat-form-field class="mff-width200">
    <mat-label>Límite inferior</mat-label>
    <input matInput type="number" formControlName="first_year_sales_lower">
  </mat-form-field>
 
  


  <div class="example-form-fields">
    <mat-list>
      <h3 class="small" mat-subheader>Descomposición por canal</h3>
      <mat-list-item  >
        <mat-form-field class="mff-width90">
          <mat-label>Delivery</mat-label>
          <input matInput type="number" formControlName="channel_decomposition_Delivery">
        </mat-form-field>
        <mat-form-field class="mff-width90">
          <mat-label>Mostrador_Kiosco</mat-label>
          <input matInput type="number" formControlName="channel_decomposition_Mostrador_Kiosco">
        </mat-form-field>
        <mat-form-field class="mff-width90">
          <mat-label>McCafe</mat-label>
          <input matInput type="number" formControlName="channel_decomposition_McCafe">
        </mat-form-field>
        <mat-form-field class="mff-width90">
          <mat-label>McAuto</mat-label>
          <input matInput type="number" formControlName="channel_decomposition_McAuto">
        </mat-form-field>
      </mat-list-item>
    </mat-list>
  
    </div>
  
<div class="example-form-fields">
  <mat-list>
    <h3 class="small" mat-subheader>Info Impacto</h3>
    <mat-list-item   formArrayName="impact_info" *ngFor="let ingredient of impact_infoValue; let i = index;" >
      <mat-form-field formGroupName={{i}}>
        <mat-label>SITE {{ingredient.site_id}}</mat-label>
        <input matInput readonly [(value)]="site_name_sel"  formControlName="site_name">
      </mat-form-field>
      <mat-form-field  class="mff-width" formGroupName={{i}}>        
        <mat-label>Delivery</mat-label> <!--delivery_impact-->
        <input matInput type="number" [(value)]="delivery_impact_sel" formControlName="delivery_impact">
      </mat-form-field>
      <mat-form-field  class="mff-width" formGroupName={{i}}>
        <mat-label>Impact</mat-label><!--site_impact-->
        <input matInput type="number" [(value)]="site_impact_sel" formControlName="site_impact">
      </mat-form-field>
      <mat-form-field  class="mff-width-sales" formGroupName={{i}}>
        <mat-label>Sales</mat-label><!--site_sales-->
        <input matInput type="number" [(value)]="site_sales_sel" formControlName="site_sales">

      </mat-form-field>
    </mat-list-item>
  </mat-list>

  </div>
  
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true"  type="submit">
      Guardar cambios
    </button>
    <button mat-flat-button mat-dialog-close color="warn" (click)="onNoClick()">Cancelar</button>
  </div>
  </form>
</div>
