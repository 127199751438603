
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";

import { AddDialogCommercialCenterBoxLocatorComponent } from "./add-dialog-commercial-center-box-locator.component";

import { McdGeoLocatorMapModule } from "../../../../../app-widgets/cartography/mcdGeoLocatorMap/mcdGeoLocatorMap.module";
import { SearchboxModule } from "../../../../../app-widgets/form-controls/searchbox/searchbox.module";


@NgModule({
  declarations: [AddDialogCommercialCenterBoxLocatorComponent ],
  imports: [SharedModule, McdGeoLocatorMapModule,SearchboxModule],
  exports: [AddDialogCommercialCenterBoxLocatorComponent],
  providers: []
})
export class AddDialogCommercialCenterBoxLocatorModule {}

