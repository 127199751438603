import { Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "src/app/app-services/data.service";
import { ImageService } from "src/app/app-services/image.service";
import sampleCompetencia from "../../../../assets/data/competencia_GranVia.json";

@Component({
  selector: "app-competencia-table",
  templateUrl: "./competencia-table.component.html",
  styleUrls: ["./competencia-table.component.scss"]
})
export class CompetenciaTableComponent implements OnInit, OnDestroy {
  @Input()
  table_num: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Competitors: Array<any> = sampleCompetencia;
  displayedColumns: string[] = ["name", "address", "dist_meters_mcd_nearby"];
  competencias: any = null;
  dataSource = new MatTableDataSource(this.competencias);
  competenciaImg: any;
  image_route = "../../../../assets/img/marker_icons/markers/";
  unsubscribe: any;
  unsubscribe2: any;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private dataService: DataService,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    let idPlace = this.dataService.getSelectedRestaurant();
    this.unsubscribe = this.dataService.getPlacesIdMcd(idPlace).subscribe(d => {
      this.competencias = d.properties.benchmark;
      this.competencias = this.competencias.filter(dt => dt.id != "Others");

      this.imageService.dictionaryImageExists(this.competencias);
      this.unsubscribe2 = this.imageService.brandsChanged$.subscribe(obj => {
        this.dataSource = new MatTableDataSource(this.competencias);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
