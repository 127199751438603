import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabStudiesPrescComponent } from "./tab-studies-presc.component";

@NgModule({
  declarations: [TabStudiesPrescComponent],
  imports: [SharedModule],
  exports: [TabStudiesPrescComponent],
  providers: []
})
export class TabStudiesPrescModule {}
