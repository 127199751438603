import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { UsersService } from "src/app/app-services/users.service";
import { Router, ActivatedRoute } from "@angular/router";

export interface UserProfile {
  name: string;
  value: string;
}

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"]
})
export class EditUserComponent implements OnInit, OnDestroy {
  userProfiles: UserProfile[] = [
    { name: "Avanzado", value: "advance" },
    { name: "Básico", value: "basic" }
  ];

  userEmail = "";
  userFirstName = "";
  userSurName = "";
  //userPassword = "";
  unsubscribe: any;
  user_id: any = "";
  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  userForm = new FormGroup({
    userEmail: new FormControl(this.userEmail, [
      Validators.required,
      Validators.email
    ]),
    userFirstName: new FormControl(this.userFirstName, [Validators.required]),
    userSurName: new FormControl(this.userSurName, [Validators.required]),
    //userPassword: new FormControl(this.userPassword, [Validators.required]),
    userProfile: new FormControl("", [Validators.required]),
    userID: new FormControl(this.user_id, [])
  });

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.userForm.value, this.userForm.status);
    let data = {
      _id: this.userForm.value.userID,
      username: this.userForm.value.userEmail,
      name: this.userForm.value.userFirstName,
      surname: this.userForm.value.userSurName,
      //password: this.userForm.value.userPassword,
      group: this.userForm.value.userProfile
    };
    this.userService.editUser(data).subscribe(
      response => {
        console.log("Response is: ", response);
        this.router.navigateByUrl("/users");
      },
      error => {
        console.error("An error occurred, ", error);
      }
    );
  }

  ngOnInit() {
    //load user data:
    this.unsubscribe = this.route.params.subscribe(d => {
      this.userService.getUserById(d.idUser).subscribe(user => {
        this.user_id = user._id;
        if (user.surname) {
          this.userSurName = user.surname;
        } else {
          this.userSurName = user.name.split(" ")[1];
        }
        this.userForm.patchValue({
          userEmail: user.username,
          userFirstName: user.name.split(" ")[0],
          userSurName: this.userSurName,
          //userPassword: user.password,
          userProfile: user.group,
          userID: user._id
        });
      });
    });
  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }

  resetPassword() {
    let data = {
      _id: this.userForm.value.userID,
      username: this.userForm.value.userEmail,
      name: this.userForm.value.userFirstName,
      surname: this.userForm.value.userSurName,
      //password: this.userForm.value.userPassword,
      group: this.userForm.value.userProfile
    };
    
  }
}
