import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NewUserComponent } from "./new-user.component";

const routes: Routes = [
  { path: "users/new", component: NewUserComponent, canActivate: [] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewUserRoutingModule {
  static components = [NewUserComponent];
}
