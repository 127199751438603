import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { UlSitesModule } from "./sidenav-restaurants/ul-sites/ul-sites.module";
import { RotateArrowModule } from "../../buttons/rotate-arrow/rotate-arrow.module";

import { SidenavStartLeftComponent } from "./sidenav-start-left.component";

@NgModule({
  declarations: [SidenavStartLeftComponent],
  imports: [SharedModule, RotateArrowModule, UlSitesModule],
  exports: [SidenavStartLeftComponent],
  providers: []
})
export class SidenavStartLeftModule {}
