<nav *ngIf="ready">
  <div role="header" class="selector-header">
    <div class="row">
      <div class="site site-flex">
        <div class="padding-xs">
          <p class="small">ESTUDIO</p>
          <p class="big-number">{{ studyData.properties.study.study_id }}</p>
        </div>
      </div>
      <div class="logo-flex logo-mcd"></div>


      <div class="name-flex">
        <div class="padding-xs">
          <h1 class="big-number">{{ studyData.properties.info.name }}</h1>
          <p>{{ studyData.properties.info.address }}</p>
          <p>
            {{ studyData.properties.info.brand }}
          </p>
        </div>
      </div>


    </div>
  </div>
  <app-study-ficha [studyData]="studyData"></app-study-ficha>
</nav>