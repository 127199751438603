import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { OptionsFromJsonModule } from "../../../app-widgets/form-controls/options-from-json/options-from-json.module";

import { DataEntornoComponent } from "./data-entorno.component";
import { PopulationPyramidModule } from "../../../app-widgets/charts/population-pyramid/population-pyramid.module";
import { GroupedBarModule } from "../../../app-widgets/charts/grouped-bar/grouped-bar.module";
import { ScatterplotModule } from "../../../app-widgets/charts/scatterplot/scatterplot.module";

import { VerticalBarsModule } from "../../../app-widgets/charts/vertical-bars/vertical-bars.module";
import { ProgressBarModule } from "../../../app-widgets/indicators/progress-bar/progress-bar.module";

@NgModule({
  declarations: [DataEntornoComponent],
  imports: [
    SharedModule,
    OptionsFromJsonModule,
    PopulationPyramidModule,
    GroupedBarModule,
    ScatterplotModule,
    VerticalBarsModule,
    ProgressBarModule
  ],
  exports: [DataEntornoComponent],
  providers: []
})
export class DataEntornoModule {}
