import { Component, OnInit, Input } from '@angular/core';
import * as L from "leaflet";
import { BrickReportMapService } from './brick-report-map.service';

@Component({
  selector: 'app-brick-report-map',
  templateUrl: './brick-report-map.component.html',
  styleUrls: ['./brick-report-map.component.scss']
})
export class BrickReportMapComponent implements OnInit {
  @Input() setup: any;
  token: string;
  latitude: number;
  longitude: number;
  marker: L.Marker;
  marker_icon: L.Icon;
  lMap;
  zoomControl: L.Control;
  tileLayer: L.TileLayer;
  geojsonLayer;
  layer_ptos;
  
  constructor(private brickReportMapService: BrickReportMapService) { }

   zoomMap = (objectZoom: any) => {   
      var currentZoom = this.lMap.getZoom();
      if(currentZoom>11){//remove layer circles     
        this.layer_ptos.setStyle({
          opacity: 0,
          fillOpacity: 0.0
        });
      }else{
        this.layer_ptos.setStyle({
          opacity: 0.7,
          fillOpacity: 0.7
        });
      }
   }

  // EVENTS
  onEachFeature = (feature: any, layer: any): void => {
    if (feature.properties) {
      layer.bindPopup('<p>Brick | <strong>' + feature.properties.brick_id + '</strong></p><p>' + parseFloat(parseFloat(feature.properties.sales_info.first_year_sales).toFixed(0)).toLocaleString('es-ES', { useGrouping: true }) + ' €</p>');
    }
  };

  fitBrickBounds(map, layer) {
    map.fitBounds(layer.getBounds());
  }

  drawColorKeys(map, scales): void {
    let colors = scales.colors;
    let steps = scales.steps;
    let keysControl = new L.Control({ position: 'bottomright' });

    keysControl.onAdd = () => {
      let div: HTMLElement = L.DomUtil.create('div', 'info legend');
      div.innerHTML = "<h4>Ventas(€)</h4>";
      if(steps.length==1){
        div.innerHTML += '<div><span width="10" height="10" style="background-color: ' + colors[7] + '; display: inline-block; width: 10px; height: 10px"></span>' + steps.toLocaleString() + '</div>';
      }else{
        if(steps.length<8){
          steps.forEach((element, index, array) => {
              div.innerHTML += '<div><span width="10" height="10" style="background-color: ' + colors[index] + '; display: inline-block; width: 10px; height: 10px"></span>' + element.toLocaleString() + '</div>';
          });
        }else{
          steps.forEach((element, index, array) => {
            if (index === array.length - 1) {
              div.innerHTML += '<div><span width="10" height="10" style="background-color: ' + colors[index] + '; display: inline-block; width: 10px; height: 10px"></span>>' + element.toLocaleString() + '</div>';
            } else {
              div.innerHTML += '<div><span width="10" height="10" style="background-color: ' + colors[index] + '; display: inline-block; width: 10px; height: 10px"></span>' + element.toLocaleString() + '</div>';
            }
          });
        }
    }
      return div;
    };
    keysControl.addTo(map);
  }

  getMarkerPopup(data) {

    let y;
    return "<i>" + data.properties.study_name + "</i>";
  }


  /* INIT */
  ngOnInit(): void {
    let w = this.setup;
    this.token = this.setup.map.token;
    this.latitude = this.setup.map.geometry.coordinates[1];
    this.longitude = this.setup.map.geometry.coordinates[0];

    this.marker_icon = L.icon({
      iconUrl: this.setup.map.markerUrl,
      //shadowUrl: 'leaf-shadow.png',
      iconSize: this.setup.map.iconSize // size of the icon
      // shadowSize:   [50, 64], // size of the shadow
      //iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
      //shadowAnchor: [4, 62],  // the same for the shadow
      //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
  }

  /* AFTER VIEW */
  ngAfterViewInit() {

    this.lMap = L.map(this.setup.map.chartElement, {
      zoomControl: this.setup.map.zoomControl
    }).setView([this.latitude, this.longitude], this.setup.map.zoom);

    this.zoomControl = L.control
      .zoom({
        position: this.setup.map.zoomPosition
      })
      .addTo(this.lMap);

    this.tileLayer = L.tileLayer(this.token, {
      attribution: this.setup.map.attribution,
      maxZoom: this.setup.map.maxZoom,
      id: this.setup.map.id
    }).addTo(this.lMap);

    this.marker = L.marker([this.latitude, this.longitude], {
      icon: this.marker_icon
    })//.bindPopup(this.getMarkerPopup(this.setup)).addTo(this.lMap);

    this.geojsonLayer = L.geoJSON(this.setup.map.bricks, {
      style: (feature): any => {
        return {
          fillColor: this.brickReportMapService.getColor(feature.properties.sales_info.first_year_sales, this.setup.map.minMaxScales[0]),
          weight: 2,
          opacity: 0.7,
          color: 'gray',
          dashArray: '0',
          fillOpacity: 0.7
        };
      },
      onEachFeature: this.onEachFeature,
    }).addTo(this.lMap);

    //sacamos geojson con centroides para la capa de circulos:
    let json_ptos = this.setup.map.bricks.map(function(feat) {
      return {
        type: 'Feature',
        properties: feat.properties,
        geometry: feat.properties.centroid
    }
    });


    this.layer_ptos = L.geoJSON(json_ptos,{
      style:(feature): any =>{
        return {
          fillColor: this.brickReportMapService.getColor(feature.properties.sales_info.first_year_sales, this.setup.map.minMaxScales[0]),
          weight: 2,
          opacity: 0.7,
          color: 'gray',
          dashArray: '0',
          fillOpacity: 0.7
        };
      },
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, );
    },
    onEachFeature: function(feature, layer) {
        var label_building_type ='';
        if(feature.properties.building_type.includes('Fs')){
          label_building_type = 'FS'
        }else if(feature.properties.building_type.includes('Ins')){
          label_building_type = 'In'
        }        
        layer.bindTooltip(label_building_type, {direction: "center", permanent: true, className: 'labelMarkerOptions'});       
  }
    }).addTo(this.lMap);

    this.fitBrickBounds(this.lMap, this.geojsonLayer);
    this.drawColorKeys(this.lMap, this.setup.map.minMaxScales[0])

    this.lMap.on('zoomend', this.zoomMap);
  }


}
