<div class="container text-center">
  <button
    mat-button
    (click)="
      $event.stopPropagation();
      $event.preventDefault();
      openDialog('Añadir', {})
    "
    mat-flat-button
    color="primary"
  >
    Añadir restaurante MCD
  </button>
  <div class="margin-top--s"></div>

  <table
    mat-table
    [dataSource]="dataSource"
    #removetable
    class="remove-table mat-elevation-z0 competitors"
  >
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Dirección</th>
      <td mat-cell *matCellDef="let element">{{ element.address }}</td>
    </ng-container>

    <ng-container matColumnDef="kind_building">
      <th mat-header-cell *matHeaderCellDef>Tipo inmueble</th>
      <td mat-cell *matCellDef="let element">{{ element.view_kind_building }}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Acción</th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <a (click)="openDialog('Editar', element)">Editar</a> |
        <a (click)="openDialog('Eliminar', element)">Eliminar</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

</div>
