import { Component } from "@angular/core";
import { OnInit } from "@angular/core";

// forms
import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";

// dummy data
import sampleProvincias from "../../../assets/data/provincias.json";

// dummy data
import sampleInmuebles from "../../../assets/data/inmuebles.json";

import { Router } from "@angular/router";
import { GeoStudiesPrescriptiveDataService } from "../../app-services/geo-studies-prescriptive-data.service";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "../../app-widgets/popup-modals/edit-dialog/edit-dialog.component";

@Component({
  selector: "app-bricks-new-form",
  templateUrl: "./bricks-new-form.component.html",
  styleUrls: ["./bricks-new-form.component.scss"]
})
export class BricksNewFormComponent implements OnInit {
  title: string = "Nuevo formulario prescriptivo";
  paragraph: string =
    "Al pulsar guardar se iniciará un proceso de cálculo que" +
    " puede durar bastante tiempo. Una vez concluido, el" +
    " escenario quedará guardado en los estudios y podrá visualizarlo.";

  provinciaSelected = "01";
  province_idSelected = "01";
  inmuebleSelected = "-1";
  provincias = sampleProvincias;
  inmubles = sampleInmuebles;
  inmuebles_0 = sampleInmuebles;
  inmuebles_1 = sampleInmuebles;
  inmuebles_2 = sampleInmuebles;  
  inmuebles_3 = sampleInmuebles;
  inmuebles_4 = sampleInmuebles;

  variable_inmuebles:any = "inmubles";

  study_name = "Nuevo estudio";
  orderSelected = "1";
  orderArray = [1,2,3,4];
  min_sales = 0;
  max_sales_from_restaurants_percent = 0;
  max_restaurant_impact_percent = 0;

  kind_building = "Instore";
  building_type = "Instore Urban";
  number_vta_minSelected = 1300000;
  number_prct_vta_maxSelected = 30;
  number_prct_impact_maxSelected = 20;

  delivery = true;
  mccafe = true;
  mcauto = false;
  mckiosko = true;
  mcexpress = false;
  type_play = "No";
  seats_room = 130;
  seats_terrace_outdoor = 20;
  seats_terrace_indoor = 0;
  separate_entrance_mall = "No";
  total_parking_spaces = 0;
  own_parking = "No";
  own_parking_space = 0;
  province_id = this.provinciaSelected;
  accesibility = "A";
  visibility =  "A";

  studies : FormArray;
  columns:string[]; 


  get formArray(): any | null {
    return this.bricksStudyFormGroup.get("formArray");
  }

  constructor(private formBuilder: FormBuilder,  private router: Router, private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService,   public dialog: MatDialog,) {
    this.columns = ["Orden", "Tipo inmueble", "Ventas mínimas", "Porcentaje impacto máximo", "Porcentaje ventas ajenas máximo"];  
  }


  bricksStudyFormGroup = new FormGroup({
    study_name: new FormControl("", [
      Validators.required
    ]),
    study_zone_id: new FormControl("", [Validators.required]),
    studies: new FormControl([], [])
  });


  removeOptions(index, slab) {
    if(slab!='-1'){
      switch (index) {
        case 1: {
          this.inmuebles_2 = this.inmuebles_0.filter(element => element.key  != slab);
          this.inmuebles_3 = this.inmuebles_0.filter(element => element.key  != slab);
          this.inmuebles_4 = this.inmuebles_0.filter(element => element.key  != slab);
          break;
        }
        case 2: {
          this.inmuebles_3 = this.inmuebles_3.filter(element => element.key  != slab);
          this.inmuebles_4 = this.inmuebles_4.filter(element => element.key  != slab);
          break;
        }
        case 3: {
          this.inmuebles_4 = this.inmuebles_4.filter(element => element.key  != slab);
          break;
        }
        case 4: {
          break;
        }
      }
    }
    
  }

  onChange(filterValue: any, idx: any) {
      //change selector from next order:    
      if(filterValue =="instore_u"){      
     
        this.bricksStudyFormGroup.get('studies')['controls'][idx-1].patchValue({
          order: idx,
          inmueble: filterValue,
          kind_building: "Instore",
          building_type: "Instore Urban",
          min_sales: 1300000,
          max_sales_from_restaurants_percent: 30,
          max_restaurant_impact_percent: 20,
          delivery : true,
          mccafe : true,
          mcauto : false,
          mckiosko : true,
          mcexpress : false,
          type_play : "No",
          seats_room : 130,
          seats_terrace_outdoor : 20,
          seats_terrace_indoor : 0,
          separate_entrance_mall : "No",
          total_parking_spaces : 0,
          own_parking : "No",
          own_parking_space : 0,
          province_id : this.provinciaSelected,
          accesibility : "A",
          visibility :  "A",
        });
        
      }
      if(filterValue =="instore_m"){
       
        this.bricksStudyFormGroup.get('studies')['controls'][idx-1].patchValue({
          order: idx,
          inmueble: filterValue,
          kind_building: "Instore",
          building_type: "Instore Medio",
          min_sales: 1800000,
          max_sales_from_restaurants_percent: 30,
          max_restaurant_impact_percent: 20,
          delivery : true,
          mccafe : true,
          mcauto : false,
          mckiosko : true,
          mcexpress : false,
          type_play : "No",
          seats_room : 180,
          seats_terrace_outdoor : 40,
          seats_terrace_indoor : 0,
          separate_entrance_mall : "No",
          total_parking_spaces : 0,
          own_parking : "No",
          own_parking_space : 0,
          province_id : this.provinciaSelected,
          accesibility : "A",
          visibility :  "A",
        });
        
      }
      if(filterValue =="freestanding1"){
        
        this.bricksStudyFormGroup.get('studies')['controls'][idx-1].patchValue({
          order: idx,
          inmueble: filterValue,
          kind_building: "Free Standing",
          building_type: "Fst 1",
          min_sales: 1300000,
          max_sales_from_restaurants_percent: 30,
          max_restaurant_impact_percent: 20,
          delivery : true,
          mccafe : true,
          mcauto : true,
          mckiosko : true,
          mcexpress : false,
          type_play : "Play Interior",
          seats_room : 109,
          seats_terrace_outdoor : 70,
          seats_terrace_indoor : 0,
          separate_entrance_mall : "No",
          total_parking_spaces : 25,
          own_parking : "Sí",
          own_parking_space : 25,
          province_id :this.provinciaSelected,
          accesibility : "A",
          visibility :  "A"
        });
        


      }
      if(filterValue =="freestanding2"){
        
        this.bricksStudyFormGroup.get('studies')['controls'][idx-1].patchValue({
          order: idx,
          inmueble: filterValue,
          kind_building: "Free Standing",
          building_type: "Fst 2",
          min_sales: 1800000,
          max_sales_from_restaurants_percent: 30,
          max_restaurant_impact_percent: 20,
          delivery : true,
          mccafe : true,
          mcauto : true,
          mckiosko : true,
          mcexpress : false,
          type_play : "Play Exterior Con Carpa",
          seats_room : 120,
          seats_terrace_outdoor : 100,
          seats_terrace_indoor : 0,
          separate_entrance_mall : "No",
          total_parking_spaces : 25,
          own_parking : "Sí",
          own_parking_space : 25,       
          accesibility : "A",
          visibility :  "A"
        });
        
      }if(filterValue =="-1"){      
        for(let rest = idx-1; rest<this.bricksStudyFormGroup.get('studies')['controls'].length; rest++){
          this.bricksStudyFormGroup.get('studies')['controls'][rest].patchValue({
            order: rest+1,
            inmueble: filterValue,
            kind_building: "Ninguno",
            building_type: "Ninguno",
            min_sales: 0,
            max_sales_from_restaurants_percent: 0,
            max_restaurant_impact_percent: 0 
          });
        }   
        
      }

    this.removeOptions(idx, filterValue);

  }  


  private validateGroup2(formGroup: FormGroup) {
      let studiesArr = this.bricksStudyFormGroup.value.studies.filter(function(item) {
        return item.inmueble !== "-1"
      });
      var missing = new Array();
      var a = [];
      for (var i = 0; i < studiesArr.length; i++) {
        a.push(studiesArr[i].order);    
      }
      for (var i = 1; i <= studiesArr.length; i++) {
        if (a.indexOf(i) == -1) {
          missing.push(i); }
      }
      if(studiesArr.length>0){
        if(missing.length>0){
          return  { 'nullArr': true };
        }else{
          return null;

        }
      }
  }

  BuildingValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value.inmueble == '-1') {
      return { 'age': true }; }
    return null;
  }


  ngOnInit() {
    var array =[];
      array.push(
        this.formBuilder.group({         
      order: 1,
      inmueble: [this.inmuebleSelected,  ],
      kind_building: [this.kind_building,],
      building_type: [this.building_type],
      min_sales: [this.min_sales, Validators.required],
      max_sales_from_restaurants_percent: [this.max_sales_from_restaurants_percent, Validators.required],
      max_restaurant_impact_percent: [this.max_restaurant_impact_percent, Validators.required],
      delivery : true,
      mccafe : true,
      mcauto : false,
      mckiosko : true,
      mcexpress : false,
      type_play : "No",
      seats_room : 130,
      seats_terrace_outdoor : 20,
      seats_terrace_indoor : 0,
      separate_entrance_mall : "No",
      total_parking_spaces : 0,
      own_parking : "No",
      own_parking_space : 0,
      province_id : this.provinciaSelected,
      accesibility : "A",
      visibility :  "A"
    }
    ,{
      validator: (formGroup: FormGroup) => {      
        let val2 =  this.BuildingValidator(formGroup);
        let val =  this.validateGroup2(formGroup);
        let ok:any = null;      
        if(val){
          if(val2){
            ok = {val, val2};
          }else{
            ok = val
          }
        }else{
          if(val2){
            ok =val2;
            }
        }
        return ok;
      }
    }
    )
    );
    for(var t = 1; t< this.orderArray.length ; t++){  
        array.push(
          this.formBuilder.group({              
            order: t+1,
            inmueble: [this.inmuebleSelected,  ],
            kind_building: [this.kind_building,],
            building_type: [this.building_type],
            min_sales: [this.min_sales, Validators.required],
            max_sales_from_restaurants_percent: [this.max_sales_from_restaurants_percent, Validators.required],
            max_restaurant_impact_percent: [this.max_restaurant_impact_percent, Validators.required],
            delivery : true,
            mccafe : true,
            mcauto : false,
            mckiosko : true,
            mcexpress : false,
            type_play : "No",
            seats_room : 130,
            seats_terrace_outdoor : 20,
            seats_terrace_indoor : 0,
            separate_entrance_mall : "No",
            total_parking_spaces : 0,
            own_parking : "No",
            own_parking_space : 0,
            province_id : this.provinciaSelected,
            accesibility : "A",
            visibility :  "A"
          }
          ,{
            validator: (formGroup: FormGroup) => {          
              let val2 =  this.BuildingValidator(formGroup);
              let val =  this.validateGroup2(formGroup);
              let ok:any = null;
              if(val){
                if(val2){
                  ok = {val, val2};
                }else{
                  ok = val
                }
              }else{
                if(val2){
                  ok = val2;
                  }
              }
            return ok;
            }
          }      
        ));
      }


      this.bricksStudyFormGroup = this.formBuilder.group({
        study_name: [this.study_name, Validators.required],
        study_zone_id: [this.provinciaSelected],
        studies: this.formBuilder.array(
          array
         )
      });

      this.columns = ["Orden", "Tipo inmueble", "Ventas mínimas", "Porcentaje impacto máximo", "Porcentaje ventas ajenas máximo"];      
  }

  openDialogEdit(expandedStudy, text): void {    
      const dialogRefEdit = this.dialog.open(EditDialogComponent, {
        width: "320px",
        data: text
      });
      dialogRefEdit.afterClosed().subscribe(result => {
        if (result) {
          console.log("Yes clicked", result);
        }
      });
    
 }


 setValueToNumber() {
   for(let ind= 0; ind< this.bricksStudyFormGroup.value.studies.length; ind++){
    this.bricksStudyFormGroup.get('studies')['controls'][ind].patchValue({
      max_sales_from_restaurants_percent: Number(this.bricksStudyFormGroup.value.studies[ind].max_sales_from_restaurants_percent) , 
      min_sales: Number(this.bricksStudyFormGroup.value.studies[ind].min_sales),
      max_restaurant_impact_percent: Number(this.bricksStudyFormGroup.value.studies[ind].max_restaurant_impact_percent)
    });
    
    return this.bricksStudyFormGroup.value.studies;
   }
}


  onSubmit() {
    this.bricksStudyFormGroup.value.studies = this.setValueToNumber();

    this.bricksStudyFormGroup.value.studies = this.bricksStudyFormGroup.value.studies.filter(function(item) {
      return item.inmueble !== "-1"
    });
    var missing = new Array();
    var a = [];
    for (var i = 0; i < this.bricksStudyFormGroup.value.studies.length; i++) {
      a.push(this.bricksStudyFormGroup.value.studies[i].order);
    
    }
    for (var i = 1; i <= this.bricksStudyFormGroup.value.studies.length; i++) {
    if (a.indexOf(i) == -1) {
      missing.push(i);
    }
    }


    if(this.bricksStudyFormGroup.value.studies.length>0){
      if(missing.length>0){

        this.openDialogEdit(this.bricksStudyFormGroup.value, "No puede existir un Tipo de restaurante sin rellenar entre filas.");
      }else{

    //build the json to send:
    let prescript_json = this.bricksStudyFormGroup.value;
    prescript_json.group = "advance";
    console.log(JSON.stringify(prescript_json));
    //api nommon postman call
   
    this.geoStudiesPrescriptiveDataService
      .executeStudyPrescriptive(prescript_json)
      .subscribe(
        (response) => {
          console.log("executeStudyPrescriptive Response is: ", response);
           //redirect to list studies:
          this.router.navigateByUrl("/prescriptive/studies");
          },
        (error) => {
          console.error("executeStudyPrescriptive An error occurred, ", error);
           //redirect to list studies:
          //this.router.navigateByUrl("/prescriptive/studies");
          }
        );

   
  //redirect to list studies:
    this.router.navigateByUrl("/prescriptive/studies");
  }


    }else{
    this.openDialogEdit(this.bricksStudyFormGroup.value, "Debe introducir al menos un Tipo de inmueble para realizar el estudio Prescriptivo.");
    }


  }
}
