import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { GeoStudiesDataService } from "./geo-studies-data.service";
import { AuthenticationService } from "./authentication.service";
import { GeoStudiesPrescriptiveDataService } from "./geo-studies-prescriptive-data.service";
import { BrickReportService } from "../brick-report/brick-report.service";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "../app-widgets/popup-modals/edit-dialog/edit-dialog.component";

@Injectable({
  providedIn: "root"
})
export class TableStudyActionsService {
  constructor(
    private router: Router,
    private geoStudiesDataService: GeoStudiesDataService,
    private authenticationService: AuthenticationService,
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService,
    private brickReportService: BrickReportService,
    public dialog: MatDialog,
  ) {}

  editStudy(study): void {
    console.log("Edit clicked", study);
  }
  seeMap(study): void {
    console.log("Ver mapa clicked", study);
    this.geoStudiesDataService.getStudyMap(study);
  }

  seeMapP(study): void {
    console.log("Ver mapa clicked", study);

    this.router.navigate(["/prescriptive/map/study/" + study._id]);
    this.geoStudiesPrescriptiveDataService.setIdStudyPrescriptive(study._id);
    this.geoStudiesPrescriptiveDataService.getStudyMap(study._id);
  }


  openDialogSee(expandedStudy): void {
    if (expandedStudy.properties.study.state_code == "processed") {
      this.seeMapMassiveP(expandedStudy._id);
    } else {
      const dialogRefEdit = this.dialog.open(EditDialogComponent, {
        width: "320px",
        data: "No están disponibles los datos. Espere a estado Disponible."
      });

      dialogRefEdit.afterClosed().subscribe(result => {
        if (result) {
          console.log("Yes clicked", expandedStudy);
        }
      });
    }
  }

  seeMapMassiveP(study): void {
    console.log("Ver mapa clicked", study);
    this.router.navigate(["/massive-prescriptive/map/study/" + study]);
    this.geoStudiesPrescriptiveDataService.setIdStudyMassivePrescriptive(study);
    this.geoStudiesPrescriptiveDataService.getMassiveStudyMap(study);
  }


  seeOnline(study): void {
    let url = environment.urlExpansionReport + "?informe=" + study._id + "";
    this.authenticationService.currentUser.subscribe(object => {
      //window.open();
      //Hidden Form
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      sessionStorage.setItem("hash", object.token);
      //url = url + "&data=" + object.username;
      form.setAttribute("action", url);
      // form.setAttribute("target", "studyhtml");
      form.setAttribute("target", "_blank");
      form.setAttribute("rel", "opener");
      //Hidden Field
      var hiddenField1 = document.createElement("input");
      var hiddenField2 = document.createElement("input");

      //username ID
      hiddenField1.setAttribute("type", "hidden");
      hiddenField1.setAttribute("id", "username");
      hiddenField1.setAttribute("name", "username");
      hiddenField1.setAttribute("value", object.username);

      //Password
      hiddenField2.setAttribute("type", "hidden");
      hiddenField2.setAttribute("id", "password");
      hiddenField2.setAttribute("name", "password");
      hiddenField2.setAttribute("value", object.password);

      let tryingUser = this.authenticationService.currentUserValue;
      sessionStorage.setItem("tryingUser", JSON.stringify(tryingUser));

      window.name = JSON.stringify(tryingUser);

      localStorage.setItem('getSessionStorage', JSON.stringify(tryingUser));


      form.appendChild(hiddenField1);
      form.appendChild(hiddenField2);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    });
  }

  seeOnlinePrescriptive(study): void {
    let tryingUser = this.authenticationService.currentUserValue;
    sessionStorage.setItem("tryingUser", JSON.stringify(tryingUser));
    /*let urlt = this.router.serializeUrl(
      this.router.createUrlTree([`/brick-report/${study._id}`])
    );*/
    let urlt =location.href.substring(0,location.href.indexOf('#')+2)  +`/brick-report/${study._id}`;  
    window.open(urlt, '_blank');    
   /*
   //opcion para abrir en misma ventana
    this.router.navigate(["/brick-report"]);
    this.brickReportService.setSelectedStudyReportPresc(study);
    */
  }

  downloadStudy(study): void {
    console.log("Descargar clicked", study);
    let url = environment.urlExpansionReport + "?informe=" + study._id + "";
    window.open(url, "_blank");
  }
}
