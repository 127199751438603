import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabStudiesComponent } from "./tab-studies.component";

@NgModule({
  declarations: [TabStudiesComponent],
  imports: [SharedModule],
  exports: [TabStudiesComponent],
  providers: []
})
export class TabStudiesModule {}
