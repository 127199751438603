import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabExpansionComponent } from "./tab-expansion.component";
import { ReactiveFormRangeSliderModule } from "../../../../form-controls/reactive-form-range-slider/reactive-form-range-slider.module";

@NgModule({
  declarations: [TabExpansionComponent],
  imports: [SharedModule, ReactiveFormRangeSliderModule],
  exports: [TabExpansionComponent],
  providers: []
})
export class TabExpansionModule {}
