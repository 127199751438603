<nav class="nav-inner-content">
  <!--<mat-tab-group
    mat-align-tabs="center"
    [selectedIndex]="0"
    (selectedTabChange)="changeDataTab($event)"
  >
    <mat-tab label="Expansión">
   
      <ng-template matTabContent> -->
        <app-tab-massive-expansion [studyData]="studyData"></app-tab-massive-expansion>
        
        

         
          
          <section *ngIf="ready" class="full-width clearfix margin-top--xs">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Brick" />
            </mat-form-field>
            <form *ngIf="ready" >
              <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="">
                
                <ng-container matColumnDef="properties.ID">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td
                    mat-cell
                    *matCellDef="let element"                    
                    (click)="clickBrick(element)"
    
                  >
                    <p>
                      <strong> ID - {{ element.properties.ID }}</strong>
                    </p>
                    
                    <p>{{ element.properties.sales_info.first_year_sales | number: "1.0-0":"es" }} €</p>
                    
                  </td>
                </ng-container>

                <ng-container matColumnDef="properties.building_type">
                  <th mat-header-cell *matHeaderCellDef > % VENTAS IMPACTO - </th>
                  <td
                    mat-cell
                    *matCellDef="let element"                    
                    (click)="clickBrick(element)"
                    style="text-align: center;"
                  >
                    <p>
                      <strong> Ventas asociadas </strong>
                    </p>
                    <p>{{ element.properties.sales_info.perc_of_sales_associated_to_existing_rest.toFixed(2) }} % </p>
                  </td>
                </ng-container>
            
                
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>              
            </form>           
            </section>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <section *ngIf="!ready">              
                <p >No hay bricks</p>              
            </section>
            
          
          
         
    <!--   </ng-template>
   </mat-tab>-->

    <!--  *ngIf="readyVentas" -->
<!--
    <mat-tab label="Buscador">
      <ng-template matTabContent>
        <app-tab-buscador></app-tab-buscador>
      </ng-template>
    </mat-tab>
    <mat-tab label="Temáticos">
      <ng-template matTabContent>
        <app-tab-tematicos></app-tab-tematicos>
      </ng-template>
    </mat-tab>
  -->

 <!-- </mat-tab-group>-->
</nav>
