import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  OnDestroy
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { DataService } from "src/app/app-services/data.service";

@Component({
  selector: "app-tab-competencia",
  templateUrl: "./tab-competencia.component.html",
  styleUrls: ["./tab-competencia.component.scss"]
})
export class TabCompetenciaComponent implements OnChanges, OnDestroy {
  checked = false;
  checkedOth = false;
  selectedYears: any[];
  @Input()
  brandListZoom: any;
  displayedColumns: string[] = ["name"];
  competencias: any;
  competenciasOthers: any;
  dataSource = new MatTableDataSource([]);
  selected_brand: any;
  ensenyas = new FormControl();

  ensenyasOth = new FormControl();
  ensenyasListOth: string[];

  ensenyasList: string[];
  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataService.getFilteredCompetitors(this.dataSource.filteredData);
  }

  selectAll(ev, values) {
    
    if (ev.checked) {
      this.ensenyas.setValue(values);
      this.applyFilterBrand(this.ensenyas.value);
    }
    if (ev.checked == false) {
      this.ensenyas.setValue([]);
      this.applyFilterBrand(this.ensenyas.value);
    }else if(ev== false){
      this.ensenyas.setValue([]);
      this.applyFilterBrand(this.ensenyas.value);
    }
  }

  selectOthers(ev, values) {
    if (ev.checked) {
      //let values = []; //search competitors others benchmark2
      this.ensenyasOth.setValue(values);
      this.applyFilterOthers(ev.checked);
    }
    if (ev.checked == false) {
      this.ensenyasOth.setValue([]);
      this.applyFilterOthers(ev.checked);
    }
  }

  applyFilterOthers(selectedAll: any) {
    this.selected_brand = "";
    const _this = this;
    this.dataSource = new MatTableDataSource([]);
    if (selectedAll) {
      let coords = this.dataService.getCoordsMap();
      this.unsubscribe = this.dataService
        .getCompetitorsOthersByBrandsCoords(
          this.selected_brand,
          coords[0],
          coords[1],
          coords[2],
          coords[3]
        )
        .subscribe((result: any) => {
          result;
          _this.competenciasOthers = result;
          _this.competenciasOthers = _this.competenciasOthers.map(element => {
            element.properties.info.id = element.properties.ID_;
            element.properties.info.geometry = element.geometry;
            return element.properties.info;
          });

          
        //  To see data in Filter:
          if(_this.checked){
            let data =[];
            data = _this.competencias.concat(_this.competenciasOthers) ;
           // _this.dataSource = new MatTableDataSource(data);
           _this.dataSource = new MatTableDataSource(_this.competencias);
            
          }else{
          //  _this.dataSource = new MatTableDataSource(_this.competenciasOthers);
          _this.dataSource = new MatTableDataSource(_this.competencias);
          }

          _this.dataSource.sortData(_this.dataSource.filteredData, _this.sort);
          _this.ensenyasListOth = _this.competenciasOthers;

          let ensenyasLOth: any = [];
          let ensenyasLOth2: any = [];
          _this.ensenyasListOth.forEach(element => {
            ensenyasLOth.push(element["brand"]) ;
          });
          ensenyasLOth.forEach(element => {
            ensenyasLOth2 = element + ";" + ensenyasLOth2;
          });

          ensenyasLOth2 = ensenyasLOth2.substring(0, ensenyasLOth2.length - 1);
          _this.ensenyasListOth = ensenyasLOth
          _this.ensenyasOth.setValue(ensenyasLOth);

          _this.dataService.getFilteredCompetitorsOthers(
            _this.competenciasOthers
          );

          _this.dataSource.paginator = _this.paginator;
          _this.dataSource.sort = _this.sort;

          
        });
    } else {
      if(_this.checked){
        let data =[];
        data = _this.competencias.concat(_this.competenciasOthers) ;
       // _this.dataSource = new MatTableDataSource(data);
       _this.dataSource = new MatTableDataSource(_this.competencias);
       _this.dataSource.sortData(_this.dataSource.filteredData, _this.sort);
       _this.dataSource.paginator = _this.paginator;
          _this.dataSource.sort = _this.sort;
      }else{
        //_this.dataSource = new MatTableDataSource(_this.competencias);
      }
      _this.dataService.getFilteredCompetitorsOthers([]);

      _this.dataSource = new MatTableDataSource(_this.competencias);
      _this.dataSource.sortData(_this.dataSource.filteredData, _this.sort);
      _this.dataSource.paginator = _this.paginator;
         _this.dataSource.sort = _this.sort;
     
    }
  }
  applyFilterBrand(filterValue: any) {
    //search according to selected brand
    this.selected_brand = "";
    filterValue.forEach(element => {
      this.selected_brand = element + ";" + this.selected_brand;
    });
    this.selected_brand = this.selected_brand.substring(
      0,
      this.selected_brand.length - 1
    );
    const _this = this;
    let coords = this.dataService.getCoordsMap();
    this.dataService.setBrandFilter(this.selected_brand);
    this.dataSource = new MatTableDataSource([]);
    if(coords){
      this.unsubscribe2 = this.dataService
      .getCompetitorsByBrandsCoords(
        this.selected_brand,
        coords[0],
        coords[1],
        coords[2],
        coords[3]
      )
      .subscribe((result: any) => {
        result;
        _this.competencias = result;
        _this.competencias = _this.competencias.map(element => {
          element.properties.info.id = element.properties.ID_;
          element.properties.info.geometry = element.geometry;
          return element.properties.info;
        });
        if(_this.checkedOth){
          let data =[];
          data = _this.competencias.concat(_this.competenciasOthers) ;
         // _this.dataSource = new MatTableDataSource(data);
         _this.dataSource = new MatTableDataSource(_this.competencias);
          
        }else{
          _this.dataSource = new MatTableDataSource(_this.competencias);
        }

        
        _this.dataSource.sortData(_this.dataSource.filteredData, _this.sort);
        _this.dataService.getFilteredCompetitors(_this.dataSource.filteredData);

        _this.dataSource.paginator = _this.paginator;
        _this.dataSource.sort = _this.sort;
      });
    }
    
  }

  placeSelected(elementId: string, geometry: string, layer: string) {
    this.dataService.getZoomOnPlace(geometry, "layer_marker");
  }

  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.name.toLowerCase().includes(searchText);
    });
  }

  constructor(private dataService: DataService) {}

  ngOnChanges() {
    this.selectAll(false, null);
    const _this = this;
    let result = this.dataService.getBrandsByCoordsF();
    if (result != undefined) {
      this.ensenyasList = result;
      let ensenyasL: any = [];
      this.ensenyasList.forEach(element => {
        ensenyasL = element + ";" + ensenyasL;
      });
      ensenyasL = ensenyasL.substring(0, ensenyasL.length - 1);
      this.ensenyas.setValue(this.ensenyasList);
      let coords = this.dataService.getCoordsMap();
      if(!this.checked){
        this.selectAll(false, null);
    }
    this.dataSource = new MatTableDataSource([]);
     // if(this.checked){
      if (coords != undefined) {
        _this.unsubscribe3 = _this.dataService
          .getCompetitorsByBrandsCoords(
            ensenyasL,
            coords[0],
            coords[1],
            coords[2],
            coords[3]
          )
          .subscribe((result1: any) => {
            _this.competencias = result1;
            _this.competencias = _this.competencias.map(element => {
              element.properties.info.id = element.properties.ID_;
              element.properties.info.geometry = element.geometry;
              return element.properties.info;
            });
           /* if(_this.checkedOth){
              let data =[];
              data = _this.competencias.concat(_this.competenciasOthers) ;
             // _this.dataSource = new MatTableDataSource(data);
             _this.dataSource = new MatTableDataSource(_this.competencias);
            }else{
              _this.dataSource = new MatTableDataSource(_this.competencias);
            }*/

            if(this.checked){
              _this.dataSource = new MatTableDataSource(_this.competencias);
            }

           // _this.dataSource = new MatTableDataSource(_this.competencias);
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.sort = _this.sort;
          });
      }
   // }
      }
      
    
  

    this.unsubscribe = this.dataService.competitorZoomDisabledDescriptiveChanged$.subscribe(
      competitorDisabled => {
        let competitorDisabledCheck = !competitorDisabled;
        if(competitorDisabled){
          this.checked = competitorDisabledCheck;
        }
        //this.ensenyas.setValue(this.ensenyasList);
        this.selectAll(competitorDisabledCheck, this.ensenyasList);
        
      }
    );

  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
  }
}
