<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checkedClosed" (change)="showClosed()">Mostrar cerrados</mat-checkbox>
</section>

<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checked" (change)="showAll()">Mostrar abiertos</mat-checkbox>
</section>

<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checkedInflu" (change)="showInfluence()">Influencia mcd</mat-checkbox>
</section>


<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checkedDelivery" (change)="showDelivery()">Áreas delivery</mat-checkbox>
</section>

<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checkedPlan5" (change)="showPlan5()">Plan 5 años</mat-checkbox>
</section>

<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checkedProxApert" (change)="showProxApert()">Próxima apertura</mat-checkbox>
</section>

<section class="full-width clearfix">
  <mat-form-field>
    <mat-label>Real State Type</mat-label>
    <mat-select multiple (selectionChange)="filterKindBuilding($event.value)">
      <mat-option *ngFor="let ensenya of kindBuildingList" [value]="ensenya">{{ ensenya }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Franquiciado</mat-label>
    <mat-select multiple (selectionChange)="filterFranchised($event.value)" [formControl]="selectedOptions">
      <mat-option #allSelected  [value]="0" (click)="toggleAllSelection( franchisedList)" >All</mat-option>
      
      <mat-option *ngFor="let b of franchisedList" [value]="b">{{
        b
        }}</mat-option>

    </mat-select>
  </mat-form-field>
</section>



<section class="full-width clearfix margin-top--xs">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="places">
    <!-- ONE Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="restaurantSelected(element.id, element.geometry)">
        <p>{{ element.id_mcd }}</p>
        <p>
          <strong>{{ element.name }}</strong>
        </p>
        <p>{{ element.address }}</p>
        <p>
          {{ element.zip_code }} | {{ element.town }},
          {{ element.province }}
        </p>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</section>