import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";

import { EditUserRoutingModule } from "./edit-user-routing.module";

@NgModule({
  declarations: [EditUserRoutingModule.components],
  imports: [SharedModule, EditUserRoutingModule],
  exports: [],
  providers: []
})
export class EditUserModule {}
