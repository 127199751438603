import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UsersService } from "src/app/app-services/users.service";
import { Router } from "@angular/router";

export interface UserProfile {
  name: string;
  value: string;
}

@Component({
  selector: "app-new-user",
  templateUrl: "./new-user.component.html",
  styleUrls: ["./new-user.component.scss"]
})
export class NewUserComponent implements OnInit, OnDestroy {
  userProfiles: UserProfile[] = [
    { name: "Avanzado", value: "advance" },
    { name: "Básico", value: "basic" }
  ];

  constructor(private userService: UsersService, private router: Router) {}

  userForm = new FormGroup({
    //userName: new FormControl("", [Validators.required]),
    userEmail: new FormControl("", [Validators.required, Validators.email]),
    userFirstName: new FormControl("", [Validators.required]),
    userSurName: new FormControl("", [Validators.required]),
    userPassword: new FormControl("", [Validators.required]),
    //userSend: new FormControl("", [Validators.required]),
    userProfile: new FormControl("", [Validators.required])
  });
  unsubscribe: any;

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.userForm.value, this.userForm.status);

    let userData = {
      username: this.userForm.value.userEmail,
      name: this.userForm.value.userFirstName,
      //+
      // " " +
      // this.userForm.value.userSurName,
      surname: this.userForm.value.userSurName,
      password: this.userForm.value.userPassword,
      group: this.userForm.value.userProfile
    };
    this.unsubscribe = this.userService.createUser(userData).subscribe(
      response => {
        console.log("Response is: ", response);
        this.router.navigateByUrl("/users");
      },
      error => {
        console.error("An error occurred, ", error);
      }
    );
  }

  ngOnInit() {}
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
