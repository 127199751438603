<!-- app.component.html -->
<div class="container text-center">
  <div class="margin-top--s"></div>

  <!-- <table
    mat-table
    [dataSource]="dataSource"
    #removetable
    class="remove-table mat-elevation-z0"
  > -->
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="mat-elevation-z0 competitors"
  >
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- nombre Column -->
    <ng-container matColumnDef="id_brand">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">
        {{ element.properties.info.id_brand }}
      </td>
    </ng-container>

    <!-- nombre_local Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element">
        {{ element.properties.info.name }}
      </td>
    </ng-container>

    <!-- 
    <ng-container matColumnDef="province">
      <th mat-header-cell *matHeaderCellDef>Provincia</th>
      <td mat-cell *matCellDef="let element">
        {{ element.properties.info.province }}
      </td>
    </ng-container>
-->
    <ng-container matColumnDef="distance_tostudy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Distancia (metros)</th>
      <td mat-cell *matCellDef="let element">
        {{ element.properties.info.distance_tostudy }}
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Acción</th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <a (click)="openDialog('Editar', element)">Editar</a> |
        <a (click)="openDialog('Eliminar', element)">Eliminar</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
