import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { OptionsFromJsonComponent } from "./options-from-json.component";

@NgModule({
  declarations: [OptionsFromJsonComponent],
  imports: [SharedModule],
  exports: [OptionsFromJsonComponent],
  providers: []
})
export class OptionsFromJsonModule {}
