import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";
import { SidenavDataComponent } from "./sidenav-data.component";
import { RotateArrowModule } from "../../app-widgets/buttons/rotate-arrow/rotate-arrow.module";

import { DataFichaModule } from "../sidenav-data/data-ficha/data-ficha.module";
import { VentasModule } from "../sidenav-data/data-ventas/data-ventas.module";
import { DataCompetenciaModule } from "../sidenav-data/data-competencia/data-competencia.module";
import { DataEntornoModule } from "../sidenav-data/data-entorno/data-entorno.module";
import { DataPresenciaModule } from "../sidenav-data/data-presencia/data-presencia.module";
import { DataTraficoModule } from "../sidenav-data/data-trafico/data-trafico.module";

@NgModule({
  declarations: [SidenavDataComponent],
  imports: [
    SharedModule,
    RotateArrowModule,
    DataFichaModule,
    VentasModule,
    DataCompetenciaModule,
    DataEntornoModule,
    DataPresenciaModule,
    DataTraficoModule
  ],
  exports: [SidenavDataComponent],
  providers: []
})
export class SidenavDataModule {}
