import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { GeoStudiesTableComponent } from "./geo-studies-table.component";
import { GeoStudiesDataService } from "../../../app-services/geo-studies-data.service";
import { StudySalesModule } from "../../../app-widgets/tables/study-sales/study-sales.module";

import { DeleteDialogModule } from "../../../app-widgets/popup-modals/delete-dialog/delete-dialog.module";
import { EditDialogComponent } from "src/app/app-widgets/popup-modals/edit-dialog/edit-dialog.component";
import { EditDialogPartialPredictiveStudyModule } from "src/app/app-widgets/popup-modals/edit-dialog-partial-predictive-study/edit-dialog-partial-predictive-study.module";

@NgModule({
    declarations: [GeoStudiesTableComponent, EditDialogComponent],
    imports: [SharedModule, StudySalesModule, DeleteDialogModule,EditDialogPartialPredictiveStudyModule ],
    exports: [GeoStudiesTableComponent],
    providers: [GeoStudiesDataService]
})
export class GeoStudiesTableModule {}
