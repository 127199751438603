
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";

import { AddDialogBoxLocatorComponent } from "./add-dialog-box-locator.component";

//import { McdGeoMakerModule } from "../../../../app-widgets/cartography/mcdGeoMaker/mcdGeoMaker.module";
import { McdGeoLocatorMapModule } from "../../../../app-widgets/cartography/mcdGeoLocatorMap/mcdGeoLocatorMap.module";
import { SearchboxModule } from "../../../../app-widgets/form-controls/searchbox/searchbox.module";


@NgModule({
  declarations: [AddDialogBoxLocatorComponent ],
  imports: [SharedModule, McdGeoLocatorMapModule,SearchboxModule],
  exports: [AddDialogBoxLocatorComponent],
  providers: []
})
export class AddDialogBoxlocatorModule {}

