<mat-accordion class="accordion">
  <mat-expansion-panel
    *ngFor="let d of dataSource"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img
          src="../../../../assets/img/marker_icons/markers/{{ d.id_brand }}.svg"
          alt="Smiley face"
          height="32"
          width="32"
          style="margin-right: 8px"
        />
        {{ d.local_name }}
      </mat-panel-title>
      <mat-panel-description>
        <i class="material-icons">
          my_location
        </i>
        {{ d.dist_meters_mcd_nearby | number: "1.0-0":"es-ES" }} metros
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{ d.address }} | {{ d.zip_code }} | {{ d.town }} | {{ d.province }}</p>
    <p>Apertura: {{ d.openning_date }}</p>
    <p>Centro comercial: {{ d.mall }}</p>
  </mat-expansion-panel>
</mat-accordion>
