<section class="full-width clearfix padding-xs margin-top--s" *ngIf="ready">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">

      <h2 class="tab-headline">INFORMACIÓN BÁSICA</h2>
      <ul role="list">
        <li class="margin-top--m" role="listitem">
          <div class="mat-icon--icon">
            <mat-icon>assignment</mat-icon>
          </div>
          <label class="mat-icon--label">{{
            studyData.properties.info.name
            }}</label>
        </li>

        <li class="margin-top--xs" role="listitem">
          <div class="mat-icon--icon"><mat-icon>calendar_today</mat-icon></div>
          <label class="mat-icon--label">{{
            studyData.properties.study.created
            }}</label>
        </li>

        <li class="margin-top--xs" role="listitem">
          <div class="mat-icon--icon">
            <mat-icon>store_mall_directory</mat-icon>
          </div>
          <label class="mat-icon--label">{{
            studyData.properties.info.kind_building
            }}</label>
        </li>
      </ul>

      <app-study-sales [studyData]="studyData"></app-study-sales>

      <div class="margin-top--s"></div>
    </div>

    <!-- [mcDesayunos]="mcDesayunos" -->
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
      <h2 class="tab-headline">RESUMEN DE SERVICIOS</h2>
      <app-services-icons [mcCafe]="mcCafe" [mcAuto]="mcAuto" [mcExpress]="mcExpress" [mcKiosko]="mcKiosko"
        [mcPlayPlace]="mcPlayPlace" [mcParking]="mcParking" [mcDelivery]="mcDelivery"></app-services-icons>
      <table style="width:100%" class="margin-top--s">
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!--
          <tr>
            <td>McCafe</td>
            <td>
              {{ studyData.properties.info.mccafe === true ? "Sí" : "No" }}
            </td>
          </tr>
-->
          <tr>
            <td>McAuto</td>
            <td>
              {{ studyData.properties.info.mcauto === true ? "Sí" : "No" }}
            </td>
          </tr>
          <tr>
            <td>McExpress</td>
            <td>
              {{ studyData.properties.info.mcexpress === true ? "Sí" : "No" }}
            </td>
          </tr>

          <tr>
            <td>McKiosko</td>
            <td>
              {{ studyData.properties.info.mckiosko === true ? "Sí" : "No" }}
            </td>
          </tr>

          <!-- <tr>
            <td>Desayuno</td>
            <td>
              {{ studyData.properties.info.breakfast === true ? "Sí" : "No" }}
            </td>
          </tr> -->
          <tr>
            <td>Delivery</td>
            <td>
              {{ studyData.properties.info.delivery === true ? "Sí" : "No" }}
            </td>
          </tr>

          <tr>
            <td>Tipo Play</td>
            <td>{{ studyData.properties.info.type_play }}</td>
          </tr>

          <tr>
            <td>Asientos salón</td>
            <td>{{ studyData.properties.info.seat_room }}</td>
          </tr>
          <tr>
            <td>Asientos terraza exterior</td>
            <td>{{ studyData.properties.info.seat_terrace_outward }}</td>
          </tr>
          <tr>
            <td>Asientos terraza interior</td>
            <td>{{ studyData.properties.info.seat_terrace_indoor }}</td>
          </tr>

          <tr>
            <td>Entrada independiente CC</td>
            <td>{{ studyData.properties.info.separate_entrance_mall }}</td>
          </tr>

          <!-- <tr>
            <td>Datos parking</td>
            <td>{{ studyData.properties.info.own_parking }}</td>
          </tr> -->

          <tr>
            <td>Plazas totales parking</td>
            <td>{{ studyData.properties.info.total_parking_spaces }}</td>
          </tr>
          <!-- <tr>
            <td>Parking propio</td>
            <td>{{ studyData.properties.info.own_parking }}</td>
          </tr> -->

          <tr>
            <td>Plazas parking propio</td>
            <td>{{ studyData.properties.info.own_parking_space }}</td>
          </tr>

          <tr>
            <td>Accesibilidad</td>
            <td>{{ studyData.properties.info.accessibility }}</td>
          </tr>

          <tr>
            <td>Visibilidad</td>
            <td>{{ studyData.properties.info.visibility }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>