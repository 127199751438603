import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";
import { OptionsFromJsonModule } from "../../../../app-widgets/form-controls/options-from-json/options-from-json.module";
import { OptionsFromParentModule } from "../../../../app-widgets/form-controls/options-from-parent/options-from-parent.module";
import { GroupedBarModule } from "../../../../app-widgets/charts/grouped-bar/grouped-bar.module";

import { PresenciaSegmentosComponent } from "./presencia-segmentos.component";

@NgModule({
  declarations: [PresenciaSegmentosComponent],
  imports: [
    SharedModule,
    OptionsFromJsonModule,
    OptionsFromParentModule,
    GroupedBarModule
  ],
  exports: [PresenciaSegmentosComponent],
  providers: []
})
export class PresenciaSegmentosModule {}
