import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { PopulationPyramidComponent } from "./population-pyramid.component";

@NgModule({
  declarations: [PopulationPyramidComponent],
  imports: [SharedModule],
  exports: [PopulationPyramidComponent],
  providers: []
})
export class PopulationPyramidModule {}
