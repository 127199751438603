import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guards/auth.guard";

import { LoginPageComponent } from "./login-page.component";
import { AlertComponent } from "./alert/alert.component";

const routes: Routes = [{ path: "login", component: LoginPageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class LoginRoutingModule {
  static components = [LoginPageComponent,AlertComponent];
}
