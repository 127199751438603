import { Injectable } from "@angular/core";
import { D3MainService } from "./d3-main.service";
import * as d3 from "d3";

@Injectable({
  providedIn: "root"
})
export class SelectWidgetService {
  // Dinamic select OPTIONS from data params
  // nesting columns

  public getOptionsFromData = (select: any, d: any) => {
    let id: string = d.id;
    let rawData: any;
    const priority_months: Array<string> = this.d3MainService.es_ES.months;

    // filter from selectors
    switch (id) {
      case "presence_segmentation":
      case "presence_residence_selectors":
      case "presence_residence_spain":
        rawData = d.presence;
        //selectors.forEach(select => {
        switch (select.id) {
          case "mode":
            select["options"] = d3
              .nest()
              .key(d => {
                //return d.pre;
                return d.mode;
              })
              .key(d => {
                return d.area;
              })
              .entries(rawData);
            select["options"][0]["selected"] = true;
            select["options"][0]["values"][0]["selected"] = true;
            break;

          case "months":
            select["options"] = d3
              .nest()
              .key(d => {
                return d.month;
              })
              .sortKeys(function(a, b) {
                return priority_months.indexOf(a) - priority_months.indexOf(b);
              })
              .key(d => {
                return d.date;
              })
              .sortKeys((a, b) => {
                return +a - +b;
              })
              .entries(rawData);
            select["options"][0]["selected"] = true;
            break;

          default:
            select["options"] = null;
        }
        //return select;
        //});
        break;

      case "traffic_segmentation":
      case "traffic_residence_selectors":
        rawData = d.traffic;
        switch (select.id) {
          case "trip_distance":
            select["options"] = d3
              .nest()
              .key(d => {
                return d[select.id];
              })
              .sortKeys(d3.ascending)
              .key(d => {
                return d.origin_distance;
              })
              .sortKeys(d3.ascending)
              .key(d => {
                return d.destination_distance;
              })
              .sortKeys(d3.ascending)
              .entries(rawData);

            select["options"].unshift({ key: "Todas", values: [] });
            select["options"][0]["selected"] = true;
            //select["options"][0]["values"][0]["selected"] = true;
            //select["options"][0]["values"][0]["values"][0]["selected"] = true;

            break;

          case "months":
            select["options"] = d3
              .nest()
              .key(d => {
                return d.month;
              })
              .sortKeys(function(a, b) {
                return priority_months.indexOf(a) - priority_months.indexOf(b);
              })
              .key(d => {
                return d.date;
              })
              .sortKeys((a, b) => {
                return +a - +b;
              })
              .entries(rawData);
            select["options"][0]["selected"] = true;
            break;
        }

        break;

      default:
        select["options"] = [{ key: "default", value: null }];
    }
    //return selectors;
    return select;
  };
  constructor(private d3MainService: D3MainService) {}
  ngOnChanges(changes: any) {}
}
