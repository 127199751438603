import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";

import { AddDialogMcdBoxComponent } from "./add-dialog-mcd-box/add-dialog-mcd-box.component";

export interface UsersData {
  id: number;
  name: string;
  address: string;
  kind_building: number;
  view_kind_building: number;
}

@Component({
  selector: "app-geo-add-mcd-table",
  templateUrl: "./geo-add-mcd-table.component.html",
  styleUrls: ["./geo-add-mcd-table.component.scss"]
})
export class GeoAddMcdTableComponent implements OnInit {
  displayedColumns: string[] = ["name", "address", "kind_building", "action"];
  dataSource: any[] = [];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Output() mcDonaldsAdded: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDialogMcdBoxComponent, {
      //width: "750px",
      hasBackdrop: true,
      role: "dialog",
      autoFocus: true,
      //position: { top: "40px" },
      //disableClose: true,
      //maxHeight: "660px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Añadir") {
        this.addRowData(result.data);
      } else if (result.event == "Editar") {
        this.updateRowData(result.data);
      } else if (result.event == "Eliminar") {
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(row_obj) {
    var d = new Date();
    
        
    switch (row_obj.kind_building) {
      case "instore":
        row_obj.kind_building = "instore";
        row_obj.view_kind_building = "Instore";
        break;
      case "mall":
        row_obj.kind_building = "mall";
        row_obj.view_kind_building = "Mall";
        break;
      case "mallnuevodesarrollocomercial":
        row_obj.kind_building = "mallnuevodesarrollocomercial";
        row_obj.view_kind_building = "Nuevo desarrollo comercial";
        break;
      case "freestanding":
        row_obj.kind_building = "freestanding";
        row_obj.view_kind_building = "Free Standing";
        break;
      default:
        row_obj.kind_building = "instore";
        row_obj.view_kind_building = "Instore";        
        break;
    }
    this.dataSource.push({
      id: d.getTime(),
      name: row_obj.name,
      address: row_obj.addressCtrl,
      kind_building: row_obj.kind_building,
      view_kind_building: row_obj.view_kind_building,
      address_view: row_obj.addressCtrl,
      address_all: row_obj.addressTotal,
      city: row_obj.city,
      province: row_obj.province,
      lat: row_obj.lat,
      long: row_obj.long,
      mccafeCtrl: true, //row_obj.mccafeCtrl,
      mckioskoCtrl: row_obj.mckioskoCtrl,
      mcexpressCtrl: row_obj.mcexpressCtrl,
      mcautoCtrl: row_obj.mcautoCtrl,
      mcdeliveryCtrl: row_obj.mcdeliveryCtrl,
      nuevodesarrollocomercial: row_obj.nuevodesarrollocomercial,

      playCtrl: row_obj.playCtrl,
      loungeSeatsCtrl: row_obj.loungeSeatsCtrl,
      outdoorSeatsCtrl: row_obj.outdoorSeatsCtrl,
      indoorSeatsCtrl: row_obj.indoorSeatsCtrl,
      independentEntanceCtrl: row_obj.independentEntanceCtrl,
      totalParkingCtrl: row_obj.totalParkingCtrl,
      totalOwnParkingCtrl: row_obj.totalOwnParkingCtrl,
      accessibilityCtrl: row_obj.accessibilityCtrl,
      visibilityCtrl: row_obj.visibilityCtrl,
      sales_estimation: row_obj.sales_estimation

    });

    this.mcDonaldsAdded.emit(this.dataSource);

    this.table.renderRows();
  }
  updateRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      if (value.id == row_obj.id) {
        value.name = row_obj.name;
        value.address = row_obj.addressCtrl;
        value.address_view = row_obj.addressCtrl;
        value.address_all = row_obj.addressTotal;
        value.city = row_obj.city;
        value.province = row_obj.province;
        value.lat = row_obj.lat;
        value.long = row_obj.long;
        value.mccafeCtrl = true; //row_obj.mccafeCtrl;
        value.mckioskoCtrl = row_obj.mckioskoCtrl;
        value.mcexpressCtrl = row_obj.mcexpressCtrl;
        value.mcautoCtrl = row_obj.mcautoCtrl;
        value.mcdeliveryCtrl = row_obj.mcdeliveryCtrl;
        value.nuevodesarrollocomercial = row_obj.nuevodesarrollocomercial;

        value.playCtrl = row_obj.playCtrl;
        value.loungeSeatsCtrl = row_obj.loungeSeatsCtrl;
        value.outdoorSeatsCtrl = row_obj.outdoorSeatsCtrl;
        value.indoorSeatsCtrl = row_obj.indoorSeatsCtrl;
        value.independentEntanceCtrl = row_obj.independentEntanceCtrl;
        value.totalParkingCtrl = row_obj.totalParkingCtrl;
        value.totalOwnParkingCtrl = row_obj.totalOwnParkingCtrl;
        value.accessibilityCtrl = row_obj.accessibilityCtrl;
        value.visibilityCtrl = row_obj.visibilityCtrl;
        value.sales_estimation = row_obj.sales_estimation;

        switch (row_obj.kind_building) {
          case "instore":
            value.kind_building = "instore";
            value.view_kind_building = "Instore";
            break;
          case "mall":
            value.kind_building = "mall";
            value.view_kind_building = "Mall";
            break;
          case "mallnuevodesarrollocomercial":
            value.kind_building = "mallnuevodesarrollocomercial";
            value.view_kind_building = "Nuevo desarrollo comercial";
            break;
          case "freestanding":
            value.kind_building = "freestanding";
            value.view_kind_building = "Free Standing";
            break;
          default:
            value.kind_building = "instore";
            value.view_kind_building = "Instore";        
            break;
        }

       
        
      }
      return true;
    });
    this.mcDonaldsAdded.emit(this.dataSource);
  }
  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != row_obj.id;
    });
    this.mcDonaldsAdded.emit(this.dataSource);
  }

  ngOnInit() {}
}
 