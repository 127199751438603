import { Component } from "@angular/core";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from "@angular/router";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "mcdonalds-app";
  brand_logo = "../assets/img/brand/mcd.svg";
  navigationHidden: boolean = false;
  footerHidden: boolean = false;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (environment.production) {
        if (location.protocol === "http:") {
          window.location.href = location.href.replace("http", "https");
        }
        if (location.href.indexOf("www.") > -1) {
          window.location.href = location.href.replace("www.", "");
        }
      }

      if (event instanceof NavigationStart) {
        //console.log(event, event.url);
        if (event.url === "/login" || event.url === "/" || event.url === "" || event.url.includes("/brick-report") ) {
          this.navigationHidden = true;
          if (event.url.includes("/brick-report")) {
            this.footerHidden = true;
          }
        } else {
          this.navigationHidden = false;
          this.footerHidden = false;
        }

        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  ngOnInit() {
    this.navigationHidden = false;

  }
}
