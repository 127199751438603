import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";

import { MassiveBrickStudiesRoutingModule } from "./massive-brick-studies-routing.module";
import { MassiveBrickStudiesTableModule } from "./massive-brick-studies-table/massive-brick-studies-table.module";

@NgModule({
  declarations: [MassiveBrickStudiesRoutingModule.components],
  imports: [SharedModule, MassiveBrickStudiesRoutingModule, MassiveBrickStudiesTableModule],
  exports: [],
  providers: []
})
export class MassiveBrickStudiesModule {}
