import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";
import { GeoNewFormRoutingModule } from "./geo-new-form-routing.module";
import { McdGeoLocatorMapModule } from "../../app-widgets/cartography/mcdGeoLocatorMap/mcdGeoLocatorMap.module";
import { GeoAddCompetitorsTableModule } from "./geo-add-competitors-table/geo-add-competitors-table.module";
import { GeoRemoveCompetitorsTableModule } from "./geo-remove-competitors-table/geo-remove-competitors-table.module";

import { GeoAddMcdTableModule } from "./geo-add-mcd-table/geo-add-mcd-table.module";

import { GeoAddCommercesTableModule } from "./geo-add-commerces-table/geo-add-commerces-table.module";
import { GeoRemoveCommercesTableModule } from "./geo-remove-commerces-table/geo-remove-commerces-table.module";
import { GeoEditCommercialCenterTableModule } from "./geo-edit-commercial-center-table/geo-edit-commercial-center-table.module";
import { AddDialogCommercialCenterBoxComponent } from "./geo-add-commercial-center-table/add-dialog-commercial-center-box/add-dialog-commercial-center-box.component";
import { GeoAddCommercialCenterTableComponent } from "./geo-add-commercial-center-table/geo-add-commercial-center-table.component";
import { EditDialogCommercialCenterBoxComponent } from "./geo-edit-commercial-center-table/edit-dialog-commercial-center-box/edit-dialog-commercial-center-box.component";
import { AppGeoRemoveCompetitorsOthersTableModule } from "./geo-remove-competitors-others-table/app-geo-remove-competitors-others-table/app-geo-remove-competitors-others-table.module";
import { GeoRemoveMcdTableModule } from "./geo-remove-mcd-table/geo-remove-mcd-table.module";
@NgModule({
    declarations: [
        GeoNewFormRoutingModule.components,
        GeoAddCommercialCenterTableComponent,
        AddDialogCommercialCenterBoxComponent,
        EditDialogCommercialCenterBoxComponent,
    ],
    exports: [
        GeoNewFormRoutingModule.components,
        GeoAddCommercialCenterTableComponent
    ],
    providers: [],
    imports: [
        SharedModule,
        GeoNewFormRoutingModule,
        McdGeoLocatorMapModule,
        GeoAddCompetitorsTableModule,
        GeoRemoveCompetitorsTableModule,
        GeoAddCommercesTableModule,
        GeoRemoveCommercesTableModule,
        GeoEditCommercialCenterTableModule,
        AppGeoRemoveCompetitorsOthersTableModule,
        GeoAddMcdTableModule,
        GeoRemoveMcdTableModule
    ]
})
export class GeoNewFormModule {}
