import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { McdGeoMakerComponent } from "./mcdGeoMaker.component";
//import { MarkerClusterGroup } from "leaflet.markercluster/src";

@NgModule({
  declarations: [McdGeoMakerComponent],
  imports: [SharedModule],
  exports: [McdGeoMakerComponent],
  providers: [
    /*MarkerClusterGroup*/
  ]
})
export class McdGeoMakerModule {}
