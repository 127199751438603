<app-progress-bar [loading]="traffic_loading"></app-progress-bar>
<app-trafico-residentes
  [traffic_residence_data]="traffic_residence_data"
  *ngIf="traffic_residence_data_ready"
>
</app-trafico-residentes>

<app-trafico-segmentos
  [traffic_segmentation_data]="traffic_segmentation_data"
  *ngIf="traffic_segmentation_data_ready"
></app-trafico-segmentos>
