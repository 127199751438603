import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { DataService } from "src/app/app-services/data.service";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";

@Component({
  selector: "app-study-sales",
  templateUrl: "./study-sales.component.html",
  styleUrls: ["./study-sales.component.scss"]
})
export class StudySalesComponent implements OnInit, OnDestroy {
  @Input() studyData: any;
  ventas: any;
  ventas_first_year_sales_medium: any = 0;
  channel_decomposition: Array<any>;
  ready: boolean = false;
  unsubscribe: any;
  unsubscribe2: any;

  constructor(
    private geoStudiesDataService: GeoStudiesDataService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.unsubscribe = this.dataService.studySelectedPredictiveChanged$.subscribe(
      id => {
        if (id !== undefined) {
          this.unsubscribe2 = this.geoStudiesDataService
            .getBasicInfoStudy(id)
            .subscribe(d => {
              this.studyData = d;

              //ñapa hasta que JL pase los datos ventas:
              if (!this.studyData.properties.hasOwnProperty("data_model")) {
                this.studyData.properties["data_model"] = {};
                this.studyData.properties["data_model"]["sales_info"] = {};
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ] = {};
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ]["Delivery"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ]["Kiosco"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ]["Mostrador"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ]["Mostrador_Kiosco"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ]["McCafe"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "channel_decomposition"
                ]["McAuto"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "perc_of_sales_associated_to_existing_rest"
                ] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "first_year_sales"
                ] = {};
                this.studyData.properties["data_model"]["sales_info"][
                  "first_year_sales"
                ]["medium"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "first_year_sales"
                ]["lower"] = 0;
                this.studyData.properties["data_model"]["sales_info"][
                  "first_year_sales"
                ]["upper"] = 0;
                // this.studyData.properties["data_model"]["sales_info"][
                //   "first_year_sales"
                // ] = 0;

                this.studyData.properties["impact_info"] = {};
              }
              this.ventas = this.studyData.properties.data_model.sales_info;
              if (
                this.studyData.properties.data_model.sales_info.first_year_sales
              ) {
                this.ventas_first_year_sales_medium = this.studyData.properties.data_model.sales_info.first_year_sales.medium;
              }
              if (this.ventas.channel_decomposition.Mostrador_Kiosco) {
                this.channel_decomposition = [
                  // {
                  //   name: "Mostrador_Kiosco",
                  //   value: this.ventas.channel_decomposition.Mostrador_Kiosco,
                  //   icon: "tablet_android"
                  // },
                  // {
                  //   name: "McAuto",
                  //   value: this.ventas.channel_decomposition.McAuto,
                  //   icon: "directions_car"
                  // },

                  {
                    name: "Mostrador_Kiosco_McAuto_McCafe",
                    value:
                      this.ventas.channel_decomposition.Mostrador_Kiosco +
                      this.ventas.channel_decomposition.McAuto + this.ventas.channel_decomposition.McCafe,
                    icon: "tablet_android"
                  },
                  /*{
                    name: "McCafe",
                    value: this.ventas.channel_decomposition.McCafe,
                    icon: "local_cafe"
                  },
                  */
                  {
                    name: "Delivery",
                    value: this.ventas.channel_decomposition.Delivery,
                    icon: "local_mall"
                  }
                ];
              } else {
                this.channel_decomposition = [
                  {
                    name: "Mostrador",
                    value: this.ventas.channel_decomposition.Mostrador,
                    icon: "fastfood"
                  },
                  {
                    name: "McAuto",
                    value: this.ventas.channel_decomposition.McAuto,
                    icon: "directions_car"
                  },
                  {
                    name: "Kiosco",
                    value: this.ventas.channel_decomposition.Kiosco,
                    icon: "tablet_android"
                  },
                  /*{
                    name: "McCafe",
                    value: this.ventas.channel_decomposition.McCafe,
                    icon: "local_cafe"
                  },
                  */
                  {
                    name: "Delivery",
                    value: this.ventas.channel_decomposition.Delivery,
                    icon: "local_mall"
                  }
                ];
              }

              this.ready = true;
            });
        }
      }
    );

    //ñapa hasta que JL pase los datos ventas:
    if (!this.studyData.properties.hasOwnProperty("data_model")) {
      this.studyData.properties["data_model"] = {};
      this.studyData.properties["data_model"]["sales_info"] = {};
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ] = {};
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Delivery"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Kiosco"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Mostrador_Kiosco"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Mostrador"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["McCafe"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["McAuto"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "perc_of_sales_associated_to_existing_rest"
      ] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "first_year_sales"
      ] = {};
      this.studyData.properties["data_model"]["sales_info"]["first_year_sales"][
        "medium"
      ] = 0;
      this.studyData.properties["data_model"]["sales_info"]["first_year_sales"][
        "lower"
      ] = 0;
      this.studyData.properties["data_model"]["sales_info"]["first_year_sales"][
        "upper"
      ] = 0;
      // this.studyData.properties["data_model"]["sales_info"][
      //   "first_year_sales"
      // ] = 0;

      this.studyData.properties["impact_info"] = {};
    } else if (
      !this.studyData.properties.data_model.hasOwnProperty("sales_info")
    ) {
      this.studyData.properties["data_model"]["sales_info"] = {};
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ] = {};
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Delivery"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Kiosco"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Mostrador_Kiosco"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["Mostrador"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["McCafe"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "channel_decomposition"
      ]["McAuto"] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "perc_of_sales_associated_to_existing_rest"
      ] = 0;
      this.studyData.properties["data_model"]["sales_info"][
        "first_year_sales"
      ] = {};
      this.studyData.properties["data_model"]["sales_info"]["first_year_sales"][
        "medium"
      ] = 0;
      this.studyData.properties["data_model"]["sales_info"]["first_year_sales"][
        "lower"
      ] = 0;
      this.studyData.properties["data_model"]["sales_info"]["first_year_sales"][
        "upper"
      ] = 0;
      // this.studyData.properties["data_model"]["sales_info"][
      //   "first_year_sales"
      // ] = 0;

      this.studyData.properties["impact_info"] = {};
    }
    this.ventas = this.studyData.properties.data_model.sales_info;
    if (this.studyData.properties.data_model.sales_info.first_year_sales) {
      this.ventas_first_year_sales_medium = this.studyData.properties.data_model.sales_info.first_year_sales.medium;
    }
    if (this.ventas.channel_decomposition.Mostrador_Kiosco) {
      this.channel_decomposition = [
        // {
        //   name: "Mostrador_Kiosco",
        //   value: this.ventas.channel_decomposition.Mostrador_Kiosco,
        //   icon: "tablet_android"
        // },
        // {
        //   name: "McAuto",
        //   value: this.ventas.channel_decomposition.McAuto,
        //   icon: "directions_car"
        // },
        {
          name: "Mostrador_Kiosco_McAuto_McCafe",
          value:
            this.ventas.channel_decomposition.Mostrador_Kiosco +
            this.ventas.channel_decomposition.McAuto + this.ventas.channel_decomposition.McCafe,
          icon: "tablet_android"
        },
       /* {
          name: "McCafe",
          value: this.ventas.channel_decomposition.McCafe,
          icon: "local_cafe"
        },
        */
        {
          name: "Delivery",
          value: this.ventas.channel_decomposition.Delivery,
          icon: "local_mall"
        }
      ];
    } else {
      this.channel_decomposition = [
        {
          name: "Mostrador",
          value: this.ventas.channel_decomposition.Mostrador,
          icon: "fastfood"
        },
        {
          name: "McAuto",
          value: this.ventas.channel_decomposition.McAuto,
          icon: "directions_car"
        },
        {
          name: "Kiosco",
          value: this.ventas.channel_decomposition.Kiosco,
          icon: "tablet_android"
        },
        /*{
          name: "McCafe",
          value: this.ventas.channel_decomposition.McCafe,
          icon: "local_cafe"
        },
        */
        {
          name: "Delivery",
          value: this.ventas.channel_decomposition.Delivery,
          icon: "local_mall"
        }
      ];
    }

    this.ready = true;
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
