import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: "root"
})
export class DataService {
  selectedRestaurant: any;
  selectedStudy: any;
  selectedStudyPresc: any = "";
  selectedStudyPrescMassive: any = "";
  brandsByCoords: any;
  coordsMap: any;
  brandFilter: any;
  disabledCompetitor: boolean = true;
  commmercesAll: any;
  restaurantInfluence: any;

  private headers: HttpHeaders = new HttpHeaders();

  private sideNavsSubject$ = new Subject<any>();
  sideNavsStateChanged$ = this.sideNavsSubject$.asObservable();

  private searchboxDescriptive$ = new Subject<any>();
  searchboxDescriptiveChanged$ = this.searchboxDescriptive$.asObservable();

  private restaurantCheckDescriptive$ = new Subject<any>();
  restaurantCheckDescriptiveChanged$ = this.restaurantCheckDescriptive$.asObservable();

  private restaurantClosedCheckDescriptive$ = new Subject<any>();
  restaurantClosedCheckDescriptiveChanged$ = this.restaurantClosedCheckDescriptive$.asObservable();

  private restaurantPlan5CheckDescriptive$ = new Subject<any>();
  restaurantPlan5CheckDescriptiveChanged$ = this.restaurantPlan5CheckDescriptive$.asObservable();

  private restaurantProxApertCheckDescriptive$ = new Subject<any>();
  restaurantProxApertCheckDescriptiveChanged$ = this.restaurantProxApertCheckDescriptive$.asObservable();

  private deliveryCheckDescriptive$ = new Subject<any>();
  deliveryCheckDescriptiveChanged$ = this.deliveryCheckDescriptive$.asObservable();

  private influenceCheckDescriptive$ = new Subject<any>();
  influenceCheckDescriptiveChanged$ = this.influenceCheckDescriptive$.asObservable();

  private influenceRestaurantCheckDescriptive$ = new Subject<any>();
  influenceRestaurantCheckDescriptiveChanged$ = this.influenceRestaurantCheckDescriptive$.asObservable();

  private restaurantSelectedInfluenceDescriptive$ = new Subject<any>();
  restaurantSelectedInfluenceDescriptiveChanged$ = this.restaurantSelectedInfluenceDescriptive$.asObservable();

  private restaurantFilteredDescriptive$ = new Subject<any>();
  restaurantFilteredDescriptiveChanged$ = this.restaurantFilteredDescriptive$.asObservable();

  private restaurantZoomDescriptive$ = new Subject<any>();
  restaurantZoomDescriptiveChanged$ = this.restaurantZoomDescriptive$.asObservable();

  private competitorZoomDescriptive$ = new Subject<any>();
  competitorZoomDescriptiveChanged$ = this.competitorZoomDescriptive$.asObservable();

  private restaurantSelectedDescriptive$ = new Subject<any>();
  restaurantSelectedDescriptiveChanged$ = this.restaurantSelectedDescriptive$.asObservable();

  private studySelectedPredictive$ = new Subject<any>();
  studySelectedPredictiveChanged$ = this.studySelectedPredictive$.asObservable();

  private studySelectedPrescriptive$ = new Subject<any>();
  studySelectedPrescriptiveChanged$ = this.studySelectedPrescriptive$.asObservable();


  private studySelectedPrescriptiveMassive$ = new Subject<any>();
  studySelectedPrescriptiveMassiveChanged$ = this.studySelectedPrescriptiveMassive$.asObservable();



  private bricksStudyPrescriptive$ = new Subject<any>();
  bricksStudyPrescriptiveChanged$ = this.bricksStudyPrescriptive$.asObservable();

  private filterBricksPrescriptive$ = new Subject<any>();
  filterBricksPrescriptiveChanged$ = this.filterBricksPrescriptive$.asObservable();

  private competitorFilteredDescriptive$ = new Subject<any>();
  competitorFilteredDescriptiveChanged$ = this.competitorFilteredDescriptive$.asObservable();

  private competitorOthersFilteredDescriptive$ = new Subject<any>();
  competitorOthersFilteredDescriptiveChanged$ = this.competitorOthersFilteredDescriptive$.asObservable();

  private competitorZoomDisabledDescriptive$ = new Subject<any>();
  competitorZoomDisabledDescriptiveChanged$ = this.competitorZoomDisabledDescriptive$.asObservable();

  private brickDescriptive$ = new Subject<any>();
  brickDescriptiveChanged$ = this.brickDescriptive$.asObservable();

  private commercesFilteredDescriptive$ = new Subject<any>();
  commercesFilteredDescriptiveChanged$ = this.commercesFilteredDescriptive$.asObservable();

  private mallsFilteredDescriptive$ = new Subject<any>();
  mallsFilteredDescriptiveChanged$ = this.mallsFilteredDescriptive$.asObservable();

  private studyPredictiveCheck$ = new Subject<any>();
  studyPredictiveCheckChanged$ = this.studyPredictiveCheck$.asObservable();

  private studyPredictiveFilteredDescriptive$ = new Subject<any>();
  studyPredictiveFilteredDescriptiveChanged$ = this.studyPredictiveFilteredDescriptive$.asObservable();

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  getMapBoxUrl = () => {
    return [
      {
        key: "main",
        value:
         // "https://api.mapbox.com/styles/v1/abacus-consulting/cjxem7nyt09cj1cmkiz7v371c/tiles/256/{z}/{x}/{y}?access_token="
          "https://api.mapbox.com/styles/v1/adegracia/clzi9zxzt00hm01r3bvlzbiwc/tiles/256/{z}/{x}/{y}?access_token="
      }
    ];
  };

  getMapBoxToken = () => {
    return [
      {
        key: "main",
        value:
          this.getMapBoxUrl()[0].value +
          //"pk.eyJ1IjoiYWJhY3VzLWNvbnN1bHRpbmciLCJhIjoiY2p4ZW00Ync1MGN4ejN6czAybGF3cXowYiJ9.Gy0d0IYnSxyJCjuPp8GRUw"
          "pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg"
      }
    ];
  };

  checkIfRestaurantServiceExists = (str: any) => {
    let exists: boolean =
      str === undefined ||
        str === "NO" ||
        str === "No" ||
        0 === str.length ||
        str === "0" ||
        str === 0 ||
        str == null ||
        !str ||
        str === false
        ? false
        : true;

    return exists;
  };

  getStaticRestaurantData(): Observable<any> {
    return this.http.get("assets/data/restaurant.json", {
      responseType: "json"
    });
  }
  getStaticPresenceData(): Observable<any> {
    return this.http.get("assets/data/presence_100.json", {
      responseType: "json"
    });
  }

  getStaticTrafficData(): Observable<any> {
    return this.http.get("assets/data/transit_100.json", {
      responseType: "json"
    });
  }

  serviceToggleSideNavs(sidenavStates: any) {
    this.sideNavsSubject$.next(sidenavStates);
  }

  getMarkersGeoJson(
    lngmin: any,
    latmin: any,
    lngmax: any,
    latmax: any
  ): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/places/benchmark/" +
      lngmin +
      "," +
      latmin +
      ";" +
      lngmax +
      "," +
      latmax +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getMarkersbyBrandsGeoJson(
    brands: any,
    lngmin: any,
    latmin: any,
    lngmax: any,
    latmax: any
  ): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/places_by_brands/" +
      brands +
      "/" +
      lngmin +
      "," +
      latmin +
      ";" +
      lngmax +
      "," +
      latmax +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getPlacesMcdGeoJson(): Observable<any> {
    return this.http.get("assets/data/placesMcd.json", {
      responseType: "json"
    });
  }

  getPlacesIdMcd(idPlace: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/place/" +
      idPlace +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getPresenceIdMcd(idPlace: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/presence/" +
      idPlace +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getTransitIdMcd(idPlace: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/transit/" +
      idPlace +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getPlacesId(idPlace: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/place/" +
      idPlace +
      "/",
      {
        responseType: "json"
      }
    );
  }

  searchboxDescriptive(searchBoxData: any) {
    this.searchboxDescriptive$.next(searchBoxData);
  }

  showAllRestaurants(isChecked: boolean) {
    this.restaurantCheckDescriptive$.next(isChecked);
  }
  showClosedRestaurants(isChecked: boolean) {
    this.restaurantClosedCheckDescriptive$.next(isChecked);
  }

  showProxApertRestaurants(isChecked: boolean) {
    this.restaurantProxApertCheckDescriptive$.next(isChecked);
  }

  showPlan5Restaurants(isChecked: boolean) {
    this.restaurantPlan5CheckDescriptive$.next(isChecked);
  }

  showDeliveryRestaurant(isChecked: boolean) {
    this.deliveryCheckDescriptive$.next(isChecked);
  }

  showInfluence(isChecked: boolean) {
    this.influenceCheckDescriptive$.next(isChecked);
  }

  showInfluenceRestaurant(isChecked: boolean, idMcd: any) {
    this.influenceRestaurantCheckDescriptive$.next(isChecked);
  }

  showBricksStudyPrescriptive(isChecked: boolean, dataBricks: any) {

    this.bricksStudyPrescriptive$.next(dataBricks);
    //this.bricksStudyPrescriptive$.next(isChecked);
  }

  showAllStudiesPredictive(isChecked: boolean) {
    this.studyPredictiveCheck$.next(isChecked);
  }

  filterBricksStudyPrescriptive(data: any) {
    this.filterBricksPrescriptive$.next(data);
  }

  getPlacesMcd(): Observable<any> {
    return this.http.get(
      `${environment.postmanHost}${environment.postmanDelimitator}${environment.postmanPort}${environment.postmanContext}/places/main/`,
      {
        responseType: "json"
      }
    );
  }

  getFilteredRestaurants(object: any): any {
    this.restaurantFilteredDescriptive$.next(object);
  }

  getFilteredPredictiveStudies(object: any): any {
    this.studyPredictiveFilteredDescriptive$.next(object);
  }

  getZoomOnRestaurant(object: any): any {
    this.restaurantZoomDescriptive$.next(object);
  }

  getZoomOnPlace(object: any, layer_name: any): any {
    object.layer = layer_name;
    this.competitorZoomDescriptive$.next(object);
  }

  getBrands(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/brands/",
      {
        responseType: "json"
      }
    );
  }

  getAllCommerce(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/places/commerce/",
      {
        responseType: "json"
      }
    );
  }

  getBrandsCategoriesCommerce(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/brands/categories/",
      {
        responseType: "json"
      }
    );
  }

  getBrandsByCategoriesCommerce(category: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/brands_by_categories/" +
      category +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getMalls(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/places/mall/",
      {
        responseType: "json"
      }
    );
  }

  setBrandsByCoords(object: any): any {
    this.brandsByCoords = object;
  }

  getBrandsByCoordsF(): any {
    return this.brandsByCoords;
  }

  setCoordsMap(lngmin: any, latmin: any, lngmax: any, latmax: any): any {
    this.coordsMap = [lngmin, latmin, lngmax, latmax];
  }

  getCoordsMap(): any {
    return this.coordsMap;
  }

  setDisabledCompetitor(disabled: boolean): any {
    this.disabledCompetitor = disabled;
    this.competitorZoomDisabledDescriptive$.next(this.disabledCompetitor);
  }

  getDisabledCompetitor(): boolean {
    return this.disabledCompetitor;
  }

  getBrandsByCoords(
    lngmin: any,
    latmin: any,
    lngmax: any,
    latmax: any
  ): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/brands/benchmark/" +
      lngmin +
      "," +
      latmin +
      ";" +
      lngmax +
      "," +
      latmax +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getCompetitorsByBrands(brands: any, category: any): Observable<any> {
    if(brands!=""){
      if(category!=""){
        return this.http.get(
          environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/places_by_brands/" +
            brands +
            "/?category=" +
            category + 
            "",
          {
            responseType: "json"
          }
        );
      }else{
        return this.http.get(
          environment.postmanHost +
            environment.postmanDelimitator +
            environment.postmanPort +
            environment.postmanContext +
            "/places_by_brands/" +
            brands +
            "/",
          {
            responseType: "json"
          }
        );
      }
      
    }else{
return this.http.get(
  environment.postmanHost +
    environment.postmanDelimitator +
    environment.postmanPort +
    environment.postmanContext +
    "/places_by_brands/ " +
    "/",
  {
    responseType: "json"
  }
);
    }

  }

  getCompetitorsByBrandsCoords(
    brands: string[],
    lngmin: any,
    latmin: any,
    lngmax: any,
    latmax: any
  ): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/places_by_brands/" +
      brands +
      "/" +
      lngmin +
      "," +
      latmin +
      ";" +
      lngmax +
      "," +
      latmax +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getCompetitorsOthersByBrandsCoords(
    brands: string[],
    lngmin: any,
    latmin: any,
    lngmax: any,
    latmax: any
  ): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/places/benchmark2/" +
      lngmin +
      "," +
      latmin +
      ";" +
      lngmax +
      "," +
      latmax +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getSelectedRestaurant(): any {
    return this.selectedRestaurant;
  }

  setSelectedRestaurant(idPlace: any): any {
    this.selectedRestaurant = idPlace;
    this.restaurantSelectedDescriptive$.next(this.selectedRestaurant);
  }

  getSelectedStudy(): any {
    return this.selectedStudy;
  }

  setSelectedStudy(idStudy: any): any {
    this.selectedStudy = idStudy;
    this.studySelectedPredictive$.next(this.selectedStudy);
  }

  getSelectedStudyPresc(): any {
    return this.selectedStudyPresc;
  }

  setSelectedStudyPresc(idStudy: any): any {
    this.selectedStudyPresc = idStudy;
    this.studySelectedPrescriptive$.next(this.selectedStudyPresc);
  }

  setSelectedStudyPrescMassive(idStudy: any): any {
    this.selectedStudyPrescMassive = idStudy;
    this.studySelectedPrescriptiveMassive$.next(this.selectedStudyPrescMassive);
  }

  getSelectedStudyMassivePresc(): any {
    return this.selectedStudyPrescMassive;
  }



  getFilteredCompetitors(object: any): any {
    this.competitorFilteredDescriptive$.next(object);
  }

  getFilteredCompetitorsOthers(object: any): any {
    this.competitorOthersFilteredDescriptive$.next(object);
  }

  getFilteredCommerces(object: any): any {
    this.commercesFilteredDescriptive$.next(object);
  }

  getFilteredMalls(object: any): any {
    this.mallsFilteredDescriptive$.next(object);
  }

  getBrandFilter(): any {
    return this.brandFilter;
  }

  setBrandFilter(brands: string[]): any {
    this.brandFilter = brands;
  }

  getAllCommmercesShow(): any {
    return this.commmercesAll;
  }

  setAllCommmercesShow(commerces: any): any {
    this.commmercesAll = commerces;
  }

  getSelectedRestaurantInfluence(): any {
    return this.restaurantInfluence;
  }

  setSelectedRestaurantInfluence(influence: any): any {
    this.restaurantInfluence = influence;
    this.restaurantSelectedInfluenceDescriptive$.next(this.restaurantInfluence);
  }



  getEnvironment(lng: any, lat: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/point/" +
      lng +
      "," +
      lat +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getMCDfromID(idPlace: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/place/" +
      idPlace +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getEnvironment2(idPlace: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/place/main/" +
      idPlace +
      "/",
      {
        responseType: "json"
      }
    );
  }

  getBrickById(idBrick: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/brick/" +
      idBrick +
      "/fields=portals2017/",
      {
        responseType: "json"
      }
    );
  }

  getBrickByCoords(lng: any, lat: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/bricks/" +
      lat +
      "," +
      lng +
      "/3000/",
      {
        responseType: "json"
      }
    );
  }

  getBrickPrueba(): Observable<any> {
    return this.http.get("assets/data/prueba.json", {
      responseType: "json"
    });
  }

  getBrickMap(object: any): any {
    this.brickDescriptive$.next(object);
  }

  getFiles(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/files/",
      {
        responseType: "json"
      }
    );
  }

  getNameFolderFiles(): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/files_folder_name/",
      {
        responseType: "json"
      }
    );
  }

  getFolderYears(foldername: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/f_folder_years/" + foldername + "/",
      {
        responseType: "json"
      }
    );
  }

  getFolderFiles(foldername: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/files_folder_by_year/" + foldername + "/",
      {
        responseType: "json"
      }
    );
  }

  getFilesSuffix(filename: any): Observable<any> {
    return this.http.get(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/files/" + filename + "/",
      {
        responseType: "json"
      }
    );
  }

  getDownloadFile(file: any) {
    window.open(
      environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/file/" +
      file
    );
  }

  setHeaders() {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');

    const currentUser = this.authenticationService.currentUserValue;

    const token = `Basic ${currentUser.token}`;
    if (token !== '') {
      const tokenValue = `Basic ${currentUser.token}`;
      this.headers = this.headers.set('Authorization', tokenValue);
    }
  }


  getDownloadFileFromFolder(folder: any, year: any, file: any) {
    /*
    //this comment works but open a popup to enter the user and password 
    window.open(
    environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/file_download_folder/" +folder+"/"+year+"/"+
      file+"/", "_blank"
  );   */

    const currentUser = this.authenticationService.currentUserValue;

    let url = environment.postmanHost +
      environment.postmanDelimitator +
      environment.postmanPort +
      environment.postmanContext +
      "/file_download_folder/" + folder + "/" + year + "/" +
      file + "/";

    this.setHeaders();
    this.http.get(url, {
      headers: this.headers
    }).subscribe(
      (data: any) => {
        console.log(`open DownloadFile for file /${folder}/${year}/${file}`);
        window.open(`${url}${currentUser.token}`);
      },
      error => {
        if (error == "OK") {
          window.open(url)
        } else {
          console.error("An error occurred:", error);
        }
      },
      () => {
        console.log(`complete DownloadFile for file /${folder}/${year}/${file}`);
        window.open(`${url}${currentUser.token}`);
      }
    );

  }

}
