import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabCompetenciaComponent } from "./tab-competencia.component";

@NgModule({
  declarations: [TabCompetenciaComponent],
  imports: [SharedModule],
  exports: [TabCompetenciaComponent],
  providers: []
})
export class TabCompetenciaModule {}
