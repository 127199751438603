<section class="full-width clearfix">
  <form class="tp-full-width" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group">
      <mat-form-field class="tp-full-width">
        <input
          matInput
          type="number"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          pattern="[0-9]+"
          id="brickInput"
          name="brickInput"
          class="form-control"
          required
          minlength="9"
          maxlength="9"
          placeholder="Buscar brick"
          [formControl]="brickFormControl"
        />

        <mat-error
          *ngIf="
            brickFormControl.hasError('pattern') &&
            !brickFormControl.hasError('required')
          "
        >
          Por favor, inserte un número válido de 9 cifras.
        </mat-error>

        <mat-error *ngIf="brickFormControl.hasError('required')">
          Un número de nuevo cifras <strong>es necesario.</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <button
        mat-raised-button
        color="primary"
        class="button--m"
        type="submit"
        [disabled]="brickFormControl.pristine || !brickFormControl.valid"
      >
        Buscar
      </button>
    </div>
  </form>
</section>
