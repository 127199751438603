import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DownloadsPageComponent } from "./downloads-page.component";

const routes: Routes = [
  { path: "downloads", component: DownloadsPageComponent, canActivate: [] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DownloadsPageRoutingModule {
  static components = [DownloadsPageComponent];
}
