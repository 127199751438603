import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TableStudyActionsService } from "../../../../../app-services/table-study-actions.service";
import { DataService } from "../../../../../app-services/data.service";
import { BrickReportService } from "../../../../../brick-report/brick-report.service";

@Component({
  selector: "app-tab-expansion",
  templateUrl: "./tab-expansion.component.html",
  styleUrls: ["./tab-expansion.component.scss"]
})
export class TabExpansionComponent implements OnInit {
  dummy_studyId: number = 3526;
  expansion_range: Array<number> = [0, 100];
  expansion_range_slider: any = {
    id: "expansion_range_slider",
    title: "Euros",
    options: {
      floor: 0,
      ceil: 100,
      step: 5
    },
    minMax: this.expansion_range
  };

  downloadStudy: any;
  selectedOrder: any="";

  @Input() studyData: any;

  constructor(private tableStudyActionsService: TableStudyActionsService,
    private dataService: DataService,
    private brickReportService: BrickReportService) {}

  change_expansion_range(event) {
    this.expansion_range = event;
    this.dataService.filterBricksStudyPrescriptive(this.expansion_range);
  
  }

  ngOnInit() {
    this.downloadStudy = this.tableStudyActionsService.downloadStudy;
    if(this.studyData ){
      for(var i=0;i<this.studyData.properties.studies.length; i++){
        if(i>0){
          this.selectedOrder += " - ";
        }
        this.selectedOrder +=  this.studyData.properties.studies[i].building_type ;
      }
      this.expansion_range = [10, 70];
              this.expansion_range_slider.minMax = [10, 70];
              this.change_expansion_range([10, 70])
    }

  }

  ngAfterViewInit() {
    if(this.studyData ){
      for(var i=0;i<this.studyData.properties.studies.length; i++){
        if(i>0){
          this.selectedOrder += " - ";
        }
        this.selectedOrder +=  this.studyData.properties.studies[i].building_type ;
      }
      this.expansion_range = [10, 70];
              this.expansion_range_slider.minMax = [10, 70];
              this.change_expansion_range([10, 70])
    }
  }

  ngOnChanges(){
    if(this.studyData ){

      
      for(var i=0;i<this.studyData.properties.studies.length; i++){
        if(i>0){
          this.selectedOrder += " - ";
        }
        this.selectedOrder +=  this.studyData.properties.studies[i].building_type ;
      }

      this.expansion_range = [0, 100];
      if(this.studyData.bricks.length >0){
        //order
        
        this.brickReportService.sortBricks(this.studyData);
        this.expansion_range_slider.minMax = [Math.trunc(this.studyData.bricks[this.studyData.bricks.length-1].properties.sales_info.first_year_sales)-10, Math.trunc(this.studyData.bricks[0].properties.sales_info.first_year_sales)+10];
        this.expansion_range_slider.options.floor = Math.trunc(this.studyData.bricks[this.studyData.bricks.length-1].properties.sales_info.first_year_sales)-10;
        this.expansion_range_slider.options.ceil = Math.trunc(this.studyData.bricks[0].properties.sales_info.first_year_sales)+10;
        this.change_expansion_range(this.expansion_range_slider.minMax);
      }else{
        this.expansion_range_slider.minMax = this.expansion_range;
        this.expansion_range_slider.options.floor = this.expansion_range[0];
        this.expansion_range_slider.options.ceil = this.expansion_range[1];
        this.change_expansion_range(this.expansion_range);
      }
      
    }


  }

}
