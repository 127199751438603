import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { TreemapComponent } from "./treemap.component";

@NgModule({
  declarations: [TreemapComponent],
  imports: [SharedModule],
  exports: [TreemapComponent],
  providers: []
})
export class TreemapModule {}
