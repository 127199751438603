<mat-toolbar color="primary">
  <nav class="nav flex-flex">
    <div class="hidde-lt-xs">
      <button mat-icon-button (click)="onToggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="home-logo">
      <a routerLink="/home"></a>
    </div>

    <div class="flex-layout-row hidde-gt-xs">
      <ul class="navigation-items">
        <li class="link primary-link">
          <a routerLink="/descriptive" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">Descriptivo</a>
        </li>
        <li class="link primary-link" routerLinkActive="active">
          <a mat-button [matMenuTriggerFor]="predictiveMenu">Predictivo</a>
          <mat-menu #predictiveMenu="matMenu" yPosition="above">
            <button mat-menu-item routerLink="/predictive/map" (click)="onStudiesMap()">
              Mapa
            </button>
            <button mat-menu-item routerLink="/predictive/studies">
              Estudios
            </button>
            <button mat-menu-item routerLink="/predictive/new-form">
              Nuevo estudio
            </button>
          </mat-menu>
        </li>

        <li class="link primary-link" routerLinkActive="active">
          <a mat-button [matMenuTriggerFor]="massivePrescriptiveMenu">Estudios Masivos</a>
          <mat-menu #massivePrescriptiveMenu="matMenu" yPosition="above">

            <button mat-menu-item routerLink="/massive-prescriptive/studies">
              Estudios
            </button>

            <button *ngIf="userAuthorized" mat-menu-item routerLink="/massive-prescriptive/new-form">
              Nuevo estudio
            </button>
          </mat-menu>
        </li> 

         <li class="link primary-link" routerLinkActive="active">
          <a mat-button [matMenuTriggerFor]="prescriptiveMenu">Prescriptivo</a>
          <mat-menu #prescriptiveMenu="matMenu" yPosition="above">
            
            <button mat-menu-item routerLink="/prescriptive/studies">
              Estudios
            </button>

            <button *ngIf="userAuthorized" mat-menu-item routerLink="/prescriptive/new-form">
              Nuevo estudio
            </button>
          </mat-menu>
        </li> 



        


        <li class="link secondary-link">
          <a routerLink="/downloads" routerLinkActive="active">Descargas</a>
        </li>
        <li class="link secondary-link">
          <a routerLink="/users" routerLinkActive="active" *ngIf="userAuthorized">Usuarios</a>
        </li>
      </ul>
    </div>

    <div class="flex-flex flex-end" style="flex: 1 1 0%; box-sizing: border-box;">
      <button title="Salir de la aplicación" class="mcd-icon mcd-icon--logout" routerLink="/login"></button>
    </div>
  </nav>
</mat-toolbar>