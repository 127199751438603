import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";

import { ParamsLayoutService } from "../../../../app-services/d3/params-layout.service";
import { ParamsDataService } from "../../../../app-services/d3/params-data.service";

@Component({
  selector: "app-trafico-residentes",
  templateUrl: "./trafico-residentes.component.html",
  styleUrls: ["./trafico-residentes.component.scss"]
})
export class TraficoResidentesComponent implements OnInit {
  @Input()
  traffic_residence_data: any;
  //traffic_residence: Array<any>;

  traffic_residence_total: Array<any>;
  traffic_residence_ready: boolean;

  traffic_residence_selectors: Array<any>;
  traffic_selectors_ready: boolean = false;

  traffic_residence_spain_data: Array<any>;
  traffic_residence_spain: Array<any>;
  traffic_residence_abroad_data: Array<any>;
  traffic_residence_abroad: Array<any>;

  selectedDistance: any;
  originOptions: any;
  destinationOptions: any;
  selectedOrigin: any;
  selectedDestination: any;
  selectedPeriod: any;
  selectedMonth: any;
  selectedDay: any = null;
  dayOptions: any;
  displayMonths: boolean = false;
  displayDays: boolean = false;
  displayOrigin: boolean = false;
  displayDestination: boolean = false;

  constructor(
    private paramsLayoutService: ParamsLayoutService,
    private paramsDataService: ParamsDataService
  ) { }

  changeDistance(event) {
    this.selectedDistance = event.key;
    this.originOptions = event.values;

    if (this.originOptions.filter(e => e.key === "Todas").length == 0) {
      this.originOptions.unshift({ key: "Todas", values: [] });
    }

    if (this.selectedDistance != "Todas") {
      this.displayOrigin = true;
      this.displayDestination = false;
    } else {
      this.displayOrigin = false;
      this.displayDestination = false;
    }
  }

  changeOrigin(event) {
    this.selectedOrigin = event;

    this.destinationOptions = this.originOptions.find(
      c => c.key == this.selectedOrigin
    ).values;

    if (this.selectedDistance != "Todas" && this.selectedOrigin == "Todas") {
      this.displayDestination = false;
    } else {
      this.displayDestination = true;
    }
  }
  changeDestination(event) {
    this.selectedDestination = event;
  }

  changePeriod(event) {
    this.selectedPeriod = event;
    if (event === 'annual') {
      this.displayMonths = false;
      this.displayDays = false;
    }
    // change logic here in case weeks and days are availables again
    // changed on may 2023 because days were merged on backEnd
    if (event === "months" || event === "weeks" || event === "days") {
      this.displayMonths = true;
      this.displayDays = false;
    }
  }

  changeMonth(event) {
    this.selectedMonth = event.key;
    this.dayOptions = event.values;
  }

  changeDay(event) {
    this.selectedDay = event;
  }

  ngOnInit() {
    // filter Layout for SELECTORS

    this.traffic_residence_selectors = this.paramsLayoutService.getLayoutParams(
      JSON.parse(JSON.stringify(this.traffic_residence_data)),
      //this.traffic_residence_data,
      "traffic_residence_selectors"
    );

    // we are here
    this.traffic_residence_total = this.paramsLayoutService.getLayoutParams(
      JSON.parse(JSON.stringify(this.traffic_residence_data)),
      //this.traffic_residence_data,
      "traffic_residence_total"
    );

    // Filter data for TREEMAP only SPAIn
    this.traffic_residence_spain_data = this.paramsDataService.getDataParams(
      this.traffic_residence_data,
      "traffic_residence_spain"
    );
    this.traffic_residence_spain = this.paramsLayoutService.getLayoutParams(
      this.traffic_residence_spain_data,
      "traffic_residence_spain"
    );

    // Filter data for TREEMAP only ABROAD data
    this.traffic_residence_abroad_data = this.paramsDataService.getDataParams(
      this.traffic_residence_data,
      "traffic_residence_abroad"
    );
    this.traffic_residence_abroad = this.paramsLayoutService.getLayoutParams(
      this.traffic_residence_abroad_data,
      "traffic_residence_abroad"
    );

    this.traffic_selectors_ready = true;
    this.traffic_residence_ready = true;
  }
}
