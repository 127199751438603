import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "../../../app-services/data.service";
import { ParamsDataService } from "../../../app-services/d3/params-data.service";
import { ParamsLayoutService } from "../../../app-services/d3/params-layout.service";

@Component({
  selector: "app-data-entorno",
  templateUrl: "./data-entorno.component.html",
  styleUrls: ["./data-entorno.component.scss"]
})
export class DataEntornoComponent implements OnInit, OnDestroy {
  population_pyramid_data: any;
  population_pyramid: any;
  housing_typology_data: Array<any>;
  housing_typology: any;
  housing_vs_population_data: Array<any>;
  housing_vs_population: any;
  income_per_household_data: Array<any>;
  income_per_household: any;
  unemployment_rate_data: Array<any>;
  unemployment_rate: any;
  entornoReady: boolean = false;
  unsubscribe: any;
  unsubscribe2: any;
  entorno_loading: boolean = true;
  // emit data
  categorySelectValue: any = "3min";
  constructor(
    private dataService: DataService,
    private paramsDataService: ParamsDataService,
    private paramsLayoutService: ParamsLayoutService
  ) {}

  changeCategorySelectValue(event) {
    this.categorySelectValue = event;
  }

  sortObjKeysAlphabetically(obj) {
    let ordered = {};
 Object.keys(obj).sort().forEach(key =>  {
   ordered[key] = obj[key];
 });
return ordered;
  }


  ngOnInit() {
    let environmetObj = new Object();
    this.unsubscribe = this.dataService
      .getMCDfromID(this.dataService.selectedRestaurant)
      .subscribe(residMCD => {
        this.unsubscribe2 = this.dataService
          .getEnvironment2(residMCD["properties"]["ID_"])
          .subscribe(d_pp => {
            //population_pyramid_2017:
            let population_pyramid_2017: any;
            let country_pp =
              d_pp["properties"]["point_information"]["properties"]["country"][
                "properties"
              ]["population_pyramid"];
            let brick_pp =
              d_pp["properties"]["point_information"]["properties"]["areas"][
                "minutes_car_00_10"
              ]["properties"]["portals2017"]["v_pop_gender"];
            let tot_pob =
              d_pp["properties"]["point_information"]["properties"]["areas"][
                "minutes_car_00_10"
              ]["properties"]["portals2017"]["general"]["tot_pob"];
            Object.keys(brick_pp).map(function(e) {
              return (brick_pp[e] = (brick_pp[e] / tot_pob) * 100);
            });

            population_pyramid_2017 = Object.assign(country_pp, brick_pp);
            //housing_typology:
            let zones =
              d_pp["properties"]["point_information"]["properties"]["areas"];
            //delete zone ratio
            Object.keys(zones).sort().map(function(e) {
              if (!e.includes("minutes")) {
                delete zones[e];
              }
              return zones;
            });

            let housing_typology = [];
            let housing_vs_population = [];
            let income_per_household = [];
            let unemployment_rate = [];
            let country_data =
              d_pp["properties"]["point_information"]["properties"]["country"][
                "properties"
              ]["portals2017"];
            let tot_viv_country =
              country_data["dwellings"]["vivfpt"] +
              country_data["dwellings"]["vivfnps"] +
              country_data["dwellings"]["vivfnpv"] +
              country_data["dwellings"]["vivfnpot"] +
              country_data["dwellings"]["vivcott"];
            let tot_hog_country = // TO DO change on database tot_hog inside 'general', be careful with R scripts
              country_data["number_household_components"]["tot_hog"];
            let province_data =
              d_pp["properties"]["point_information"]["properties"]["province"][
                "properties"
              ]["portals2017"];
            let tot_viv_province =
              province_data["dwellings"]["vivfpt"] +
              province_data["dwellings"]["vivfnps"] +
              province_data["dwellings"]["vivfnpv"] +
              province_data["dwellings"]["vivfnpot"] +
              province_data["dwellings"]["vivcott"];
            let tot_hog_province = // TO DO change on database tot_hog inside 'general', be careful with R scripts
              province_data["number_household_components"]["tot_hog"];
            let municipality_data =
              d_pp["properties"]["point_information"]["properties"][
                "municipality"
              ]["properties"]["portals2017"];
            let tot_viv_municipality =
              municipality_data["dwellings"]["vivfpt"] +
              municipality_data["dwellings"]["vivfnps"] +
              municipality_data["dwellings"]["vivfnpv"] +
              municipality_data["dwellings"]["vivfnpot"] +
              municipality_data["dwellings"]["vivcott"];
            let tot_hog_municipality = // TO DO change on database tot_hog inside 'general', be careful with R scripts
              municipality_data["number_household_components"]["tot_hog"];
            Object.keys(zones).map(function(e) {
              if (!e.includes("car")) {
                delete zones[e];
              }
              return zones;
            });

            zones = this.sortObjKeysAlphabetically(zones)
            for (let i = 0; i < Object.keys(zones).sort().length; i++) {
              let zone = zones[Object.keys(zones)[i]];
              let dwellings = new Object();
              let dwelling = new Object();
              let obj_HP = new Object();
              let obj_IH = new Object();
              let obj_UR = new Object();
              let key: any;
              if (Object.keys(zones)[i].includes("00_03")) {
                key = "3min";
              } else if (Object.keys(zones)[i].includes("00_05")) {
                key = "5min";
              } else if (Object.keys(zones)[i].includes("00_10")) {
                key = "10min";
              } else {
                key = "15min";
              }

              if (
                typeof zone["properties"]["portals2017"]["dwellings"] !==
                "undefined"
              ) {
                dwellings = zone["properties"]["portals2017"]["dwellings"];
                let tot_viv =
                  dwellings["vivfpt"] +
                  dwellings["vivfnps"] +
                  dwellings["vivfnpv"] +
                  dwellings["vivfnpot"] +
                  dwellings["vivcott"];
                let tot_hog = // TO DO change on database tot_hog inside 'general', be careful with R scripts
                  zone["properties"]["portals2017"][
                    "number_household_components"
                  ]["tot_hog"];

                //build the object as chart needs
                dwelling["key"] = key;
                dwelling["name"] = Object.keys(zones)[i];
                dwelling["id"] = i - 1;
                dwelling["data"] = [
                  {
                    category: "Principal",
                    zone: (dwellings["vivfpt"] / tot_viv) * 100,
                    municipio:
                      (municipality_data["dwellings"]["vivfpt"] /
                        tot_viv_municipality) *
                      100,
                    provincia:
                      (province_data["dwellings"]["vivfpt"] /
                        tot_viv_province) *
                      100,
                    pais:
                      (country_data["dwellings"]["vivfpt"] / tot_viv_country) *
                      100
                  },
                  {
                    category: "Secundaria",
                    zone: (dwellings["vivfnps"] / tot_viv) * 100,
                    municipio:
                      (municipality_data["dwellings"]["vivfnps"] /
                        tot_viv_municipality) *
                      100,
                    provincia:
                      (province_data["dwellings"]["vivfnps"] /
                        tot_viv_province) *
                      100,
                    pais:
                      (country_data["dwellings"]["vivfnps"] / tot_viv_country) *
                      100
                  },
                  {
                    category: "Vacía",
                    zone: (dwellings["vivfnpv"] / tot_viv) * 100,
                    municipio:
                      (municipality_data["dwellings"]["vivfnpv"] /
                        tot_viv_municipality) *
                      100,
                    provincia:
                      (province_data["dwellings"]["vivfnpv"] /
                        tot_viv_province) *
                      100,
                    pais:
                      (country_data["dwellings"]["vivfnpv"] / tot_viv_country) *
                      100
                  },
                  {
                    category: "Otra",
                    zone: (dwellings["vivfnpot"] / tot_viv) * 100,
                    municipio:
                      (municipality_data["dwellings"]["vivfnpot"] /
                        tot_viv_municipality) *
                      100,
                    provincia:
                      (province_data["dwellings"]["vivfnpot"] /
                        tot_viv_province) *
                      100,
                    pais:
                      (country_data["dwellings"]["vivfnpot"] /
                        tot_viv_country) *
                      100
                  },
                  {
                    category: "Colectiva",
                    zone: (dwellings["vivcott"] / tot_viv) * 100,
                    municipio:
                      (municipality_data["dwellings"]["vivcott"] /
                        tot_viv_municipality) *
                      100,
                    provincia:
                      (province_data["dwellings"]["vivcott"] /
                        tot_viv_province) *
                      100,
                    pais:
                      (country_data["dwellings"]["vivcott"] / tot_viv_country) *
                      100
                  }
                ];
                housing_typology.push(dwelling);

                //housing_vs_population:
                obj_HP["key"] = key;
                obj_HP["name"] = Object.keys(zones)[i];
                obj_HP["id"] = i - 1;
                obj_HP["data"] = {
                  housing: tot_hog,
                  population:
                    zone["properties"]["portals2017"]["general"]["tot_pob"]
                };
                housing_vs_population.push(obj_HP);
                //income_per_household:
                //TO DO  renta_sscc in database on each brick. Just done in 'point'
                obj_IH = {
                  value:
                    (zone["properties"]["portals2017"]["general"][
                      "renta_sscc"
                    ] *
                      tot_hog) /
                    tot_hog,

                  name: key
                };
                income_per_household.push(obj_IH);

                obj_UR = {
                  value:
                    (zone["properties"]["portals2017"]["general"]["tasa_paro"] * 100
                    ),
                  name: key
                };
                unemployment_rate.push(obj_UR);
              }
            }

            income_per_household.push(
              {
                value:
                  (municipality_data["general"]["renta_sscc"] *
                    tot_hog_municipality) /
                  tot_hog_municipality,
                name: "municipio"
              },
              {
                value:
                  (province_data["general"]["renta_sscc"] * tot_hog_province) /
                  tot_hog_province,
                name: "provincia"
              },
              {
                value:
                  (country_data["general"]["renta_sscc"] * tot_hog_country) /
                  tot_hog_country,
                name: "pais"
              }
            );
            unemployment_rate.push(
              {
                value:
                  (municipality_data["general"]["tasa_paro"] * 100
                  ),
                name: "municipio"
              },
              {
                value:
                  (province_data["general"]["tasa_paro"] * 100
                  ),
                name: "provincia"
              },
              {
                value:
                  (country_data["general"]["tasa_paro"] * 100
                  ),
                name: "pais"
              }
            );

            environmetObj["environment"] = {
              population_pyramid_2017: population_pyramid_2017,
              housing_typology: housing_typology,
              housing_vs_population: housing_vs_population,
              income_per_household: income_per_household,
              unemployment_rate: unemployment_rate
            };

            this.population_pyramid_data = this.paramsDataService.getDataParams_data(
              environmetObj,
              "population_pyramid_2017"
            );
            this.population_pyramid = this.paramsLayoutService.getLayoutParams(
              this.population_pyramid_data,
              "population_pyramid_2017"
            );

            this.housing_typology_data = this.paramsDataService.getDataParams_data(
              environmetObj,
              "housing_typology"
            );
            this.housing_typology = this.paramsLayoutService.getLayoutParams(
              this.housing_typology_data,
              "housing_typology"
            );
            this.housing_vs_population_data = this.paramsDataService.getDataParams_data(
              environmetObj,
              "housing_vs_population"
            );
            this.housing_vs_population = this.paramsLayoutService.getLayoutParams(
              this.housing_vs_population_data,
              "housing_vs_population"
            );
            this.income_per_household_data = this.paramsDataService.getDataParams_data(
              environmetObj,
              "income_per_household"
            );
            this.income_per_household = this.paramsLayoutService.getLayoutParams(
              this.income_per_household_data,
              "income_per_household"
            );
            this.unemployment_rate_data = this.paramsDataService.getDataParams_data(
              environmetObj,
              "unemployment_rate"
            );
            this.unemployment_rate = this.paramsLayoutService.getLayoutParams(
              this.unemployment_rate_data,
              "unemployment_rate"
            );
            this.entornoReady = true;
            this.entorno_loading = false;
          });
      });
  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
