import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";
import { Observable, of } from "rxjs";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";

@Injectable({
  providedIn: "root"
})
export class GeoMapResolverService implements Resolve<any> {
  constructor(private geoStudiesDataService: GeoStudiesDataService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    let idStudy = route.paramMap.get("idStudy");
    return this.geoStudiesDataService.getBasicInfoStudy(idStudy);
  }
}
