<section class="full-width clearfix">
  <div class="section-container margin-top--s">
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
        <h1 class="h1-page--title">
          Editar usuario
        </h1>
        <div class="margin-top--xl">
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <!-- <div>
              <mat-form-field class="full-width">
                <input
                  formControlName="userName"
                  required
                  matInput
                  placeholder="Usuario"
                />
              </mat-form-field>
            </div> -->
            <div class="margin-top--s">
              <mat-form-field class="full-width">
                <input formControlName="userEmail" required matInput placeholder="Correo electrónico" />
              </mat-form-field>
            </div>

            <div class="margin-top--s">
              <mat-form-field class="full-width">
                <input formControlName="userFirstName" required matInput placeholder="Nombre" />
              </mat-form-field>
            </div>

            <div class="margin-top--s">
              <mat-form-field class="full-width">
                <input formControlName="userSurName" required matInput placeholder="Apellido" />
              </mat-form-field>
            </div>

            <!-- <div class="margin-top--s">
              <mat-form-field class="full-width">
                <input
                  formControlName="userPassword"
                  required
                  matInput
                  placeholder="Contraseña"
                />
              </mat-form-field>
            </div> -->
            <div class="margin-top--s">
              <mat-form-field class="full-width">
                <mat-label>Rol del usuario</mat-label>
                <mat-select formControlName="userProfile" required>
                  <mat-option *ngFor="let u of userProfiles" [value]="u.value">
                    {{ u.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="margin-top--s; ">
              <button mat-flat-button value="Reset" type="reset" color="grey" routerLink="/users">
                Cancelar
              </button>

              <!-- <button mat-flat-button value="Reset" type="reset">
                Borrar datos
              </button> -->

              <!-- <button
                class="form-button"
                mat-raised-button
                color="black"
                (click)="
                  $event.stopPropagation();
                  $event.preventDefault();
                  resetPassword()
                "
              >
                Cambiar contraseña
              </button> -->

              <!-- <button
                mat-flat-button
                color="primary"
                mat-raised-button
                value="ResetPassword"
                type="reset"
              > 
                
              </button>-->

              <button mat-flat-button color="primary" type="submit" [disabled]="!userForm.valid">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
        <div class="users-img huerotate"></div>
      </div>
    </div>
  </div>

  <div class="margin-top--xl"></div>
</section>