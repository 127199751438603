import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { LiBricksComponent } from "./li-bricks.component";
import { TabBuscadorModule } from "../tab-buscador/tab-buscador.module";
import { TabExpansionModule } from "../tab-expansion/tab-expansion.module";
import { TabTematicosModule } from "../tab-tematicos/tab-tematicos.module";

@NgModule({
  declarations: [LiBricksComponent],
  imports: [
    SharedModule,
    TabBuscadorModule,
    TabExpansionModule,
    TabTematicosModule
  ],
  exports: [LiBricksComponent],
  providers: []
})
export class LiBricksModule {}
