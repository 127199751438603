<article *ngFor="let page of setup.pages, let i = index">
    <div data-size="A4">

        <article>
            <app-brick-report-header [setup]="setup"></app-brick-report-header>

            <table class="tBricks"  *ngIf="page.children.length>0">
                <thead>
                    <tr>
                        <th>Brick ID</th>
                        <th class="text-align-center">
                            <div>Tipo</div>
                            <div>inmueble</div>
                        </th>
                        <th>Provincia</th>
                        <th>Municipio</th>
                        <th class="text-align-center">
                            <div>Ventas</div>
                            <div>(€)</div>
                        </th>
                        <th class="text-align-center">
                            <div>Asociadas</div>
                            <div>McDonald's</div>
                        </th>
                        <th class="text-align-center">
                            <div>Site</div>
                            <div></div>
                        </th>
                        <th>
                            <div>Restaurante</div>
                            <div></div>
                        </th>
                        <th class="text-align-center">
                            <div>Impacto</div>
                            <div>Delivery</div>
                        </th>
                        <th class="text-align-center">
                            <div>Impacto</div>
                            <div>total</div>
                        </th>
                    </tr>
                </thead>
                <tbody *ngFor="let brick of page.children, let p = index">
                    <tr *ngFor="let restaurant of brick.properties.impact_info, let y = index">
                        <td *ngIf="y===0" [attr.rowspan]="brick.properties.impact_info.length">
                            {{brick.properties.brick_id}} </td>
                        <td *ngIf="y===0" [attr.rowspan]="brick.properties.impact_info.length">
                            {{brick.properties.building_type}} </td>
                        <td *ngIf="y===0" [attr.rowspan]="brick.properties.impact_info.length">
                            {{brick.properties.province_name}} </td>
                        <td *ngIf="y===0" [attr.rowspan]="brick.properties.impact_info.length">
                            {{brick.properties.municipality_name}} </td>

                        <td *ngIf="y===0" [attr.rowspan]="brick.properties.impact_info.length" class="text-align-right">
                            {{brick.properties.sales_info.first_year_sales | number:
                            '1.0-0'}} </td>
                        <td *ngIf="y===0" [attr.rowspan]="brick.properties.impact_info.length" class="text-align-right">
                            {{brick.properties.sales_info.perc_of_sales_associated_to_existing_rest | number:
                            '1.2-2'}}% </td>

                        <td *ngIf="restaurant.site_id && restaurant.site_id !== ''; else elseBlock">
                            {{restaurant.site_id}} </td>
                        <td *ngIf="restaurant.site_name && restaurant.site_name !== ''; else elseBlock">
                            {{(restaurant.site_name.length>26)? (convertASCII(restaurant.site_name) |
                            slice:0:26)+'..':(convertASCII(restaurant.site_name) )}} </td>
                        <td *ngIf="restaurant.delivery_impact && restaurant.delivery_impact !== ''; else elseBlock"
                            class="text-align-right">{{restaurant.delivery_impact | number:
                            '1.2-2'}}% </td>
                        <td *ngIf="restaurant.total_site_impact && restaurant.total_site_impact !== ''; else elseBlock"
                            class="text-align-right">{{restaurant.total_site_impact | number:
                            '1.2-2'}}% </td>

                        <ng-template #elseBlock>
                            <td class="empty-td"></td>
                        </ng-template>

                    </tr>
                </tbody>
            </table>

           <p *ngIf="page.children.length<=0">No existen restaurantes que cumplan las condiciones</p> 

            <app-brick-report-footer [page_number]="this.setup.id  + i"></app-brick-report-footer>
        </article>
    </div>
</article>