import { Component, ViewChild, ElementRef } from "@angular/core";
import { OnInit } from "@angular/core";

// forms
import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";


import { Router } from "@angular/router";
import { GeoStudiesPrescriptiveDataService } from "../../app-services/geo-studies-prescriptive-data.service";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "../../app-widgets/popup-modals/edit-dialog/edit-dialog.component";



import { HttpClient } from '@angular/common/http';

import { ThemePalette } from '@angular/material/core';
import { AcceptValidator, MaxSizeValidator } from '@angular-material-components/file-input';


@Component({
  selector: "app-massive-bricks-new-form",
  templateUrl: "./massive-bricks-new-form.component.html",
  styleUrls: ["./massive-bricks-new-form.component.scss"]
})
export class MassiveBricksNewFormComponent implements OnInit {
  title: string = "Nuevo estudio masivo";
  paragraph: string =
    "Al pulsar guardar se iniciará un proceso de cálculo que" +
    " puede durar bastante tiempo. Una vez concluido, el" +
    " escenario quedará guardado en los estudios y podrá visualizarlo.";

  color: ThemePalette = 'primary';
  disabled: boolean = false;
  multiple: boolean = false;
  accept: string;

  
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('csvReader') csvReader: any;

  fileControl :FormControl;
  public records: any[] = [];

  selectedFiles: FileList
  selectedId: string;
  currentFileUpload: File
  selectedData: any;

  fileName = '';
  study_name = "Nuevo estudio"; 
  kind_building = "instore_u";
  building_type = "Instore Urban";


  public files = null;
  maxSize= 16;

  

  get formArray(): any | null {
    return this.bricksStudyFormGroup.get("formArray");
  }

  constructor(private formBuilder: FormBuilder,  private router: Router, 
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService,  
     public dialog: MatDialog,private http: HttpClient,
     private fb: FormBuilder) {
    
      this.fileControl = new FormControl(this.files, [
        Validators.required,
        MaxSizeValidator(this.maxSize * 1024)
      ]);    

  }


  

  get f(){
    return this.bricksStudyFormGroup.controls;
  }


  bricksStudyFormGroup = new FormGroup({
    study_name: new FormControl("", [
      Validators.required
    ]),
    study_kind_building: new FormControl("", [Validators.required]),
    //file: new FormControl(this.files, [Validators.required,
   //            MaxSizeValidator(this.maxSize * 1024)
   //          ])
  });


  ngOnInit() {
    
      this.fileControl.valueChanges.subscribe((file: any) => {
        if (!Array.isArray(file)) {
          console.log(file)
          this.files = [file];
        } else {
          this.files = file;
        }
      });

      this.bricksStudyFormGroup = this.formBuilder.group({
        study_name: [this.study_name, Validators.required],
        study_kind_building: [this.kind_building],
      });

    }


  onFileSelected(event) {
    const file:File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.selectedData = file;
    }
  }

  openDialogEdit(expandedStudy, text): void {    
      const dialogRefEdit = this.dialog.open(EditDialogComponent, {
        width: "320px",
        data: text
      });
      dialogRefEdit.afterClosed().subscribe(result => {
        if (result) {
          console.log("Yes clicked", result);
        }
      });
    
 }


  onSubmit() {
  
    if(this.fileName !=""){
      if(this.bricksStudyFormGroup.value.study_kind_building =="instore_u"){
        this.bricksStudyFormGroup.value.building_type = "Instore Urban";
        this.bricksStudyFormGroup.value.kind_building = "Instore";
        this.bricksStudyFormGroup.value.delivery = true;
        this.bricksStudyFormGroup.value.mccafe = true;
        this.bricksStudyFormGroup.value.mcauto = false;
        this.bricksStudyFormGroup.value.mckiosko = true;
        this.bricksStudyFormGroup.value.mcexpress = false;
        this.bricksStudyFormGroup.value.type_play = "No";
        this.bricksStudyFormGroup.value.seats_room = 130;
        this.bricksStudyFormGroup.value.seats_terrace_outdoor = 20;
        this.bricksStudyFormGroup.value.seats_terrace_indoor = 0;
        this.bricksStudyFormGroup.value.separate_entrance_mall = "No";
        this.bricksStudyFormGroup.value.total_parking_spaces = 0;
        this.bricksStudyFormGroup.value.own_parking ="No";
        this.bricksStudyFormGroup.value.own_parking_space = 0;
        this.bricksStudyFormGroup.value.accesibility = "A";
        this.bricksStudyFormGroup.value.visibility = "A";

      }else if (this.bricksStudyFormGroup.value.study_kind_building =="instore_m"){
        this.bricksStudyFormGroup.value.building_type = "Instore Medio";
        this.bricksStudyFormGroup.value.kind_building = "Instore";
        this.bricksStudyFormGroup.value.delivery = true;
        this.bricksStudyFormGroup.value.mccafe = true;
        this.bricksStudyFormGroup.value.mcauto = false;
        this.bricksStudyFormGroup.value.mckiosko = true;
        this.bricksStudyFormGroup.value.mcexpress = false;
        this.bricksStudyFormGroup.value.type_play = "No";
        this.bricksStudyFormGroup.value.seats_room = 180;
        this.bricksStudyFormGroup.value.seats_terrace_outdoor = 40;
        this.bricksStudyFormGroup.value.seats_terrace_indoor = 0;
        this.bricksStudyFormGroup.value.separate_entrance_mall = "No";
        this.bricksStudyFormGroup.value.total_parking_spaces = 0;
        this.bricksStudyFormGroup.value.own_parking ="No";
        this.bricksStudyFormGroup.value.own_parking_space = 0;
        this.bricksStudyFormGroup.value.accesibility = "A";
        this.bricksStudyFormGroup.value.visibility = "A";
      }else if(this.bricksStudyFormGroup.value.study_kind_building =="freestanding1"){
        this.bricksStudyFormGroup.value.building_type = "Fst 1";
        this.bricksStudyFormGroup.value.kind_building = "Free Standing";
        this.bricksStudyFormGroup.value.delivery = true;
        this.bricksStudyFormGroup.value.mccafe = true;
        this.bricksStudyFormGroup.value.mcauto = true;
        this.bricksStudyFormGroup.value.mckiosko = true;
        this.bricksStudyFormGroup.value.mcexpress = false;
        this.bricksStudyFormGroup.value.type_play = "Play Interior";
        this.bricksStudyFormGroup.value.seats_room = 109;
        this.bricksStudyFormGroup.value.seats_terrace_outdoor = 70;
        this.bricksStudyFormGroup.value.seats_terrace_indoor = 0;
        this.bricksStudyFormGroup.value.separate_entrance_mall = "No";
        this.bricksStudyFormGroup.value.total_parking_spaces = 25;
        this.bricksStudyFormGroup.value.own_parking ="Si";
        this.bricksStudyFormGroup.value.own_parking_space = 25;
        this.bricksStudyFormGroup.value.accesibility = "A";
        this.bricksStudyFormGroup.value.visibility = "A";
      }else if(this.bricksStudyFormGroup.value.study_kind_building =="freestanding2"){
        this.bricksStudyFormGroup.value.building_type = "Fst 2";
        this.bricksStudyFormGroup.value.kind_building = "Free Standing";
        this.bricksStudyFormGroup.value.delivery = true;
        this.bricksStudyFormGroup.value.mccafe = true;
        this.bricksStudyFormGroup.value.mcauto = true;
        this.bricksStudyFormGroup.value.mckiosko = true;
        this.bricksStudyFormGroup.value.mcexpress = false;
        this.bricksStudyFormGroup.value.type_play = "Play Exterior Con Carpa";
        this.bricksStudyFormGroup.value.seats_room = 120;
        this.bricksStudyFormGroup.value.seats_terrace_outdoor = 100;
        this.bricksStudyFormGroup.value.seats_terrace_indoor = 0;
        this.bricksStudyFormGroup.value.separate_entrance_mall = "No";
        this.bricksStudyFormGroup.value.total_parking_spaces = 25;
        this.bricksStudyFormGroup.value.own_parking ="Si";
        this.bricksStudyFormGroup.value.own_parking_space = 25;
        this.bricksStudyFormGroup.value.accesibility = "A";
        this.bricksStudyFormGroup.value.visibility = "A";
      }
      //build the json to send:
      let prescript_json = {};
      prescript_json["properties"] = this.bricksStudyFormGroup.value; 
      prescript_json["properties"].group = "advance";
      //console.log(JSON.stringify(prescript_json));
      this.selectedData;
      const formData1 = new FormData();
      formData1.append("file", this.selectedData);        

          this.geoStudiesPrescriptiveDataService
          .loadFileStudyMassivePrescriptive(formData1 )
          .subscribe(
            (response) => {
              console.log("loadFileStudyMassivePrescriptive Response is: ", response);
              if(response.uuid){
                prescript_json["properties"]["filenametmp"] = response.uuid;
              }
              this.geoStudiesPrescriptiveDataService
                  .saveStudyMassivePrescriptive(prescript_json)
                  .subscribe(
                    (response) => {
                      console.log("saveStudyMassivePrescriptive Response is: ", response);
                      //if save ok, then execute api nommon:
                      let studyMassive = response;

                        this.geoStudiesPrescriptiveDataService
                          .executeStudyMassivePrescriptive(studyMassive)
                          .subscribe(
                            (response) => {
                              console.log("executeStudyMassivePrescriptive Response is: ", response);
                              //redirect to list studies:
                              this.router.navigateByUrl("/massive-prescriptive/studies");
                              },
                            (error) => {
                              console.error("executeStudyMassivePrescriptive An error occurred, ", error);
                              //redirect to list studies:
                              //this.router.navigateByUrl("/massive-prescriptive/studies");
                              }
                            );

                      
                      },
                    (error) => {
                      console.error("saveStudyMassivePrescriptive An error occurred, ", error);
                      //redirect to list studies:
                      //this.router.navigateByUrl("/massive-prescriptive/studies");
                      }
                    );

             

              },
            (error) => {    
                console.error("loadFileStudyMassivePrescriptive An error occurred, ", error);              
              }
            );
          
    }

  }
}
