import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";

import { BrickStudiesTableComponent } from "./brick-studies-table.component";
import { DeleteDialogModule } from "../../../app-widgets/popup-modals/delete-dialog/delete-dialog.module";

import { GeoStudiesPrescriptiveDataService } from "../../../app-services/geo-studies-prescriptive-data.service";

@NgModule({
  declarations: [BrickStudiesTableComponent],
  imports: [SharedModule, DeleteDialogModule],
  exports: [BrickStudiesTableComponent],
  providers: [GeoStudiesPrescriptiveDataService]
})
export class BrickStudiesTableModule {}
