<h1 mat-dialog-title>
  <strong>{{ action }} restaurante MCDONALDS:</strong>
</h1>
<div mat-dialog-content style="overflow: hidden;">
  <div *ngIf="action != 'Eliminar'; else elseTemplate">

    <section class="full-width clearfix padding-xs margin-top--s">
      <div class="row">
        <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
          <mat-form-field>
            <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Nombre del restaurante"
              [(ngModel)]="local_data.name" required (keyup)="updateInput($event)" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tipo de inmueble</mat-label>
            <mat-select [(ngModel)]="local_data.kind_building"
              (selectionChange)="onChangeKindBuilding($event,'buildingTypeSelected')">
              <mat-option value="instore">Instore</mat-option>
              <mat-option value="mall">Mall</mat-option>
              <mat-option value="freestanding">Free Standing</mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf="show_div_kb_checkbox_ndc" class="full-width clearfix margin-top--xs border-bottom--gray"
            id="div_kb_checkbox_ndc">
            <mat-checkbox [(ngModel)]="local_data.nuevodesarrollocomercial" [checked]="checked_div_kb_checkbox_ndc"
              value="nuevodesarrollocomercial" (keydown.enter)="$event.preventDefault()"
              (change)="onChangeKBCheckbox($event,'nuevodesarrollocomercial')">Nuevo desarrollo comercial</mat-checkbox>
          </div>
          <!--
              <div class="full-width clearfix margin-top--xs">
                <mat-checkbox
                [(ngModel)]="local_data.mccafeCtrl"
                  value="mccafeCtrl"
                  (keydown.enter)="$event.preventDefault()"
                  >McCafé</mat-checkbox
                >
              </div>
            -->
          <div class="full-width clearfix margin-top--xs">
            <mat-checkbox [(ngModel)]="local_data.mckioskoCtrl" value="mckioskoCtrl"
              (keydown.enter)="$event.preventDefault()">McKiosko</mat-checkbox>
          </div>
          <div class="full-width clearfix margin-top--xs">
            <mat-checkbox [(ngModel)]="local_data.mcexpressCtrl" value="mcexpressCtrl"
              (keydown.enter)="$event.preventDefault()">McExpress</mat-checkbox>
          </div>
          <div class="full-width clearfix margin-top--xs">
            <mat-checkbox [(ngModel)]="local_data.mcautoCtrl" value="mcautoCtrl"
              (keydown.enter)="$event.preventDefault()">McAuto</mat-checkbox>
          </div>
          <div class="full-width clearfix margin-top--xs">
            <mat-checkbox [(ngModel)]="local_data.mcdeliveryCtrl" value="mcdeliveryCtrl"
              (keydown.enter)="$event.preventDefault()">Delivery</mat-checkbox>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
          <mat-form-field>
            <mat-label>Tipo play</mat-label>
            <mat-select value="playCtrl" [(ngModel)]="local_data.playCtrl">
              <mat-option value="No">No</mat-option>
              <mat-option value="Play exterior con carpa">Play exterior con carpa</mat-option>
              <mat-option value="Play exterior sin carpa">Play exterior sin carpa</mat-option>
              <mat-option value="Play interior">Play interior</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Asientos salón</mat-label>
            <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
              value="loungeSeatsCtrl" [(ngModel)]="local_data.loungeSeatsCtrl" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Asientos terraza exterior</mat-label>
            <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
              value="outdoorSeatsCtrl" [(ngModel)]="local_data.outdoorSeatsCtrl" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Asientos terraza interior</mat-label>
            <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
              value="indoorSeatsCtrl" [(ngModel)]="local_data.indoorSeatsCtrl" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Entrada independiente centrol comercial</mat-label>
            <mat-select value="independentEntanceCtrl" [(ngModel)]="local_data.independentEntanceCtrl">
              <mat-option value="No">No</mat-option>
              <mat-option value="Sí">Sí</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">

          <mat-form-field>
            <mat-label>Plazas totales de parking</mat-label>
            <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
              value="totalParkingCtrl" [(ngModel)]="local_data.totalParkingCtrl" required />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Plazas de parking propio</mat-label>
            <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
              value="totalOwnParkingCtrl" [(ngModel)]="local_data.totalOwnParkingCtrl" required />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Accesibilidad</mat-label>
            <mat-select value="accessibilityCtrl" [(ngModel)]="local_data.accessibilityCtrl">
              <mat-option value="0">0</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="A">A</mat-option>
              <mat-option value="B">B</mat-option>
              <mat-option value="C">C</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Visibilidad</mat-label>
            <mat-select value="visibilityCtrl" [(ngModel)]="local_data.visibilityCtrl">
              <mat-option value="0">0</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="A">A</mat-option>
              <mat-option value="B">B</mat-option>
              <mat-option value="C">C</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Estimación de ventas</mat-label>
            <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
              value="sales_estimation" [(ngModel)]="local_data.sales_estimation" required />
          </mat-form-field>

        </div>
        <div class="col-xs-12 col-sm-12  col-md-6  col-lg-6">
          <div style="margin-bottom: 0.3cm;">
            <button mat-button (click)="
                $event.stopPropagation();
                $event.preventDefault();
                openDialog('Añadir dirección', {local_data})
              " mat-flat-button color="primary">
              {{ action }} dirección
            </button>
          </div>
          <mat-form-field *ngIf="!disabledAddress">
            <mat-label>Dirección añadida</mat-label>
            <input placeholder="Dirección añadida" matInput [(ngModel)]="local_data.addressCtrl" readonly />
          </mat-form-field>
        </div>
      </div>
    </section>

  </div>
  <ng-template #elseTemplate>
    <p>
      ¿Seguro que quiere eliminar el <b>{{ local_data.name }}</b>?
    </p>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()" [disabled]="disabledButton">{{ action }}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancelar
  </button>
</div>