import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { DataService } from "../../../app-services/data.service";

import * as L from "leaflet";
//import "leaflet/dist/images/marker-shadow.png";
import leaflet from 'leaflet';
import { LocationService } from "src/app/app-services/location.service";
import { GeoStudiesDataService } from "src/app/app-services/geo-studies-data.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-mcdGeoLocatorMap",
  templateUrl: "./mcdGeoLocatorMap.component.html",
  styleUrls: ["./mcdGeoLocatorMap.component.scss"]
})
export class McdGeoLocatorMapComponent implements OnInit, OnDestroy {
  @Input()
  mapId: string;
  @Input()
  mapSize: string;
  @Input()
  mapDisable: boolean;

  disabledmap: boolean = false;

  locator_map: any;
  layerLocation: any;

  markerSearchBox: any;
  layer_brick: any;
  access_token: string = this.dataService.getMapBoxToken()[0].value;

  stylelayer = {
    defecto: {
      color: "red",
      opacity: 1,
      fillcolor: "red",
      fillOpacity: 0.1,
      weight: 0.5
    },
    reset: {
      color: "red",
      opacity: 0.4,
      weight: 1
    },
    highlight: {
      weight: 5,
      color: "#0D8BE7",
      dashArray: "",
      fillOpacity: 0.7
    },
    selected: {
      color: "blue",
      opacity: 0.3,
      weight: 0.5
    }
  };

  featuresSelected = [];
  arrayBounds = [];
  unsubscribe: any;
  unsubscribe2: any;
  unsubscribe3: any;
  unsubscribe4: any;
  unsubscribe5: any;
  unsubscribe6: any;

  constructor(
    private geoStudiesDataService: GeoStudiesDataService,
    private locationService: LocationService,
    private dataService: DataService
  ) {
    this.unsubscribe = this.geoStudiesDataService.addressPredictiveChanged$.subscribe(
      address => {
        if (address == "") {
          if (this.locator_map.hasLayer(this.layerLocation)) {
            try {
              this.locator_map.removeLayer(this.layerLocation);
            } catch (error) {}
          }
        } else {
          this.unsubscribe2 = this.locationService
          .geocodificarDireccion(address)
          .subscribe((respuesta: any) => {
              let dataResult = this.locationService.buscarDireccion(respuesta);
              if(dataResult.locality==null){
                if(dataResult.postal_code !=null){
                      //buscar localidad y continuar
                      this.locationService
                      .geocodificarDireccion(dataResult.postal_code)
                      .subscribe((respuestaPostalCode: any) => {
                        let dataLocality = this.locationService.buscarDireccion(respuestaPostalCode);
                        dataResult.locality = dataLocality.locality;
                        this.geoStudiesDataService.reloadDataNewForm(dataResult);
                        this.locator_map.setView([dataResult.y, dataResult.x], 18);
                        try {
                        this.locator_map.removeLayer(this.layerLocation);
                        } catch (error) {}
                        this.markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                        draggable: !this.disabledmap,
                        autoPan: true,
                        icon: L.icon({
                          iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                          shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png",
                          iconSize: [24, 36],
                          iconAnchor: [12, 36]
                        })
                        });
                        this.layerLocation = L.layerGroup([this.markerSearchBox]);
                        if (!this.disabledmap) {
                        //this.markerSearchBox.dragging.disable();
                        this.markerSearchBox.on("drag", this.reloadData.bind(this));
                        }
  
                        this.locator_map.addLayer(this.layerLocation);
                      });

                     

                }else if(respuesta.results[0].formatted_address){
                  dataResult.address = respuesta.results[0].formatted_address;
                  this.geoStudiesDataService.reloadDataNewForm(dataResult);
                  this.locator_map.setView([dataResult.y, dataResult.x], 18);
                  try {
                  this.locator_map.removeLayer(this.layerLocation);
                  } catch (error) {}
                  this.markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                  draggable: !this.disabledmap,
                  autoPan: true,
                  icon: L.icon({
                    iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                    shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png",
                    iconSize: [24, 36],
                    iconAnchor: [12, 36]
                  })
                  });
                  this.layerLocation = L.layerGroup([this.markerSearchBox]);
                  if (!this.disabledmap) {
                  //this.markerSearchBox.dragging.disable();
                  this.markerSearchBox.on("drag", this.reloadData.bind(this));
                  }

                  this.locator_map.addLayer(this.layerLocation);
                }
                
              }else{
                       this.geoStudiesDataService.reloadDataNewForm(dataResult);
                        this.locator_map.setView([dataResult.y, dataResult.x], 18);
                        try {
                        this.locator_map.removeLayer(this.layerLocation);
                        } catch (error) {}
                        this.markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                        draggable: !this.disabledmap,
                        autoPan: true,
                        icon: L.icon({
                          iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                          shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png",
                          iconSize: [24, 36],
                          iconAnchor: [12, 36]
                        })
                        });
                        this.layerLocation = L.layerGroup([this.markerSearchBox]);
                        if (!this.disabledmap) {
                        //this.markerSearchBox.dragging.disable();
                        this.markerSearchBox.on("drag", this.reloadData.bind(this));
                        }
  
                        this.locator_map.addLayer(this.layerLocation);
              }
              
            });
        }
      }
    );

    this.geoStudiesDataService.addressPredictiveAddMcdChanged$.subscribe(
      address => {
        if (address == "") {
          if (this.locator_map.hasLayer(this.layerLocation)) {
            try {
              this.locator_map.removeLayer(this.layerLocation);
            } catch (error) {}
          }
        } else {
          this.unsubscribe2 = this.locationService
            .geocodificarDireccion(address)
            .subscribe((respuesta: any) => {
              let dataResult = this.locationService.buscarDireccion(respuesta);
              this.geoStudiesDataService.reloadDataNewFormAddMcd(dataResult);
              this.locator_map.setView([dataResult.y, dataResult.x], 18);
              try {
                this.locator_map.removeLayer(this.layerLocation);
              } catch (error) {}
              this.markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                draggable: true,
                autoPan: true,
                icon: L.icon({
                  iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                  shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png",
                  iconSize: [24, 36],
                  iconAnchor: [12, 36]
                })
              });
              this.layerLocation = L.layerGroup([this.markerSearchBox]);
              
              this.markerSearchBox.on("drag", this.reloadDataAddMcd.bind(this));
              

              this.locator_map.addLayer(this.layerLocation);
            });
        }
      }
    );


    this.unsubscribe3 = this.geoStudiesDataService.coordsPredictiveChanged$.subscribe(
      coords => {
        if (coords == null) {
          if (this.locator_map.hasLayer(this.layerLocation)) {
            try {
              this.locator_map.removeLayer(this.layerLocation);
            } catch (error) {}
          }
        } else {
          this.unsubscribe4 = this.locationService
            .geocodificarCoordenadas(coords)
            .subscribe((respuesta: any) => {
              let dataResult = this.locationService.buscarDireccion(respuesta);
              if(dataResult.locality==null){
                if(dataResult.postal_code !=null){
                      //buscar localidad y continuar
                      this.locationService
                      .geocodificarDireccion(dataResult.postal_code)
                      .subscribe((respuestaPostalCode: any) => {
                        let dataLocality = this.locationService.buscarDireccion(respuestaPostalCode);
                        dataResult.locality = dataLocality.locality;
						
                        this.geoStudiesDataService.reloadDataNewForm(dataResult);
                        this.locator_map.setView([dataResult.y, dataResult.x], 18);
                        try {
                          this.locator_map.removeLayer(this.layerLocation);
                        } catch (error) {}
                        this.markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                          draggable: !this.disabledmap,
                          autoPan: true,
                          icon: L.icon({
                            iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                            shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png",
                            iconSize: [24, 36],
                            iconAnchor: [12, 36]
                          })
                        });
                        this.layerLocation = L.layerGroup([this.markerSearchBox]);
                        if (!this.disabledmap) {
                          //this.markerSearchBox.dragging.disable();
                          this.markerSearchBox.options.draggable = false;
                          this.markerSearchBox.on("drag", this.reloadData.bind(this));
                        }
          
                        this.locator_map.addLayer(this.layerLocation);
                        
						
						
						});
						}
						}else{
              this.geoStudiesDataService.reloadDataNewForm(dataResult);
                        this.locator_map.setView([dataResult.y, dataResult.x], 18);
                        try {
                          this.locator_map.removeLayer(this.layerLocation);
                        } catch (error) {}
                        this.markerSearchBox = L.marker([dataResult.y, dataResult.x], {
                          draggable: !this.disabledmap,
                          autoPan: true,
                          icon: L.icon({
                            iconUrl: environment.imgUrl + "/helpers/marker-icon.png",
                            shadowUrl: environment.imgUrl + "/helpers/marker-shadow.png",
                            iconSize: [24, 36],
                            iconAnchor: [12, 36]
                          })
                        });
                        this.layerLocation = L.layerGroup([this.markerSearchBox]);
                        if (!this.disabledmap) {
                          //this.markerSearchBox.dragging.disable();
                          this.markerSearchBox.options.draggable = false;
                          this.markerSearchBox.on("drag", this.reloadData.bind(this));
                        }
          
                        this.locator_map.addLayer(this.layerLocation);
                        
            }
              
            });
        }
      }
    );
  }

  ngOnInit() {
    this.unsubscribe5 = this.geoStudiesDataService.bricksPredictiveChanged$.subscribe(
      object => {
        this.dataService
          .getBrickByCoords(object.lat, object.lng)
          .subscribe((objectBrick: any) => {
            if (this.locator_map.hasLayer(this.layer_brick)) {
              try {
                this.locator_map.removeLayer(this.layer_brick);
              } catch (error) {}
            }

            this.layer_brick = L.geoJSON(objectBrick, {
              style: this.stylelayer.defecto,
              onEachFeature: this.clickFeature.bind(this)
            });
            this.locator_map.addLayer(this.layer_brick);
          });
      }
    );
  }

  clickFeature(feature: any, layer: any) {
    layer.on({
      click: this.clickOn.bind(this)
    });
  }

  clickOn(e) {
    let layer = e.target;
    let feature = e.target.feature;
    var result = false;
    for (var i = 0; i < this.featuresSelected.length; i++) {
      if (this.featuresSelected[i].ID_ == feature.properties.ID_) {
        result = true;
        break;
      }
    }
    if (result) {
      this.setStyleLayer(layer, this.stylelayer.defecto);
      this.removerlayers(feature);
      this.removeBounds(layer);
    } else {
      this.setStyleLayer(layer, this.stylelayer.highlight);
      this.addLayers(feature);
      this.addBounds(layer);
    }
    if (this.arrayBounds.length > 0) {
      this.locator_map.fitBounds(this.arrayBounds);
    }
  }

  removerlayers(feature) {
    this.featuresSelected = this.featuresSelected.filter(
      obj => obj.ID_ != feature.properties.ID_
    );
    this.geoStudiesDataService.setBricksTransitPredictive(
      this.featuresSelected
    );
  }

  setStyleLayer(layer, styleSelected) {
    layer.setStyle(styleSelected);
  }

  removeBounds(layer) {
    this.arrayBounds = this.arrayBounds.filter(
      bounds => bounds != layer.getBounds()
    );
  }

  addLayers(feature) {
    this.featuresSelected.push({
      ID_: feature.properties.ID_,
      feature: feature
    });
    this.geoStudiesDataService.setBricksTransitPredictive(
      this.featuresSelected
    );
  }

  addBounds(layer) {
    this.arrayBounds.push(layer.getBounds());
  }

  reloadData(data: any) {
    if (!this.disabledmap) {
      this.unsubscribe6 = this.locationService
        .geocodificarCoordenadas({
          lat: data.latlng.lat,
          lon: data.latlng.lng
        })
        .subscribe((respuesta: any) => {
          let resultApi = this.locationService.buscarDireccion(respuesta);
          if(resultApi.locality==null){
            if(resultApi.postal_code !=null){
                  //buscar localidad y continuar
                  this.locationService
                  .geocodificarDireccion(resultApi.postal_code)
                  .subscribe((respuestaPostalCode: any) => {
                    let dataLocality = this.locationService.buscarDireccion(respuestaPostalCode);
                    resultApi.locality = dataLocality.locality;
        
                    this.geoStudiesDataService.reloadDataNewForm({
                      x: resultApi.x,
                      y: resultApi.y,
                      address: resultApi.address,
                      locality: resultApi.locality,
                      province: resultApi.province
                    });
        
        
        });
        }
        }else{
          this.geoStudiesDataService.reloadDataNewForm({
            x: resultApi.x,
            y: resultApi.y,
            address: resultApi.address,
            locality: resultApi.locality,
            province: resultApi.province
          });
        }
          
        });
    }
  }

  reloadDataAddMcd(data: any) {
   // if (!this.disabledmap) {
      this.unsubscribe6 = this.locationService
        .geocodificarCoordenadas({
          lat: data.latlng.lat,
          lon: data.latlng.lng
        })
        .subscribe((respuesta: any) => {
          let resultApi = this.locationService.buscarDireccion(respuesta);
          this.geoStudiesDataService.reloadDataNewFormAddMcd({
            x: resultApi.x,
            y: resultApi.y,
            address: resultApi.address,
            locality: resultApi.locality,
            province: resultApi.province
          });
        });
   // }
  }

  ngAfterViewInit() {
    if (this.mapDisable == false) {
      this.disabledmap = false;
    } else {
      this.disabledmap = true;
    }

    this.locator_map = L.map(this.mapId, {
      zoomControl: false
    }).setView([40.42352, -3.70459], 15);

    L.control
      .zoom({
        position: "topright"
      })
      .addTo(this.locator_map);

    L.tileLayer(this.access_token, {
      attribution:
        'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
        '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="http://mapbox.com">Mapbox</a>'
      //  ,
      //  id: 'mapbox.streets',
      //  accessToken: 'pk.eyJ1IjoiYWRlZ3JhY2lhIiwiYSI6ImNpdXBybDMzMTAxajAyeXA0ZnE0cTN3dGkifQ.Sq6NjjMcGmc5JzPAC7Tnzg'
      // Para modificar el estilo del mapa de MapBox seguir las siguientes instrucciones:
      // 1 - Nos dirigimos a la página de incio de mapbox.
      // 2 - En la parte superior derecha pulsamos en el icono de nuestra cuenta y accedemos a Account.
      // 3 - En el menú vertical derecha pulsamos en la opción 'Design in Mapbox Studio'.
      // 4 - En esta ventana veremos los diferentes estilos de mapas que tengamos creados. Para obtener la ruta al estilo y ser cargado en el proyecto, pulsaremos
      // en uno de los estilos del mapa para entrar en la opción de edición de este.
      // 5 - En el menú superior pulsamos en donde pone 'Share'.
      // 6 - En esta ventana modal, copiaremos la URL de Share.
      // 7 - https://api.mapbox.com/styles/v1/kurama/cjwhqiqfv01is1clevwlypsf9.html?fresh=true&title=true&access_token=pk.eyJ1Ijoia3VyYW1hIiwiYSI6ImNqdmd1cnZnZjA2bHI0YXF0d3lsZDY5M2EifQ.Kz7LTA1Fj_Vds50sNzKBPg#10.0/42.362400/-71.020000/0
      // 8 - La URL anterior es la original, se tiene que modificar para que funcione. La sección '.html?fresh=true&title=true&' se reemplaza por '/tiles/256/{z}/{x}/{y}?'.
    }).addTo(this.locator_map);
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
    if (this.unsubscribe3) {
      this.unsubscribe3.unsubscribe();
    }
    if (this.unsubscribe4) {
      this.unsubscribe4.unsubscribe();
    }
    if (this.unsubscribe5) {
      this.unsubscribe5.unsubscribe();
    }
    if (this.unsubscribe6) {
      this.unsubscribe6.unsubscribe();
    }
  }
}
