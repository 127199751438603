import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { OnChanges } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { D3MainService } from "../../../app-services/d3/d3-main.service";

@Component({
  selector: "app-options-from-parent",
  templateUrl: "./options-from-parent.component.html",
  styleUrls: ["./options-from-parent.component.scss"]
})
export class OptionsFromParentComponent implements OnInit {
  @Input()
  params: any;
  @Input()
  childrenOptions: any;
  @Input()
  widget_num: number;

  @Input()
  emitFirst: boolean;
  @Output()
  eventEmitter = new EventEmitter<number>();

  formControl = new FormControl();
  selectorPlaceholder: string;
  selectorOptions: any;
  isMultiple: boolean;
  isParent: boolean;
  isVisible: boolean = true;
  optionExist: boolean;

  // service
  getRealName: any;

  setSelector(): void {
    const controls = this.params.selectors;
    const thisSelector = controls.find(
      c =>
        c.widget == "options-from-parent" &&
        parseInt(c.widget_num, 10) == +this.widget_num
    );

    const indexOfSelect = controls.findIndex(
      c => c.widget == "options-from-parent" && c.widget_num == +this.widget_num
    );

    // remove older options
    let options = null;

    if (!thisSelector.enabled) {
      this.formControl.disable();
    }

    this.selectorPlaceholder = thisSelector.placeholder;
    this.optionExist = !!options;

    this.isMultiple = thisSelector.isMultiple;
    this.isParent = thisSelector.isParent;
    this.isVisible = thisSelector.display;

    this.selectorOptions = this.optionExist ? options : this.childrenOptions;

    if (this.selectorOptions.length > 0) {
      this.selectorOptions[0]["selected"] = true;

      this.selectorOptions.forEach(pick => {
        pick.dictionaryName = this.getRealName(
          "controls-select",
          "short_title",
          pick.key
        );
      });

      if (!this.isMultiple) {
        this.formControl.setValue(
          this.isParent
            ? this.selectorOptions.find(c => c.selected == true)
            : this.selectorOptions.find(c => c.selected == true)["key"]
        );
      }
      if (this.isMultiple) {
        // TO BE COMPLETED
        this.formControl.setValue(thisSelector.selected);
      }

      if (!this.optionExist) {
        this.params.selectors[indexOfSelect].options = this.selectorOptions;
      }
    }
  }

  constructor(private d3MainService: D3MainService) { }

  // JS change
  onChange(newVal) {
    this.eventEmitter.emit(this.formControl.value); // "0", "1", "2", "3"
  }

  ngOnChanges() {
    this.getRealName = this.d3MainService.getRealName;

    this.setSelector();
    if (this.emitFirst) {
      this.eventEmitter.emit(this.formControl.value); // "0", "1", "2", "3"
    }
  }

  ngOnInit() { }
}
