<section class="full-width clearfix padding-xs margin-top--xs" *ngIf="ventasReady">
  <div class="row">
    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
      <div class="tab-data">
        <h2 class="tab-headline--small">Facturación</h2>
        <p class="super-big-number green" i18n>
          {{ ventas.grossData[0].facturacion | number: "1.0-0":"es" }}
        </p>
        <p class="tab-headline--small">euros / año</p>
      </div>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
      <div class="tab-data">
        <h2 class="tab-headline--small">Comparable</h2>
        <p class="super-big-number green" i18n>
          {{ ventas.grossData[0].variacion.toLocaleString() }}%
        </p>
        <p class="tab-headline--small">año anterior</p>
      </div>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
      <div class="tab-data">
        <h2 class="tab-headline--small">Hora máx. facturación</h2>
        <p class="super-big-number green" i18n>
          {{ hora_max_facturacion }}
        </p>
        <p class="tab-headline--small">
          {{ ventas.grossData[0].max_euros | number: "1.0-0":"es" }} eur.
        </p>
      </div>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
      <div class="tab-data">
        <h2 class="tab-headline--small">Hora máx. tickets</h2>
        <p class="super-big-number green" i18n>
          {{ hora_max_tickets }}
        </p>
        <p class="tab-headline--small">
          {{ ventas.grossData[0].max_tickets | number: "1.0-0":"es" }}
          tickets.
        </p>
      </div>
    </div>
  </div>

</section>

<section class="full-width clearfix padding-xs margin-top--s" *ngIf="ventasReady">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

      <h2 class="tab-headline">VENTAS POR CANAL</h2>
      <p>
        En porcentaje.
      </p>

      <app-stacked-bar async style="width: 100%;" [params]="percentages_sales" chartId="ventas_canal_stacked"
        chartSize="regular" *ngIf="ventasReady"></app-stacked-bar>

      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <h2 class="tab-headline">IMPORTE DE VENTAS</h2>
      <p>En euros.</p>
      <app-options-from-json style="width: 100%;" [params]="time_sales" widget_num="1" [emitFirst]="true"
        (eventEmitter)="changeTemporalSerie($event)" *ngIf="ventasReady"></app-options-from-json>

      <app-vertical-bars style="width: 100%;" [params]="time_sales" [temporalSerie]="temporalSerie" *ngIf="ventasReady"
        chartId="time_sales" chartSize="regular"></app-vertical-bars>
    </div>
  </div>
</section>