import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { GeoStudiesDataService } from "../../../../../app-services/geo-studies-data.service";
import { Todo } from "../../../../../app-services/geo-studies-data.service";
import { DataService } from "../../../../../app-services/data.service";

@Component({
  selector: "app-tab-studies",
  templateUrl: "./tab-studies.component.html",
  styleUrls: ["./tab-studies.component.scss"]
})
export class TabStudiesComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  checked = true;
  displayedColumns: string[] = ["nombre"];

  todos: Observable<Todo[]>;
  studiesSub: any;
  studiesTable: any;
  dataSource: any;
  studiesFiltered: any;

  applyFilter(filterValue: string) {
    if (filterValue == "") {
      this.checked = true;
    } else {
      this.checked = false;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.studiesFiltered = this.dataSource.filteredData;    
    this.dataService.getFilteredPredictiveStudies(this.studiesFiltered);
  }

  studySelected(elementId: string, lat: string, long: string) {
    let coords = { lat: lat, long: long };
    this.geoStudiesDataService.getZoomOnStudy(coords);
  }

  showAll: any = () => {
    this.dataService.showAllStudiesPredictive(this.checked);
    if(this.checked){
      this.studiesSub = this.geoStudiesDataService.todos.subscribe(d => {
        this.studiesTable = d;
        this.studiesTable = this.studiesTable.filter(
          dt => dt.properties.study.state_code == "processed"
        );
  
        if (d.length > 0) {
          this.dataSource = new MatTableDataSource(this.studiesTable);
          this.dataSource.paginator = this.paginator;
        }
      });
    }else{
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
    }
    
  };

  constructor(private geoStudiesDataService: GeoStudiesDataService, private dataService: DataService) {
    this.studiesSub = this.geoStudiesDataService.todos.subscribe(d => {
      this.studiesTable = d;
      this.studiesTable = this.studiesTable.filter(
        dt => dt.properties.study.state_code == "processed"
      );

      if (d.length > 0) {
        this.dataSource = new MatTableDataSource(this.studiesTable);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  ngOnInit() {
    this.todos = this.geoStudiesDataService.todos;
    this.geoStudiesDataService.loadAll();
  }

  ngOnDestroy() {
    if (this.studiesSub) {
      this.studiesSub.unsubscribe();
    }
  }
}
