<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checked" [value]="0" (change)="selectAll(eve, ensenyasList)" #eve>Ver enseñas preferentes
  </mat-checkbox>
</section>
<section class="full-width clearfix">
  <mat-checkbox [(ngModel)]="checkedOth" [value]="0" (change)="selectOthers(eveOth, ensenyasListOth)" #eveOth>Ver otros QSR e IEO
  </mat-checkbox>
</section>

<section class="full-width clearfix">
  <mat-form-field>
    <mat-label>Enseñas preferentes</mat-label>
    <mat-select [formControl]="ensenyas" (selectionChange)="applyFilterBrand($event.value)" multiple>
      <mat-option *ngFor="let ensenya of ensenyasList" [value]="ensenya">{{
        ensenya
        }}</mat-option>
    </mat-select>
  </mat-form-field>
</section>
<!--
<section class="full-width clearfix">
  <mat-form-field>
    <mat-label>Enseñas QSR IEO</mat-label>
    <mat-select [formControl]="ensenyasOth" (selectionChange)="applyFilterOthers($event.value)" multiple>
      <mat-option *ngFor="let ensenya of ensenyasListOth" [value]="ensenya">{{
        ensenya
        }}</mat-option>
    </mat-select>
  </mat-form-field>
</section>
-->
<section class="full-width clearfix margin-top--xs">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" matSort class="places">
    <!-- ONE Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" (click)="placeSelected(element.id, element.geometry)">
        <p>
          <strong>{{ element.name }}</strong>
        </p>
        <p>{{ element.address }}</p>
        <p>
          {{ element.zip_code }} | {{ element.poblacion }},
          {{ element.province }}
        </p>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</section>