// this module IS NOT in USE

import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";

import { AppGeoRemoveCompetitorsOthersTableComponent } from "./app-geo-remove-competitors-others-table.component";

@NgModule({
  declarations: [AppGeoRemoveCompetitorsOthersTableComponent],
  imports: [SharedModule],
  exports: [AppGeoRemoveCompetitorsOthersTableComponent],
  providers: []
})
export class AppGeoRemoveCompetitorsOthersTableModule {}
