import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class BrickReportTableService {

    constructor() { }

    public getIndexes(bricks) {

        let toSlice = bricks; // copy to slice
        let restLimit = 26; //40; // 40 restaurants per page, change number here.
        let indexes = []; // calculations container, one object per page
        let i = 0; // i for loop
        let totalRest = 0; // total leaves restaurants on tree
        let totalRestUpToLast = 0; // total leaves before quiting loop
        let restCounter = 0; // util for loop, counting restaurant on every cycle, restart the counter inside loop
        let prevIndex = 0; // util for slice

        for (i; i < bricks.length; i++) {
            let restLength = bricks[i].properties.impact_info.length;

            restCounter = restCounter + restLength;
            totalRest = totalRest + restLength;

            if (restCounter > restLimit) {
                indexes.push({ firstIndex: prevIndex, lastIndex: i - 1, restaurants: restCounter - restLength, children: toSlice.slice(prevIndex, i) });
                totalRestUpToLast = totalRestUpToLast + (restCounter - restLength);
                restCounter = 0;
                totalRest = totalRest - restLength;
                prevIndex = i;
                i--
            }

        }
        // last page
        indexes.push({ firstIndex: prevIndex, lastIndex: bricks.length, restaurants: totalRest - totalRestUpToLast, children: toSlice.slice(prevIndex, bricks.length) })

        return indexes;
    }
    public getBrickForPages(bricks) {
        console.log('pages')
        console.log(bricks)
        console.log(this.getIndexes(bricks))
        return this.getIndexes(bricks);
    }

}
