import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class DynamicSelectorsService {
  // Dinamic select OPTIONS from data params
  // nesting columns

  presence_selectors: any[] = [
    {
      id: "mode",
      order: 0,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 0,
      isMultiple: false,
      isParent: true,
      name: "mode",
      options: null,
      selected: ["activity"],
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Modo"
    },

    {
      id: "area",
      order: 1,
      type: "options-from-parent",
      widget: "options-from-parent",
      widget_num: 0,
      isMultiple: false,
      isParent: false,
      name: "area",
      options: null,
      selected: null,
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Área"
    },

    {
      id: "presence_segmentation",
      order: 2,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 2,
      isMultiple: false,
      name: "Segmentación",
      options: [
        {
          val: "activity",
          key: "activity",
          name: "Actividad",
          selected: true,
          children: []
        },
        {
          val: "age",
          key: "age",
          name: "Edad",
          selected: false,
          children: []
        },
        {
          val: "gender",
          key: "gender",
          name: "Género",
          selected: true,
          children: []
        }
      ],
      selected: ["activity"],
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Segmentación"
    },
    {
      id: "date",
      order: 3,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 3,
      isMultiple: false,
      name: "date",
      options: [
        {
          key: "annual",
          selected: false,
          values: []
        },
        {
          key: "months",
          selected: true,
          values: []
        },
        {
          key: "weeks",
          selected: false,
          values: []
        },
        {
          key: "days",
          selected: false,
          values: []
        }
      ],
      selected: ["date"],
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Período"
    },


    {
      id: "months",
      order: 4,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 4,
      isMultiple: false,
      isParent: true,
      name: "months",
      options: null,
      selected: null,
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Mes"
    },
    {
      id: "days",
      order: 5,
      type: "options-from-parent",
      widget: "options-from-parent",
      widget_num: 1,
      isMultiple: false,
      name: "days",
      options: null,
      selected: null,
      display: true, // show or hide the selector
      disabled: null, // enabled or nor enabled selector (visible but not operational)
      enabled: true,
      placeholder: "Día"
    }
  ];
  traffic_selectors = [
    {
      id: "trip_distance",
      order: 0,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 0,
      isMultiple: false,
      isParent: true,
      name: "trip_distance",
      options: null,
      selected: [0],
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Distancia"
    },

    {
      id: "origin_distance",
      order: 1,
      type: "options-from-parent",
      widget: "options-from-parent",
      widget_num: 1,
      isMultiple: false,
      isParent: false,
      name: "origin_distance",
      options: null,
      selected: null,
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Origen"
    },
    {
      id: "destination_distance",
      order: 2,
      type: "options-from-parent",
      widget: "options-from-parent",
      widget_num: 2,
      isMultiple: false,
      isParent: false,
      name: "destination_distance",
      options: null,
      selected: null,
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Destino"
    },
    {
      id: "traffic_segmentation",
      order: 3,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 3,
      isMultiple: false,
      name: "Segmentación",
      options: [
        {
          val: "activity_origin",
          key: "activity_origin",
          name: "Actividad en origen",
          selected: true,
          children: []
        },
        {
          val: "activity_destination",
          key: "activity_destination",
          name: "Actividad en destino",
          selected: true,
          children: []
        },
        {
          val: "age",
          key: "age",
          name: "Edad",
          selected: false,
          children: []
        },
        {
          val: "gender",
          key: "gender",
          name: "Género",
          selected: true,
          children: []
        }
      ],
      selected: ["activity_origin"],
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Segmentación"
    },
    {
      id: "date",
      order: 4,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 4,
      isMultiple: false,
      name: "date",
      options: [
        {
          key: "annual",
          selected: false,
          values: []
        },
        {
          key: "months",
          selected: true,
          values: []
        },
        {
          key: "weeks",
          selected: false,
          values: []
        }
        ,
        {
          key: "days",
          selected: false,
          values: []
        }
      ],
      selected: ["date"],
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Período"
    },
    {
      id: "months",
      order: 5,
      type: "options-from-json",
      widget: "options-from-json",
      widget_num: 5,
      isMultiple: false,
      isParent: true,
      name: "months",
      options: null,
      selected: null,
      display: true,
      disabled: null,
      enabled: true,
      placeholder: "Mes"
    },
    {
      id: "days",
      order: 6,
      type: "options-from-parent",
      widget: "options-from-parent",
      widget_num: 6,
      isMultiple: false,
      name: "days",
      options: null,
      selected: null,
      display: true, // show or hide the selector
      disabled: null, // enabled or nor enabled selector (visible but not operational)
      enabled: true,
      placeholder: "Día"
    }
  ];

  // pass and ID string
  public getDynamicSelectors = (id: string) => {

    // filter from selectors
    switch (id) {
      case "presence_segmentation": {
        let selector = this.presence_selectors.filter(d => d.id !== 'date');
        selector.push(
          {
            id: "date",
            order: 3,
            type: "options-from-json",
            widget: "options-from-json",
            widget_num: 3,
            isMultiple: false,
            name: "date",
            options: [
              {
                key: "annual",
                selected: false,
                values: []
              },
              {
                key: "months",
                selected: true,
                values: []
              },
              {
                key: "days",
                selected: false,
                values: []
              }
            ],
            selected: ["date"],
            display: true,
            disabled: null,
            enabled: true,
            placeholder: "Período"
          }
        )
        return selector;
      } break;


      //case "presence_segmentation":
      case "presence_residence_selectors":
      case "presence_residence_spain":
      case "presence_residence_abroad":
      case "presence_residence_total":
        {

          let selector = this.presence_selectors.filter(d => d.id !== 'date');
          selector.push(
            {
              id: "date",
              order: 3,
              type: "options-from-json",
              widget: "options-from-json",
              widget_num: 3,
              isMultiple: false,
              name: "date",
              options: [
                {
                  key: "annual",
                  selected: false,
                  values: []
                },
                {
                  key: "months",
                  selected: true,
                  values: []
                }
              ],
              selected: ["date"],
              display: true,
              disabled: null,
              enabled: true,
              placeholder: "Período"
            }
          )
          return selector;
        } break;


      case "traffic_segmentation": // segmentation componenn
        {
          let selector = this.traffic_selectors.filter(d => d.id !== 'date');
          selector.push(
            {
              id: "date",
              order: 4,
              type: "options-from-json",
              widget: "options-from-json",
              widget_num: 4,
              isMultiple: false,
              name: "date",
              options: [
                {
                  key: "annual",
                  selected: false,
                  values: []
                },
                {
                  key: "months",
                  selected: true,
                  values: []
                },
                {
                  key: "days",
                  selected: false,
                  values: []
                }
              ],
              selected: ["date"],
              display: true,
              disabled: null,
              enabled: true,
              placeholder: "Período"
            }
          )
          return selector;


        }
        break;
      case "traffic_residence_selectors": // residence selectors
      case "traffic_residence_total": // residence stacked chart
      case "traffic_residence_spain":
      case "traffic_residence_abroad":
        {
          let selector = this.traffic_selectors.filter(d => d.id !== 'date');
          selector.push(
            {
              id: "date",
              order: 4,
              type: "options-from-json",
              widget: "options-from-json",
              widget_num: 4,
              isMultiple: false,
              name: "date",
              options: [
                {
                  key: "annual",
                  selected: false,
                  values: []
                },
                {
                  key: "months",
                  selected: true,
                  values: []
                }
              ],
              selected: ["date"],
              display: true,
              disabled: null,
              enabled: true,
              placeholder: "Período"
            }
          )
          return selector;


        }
        break;

      default:
        return [{ key: "default", value: null }];
    }


  };

  constructor() { }

  ngOnChanges(changes: any) { }
}
