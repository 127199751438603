import { NgModule } from "@angular/core";
import { SharedModule } from "../../../app-modules/app-shared.module";
import { HamburgerRestaurantsModule } from "../../navigation/sidenav-start-left/hamburger-restaurants/hamburger-restaurants.module";

import { SearchboxComponent } from "./searchbox.component";

@NgModule({
  declarations: [SearchboxComponent],
  imports: [SharedModule, HamburgerRestaurantsModule],
  exports: [SearchboxComponent],
  providers: []
})
export class SearchboxModule {}
