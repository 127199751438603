import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "src/app/models/user";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private userLoginUrl =  //`${environment.postmanUrl}/login/`;
  `${environment.postmanHost}${environment.postmanDelimitator}${environment.postmanPort}${environment.postmanContext}/login/`;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private router: Router, private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(tryingUser: User) {
    this.currentUserSubject.next(tryingUser);
  }

  login(username: string, password: string) {
    const tryingUser = new User();
    tryingUser.username = username;
    tryingUser.password = password;

    this.currentUserSubject.next(tryingUser);
    return this.http
      .get<any>(
        this.userLoginUrl
        //   , {
        //   username,
        //   password
        // }
      )
      .pipe(
        map(user => {
          // login successful if there's a user
          if (user) {
            // store user details in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            //this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    this.router.navigate(["/"]);
  }
}
