import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";

import { SidenavBricksComponent } from "./sidenav-bricks.component";
import { UlBricksModule } from "./ul-bricks/ul-bricks.module";

@NgModule({
  declarations: [SidenavBricksComponent],
  imports: [SharedModule, UlBricksModule],
  exports: [SidenavBricksComponent],
  providers: []
})
export class SidenavBricksModule {}
