import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";

import { Options } from "ng5-slider";

// https://angular-slider.github.io/ng5-slider/demos#range-slider
@Component({
  selector: "app-reactive-form-range-slider",
  templateUrl: "./reactive-form-range-slider.component.html",
  styleUrls: ["./reactive-form-range-slider.component.scss"]
})
export class ReactiveFormRangeSliderComponent implements OnInit, OnDestroy {
  @Output()
  eventEmitter = new EventEmitter<Array<number>>();

  @Input()
  params: any;
  @Input()
  emitFirst: boolean;
  options: Options;
  sliderForm: FormGroup;
  id: string;
  title: string;
  @Input() studyData: any;
  constructor() { }

  resetForm(): void {
    this.sliderForm.reset({ sliderControl: this.params.minMax });
    this.eventEmitter.emit(this.sliderForm.value.sliderControl);
  }

  // JS change
  onChange(newVal) {
    this.eventEmitter.emit(this.sliderForm.value.sliderControl);
  }

  ngOnChanges() {
    this.options = this.params.options;
    this.id = this.params.id;
    this.title = this.params.title;

    this.sliderForm = new FormGroup({
      sliderControl: new FormControl(this.params.minMax)
    });

    if (this.emitFirst) {
      this.eventEmitter.emit(this.sliderForm.value.sliderControl); // "0", "1", "2", "3"
    }
  }

  ngOnInit() {

    this.options = this.params.options;
    this.id = this.params.id;
    this.title = this.params.title;
    if(this.studyData.bricks.length >0){
      this.params.minMax = [Math.trunc(this.studyData.bricks[this.studyData.bricks.length-1].properties.sales_info.first_year_sales)-10, Math.trunc(this.studyData.bricks[0].properties.sales_info.first_year_sales)+10];
    }
    this.sliderForm = new FormGroup({
      sliderControl: new FormControl(this.params.minMax)
    });

    if (this.emitFirst) {
      this.eventEmitter.emit(this.sliderForm.value.sliderControl); // "0", "1", "2", "3"
    }

    this.sliderForm.reset({ sliderControl: this.params.minMax });
  }
  ngAfterViewInit() {
    this.options = this.params.options;
    this.id = this.params.id;
    this.title = this.params.title;

    this.sliderForm = new FormGroup({
      sliderControl: new FormControl(this.params.minMax)
    });

    if (this.emitFirst) {
      this.eventEmitter.emit(this.sliderForm.value.sliderControl); // "0", "1", "2", "3"
    }

    this.sliderForm.reset({ sliderControl: this.params.minMax });
  }

  ngOnDestroy() {
    this.sliderForm.reset({ sliderControl: this.params.minMax });
  }

}
