import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-brick-section-one',
  templateUrl: './brick-section-one.component.html',
  styleUrls: ['./brick-section-one.component.scss']
})
export class BrickSectionOneComponent implements OnInit, AfterViewInit {
  @Input() setup: any;
  sectionID: number;

  displayedColumns: string[] = ['translation', 'value'];
  dataSource: any;
  background_img: string;

  constructor() { }

  ngOnInit(): void {
    //this.sectionID = this.setup[i].id;
    //this.background_img = this.setup[i].image;
    this.dataSource = new MatTableDataSource<any>(this.setup);
  }

  ngAfterViewInit() { }

}
