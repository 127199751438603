import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-edit-dialog",
  templateUrl: "./edit-dialog.component.html",
  styleUrls: ["./edit-dialog.component.scss"]
})
export class EditDialogComponent implements OnInit {
  constructor(
    public dialogRefEdit: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {}

  onNoClick(): void {
    this.dialogRefEdit.close();
  }

  ngOnInit() {}
}
