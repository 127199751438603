import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";

import { SidenavMassiveBricksComponent } from "./sidenav-massive-bricks.component";
import { UlMassiveBricksModule } from "./ul-massive-bricks/ul-massive-bricks.module";

@NgModule({
  declarations: [SidenavMassiveBricksComponent],
  imports: [SharedModule, UlMassiveBricksModule],
  exports: [SidenavMassiveBricksComponent],
  providers: []
})
export class SidenavMassiveBricksModule {}
