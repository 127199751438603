import { Injectable } from "@angular/core";
import { D3MainService } from "./d3-main.service";
import * as d3 from "d3";
@Injectable({
  providedIn: "root"
})
export class ReshapeDataService {
  public reshapeDataToRender = (d: any, changes?: Array<any>) => {
    let data: any = {};
    let change;
    let legend;
    let key;
    const priority_months: Array<string> = this.d3MainService.es_ES.months;

    // filter from selectors
    switch (d.id) {
      case "time_sales":
        change = changes[0];
        legend = d.legend.find(c => c.key == change);
        key = legend.key;

        data = d.grossData.filter(c => c.key == key)[0]["data"];

        for (let x = 0; x < data.length; x++) {
          // console.log("looping", x);
          for (let y = 0; y < legend.children.length; y++) {
            //console.log("looping",data[y]);

            if (legend.children[y]["key"] === data[x]["name"]) {
              //console.log("loop", legend.children[y], data[x]);
              data[x]["name"] = legend.children[y]["value"];
              data[x]["tooltipKey"] = legend.children[y]["name"];
            }
          }
        }
        break;

      case "percentages_sales":
        data = d.grossData;
        break;

      case "housing_typology":
        change = changes[0];
        legend = d.legend.bars.find(c => c.key == change);
        key = legend.key;
        data = d.grossData
          .filter(c => c.key == key)[0]
        ["data"].sort((x, y) => {
          return d3.descending(x.zone, y.zone);
        });

        break;

      case "income_per_household":
        data = d.grossData;
        break;

      case "unemployment_rate":
        data = d.grossData;
        break;

      case "presence_segmentation":
        let mode = changes[1]; // driving, foot
        let area = changes[2]; // area minutes
        let segmentation = changes[0]; // activity, age, gender
        let period = changes[3]; // date, period
        let month = changes[4];
        let day = changes[5]; // null or year date (20180205)
        let filtering: any;
        let nestData: any;

        //filtering = d.presence.filter(v => v.pre == mode && v.area == area);
        filtering = d.presence.filter(v => v.mode == mode && v.area == area);

        if (period === "annual") {
          nestData = d3
            .nest()
            .key(() => {
              return "Anual";
            })
            .key(d => d[segmentation])
            .rollup(v => {
              // .
              return d3.sum(v, d => d["stays"]) / (24 * 7 * 4); // Personas día promedio
            })
            .entries(filtering);


        }
        if (period === "months") {
          nestData = d3
            .nest()
            .key(d => d["month"])
            .sortKeys(function (a, b) {
              return priority_months.indexOf(a) - priority_months.indexOf(b);
            })
            .key(d => d[segmentation])
            .rollup(v => {
              // Cálculo: sumar los indicadores de las 24 horas de los 7 días del mes en cuestión y dividir entre (7*24).
              return d3.sum(v, d => d["stays"]) / (7 * 24); // Personas día promedio
            })
            .entries(filtering);
        }

        if (period === "weeks") {
          filtering = filtering.filter(d => d.month == month);

          nestData = d3
            .nest()
            .key(d => d["date"])
            .sortKeys((a, b) => {
              return +a - +b;
            })
            .key(d => d[segmentation])
            .rollup(v => {

              return d3.sum(v, d => d["stays"]) / (7 * 24);
            })
            .entries(filtering);
        }

        if (period === "days") {
          filtering = filtering.filter(d => d.month == month && d.date == day);

          nestData = d3
            .nest()
            .key(d => d["period"])
            .sortKeys((a, b) => {
              return +a - +b;
            })
            .key(d => d[segmentation])
            .rollup(v => {

              return d3.sum(v, d => d["stays"]) / (7 * 24);
            })
            .entries(filtering);
        }

        data = nestData.map(x => {
          let rObj = {};
          rObj["category"] = x.key;
          x.values.map(v => {
            rObj[v.key] = v.value;
          });
          return rObj;
        });

        break;


      // IMPORTANTE PARA PRESENCE Y TRAFFIC
      // en la primera versión teníamos datos de 7 días de una semana
      // FEBRERO: 7 días --> 24 horas
      // ABRIL: 7 días --> 24 horas
      // AGOSTO: 7 días --> 24 horas
      // NOVIEMBRE: 7 días --> 24 horas

      // en la versión actual, se ha realizado un sumatorio en Back End
      // Se sumaron todos los días de la semana, por tanto ahora tenemos
      // FEBRERO: 1 día (sumatorio de los 7, corresponde a la semana) --> 24 horas
      // ABRIL: 1 día --> 24 horas
      // AGOSTO: 1 día --> 24 horas
      // NOVIEMBRE: 1 día --> 24 horas

      case "presence_residence_total":
        let rtmode = changes[0]; // driving, foot
        let rtarea = changes[1]; // area minutes
        let rtperiod = changes[2]; // date, period
        let rtmonth = changes[3] || "Febrero";
        let rtday = changes[4]; // null or year date (20180205)
        let rtfiltering: any;
        let rtnestData: any;

        rtfiltering = d.presence.filter(
          //v => v.pre == rtmode && v.area == rtarea
          v => v.mode == rtmode && v.area == rtarea
        );

        if (rtperiod === "annual") {
          rtnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["nationality"])
            .rollup(v => {
              return d3.sum(v, d => d["stays"]) / (24 * 7 * 4); // 24 horas, 7 días, 4 meses


            })
            .entries(rtfiltering);

          data = this.getStackedDataFromNestedValues(rtnestData[0].values);
        }

        // weeks and months are the same on this json (on week from a month is the total month)
        if (rtperiod === "months" || rtperiod === "weeks" || rtperiod === "days") {
          rtfiltering = rtfiltering.filter(d => d.month == rtmonth);

          rtnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["nationality"])
            .rollup(v => {

              return d3.sum(v, d => d["stays"]) / (24 * 7); // Indicador: Personas*día promedio 24 horas, 7 días
            })
            .entries(rtfiltering);
          data = this.getStackedDataFromNestedValues(rtnestData[0].values);
        }

        break;

      // residence Abroad ha desaparecido, tras el sumatorio de back End de MAYO DE 2023
      case "presence_residence_spain":
      case "presence_residence_abroad":
        let rsmode = changes[0]; // driving, foot
        let rsarea = changes[1]; // area minutes
        let rsperiod = changes[2]; // date, period
        let rsmonth = changes[3];
        let rsday = changes[4]; // null or year date (20180205)
        let rsfiltering: any;
        let rsnestData: any;

        rsfiltering = d.presence.filter(
          //v => v.pre == rsmode && v.area == rsarea
          v => v.mode == rsmode && v.area == rsarea
        );

        // annual
        if (rsperiod === "annual") {
          rsnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["residence"])
            .rollup(v => {
              // Cálculo: sumar los indicadores de las 24 horas de los 28 días y dividir entre (28*24)
              return d3.sum(v, d => (d["stays"]) / (24 * 28)); // Indicador: Personas*día promedio
            })
            .entries(rsfiltering);
          //data = rsnestData[0];
          data = this.getRankingFromNestedValues(rsnestData[0], 20);
        }

        if (rsperiod === "months" || rsperiod === "weeks" || rsperiod === "days") {
          rsfiltering = rsfiltering.filter(d => d.month == rsmonth);

          rsnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["residence"])
            .rollup(v => {
              // Cálculo: sumar los indicadores de las 24 horas de los 7 días del mes en cuestión y dividir entre (7*24).
              return d3.sum(v, d => d["stays"]) / (24 * 7);
            })
            .entries(rsfiltering);
          data = this.getRankingFromNestedValues(rsnestData[0], 20);
        }

        break;

      case "traffic_segmentation":
        let tsdistance = changes[1]; // driving, foot
        let tsorigin = changes[2]; // area minutes
        let tsdestination = changes[3];
        let tssegmentation = changes[0]; // activity, age, gender
        let tsperiod = changes[4]; // date, period
        let tsmonth = changes[5];
        let tsday = changes[6]; // null or year date (20180205)
        let tsfiltering: any;
        let tsnestData: any;

        if (tsdistance == "Todas") {
          tsfiltering = d.traffic;
        }

        if (tsdistance !== "Todas" && tsorigin == "Todas") {
          tsfiltering = d.traffic.filter(v => v.trip_distance == tsdistance);
        }

        if (tsdistance !== "Todas" && tsorigin !== "Todas") {
          tsfiltering = d.traffic.filter(
            v =>
              v.trip_distance == tsdistance &&
              v.origin_distance == tsorigin &&
              v.destination_distance == tsdestination
          );
        }


        if (tsperiod === "annual") {
          tsnestData = d3
            .nest()
            .key(() => {
              return "Anual";
            })
            .key(d => d[tssegmentation])
            .rollup(v => {
              // .
              return d3.sum(v, d => d["transit"]) / (24 * 4 * 7); // 24 horas * 7 días * 4 meses Personas día promedio
            })
            .entries(tsfiltering);

        }

        if (tsperiod === "months") {
          tsnestData = d3
            .nest()
            .key(d => d["month"])
            .sortKeys(function (a, b) {
              return priority_months.indexOf(a) - priority_months.indexOf(b);
            })
            .key(d => d[tssegmentation])
            .rollup(v => {
              // Cálculo: sumar los indicadores de las 24 horas de los 7 días del mes en cuestión y dividir entre 7.
              return d3.sum(v, d => d["transit"]) / (24 * 7); // Tráfico diario promedio
            })
            .entries(tsfiltering);
        }

        if (tsperiod === "weeks") {
          tsfiltering = tsfiltering.filter(d => d.month == tsmonth);

          tsnestData = d3
            .nest()
            .key(d => d["date"])
            .sortKeys((a, b) => {
              return +a - +b;
            })
            .key(d => d[tssegmentation])
            .rollup(v => {
              // Cálculo: sumar los indicadores de las 24 horas del día en cuestión.
              return d3.sum(v, d => d["transit"] / (24 * 7)); // Indicador: Tráfico diario
            })
            .entries(tsfiltering);
        }

        if (tsperiod === "days") {
          tsfiltering = tsfiltering.filter(
            d => d.month == tsmonth && d.date == tsday
          );

          tsnestData = d3
            .nest()
            .key(d => d["period"])
            .sortKeys((a, b) => {
              return +a - +b;
            })
            .key(d => d[tssegmentation])
            .rollup(v => {
              // Cálculo: sumar los indicadores de la hora del día en cuestión.
              return d3.sum(v, d => d["transit"] / (24 * 7)); // Indicador: Tráfico horario
            })
            .entries(tsfiltering);
        }

        data = tsnestData.map(x => {
          let rObj = {};
          rObj["category"] = x.key;
          x.values.map(v => {
            rObj[v.key] = v.value;
          });
          return rObj;
        });
        break;

      case "traffic_residence_total":
        let trdistance = changes[0]; // distance
        let trorigin = changes[1]; // origin
        let trdestination = changes[2]; // destination
        let trperiod = changes[3]; // month, week, day

        let trmonth = changes[4] || "Febrero"; // weeks
        let trday = changes[5]; // days
        let trfiltering: any;
        let trnestData: any;

        if (trdistance == "Todas") {
          trfiltering = d.traffic;
        }

        if (trdistance !== "Todas" && trorigin == "Todas") {
          trfiltering = d.traffic.filter(v => v.trip_distance == trdistance);
        }

        if (trdistance !== "Todas" && trorigin !== "Todas") {
          trfiltering = d.traffic.filter(
            v =>
              v.trip_distance == trdistance &&
              v.origin_distance == trorigin &&
              v.destination_distance == trdestination
          );
        }

        if (trperiod === "annual") {
          trnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["nationality"])
            .rollup(v => {
              //Cálculo: sumar los indicadores de las 24 horas de los 28 días y dividir entre (28*24).
              return d3.sum(v, d => d["transit"]) / (24 * 28); // Indicador: Personas*día promedio
            })
            .entries(trfiltering);

          data = this.getStackedDataFromNestedValues(trnestData[0].values);
        }


        if (trperiod === "months" || trperiod === "weeks" || trperiod === "days") {
          trfiltering = trfiltering.filter(d => d.month == trmonth);
          trnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["nationality"])
            .rollup(v => {
              return d3.sum(v, d => d["transit"]) / (24 * 7); // 1 mes 7 días,Tráfico diario promedio
            })
            .entries(trfiltering);

          data = this.getStackedDataFromNestedValues(trnestData[0].values);
        }

        break;

      case "traffic_residence_spain":
      case "traffic_residence_abroad":
        let trsdistance = changes[0]; // distance
        let trsorigin = changes[1]; // origin
        let trsdestination = changes[2]; // destination
        let trsperiod = changes[3]; // month, week, day
        let trsmonth = changes[4]; // weeks
        let trsday = changes[5]; // days
        let trsfiltering: any;
        let trsnestData: any;

        if (trsdistance == "Todas") {
          trsfiltering = d.traffic;
        }

        if (trsdistance !== "Todas" && trsorigin == "Todas") {
          trsfiltering = d.traffic.filter(v => v.trip_distance == trsdistance);
        }

        if (trsdistance !== "Todas" && trsorigin !== "Todas") {
          trsfiltering = d.traffic.filter(
            v =>
              v.trip_distance == trsdistance &&
              v.origin_distance == trsorigin &&
              v.destination_distance == trsdestination
          );
        }

        if (trsperiod === "annual") {
          trsnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["residence"])
            .rollup(v => {
              // Cálculo: sumar los indicadores de las 24 horas de los 28 días y dividir entre 28.
              return d3.sum(v, d => d["transit"]) / (24 * 28); // Indicador: Tráfico diario promedio
            })
            .entries(trsfiltering);

          data = this.getRankingFromNestedValues(trsnestData[0], 6);
        }

        if (trsperiod === "months" || trsperiod === "weeks" || trsperiod === "days") {
          trsfiltering = trsfiltering.filter(d => d.month == trsmonth);
          trsnestData = d3
            .nest()
            .key(() => {
              return "treemap";
            })
            .key(d => d["residence"])
            .rollup(v => {
              return d3.sum(v, d => d["transit"]) / (24 * 7); // Indicador: Tráfico diario promedio
            })
            .entries(trsfiltering);

          data = this.getRankingFromNestedValues(trsnestData[0], 6);
        }
        break;

      default:
        data = { key: "default", value: null };
    }
    return data;
  };

  public getStackedDataFromNestedValues(nestedValues: Array<any>) {
    let i = 0;
    let nestedLength = nestedValues.length;
    let stackedData = { category: "2019" };

    for (i; i < nestedLength; i++) {
      stackedData[nestedValues[i]["key"]] = nestedValues[i]["value"];
    }
    return [stackedData];
  }
  public getRankingFromNestedValues = (
    nestedValues: any,
    rank: number
  ): Array<any> | undefined => {
    if (nestedValues && nestedValues.values && nestedValues.values.length) {
      let entries = nestedValues.values;
      let sorted_entries = entries.sort((x, y) => {
        return d3.descending(x.value, y.value);
      });
      let top_entries = sorted_entries.slice(0, rank);

      let rest_entries = sorted_entries.slice(rank);
      let rest_entries_l = rest_entries.length;
      let i = 0;
      let rest_entries_sum = [{ key: "Others", value: 0 }];

      for (i; i < rest_entries_l; i++) {
        rest_entries_sum[0]["value"] += rest_entries[i].value;
      }
      let entries_final = top_entries.concat(rest_entries_sum);

      nestedValues["values"] = entries_final.sort(function (x, y) {
        return d3.descending(x.value, y.value);
      });
      return nestedValues;
    }
    return nestedValues;


  };

  constructor(private d3MainService: D3MainService) { }
  ngOnChanges(changes: any) { }
}
