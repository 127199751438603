<nav *ngIf="ready">
  <div role="header" class="selector-header">
    <div class="row">
      <div class="site site-flex">

        <div class="padding-xs">
          <p class="small">SITIO</p>
          <p class="big-number">{{ restaurant.id_mcd }}</p>

        </div>
      </div>
      <div class="logo-flex logo-mcd">
      </div>
      <div class="name-flex">
        <div class="padding-xs">
          <h1 class="big-number">{{ restaurant.info.name }}</h1>
          <p>{{ restaurant.info.address }}</p>
          <p>
            {{ restaurant.info.brand }}
          </p>
        </div>
      </div>

    </div>


  </div>

  <mat-tab-group mat-stretch-tabs mat-align-tabs="center" [(selectedIndex)]="selectedIndexInit"
    (selectedTabChange)="changeDataTab($event)">
    <mat-tab label="Ficha">
      <app-data-ficha></app-data-ficha>
    </mat-tab>

    <!--  *ngIf="readyVentas" -->

    <mat-tab label="Ventas" *ngIf="!isMcdsPA_P5">
      <app-data-ventas *ngIf="readyVentas"></app-data-ventas>
    </mat-tab>
    <mat-tab label="Competencia" *ngIf="!isMcdsPA_P5">
      <app-data-competencia *ngIf="readyCompetencia"></app-data-competencia>
    </mat-tab>

    <mat-tab label="Entorno">
      <app-data-entorno *ngIf="readyEntorno"></app-data-entorno>
    </mat-tab>

    <mat-tab label="Presencia" *ngIf="!isMcdsPA_P5">
      <!-- *ngIf="readyPresencia" -->

      <app-data-presencia *ngIf="readyPresencia"></app-data-presencia>
    </mat-tab>

    <mat-tab label="Tráfico" *ngIf="mcAutoExists">
      <!-- *ngIf="readyTrafico" -->
      <app-data-trafico *ngIf="readyTrafico && mcAutoExists"></app-data-trafico>
    </mat-tab>
  </mat-tab-group>
</nav>