import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../app-modules/app-shared.module";
import { TabBricksComponent } from "./tab-bricks.component";

@NgModule({
  declarations: [TabBricksComponent],
  imports: [SharedModule],
  exports: [TabBricksComponent],
  providers: []
})
export class TabBricksModule {}
