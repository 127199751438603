<article *ngFor="let d of setup">
    <div data-size="A4">

        <article>
            <app-brick-report-header [setup]="setup"></app-brick-report-header>

            <h3>{{ d.title}}</h3>

            <div class="section-one--img" [style.background-image]="'url(' + d.image + ')'"></div>

            <div class="mat-elevation-z0">
                <table mat-table [dataSource]="d.tableSource" class="tStudy">

                    <!-- Position Column -->
                    <ng-container matColumnDef="translation">
                        <th mat-header-cell *matHeaderCellDef> Estudio </th>
                        <td mat-cell *matCellDef="let element "> {{element.translation}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef> Datos </th>
                        <td mat-cell *matCellDef="let element "> {{element.value}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>


            </div>



            <app-brick-report-footer [page_number]="d.id + 1"></app-brick-report-footer>
        </article>
    </div>
</article>