import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { DataService } from "../../../../app-services/data.service";

@Component({
  selector: "app-study-ficha",
  templateUrl: "./study-ficha.component.html",
  styleUrls: ["./study-ficha.component.scss"]
})
export class StudyFichaComponent implements OnInit {
  @Input() studyData: any;
  checkIfServiceExists = this.dataService.checkIfRestaurantServiceExists;
  ready: boolean = false;
  mcCafe: boolean = false;
  mcAuto: boolean = false;
  mcExpress: boolean = false;
  mcKiosko: boolean = false;
  mcDesayunos: boolean = false;
  mcPlayPlace: boolean = false;
  mcParking: boolean = false;
  mcDelivery: boolean = false;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.mcCafe = this.checkIfServiceExists(
      this.studyData.properties.info.mccafe
    );
    this.mcAuto = this.checkIfServiceExists(
      this.studyData.properties.info.mcauto
    );
    this.mcExpress = this.checkIfServiceExists(
      this.studyData.properties.info.mcexpress
    );
    this.mcKiosko = this.checkIfServiceExists(
      this.studyData.properties.info.mckiosko
    );
    this.mcDesayunos = this.checkIfServiceExists(
      this.studyData.properties.info.breakfast
    );
    this.mcDelivery = this.checkIfServiceExists(
      this.studyData.properties.info.delivery
    );
    this.mcPlayPlace = this.checkIfServiceExists(
      this.studyData.properties.info.type_play
    );
    this.mcParking = this.checkIfServiceExists(
      this.studyData.properties.info.data_parking
    );

    this.ready = true;
  }
}
