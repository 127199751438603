import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";
import { HamburgerRestaurantsComponent } from "./hamburger-restaurants.component";

@NgModule({
  declarations: [HamburgerRestaurantsComponent],
  imports: [SharedModule],
  exports: [HamburgerRestaurantsComponent],
  providers: []
})
export class HamburgerRestaurantsModule {}
