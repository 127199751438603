import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrickReportComponent } from './brick-report.component';
import { BrickReportResolverService } from './brick-report-resolver.service';

const routes: Routes = [
  { path: 'brick-report', component: BrickReportComponent, canActivate: [] },
  {
    path: "brick-report/:idStudy",
    component: BrickReportComponent,
    canActivate: [],
    resolve: {
      mappedParams: BrickReportResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", relativeLinkResolution: 'legacy' , useHash: true })],
  bootstrap: [BrickReportComponent],
  exports: [RouterModule],
})
export class BrickReportRoutingModule {
  static components = [BrickReportComponent];
}

