<mat-form-field>
  <mat-label>{{ selectorPlaceholder }}</mat-label>
  <mat-select *ngIf="isMultiple && isVisible" multiple required [formControl]="formControl"
    placeholder="{{ selectorPlaceholder }}" (selectionChange)="onChange($event)">
    <mat-option *ngFor="let pick of pickerOptions" [value]="isParent ? pick : pick.key">{{ pick?.name }}</mat-option>
  </mat-select>

  <mat-select *ngIf="!isMultiple && isVisible" [formControl]="formControl" placeholder="{{ selectorPlaceholder }}"
    (selectionChange)="onChange($event)">
    <mat-option *ngFor="let pick of pickerOptions" [value]="isParent ? pick : pick.key">{{ pick?.dictionaryName
      }}</mat-option>
  </mat-select>
</mat-form-field>