import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataService } from "../../../app-services/data.service";
import { ParamsDataService } from "../../../app-services/d3/params-data.service";
import { ParamsLayoutService } from "../../../app-services/d3/params-layout.service";

@Component({
  selector: "app-data-presencia",
  templateUrl: "./data-presencia.component.html",
  styleUrls: ["./data-presencia.component.scss"]
})
export class DataPresenciaComponent implements OnInit, OnDestroy {
  presence_segmentation_data: Array<any>;
  presence_segmentation: any;
  presence_residence_selectors: any;
  presence_residence_data: Array<any>;

  presence_residence_spain_data: any;
  presence_residence_spain: any;

  presence_residence_abroad_data: any;
  presence_residence_abroad: any;

  presence_residence_total_data: any;
  presence_residence_total: any;

  presence_segmentation_ready: boolean = false;
  presence_residence_ready: boolean = false;
  presence_selectors_ready: boolean = false;

  presence_loading: boolean = true;
  unsubscribe: any;
  unsubscribe2: any;

  constructor(
    private dataService: DataService,
    private paramsDataService: ParamsDataService,
    private paramsLayoutService: ParamsLayoutService
  ) { }

  ngOnInit() {
    this.unsubscribe = this.dataService
      .getMCDfromID(this.dataService.selectedRestaurant)
      .subscribe(residMCD => {
        this.unsubscribe2 = this.dataService
          .getPresenceIdMcd(residMCD["properties"]["id_mcd"])
          .subscribe(d => {
            this.presence_segmentation_data = this.paramsDataService.getDataParams(
              d,
              "presence_segmentation"
            );
            this.presence_segmentation = this.paramsLayoutService.getLayoutParams(
              this.presence_segmentation_data,
              // for reuse data, you need to parse and stringify data
              // JSON.parse(JSON.stringify(this.presence_segmentation_data)),
              "presence_segmentation"
            );


            /* OK PRESENCE SEGMENTATION*/

            // Filter data for  Residence overall with Spain data and abroad data
            this.presence_residence_data = this.paramsDataService.getDataParams(
              d,
              "presence_residence"
            );

            const parse_residence_data = JSON.parse(
              JSON.stringify(this.presence_residence_data)
            );

            // filter Layout for SELECTORS
            this.presence_residence_selectors = this.paramsLayoutService.getLayoutParams(
              JSON.parse(JSON.stringify(this.presence_residence_data)),
              //this.presence_residence_data,
              "presence_residence_selectors"
            );



            this.presence_residence_total = this.paramsLayoutService.getLayoutParams(
              JSON.parse(JSON.stringify(this.presence_residence_data)),
              //this.presence_residence_data,
              "presence_residence_total"
            );

            // Filter data for TREEMAP only SPAIn
            this.presence_residence_spain_data = this.paramsDataService.getDataParams(
              this.presence_residence_data,
              "presence_residence_spain"
            );
            this.presence_residence_spain = this.paramsLayoutService.getLayoutParams(
              this.presence_residence_spain_data,
              "presence_residence_spain"
            );

            // Filter data for TREEMAP only ABROAD data
            this.presence_residence_abroad_data = this.paramsDataService.getDataParams(
              this.presence_residence_data,
              "presence_residence_abroad"
            );
            this.presence_residence_abroad = this.paramsLayoutService.getLayoutParams(
              this.presence_residence_abroad_data,
              "presence_residence_abroad"
            );
            this.presence_segmentation_ready = true;
            this.presence_selectors_ready = true;
            this.presence_residence_ready = true;
            this.presence_loading = false;
          });
      });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
