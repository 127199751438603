import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app-modules/app-shared.module';
import { BrickReportComponent } from './brick-report.component';
import { BrickReportRoutingModule } from './brick-report.routing.module';
import { BrickReportService } from './brick-report.service';
import { BrickReportTableService } from './brick-report-loader/brick-report-table.service';
import { BrickReportMapService } from "./brick-report-map/brick-report-map.service";
import { BrickSectionZeroComponent } from './brick-section-zero/brick-section-zero.component';
import { BrickSectionOneComponent } from './brick-section-one/brick-section-one.component';
import { BrickSectionTwoComponent } from './brick-section-two/brick-section-two.component';


import { BrickReportLoaderComponent } from './brick-report-loader/brick-report-loader.component';
import { BrickReportMapComponent } from './brick-report-map/brick-report-map.component';
import { BrickReportFooterComponent } from './brick-report-footer/brick-report-footer.component';
import { BrickReportHeaderComponent } from './brick-report-header/brick-report-header.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
  declarations:
    [
      BrickReportComponent,
      BrickReportLoaderComponent,
      BrickReportFooterComponent,
      BrickReportHeaderComponent,
      BrickSectionZeroComponent,
      BrickSectionOneComponent,
      BrickSectionTwoComponent,
      BrickReportMapComponent
    ],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,
    BrickReportRoutingModule
  ],
  providers: [ {provide: LocationStrategy, useClass: HashLocationStrategy}, BrickReportService, BrickReportMapService, BrickReportTableService ]
})
export class BrickReportModule { }
