import { Component, OnChanges } from "@angular/core";
import { OnInit } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";

// table competencia
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-geo-remove-commerces-table",
  templateUrl: "./geo-remove-commerces-table.component.html",
  styleUrls: ["./geo-remove-commerces-table.component.scss"]
})
export class GeoRemoveCommercesTableComponent implements OnChanges {
  @Input()
  inputCommerce: string;
  @Output() commercesRemoved: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  data: any;
  dataSource: any;
  dataSourceOtros: any;
  selection: any;
  image_route = "../../../../assets/img/marker_icons/";
  displayedColumns: string[] = ["select" ,"name","address", "category","mall","sup_m2","distance_tostudy"]//["name", "commerce", "number", "edited"];
  checkedCompetitors: Array<any>;
  Competitors: any;
  displayedColumnsOtros: string[] = ["name", "number"];

  //@Output() eventEmitter: EventEmitter<any> = new EventEmitter();

  onDeleteCommerce(ev: any, element: any) {
    element.num = element.num - ev.target.value;
  //  this.commercesEdited.emit(element);
  
  }

  onAddCommerce(ev: any, element: any) {
    element.num = element.num + ev.target.value;
   // this.commercesEdited.emit(element);
  }

  onEditedCommerce(ev: any, element: any) {
    element.num_mod = ev.target.value;
    //this.dataSource;
   // this.commercesEdited.emit(element);
    //this.dataSource.sort = this.sort;
  }


   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  isSomeSelected() {
    this.commercesRemoved.emit(this.selection.selected);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));

    this.commercesRemoved.emit(this.selection.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
  }

  checkUrl = (url: any) => {
    try {
      let reader = new XMLHttpRequest();
      reader.open("GET", url, false);
      reader.send();
      if (reader.status == 404) {
        return false;
      } else {
        return true;
      }
    } catch (error) {}
  };	

  constructor() {}

  ngOnInit() {
    this.data = this.inputCommerce;

    if(this.data){
      let tot = this.data;
      this.data = this.data.filter(dt =>
        dt.properties.priority == true); 
        let  nonPriority = tot.filter(dt =>
          dt.properties.priority == false);
          let otrosComer  = 
          [{name : 'Otros',
          num: nonPriority.length
        }];
          this.dataSourceOtros = new MatTableDataSource<any>(otrosComer);

          if(this.data.length<1 || nonPriority.length<1){
            this.data = this.inputCommerce
          }

      this.data.forEach(element => {
        let idImgIcon = element.properties.info.icono;
          let urlIcon = this.image_route +"commerces_markers/"+idImgIcon+".png";
          var checkedurl = this.checkUrl(urlIcon);
          if (!checkedurl) {
            urlIcon = this.image_route + "helpers/commerces.png";
          }
        element.url_icono = urlIcon; 
        element.name = element.properties.info.id_brand;
        element.address = element.properties.info.address;
        element.category = element.properties.info.category;
        element.mall = element.properties.info.mall;
        element.sup_m2 = element.properties.info.sup_m2;
        element.distance_tostudy =  element.properties.distance_tostudy;
        element.url_icono = urlIcon; 
      });
    }
    
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.selection = new SelectionModel<any>(true, []);
    //this.dataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.data = this.inputCommerce;
    if(this.data){
      let tot = this.data;
      this.data = this.data.filter(dt =>
        dt.properties.priority == true); 
        let  nonPriority = tot.filter(dt =>
          dt.properties.priority == false);
          let otrosComer  = 
          [{name : 'Otros',
          num: nonPriority.length
        }];
          this.dataSourceOtros = new MatTableDataSource<any>(otrosComer);

          if(this.data.length<1 || nonPriority.length<1){
            this.data = this.inputCommerce
          }

      this.data.forEach(element => {
        let idImgIcon = element.properties.info.icono;
          let urlIcon = this.image_route +"commerces_markers/"+idImgIcon+".png";
          var checkedurl = this.checkUrl(urlIcon);
          if (!checkedurl) {
            urlIcon = this.image_route + "helpers/commerces.png";
          }
        element.name = element.properties.info.id_brand;
        element.address = element.properties.info.address;
        element.category = element.properties.info.category;
        element.mall = element.properties.info.mall;
        element.sup_m2 = element.properties.info.sup_m2;

        element.distance_tostudy =  element.properties.distance_tostudy;
        element.url_icono = urlIcon; 
        
      });
    }
    
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource.sort = this.sort;
  }
}
