import { NgModule } from "@angular/core";
import { SharedModule } from "../../app-modules/app-shared.module";
import { GeoStudiesRoutingModule } from "./geo-studies-routing.module";
import { GeoStudiesTableModule } from "./geo-studies-table/geo-studies-table.module";

@NgModule({
    declarations: [GeoStudiesRoutingModule.components],
    imports: [SharedModule, GeoStudiesRoutingModule, GeoStudiesTableModule],
    exports: [],
    providers: []
})
export class GeoStudiesModule {}
