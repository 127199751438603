import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { DataService } from "../../../app-services/data.service";

@Component({
  selector: "app-sidenav-end-right",
  templateUrl: "./sidenav-end-right.component.html",
  styleUrls: ["./sidenav-end-right.component.scss"]
})
export class SidenavEndRightComponent implements OnInit {
  @Input()
  showSidenavEndRight;

  @Input()
  isPrescriptive;

  @Input()
  isMassivePrescriptive;

  constructor(private dataService: DataService) {}

  ngOnInit() {}

  triggerToggleSideNavs: any = () => {
    this.dataService.serviceToggleSideNavs({
      SidenavStartLeftState: false,
      SidenavEndRightState: false
    });
  };
}
