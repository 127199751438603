import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "../../../../../app-services/data.service";


@Component({
  selector: "app-tab-restaurants",
  templateUrl: "./tab-restaurants.component.html",
  styleUrls: ["./tab-restaurants.component.scss"]
})
export class TabRestaurantsComponent implements OnInit, OnDestroy {
  dataSource: any;
  checked = true;
  checkedClosed = false;
  checkedPlan5 = false;
  checkedProxApert = false;
  displayedColumns: string[] = ["name"];
  restaurants: any;
  restaurantsT: any;
  unsubscribe: any;

  checkedInflu = false;
  checkedDelivery = false;

  kindBuildingList: any;
  franchisedList: any;
  dataKB: any;

  selectedOptions= new FormControl();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('allSelected') private allSelected: MatOption;

  applyFilter(filterValue: string) {
    if (filterValue == "") {
      this.checked = true;
    } else {
      this.checked = false;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.restaurantsT = this.dataSource.filteredData;
    this.dataService.getFilteredRestaurants(this.restaurantsT); 
  }

  filterMcd( obj: any , status: any) {
    if(this.checked && this.checkedClosed){
      return obj
    }else{
      if(this.checked){
        return obj.filter(word => word.status == status)
      }else{
        return obj.filter(word => word.status == status)
      }
    }   
  }

  typesKindBuilding(obj: any ){
    return obj.map(item => item.kind_building)
    .filter((value, index, self) => self.indexOf(value) === index)
  }

  restaurantSelected(elementId: string, geometry: string) {
    this.dataService.getZoomOnRestaurant(geometry);
  }

  filterKindBuilding(filterValue: any) {
    this.checked = false;
    let restaurants = this.filterMcd(this.restaurants,"Abierto");
    let r=[];
    let r1;
      filterValue.every(key => {
        r1 =  restaurants.filter(item => {
          return item.kind_building == key
        });
        r=[].concat(r, r1);    
        return r;
      });
      this.dataSource = new MatTableDataSource(r);
      this.dataSource.paginator = this.paginator;
      this.dataKB = r;
      this.franchisedList = this.loadFranchised(r);
      this.allSelected.select();
      this.toggleAllSelection(this.franchisedList)
      this.dataService.getFilteredRestaurants(r);
  }

  loadFranchised(obj: any){
    return obj.map(item => item.franchised)
    .filter((value, index, self) => self.indexOf(value) === index)
  }

  filterFranchised(filterValue: any){
    let restaurants = this.dataKB;
    let r=[];
    let r1;
     filterValue.every(key => {
       r1 =  restaurants.filter(item => {
         return item.franchised == key
       });
       r=[].concat(r, r1);    
       return r;
     });
     this.dataSource = new MatTableDataSource(r);
     this.dataSource.paginator = this.paginator;
     this.dataService.getFilteredRestaurants(r);
  }

  showAll: any = () => {
    this.dataService.showAllRestaurants(this.checked);
    if (!this.checked) {
      this.dataSource = new MatTableDataSource(this.filterMcd(this.restaurants, "Cerrado"));
    } else {
      this.dataSource = new MatTableDataSource(this.filterMcd(this.restaurants, "Abierto"));
    }
    this.dataSource.paginator = this.paginator;
  };

  showClosed: any = () => {
    this.dataSource = new MatTableDataSource(this.filterMcd(this.restaurants, "Cerrado"));
    this.dataSource.paginator = this.paginator;
    this.dataService.showClosedRestaurants(this.checkedClosed);
  };

  showPlan5: any = () => {
    this.dataSource = new MatTableDataSource(this.filterMcd(this.restaurants, "Plan 5 años"));
    this.dataSource.paginator = this.paginator;
    this.dataService.showPlan5Restaurants(this.checkedPlan5);
  };

  showProxApert: any = () => {
    this.dataSource = new MatTableDataSource(this.filterMcd(this.restaurants, "Próxima apertura"));
    this.dataSource.paginator = this.paginator;
    this.dataService.showProxApertRestaurants(this.checkedProxApert);
  };


  showDelivery: any = () => {
    this.dataService.showDeliveryRestaurant(this.checkedDelivery);
  };

  showInfluence: any = () => {
    this.dataService.showInfluence(this.checkedInflu);
  };

  toggleAllSelection(values) {
    if (this.allSelected.selected) {
     this.filterFranchised(values);
     values.push(0)
      this.selectedOptions = new FormControl(values);
      this.allSelected.select();
    } else {
     this.selectedOptions = new FormControl();
      this.allSelected.deselect();
      this.filterFranchised([]);
    }


  }
  


  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.unsubscribe = this.dataService
      .getPlacesMcd()
      .subscribe((result: any) => {
        this.restaurants = result;
        this.restaurants = this.restaurants.map(element => {
          element.properties.info.id = element.properties.ID_;
          element.properties.info.id_mcd = element.properties.id_mcd;
          element.properties.info.geometry = element.geometry;
          element.properties.info.properties = element.properties;
          return element.properties.info;
        });
        let restaurants = this.filterMcd(this.restaurants,"Abierto")
        this.kindBuildingList = this.typesKindBuilding(restaurants);
        this.dataSource = new MatTableDataSource(this.filterMcd(this.restaurants,"Abierto"));
        this.dataSource.paginator = this.paginator;
      });
  }

  ngOnChanges() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
