<!-- <table
  mat-table
  matSort
  [dataSource]="dataSource"
  class="mat-elevation-z0 competitors"
> -->
<!-- <div class="example-container mat-elevation-z0"> -->
<!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort> -->
<mat-table
  [dataSource]="dataSource"
  matSort
  class=" mat-elevation-z0 competitors"
>
  <!-- <mat-table [dataSource]="dataSource" matSort> -->
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        (keydown.enter)="$event.preventDefault()"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (keydown.enter)="$event.preventDefault()"
        (change)="$event ? selection.toggle(row) : null; isSomeSelected()"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Eliminar restaurante
    </th>
    <td mat-cell *matCellDef="let element">
      <!--element.properties.info.name    
      {{ element.nombre }}
      -->
      <img
        src="{{ element.url_icono }}"
        alt=""
        height="32"
        width="32"
        style="margin-right: 8px"
      />{{ element.name }}
    </td>
  </ng-container>

  
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
    <td mat-cell *matCellDef="let element">
      {{ element.status
      }}
    </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Dirección</th>
    <td mat-cell *matCellDef="let element">
      {{ element.address
      }}<!--element.properties.info.address-->
    </td>
  </ng-container>

  <ng-container matColumnDef="distance_tostudy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Distancia (metros)</th>
    <td mat-cell *matCellDef="let element1">
      {{ element1.distance_tostudy }}
    </td>
  </ng-container>

  


  <!-- Name Column 
  <ng-container matColumnDef="area">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Área</th>
    <td mat-cell *matCellDef="let element">
      {{ element.id_brand }}
    </td>
  </ng-container>
-->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="selection.toggle(row)"
  ></tr>
  <!-- </table> -->
  <!-- </mat-table>
</div> -->
  <!-- </table> -->
</mat-table>
