
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../app-modules/app-shared.module";

import { AddDialogMcdBoxLocatorComponent } from "./add-dialog-mcd-box-locator.component";

import { McdGeoLocatorMapModule } from "../../../../app-widgets/cartography/mcdGeoLocatorMap/mcdGeoLocatorMap.module";
import { SearchboxModule } from "../../../../app-widgets/form-controls/searchbox/searchbox.module";


@NgModule({
  declarations: [AddDialogMcdBoxLocatorComponent ],
  imports: [SharedModule, McdGeoLocatorMapModule,SearchboxModule],
  exports: [AddDialogMcdBoxLocatorComponent],
  providers: []
})
export class AddDialogMcdBoxlocatorModule {}

