<aside>
  <div class="searchbox-container" role="search">
    <app-hamburger-restaurants></app-hamburger-restaurants>
    <div class="searchbox searchbox-shadow">
      <form class="searchbox-form-wrapper">
        <mat-form-field>
          <input
            matInput
            #message
            maxlength="256"
            autofocus="autofocus"
            value=""
            aria-label="Buscar en Google Maps"
            autocomplete="off"
            id="searchboxinput"
            name="searchboxinputq"
            class="tactile-searchbox-input"
            aria-haspopup="false"
            role="combobox"
            aria-autocomplete="list"
            dir="ltr"
            spellcheck="false"
            [(ngModel)]="searchQuery"
            (keydown.enter)="searchboxDescriptive()"
          />
        </mat-form-field>
      </form>
      <div class="searchbox-searchbutton-container">
        <button
          class="searchbox-searchbutton"
          (click)="searchboxDescriptive()"
          
        >
          <mat-icon aria-label="Buscar">search</mat-icon>
        </button>
      </div>
    </div>
  </div>
</aside>
