import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { GeoMapComponent } from "./geo-map.component";
import { GeoMapResolverService } from "./geo-map-resolver.service";

const routes: Routes = [
  {
    path: "predictive/map",
    component: GeoMapComponent,
    canActivate: []
  },
  {
    path: "predictive/map/study/:idStudy",
    component: GeoMapComponent,
    resolve: {
      mappedParams: GeoMapResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeoMapRoutingModule {
  static components = [GeoMapComponent];
}
