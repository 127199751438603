import { NgModule } from "@angular/core";
import { SharedModule } from "../app-modules/app-shared.module";

import { DownloadsPageRoutingModule } from "./downloads-page-routing.module";

@NgModule({
  imports: [DownloadsPageRoutingModule, SharedModule],
  declarations: [DownloadsPageRoutingModule.components],
  providers: []
})
export class DownloadsPageModule {}
