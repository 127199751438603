import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";

@Component({
  selector: "app-services-icons",
  templateUrl: "./services-icons.component.html",
  styleUrls: ["./services-icons.component.scss"]
})
export class ServicesIconsComponent implements OnInit {
  @Input() mcCafe: boolean;
  @Input() mcAuto: boolean;
  @Input() mcExpress: boolean;
  @Input() mcKiosko: boolean;
  @Input() mcDesayunos: boolean;
  @Input() mcPlayPlace: boolean;
  @Input() mcParking: boolean;
  @Input() mcDelivery: boolean;

  constructor() {}

  ngOnInit() {}
}
