import {Component, OnDestroy} from "@angular/core";
import {OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "../../../app-widgets/popup-modals/delete-dialog/delete-dialog.component";
import {EditDialogComponent} from "../../../app-widgets/popup-modals/edit-dialog/edit-dialog.component";
import {Observable, Subject} from "rxjs";
import {ViewChild} from "@angular/core";
import {animate} from "@angular/animations";
import {state} from "@angular/animations";
import {style} from "@angular/animations";
import {transition} from "@angular/animations";
import {trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Study} from "../../../../assets/models/study.model";
import {GeoStudiesDataService} from "../../../app-services/geo-studies-data.service";
import {TableStudyActionsService} from "../../../app-services/table-study-actions.service";
import {Todo} from "../../../app-services/geo-studies-data.service";
import {Router, RouterEvent, NavigationEnd} from "@angular/router";
import {
    EditDialogPartialPredictiveStudyComponent
} from "src/app/app-widgets/popup-modals/edit-dialog-partial-predictive-study/edit-dialog-partial-predictive-study.component";

@Component({
    selector: "app-geo-studies-table",
    templateUrl: "./geo-studies-table.component.html",
    styleUrls: ["./geo-studies-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state(
                "collapsed, void",
                style({height: "0px", minHeight: "0", display: "none"})
            ),
            state("expanded", style({height: "*"})),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            )
        ])
    ]
})
export class GeoStudiesTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    todos: Observable<Todo[]>;
    studiesSub: any;
    studiesTable: any;
    dataSource: any;

    userAuthorized: boolean = false;

    unsubscribe: any;
    unsubscribe2: any;
    interval: any;
    public destroyed = new Subject<any>();

    columnsToDisplay: string[] = [
        "id",
        "version",
        "nombre",
        "dirección",
        "fecha creacion",
        "fecha modificado",
        "estado"
    ];
    expandedStudy: Study | null;
    index: any;

    downloadStudy: any;
    seeOnline: any;
    seeMap: any;
    editStudy: any;
    private updateInterval: any;

    constructor(
        private geoStudiesDataService: GeoStudiesDataService,
        public dialog: MatDialog,
        private tableStudyActionsService: TableStudyActionsService,
        private router: Router
    ) {
        /*
        this.studiesSub = this.geoStudiesDataService.todos.subscribe(d => {
          this.studiesTable = d.map(el => {
            let kb = el["properties"]["info"]["kind_building"];
            if(kb =="instore"){
              el["properties"]["info"]["kind_building"] = "Instore"
            }else if(kb == "mall"){ 
              el["properties"]["info"]["kind_building"] = "Mall"
            }else if(kb == "mallnuevodesarrollocomercial"){ 
              el["properties"]["info"]["kind_building"] = "Mall nuevo desarrollo centro comercial"
            }else if(kb == "freestandingnuevodesarrollocomercial"){ 
              el["properties"]["info"]["kind_building"] = "FreeStanding nuevo desarrollo centro comercial"
            }else if(kb == "freestandingzonacomercialexistente" || kb == "freestandingcomercial"){ 
              el["properties"]["info"]["kind_building"] = "FreeStanding zona comercial existente"
            }else if(kb == "freestandingzonaresidencial" || kb == "freestandingresidencial"){ 
              el["properties"]["info"]["kind_building"] = "FreeStanding zona residencial"
            }else if(kb == "freestanding"){
              if(el["properties"].data_model.restaurant_type == "freestandingcomercial"){
                el["properties"]["info"]["kind_building"] = "Free Standing Comercial"
              }else if(el["properties"].data_model.restaurant_type == "freestandingresidencial"){
                el["properties"]["info"]["kind_building"] = "Free Standing Residencial"
              }
            }
            
            
            
            
            return el["properties"]["info"];
          });
          if (d.length > 0) {
            this.dataSource = new MatTableDataSource(this.studiesTable);
            // to filter just id column
            this.dataSource.filterPredicate = (data: any, filter: string) => {          
              return (
                String(data.id)
                  .toLowerCase()
                  .includes(filter) ||
                String(data.name)
                  .toLowerCase()
                  .includes(filter) ||
                String(data.address)
                  .toLowerCase()
                  .includes(filter)
              );
            };
    
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
        });
        */
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: "320px",
            data:
                "Esta acción no podrá deshacerse. ¿Está seguro que quiere eliminar el estudio?"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // delete study:
                this.unsubscribe2 = this.geoStudiesDataService
                    .deleteStudy(this.expandedStudy)
                    .subscribe(
                        response => {
                            console.log("deleteStudy Response is: ", response);
                            this.geoStudiesDataService.loadAll();
                        },
                        error => {
                            console.error("deleteStudy An error occurred, ", error);
                        }
                    );
            }
        });
    }

    openDialogEdit(expandedStudy): void {
        if (expandedStudy.properties.study.state_code == "processed") {
            this.tableStudyActionsService.seeOnline(expandedStudy);
        } else {
            const dialogRefEdit = this.dialog.open(EditDialogComponent, {
                width: "320px",
                data: "No está disponible el informe. Espere a estado Disponible."
            });

            dialogRefEdit.afterClosed().subscribe(result => {
                if (result) {
                    console.log("Yes clicked", this.expandedStudy);
                }
            });
        }
    }

    openDialogEditPartial(expandedStudy): void {
        if (expandedStudy.properties.study.state_code == "processed") {
            const dialogRefEdit = this.dialog.open(EditDialogPartialPredictiveStudyComponent, {
                width: "500px",
                maxHeight: '620px',
                maxWidth: "500px",
                data: expandedStudy
            });


        } else {
            const dialogRefEdit = this.dialog.open(EditDialogComponent, {
                width: "320px",
                data: "No están disponibles los datos. Espere a estado Disponible."
            });

            dialogRefEdit.afterClosed().subscribe(result => {
                if (result) {
                    console.log("Yes clicked", expandedStudy);
                }
            });
        }
    }

    ngOnInit() {
        this.downloadStudy = this.tableStudyActionsService.downloadStudy;
        this.seeOnline = this.tableStudyActionsService.seeOnline;
        this.seeMap = this.tableStudyActionsService.seeMap;
        this.editStudy = this.tableStudyActionsService.editStudy;

        this.todos = this.geoStudiesDataService.todos;
        this.unsubscribe = this.geoStudiesDataService.loadAll();
        this.updateInterval = setInterval(() => {
            this.geoStudiesDataService.updateLast();
        }, 120000);
        this.unsubscribe = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.geoStudiesDataService.loadAll();
            }
        });

        const user = JSON.parse(localStorage.getItem("currentUser"));
        if (user) {
            //let type = typeof user.group;
            // if(type == "string"){
            if (user.group_a != "analyst") {
                this.userAuthorized = false;
            } else {
                this.userAuthorized = true;
            }
            /*  }else{
                if (!user.group.includes("analyst") ) {
                  this.userAuthorized = false;
                } else {
                  this.userAuthorized = true;
                }
              }*/

        }


        this.studiesSub = this.geoStudiesDataService.todos.subscribe(d => {
            this.studiesTable = d.map(el => {
                let kb = el["properties"]["info"]["kind_building"];
                if (kb == "instore") {
                    el["properties"]["info"]["kind_building"] = "Instore"
                } else if (kb == "mall") {
                    el["properties"]["info"]["kind_building"] = "Mall"
                } else if (kb == "mallnuevodesarrollocomercial") {
                    el["properties"]["info"]["kind_building"] = "Mall nuevo desarrollo centro comercial"
                } else if (kb == "freestandingnuevodesarrollocomercial") {
                    el["properties"]["info"]["kind_building"] = "FreeStanding nuevo desarrollo centro comercial"
                } else if (kb == "freestandingzonacomercialexistente" || kb == "freestandingcomercial") {
                    el["properties"]["info"]["kind_building"] = "FreeStanding zona comercial existente"
                } else if (kb == "freestandingzonaresidencial" || kb == "freestandingresidencial") {
                    el["properties"]["info"]["kind_building"] = "FreeStanding zona residencial"
                } else if (kb == "freestanding") {
                    if (el["properties"].data_model.restaurant_type == "freestandingcomercial") {
                        el["properties"]["info"]["kind_building"] = "Free Standing Comercial"
                    } else if (el["properties"].data_model.restaurant_type == "freestandingresidencial") {
                        el["properties"]["info"]["kind_building"] = "Free Standing Residencial"
                    }
                }


                return el["properties"]["info"];
            });
            if (d.length > 0) {
                this.dataSource = new MatTableDataSource(this.studiesTable);
                // to filter just id column
                this.dataSource.filterPredicate = (data: any, filter: string) => {
                    return (
                        String(data.id)
                            .toLowerCase()
                            .includes(filter) ||
                        String(data.name)
                            .toLowerCase()
                            .includes(filter) ||
                        String(data.address)
                            .toLowerCase()
                            .includes(filter)
                    );
                };

                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            }
        });
    }

    ngOnDestroy() {
        clearInterval(this.updateInterval);
        if (this.unsubscribe) {
            this.unsubscribe.unsubscribe();
        }
        if (this.unsubscribe2) {
            this.unsubscribe2.unsubscribe();
        }
        if (this.studiesSub) {
            this.studiesSub.unsubscribe();
        }
    }
}
