<section class="full-width clearfix padding-xs margin-top--s">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <h2 class="tab-headline">TRÁFICO MEDIO DIARIO POR RESIDENCIA</h2>
      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="traffic_residence_selectors" widget_num="0"
        [emitFirst]="true" (eventEmitter)="changeDistance($event)" *ngIf="traffic_selectors_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="traffic_residence_selectors"
        [childrenOptions]="originOptions" widget_num="1" [emitFirst]="true" (eventEmitter)="changeOrigin($event)"
        *ngIf="traffic_selectors_ready && displayOrigin">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>
    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent style="width: 100%;" [params]="traffic_residence_selectors"
        [childrenOptions]="destinationOptions" widget_num="2" [emitFirst]="true"
        (eventEmitter)="changeDestination($event)" *ngIf="traffic_selectors_ready && displayDestination">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json style="width: 100%;" [params]="traffic_residence_selectors" widget_num="4"
        [emitFirst]="true" (eventEmitter)="changePeriod($event)" *ngIf="traffic_selectors_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-json [ngClass]="{'hidden': this.displayMonths === false }" style="width: 100%;"
        [params]="traffic_residence_selectors" widget_num="5" [emitFirst]="true" (eventEmitter)="changeMonth($event)"
        *ngIf="traffic_selectors_ready">
      </app-options-from-json>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
      <app-options-from-parent [ngClass]="{'hidden': this.displayDays === false }" style="width: 100%;"
        [params]="traffic_residence_selectors" [childrenOptions]="dayOptions" widget_num="6" [emitFirst]="true"
        (eventEmitter)="changeDay($event)" *ngIf="traffic_selectors_ready">
      </app-options-from-parent>
      <div class="margin-top--s"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
      <h2 class="tab-headline">Residentes</h2>
      <div class="full-width clearfix">
        <app-stacked-bar *ngIf="traffic_residence_ready && this.traffic_selectors_ready" style="width: 100%;"
          [params]="traffic_residence_total" chartId="traffic_residence_total" chartSize="xs"
          [firstFilterValue]="selectedDistance" [secondFilterValue]="selectedOrigin"
          [thirdFilterValue]="selectedDestination" [fourFilterValue]="selectedPeriod" [fifthFilterValue]="selectedMonth"
          [sixthFilterValue]="selectedDay"></app-stacked-bar>
      </div>
      <div class="margin-top--s"></div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
      <h2 class="tab-headline">Nacionales</h2>
      <div class="full-width clearfix">
        <app-treemap *ngIf="traffic_residence_ready && this.traffic_selectors_ready" style="width: 100%;"
          [params]="traffic_residence_spain" [firstFilterValue]="selectedDistance" [secondFilterValue]="selectedOrigin"
          [thirdFilterValue]="selectedDestination" [fourFilterValue]="selectedPeriod" [fifthFilterValue]="selectedMonth"
          [sixthFilterValue]="selectedDay" chartId="traffic_residence_spain" chartSize="regular"></app-treemap>
      </div>
      <div class="margin-top--s"></div>
    </div>


    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
      <!-- <h2 class="tab-headline">Extranjeros</h2> -->
      <div class="full-width clearfix">
        <!-- <app-treemap
          style="width: 100%;"
          [params]="traffic_residence_abroad"
          [firstFilterValue]="selectedDistance"
          [secondFilterValue]="selectedOrigin"
          [thirdFilterValue]="selectedDestination"
          [fourFilterValue]="selectedPeriod"
          [fifthFilterValue]="selectedMonth"
          [sixthFilterValue]="selectedDay"
          chartId="traffic_residence_abroad"
          chartSize="regular"
          *ngIf="traffic_residence_ready"
        ></app-treemap> -->
      </div>

      <div class="margin-top--s"></div>
    </div>
  </div>
</section>