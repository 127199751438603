import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { GeoStudiesComponent } from "./geo-studies.component";

const routes: Routes = [
  {
    path: "predictive/studies",
    component: GeoStudiesComponent,
    canActivate: [],
    runGuardsAndResolvers: "always"
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class GeoStudiesRoutingModule {
  static components = [GeoStudiesComponent];
}
