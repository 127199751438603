import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "src/app/app-services/data.service";
import { GeoStudiesPrescriptiveDataService } from "../../../../../app-services/geo-studies-prescriptive-data.service"

@Component({
  selector: 'app-ul-bricks',
  templateUrl: './ul-bricks.component.html',
  styleUrls: ['./ul-bricks.component.scss']
})
export class UlBricksComponent implements OnInit {

  constructor(private dataService: DataService,
    private geoStudiesPrescriptiveDataService: GeoStudiesPrescriptiveDataService) { }
  dataSource: any;

  ready: boolean = false;

  unsubscribe: any;
  studyData: any;
  unsubscribe2: any;
  studyDataVoid: any;

  displayedColumns: string[] = ["nombre"];

  ngOnInit() {


    this.unsubscribe = this.dataService.studySelectedPrescriptiveChanged$.subscribe(
      id => {
        this.studyData = this.studyDataVoid;
        if (id !== undefined) {
          this.unsubscribe2 = this.geoStudiesPrescriptiveDataService
            .getBasicInfoStudy(id)
            .subscribe(d => {
              this.studyData = d;
              this.ready = true;
              this.dataSource = new MatTableDataSource(this.studyData);
            });
        }
      }
    );

    


  }

}
