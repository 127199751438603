<section class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

        <h1 class="h1-page--title">
          Nuevo estudio predictivo
        </h1>
      </div>
    </div>
  </div>
</section>

<article class="full-width clearfix">
  <div class="section-container margin-top--l">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

        <form [formGroup]="geoStudyFormGroup" (ngSubmit)="onSubmit()">
          <mat-horizontal-stepper #stepper formArrayName="formArray" [linear]="false">
            <!--   LOCALIZACION STEP   -->

            <mat-step formGroupName="0" [stepControl]="formArray.get([0])">
              <ng-template matStepLabel>Localización</ng-template>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <mat-form-field>
                          <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Nombre del proyecto"
                            formControlName="projectNameCtrl" required />
                        </mat-form-field>
                        <!-- [disabled]="partDisabled" -->
                        <mat-form-field>
                          <mat-label>Tipo de inmueble</mat-label>
                          <mat-select [(value)]="buildingTypeSelected" formControlName="buildingTypeCtrl"
                            (selectionChange)="
                              onChangeKindBuilding(
                                $event,
                                'buildingTypeSelected'
                              )
                            ">
                            <!-- <mat-option value="instore">Instore</mat-option>
                            <mat-option value="mall">Mall</mat-option>
                            <mat-option value="freestandingzonaresidencial"
                              >Free Standing en zona residencial</mat-option
                            >
                            <mat-option
                              value="freestandingzonacomercialexistente"
                              >Free Standing en zona comercial
                              existente</mat-option
                            >
                            <mat-option
                              value="freestandingnuevodesarrollocomercial"
                              >Free Standing en nuevo desarrollo
                              comercial</mat-option
                            >
                            <mat-option value="mallnuevodesarrollocomercial"
                              >Mall en nuevo desarrollo comercial</mat-option
                            > -->
                            <mat-option value="instore">Instore</mat-option>
                            <mat-option value="mall">Mall</mat-option>
                            <mat-option value="freestanding">Free Standing</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <!--<div
                          *ngIf="show_div_kb_checkbox_zce"
                          class="full-width clearfix margin-top--xs border-bottom--gray"
                          id="div_kb_checkbox_zce"
                        >
                          <mat-checkbox
                            [checked]="checked_div_kb_checkbox_zce"
                            value="zonacomercialexistente"
                            formControlName="zonacomercialexistenteCtrl"
                            (keydown.enter)="$event.preventDefault()"
                            (change)="
                              onChangeKBCheckbox(
                                $event,
                                'zonacomercialexistente'
                              )
                            "
                            >Pertenece a centro comercial/p.
                            medianas</mat-checkbox
                          >
                        </div>-->
                        <div *ngIf="show_div_kb_checkbox_ndc"
                          class="full-width clearfix margin-top--xs border-bottom--gray" id="div_kb_checkbox_ndc">
                          <mat-checkbox [checked]="checked_div_kb_checkbox_ndc" value="nuevodesarrollocomercial"
                            formControlName="nuevodesarrollocomercialCtrl" (keydown.enter)="$event.preventDefault()"
                            (change)="
                              onChangeKBCheckbox(
                                $event,
                                'nuevodesarrollocomercial'
                              )
                            ">Nuevo desarrollo comercial</mat-checkbox>
                        </div>
                        <!--
                        <div class="full-width clearfix margin-top--xs">
                          <mat-checkbox
                            value="mccafeCtrl"
                            formControlName="mccafeCtrl"
                            (keydown.enter)="$event.preventDefault()"
                            >McCafé</mat-checkbox
                          >
                        </div>
                        -->
                        <div class="full-width clearfix margin-top--xs">
                          <mat-checkbox value="mckioskoCtrl" formControlName="mckioskoCtrl"
                            (keydown.enter)="$event.preventDefault()">McKiosko</mat-checkbox>
                        </div>
                        <div class="full-width clearfix margin-top--xs">
                          <mat-checkbox value="mcexpressCtrl" formControlName="mcexpressCtrl"
                            (keydown.enter)="$event.preventDefault()">McExpress</mat-checkbox>
                        </div>
                        <div class="full-width clearfix margin-top--xs">
                          <mat-checkbox #ref value="mcautoCtrl" (change)="onChange($event, 'mcautoCtrl')"
                            formControlName="mcautoCtrl" (keydown.enter)="$event.preventDefault()">McAuto</mat-checkbox>
                        </div>
                        <div class="full-width clearfix margin-top--xs">
                          <mat-checkbox value="mcdeliveryCtrl" formControlName="mcdeliveryCtrl"
                            (keydown.enter)="$event.preventDefault()">Delivery</mat-checkbox>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <mat-form-field>
                          <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Dirección"
                            formControlName="addressCtrl" required [readonly]="partDisabled" />
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Ciudad"
                            formControlName="cityCtrl" required [readonly]="partDisabled" />
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Provincia"
                            formControlName="provinceCtrl" required [readonly]="partDisabled" />
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Longitud"
                            formControlName="lonCtrl" readonly required />
                        </mat-form-field>

                        <mat-form-field>
                          <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Latitud"
                            formControlName="latCtrl" readonly required />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" id="div_map">


                    <app-mcdGeoLocatorMap style="width: 100%;" mapId="geoStudyLocatorMap" mapSize="regular"
                      [mapDisable]="partDisabled"></app-mcdGeoLocatorMap>

                    <div class="mat-dialog-actions margin-top--s">
                      <button mat-button class="button--xs" type="button" (click)="
                          partDisabled
                            ? $event.stopPropagation()
                            : locateAddress();
                          (partDisabled ? false : null)
                        " [class.disabled]="partDisabled">
                        Localizar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="mat-dialog-actions">
                      <button type="button" mat-button (click)="loadTransit()" matStepperNext>
                        Siguiente
                      </button>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <mat-dialog-actions> </mat-dialog-actions>
                  </div>
                </div>
              </section>
            </mat-step>

            <!--   CARACTERISTICAS STEP    -->
            <mat-step formGroupName="2" [stepControl]="formArray.get([2])">
              <ng-template matStepLabel>
                <p (click)="bricksSelected()">Características</p>
              </ng-template>
              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                    <mat-form-field>
                      <mat-label>Tipo play</mat-label>
                      <mat-select [(value)]="playSelected" formControlName="playCtrl">
                        <mat-option value="No">No</mat-option>
                        <mat-option value="Play exterior con carpa">Play exterior con carpa</mat-option>
                        <mat-option value="Play exterior sin carpa">Play exterior sin carpa</mat-option>
                        <mat-option value="Play interior">Play interior</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Asientos salón</mat-label>
                      <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
                        [(value)]="loungeSelected" formControlName="loungeSeatsCtrl" required />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Asientos terraza exterior</mat-label>
                      <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
                        [(value)]="outdoorSeatsCtrl" formControlName="outdoorSeatsCtrl" required />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Asientos terraza interior</mat-label>
                      <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
                        [(value)]="indoorSeatsCtrl" formControlName="indoorSeatsCtrl" required />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Entrada independiente centrol comercial</mat-label>
                      <mat-select [(value)]="independentEntanceSelected" formControlName="independentEntanceCtrl">
                        <mat-option value="No">No</mat-option>
                        <mat-option value="Sí">Sí</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
                  <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                    <mat-form-field>
                      <mat-label>Plazas totales de parking</mat-label>
                      <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
                        [(value)]="totalParkingSelected" formControlName="totalParkingCtrl" required />
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Plazas de parking propio</mat-label>
                      <input matInput type="number" min="0" pattern="^[0-9]+" (keydown.enter)="$event.preventDefault()"
                        [(value)]="totalOwnParkingSelected" formControlName="totalOwnParkingCtrl" required />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Accesibilidad</mat-label>
                      <mat-select [(value)]="accessibilitySelected" formControlName="accessibilityCtrl">
                        <mat-option value="0">0</mat-option>
                        <mat-option value="10">10</mat-option>
                        <mat-option value="A">A</mat-option>
                        <mat-option value="B">B</mat-option>
                        <mat-option value="C">C</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Visibilidad</mat-label>
                      <mat-select [(value)]="visibilitySelected" formControlName="visibilityCtrl">
                        <mat-option value="0">0</mat-option>
                        <mat-option value="10">10</mat-option>
                        <mat-option value="A">A</mat-option>
                        <mat-option value="B">B</mat-option>
                        <mat-option value="C">C</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="mat-dialog-actions">
                      <button type="button" mat-button matStepperNext (click)="addData(null, 3)"
                        [disabled]="disabledButton">
                        Siguiente
                      </button>

                    </div>
                    <div class="invalid-feedback"
                      style="color: orange; text-indent: 0.16cm; font-size: smaller; font-weight: bold;"
                      *ngIf="disabledButton">
                      <sup>*</sup> Se están cargando los datos de las siguientes ventanas. Por favor espere.
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <mat-dialog-actions> </mat-dialog-actions>
                  </div>
                </div>
              </section>
            </mat-step>
            <!--   COMPETENCIA STEP   -->
            <mat-step formGroupName="3" [stepControl]="formArray.get([3])">
              <ng-template matStepLabel>Restauración</ng-template>

              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      INSERTAR UN NUEVO RESTAURANTE MCDONALDS
                    </h2>
                    <P>Rellene los campos correspondientes para insertar un
                      nuevo restaurante MCDONALDS.</P>
                    <app-geo-add-mcd-table (mcDonaldsAdded)="mcDonaldsAddedHandler($event)"></app-geo-add-mcd-table>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      ELIMINAR UN RESTAURANTE MCDONALDS
                    </h2>
                    <P>Seleccione los restaurantes McDonald's que desea eliminar
                      haciendo click sobre la casilla correspondiente. Los
                      restaurantes seleccionados no se tendrán en cuenta en el
                      estudio. Área 10min coche.</P>

                    <app-geo-remove-mcd-table (eventEmitter)="mcDonadsRemovedHandler($event)"
                      [inputData]="sampleMcDonalds"></app-geo-remove-mcd-table>
                  </div>
                </div>
              </section>




              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      INSERTAR UN NUEVO RESTAURANTE DE LA COMPETENCIA
                    </h2>
                    <P>Rellene los campos correspondientes para insertar un
                      nuevo restaurante de la competencia.</P>
                    <app-geo-add-competitors-table
                      (competitorsAdded)="competitorsAddedHandler($event)"></app-geo-add-competitors-table>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      ELIMINAR UN RESTAURANTE DE LA COMPETENCIA
                    </h2>
                    <P>Seleccione los restaurates de la competencia que desea
                      eliminar haciendo click sobre la casilla correspondiente.
                      Los restaurantes seleccionados no se tendrán en cuenta en
                      el estudio. Área 10min coche.</P>

                    <app-geo-remove-competitors-table (competitorsRemoved)="competitorsRemovedHandler($event)"
                      (eventEmitter)="competitorsRemovedHandler($event)"
                      [inputData]="sampleCompetencia"></app-geo-remove-competitors-table>


                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      OTRA COMPETENCIA
                    </h2>

                    <app-geo-remove-competitors-others-table
                      [inputCompetitorOth]="competitorsOth"></app-geo-remove-competitors-others-table>

                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="mat-dialog-actions">
                      <button type="button" mat-button matStepperNext (click)="competitorsNext()">
                        Siguiente
                      </button>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <mat-dialog-actions> </mat-dialog-actions>
                  </div>
                </div>
              </section>
            </mat-step>

            <!--COMERCIO-->
            <mat-step formGroupName="4" [stepControl]="formArray.get([4])">
              <ng-template matStepLabel>Comercio</ng-template>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      ELIMINAR/AÑADIR UN COMERCIO
                    </h2>
                    <P>Seleccione en cada comercio existente el número que desea
                      eliminar o añadir. Área 10min andando.</P>
                    <app-geo-remove-commerces-table (commercesRemoved)="commercesEditedHandler($event)"
                      (eventEmitter)="commercesEditedHandler($event)"
                      [inputCommerce]="commerces"></app-geo-remove-commerces-table>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      AÑADIR UN COMERCIO
                    </h2>
                    <P>Haga click sobre el botón de añadir y rellene los campos
                      correspondientes para insertar un comercio. Área 10min
                      andando.</P>
                    <app-geo-add-commerces-table
                      (commercesAdded)="commercesAddedHandler($event)"></app-geo-add-commerces-table>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="mat-dialog-actions">
                      <!-- <button type="button" mat-button matStepperPrevious>
                        Anterior
                    </button> -->
                      <button type="button" mat-button matStepperNext (click)="commercesNext()">
                        Siguiente
                      </button>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <mat-dialog-actions> </mat-dialog-actions>
                  </div>
                </div>
              </section>
            </mat-step>

            <mat-step formGroupName="5" [stepControl]="formArray.get([5])">
              <ng-template matStepLabel>Centro comercial</ng-template>

              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      EDITAR UN CENTRO COMERCIAL
                    </h2>
                    <P>Haga click sobre el centro comercial que desea editar.
                      Área 10min andando.
                    </P>

                    <app-geo-edit-commercial-center-table (commercialCenterEdited)="
                        commercialCenterEditedHandler($event)
                      " [inputCommercialCenter]="mall_center">
                    </app-geo-edit-commercial-center-table>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--xl">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      AÑADIR UN CENTRO COMERCIAL
                    </h2>
                    <P>Haga click sobre el botón para añadir un nuevo centro
                      comercial. Área 10min andando.
                    </P>

                    <app-geo-add-commercial-center-table (commercialCenterAdded)="
                        commercialCenterAddedHandler($event)
                      ">
                    </app-geo-add-commercial-center-table>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="mat-dialog-actions">
                      <button type="button" mat-button matStepperNext (click)="saveStudy()">
                        Siguiente
                      </button>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <mat-dialog-actions> </mat-dialog-actions>
                  </div>
                </div>
              </section>
            </mat-step>
            <mat-step formGroupName="6" [stepControl]="formArray.get([6])">
              <ng-template matStepLabel>Presencia/Tráfico</ng-template>

              <section class="full-width clearfix padding-xs margin-top--s" *ngIf="!hasPresenciaTrafico">
                <!-- -->
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div>
                      <p>
                        Se están calculando los datos de presencia y tráfico.
                        Para visualizar los datos debe esperar unos segundos. Se
                        verá el estado en el listado de estudios
                      </p>
                      <!-- <button type="button" mat-button routerLink="/predictive/studies" >Aceptar</button> -->
                    </div>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s"
                *ngIf="hasPresenciaTrafico && (!isExecuted && !isProcessed)">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      PRESENCIA
                    </h2>
                    <P>Escoja el número de personas al día en los selectores que
                      se encuentran a su derecha.</P>
                  </div>
                </div>

                <div class="row margin-top--xs" *ngFor="let d of sampleMovilidad[0].values; let i = index">
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <p>
                      {{ d.name }}
                    </p>

                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field>
                      <input matInput type="number" (keydown.enter)="$event.preventDefault()" placeholder="{{ d.name }}"
                        formControlName="{{ d.key }}_vCtrl" required />
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field>
                      <input matInput type="number" (keydown.enter)="$event.preventDefault()" placeholder="{{ d.name }}"
                        formControlName="{{ d.key }}_iCtrl" required />
                    </mat-form-field>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s"
                *ngIf="hasPresenciaTrafico && (!isExecuted && !isProcessed)">
                <!---->
                <div class="row margin-top--xs">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 class="tab-headline">
                      TRÁFICO
                    </h2>
                    <P>Escoja el número de personas al día en el selector que se
                      encuentra a su derecha.</P>
                  </div>
                </div>

                <div class="row" *ngFor="let d of sampleMovilidad[1].values; let i = index">
                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <p>
                      {{ d.name }}
                    </p>
                  </div>

                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <mat-form-field>
                      <input matInput type="number" (keydown.enter)="$event.preventDefault()" placeholder="{{ d.name }}"
                        formControlName="{{ d.key }}_vCtrl" required />
                    </mat-form-field>
                  </div>

                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <mat-form-field>
                      <input matInput type="number" (keydown.enter)="$event.preventDefault()" placeholder="{{ d.name }}"
                        formControlName="{{ d.key }}_iCtrl" required />
                    </mat-form-field>
                  </div>
                </div>
              </section>

              <section class="full-width clearfix padding-xs margin-top--s"
                *ngIf="hasPresenciaTrafico && (!isExecuted && !isProcessed)">
                <!---->
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                    <div class="mat-dialog-actions">
                      <!-- <button type="button" mat-button matStepperPrevious (click)="movilidadBefore()">
                                                Anterior
                                            </button> -->
                      <button type="button" mat-button matStepperNext color="primary" type="submit">
                        <!-- Siguiente -->
                        Ejecutar Modelo
                      </button>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <mat-dialog-actions> </mat-dialog-actions>
                  </div>
                </div>
              </section>

              <!---->
              <section class="full-width clearfix padding-xs margin-top--s" *ngIf="isExecuted">

                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div>
                      <p>Se está calculando el modelo, por favor espere.</p>
                      <button type="button" mat-button routerLink="/predictive/studies">
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <section class="full-width clearfix padding-xs margin-top--s" *ngIf="isProcessed">

                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div>
                      <p>
                        El estudio se ha terminado, ya está disponible el
                        informe.
                      </p>
                      <button type="button" mat-button routerLink="/predictive/studies">
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <!---->
            </mat-step>
            <!--   GUARDAR informe STEP  -->

            <!-- <mat-step formGroupName="7" [stepControl]="formArray.get([7])">
              <ng-template matStepLabel>
                <p (click)="competitorsNext()">Ejecución</p>
              </ng-template>

              <section
                class="full-width clearfix padding-xs margin-top--s"
                *ngIf="isExecuted"
              >
                
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div>
                      <p>Se está calculando el modelo, por favor espere.</p>
                      <button
                        type="button"
                        mat-button
                        routerLink="/predictive/studies"
                      >
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="full-width clearfix padding-xs margin-top--s"
                *ngIf="isProcessed"
              >
                
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div>
                      <p>
                        El estudio se ha terminado, ya está disponible el
                        informe.
                      </p>
                      <button
                        type="button"
                        mat-button
                        routerLink="/predictive/studies"
                      >
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </section>

              <section
                class="full-width clearfix padding-xs margin-top--s"
                *ngIf="!isExecuted && !isProcessed"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                    <mat-dialog-actions>
                      <div>
                        <p>
                          Al pulsar sobre el botón "Ejecutar" se lanzará el
                          modelo de estimación de ventas. Este proceso puede
                          tardar unos minutos, una vez finalizado aparecerá en
                          la lista de estudios con el estado "Disponible".
                        </p>
                        <button
                          mat-flat-button
                          type="button"
                          routerLink="/predictive/studies"
                        >
                          Cancelar
                        </button>

                        <button mat-flat-button color="primary" type="submit">
                          Ejecutar
                        </button>
                      </div>
                    </mat-dialog-actions>
                  </div>
                </div>
              </section>
            </mat-step> -->
          </mat-horizontal-stepper>
        </form>
      </div>
    </div>
  </div>
</article>

<div class="margin-top--xl"></div>