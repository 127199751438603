import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from "src/app/app-services/data.service";

export interface Study {
  name: string;
  value: string;
}

export interface Period {
  name: string;
  value: string;
}

@Component({
  selector: "app-downloads-page",
  templateUrl: "./downloads-page.component.html",
  styleUrls: ["./downloads-page.component.scss"]
})
export class DownloadsPageComponent implements OnInit, OnDestroy {
  studies: any;
  periods: any;
  directories: any;
  dirYears: any;
  files: any;

  downloadsForm = new FormGroup({
    fileName: new FormControl("", [Validators.required]),
    yearName: new FormControl("", [Validators.required]),
    folderName: new FormControl("", [Validators.required])
  });

  unsubscribe: any;
  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.downloadsForm.value, this.downloadsForm.status);

    //this.dataService.getDownloadFile(this.downloadsForm.value.studyName);
    this.dataService.getDownloadFileFromFolder(this.downloadsForm.value.folderName ,this.downloadsForm.value.yearName, this.downloadsForm.value.fileName);

  }

  filterFile(filterValue: any) {
        let fileName = filterValue
          .split(".")[0]
          .split(
            "_" +
            filterValue.split(".")[0].split("_")[
              filterValue.split(".")[0].split("_").length - 1
              ]
          )[0];

    this.dataService.getFilesSuffix(fileName).subscribe(result => {     
      this.periods = [];
      result.forEach((element, index) => {
        let filePeriod = element.split(".")[0].split("_")[
          element.split(".")[0].split("_").length - 1
        ];
        var objPeriod = {};         
        objPeriod["name"] = filePeriod;
        objPeriod["value"] = filePeriod;
        this.periods.push(objPeriod);
      });
    });
  }

  filterFolder(filterValue: any) {
    this.dataService.getFolderYears(filterValue).subscribe(result => {
      this.dirYears = [];
      this.files = [];
      result.forEach((element, index) => {
        let filePeriod = element.split(".")[0].split("_")[
          element.split(".")[0].split("_").length - 1
        ];
        var objFolderYear = {};         
        objFolderYear["name"] = filePeriod;
        objFolderYear["value"] = filePeriod;
        objFolderYear["fullName"] = element;
        this.dirYears.push(objFolderYear);
      });
    });
}

  filterFolderYear(filterValue: any) {
    this.dataService.getFolderFiles(this.downloadsForm.value.folderName+"/"+filterValue).subscribe(result => {
      this.files = [];
      result.forEach((element, index) => {
        let filePeriod = element.split(".")[0].split("_")[
          element.split(".")[0].split("_").length - 1
        ];
        var objFile = {};         
        objFile["name"] = filePeriod;
        objFile["value"] = filePeriod;
        objFile["fullName"] = element;
        this.files.push(objFile);
      });
    });
}

  constructor(private dataService: DataService) {}

  ngOnInit() {

    this.unsubscribe = this.dataService.getNameFolderFiles().subscribe(foldernames => {
      this.directories = [];
      foldernames.forEach((element) => {
        var objDir = {};
      if(element=="Trafico"){
        objDir["value"] = element;
        objDir["name"] = "Tráfico";
      }else if(element.includes("_")){
        
        objDir["value"] = element;
        objDir["name"] = element.replace(/_/g," ");;
      }else{
        objDir["value"] = element;
        objDir["name"] = element;
      }     
      this.directories.push(objDir);
      })
      

    });

  }
  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
