import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"]
})
export class LoginPageComponent implements AfterViewInit {
  @ViewChild('mcdonalds_video') mcdonalds_video: ElementRef;
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(private route: ActivatedRoute, private router: Router) { }

  playVideo() {
    const media = this.mcdonalds_video.nativeElement;
    media.muted = true; // without this line it's not working although I have "muted" in HTML
    media.play();
  }

  ngOnInit() { }
  ngAfterViewInit() {
    this.playVideo();
  }

}
