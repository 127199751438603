import { NgModule } from "@angular/core";
import { SharedModule } from "../app-modules/app-shared.module";
import { LoginRoutingModule } from "./login-routing.module";
import { LoginCardComponent } from "./login-card/login-card.component";

import { FormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  imports: [SharedModule, LoginRoutingModule, MatProgressSpinnerModule],
  declarations: [LoginRoutingModule.components, LoginCardComponent],
  exports: [LoginCardComponent]
})
export class LoginModule {}
