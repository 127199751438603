import { NgModule } from "@angular/core";
import { DummyMarkerComponent } from "./dummy-marker.component";

@NgModule({
  declarations: [DummyMarkerComponent],
  imports: [],
  exports: [DummyMarkerComponent],
  providers: []
})
export class DummyMarkerModule {}
