import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { UsersService } from "../app-services/users.service";
export interface User {
  name: string;
  position: number;
  email: string;
  perfil: string;
}

export interface UserProfile {
  name: string;
  value: string;
}

// const users: User[] = [
//   {
//     position: 1,
//     name: "Gabriel Narutowicz",
//     email: "demo@mcdonalds.es",
//     perfil: "Administrador"
//   },
//   {
//     position: 2,
//     name: "Stanisław Wojciechowski",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 3,
//     name: "Maciej Rataj",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 4,
//     name: "Ignacy Mościcki",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 5,
//     name: "Władysław Raczkiewicz",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 6,
//     name: "August Zaleski",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 7,
//     name: "Stanisław Ostrowski",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 8,
//     name: "Edward Raczyński",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 9,
//     name: "Kazimierz Sabbat",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   },
//   {
//     position: 10,
//     name: "Ryszard Kaczorowski",
//     email: "demo@mcdonalds.es",
//     perfil: "Usuario"
//   }
// ];

@Component({
  selector: "app-users-page",
  templateUrl: "./users-page.component.html",
  styleUrls: ["./users-page.component.scss"]
})
export class UsersPageComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "select",
    // "position",
    "name",
    "email",
    "perfil"
  ];

  userProfiles: UserProfile[] = [
    { name: "Avanzado", value: "advance" },
    { name: "Básico", value: "basic" }
  ];

  users: User[] = [];
  dataSource = new MatTableDataSource(this.users);
  selection = new SelectionModel<any>(true, []);
  ready: boolean = false;
  unsubscribe: any;
  unsubscribe2: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: User): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
  }
  deleteUser() {    
    let userdel = this.selection.selected;
    userdel.forEach(element => {
      this.unsubscribe = this.userService.deleteUser(element._id).subscribe(
        response => {
          console.log("Response is: ", response);
          //this.geoStudiesDataService.loadAll();
          this.unsubscribe2 = this.userService
            .getAllUsers()
            .subscribe((result_user: any) => {
              this.users = result_user;
              this.users = result_user.map(element => {
                element.position = 1;
                if (this.checkUndefined(element.name)) {
                  element.name = element.username;
                } else {
                  element.name =
                    element.name +
                    " " +
                    (this.checkUndefined(element.surname)
                      ? ""
                      : element.surname);
                }
                element.email = element.username;
                if (element.group == "basic") {
                  element.perfil = "Básico";
                } else {
                  element.perfil = "Avanzado";
                }
                return element;
              });
              this.dataSource = new MatTableDataSource(this.users);
              this.dataSource.paginator = this.paginator;
            });
          this.dataSource._updateChangeSubscription();
        },
        error => {
          console.error("An error occurred, ", error);
        }
      );
    });

    this.dataSource._updateChangeSubscription();
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  checkUndefined(value: string) {
    if (value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  constructor(private userService: UsersService) {
    this.unsubscribe2 = this.userService
      .getAllUsers()
      .subscribe((result_user: any) => {
        this.users = result_user;
        this.users = result_user.map(element => {
          element.position = 1;
          if (this.checkUndefined(element.name)) {
            element.name = element.username;
          } else {
            element.name =
              element.name +
              " " +
              (this.checkUndefined(element.surname) ? "" : element.surname);
          }

          element.email = element.username;
          //element.perfil = element.group;

          if (element.group == "basic") {
            element.perfil = "Básico";
          } else {
            element.perfil = "Avanzado";
          }

          // if (element.hasOwnProperty("_id")) {
          //   delete element["_id"];
          // }
          // if (element.hasOwnProperty("username")) {
          //   delete element["username"];
          // }
          // if (element.hasOwnProperty("password")) {
          //   delete element["password"];
          // }
          // if (element.hasOwnProperty("group")) {
          //   delete element["group"];
          // }
          // if (element.hasOwnProperty("hashcode")) {
          //   delete element["hashcode"];
          // }
          return element;
        });
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
      });
  }

  ngOnInit() {
    // this.dataSource = new MatTableDataSource(this.users);
    // this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
    if (this.unsubscribe2) {
      this.unsubscribe2.unsubscribe();
    }
  }
}
