import { Component, OnInit, OnDestroy } from "@angular/core";

import { DataService } from "../../../app-services/data.service";
import { ParamsDataService } from "../../../app-services/d3/params-data.service";
import { ParamsLayoutService } from "../../../app-services/d3/params-layout.service";

@Component({
  selector: "app-data-ventas",
  templateUrl: "./data-ventas.component.html",
  styleUrls: ["./data-ventas.component.scss"]
})
export class DataVentasComponent implements OnInit, OnDestroy {
  data: any;
  ventas: any = {};
  percentages_sales_data: any = {};
  percentages_sales: any = {};
  time_sales_data: any = {};
  time_sales: any = {};

  hora_max_facturacion: any;
  hora_max_tickets: any;

  ventasReady: boolean = false;
  unsubscribe: any;

  // emit data
  temporalSerie: any = "half_hour_sales";
  constructor(
    private dataService: DataService,
    private paramsDataService: ParamsDataService,
    private paramsLayoutService: ParamsLayoutService
  ) {}

  changeTemporalSerie(event) {
    this.temporalSerie = event;
  }

  getHoraMaxVentas = (str: string) => {
    let maxArray: Array<string> = str.split("_");
    let day: string = maxArray[0];
    let hour: string = maxArray[1];

    switch (day) {
      case "Sabado":
        day = "Sábado";
        break;
      case "Miercoles":
        day = "Miércoles";
        break;
      default:
        day = day;
    }

    let maxString: string = day + ", " + hour + "h.";
    return maxString;
  };
  ngOnInit() {
    this.unsubscribe = this.dataService
      .getPlacesIdMcd(this.dataService.selectedRestaurant)
      .subscribe(d => {
        this.data = d;
        this.ventas = this.paramsDataService.getDataParamsSales(d, "sales");

        this.hora_max_facturacion = this.getHoraMaxVentas(
          this.ventas.grossData[0].hora_max_facturacion
        );
        this.hora_max_tickets = this.getHoraMaxVentas(
          this.ventas.grossData[0].hora_max_tickets
        );
        if(this.ventas.grossData[0].variacion==undefined){
          this.ventas.grossData[0].variacion = 0.0;
        }

        this.percentages_sales_data = this.paramsDataService.getDataParamsSales(
          d,
          "percentages_sales"
        );
        this.percentages_sales = this.paramsLayoutService.getLayoutParams(
          this.percentages_sales_data,
          "percentages_sales"
        );
        this.time_sales_data = this.paramsDataService.getDataParamsSales(
          d,
          "time_sales"
        );
        this.time_sales = this.paramsLayoutService.getLayoutParams(
          this.time_sales_data,
          "time_sales"
        );
        this.ventasReady = true;
      });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
