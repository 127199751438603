<section class="full-width clearfix padding-xs margin-top--xs">
  <div class="row">
    <div class="col-xs-12 col-sm-12  col-md-12  col-lg-12">

      <h1 mat-dialog-title>
        <strong>{{ action }} centro comercial:
          {{ local_data.properties.info.name }}</strong>
      </h1>
    </div>
  </div>
</section>

<section class="full-width clearfix padding-xs">
  <div mat-dialog-content>
    <div *ngIf="action != 'Eliminar'; else elseTemplate">

      <div class="row" *ngFor="let d of formElements; let i = index">

        <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
          {{ d.header }}
        </div>
        <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
          <mat-form-field>
            <input [(ngModel)]="local_data.properties.info[d.key]" placeholder="{{ d.name }}" matInput
              [disabled]="true" />
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
          <mat-form-field *ngIf="d.form_element === 'select'">
            <mat-label>{{ d.name }}</mat-label>
            <mat-select [(ngModel)]="local_data.model[d.key]" name="local_data.model[[d.key]">
              <mat-option *ngFor="let d of d.options" [value]="d.value">{{
                d.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="d.form_element === 'input' && d.validators != 'true'">
            <input type="{{ d.type ? d.type : text }}" placeholder="{{ d.name }}" matInput
              [(ngModel)]="local_data.model[d.key]" value="local_data.model[d.key]"
              (keydown.enter)="$event.preventDefault()" />
          </mat-form-field>

          <mat-form-field *ngIf="d.form_element === 'input' && d.validators === 'true'">
            <input type="{{ d.type ? d.type : text }}" placeholder="{{ d.name }}" matInput
              [(ngModel)]="local_data.model[d.key]" value="local_data.model[d.key]"
              (keydown.enter)="$event.preventDefault()" min="0" pattern="^[0-9]+" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12  col-md-4  col-lg-4">
          <div class="col-xs-12 col-sm-12  col-md-8  col-lg-8">
            <p>
              <small>La suma total de las 6 categorí­as del mix comercial debe sumar
                100.</small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end if -->

    <ng-template #elseTemplate>
      <p>
        ¿Está usted seguro de que quiere eliminar el
        <b>{{ local_data.properties.info.name }}</b>?
      </p>
    </ng-template>
  </div>

  <div class="margin-top--s"></div>
  <div mat-dialog-actions>
    <button mat-button (click)="doAction()">{{ action }}</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
      Cancelar
    </button>
  </div>
  <div class="margin-top--s"></div>

</section>