import { Component, OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";

import { FormControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { DataService } from "src/app/app-services/data.service";

@Component({
  selector: "app-tab-bricks",
  templateUrl: "./tab-bricks.component.html",
  styleUrls: ["./tab-bricks.component.scss"]
})
export class TabBricksComponent implements OnInit, OnDestroy {
  title = "Brick input";
  brickFormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(9),
    Validators.maxLength(9),
    Validators.pattern("[0-9]{9}")
  ]);

  submitted: boolean = false;
  unsubscribe: any;

  getBrickNumber() {
    return this.brickFormControl.value;
  }
  resetBrickNumber() {
    return this.brickFormControl.reset();
  }

  constructor(private dataService: DataService) {}

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;
    if (!this.brickFormControl.valid) {
      return;
    }
    this.unsubscribe = this.dataService
      .getBrickById(JSON.stringify(this.brickFormControl.value))
      .subscribe(d => {
        this.dataService.getBrickMap(d);
      });
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }
}
