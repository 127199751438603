<div mat-dialog-content>
  <p>{{ message }}</p>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
